import { recoverAuthFromMemory, registerAuthInMemory, destroyAuthMemory } from "./memory"
import { reactive, ref } from "@vue/composition-api"
import { navigate } from "@/router"
import { sessionRefreshed } from "@/utils/event-bus"
import { IUser, UserRoleEnum } from "@/types/user"
import { AuthData } from "./type"
import env from "@/utils/env"
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js"
import router from "@/router"
import Utils from "@/utils/utils"

export const session = reactive({
  auth0: {} as Auth0Client,
  auth: {
    initiated: false,
    userId: null,
    isAuthenticated: false,
    isAdmin: false,
    isSupport: false,
    isSysAdmin: false
  } as AuthData,

  _kickFromAuthenticatedRoute() {
    const routeRequiresAuth = router.currentRoute.meta?.requiresAuth ?? false

    if (routeRequiresAuth) {
      navigate({ name: "Login" })
    }
  },

  async openLoginPopup() {
    try {
      await this.auth0.loginWithPopup()
      await this.refreshAuth()
    } catch (err) {
      throw err as Error
    }
  },

  async getUser() {
    await this.auth0.handleRedirectCallback()
    const user = await this.auth0.getUser()
    await this.refreshAuth()

    return user
  },

  async loginWithRedirect() {
    try {
      await this.auth0.checkSession()
      await this.auth0.loginWithRedirect({ redirect_uri: window.location.origin + "/login/redirect" })
    } catch (err) {
      throw err as Error
    }
  },

  async login() {
    await this.openLoginPopup()
  },

  async refreshAuth() {
    const auth = Utils.isType<Auth0Client>(this.auth0, "isAuthenticated")

    if (auth) {
      const lastAuthLevel = this.auth.isAuthenticated
      const newAuthLevel = await auth.isAuthenticated()

      if (lastAuthLevel !== newAuthLevel) {
        this.auth.isAuthenticated = newAuthLevel
        sessionRefreshed()
      }
    }
  },

  async isValidUser() {
    const auth = Utils.isType<Auth0Client>(this.auth0, "isAuthenticated")

    if (!auth) {
      await onCreated()
      sessionRefreshed()
    }

    await this.refreshAuth()
    return this.auth.isAuthenticated
  },

  async terminate() {
    await this.refreshAuth()
    sessionStorage.clear()

    destroyAuthMemory()

    try {
      await this.auth0.logout()
    } catch {
      this._kickFromAuthenticatedRoute()
    }
  },

  async validate() {
    await this.auth0.checkSession({
      ignoreCache: true
    })

    await this.refreshAuth()

    if (!this.auth.isAuthenticated) {
      this._kickFromAuthenticatedRoute()
    }

    return this.auth.isAuthenticated
  },

  async updateSelfDetails(user: IUser) {
    this.auth.isAdmin = user.role === UserRoleEnum.Admin
    this.auth.isSysAdmin = user.role === UserRoleEnum.SysAdmin
    this.auth.isSupport = user.role === UserRoleEnum.Support
    this.auth.userId = user.id
    await this.refreshAuth()

    registerAuthInMemory(this.auth)
  },

  async fetchTokenSilently() {
    const token = ref("")
    const auth = Utils.isType<Auth0Client>(this.auth0, "isAuthenticated")

    if (auth) {
      try {
        token.value = await this.auth0.getTokenSilently()
      } catch (err) {
        this.terminate()
        throw err
      }
    } else {
      await onCreated()
      sessionRefreshed()

      token.value = await this.fetchTokenSilently()
    }

    return token.value
  }
})

const onCreated = async () => {
  session.auth0 = await createAuth0Client({
    domain: env.VUE_APP_AUTH0_DOMAIN,
    client_id: env.VUE_APP_AUTH0_CLIENTID,
    audience: env.VUE_APP_AUTH0_AUDIENCE,
    cacheLocation: "localstorage",
    redirect_uri: window.location.origin
  })
}

export const startSession = async () => {
  const recoveredAuth = recoverAuthFromMemory()

  await onCreated()

  if (recoveredAuth?.auth) {
    session.auth = { ...recoveredAuth.auth }

    if (recoveredAuth.expired) {
      session.validate()
    }
  }

  session.auth.initiated = true

  return session
}

export default session.auth0
