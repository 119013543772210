<template>
  <ul class="emojiContainer">
    <li :class="emojiStatusClass(1)">
      <span @click="changeEmoji(1)">
        <img :src="emojis.horrible" :alt="$t('FeedbackDialog.img.alt.horrible')" />
      </span>
    </li>
    <li :class="emojiStatusClass(2)">
      <span @click="changeEmoji(2)">
        <img :src="emojis.sad" :alt="$t('FeedbackDialog.img.alt.sad')" />
      </span>
    </li>
    <li :class="emojiStatusClass(3)">
      <span @click="changeEmoji(3)">
        <img :src="emojis.neutral" :alt="$t('FeedbackDialog.img.alt.neutral')" />
      </span>
    </li>
    <li :class="emojiStatusClass(4)">
      <span @click="changeEmoji(4)">
        <img :src="emojis.happy" :alt="$t('FeedbackDialog.img.alt.happy')" />
      </span>
    </li>
    <li :class="emojiStatusClass(5)">
      <span @click="changeEmoji(5)">
        <img :src="emojis.love" :alt="$t('FeedbackDialog.img.alt.love')" />
      </span>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, readonly, SetupContext } from "@vue/composition-api"
import { Dictionary, VModel } from "@/types/core"
import Emojis from "../templates/emojis"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "EmojiBtn",
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  setup(props, ctx) {
    return {
      ...EmojiSetup(props, ctx)
    }
  }
})

const EmojiSetup = (props: VModel<unknown>, ctx: SetupContext): Dictionary => {
  const emojis = readonly(Emojis)
  const emojiRate = Utils.vModel(props, ctx.emit)

  const emojiStatusClass = (status: number): string => {
    const rate = emojiRate.value

    if (rate && rate === status) {
      return "active"
    } else if (rate) {
      return "faded"
    }

    return ""
  }

  const changeEmoji = (status: number): void => {
    if (emojiRate.value === status) {
      emojiRate.value = null
    } else {
      emojiRate.value = status
    }
  }

  return {
    emojis,
    emojiRate,
    changeEmoji,
    emojiStatusClass
  }
}
</script>
