import { PlatformViewType } from "@/types/core"

export const getPlatformViewSettings = () => {
  const { VIZ_NOW, APP_LAUNCHER } = PlatformViewType
  const view = localStorage.getItem("view-settings") || VIZ_NOW

  return {
    appLauncher: view === APP_LAUNCHER,
    vizNow: view === VIZ_NOW
  }
}
