import { defineStore } from "pinia"
import { AdvancedFilterConfig } from "@/utils/advancedFilter/type"
import { Workspace, WorkspaceTemplate as Template, Deployment, FileTransfer, CloudProvider } from "@/services"
import { WizardConfigItem } from "@/types/wizard"
import { Organization } from "@/services"
import { RegisteredIp, CloudZone } from "@/types/core"
import { FileTransferConfig } from "@/types/fileTransfer"
import { reactive } from "@vue/composition-api"
import { AwsVpcConfig, VpcPeerWorkspace, Vpc } from "@/views/workspaceEdit/components/header/multipleVpc/lib/type"
import { ScheduledOnOff, ShutdownSettings } from "@/views/appLauncher/lib/types"

import {
  SpaceTag,
  WorkspaceTemplate,
  CreateWorkspacePayload,
  IAssetModel,
  WorkspaceRawTemplate,
  SortingConfig,
  AddNewAssetPayload,
  AppItem,
  UpdateAssetPayload,
  WorkspaceOnlineStatusEnum
} from "@/types/workspace"

export const WorkspaceModule = defineStore("workspace", {
  state: () => ({
    newWorkspace: {
      renameTemplateTo: "",
      newTemplateDescription: "",
      newTemplateTags: [] as string[],
      organizationId: "",
      templateId: ""
    },
    advancedFilterConfig: {
      ownerId: "any",
      lastModified: "any",
      status: [],
      search: ""
    } as AdvancedFilterConfig,
    sortingConfig: {
      sortBy: ["status"],
      sortDesc: [true]
    } as SortingConfig,
    selectedTemplate: {} as WorkspaceTemplate,
    wizardConfig: [] as WizardConfigItem[],
    registeredWizardConfig: [] as WizardConfigItem[],
    watching: reactive({} as WorkspaceTemplate),
    appIdBeingEdited: "",
    templates: [] as WorkspaceRawTemplate[],
    appItems: [] as AppItem[],
    assetModels: [] as IAssetModel[],
    myWorkspaces: [] as WorkspaceTemplate[],
    myWorkspacesFromOrganization: [] as WorkspaceTemplate[],
    vpc: [] as Vpc[],
    spaceRegions: [] as CloudZone[],
    scheduledOnOff: {
      scheduledOnOffEnabled: false,
      autoStartupAt: null,
      autoShutdownAt: null
    } as ScheduledOnOff,
    shutdownSettings: {
      autoShutdownEnabled: true,
      shutdownTimerInHours: 2,
      inactivityTrackerEnabled: false,
      inactivityPeriodInHours: 0
    } as ShutdownSettings
  }),
  actions: {
    resetNewWorkspaceSettings() {
      this.newWorkspace.renameTemplateTo = ""
      this.newWorkspace.newTemplateDescription = ""
      this.newWorkspace.newTemplateTags = []
    },

    resetCurrentWorkspace() {
      this.watching = {} as WorkspaceTemplate
    },

    clearAutoShutdown() {
      this.scheduledOnOff.scheduledOnOffEnabled = false
      this.scheduledOnOff.autoStartupAt = null
      this.scheduledOnOff.autoShutdownAt = null
    },

    async getRegions() {
      const req = await CloudProvider.getRegions()
      this.spaceRegions = req.data.data.regions
    },

    async getWorkspace(workspaceId: string): Promise<WorkspaceTemplate> {
      const req = await Workspace.getWorkspace(workspaceId)
      const workspace = req?.data?.data

      if (workspace) {
        this.watching = reactive({ ...req.data.data })
      }

      return this.watching
    },

    async postponeAutoShutdown(workspaceId: string, postponeFor?: number) {
      await Workspace.postponeAutoShutdown(workspaceId, postponeFor)
    },

    async cancelAutoShutdown(workspaceId: string) {
      await Workspace.cancelAutoShutdown(workspaceId)
    },

    async getNdiBridgeSettings(workspaceId: string, assetId: string) {
      const req = await Workspace.getNdiBridgeSettings(workspaceId, assetId)
      return req.data.data
    },

    async getMyWorkspaces() {
      const req = await Workspace.getMyWorkspaces()
      this.myWorkspaces = req.data.data

      return this.myWorkspaces
    },

    async getMyWorkspacesUsingOrganizationId(organizationId: string) {
      const req = await Workspace.getMyWorkspacesUsingOrganizationId(organizationId)
      this.myWorkspacesFromOrganization = req.data.data

      return this.myWorkspaces
    },

    async getVpc(workspaceId: string) {
      const req = await Workspace.getVpc(workspaceId)
      this.vpc = req.data.data

      return this.vpc
    },

    async deleteVpc(workspaceId: string, peeringId: string) {
      return await Workspace.deleteVpc(workspaceId, peeringId)
    },

    async addApp(app: AddNewAssetPayload) {
      return await Workspace.addApp(this.watching.id, app)
    },

    async setThenDownloadFileTransferConfig(fileTransferConfig: FileTransferConfig, workspaceId: string) {
      await FileTransfer.setFileTransferConfig(fileTransferConfig, workspaceId)
      return this.downloadFileTransfer(workspaceId)
    },

    async downloadFileTransfer(workspaceId: string) {
      await FileTransfer.downloadFileTransfer(workspaceId)
    },

    async updateApp(appId: string, app: UpdateAssetPayload) {
      await Workspace.updateApp(this.watching.id, appId, app)
      await this.getWorkspace(this.watching.id)
    },

    async removeApp(assetId: string) {
      await Workspace.deleteApp(this.watching.id, assetId)
    },

    async resetWorkspaceSchemaToDeployed() {
      await Workspace.resetWorkspaceSchemaToDeployed(this.watching.id)
      await this.getWorkspace(this.watching.id)
    },

    async appDiscardChanges(appId: string) {
      await Workspace.appDiscardChanges(this.watching.id, appId)
      await this.getWorkspace(this.watching.id)
    },

    async getAvailableApps(workspaceId: string) {
      const req = await Workspace.getAvailableApps(workspaceId)
      this.appItems = req.data.data

      return this.appItems
    },

    async updateTag(tag: SpaceTag, workspaceId: string) {
      await Workspace.updateTag(tag, workspaceId)
    },

    async deleteTag(tagName: string, workspaceId: string) {
      await Workspace.deleteTag(tagName, workspaceId)
    },

    async delete(workspaceId: string) {
      return await Workspace.delete(workspaceId)
    },

    async create(config: CreateWorkspacePayload) {
      const req = await Workspace.create(config)
      return req.data.data
    },

    async destroy(workspaceId: string) {
      return await Deployment.destroy(workspaceId)
    },

    async deploy(workspaceId: string, renewCertificate: boolean) {
      const req = await Deployment.deploy(workspaceId, renewCertificate)
      this.watching = req.data.data
    },

    async setShutdownSettings(workspaceId: string, shutdownSettings: ShutdownSettings) {
      await Workspace.setShutdownSettings(workspaceId, shutdownSettings)
    },

    async getAutoShutdownSettings(workspaceId: string) {
      const req = await Workspace.getAutoShutdownSettings(workspaceId)
      this.shutdownSettings = req.data.data
      return this.shutdownSettings
    },

    async getScheduledOnOff(workspaceId: string) {
      const req = await Workspace.getScheduledOnOff(workspaceId)

      if (req.data.data) {
        this.scheduledOnOff = req.data.data
        return this.scheduledOnOff
      } else {
        this.clearAutoShutdown()
      }
    },

    async setScheduledOnOff(workspaceId: string, schedule: ScheduledOnOff) {
      const req = await Workspace.setScheduledOnOff(workspaceId, schedule)

      if (req.data.data) {
        this.scheduledOnOff = req.data.data
        return this.scheduledOnOff
      }
    },

    async saveChanges(workspace: WorkspaceTemplate) {
      await Workspace.saveChanges(workspace)

      return await this.getWorkspace(workspace.id)
    },

    async authorizeIP(ipProp: RegisteredIp, workspaceId: string) {
      await Workspace.authorizeIp(ipProp, workspaceId)
      await this.getWorkspace(workspaceId)
    },

    async saveVpc(vpcConfig: AwsVpcConfig | VpcPeerWorkspace, workspaceId: string) {
      await Workspace.saveVpc(vpcConfig, workspaceId)
    },

    async revokeIP(ipProp: RegisteredIp, workspaceId: string) {
      await Workspace.revokeIp(ipProp, workspaceId)
      await this.getWorkspace(workspaceId)
    },

    async getAppPassword(workspaceId: string, appId: string) {
      const req = await Deployment.getAppPassword(workspaceId, appId)
      return req.data.data
    },

    async unlock(workspaceId: string) {
      await Deployment.unlockWorkspace(workspaceId)
    },

    async authorizeUser(userId: string, workspaceId: string) {
      await Workspace.authorizeUser(userId, workspaceId)
      await this.getWorkspace(workspaceId)
    },

    async setAppOnlineStatus(workspaceId: string, status: WorkspaceOnlineStatusEnum, appInstanceId = "") {
      const req = await Deployment.appSetOnlineState(workspaceId, status, appInstanceId)

      this.watching = req.data.data
      return this.watching
    },

    async archive(workspaceId: string) {
      await Workspace.archive(workspaceId)
    },

    async restore(workspaceId: string) {
      await Workspace.restore(workspaceId)
    },

    async revokeUser(userId: string, workspaceId: string) {
      await Workspace.revokeUser(userId, workspaceId)
      await this.getWorkspace(workspaceId)
    },

    async saveInstructions(workspace: WorkspaceTemplate) {
      await Workspace.saveInstructions(workspace)

      return await this.getWorkspace(workspace.id)
    },

    async getAssetModels() {
      const req = await Workspace.getAssetModels()
      this.assetModels = req.data.data

      return this.assetModels
    },

    async saveTagChanges(tags: string[], workspaceId: string) {
      await Workspace.saveTagChanges(tags, workspaceId)

      return await this.getWorkspace(workspaceId)
    },

    async refreshInstancesOnlineStatus(workspaceId: string): Promise<WorkspaceTemplate> {
      const req = await Workspace.refreshInstancesOnlineStatus(workspaceId)
      return req.data.data
    },

    async changeAppStatus(workspaceId: string, appState: WorkspaceOnlineStatusEnum) {
      const { Starting, Stopping } = WorkspaceOnlineStatusEnum
      const state = appState === "online" ? Starting : Stopping
      const req = await Deployment.appSetOnlineState(workspaceId, state)

      this.watching = req.data.data
    },

    async abortChanges(workspaceId: string) {
      await Deployment.abortWorkspaceChanges(workspaceId)
    },

    async changePrivacy(workspaceId: string, shared: boolean) {
      await Workspace.changeSharedState(workspaceId, shared)
      await this.getWorkspace(workspaceId)
    },

    async getWizardSettings(workspaceTemplateId: string): Promise<WizardConfigItem[]> {
      const req = await Template.getSettings(workspaceTemplateId)

      this.wizardConfig = req.data.data
      return this.wizardConfig
    },

    async getTemplates(organizationId: string) {
      const req = await Organization.getTemplates(organizationId)

      this.templates = req.data.data

      return this.templates
    },

    async createNew(workspace: WorkspaceTemplate) {
      const req = await Workspace.createWorkspace(workspace)
      return req.data.data
    }
  }
})
