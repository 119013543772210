<template>
  <div id="privacyPolicy" class="commonTab">
    <v-progress-linear v-if="loader.loading" color="primary" class="my-0 py-0" indeterminate />

    <div class="containCommunicationItem backgroundWrapper">
      <div class="summary">
        <h2>{{ $t("Admin.privacyPolicy.title") }}</h2>
        <p v-html="$t('Admin.privacyPolicy.subTitle')" />
      </div>

      <v-btn @click="confirmResetConditions" :disabled="loader.loading" class="primary" large>
        <span>{{ $t("Admin.privacyPolicy.resetButton") }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { AdminModule } from "@/store/admin"
import { raiseConfirmation } from "@/utils/event-bus"
import { UsersModule } from "@/store/users"
import { translate } from "@/plugins/i18n"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "PrivacyPolicyRoot",
  setup() {
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const loader = Loader()

    const confirmResetConditions = async () => {
      const warning = translate("Admin.privacyPolicy.warningBeforeReset")

      if (await raiseConfirmation({ text: warning })) {
        loader.run(async () => {
          await adminModule.resetTermsAndConditions()
          await usersModule.getMyDetails("forced")
        })
      }
    }

    return {
      loader,
      confirmResetConditions
    }
  }
})
</script>
