<template>
  <div class="inputLabel">
    <slot name="label">
      <label v-if="label">{{ label }}</label>
    </slot>

    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          v-model="activationDate"
          @input.native="$emit('input', value)"
          @blur="$emit('blur', value)"
          @change="$emit('change', value)"
          :rules="rules"
          :placeholder="placeholder"
          :name="name"
          :disabled="isDisabled"
          :loading="isLoading"
          :hide-details="shouldHideDetails"
          readonly
        />
      </template>

      <v-date-picker v-model="modelValue" no-title scrollable />
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { InputItem } from "./lib/type"
import commonInputConfig from "./composable/common-props"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "DateInput",
  props: {
    value: {
      type: String,
      required: true
    },
    director: {
      type: Object as PropType<InputItem>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const modelValue = Utils.vModel(props, ctx.emit)

    const activationDate = computed({
      get() {
        return Utils.dateFormat(modelValue.value)
      },
      set(value: string) {
        modelValue.value = new Date(value).toISOString()
      }
    })

    return {
      activationDate,
      ...commonInputConfig(props, ctx.emit)
    }
  }
})
</script>
