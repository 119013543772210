import { Dictionary } from "@/types/core"
import { WorkspaceTemplate, WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { WorkspaceStates } from "@/config/workspace-states"

const _iconMap: Dictionary<string> = {
  idle: `/favicons/draft.png?v=fav${Math.round(Math.random() * 5000)}`,
  draft: `/favicons/draft.png?v=fav${Math.round(Math.random() * 5000)}`,
  deploying: `/favicons/deploying.png?v=fav${Math.round(Math.random() * 5000)}`,
  archived: `/favicons/draft.png?v=fav${Math.round(Math.random() * 5000)}`,
  archiving: `/favicons/deploying.png?v=fav${Math.round(Math.random() * 5000)}`,
  restoring: `/favicons/deploying.png?v=fav${Math.round(Math.random() * 5000)}`,
  destroying: `/favicons/destroying.png?v=fav${Math.round(Math.random() * 5000)}`,
  failed: `/favicons/failed.png?v=fav${Math.round(Math.random() * 5000)}`,
  online: `/favicons/deployed.png?v=fav${Math.round(Math.random() * 5000)}`,
  offline: `/favicons/offline.png?v=fav${Math.round(Math.random() * 5000)}`,
  default: `/favicon.ico?v=fav${Math.round(Math.random() * 5000)}`
}

export const iconReplace = (status: string) => {
  const faviconStatus = _iconMap[status] ?? "default"

  const link = document.createElement("link")
  const oldLink = document.querySelector('link[rel="shortcut icon"]') as Element
  oldLink.parentNode?.removeChild(oldLink)

  link.id = "workspace-status"
  link.rel = "shortcut icon"
  link.href = faviconStatus

  document.head.appendChild(link)
}

const _getOnlineIcon = (onlineStatus: WorkspaceOnlineStatusEnum) => {
  if (["online", "starting"].includes(onlineStatus)) {
    return iconReplace("online")
  } else {
    return iconReplace("offline")
  }
}

export const updateFavicon = (workspace: WorkspaceTemplate | "default") => {
  if (workspace === "default") {
    return iconReplace("default")
  } else {
    if (WorkspaceStates.isLoading(workspace.status)) {
      if (workspace.status === "destroying") {
        return iconReplace("destroying")
      } else {
        return iconReplace("deploying")
      }
    } else if (WorkspaceStates.isDeployed(workspace.status)) {
      return _getOnlineIcon(workspace.onlineStatus)
    } else if (WorkspaceStates.failed(workspace.status)) {
      return iconReplace("failed")
    } else {
      iconReplace(workspace.status)
    }
  }
}
