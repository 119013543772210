<template>
  <popup v-model="templateEditorOpen" @confirm="confirm" :title="$t('Admin.spaceTemplate.popup.title')">
    <div class="templateEditorContainer">
      <v-label>
        <span>{{ $t("Admin.spaceTemplate.popup.input.deployPackage") }}</span>
        <v-select v-model="selectedTemplate" :items="packageItems" hide-details />
      </v-label>

      <instructions v-model="instructions" />
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { IDeployPackage } from "@/types/deployPackages"
import { WorkspaceRawTemplate } from "@/types/workspace"
import Popup from "@/components/popup/PopupRoot.vue"
import Utils from "@/utils/utils"
import Instructions from "./Instruction.vue"

export default defineComponent({
  name: "TemplateEditor",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    template: {
      type: Object as PropType<WorkspaceRawTemplate>,
      required: true
    },
    deployPackages: {
      type: Array as PropType<IDeployPackage[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const templateEditorOpen = Utils.vModel(props, ctx.emit)
    const selectedTemplate = ref(props.template.deployPackageId)
    const instructions = ref(props.template.instructions)

    const packageItems = computed(() => {
      return props.deployPackages.map(list => {
        return {
          text: list.name,
          value: list.id
        }
      })
    })

    const confirm = () => {
      ctx.emit("confirm", {
        templateId: props.template.id,
        selectedTemplate: selectedTemplate.value,
        instructions: instructions.value
      })

      templateEditorOpen.value = false
    }

    return {
      instructions,
      confirm,
      selectedTemplate,
      packageItems,
      templateEditorOpen
    }
  },
  components: {
    Popup,
    Instructions
  }
})
</script>
