<template>
  <div id="deployPackageVersionList">
    <div
      v-for="(deploy, index) in items"
      :key="`${deploy.id}-${items.length}`"
      :class="`delay-${index}`"
      class="versionItem animateFadeUp"
    >
      <span class="version">
        <v-chip v-if="deploy.version" :color="chipColor(deploy.version)" small>v{{ deploy.version }}</v-chip>
        <small v-else>{{ $t("Admin.deployPackage.versionList.empty") }}</small>
      </span>

      <span class="date">
        {{ lastModified(deploy.date) }}
      </span>

      <span> {{ fileSize(deploy.size) }} Kb </span>

      <span>
        <span v-if="deploy.terraformVersion">{{ deploy.terraformVersion }}</span>
        <small v-else class="empty">{{ $t("Admin.deployPackage.versionList.noTerraformVersion") }}</small>
      </span>

      <span>
        <span>{{ getPrettyServiceVersion(deploy.serviceVersion) }}</span>
      </span>

      <span class="description">
        <span v-if="deploy.description">{{ deploy.description }}</span>
        <small v-else>{{ $t("Admin.deployPackage.versionList.noDescription") }}</small>
      </span>

      <span>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>navigate_more_options_vertical</v-icon>
            </v-btn>
          </template>

          <div class="listMenu">
            <div
              @click="$emit('set:current', deploy.id, deploy.deployPackageId)"
              :disabled="!!chipColor(deploy.version)"
              class="listRow"
            >
              <v-icon left>content_campaign</v-icon>
              {{ $t("Admin.deployPackage.versionList.setCurrentVersion") }}
            </div>

            <div @click="$emit('download', deploy.id)" class="listRow">
              <v-icon left>action_download_or_import</v-icon>
              {{ $t("Admin.deployPackage.versionList.downloadTemplate") }}
            </div>
          </div>
        </v-menu>
      </span>
    </div>

    <div v-if="items.length === 0" class="emptyText">
      <small>{{ $t("Admin.deployPackage.versionList.noVersionsYet") }}</small>
    </div>

    <div class="paginationController">
      <span>{{ pageLabel }}</span>

      <div class="group">
        <v-btn @click="paginatedVersions.previous()" :disabled="!paginatedVersions.hasPreviousPage" icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn @click="paginatedVersions.next()" :disabled="!paginatedVersions.hasNextPage" icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive } from "@vue/composition-api"
import { IDeployPackageVersion } from "@/types/deployPackages"
import { Pagination } from "@/utils/pagination"
import { useStore } from "@/composable/useStore"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "VersionList",
  props: {
    versions: {
      type: Array as PropType<IDeployPackageVersion[]>,
      required: true
    },
    currentVersion: {
      type: String,
      default: "1"
    }
  },
  setup(props) {
    const { adminModule } = useStore()
    const paginatedVersions = reactive(new Pagination(props.versions))

    const serviceVersions = computed(() => {
      return adminModule.deployPackageServiceVersions
    })

    const items = computed(() => {
      return paginatedVersions.items
    })

    const pageLabel = computed(() => {
      return `${paginatedVersions.page} / ${paginatedVersions.lastPage}`
    })

    const lastModified = (date: string) => {
      return Utils.lastModified(date)
    }

    const fileSize = (size: number) => {
      return (size / 1000).toFixed(2)
    }

    const chipColor = (version: string) => {
      if (props.currentVersion === version) {
        return "primary"
      }

      return ""
    }

    const getPrettyServiceVersion = (versionId: number) => {
      return serviceVersions.value.find(version => version.id === versionId)?.prettyName
    }

    return {
      items,
      getPrettyServiceVersion,
      fileSize,
      pageLabel,
      lastModified,
      chipColor,
      paginatedVersions
    }
  }
})
</script>
