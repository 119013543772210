<template>
  <v-form ref="Form">
    <v-checkbox
      v-model="userAgreement"
      :rules="MandatoryCheckbox"
      :label="$t('Terms.inputs.checkbox')"
      hide-details
      color="primary"
    />

    <div class="termControllers">
      <v-btn @click="confirmAgreement" :disabled="!userAgreement" :loading="loading" color="primary" large>{{
        $t("Terms.inputs.confirmButton")
      }}</v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api"
import { MandatoryCheckbox } from "@/utils/input-rules"
import { VForm } from "@/types/core"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "TermForm",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(_, ctx) {
    const Form = ref(null)
    const userAgreement = ref(false)

    const confirmAgreement = () => {
      const form = Utils.isType<VForm>(Form.value, "validate")

      if (form && form.validate()) {
        ctx.emit("confirm")
      }
    }

    return {
      Form,
      userAgreement,
      MandatoryCheckbox,
      confirmAgreement
    }
  }
})
</script>
