import { BundleActions } from "./factory"
import { Dictionary } from "@/types/core"

const ERROR = {
  prefix: "ApiFactoryError - "
}

export default class Services {
  private bundle: Dictionary<BundleActions> = {}

  private findBundle(bundleName: string): BundleActions {
    const module = this.bundle[bundleName]

    if (module) {
      return module
    }

    throw Error(`${ERROR.prefix} Module (${bundleName}) not found.`)
  }

  public add<T>(bundleName: string, actions: T & BundleActions): void {
    this.bundle[bundleName] = actions
  }

  public find(bundleName: string | string[]): Dictionary<BundleActions> {
    const bundles: Dictionary<BundleActions> = {}

    if (Array.isArray(bundleName)) {
      bundleName.forEach(name => {
        bundles[name] = this.findBundle(name)
      })
    } else {
      bundles[bundleName] = this.findBundle(bundleName)
    }

    return bundles
  }
}
