<template>
  <div v-if="deployOutputs.length" class="gridColumn">
    <div v-for="output in deployOutputs" :key="output.key" class="gridItem">
      <span>{{ t(output.key) }}</span>
      <span>{{ output.value }}</span>

      <copy-btn :text="output.value" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { useStore } from "@/composable/useStore"
import { WorkspaceStates } from "@/config/workspace-states"
import { createTranslationModule } from "@/plugins/i18n"
import router from "@/router"
import CopyBtn from "@/components/copyBtn"

export default defineComponent({
  name: "IpDistribution",
  setup() {
    const { spaceModule } = useStore()
    const t = createTranslationModule("TriCaster.boxFooter.moreInfo.ipKeys")

    const deployOutputs = computed(() => {
      if (isDeployed) {
        const appId = router.currentRoute.params.appId
        const app = spaceModule.watching.schemaDeployed?.assets.find(app => app.id === appId)

        if (app) {
          return app.deployOutputs.filter(output => output.key.includes("ip"))
        }
      }

      return []
    })

    const isDeployed = computed(() => {
      return WorkspaceStates.isDeployed(spaceModule.watching.status)
    })

    return {
      t,
      deployOutputs,
      isDeployed
    }
  },
  components: {
    CopyBtn
  }
})
</script>
