<template>
  <div class="htmlEditor" :loading="loading">
    <vue-editor v-model="htmlValue" :editor-toolbar="customToolbarConfig" class="quill-neo" />
  </div>
</template>

<script lang="ts">
import Utils from "@/utils/utils"
import { defineComponent } from "@vue/composition-api"
import "./scss/_htmlEditor.scss"

export default defineComponent({
  name: "HtmlEditor",
  props: {
    value: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const htmlValue = Utils.vModel(props, ctx.emit)

    const customToolbarConfig = [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "color-picker", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }]
    ]

    return {
      htmlValue,
      customToolbarConfig
    }
  }
})
</script>

<script>
/**
 * This script block is a workaround for us to be able
 * to run the VueEditor without compiler complaining.
 *
 * We need to keep the information on the above script so
 * Eslint can recognize the types, but it's being ignored
 * by Vue as soon as second defineComponent is called.
 *
 * SASS import should be fine.
 */
import { VueEditor } from "vue2-editor"
import { defineComponent } from "@vue/composition-api"
import Utils from "@/utils/utils"

/* eslint-disable */ // Ignore warning because of the new Vue CLI 4.5.19 (workaround)
export default defineComponent({
  name: "HtmlEditorV4.5.19",
  props: {
    value: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const htmlValue = Utils.vModel(props, ctx.emit)

    const customToolbarConfig = [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "color-picker", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }]
    ]

    return {
      htmlValue,
      customToolbarConfig
    }
  },
  components: {
    VueEditor
  }
})
</script>
