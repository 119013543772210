import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transactionTable"},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.walletTokens,"items-per-page":_vm.itemsPerPage,"item-class":_vm.rowClass,"show-expand":"","hide-default-footer":"","expand-icon":"mdi-menu-up"},scopedSlots:_vm._u([{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateFormat(item.creationDate))+" ")]}},{key:"item.initialAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"initialAmount"},[_vm._v(" + "+_vm._s(item.initialAmount)+" ")])]}},{key:"item.usedAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"usedAmount"},[_vm._v(" "+_vm._s(_vm.calculateRemainingValue(item))+" ")])]}},{key:"item.expirationDate",fn:function(ref){
var item = ref.item;
return [(item.expirationDate)?_c('div',[(!_vm.isExpired(item))?_c('span',[_vm._v(_vm._s(_vm.toDateFormatWithTime(item.expirationDate)))]):_c('span',{attrs:{"expired":""}},[_vm._v(_vm._s(_vm.t("expired")))])]):_c('span',[_vm._v(_vm._s(_vm.t("neverExpires")))])]}},{key:"item.activationDate",fn:function(ref){
var item = ref.item;
return [(item.activationDate)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.toDateFormat(item.activationDate)))])]):_c('span',[_vm._v(_vm._s(_vm.t("neverExpires")))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.transactions),function(transaction,index){return _c('ul',{key:index},_vm._l((Object.keys(transaction)),function(keyName){return _c('li',{key:keyName,class:keyName,style:({ gridArea: keyName }),attrs:{"debt":transaction.amount < 0}},[_vm._v(" "+_vm._s(_vm.getInnerTableItem(transaction, keyName))+" ")])}),0)}),0)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }