<template>
  <div :class="componentType" class="switchWrapper">
    <span v-if="!isChangingOnlineState">
      {{ $t("workspaces.turnEverything") }}
    </span>

    <span v-else>
      <going-to-label :apps-deployed="appsDeployed" :online-status="onlineStatus" />
    </span>

    <v-btn
      v-if="isOnline"
      @click.stop="confirmBeforeChangeStatus(Offline, BtnIndex.OFF)"
      :loading="loading && isBtnIndexActive(BtnIndex.OFF)"
      :disabled="loading && !isBtnIndexActive(BtnIndex.OFF)"
    >
      <span>{{ $t("workspaces.switchButton.off") }}</span>
    </v-btn>

    <v-btn
      v-if="containsOfflineApps && !isChangingOnlineState"
      @click.stop="confirmBeforeChangeStatus(Online, BtnIndex.ON)"
      :loading="loading && isBtnIndexActive(BtnIndex.ON)"
      :disabled="loading && !isBtnIndexActive(BtnIndex.ON)"
      color="primary"
    >
      <span>{{ $t("workspaces.switchButton.on") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum, IWorkspaceAsset, WorkspaceTemplate } from "@/types/workspace"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import GoingToLabel from "./GoingToLabel.vue"

enum BtnIndex {
  OFF = 0,
  ON = 1,
  CANCEL = 2
}

export default defineComponent({
  name: "SwitchHandler",
  props: {
    isOnline: {
      type: Boolean,
      default: false
    },
    isDeployed: {
      type: Boolean,
      default: false
    },
    isChangingOnlineState: {
      type: Boolean,
      required: true
    },
    appsDeployed: {
      type: Array as PropType<IWorkspaceAsset[] | null>,
      required: true
    },
    onlineStatus: {
      type: String as PropType<WorkspaceOnlineStatusEnum>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    componentType: {
      type: String as PropType<"table" | "grid">,
      default: "table"
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props, ctx) {
    const { Online, Offline } = WorkspaceOnlineStatusEnum
    const lastBtnClickedIndex = ref(-1)

    const _stepBtnIndex = (index: number) => {
      lastBtnClickedIndex.value = index
    }

    const isBtnIndexActive = (index: number) => {
      return lastBtnClickedIndex.value === index
    }

    const confirmBeforeChangeStatus = async (status: WorkspaceOnlineStatusEnum, index: number) => {
      const freePass = status === Online
      const off = "workspaces.confirmSetStatus.offline"
      const aborting = "workspaces.confirmSetStatus.aborting"
      const snippet = status === Offline ? off : aborting

      _stepBtnIndex(index)

      if (freePass || (await raiseConfirmation({ text: translate(snippet) }))) {
        ctx.emit("set:status", status)
      }
    }

    const containsOfflineApps = computed(() => {
      const workspace = props.workspace
      const anyOfflineApp = workspace.schemaDeployed?.assets?.some(app => app.onlineStatus === Offline)

      return anyOfflineApp
    })

    const appsGoingToStatus = (status: WorkspaceOnlineStatusEnum) => {
      if (props.appsDeployed) {
        return props.appsDeployed.filter(app => app.onlineStatus === status).length
      }

      return 0
    }

    return {
      containsOfflineApps,
      BtnIndex,
      appsGoingToStatus,
      isBtnIndexActive,
      confirmBeforeChangeStatus,
      ...WorkspaceOnlineStatusEnum
    }
  },
  components: {
    GoingToLabel
  }
})
</script>
