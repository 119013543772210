<template>
  <nested-item
    :app="slider"
    :service="service"
    :has-changes="hasChanges"
    :readonly="service.firstDeployOnly && wasDeployed"
  >
    <v-checkbox
      v-model="service.active"
      :disabled="isMandatory || (service.firstDeployOnly && wasDeployed)"
      :label="label[$i18n.locale]"
      rbt-target="wizardSectionContainer"
      class="my-0"
      hide-details
    />

    <template v-slot:child>
      <v-label v-if="service.widget">
        <div class="wrapLabel">
          <span>{{ service.widget.value }} {{ service.widget.unitLabel }}</span>
          <span>{{ service.widget.max }} {{ service.widget.unitLabel }}</span>
        </div>

        <v-slider
          v-model="service.widget.value"
          :min="service.widget.min"
          :max="service.widget.max"
          :step="service.widget.step || 1"
          :tick-size="2"
          hide-details
        />
      </v-label>
    </template>
  </nested-item>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import WizardWidgetMixin from "../mixin/WizardWidgetMixin"

export default defineComponent({
  name: "WizardSlider",
  mixins: [WizardWidgetMixin]
})
</script>
