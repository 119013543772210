import { Dictionary } from "@/types/core"
import { UUID, VIZ_NOW_ACCOUNT_ID } from "./config"

export interface ExternalLink {
  label: string
  link: string
}

export const vizNow: Dictionary<string> = {
  assumingIAMRole: "https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_use.html",
  creatingIAMRole: `https://console.aws.amazon.com/iam/home#/roles$new?step=tags&roleType=crossAccount&isThirdParty&awsAccount=${VIZ_NOW_ACCOUNT_ID}&externalID=${UUID}&policies=arn:aws:iam::aws:policy%2FAdministratorAccess&roleName=viz-now-manage&trustedEntityType=AWS_ACCOUNT`,
  creatingIAMRoleWithoutAdmin: `https://console.aws.amazon.com/iam/home#/roles$new?step=tags&roleType=crossAccount&isThirdParty&awsAccount=${VIZ_NOW_ACCOUNT_ID}&externalID=${UUID}&roleName=viz-now-manage&trustedEntityType=AWS_ACCOUNT`,
  administratorAccess:
    "https://console.aws.amazon.com/iam/home#/policies/arn:aws:iam::aws:policy/AdministratorAccess$serviceLevelSummary",
  niceDCV: "https://download.nice-dcv.com",
  DCVGuide: "https://docs.amazonaws.cn/en_us/dcv/latest/userguide/dcv-ug.pdf"
}

export const deployAgent: Dictionary<string> = {
  assumingIAMRole: "https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_use.html",
  spaceliftDocs: "https://docs.spacelift.io/",
  creatingIAMRole:
    "https://console.aws.amazon.com/iam/home#/roles$new?step=tags&roleType=crossAccount&isThirdParty&awsAccount=324880187172&externalID=SpaceliftIntegration@*&policies=arn:aws:iam::aws:policy%2FAdministratorAccess&roleName=viz-now-deploy-infra&trustedEntityType=AWS_ACCOUNT",
  creatingIAMWithoutAdmin:
    "https://console.aws.amazon.com/iam/home#/roles$new?step=tags&roleType=crossAccount&isThirdParty&awsAccount=324880187172&externalID=SpaceliftIntegration@*&roleName=viz-now-deploy-infra&trustedEntityType=AWS_ACCOUNT"
}
