<template>
  <div id="moreDetailsContainer">
    <div class="gridColumn animateFadeUp animationDelay-1">
      <region-label />

      <subscription-label />

      <ssl-label />

      <token-label />

      <ip-distribution />

      <dns-label />
    </div>

    <div class="gridColumn allowedIp">
      <div class="btnContainer">
        <v-btn @click="$emit('open:ip-config')">{{ t("toggleAuthorizedIPBtn") }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IWorkspaceAsset, WorkspaceTemplate } from "@/types/workspace"
import { defineComponent, PropType } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import RegionLabel from "./moreDetails/_RegionLabel.vue"
import SubscriptionLabel from "./moreDetails/_SubscriptionLabel.vue"
import SslLabel from "./moreDetails/_SslLabel.vue"
import TokenLabel from "./moreDetails/_TokenLabel.vue"
import IpDistribution from "./moreDetails/_IpDistribution.vue"
import DnsLabel from "./moreDetails/_DnsLabel.vue"

export default defineComponent({
  name: "MoreDetailsContainer",
  props: {
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup() {
    const t = createTranslationModule("TriCaster.boxFooter.moreInfo.")

    return {
      t
    }
  },
  components: {
    TokenLabel,
    RegionLabel,
    IpDistribution,
    SubscriptionLabel,
    DnsLabel,
    SslLabel
  }
})
</script>
