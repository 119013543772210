import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { ChangeLogs } from "@/types/messages"

const api = new Request("/v1/ChangeLog/")

export default createService("changeLog", {
  async getCurrentChangeLogs() {
    return await api.get<ChangeLogs>("")
  },

  async viewed() {
    return await api.post("Viewed/")
  },

  async releaseChangeLogs(changeLog: ChangeLogs) {
    return await api.post("", changeLog)
  },

  async resetViews() {
    return await api.post("ResetViews")
  }
})
