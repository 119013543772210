<template>
  <div id="terms">
    <Dialog
      v-model="showDialog"
      :title="$t('Terms.title')"
      max-width="700px"
      custom-class="termsWrap"
      hide-close-button
      persistent
      hide-actions
    >
      <template v-slot:activator="{ on, attrs }">
        <a v-bind="attrs" v-on="on">{{ $t("Terms.title") }}</a>
      </template>

      <privacy-policy-sheet />

      <term-form @confirm="confirmDialog" :loading="appRequestingData" />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "@vue/composition-api"
import { raiseSuccess } from "@/utils/event-bus"
import { UsersModule } from "@/store/users"
import PrivacyPolicySheet from "./components/PrivacyPolicySheet.vue"
import Dialog from "@/components/global/Dialog.vue"
import TermForm from "./components/TermForm.vue"
import "./scss/_terms.scss"

export default defineComponent({
  name: "TermsRoot",
  setup() {
    const showDialog = ref(false)
    const appRequestingData = ref(false)
    const usersStore = UsersModule()

    const shouldForceShowTerms = computed(() => {
      return usersStore.pendingAcceptPrivacyPolicy
    })

    const forceShowTerms = () => {
      if (shouldForceShowTerms.value) {
        showDialog.value = true
      }
    }

    const getDetails = async () => {
      try {
        appRequestingData.value = true

        await usersStore.getMyDetails()
        forceShowTerms()
      } finally {
        appRequestingData.value = false
      }
    }

    getDetails()

    const confirmDialog = async () => {
      try {
        appRequestingData.value = true

        await usersStore.acceptTerms()
        showDialog.value = false
        raiseSuccess()
      } finally {
        appRequestingData.value = false
      }
    }

    return {
      showDialog,
      appRequestingData,
      confirmDialog
    }
  },
  components: {
    PrivacyPolicySheet,
    TermForm,
    Dialog
  }
})
</script>
