<template>
  <div id="errorReport">
    <div class="messageContainer">
      <h1>
        <v-icon :size="32" color="error" left>status_error</v-icon>
        <span>{{ t("title") }}</span>
      </h1>

      <p>{{ t("possibleSolution") }}</p>

      <ul>
        <li v-html="t('invalidDotNow')" />
        <li v-html="t('retry')" />
        <li>{{ t("contactSupport") }}</li>
      </ul>

      <div class="errorReport">
        <v-text-field
          :value="message"
          @click:append="copyErrorMessage"
          readonly
          append-icon="mdi-content-copy"
          hide-details
        />
      </div>

      <div class="buttonWrapper">
        <v-btn
          v-if="canDestroySpace"
          @click="confirmDestroy"
          :loading="isLoading(ButtonIndex.DESTROY)"
          :disabled="loading"
          color="error"
          large
        >
          {{ t("destroySpace") }}
        </v-btn>

        <v-btn @click="reloadPage" :loading="isLoading(ButtonIndex.RETRY)" :disabled="loading" color="primary" large>
          {{ t("retryButton") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from "@vue/composition-api"
import { copyToClipboard } from "@/utils/ui"
import { translate } from "@/plugins/i18n"
import { raiseConfirmation } from "@/utils/event-bus"
import CopyBtn from "@/components/copyBtn"
import router from "@/router"
import "./error-report.scss"

enum ButtonIndex {
  RETRY = 1,
  DESTROY = 2
}

export default defineComponent({
  name: "ErrorReport",
  props: {
    message: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const t = (snippet: string) => translate("TriCaster.errorPage." + snippet)
    const buttonLoading = ref(false)
    const buttonIndex = ref(-1)

    const canDestroySpace = computed(() => {
      return false
    })

    const reloadPage = () => {
      buttonIndex.value = ButtonIndex.RETRY
      buttonLoading.value = true
      router.go(0)
    }

    const confirmDestroy = async () => {
      buttonIndex.value = ButtonIndex.DESTROY

      if (await raiseConfirmation({ text: t("confirmDestroy"), title: t("confirmDestroyTitle") })) {
        ctx.emit("destroy:space")
      }
    }

    const isLoading = (btnIndex: number) => {
      if (btnIndex === ButtonIndex.RETRY) {
        return buttonIndex.value === btnIndex
      } else {
        return props.loading && buttonIndex.value === btnIndex
      }
    }

    const copyErrorMessage = () => {
      copyToClipboard(props.message)
    }

    return {
      ButtonIndex,
      t,
      canDestroySpace,
      buttonIndex,
      isLoading,
      confirmDestroy,
      copyErrorMessage,
      buttonLoading,
      reloadPage
    }
  },
  components: {
    CopyBtn
  }
})
</script>
