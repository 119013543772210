<template>
  <div class="instructions">
    <Dialog :title="$t('WorkspaceEdit.instructions.title')" max-width="60vw" instruction hide-actions>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" rbt-target="openInstruction" large>
          <v-icon left>vizartist_show_scene_script</v-icon>
          <span>{{ $t("WorkspaceEdit.instructions.buttons.open") }}</span>
        </v-btn>
      </template>

      <template v-slot:root>
        <div id="instructionsModal">
          <div class="instructionWrapper">
            <div class="editorWrap" v-if="instructionsEditorActive">
              <html-editor v-model="tempInstruction" />
            </div>

            <div v-else class="wrapContent">
              <v-progress-linear v-if="loading" color="primary" class="my-0" indeterminate />

              <html-viewer :content="instructionMsg" overflow-auto />
            </div>
          </div>

          <div v-if="canEditPermission" class="instructionController">
            <div v-if="instructionsEditorActive" class="editingControllers">
              <v-btn @click="cancel" large>
                <v-icon left>action_remove_circle_outline</v-icon>
                <span>{{ $t("WorkspaceEdit.instructions.buttons.cancel") }}</span>
              </v-btn>

              <v-btn @click="save" color="primary" rbt-target="saveInstructions" large>
                <v-icon left>action_save</v-icon>
                <span>{{ $t("WorkspaceEdit.instructions.buttons.save") }}</span>
              </v-btn>
            </div>

            <v-btn
              v-else
              @click="instructionsEditorActive = true"
              :disabled="loading"
              rbt-target="editInstructions"
              large
            >
              <v-icon left>action_edit</v-icon>
              <span>{{ $t("WorkspaceEdit.instructions.buttons.edit") }}</span>
            </v-btn>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, computed } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import HtmlEditor from "@/components/htmlEditor/HtmlEditor.vue"
import HtmlViewer from "@/components/htmlEditor/HtmlViewer.vue"
import { translate } from "@/plugins/i18n"
import { UsersModule } from "@/store/users"
import Dialog from "@/components/global/Dialog.vue"

export default defineComponent({
  name: "Instructions",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const tempInstruction = ref(props.workspace.instructions)
    const instructionsEditorActive = ref(false)
    const usersModule = UsersModule()

    const canEditPermission = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const instructionMsg = computed(() => {
      return props.workspace.instructions || translate("WorkspaceEdit.instructions.label.empty")
    })

    const cancel = () => {
      tempInstruction.value = props.workspace.instructions
      instructionsEditorActive.value = false
    }

    const save = () => {
      ctx.emit("save", tempInstruction.value)
      instructionsEditorActive.value = false
    }

    return {
      canEditPermission,
      cancel,
      tempInstruction,
      instructionMsg,
      save,
      instructionsEditorActive
    }
  },
  components: {
    Dialog,
    HtmlViewer,
    HtmlEditor
  }
})
</script>
