import { translate } from "@/plugins/i18n"
import { OrganizationType } from "@/types/organization"

export const organizationTypeOptions = () => {
  return [
    {
      text: translate("organizationTypes.CustomerHosted"),
      value: OrganizationType.CustomerHosted
    },
    {
      text: translate("organizationTypes.SaaS"),
      value: OrganizationType.SaaS
    }
  ]
}
