import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { FeedbackItem, FeedbackPayload } from "@/types/feedback"

const api = new Request("/v1/Feedbacks/")

export default createService("feedback", {
  async send(payload: FeedbackPayload) {
    return await api.post<FeedbackPayload>("", payload)
  },

  async getFeedbacks() {
    return await api.get<FeedbackItem[]>("")
  },

  async resolveFeedback(feedback: FeedbackItem) {
    return await api.post(`${feedback.id}/Solve`, feedback.solved)
  }
})
