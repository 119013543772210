import { WorkspaceTemplate, WorkspaceErrorLevelEnum } from "@/types/workspace"
import { computed } from "@vue/composition-api"

interface GridCardSetup {
  workspace: WorkspaceTemplate
  error?: boolean
  shared?: boolean
}

const DEFAULTS = {
  icons: {
    error: "status_error",
    warning: "status_warning",
    shared: "multimedia_rubberband"
  },
  iconColor: {
    error: "error",
    warning: "warning",
    shared: ""
  }
}

const gridCardSetup = (props: GridCardSetup) => {
  const _errorLevel = props.workspace.lastErrorLevel

  if (!props.error && !props.shared) {
    throw Error("Expected to be error/warning or shared icon")
  }

  const _isError = computed(() => {
    return _errorLevel === WorkspaceErrorLevelEnum.Error
  })

  const _isWarning = computed(() => {
    return _errorLevel === WorkspaceErrorLevelEnum.Warning
  })

  const showIcon = computed(() => {
    if (props.shared) {
      return props.workspace.isShared
    }

    return _isError.value || _isWarning.value
  })

  const feedbackMessage = computed(() => {
    const { error, shared } = props
    const hasWorkspaceError = Boolean(_errorLevel)

    if (error && hasWorkspaceError) {
      return props.workspace.lastErrorTitle || "Something went wrong"
    }
    if (shared && props.workspace.isShared) {
      return "Shared"
    }

    return ""
  })

  const icon = computed(() => {
    const { error, shared } = props

    if (error && (_isError.value || _isWarning.value)) {
      if (_isError.value) {
        return DEFAULTS.icons.error
      }
      if (_isWarning.value) {
        return DEFAULTS.icons.warning
      }
    } else if (shared && showIcon.value) {
      return DEFAULTS.icons.shared
    }

    return ""
  })

  const iconColor = computed(() => {
    switch (icon.value) {
      case DEFAULTS.icons.error:
        return DEFAULTS.iconColor.error
      case DEFAULTS.icons.warning:
        return DEFAULTS.iconColor.warning
      case DEFAULTS.icons.shared:
        return DEFAULTS.iconColor.shared
      default:
        return ""
    }
  })

  return {
    feedbackMessage,
    showIcon,
    icon,
    iconColor
  }
}

export default gridCardSetup
