import { TabItem } from "./type"
import { translate } from "@/plugins/i18n"
import { UserRoleEnum } from "@/types/user"
import { getRoleImportance } from "@/utils/role-importance"
import Organization from "../components/organizations/OrganizationRoot.vue"
import SpaceTemplate from "../components/spaceTemplates/SpaceTemplateRoot.vue"
import DeployPackage from "../components/deployPackage/DeployPackageRoot.vue"
import Feedback from "../components/feedback/FeedbackRoot.vue"
import PrivacyPolicy from "../components/privacyPolicy/PrivacyRoot.vue"
import ChangeLog from "../components/changeLog/ChangeLogRoot.vue"
import SystemMessages from "../components/systemMessages/SystemMessagesRoot.vue"
import AssetHandler from "../components/assetHandler"
import Utils from "@/utils/utils"

const { SysAdmin, Support } = UserRoleEnum

const is = (myRole: UserRoleEnum, targetRole: UserRoleEnum) => {
  return getRoleImportance(myRole) >= getRoleImportance(targetRole)
}

const tabItems: (myRole: UserRoleEnum) => TabItem[] = myRole => [
  ...Utils.insertIf(is(myRole, Support), {
    label: translate("Admin.tabOptions.organization"),
    value: "organizations",
    component: Organization
  }),
  ...Utils.insertIf(is(myRole, Support), {
    label: translate("Admin.tabOptions.spaceTemplates"),
    value: "space-template",
    component: SpaceTemplate
  }),
  ...Utils.insertIf(is(myRole, SysAdmin), {
    label: translate("Admin.tabOptions.deployPackage"),
    value: "deploy-package",
    component: DeployPackage
  }),
  ...Utils.insertIf(is(myRole, Support), {
    label: translate("Admin.tabOptions.feedback"),
    value: "feedback",
    component: Feedback
  }),
  ...Utils.insertIf(is(myRole, SysAdmin), {
    label: translate("Admin.tabOptions.privacyPolicy"),
    value: "privacy-policy",
    component: PrivacyPolicy
  }),
  ...Utils.insertIf(is(myRole, SysAdmin), {
    label: translate("Admin.tabOptions.changeLog"),
    value: "change-log",
    component: ChangeLog
  }),
  ...Utils.insertIf(is(myRole, SysAdmin), {
    label: translate("Admin.tabOptions.systemMessages"),
    value: "system-messages",
    component: SystemMessages
  }),
  ...Utils.insertIf(is(myRole, SysAdmin), {
    label: translate("Admin.tabOptions.assetHandler"),
    value: "asset-handler",
    component: AssetHandler
  })
]

export default tabItems
