import { Rule } from "./input-rules"

/**
 * This helper function is used to avoid having useless input detail margin
 * you can add it in a component as :hide-details="hideDetails(rule, value)"
 *
 * @param rules Should be used with rules from input-rules
 * @param value Should be the v-model prop
 * @returns boolean
 *
 * @example
 * ```typescript
 *  hideDetails(MandatoryTextField, "12"): false
 *  hideDetails(MandatoryTextField, "1234"): true
 * ```
 *
 * ```html
 * <!-- vue component -->
 * <v-text-field v-model="value" :hide-details="hideDetails(MandatoryTextField, value)" />
 * ```
 */
export const hideDetails = <T = string | null>(rules: Rule<T>[], value: T) => {
  if (!rules || rules.length === 0) {
    throw "Invalid rules format, expected Rule[]"
  }

  return rules.some(rule => typeof rule(value) !== "string")
}
