<template>
  <div class="categoriesCollection">
    <span
      v-for="category in categoryItems"
      @click="selectCategory(category.key)"
      :key="category.key"
      :selected="isSelected(category.key)"
      class="categoryItem"
    >
      <span>{{ tagPattern(category.key) }}</span>
      <span class="counter">{{ category.items.length }}</span>
    </span>
  </div>
</template>

<script lang="ts">
import Utils from "@/utils/utils"
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { Dictionary } from "@/types/core"
import { CategoryItem } from "../../../lib/types"

export default defineComponent({
  name: "Categories",
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true
    },
    items: {
      type: Object as PropType<Dictionary<CategoryItem>>,
      required: true
    }
  },
  setup(props, ctx) {
    const selectedCategories = Utils.vModel(props, ctx.emit)

    const categoryItems = computed(() => {
      return Object.values(props.items)
    })

    const selectCategory = (category: string) => {
      const categoryIndex = selectedCategories.value.indexOf(category)

      if (categoryIndex === -1) {
        selectedCategories.value.push(category)
      } else {
        selectedCategories.value.splice(categoryIndex, 1)
      }

      ctx.emit("change")
    }

    const isSelected = (category: string) => {
      return selectedCategories.value.includes(category)
    }

    const tagPattern = (category: string) => {
      if (category) {
        return category.replace(/[-]/, " ")
      }

      return ""
    }

    return {
      categoryItems,
      tagPattern,
      isSelected,
      selectCategory
    }
  }
})
</script>
