<template>
  <div id="organizationRoot">
    <v-progress-linear v-if="contentLoader.on" color="primary" class="my-0 py-0" indeterminate />

    <div class="backgroundWrapper">
      <h1>{{ $t("Admin.organizations.summary") }}</h1>

      <div class="headerContainer">
        <div class="wrap">
          <search v-model="filterSettings.search" icon="mdi-filter-outline" />
          <org-filter />
        </div>

        <v-btn
          v-if="hasGodLevelPermissions"
          @click="isCreatingOrganization = true"
          :disabled="contentLoader.on"
          rbt-target="btnCreateNewOrganization"
          color="primary"
          large
        >
          <v-icon left>action_add</v-icon>
          <span>{{ $t("Admin.organizations.createPopup.buttonLabel") }}</span>
        </v-btn>
      </div>

      <edit-organization-popup
        v-if="isEditingOrganization"
        v-model="isEditingOrganization"
        @save:workspace-template="saveTemplateChanges"
        @save:organization-name="editOrganizationName"
        @add:token="id => manageTokenPopup('add', id)"
        @remove:token="id => manageTokenPopup('remove', id)"
        @edit:subscription="id => editSubscription(id)"
        @update:organization="updateOrganizationCollection"
        :organization-logo-collection="organizationLogoCollection"
        :loading="contentLoader.on"
      />

      <edit-token-transaction
        v-if="transactionBeingEdited.isOpen"
        v-model="transactionBeingEdited"
        @update:wallet="updateWallet"
      />

      <edit-subscription
        v-if="subscriptionEdit.open"
        v-model="subscriptionEdit"
        :key="subscriptionEdit.id"
        @update:organization="refreshOrganizationDetails"
      />

      <create-organization-popup
        v-if="isCreatingOrganization"
        v-model="isCreatingOrganization"
        @close="closeCreateOrganizationPopup"
        :loading="contentLoader.on"
      />

      <table-organization
        @edit="editOrganization"
        @delete="removeOrganization"
        :items="filteredOrganizations"
        :loading="contentLoader.on"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive, nextTick } from "@vue/composition-api"
import { AdminModule, UploadModule, OrganizationModule, ConfigurationModule, UsersModule } from "@/store"
import { IMedia } from "@/types/media"
import { raiseSuccess } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { filterSetupInstance } from "./lib/org-filter"
import Search from "@/components/search/Search.vue"
import OrgFilter from "./_OrgFilter.vue"
import Loader from "@/utils/loader"
import TableOrganization from "./_TableOrganization.vue"
import EditOrganizationPopup from "./_EditOrganizationPopup.vue"
import CreateOrganizationPopup from "./_CreateOrganizationPopup.vue"
import EditTokenTransaction from "./_EditTokenTransaction.vue"
import EditSubscription from "./_EditSubscription.vue"

export default defineComponent({
  name: "OrganizationRoot",
  setup() {
    const adminModule = AdminModule()
    const uploadModule = UploadModule()
    const usersModule = UsersModule()
    const configurationModule = ConfigurationModule()
    const organizationModule = OrganizationModule()
    const contentLoader = Loader()
    const isEditingOrganization = ref(false)
    const isCreatingOrganization = ref(false)
    const organizationLogoCollection = ref<IMedia[]>([])

    const subscriptionEdit = reactive({
      id: "",
      open: false
    })

    const transactionBeingEdited = reactive({
      transactionId: "",
      action: "add",
      isOpen: false
    })

    const hasGodLevelPermissions = computed(() => {
      return usersModule.hasGodPermissions
    })

    const organizations = computed(() => {
      return adminModule.organizationCollection
    })

    const manageTokenPopup = (action: "add" | "remove", transactionId: string) => {
      transactionBeingEdited.transactionId = transactionId
      transactionBeingEdited.isOpen = true
      transactionBeingEdited.action = action
    }

    const getOrganizationLogoCollection = async (organizationId: string) => {
      const organizationFileList = await uploadModule.getContextId({
        contextId: organizationId,
        context: "organization-logo",
        ignoreExhibition: false
      })

      organizationLogoCollection.value = organizationFileList
    }

    const saveTemplateChanges = (organizationWorkspaceTemplates: string[]) => {
      contentLoader.run(async () => {
        const organizationId = adminModule.organizationDetail.id
        await adminModule.updateWorkspaceTemplate(organizationWorkspaceTemplates, organizationId)
        await adminModule.getSimpleOrganizationCollection()
      })
    }

    const removeOrganization = (organizationId: string) => {
      contentLoader.run(async () => {
        await adminModule.deleteOrganization(organizationId)
        await adminModule.getOrganizationCollection()
        await adminModule.getSimpleOrganizationCollection()

        filterSetupInstance.setOrganizationCollection(organizations.value)
      })
    }

    const updateWallet = (organizationId: string) => {
      contentLoader.run(async () => {
        await organizationModule.getWallet(organizationId)
        await adminModule.getOrganizationDetail(organizationId)
      })
    }

    const editSubscription = (id: string) => {
      subscriptionEdit.id = id
      subscriptionEdit.open = true
    }

    const refreshOrganizationDetails = () => {
      updateWallet(subscriptionEdit.id)
    }

    const editOrganization = async (organizationId: string) => {
      contentLoader.run(async () => {
        await adminModule.getOrganizationDetail(organizationId)
        await organizationModule.getWallet(organizationId)
        await getOrganizationLogoCollection(organizationId)
        isEditingOrganization.value = true
      })
    }

    const closeCreateOrganizationPopup = (organizationId: string) => {
      contentLoader.run(async () => {
        await adminModule.getOrganizationCollection()
        await adminModule.getSimpleOrganizationCollection()
        isCreatingOrganization.value = false
        filterSetupInstance.setOrganizationCollection(organizations.value)

        raiseSuccess({ text: translate("Admin.organizations.createPopup.organizationCreatedAlert") })

        nextTick(() => {
          editOrganization(organizationId)
        })
      })
    }

    const updateOrganizationCollection = () => {
      contentLoader.run(async () => {
        await adminModule.getOrganizationCollection()
        filterSetupInstance.setOrganizationCollection(organizations.value)
      })
    }

    const editOrganizationName = (organizationName: string, organizationId: string) => {
      contentLoader.run(async () => {
        await adminModule.changeOrganizationName(organizationName, organizationId)
        await adminModule.getOrganizationCollection()
        await adminModule.getOrganizationDetail(organizationId)
        await adminModule.getSimpleOrganizationCollection()

        filterSetupInstance.setOrganizationCollection(organizations.value)
      })
    }

    contentLoader.run(async () => {
      await adminModule.getOrganizationCollection()
      await adminModule.getAllWorkspaceTemplates()
      await configurationModule.getSubscriptionTypes()

      filterSetupInstance.setOrganizationCollection(organizations.value)
    })

    return {
      hasGodLevelPermissions,
      isCreatingOrganization,
      isEditingOrganization,
      contentLoader,
      organizations,
      organizationLogoCollection,
      transactionBeingEdited,
      closeCreateOrganizationPopup,
      subscriptionEdit,
      refreshOrganizationDetails,
      editSubscription,
      updateOrganizationCollection,
      updateWallet,
      manageTokenPopup,
      editOrganizationName,
      saveTemplateChanges,
      editOrganization,
      getOrganizationLogoCollection,
      removeOrganization,
      ...filterSetupInstance
    }
  },
  components: {
    Search,
    OrgFilter,
    EditSubscription,
    EditTokenTransaction,
    TableOrganization,
    CreateOrganizationPopup,
    EditOrganizationPopup
  }
})
</script>
