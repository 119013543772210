<template>
  <tooltip :text="workspaceWorkingSince">
    <div class="runningSince" :key="now">{{ upTime }}</div>
  </tooltip>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api"
import { translate, translatePlural } from "@/plugins/i18n"
import Utils from "@/utils/utils"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "RunningFor",
  props: {
    runningSince: {
      type: [String, Number, Date],
      default: null
    }
  },
  setup(props) {
    const now = ref(Date.now())

    const upTime = computed(() => {
      if (props.runningSince) {
        const { days, min, hours } = Utils.dateRange(props.runningSince, now.value)

        if (days > 0) {
          return `${days} ${translatePlural("CertificateWarning.day", days)}`
        } else if (![hours, min].includes(-1)) {
          const _hour = `${hours} h`
          const _min = `${min} min`

          return hours > 0 ? `${_hour} ${_min}` : _min
        }
      }

      return ""
    })

    const workspaceWorkingSince = computed(() => {
      return translate("CertificateWarning.onlineSince", { date: Utils.dateFormatWithTime(props.runningSince) })
    })

    const _updateTime = () => {
      setTimeout(() => {
        now.value = Date.now()
        _updateTime()
      }, 10000)
    }

    _updateTime()

    return {
      now,
      workspaceWorkingSince,
      upTime
    }
  },
  components: {
    Tooltip
  }
})
</script>
