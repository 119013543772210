<template>
  <div id="toolbar">
    <search-input v-model="config.search" @change="$emit('change')" @input.native="$emit('change')" />

    <v-select
      v-model="config.filter"
      @change="$emit('change')"
      :items="filterOptions"
      prepend-inner-icon="action_filter"
      hide-details
    />

    <v-checkbox
      v-model="config.showResolved"
      :label="$t('Admin.feedback.showResolved')"
      class="my-0 py-0"
      hide-details
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { ToolbarConfig } from "../lib/type"
import SearchInput from "@/components/search/Search.vue"
import FilterOptions from "../lib/feedback-filter-type"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "FeedbackToolbar",
  props: {
    value: {
      type: Object as PropType<ToolbarConfig>,
      required: true
    }
  },
  setup(props, ctx) {
    const config = Utils.vModel(props, ctx.emit)

    const filterOptions = computed(() => {
      return FilterOptions()
    })

    return {
      config,
      filterOptions
    }
  },
  components: {
    SearchInput
  }
})
</script>
