import Vue from "vue"
import app from "@/main"
import axios from "axios"
import VueI18n, { LocaleMessages } from "vue-i18n"
import { Dictionary } from "@/types/core"
import { enUS as dateFnsEnUs, pt as dateFnsEnPt } from "date-fns/locale"

Vue.use(VueI18n)

export const locales: Dictionary<string> = {
  en: "English",
  pt: "Português (PT)"
}

const loadLocaleMessages = (): LocaleMessages => {
  const locales = require.context("@/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) messages[matched[1]] = locales(key)
  })

  return messages
}

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages()
})

export const getDateDnsLocale = (): Locale => {
  switch (i18n.locale) {
    case "pt":
      return dateFnsEnPt
    case "en":
    default:
      return dateFnsEnUs
  }
}

export const changeLocale = (locale: string): void => {
  i18n.locale = locale
  app.$vuetify.lang.current = locale
  document.documentElement.lang = locale

  if (axios.defaults.headers) {
    axios.defaults.headers.common["Accept-Language"] = locale
  }

  window.localStorage.setItem("locale", locale)
}

export const setDefaultLocale = (): void => {
  const availableLocales = Object.keys(locales)
  const localLang = window.localStorage.getItem("locale")

  if (localLang && Object.keys(locales).includes(localLang)) {
    changeLocale(localLang)
  } else if (availableLocales.includes(navigator.language)) {
    changeLocale(navigator.language)
  } else if (availableLocales.includes(navigator.language.substring(0, 2))) {
    changeLocale(navigator.language.substring(0, 2))
  }
}

export default i18n

export type TranslateArgs = Dictionary<string | number>

export const translate = (snippet: string, options: TranslateArgs = {}): string => {
  return i18n.t(snippet, options) as string
}

export const translatePlural = (snippet: string, plural: number, options: TranslateArgs = {}): string => {
  return i18n.tc(snippet, plural, options)
}

/**
 * Faster way to use translation modules, without having to
 * create a function with prefix snippet every time.
 *
 * @example
 * const t = createTranslationModule("Common.popups.")
 *
 * const popupTitle = t("createOrganization") // Common.popups.createOrganization
 */
export const createTranslationModule = (translationModule: string) => {
  const moduleWithDot = translationModule.slice(-1) === "." ? translationModule : `${translationModule}.`
  return (snippet: string, args?: Dictionary<string | number>, plural?: number) =>
    typeof plural === "number"
      ? translatePlural(moduleWithDot + snippet, plural, args)
      : translate(moduleWithDot + snippet, args)
}
