<template>
  <div class="simpleTextEdit">
    <v-form v-if="isEditingText" v-model="isValidForm" @submit.prevent class="wrapControllers">
      <v-text-field v-model="tempText" @keypress.enter="saveText" :rules="useRules" hide-details dense />

      <v-btn
        v-if="canEdit"
        @click="saveText"
        :disabled="!isValidForm || !hasChange || disabled"
        :loading="loading"
        rbt-target="saveChanges"
        icon
      >
        <v-icon>action_save</v-icon>
      </v-btn>

      <v-btn @click="isEditingText = false" :disabled="disabled" rbt-target="discardChanges" icon>
        <v-icon>action_close</v-icon>
      </v-btn>
    </v-form>

    <div v-else class="wrapText">
      <span v-if="prefix">{{ prefix }}</span>
      <span>{{ text }}</span>
      <span v-if="suffix">{{ suffix }}</span>

      <v-btn
        v-if="canEdit"
        @click="startEditing"
        :disabled="disabled"
        :loading="loading"
        :class="alwaysVisible ? 'alwaysVisible' : ''"
        icon
      >
        <v-icon>action_edit</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { MinLengthTextField, Rule } from "@/utils/input-rules"
import "./scss/_simpleTextEdit.scss"

export default defineComponent({
  name: "SimpleTextEdit",
  emits: ["save"],
  props: {
    text: {
      type: [String, Number],
      required: true
    },
    minLength: {
      type: Number,
      default: 2
    },
    alwaysVisible: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array as PropType<Rule[]>,
      default: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: [String, Boolean],
      default: false
    },
    prefix: {
      type: [String, Boolean],
      default: false
    }
  },
  setup(props, ctx) {
    const isValidForm = ref(false)
    const isEditingText = ref(false)
    const tempText = ref(props.text)

    const useRules = computed(() => {
      if (props.rules.length) {
        return props.rules
      }
      return MinLengthTextField(props.minLength)
    })

    const hasChange = computed(() => {
      return tempText.value !== props.text
    })

    const saveText = () => {
      if (hasChange.value && isValidForm.value) {
        ctx.emit("save", tempText.value)
        isEditingText.value = false
      }
    }

    const startEditing = () => {
      isEditingText.value = true
      tempText.value = props.text
    }

    return {
      hasChange,
      isValidForm,
      tempText,
      isEditingText,
      useRules,
      saveText,
      startEditing
    }
  }
})
</script>
