<template>
  <div class="assetForm">
    <div class="formRow">
      <h3>{{ t("basicInformation") }}</h3>

      <div class="formItem">
        <div v-if="structure.id" class="inputLabel">
          <span>ID</span>
          <v-text-field v-model="structure.id" disabled dense hide-details />
        </div>

        <div class="inputLabel">
          <span>
            {{ t("assetTitle") }}
            <span class="mandatorySign">*</span>
          </span>
          <v-text-field v-model="structure.text" :rules="rules.MANDATORY" dense hide-details />
        </div>

        <div class="inputLabel">
          <span>
            {{ t("instancePlatform") }}
          </span>

          <v-select v-model="structure.instancePlatform" :items="instancePlatformItems" clearable dense hide-details />
        </div>
      </div>
    </div>

    <div class="formRow">
      <div class="formItem">
        <div class="inputLabel">
          <span>
            {{ t("key") }}
            <span class="mandatorySign">*</span>
          </span>
          <v-text-field v-model="structure.key" :rules="rules.MANDATORY" dense hide-details />
        </div>

        <div class="inputLabel">
          <span>{{ t("costCode") }}</span>
          <v-text-field v-model="structure.costCode" dense hide-details />
        </div>

        <div class="inputLabel">
          <span>
            {{ t("icon") }}
            <span class="mandatorySign">*</span>
          </span>
          <v-combobox
            v-model="structure.icon"
            :items="iconCollection"
            :rules="rules.COMBOBOX"
            :return-object="false"
            item-value="value"
            item-text="text"
            dense
            hide-details
          />
        </div>
      </div>
    </div>

    <div class="formRow">
      <div class="formItem">
        <div class="inputLabel">
          <span>
            {{ t("category") }}
            <span class="mandatorySign">*</span>
          </span>

          <v-combobox
            v-model="structure.category"
            :rules="rules.MANDATORY_SELECT"
            :items="categoryList"
            dense
            hide-details
          />
        </div>

        <div class="inputLabel">
          <span>{{ t("manualUrl") }}</span>
          <v-text-field v-model="structure.manualUrl" dense hide-details />
        </div>

        <div class="inputLabel">
          <span>
            {{ t("amiName") }}
            <span class="mandatorySign">*</span>
          </span>
          <v-text-field v-model="structure.amiName" :rules="rules.MANDATORY" dense hide-details />
        </div>
      </div>
    </div>

    <div class="formRow">
      <div class="formItem">
        <div class="inputLabel">
          <span>{{ t("description") }}</span>
          <v-textarea v-model="structure.description" dense hide-details />
        </div>
      </div>
    </div>

    <div v-if="structure.extraInformation" class="formRow">
      <h3>{{ t("extraInformation") }}</h3>

      <div class="formItem">
        <div class="inputLabel">
          <span>{{ t("estimatedCost") }}</span>
          <v-text-field v-model="structure.extraInformation.estimatedCost" dense hide-details />
        </div>

        <div class="inputLabel">
          <span>{{ t("input") }}</span>
          <v-text-field v-model="structure.extraInformation.inputs" dense hide-details />
        </div>

        <div class="inputLabel">
          <span>{{ t("output") }}</span>
          <v-text-field v-model="structure.extraInformation.outputs" dense hide-details />
        </div>
      </div>
    </div>

    <div class="formRow">
      <v-checkbox v-model="structure.requireLicenseServer" :label="t('requireLicenseServer')" hide-details />
    </div>

    <div class="formRow">
      <div class="formItem">
        <v-checkbox v-model="structure.installCrowdStrike" :label="t('installCrowdStrike')" hide-details />
      </div>
    </div>

    <div class="formRow">
      <div class="formItem">
        <v-checkbox v-model="structure.fileTransferElegible" :label="t('fileTransferEligible')" hide-details />
      </div>
    </div>

    <div v-if="structure.fileTransferElegible" class="formRow groupType animateFadeUp">
      <h3>{{ t("fileTransferConfig") }}</h3>

      <div class="groupWrapper">
        <div
          v-for="(folder, index) in fileTransferConfigFolders"
          :key="`folder-${index}`"
          class="item multiStructure animateFadeUp"
        >
          <div class="inputLabel">
            <span>
              {{ t("mountDrive") }}
              <span class="mandatorySign">*</span>
            </span>
            <v-text-field v-model="folder.mountDrive" :rules="rules.MANDATORY" dense hide-details />
          </div>

          <div class="inputLabel">
            <span>
              {{ t("name") }}
              <span class="mandatorySign">*</span>
            </span>
            <v-text-field v-model="folder.name" :rules="rules.MANDATORY" dense hide-details />
          </div>

          <div class="inputLabel">
            <span>
              {{ t("type") }}
            </span>

            <div class="multiStructure">
              <v-text-field v-model="folder.type" :rules="rules.MANDATORY" disabled dense hide-details />
              <v-icon v-if="index % 2 === 1" @click="removeFolder(index)">action_trash</v-icon>
            </div>
          </div>
        </div>
      </div>

      <v-btn @click="addFileTransferFolder">
        <v-icon left>action_add_box</v-icon>
        <span>{{ t("addFolder") }}</span>
      </v-btn>
    </div>

    <div class="formRow groupType">
      <h3>{{ t("instanceType") }}</h3>

      <div class="groupWrapper">
        <div
          v-for="(instance, index) in structure.availableInstanceTypes"
          :key="`instanceType-${index}`"
          class="item animateFadeUp"
        >
          <div class="inputLabel">
            <div class="inputWrap">
              <span>AWS</span>
            </div>
          </div>

          <div class="nestedElements">
            <div class="inputLabel">
              <span v-if="instance.instanceTypes.length">
                {{ t("code") }}
                <span class="mandatorySign">*</span>
              </span>

              <div
                v-for="(instanceType, codeIndex) in instance.instanceTypes"
                :key="`code-${index}-${codeIndex}`"
                class="inputWrap"
              >
                <v-text-field
                  v-model="instanceType.code"
                  :rules="rules.MANDATORY"
                  :placeholder="t('code')"
                  dense
                  hide-details
                />

                <div class="itemController">
                  <tooltip :text="t('defaultInstanceTooltip')">
                    <v-checkbox
                      @change="setDefaultInstanceTypeDefinition(instanceType)"
                      :input-value="isDefaultInstanceType(instanceType)"
                      :disabled="disableToSetDefaultInstanceType(instanceType)"
                      hide-details
                    />
                  </tooltip>
                  <v-icon @click="removeCode(instance, codeIndex)">action_trash</v-icon>
                </div>
              </div>

              <v-btn @click="addCode(instance.instanceTypes)">
                <v-icon left>action_add_box</v-icon>
                <span>{{ t("addCodeLine") }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="formRow" v-if="false">
      <div class="formItem">
        <div class="inputLabel">
          <span>{{ t("userConfiguration") }}</span>
          <v-textarea v-model="userConfigurations" dense hide-details />
        </div>
      </div>
    </div>

    <div class="formRow">
      <h3>{{ t("defaultApplicationLinks") }}</h3>

      <div
        v-for="(application, index) in structure.defaultApplicationLinks"
        :key="`applicationLinks-${index}-${refreshKey}`"
        class="formItem twoPerRow"
      >
        <div class="inputLabel">
          <span>
            {{ t("label") }}
            <span class="mandatorySign">*</span>
          </span>
          <v-text-field v-model="application.label" :rules="rules.MANDATORY" dense hide-details />
        </div>

        <div class="inputLabel">
          <span>
            {{ t("link") }}
            <span class="mandatorySign">*</span>
          </span>

          <div class="inputWrap">
            <v-text-field v-model="application.link" :rules="rules.MANDATORY" dense hide-details />

            <v-icon
              @click="addApplicationLinkCredential(application)"
              :disabled="disableImplementCredential(application)"
            >
              {{ appLinkHasCredential(application) ? "mdi-key" : "mdi-key-outline" }}
            </v-icon>

            <v-icon @click="removeApplicationLink(index)">action_trash</v-icon>
          </div>
        </div>

        <div v-if="appLinkHasCredential(application)" class="inputLabel">
          <span>
            {{ t("username") }}
            <span class="mandatorySign">*</span>
          </span>

          <div class="inputWrap">
            <v-combobox
              v-model="getCredentials(application).username"
              @input="updateCredentialTypes"
              :rules="rules.MANDATORY"
              :items="credentialTypes"
              dense
              hide-details
            />
          </div>
        </div>

        <div v-if="appLinkHasCredential(application)" class="inputLabel">
          <span>
            {{ t("password") }}
            <span class="mandatorySign">*</span>
          </span>

          <div class="inputWrap">
            <v-combobox
              v-model="getCredentials(application).password"
              @input="updateCredentialTypes"
              :rules="rules.MANDATORY"
              :items="credentialTypes"
              dense
              hide-details
            />
          </div>
        </div>
      </div>

      <v-btn @click="addApplicationLink">
        <v-icon left>action_add_box</v-icon>
        <span>{{ t("addApplicationLink") }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { MandatorySelect, MandatoryTextField } from "@/utils/input-rules"
import { SelectItem } from "@/types/core"
import { newFolderItem } from "./lib/new-structure"
import { createTranslationModule } from "@/plugins/i18n"
import { InstancePlatform } from "@/types/workspace"
import Utils from "@/utils/utils"
import Loader from "@/utils/loader"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

import type {
  WorkspaceApplication,
  Code,
  TypeOfInstance,
  ApplicationLink,
  ApplicationLinkCredential
} from "@/types/asset"

const validateCombobox = (value: string, iconCollection: SelectItem<string>[]) => {
  return iconCollection.some(icon => icon.value === value) || "Invalid"
}

export default defineComponent({
  name: "AssetForm",
  props: {
    value: {
      type: Object as PropType<WorkspaceApplication>,
      required: true
    },
    categoryList: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const structure = Utils.vModel(props, ctx.emit)
    const loader = Loader()
    const iconCollection = ref<SelectItem<string>[]>([])
    const refreshKey = ref(0)
    const t = createTranslationModule("Admin.assetHandler.popupForm")

    const rules = {
      MANDATORY: MandatoryTextField,
      MANDATORY_SELECT: MandatorySelect,
      COMBOBOX: [(value: string) => validateCombobox(value, iconCollection.value)]
    }

    const userConfigurations = computed({
      get() {
        if (structure.value.userConfigurations) {
          return JSON.stringify(structure.value.userConfigurations)
        } else {
          return "[]"
        }
      },
      set(value: string) {
        structure.value.userConfigurations = JSON.parse(value)
      }
    })

    const instancePlatformItems = computed(() => {
      return Object.values(InstancePlatform)
    })

    const fileTransferConfigFolders = computed(() => {
      return structure.value.fileTransferConfig?.folders ?? []
    })

    const credentialTypes = computed(() => {
      return structure.value.userConfigurations?.map(config => config.resourceKey)
    })

    const removeInstanceType = (nestedElementIndex: number) => {
      if (structure.value.defaultInstanceTypes) {
        structure.value.defaultInstanceTypes.splice(nestedElementIndex, 1)
      }
    }

    const removeApplicationLink = (applicationIndex: number) => {
      if (structure.value.defaultApplicationLinks) {
        structure.value.defaultApplicationLinks.splice(applicationIndex)
      }
    }

    const removeFolder = (folderIndex: number) => {
      if (structure.value.fileTransferConfig?.folders?.length) {
        structure.value.fileTransferConfig.folders.splice(folderIndex - 1, 2)
      }
    }

    const isDefaultInstanceType = (nestedInstanceType: Code) => {
      const instanceCollection = structure.value.defaultInstanceTypes
      const awsInstance = instanceCollection.find(instance => instance.name === "AWS")

      if (awsInstance) {
        return awsInstance.instanceTypes.some(instance => instance.code === nestedInstanceType.code)
      }

      return false
    }

    const appLinkHasCredential = (appLink: ApplicationLink) => {
      const app = structure.value.defaultApplicationLinks.find(appInfo => appInfo.link === appLink.link)

      if (app) {
        return Boolean(app.credentials)
      }

      return false
    }

    const addApplicationLinkCredential = (appLink: ApplicationLink) => {
      const app = structure.value.defaultApplicationLinks.find(appInfo => appInfo.link === appLink.link)

      if (app) {
        if (!appLinkHasCredential(appLink)) {
          if (app) {
            app.credentials = {
              username: "",
              usernameType: "Static",
              password: "",
              passwordType: "Static"
            }
          }
        } else {
          app.credentials = null
        }

        refreshKey.value++
      }
    }

    const getCredentials = (appLink: ApplicationLink) => {
      return appLink.credentials as ApplicationLinkCredential
    }

    const disableImplementCredential = (appLink: ApplicationLink) => {
      const hasLinkIdentifier = Boolean(appLink.link)

      if (hasLinkIdentifier) {
        const appLinkCollection = structure.value.defaultApplicationLinks
        const isUniqueIdentifier = appLinkCollection.filter(link => link.link === appLink.link).length === 1

        if (isUniqueIdentifier) {
          return false
        }
      }

      return false
    }

    const disableToSetDefaultInstanceType = (nestedInstanceType: Code) => {
      const hasCodeDefined = Boolean(nestedInstanceType.code)

      if (hasCodeDefined) {
        const instanceCollection = structure.value.availableInstanceTypes[0]?.instanceTypes

        if (instanceCollection) {
          const isUniqueCode =
            instanceCollection.filter(instance => instance.code === nestedInstanceType.code).length === 1

          if (isUniqueCode) {
            return false
          }
        }
      }

      return true
    }

    const setDefaultInstanceTypeDefinition = (nestedInstanceType: Code) => {
      const instanceCollection = structure.value.defaultInstanceTypes
      const awsInstance = instanceCollection.find(instance => instance.name === "AWS") as TypeOfInstance
      const childInstanceCollection = awsInstance.instanceTypes

      if (isDefaultInstanceType(nestedInstanceType)) {
        const indexOfInstance = childInstanceCollection.findIndex(instance => instance.code === nestedInstanceType.code)

        if (indexOfInstance !== -1) {
          awsInstance.instanceTypes.splice(indexOfInstance, 1)
        }
      } else {
        awsInstance.instanceTypes.push(nestedInstanceType)
      }
    }

    const addFileTransferFolder = () => {
      const uploadFolder = newFolderItem("upload")
      const downloadFolder = newFolderItem("download")

      if (structure.value.fileTransferConfig?.folders) {
        structure.value.fileTransferConfig.folders.push(uploadFolder, downloadFolder)
      } else {
        structure.value.fileTransferConfig = {
          folders: [uploadFolder, downloadFolder]
        }
      }
    }

    const addCode = (instanceType: Code[]) => {
      instanceType.push({
        code: ""
      })
    }

    const addInstanceType = () => {
      if (structure.value.defaultInstanceTypes) {
        structure.value.defaultInstanceTypes.push({
          name: "AWS",
          instanceTypes: [{ code: "" }]
        })
      } else {
        structure.value.defaultInstanceTypes = [
          {
            name: "AWS",
            instanceTypes: [{ code: "" }]
          }
        ]
      }
    }

    const addApplicationLink = () => {
      if (structure.value.defaultApplicationLinks) {
        structure.value.defaultApplicationLinks.push({
          link: "",
          label: "",
          credentials: null
        })
      } else {
        structure.value.defaultApplicationLinks = [
          {
            link: "",
            label: "",
            credentials: null
          }
        ]
      }
    }

    const addAvailableInstanceType = () => {
      if (structure.value.availableInstanceTypes) {
        structure.value.availableInstanceTypes.push({
          name: "",
          instanceTypes: [{ code: "" }]
        })
      } else {
        structure.value.availableInstanceTypes = [
          {
            name: "",
            instanceTypes: [{ code: "" }]
          }
        ]
      }
    }

    const removeAvailableInstanceType = (index: number) => {
      if (structure.value.availableInstanceTypes?.length) {
        structure.value.availableInstanceTypes.splice(index, 1)
      }
    }

    const removeCode = (instanceType: TypeOfInstance, index: number) => {
      instanceType.instanceTypes.splice(index, 1)
    }

    const updateCredentialTypes = () => {
      const appLinkCollection = structure.value.defaultApplicationLinks

      appLinkCollection.forEach(link => {
        if (link.credentials) {
          if (_getCredentialIsDynamic(link.credentials.password)) {
            link.credentials.passwordType = "DynamicConfig"
          } else {
            link.credentials.passwordType = "Static"
          }

          if (_getCredentialIsDynamic(link.credentials.username)) {
            link.credentials.usernameType = "DynamicConfig"
          } else {
            link.credentials.usernameType = "Static"
          }
        }
      })
    }

    const getAppIcons = () => {
      loader.run(async () => {
        const images = require.context("/public/products/", false, /\.(png|jpe?g|gif|svg)$/)

        const collection = images.keys().map(key => {
          const iconName = key.split("/").pop() as string
          const iconWithoutFileFormat = iconName.replace(/\.\w+$/, "")
          const iconPath = key.slice(1)

          return {
            text: iconWithoutFileFormat,
            value: `products${iconPath}`
          }
        }) as SelectItem<string>[]

        iconCollection.value = collection
      })
    }
    getAppIcons()

    const _getCredentialIsDynamic = (credentialValue: string) => {
      return credentialTypes.value.includes(credentialValue)
    }

    return {
      t,
      disableImplementCredential,
      updateCredentialTypes,
      credentialTypes,
      refreshKey,
      getCredentials,
      addApplicationLinkCredential,
      appLinkHasCredential,
      setDefaultInstanceTypeDefinition,
      isDefaultInstanceType,
      disableToSetDefaultInstanceType,
      iconCollection,
      addApplicationLink,
      instancePlatformItems,
      removeApplicationLink,
      addFileTransferFolder,
      fileTransferConfigFolders,
      userConfigurations,
      removeAvailableInstanceType,
      removeCode,
      addCode,
      addAvailableInstanceType,
      addInstanceType,
      removeInstanceType,
      rules,
      structure,
      removeFolder
    }
  },
  components: {
    Tooltip
  }
})
</script>
