<template>
  <div id="systemMessages" class="commonTab">
    <div class="containCommunicationItem backgroundWrapper">
      <div class="summary">
        <div class="wrapTitle">
          <h2>{{ $t("Admin.systemMessages.title") }}</h2>
          <p>{{ $t("Admin.systemMessages.subTitle") }}</p>
        </div>

        <v-switch
          v-model="systemMessageIsVisible"
          @change="confirmSetVisibleSystemMessage"
          :disabled="loader.on"
          :label="$t('Admin.systemMessages.buttonShowMessage')"
          class="my-0 py-0"
          hide-details
        />
      </div>

      <html-editor v-model="usersModule.systemMessage.message" :loading="loader.on" />

      <div class="controllers">
        <v-switch
          v-model="preview"
          @change="changePreviewState"
          :label="$t('Admin.systemMessages.buttonPreview')"
          class="my-0 py-0"
          hide-details
        />

        <v-btn @click="confirmSaveSystemMessage" :loading="loader.on" color="primary" large>
          <span>{{ $t("Admin.systemMessages.buttonSave") }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onBeforeUnmount } from "@vue/composition-api"
import { AdminModule } from "@/store/admin"
import { raiseSuccess, raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { UsersModule } from "@/store/users"
import HtmlEditor from "@/components/htmlEditor/HtmlEditor.vue"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "ChangeLogRoot",
  setup() {
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const systemMessageIsVisible = ref(false)
    const loader = Loader()

    const preview = computed({
      get() {
        return usersModule.previewSystemMessage
      },
      set(value: boolean) {
        usersModule.previewSystemMessage = value
      }
    })

    const _setStateOnMemory = (state: boolean) => {
      systemMessageIsVisible.value = state
      usersModule.systemMessage.active = state
    }

    const fetchSystemMessageThenUpdateMemory = async () => {
      await loader.run(async () => {
        preview.value = true
        await usersModule.getSystemMessage()
        _setStateOnMemory(usersModule.systemMessage.active)
      })
    }

    const confirmSaveSystemMessage = async () => {
      if (await raiseConfirmation({ text: translate("Admin.systemMessages.confirmation.saveConfirmation") })) {
        loader.run(async () => {
          await adminModule.setSystemMessage(usersModule.systemMessage.message)
          await fetchSystemMessageThenUpdateMemory()
          changePreviewState()

          raiseSuccess({ text: translate("Admin.systemMessages.confirmation.success") })
        })
      }
    }

    const confirmSetVisibleSystemMessage = async (state: boolean) => {
      const isOn = state ? "on" : "off"
      const showingText = translate(`Admin.systemMessages.switchMessage.${isOn}`)
      const confirmText = translate("Admin.systemMessages.confirmation.resetViews", { state: showingText })

      if (await raiseConfirmation({ text: confirmText })) {
        loader.run(async () => {
          await adminModule.setSystemMessageActivity(state)
          _setStateOnMemory(state)
          preview.value = false
        })
      } else {
        _setStateOnMemory(!state)
      }
    }

    const changePreviewState = () => {
      usersModule.systemMessage.active = preview.value
    }

    fetchSystemMessageThenUpdateMemory()

    onBeforeUnmount(() => {
      preview.value = false
    })

    return {
      systemMessageIsVisible,
      changePreviewState,
      confirmSetVisibleSystemMessage,
      confirmSaveSystemMessage,
      usersModule,
      loader,
      preview
    }
  },
  components: {
    HtmlEditor
  }
})
</script>
