<template>
  <div v-if="hasAdminLevel && !loader.on" id="onboarding">
    <div class="contentTitle">
      <org-logo />

      <h4>{{ $t("Onboarding.title", { organizationName, count: isCustomerHosted ? 4 : 3 }) }}</h4>
    </div>

    <onboarding-tabs
      v-model="currentTabIndex"
      @update:step="reachedNewStep"
      :is-customer-hosted="isCustomerHosted"
      :step-reached="maxTabReached"
    />
  </div>

  <div v-else id="onboarding" class="noPermission">
    <div v-if="loader.on" class="loading">
      <span> {{ $t("Onboarding.loadingUserPermission") }} <span class="dot">.</span> </span>
    </div>

    <div class="contentTitle" v-else>
      <org-logo />

      <div class="lowLevelPermission">
        <warning-bar
          :title="$t('Onboarding.titleNoPermission', { organizationName })"
          :text="$t('Onboarding.subTitleNoPermission')"
          type="warning"
          hide-close-button
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "@vue/composition-api"
import { AdminModule, UsersModule, LicenseModule } from "@/store"
import { LicenseServer } from "@/types/workspace"
import { navigate } from "@/router"
import { OrganizationType } from "@/types/organization"
import OnboardingTabs from "./components/OnboardingTabs.vue"
import LoadImage from "@/components/global/LoadImage.vue"
import OrgLogo from "@/components/organizationLogo/OrganizationLogo.vue"
import WarningBar from "@/components/warningBar/WarningBarRoot.vue"
import Loader from "@/utils/loader"
import "./scss/_onboardingTab.scss"

export default defineComponent({
  name: "OnboardingRoot",
  setup() {
    const licenseModule = LicenseModule()
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const loader = Loader({ alertOnError: false })
    const currentTabIndex = ref<number>(1)
    const maxTabReached = ref(currentTabIndex.value)
    const hasAdminLevel = ref(false)

    const reachedNewStep = () => {
      const highestStepReached = currentTabIndex.value > maxTabReached.value

      if (highestStepReached) {
        maxTabReached.value = currentTabIndex.value
      }
    }

    const organizationName = computed(() => {
      return usersModule.selfDetail?.organization?.name ?? ""
    })

    const isCustomerHosted = computed(() => {
      return adminModule.organizationDetail.organizationType === OrganizationType.CustomerHosted
    })

    const getLicenseSettings = async (license: LicenseServer) => {
      const licenseStatus = await licenseModule.getLicenseState()

      if (licenseStatus) {
        navigate({ name: "WorkspacesList" })
      } else if (license?.id) {
        licenseModule.licenseServer.organizationId = usersModule.selfDetail.organizationId
        licenseModule.workspaceId = license.id
        currentTabIndex.value = 4

        reachedNewStep()
      }
    }

    const _validateAdminLevelRole = async () => {
      await usersModule.getMyDetails()

      hasAdminLevel.value = usersModule.hasAdminPermissions
    }

    loader.run(async () => {
      try {
        await _validateAdminLevelRole()
        await adminModule.getOrganizationDetail(usersModule.selectedOrganizationId)

        if (hasAdminLevel.value) {
          await licenseModule.getLicenseServer(usersModule.selfDetail.organizationId)
        }
      } finally {
        await getLicenseSettings(licenseModule.currentLicense)
      }
    })

    watch(
      computed(() => licenseModule.currentLicense),
      getLicenseSettings,
      { immediate: true }
    )

    return {
      isCustomerHosted,
      loader,
      currentTabIndex,
      maxTabReached,
      organizationName,
      hasAdminLevel,
      reachedNewStep
    }
  },
  components: {
    WarningBar,
    OnboardingTabs,
    LoadImage,
    OrgLogo
  }
})
</script>
