<template>
  <div class="errorBox yellowBorder">
    <div class="wrapDetails">
      <v-icon color="warning">status_warning</v-icon>

      <div class="errorDetail">
        <p>
          <span>
            {{ t("warning") }}
          </span>
        </p>
      </div>
    </div>

    <div class="barFunctions">
      <copy-btn button-version :text="downloadLink" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import { appLauncherDownloadLink } from "@/config/download-app-launcher-link"
import CopyBtn from "@/components/copyBtn"

export default defineComponent({
  name: "VersionOutdated",
  setup() {
    const t = createTranslationModule("TriCaster.versionOutdated")
    const downloadLink = appLauncherDownloadLink

    return {
      t,
      downloadLink
    }
  },
  components: {
    CopyBtn
  }
})
</script>
