<template>
  <popup v-model="showModal" @confirm="save" :title="$t('OrganizationUsers.userEditor.title')">
    <div id="userEditor">
      <div class="summary">
        <v-label>
          <span>{{ $t("OrganizationUsers.userEditor.input.email") }}*</span>
          <v-text-field :value="tempUser.email" readonly hide-details />
        </v-label>

        <v-switch
          v-model="tempUser.disabled"
          :label="$t('OrganizationUsers.userEditor.input.disableUser')"
          rbt-target="activateUserSwitch"
          hide-details
        />
      </div>

      <v-form ref="Form">
        <v-label>
          <span>{{ $t("OrganizationUsers.userEditor.input.firstName") }}*</span>
          <v-text-field v-model="tempUser.firstName" :rules="rules" rbt-target="editFirstNameInput" hide-details />
        </v-label>

        <v-label>
          <span>{{ $t("OrganizationUsers.userEditor.input.lastName") }}</span>
          <v-text-field v-model="tempUser.lastName" rbt-target="editLastNameInput" hide-details />
        </v-label>

        <div class="inputLabel">
          <span>{{ $t("OrganizationUsers.userEditor.input.role") }}*</span>

          <span class="userRole">
            <v-select v-model="tempUser.role" :rules="rules" :items="userRoles" rbt-target="userRole" hide-details />

            <tooltip :text="roleExplanation">
              <v-icon>status_question</v-icon>
            </tooltip>
          </span>
        </div>
      </v-form>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { MandatoryTextField } from "@/utils/input-rules"
import { getRoleImportance } from "@/utils/role-importance"
import { raiseConfirmation } from "@/utils/event-bus"
import { userRoleItems } from "../lib/user-roles"
import { UsersModule } from "@/store/users"
import { translate } from "@/plugins/i18n"
import { VForm } from "@/types/core"
import { IUser, UserRoleEnum } from "@/types/user"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Popup from "@/components/popup/PopupRoot.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "UserEditor",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object as PropType<IUser>,
      required: true
    }
  },
  setup(props, ctx) {
    const showModal = Utils.vModel(props, ctx.emit)
    const usersModule = UsersModule()
    const tempUser = ref({ ...props.user })
    const Form = ref<null | VForm>(null)
    const rules = MandatoryTextField

    const userRoles = computed(() => {
      const currentRoleLevel = getRoleImportance(usersModule.selfDetail.role)

      return userRoleItems().filter(roleItem => {
        const { hasGodPermissions, hasSupportPermissions } = usersModule

        if (hasGodPermissions) {
          return true
        } else if (hasSupportPermissions) {
          return roleItem.value !== UserRoleEnum.SysAdmin
        } else {
          return currentRoleLevel >= getRoleImportance(roleItem.value)
        }
      })
    })

    const roleExplanation = computed(() => {
      return translate(`OrganizationUsers.roleExplanation.${tempUser.value.role}`)
    })

    const save = async () => {
      const form = Utils.isType<VForm>(Form.value, "validate")

      if (form && form.validate()) {
        if (await raiseConfirmation({ text: translate("OrganizationUsers.userEditor.confirmSave") })) {
          ctx.emit("save", tempUser.value)
          showModal.value = false
        }
      }
    }

    return {
      Form,
      rules,
      save,
      tempUser,
      userRoles,
      showModal,
      roleExplanation
    }
  },
  components: {
    Popup,
    Tooltip
  }
})
</script>
