<template>
  <popup v-model="isOpened" :loading="loading || loader.on" min-width="850px" title="VPC Peering" hide-footer>
    <div id="multiVpc">
      <p>{{ t("explanation") }}</p>

      <span class="preTitle"> {{ $t("WorkspaceEdit.workspace") }} </span>

      <div class="spacesContainer">
        <div class="spaceList">
          <div class="scrollContainer">
            <ul>
              <li
                v-for="(vpcItem, index) in vpcCollection"
                @click="selectVpc(vpcItem)"
                :key="vpcItem.id"
                :showing="isShowing(vpcItem)"
                :class="`animationDelay-${index}`"
                class="spaceItem animateFadeUpFast"
              >
                <span class="vpcStatus">
                  <v-icon v-if="isNew(vpcItem)" color="warning">status_warning</v-icon>

                  <tooltip
                    v-else-if="showIcon(vpcItem.peeringStatus)"
                    :text="$t(`WorkspaceEdit.vpcPeering.status.${vpcItem.peeringStatus}`)"
                  >
                    <v-icon :size="16" class="spinning" color="#fff">status_spinner_indeterminate</v-icon>
                  </tooltip>

                  <span v-else :status="vpcItem.peeringStatus" class="statusCircle" />
                </span>

                <span class="vpcName">{{ vpcItem.name || "Untitled" }}</span>

                <span v-if="isExternalVpc(vpcItem) && !isShowing(vpcItem)" class="externalLabel">
                  <small>{{ t("external") }}</small>
                </span>

                <span v-else-if="isShowing(vpcItem)" class="statusLabel">
                  <small>{{ translateVpcStatus(vpcItem) }}</small>
                </span>
              </li>

              <li v-if="vpcCollection.length === 0" class="emptyList">
                <small>{{ t("noPeering") }}</small>
              </li>
            </ul>
          </div>

          <div class="createNewPeering">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <spotlight-element :label="t('spotlightCreateNewVpc')" :active="createVpcIndicatorIsActive" right>
                  <v-btn v-bind="attrs" v-on="on" rbt-target="moreOptionsBtn" icon>
                    <v-icon>action_add_circle</v-icon>
                  </v-btn>
                </spotlight-element>
              </template>

              <ul class="listMenu">
                <li @click="startNewPeering(CreationType.SPACE_PEERING)" class="listRow" rbt-target="editUserBtn">
                  <span>{{ t("addNewVpcOptions.peerWithSpace") }}</span>
                </li>

                <li @click="startNewPeering(CreationType.EXTERNAL_PEERING)" class="listRow" rbt-target="editUserBtn">
                  <span>{{ t("addNewVpcOptions.externalPeering") }}</span>
                </li>
              </ul>
            </v-menu>
          </div>
        </div>

        <div class="newVpcSection">
          <vpc-watcher
            v-if="vpc"
            v-model="vpc"
            :vpc-collection="vpcCollection"
            @remove:new-vpc="removeNewVpc"
            @remove:old-vpc="removeOldVpc"
            @create:new-vpc="createVpc"
          />
        </div>
      </div>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "@vue/composition-api"
import { translate } from "@/plugins/i18n"
import { WorkspaceModule } from "@/store"
import { PeeringStatus, CreationType, AwsVpcConfig, VpcPeerWorkspace } from "./lib/type"
import { createVpcStructure } from "./lib/vpc-structure"
import { VpcItem } from "./lib/type"
import { raiseConfirmation } from "@/utils/event-bus"
import { defaults } from "./lib/defaults"
import Popup from "@/components/popup/PopupRoot.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"
import VpcWatcher from "./_VpcWatcher.vue"
import SpotlightElement from "@/components/spotlightElement"
import Loader from "@/utils/loader"
import "./vpc-peering.scss"

export default defineComponent({
  name: "MultiVpc",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const _isCreatingNew = ref(false)
    const _vpcCollectionNewItems = ref<VpcItem[]>([])
    const watchingVpcId = ref<null | string>(null)
    const isOpened = Utils.vModel(props, ctx.emit)
    const creationType = ref<CreationType | null>(null)
    const loader = Loader()
    const workspaceModule = WorkspaceModule()
    const vpc = ref<VpcItem | null>(null)

    const vpcCollection = computed(() => {
      const newItems = _vpcCollectionNewItems.value
      const createdVpcs = workspaceModule.vpc

      return [...newItems, ...createdVpcs] as VpcItem[]
    })

    const createVpcIndicatorIsActive = computed(() => {
      return vpcCollection.value.length === 0
    })

    const t = (snippet: string) => {
      const translationPrefix = "WorkspaceEdit.multiVpc."
      return translate(translationPrefix + snippet)
    }

    const isNew = (vpc: VpcItem) => {
      return vpc?.isNew || false
    }

    const showIcon = (vpcStatus: PeeringStatus | null) => {
      const { initiatingRequest, pendingAcceptance, provisioning, deleting } = PeeringStatus

      if (vpcStatus) {
        return [initiatingRequest, pendingAcceptance, provisioning, deleting].includes(vpcStatus)
      }
      return false
    }

    const startNewPeering = (peeringType: CreationType) => {
      const newVpc = createVpcStructure()

      newVpc.creationType = peeringType

      if (peeringType === CreationType.EXTERNAL_PEERING) {
        newVpc.name = defaults.CUSTOM_PEERING_NAME
      } else {
        newVpc.name = defaults.SPACE_PEERING
      }

      _vpcCollectionNewItems.value.unshift(newVpc)
      _selectFirstVpcFromCollection()
    }

    const isPeeringType = (peeringType: CreationType) => {
      return peeringType === creationType.value && _isCreatingNew.value
    }

    const selectVpc = (vpcItem: VpcItem) => {
      watchingVpcId.value = vpcItem.id
      vpc.value = vpcCollection.value.find(_vpc => _vpc.id === watchingVpcId.value) as VpcItem
    }

    const isShowing = (vpc: VpcItem) => {
      return vpc.id === watchingVpcId.value
    }

    const translateVpcStatus = (vpc: VpcItem) => {
      const isNew = vpc.isNew

      if (!isNew) {
        if (vpc.peerWorkspaceId) {
          return translate("WorkspaceEdit.vpcPeering.status__spacePeering." + vpc.peeringStatus)
        } else {
          return translate("WorkspaceEdit.vpcPeering.status__customPeering." + vpc.peeringStatus)
        }
      }

      return translate("WorkspaceEdit.vpcPeering.status__customPeering.null")
    }

    const removeNewVpc = (vpcId: string) => {
      const vpcIndexInCollection = _vpcCollectionNewItems.value.findIndex(vpc => vpc.id === vpcId)

      if (vpcIndexInCollection !== -1) {
        _vpcCollectionNewItems.value.splice(vpcIndexInCollection, 1)
        _selectFirstVpcFromCollection()
      }
    }

    const createVpc = (vpc: AwsVpcConfig | VpcPeerWorkspace) => {
      loader.run(async () => {
        const spaceId = workspaceModule.watching.id

        await workspaceModule.saveVpc(vpc, spaceId)
        removeNewVpc(vpc.id)
        await _updateVpcCollection()
      })
    }

    const isExternalVpc = (vpc: VpcItem) => {
      return Boolean(!vpc.peerWorkspaceId) && !vpc.isNew
    }

    const removeOldVpc = async (peeringId: string) => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.multiVpc.confirmRemoveVpc") })) {
        loader.run(async () => {
          const spaceId = workspaceModule.watching.id

          await workspaceModule.deleteVpc(spaceId, peeringId)
          await _updateVpcCollection()
        })
      }
    }

    const _updateVpcCollection = async () => {
      const spaceId = workspaceModule.watching.id

      await workspaceModule.getVpc(spaceId)
      _selectFirstVpcFromCollection()
    }

    const _resetWatchingVpc = () => {
      watchingVpcId.value = null
      vpc.value = null
    }

    const _selectFirstVpcFromCollection = () => {
      if (vpcCollection.value.length > 0) {
        const firstVpc = vpcCollection.value[0]
        selectVpc(firstVpc)
      } else {
        _resetWatchingVpc()
      }
    }

    loader.run(async () => {
      const organizationId = workspaceModule.watching.organizationId

      await _updateVpcCollection()
      await workspaceModule.getMyWorkspacesUsingOrganizationId(organizationId)
    })

    return {
      _vpcCollectionNewItems,
      t,
      showIcon,
      loader,
      isExternalVpc,
      translateVpcStatus,
      isNew,
      removeOldVpc,
      removeNewVpc,
      createVpc,
      vpc,
      isShowing,
      isOpened,
      selectVpc,
      createVpcIndicatorIsActive,
      vpcCollection,
      startNewPeering,
      isPeeringType,
      CreationType
    }
  },
  components: {
    SpotlightElement,
    Tooltip,
    Popup,
    VpcWatcher
  }
})
</script>
