import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animateFadeUp",attrs:{"id":"versionHandler"}},[_c('div',{staticClass:"inputs"},[_c(VForm,{ref:"Form"},[_c(VLabel,[_c('span',[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionHandler.description")))]),_c(VTextField,{attrs:{"rbt-target":"inputVersionDescription"},model:{value:(_vm.newVersionDescription),callback:function ($$v) {_vm.newVersionDescription=$$v},expression:"newVersionDescription"}})],1),_c(VLabel,[_c('span',[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionHandler.version")))]),_c(VTextField,{attrs:{"value":_vm.newVersion,"rbt-target":"inputVersion","readonly":""}})],1),_c(VLabel,[_c('span',[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionHandler.serviceVersion")))]),_c(VSelect,{attrs:{"items":_vm.serviceVersionOptions,"rbt-target":"selectServiceVersion"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"rbt-target":_vm.toRobotTag(("option " + (item.text)))}},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.serviceVersion),callback:function ($$v) {_vm.serviceVersion=$$v},expression:"serviceVersion"}})],1),_c(VLabel,[_c('span',[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionHandler.terraformVersion"))+" *")]),_c(VTextField,{attrs:{"rules":_vm.rules,"rbt-target":"inputTerraformVersion"},model:{value:(_vm.terraformVersion),callback:function ($$v) {_vm.terraformVersion=$$v},expression:"terraformVersion"}})],1)],1)],1),_c('form-uploader',{attrs:{"formats":"zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"},on:{"upload":_vm.storeUploadedFile}}),_c('div',{staticClass:"controller"},[_c(VBtn,{attrs:{"rbt-target":"buttonCancelRelease","large":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionHandler.button.cancel")))]),_c(VBtn,{attrs:{"color":"primary","rbt-target":"buttonRelease","large":""},on:{"click":_vm.release}},[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionHandler.button.release")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }