<template>
  <v-dialog v-model="show" width="500" @click:outside="cancel" @keydown.esc="cancel" @keydown.enter="confirm">
    <v-card class="confirmDialog">
      <v-card-title>
        <h3>{{ title }}</h3>

        <v-btn icon @click="cancel">
          <v-icon size="18">action_close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="containText" v-html="text"></div>

        <v-text-field v-if="useStrictMode" v-model="strictInput" :placeholder="strictConfirmation" hide-details />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn large @click="cancel">{{ buttonCancel }}</v-btn>
        <v-btn large @click="confirm" :disabled="disableConfirmButton" rbt-target="confirmDialog" class="primary">
          {{ buttonConfirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "@vue/composition-api"
import { DEFAULTS } from "./lib/config"
import { EventBusConfirmation } from "@/types/core"
import { eventBus } from "@/main"
import "./scss/_confirmDialog.scss"

export default defineComponent({
  setup() {
    const title = ref(DEFAULTS.title())
    const text = ref(DEFAULTS.text())
    const show = ref(false)
    const strictInput = ref("")
    const strictConfirmation = ref("")
    const buttonConfirm = ref(DEFAULTS.confirmText())
    const buttonCancel = ref(DEFAULTS.cancelText())

    let onConfirm = new Function()
    let onCancel = new Function()

    const useStrictMode = computed(() => {
      return Boolean(strictConfirmation.value)
    })

    const disableConfirmButton = computed(() => {
      if (useStrictMode.value) {
        return strictConfirmation.value.toLowerCase() !== strictInput.value.toLowerCase()
      }

      return false
    })

    const set = (key: "title" | "text", newValue: string | undefined | null): void => {
      switch (key) {
        case "title":
          title.value = newValue || DEFAULTS.title()
          break
        case "text":
          text.value = newValue || DEFAULTS.text()
          break
      }
    }

    const openConfirmModal = (confirmationData: EventBusConfirmation = {}) => {
      const { title, text } = confirmationData

      set("title", title)
      set("text", text)

      onConfirm = confirmationData?.onConfirm ?? DEFAULTS.onConfirm
      onCancel = confirmationData?.onCancel ?? DEFAULTS.onCancel
      strictConfirmation.value = confirmationData?.strictLabel ?? ""
      buttonConfirm.value = confirmationData?.buttonConfirmLabel ?? DEFAULTS.confirmText()
      buttonCancel.value = confirmationData?.buttonCancelLabel ?? DEFAULTS.cancelText()
      strictInput.value = ""

      show.value = true
    }

    const confirm = () => {
      const passKey = strictConfirmation.value.toLowerCase().trim()
      const keyTyped = strictInput.value.toLowerCase().trim()

      if (passKey && passKey !== keyTyped) {
        return
      }

      onConfirm()
      eventBus.$emit("confirm:confirm-modal")
      close()
    }

    const cancel = () => {
      onCancel()
      eventBus.$emit("cancel:confirm-modal")
      close()
    }

    const close = () => {
      show.value = false
    }

    eventBus.$on("confirm-modal", openConfirmModal)

    return {
      text,
      title,
      strictInput,
      show,
      disableConfirmButton,
      confirm,
      useStrictMode,
      cancel,
      strictConfirmation,
      buttonCancel,
      buttonConfirm
    }
  }
})
</script>
