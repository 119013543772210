<template>
  <div id="controllers">
    <v-btn @click="$emit('create:user')" :disabled="disabled" color="primary" rbt-target="openUserCreation" large>
      {{ $t("OrganizationUsers.buttons.createUser") }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "Controllers",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
</script>
