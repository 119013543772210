import { WorkspaceTemplate } from "@/types/workspace"
import { WorkspaceLevelDiff, AppDiffType } from "./type"
import { getDiff, hasSchemaChanges, areEqualStructures } from "./workspace-diff"

export const appChanges = (workspace: WorkspaceTemplate) => getDiff(workspace)

export { hasSchemaChanges, areEqualStructures }

export type { WorkspaceLevelDiff }

export type { AppDiffType }
