<template>
  <v-img class="workspace-image" height="40" width="40" :src="spaceIconStatus" />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { workspaceStatusIcon } from "@/utils/cloud-state-icons"

export default defineComponent({
  name: "WorkspaceListImage",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props) {
    const spaceIconStatus = computed(() => {
      return workspaceStatusIcon(props.workspace)
    })

    return {
      spaceIconStatus
    }
  }
})
</script>
