import { Dictionary } from "@/types/core"

export enum MediaContext {
  feedback = "feedback-files",
  organization = "organization-logo",
  workspace = "workspace-asset-files"
}

export const UploadContext: Dictionary<string> = {
  feedback: MediaContext.feedback,
  organization: MediaContext.organization,
  workspace: MediaContext.workspace
}

export default UploadContext
