export interface AwsVpcConfig {
  id: string
  name: string
  ownerAccountId: string | null
  peerVpcId: string
  peerRegion: string
  peerCidr: string
  peeringType: CreationType
}

export interface VpcPeerWorkspace {
  id: string
  name: string
  peerWorkspaceId: string
  peeringType: CreationType
}

export enum PeeringStatus {
  active = "Active",
  deleted = "Deleted",
  deleting = "Deleting",
  expired = "Expired",
  failed = "Failed",
  initiatingRequest = "InitiatingRequest",
  pendingAcceptance = "PendingAcceptance",
  provisioning = "Provisioning",
  rejected = "Rejected"
}

export interface Vpc {
  id: string
  name: string
  ownerAccountId: string
  peerVpcId: string
  peerCidr: string
  peeringConnectionId: string
  peerWorkspaceId: string
  requiresManualAction: boolean
  peerRegion: string
  peeringStatus: PeeringStatus | null
}

export enum CreationType {
  SPACE_PEERING = "SpacePeering",
  EXTERNAL_PEERING = "ExternalPeering"
}

export interface VpcItem extends Vpc {
  id: string
  isNew: boolean
  creationType?: CreationType
}

export enum ViewMode {
  delete = "delete",
  create = "create"
}
