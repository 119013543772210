import { WorkspaceListItem } from "@/types/workspace"
import { WorkspaceStates } from "@/config/workspace-states"
import { Dictionary } from "@/types/core"

const PRIORITY = {
  none: 0,
  veryLow: 5,
  low: 10,
  high: 15,
  veryHigh: 20
}

const DEFAULTS = {
  importanceTable: {
    status: {
      archived: PRIORITY.high,
      restoring: PRIORITY.veryHigh,
      archiving: PRIORITY.veryHigh,
      deployed: PRIORITY.veryHigh,
      "deployed-with-error": PRIORITY.veryHigh,
      deploying: PRIORITY.veryLow,
      preparing: PRIORITY.veryLow,
      validating: PRIORITY.veryLow,
      draft: PRIORITY.low,
      destroying: PRIORITY.none,
      "deploy-failed": PRIORITY.high,
      "destroy-failed": PRIORITY.none
    },
    onlineStatus: {
      online: 5,
      starting: 4,
      archived: 3,
      stopping: 2,
      offline: 1
    }
  }
}

const sortStatus = (list: WorkspaceListItem[], sortDescending: boolean) => {
  const rateOnlineStatus = DEFAULTS.importanceTable.onlineStatus as Dictionary<number>
  const rateStatus = DEFAULTS.importanceTable.status as Dictionary<number>

  return list.sort((a, b) => {
    let sumCountA = rateStatus[a.status] || 0
    let sumCountB = rateStatus[b.status] || 0

    if (WorkspaceStates.isDeployed(a.status)) {
      sumCountA += rateOnlineStatus[a.onlineStatus]
    }
    if (WorkspaceStates.isDeployed(b.status)) {
      sumCountB += rateOnlineStatus[b.onlineStatus]
    }

    if (sortDescending) {
      return sumCountB - sumCountA
    } else {
      return sumCountA - sumCountB
    }
  })
}

const _autoDetectDateKey = (workspace: WorkspaceListItem) => {
  if (workspace.stateChange) {
    return new Date(workspace.stateChange.changeDate).getTime()
  } else if (workspace.modifyDate) {
    return new Date(workspace.modifyDate).getTime()
  } else {
    return new Date(workspace.creationDate).getTime()
  }
}

const sortLastModified = (list: WorkspaceListItem[], sortDescending: boolean) => {
  const newList = list.sort((priorWorkspace, afterWorkspace) => {
    const priorDate = _autoDetectDateKey(priorWorkspace)
    const afterDate = _autoDetectDateKey(afterWorkspace)

    if (sortDescending) {
      return afterDate - priorDate
    } else {
      return priorDate - afterDate
    }
  })

  return newList
}

const sortAlphabet = (list: WorkspaceListItem[], sortKey: string, sortDescending: boolean) => {
  if (sortDescending) {
    return list.sort((priorWorkspace, afterWorkspace) =>
      (afterWorkspace[sortKey] as string).localeCompare(priorWorkspace[sortKey] as string)
    )
  } else {
    return list.sort((priorWorkspace, afterWorkspace) =>
      (priorWorkspace[sortKey] as string).localeCompare(afterWorkspace[sortKey] as string)
    )
  }
}

export const workspaceSort = (workspaceList: WorkspaceListItem[], sortType: string, sortDescending: boolean) => {
  switch (sortType) {
    case "status":
      return sortStatus(workspaceList, sortDescending)
    case "username":
      return sortAlphabet(workspaceList, "username", sortDescending)
    case "date":
      return sortLastModified(workspaceList, sortDescending)
    case "title":
    default:
      return sortAlphabet(workspaceList, "title", sortDescending)
  }
}
