<template>
  <div class="deploying">
    <span class="upperContent">
      <span>
        {{ $t(`Onboarding.licenseStage.${stage}`) }}
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceStatusEnum } from "@/types/workspace"

export default defineComponent({
  name: "StageError",
  props: {
    stage: {
      type: String as PropType<WorkspaceStatusEnum>,
      required: true
    }
  }
})
</script>
