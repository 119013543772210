import { VueConstructor } from "vue"
import Identity from "../components/identity"
import Infrastructure from "../components/infrastructure"
import Tags from "../components/tags"

interface Option {
  name: string
  icon: string
  component: VueConstructor
}

export const tabCollection: Option[] = [
  {
    name: "identity",
    icon: "mdi-cog-outline",
    component: Identity
  },
  {
    name: "infrastructure",
    icon: "mdi-cloud-arrow-up-outline",
    component: Infrastructure
  },
  {
    name: "tags",
    icon: "mdi-tag-outline",
    component: Tags
  }
]
