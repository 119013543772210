<template>
  <div id="deployPackage">
    <v-progress-linear v-if="loader.on" class="my-0 py-0" color="primary" indeterminate />

    <div class="backgroundWrapper">
      <div class="summary">
        <h1>{{ $t("Admin.deployPackage.summary.title") }}</h1>

        <div class="blocks">
          <p>{{ $t("Admin.deployPackage.summary.subTitleOne") }}</p>
        </div>
      </div>

      <div class="headerContainer">
        <search v-model="searchSnippet" />

        <create-new-deploy @create="createDeployPackage" />
      </div>

      <div class="contentContainer">
        <table-deploy-package @delete="deleteDeployPackage" @edit="openDeployPackageEditor" :items="filteredPackages" />
      </div>

      <editor-deploy-package
        v-model="showPackageEditor"
        v-if="showPackageEditor"
        @save="updatePackageBeingEdited"
        @release="releaseNewDeployVersion"
        @set:current="setCurrentVersion"
        @download="downloadSpaceTemplate"
        :package-data="packageBeingModified"
        :versions="packageVersions"
        :loading="loader.on"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api"
import { BasicDeployPackageData } from "@/types/deployPackages"
import { raiseConfirmation, raiseSuccess } from "@/utils/event-bus"
import { DeployPackagePayload, IDeployPackage } from "@/types/deployPackages"
import { translate } from "@/plugins/i18n"
import { useStore } from "@/composable/useStore"
import Search from "@/components/search/Search.vue"
import CreateNewDeploy from "./CreateNewDeploy.vue"
import Loader from "@/utils/loader"
import TableDeployPackage from "./TableDeployPackage.vue"
import EditorDeployPackage from "./EditorDeployPackage.vue"

export default defineComponent({
  name: "DeployPackageRoot",
  setup() {
    const { adminModule } = useStore()
    const searchSnippet = ref("")
    const loader = Loader()
    const packageBeingModified = ref({} as IDeployPackage)
    const showPackageEditor = ref(false)

    const filteredPackages = computed(() => {
      const lower = (text: string) => text.toLowerCase().trim()

      return packages.value.filter(item => lower(item.name).includes(searchSnippet.value))
    })

    const packages = computed(() => {
      return adminModule.deployPackages
    })

    const packageVersions = computed(() => {
      return adminModule.packageVersions
    })

    const createDeployPackage = (deployData: BasicDeployPackageData) => {
      loader.run(async () => {
        const deployPackage = await adminModule.createDeployPackage(deployData)
        openDeployPackageEditor(deployPackage)
      })
    }

    const releaseNewDeployVersion = (releaseNewVersion: DeployPackagePayload) => {
      loader.run(async () => {
        await adminModule.releaseDeployPackageVersion(releaseNewVersion)
        showPackageEditor.value = false
        raiseSuccess({ text: translate("Admin.deployPackage.alerts.successReleaseDeployVersion") })
      })
    }

    const openDeployPackageEditor = (packageItem: IDeployPackage) => {
      loader.run(async () => {
        await adminModule.getPackageVersions(packageItem.id)

        packageBeingModified.value = packageItem
        showPackageEditor.value = true
      })
    }

    const deleteDeployPackage = async (deployPackageId: string) => {
      if (await raiseConfirmation({ text: translate("Admin.deployPackage.alerts.confirmDelete") })) {
        loader.run(async () => {
          await adminModule.deleteDeployPackage(deployPackageId)
        })
      }
    }

    const setCurrentVersion = async (versionId: string, deployPackageId: string) => {
      if (await raiseConfirmation({ text: translate("Admin.deployPackage.alerts.confirmChangeVersion") })) {
        loader.run(async () => {
          await adminModule.pickPackageVersion(deployPackageId, versionId)

          packageBeingModified.value = await adminModule.getDeployPackageDetails(packageBeingModified.value.id)

          await adminModule.getPackageVersions(packageBeingModified.value.id)
          await adminModule.getDeployPackages()
        })
      }
    }

    const updatePackageBeingEdited = (packageData: IDeployPackage) => {
      loader.run(async () => {
        await adminModule.updateDeployPackage(packageData)
        packageBeingModified.value = await adminModule.getDeployPackageDetails(packageData.id)
        await adminModule.getDeployPackages()
      })
    }

    const downloadSpaceTemplate = async (versionId: string) => {
      if (await raiseConfirmation({ text: translate("Admin.deployPackage.alerts.confirmDownloadTemplate") })) {
        await adminModule.downloadDeploySpaceTemplate(
          packageBeingModified.value.id,
          versionId,
          packageBeingModified.value.name
        )
      }
    }

    loader.run(async () => {
      await Promise.all([adminModule.getDeployPackages(), adminModule.getDeployPackageServiceVersions()])
    })

    return {
      loader,
      downloadSpaceTemplate,
      packageVersions,
      setCurrentVersion,
      releaseNewDeployVersion,
      updatePackageBeingEdited,
      openDeployPackageEditor,
      deleteDeployPackage,
      showPackageEditor,
      packageBeingModified,
      filteredPackages,
      createDeployPackage,
      searchSnippet
    }
  },
  components: {
    Search,
    EditorDeployPackage,
    TableDeployPackage,
    CreateNewDeploy
  }
})
</script>
