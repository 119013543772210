<template>
  <div class="idleStage">
    <span class="upperContent">
      <span class="statusText">
        <v-icon color="warning" left>status_warning</v-icon>
        <span>{{ $t("Onboarding.licenseServer.idle") }}</span>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({})
</script>
