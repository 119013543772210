<template>
  <popup v-model="showPopup" :title="t('title')" :loading="loader.on" width="650px" no-padding hide-footer>
    <div class="postponeContent">
      <div class="wrapText">
        <p>{{ t("warning", { timeRemaining }) }}</p>
      </div>

      <div class="controller">
        <v-btn @click="cancelShutdown" large>{{ t("buttons.cancel") }}</v-btn>
        <v-btn @click="continueShutdown" :disabled="!preventingNotifications" large>{{ t("buttons.continue") }}</v-btn>
        <v-btn @click="postponeShutdown" color="primary" large>{{ t("buttons.postpone") }}</v-btn>
      </div>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import Utils from "@/utils/utils"
import Popup from "@/components/popup/PopupRoot.vue"
import Loader from "@/utils/loader"
import { WorkspaceModule } from "@/store"
import { createTranslationModule } from "@/plugins/i18n"

export default defineComponent({
  name: "PostponeAutoShutdownPopup",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    timeRemaining: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    preventingNotifications: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const t = createTranslationModule("WorkspaceEdit.postponePopup")
    const workspaceModule = WorkspaceModule()
    const showPopup = Utils.vModel(props, ctx.emit)
    const loader = Loader()

    const continueShutdown = () => {
      showPopup.value = false
      ctx.emit("continue:shutdown")
    }

    const cancelShutdown = () => {
      loader.run(async () => {
        await workspaceModule.cancelAutoShutdown(props.workspaceId)
        await workspaceModule.getWorkspace(props.workspaceId)
        showPopup.value = false
      })
    }

    const postponeShutdown = () => {
      loader.run(async () => {
        await workspaceModule.postponeAutoShutdown(props.workspaceId)
        await workspaceModule.getWorkspace(props.workspaceId)
        ctx.emit("restart:notifications")
        showPopup.value = false
      })
    }

    return {
      t,
      continueShutdown,
      loader,
      postponeShutdown,
      cancelShutdown,
      showPopup
    }
  },
  components: {
    Popup
  }
})
</script>
