<template>
  <div class="alertErrorType">
    <generic-alert
      @click="confirmRedeployThenEmit"
      @cancel="confirmDestroyThenEmit"
      icon="action_remove_box"
      :action="$t('WorkspaceEdit.alerts.buttons.redeploy')"
      :cancel-text="$t('WorkspaceEdit.alerts.buttons.destroy')"
      :disabled="disabled"
      type="error"
    >
      <p>
        {{ feedbackMessage }}
      </p>
    </generic-alert>
  </div>
</template>

<script lang="ts">
import { translate } from "@/plugins/i18n"
import { raiseConfirmation } from "@/utils/event-bus"
import { computed, defineComponent } from "@vue/composition-api"
import GenericAlert from "./GenericAlert.vue"

export default defineComponent({
  name: "ErrorAlert",
  props: {
    feedback: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const confirmDestroyThenEmit = async () => {
      const text = translate("WorkspaceEdit.otherOptions.confirm.destroy")
      const strictLabel = translate("WorkspaceEdit.otherOptions.confirm.deleteSnippet")
      const buttonConfirmLabel = translate("WorkspaceEdit.otherOptions.confirm.confirmDelete")

      if (await raiseConfirmation({ text, strictLabel, buttonConfirmLabel })) {
        ctx.emit("destroy")
      }
    }

    const confirmRedeployThenEmit = async () => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.otherOptions.confirm.confirmRedeploy") })) {
        ctx.emit("redeploy")
      }
    }

    const feedbackMessage = computed(() => {
      const staticMsg = translate("WorkspaceEdit.alerts.feedbacks.deployedWithError")
      const error = props.feedback ? `- ${props.feedback}` : ""
      return `${staticMsg} ${error}`.trim()
    })

    return {
      confirmDestroyThenEmit,
      confirmRedeployThenEmit,
      feedbackMessage
    }
  },
  components: {
    GenericAlert
  }
})
</script>
