<template>
  <div id="infrastructure">
    <cloud-provider :has-tab-focused="hasTabFocused" />
    <cidr-block />
    <update-arn />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "@vue/composition-api"
import { IOrganization } from "@/types/organization"
import Loader from "@/utils/loader"
import UpdateArn from "./_UpdateArn.vue"
import CidrBlock from "./_CidrBlock.vue"
import CloudProvider from "./_CloudProvider.vue"

export default defineComponent({
  name: "InfrastructureRoot",
  props: {
    hasTabFocused: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const loader = Loader()
    const organization = inject("organization") as IOrganization

    return {
      organization,
      loader
    }
  },
  components: {
    UpdateArn,
    CidrBlock,
    CloudProvider
  }
})
</script>
