import { defineStore } from "pinia"
import { Users, Organization, Admin } from "@/services"
import { IUser } from "@/types/user"
import { IOrganization, Wallet, OrganizationType } from "@/types/organization"

export const OrganizationModule = defineStore("organization", {
  state: () => ({
    users: [] as IUser[],
    organizationDetail: {} as IOrganization,
    walletDetail: {} as Wallet
  }),
  getters: {
    organizationType(): OrganizationType | null {
      return this.organizationDetail?.organizationType ?? null
    }
  },
  actions: {
    async getUsersFromOrganization(organizationId: string) {
      const req = await Users.getOrgUsers(organizationId)
      this.users = req.data.data

      return this.users
    },

    async getWallet(organizationId: string) {
      const req = await Organization.getWallet(organizationId)
      this.walletDetail = req.data.data
      return this.walletDetail
    },

    async getOrganizationDetail(organizationId: string) {
      if (organizationId) {
        const req = await Admin.getOrganizationDetail(organizationId)
        this.organizationDetail = req.data.data
      }
    }
  }
})
