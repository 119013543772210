<template>
  <popup v-model="showPopup" title="TriCaster Warning" width="400px" hide-footer>
    <template v-slot:title>
      <span>
        <v-icon color="warning">status_warning</v-icon>
        <span>TriCaster Warning</span>
      </span>
    </template>

    <div id="showWarningPopup">
      <p>Please follow the recommended shutdown procedure (while logged in using NICE DCV):</p>

      <ul class="steps">
        <li>
          <span>Step 1: Exit TriCaster Session</span>
          <span class="asLabel">(File > Exit > Exit)</span>
        </li>

        <li>
          <span>Step 2: Shutdown the TriCaster </span>
          <span class="asLabel">(Shutdown > Shutdown TriCaster Now > Shutdown)</span>
        </li>
      </ul>

      <div class="asLabel">
        <p>Going offline without saving may result in:</p>

        <ul class="p-0">
          <li>Unsaved changes</li>
          <li>Corrupted files</li>
        </ul>
      </div>

      <div class="controller">
        <v-btn @click="close" large>Cancel</v-btn>
        <v-btn @click="goOffline" color="error" large>Go Offline</v-btn>
      </div>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import Popup from "@/components/popup/PopupRoot.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "WarningPopup",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const showPopup = Utils.vModel(props, ctx.emit)

    const close = () => {
      showPopup.value = false
    }

    const goOffline = () => {
      ctx.emit("shutdown")
      close()
    }

    return {
      close,
      goOffline,
      showPopup
    }
  },
  components: {
    Popup
  }
})
</script>
