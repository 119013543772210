import { authGuard } from "@/auth/authGuard"
import env from "@/utils/env"
import Vue from "vue"
import VueRouter, { RawLocation } from "vue-router"
import routes from "../routes"

Vue.use(VueRouter)

export const createRouter = (): VueRouterPlugin => {
  const router = new VueRouter({
    mode: "history",
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    base: env.BASE_URL,
    routes
  })

  router.beforeEach(authGuard)

  return router as VueRouterPlugin
}

const routerInstance = createRouter()

export const navigate = (location: RawLocation) => {
  routerInstance.push(location).catch(err => {
    if (err.name !== "NavigationDuplicated") {
      throw err
    }
  })
}

export const replace = (location: RawLocation) => {
  routerInstance.replace(location).catch(err => {
    if (err.name !== "NavigationDuplicated") {
      throw err
    }
  })
}

export const navigateAttemptedRoute = () => {
  const attemptedRoute = sessionStorage.getItem("attemptedRouteDirect")

  if (attemptedRoute) {
    navigate(attemptedRoute)
    setTimeout(() => sessionStorage.removeItem("attemptedRouteDirect"), 1200)
  } else {
    navigate({ name: "Root" })
  }
}

export default routerInstance
