import { Dictionary } from "vue-router/types/router"
import { BundleActions } from "./factory"
import Services from "./service"

export const services = new Services()

export const createService = <B extends BundleActions>(serviceName: string, actions: B): B => {
  services.add(serviceName, actions)

  return {
    ...actions
  }
}

/**
 * Returns multiple bundles but will lose type support
 *
 * @example
 * const { user, feedback } = findServices(["user", "feedback"])
 */
export const findServices = (serviceName: string | string[]): Dictionary<BundleActions> => {
  return services.find(serviceName)
}
