import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum, WorkspaceStatusEnum } from "@/types/workspace"
import { WorkspaceModule } from "@/store"
import ProgressBar from "../ProgressBar.vue"

export default defineComponent({
  name: "ComponentLogicMixin",
  props: {
    status: {
      type: String as PropType<WorkspaceStatusEnum | WorkspaceOnlineStatusEnum>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    appId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const workspaceModule = WorkspaceModule()

    const currentWorkspace = computed(() => {
      return workspaceModule.watching
    })

    const lastModifiedTime = computed(() => {
      return new Date(currentWorkspace?.value.stateChange?.changeDate || 0).getTime()
    })

    const checkStatus = (status: WorkspaceStatusEnum | WorkspaceOnlineStatusEnum) => {
      return props.status === status
    }

    return {
      checkStatus,
      lastModifiedTime,
      ...WorkspaceStatusEnum,
      ...WorkspaceOnlineStatusEnum
    }
  },
  components: {
    ProgressBar
  }
})
