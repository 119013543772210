import { TemplateSizes } from "./types"

const measure = (width: string, height: string) => {
  return {
    width,
    height
  }
}

export const templateSizes: TemplateSizes = {
  small: measure("200px", "auto"),
  medium: measure("350px", "auto"),
  large: measure("100%", "auto"),
  default: measure("200px", "auto")
}
