<template>
  <div id="workspaceConfig">
    <tags-editor v-model="newWorkspace.tags" />

    <div class="alignContent">
      <workspace-header v-model="newWorkspace" />

      <wizard-topbar @reset="resetSettings" @create="createWorkspace" @back="$emit('back')" />
    </div>

    <div class="loadingState" v-if="loader.on">
      <v-progress-linear class="my-0" indeterminate />

      <p class="emptyList">
        <span>{{ $t("WorkspaceWizard.labels.loadingSettings") }}<span class="dot">.</span> </span>
      </p>
    </div>

    <settings v-else v-model="workspaceSettings" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import { WizardConfigItem } from "@/types/wizard"
import { CreateWorkspacePayload } from "@/types/workspace"
import { raiseError } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { AdminModule } from "@/store/admin"
import { RequestErrorResponse } from "@/types/core"
import { UsersModule } from "@/store/users"
import { navigate } from "@/router"
import WizardTopbar from "./WizardTopbarController.vue"
import Settings from "./Settings.vue"
import WorkspaceHeader from "@/components/workspaceHeaderInputs/HeaderRoot.vue"
import Utils from "@/utils/utils"
import TagsEditor from "./TagsEditor.vue"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "WorkspaceConfigRoot",
  props: {
    value: {
      type: Object as PropType<CreateWorkspacePayload>,
      required: true
    }
  },
  setup(props, ctx) {
    const newWorkspace = Utils.vModel(props, ctx.emit)
    const workspaceModule = WorkspaceModule()
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const templateDefaultState = ref<WizardConfigItem[]>([])
    const workspaceSettings = ref<WizardConfigItem[]>([])
    const loader = Loader({ throwOnError: true, alertOnError: false })

    const resetSettings = () => {
      if (templateDefaultState.value.length > 0) {
        const deepClonedList = Utils.deepCloneMultiple(templateDefaultState.value)
        workspaceSettings.value = [...deepClonedList]
      }
    }

    const createWorkspace = () => {
      try {
        _registerWizardSettings()
        _createWorkspaceThenRedirect()
      } catch (error) {
        ctx.emit("back")
        raiseError({ text: translate("WorkspaceWizard.error.couldNotCreateSettings"), error })
      }
    }

    const _invalidTemplate = () => {
      ctx.emit("back")
      raiseError({ text: translate("WorkspaceWizard.error.invalidSettings") })
    }

    const _createWorkspaceThenRedirect = async () => {
      const workspace = await workspaceModule.create(newWorkspace.value)

      navigate({
        name: "WorkspaceEdit",
        params: {
          id: workspace.id
        }
      })
    }

    const _registerWizardSettings = () => {
      newWorkspace.value.workspaceWizardConfiguration = workspaceSettings.value
    }

    const _setConfig = () => {
      newWorkspace.value.title = workspaceModule.selectedTemplate.title
      newWorkspace.value.description = workspaceModule.selectedTemplate.description
    }

    const _updateOwnIp = async () => {
      newWorkspace.value.authorizedIps.push({
        ip: await adminModule.getOwnIp(),
        description: usersModule.selfDetail.email
      })
    }

    loader.run(async () => {
      try {
        await _updateOwnIp()
        await workspaceModule.getWizardSettings(newWorkspace.value.templateId)

        templateDefaultState.value = workspaceModule.wizardConfig
        resetSettings()
      } catch (error) {
        const requestError = Utils.isType<RequestErrorResponse>(error, "httpStatusCode")

        if (requestError) {
          const noWizardConfig = requestError.httpStatusCode === 404

          if (noWizardConfig) {
            _createWorkspaceThenRedirect()
          } else {
            _invalidTemplate()
          }
        } else {
          throw error
        }
      }
    })

    _setConfig()

    return {
      newWorkspace,
      workspaceSettings,
      loader,
      resetSettings,
      createWorkspace
    }
  },
  components: {
    TagsEditor,
    WizardTopbar,
    WorkspaceHeader,
    Settings
  }
})
</script>
