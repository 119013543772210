<template>
  <div :class="dynamicClasses">
    <div class="wrapDetails">
      <v-icon :color="iconSettings.color">{{ iconSettings.icon }}</v-icon>

      <div class="errorDetail">
        <p>
          <span>
            {{ certificateWarning }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Subscription } from "@/types/organization"
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import { appLauncherDownloadLink } from "@/config/download-app-launcher-link"
import CopyBtn from "@/components/copyBtn"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "SubscriptionWarning",
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
      required: true
    }
  },
  setup(props) {
    const t = createTranslationModule("TriCaster.subscriptionWarning")
    const downloadLink = appLauncherDownloadLink

    const isExpired = computed(() => {
      return remainingDays.value < 1
    })

    const remainingDays = computed(() => {
      const expiresAt = props.subscription?.expirationDate || Date.now()
      return Utils.dateRange(Date.now(), expiresAt).days
    })

    const dynamicClasses = computed(() => {
      const baseClass = "errorBox"
      const classes = [baseClass]

      if (remainingDays.value > 0) {
        classes.push("yellowBorder")
      }

      return classes.join(" ")
    })

    const iconSettings = computed(() => {
      const warning = { icon: "status_warning", color: "warning" }
      const error = { icon: "status_error", color: "error" }

      return dynamicClasses.value.includes("yellowBorder") ? warning : error
    })

    const certificateWarning = computed(() => {
      if (isExpired.value) {
        return t("expired")
      } else {
        return t("willExpire", { days: remainingDays.value }, remainingDays.value)
      }
    })

    return {
      t,
      iconSettings,
      certificateWarning,
      dynamicClasses,
      downloadLink
    }
  },
  components: {
    CopyBtn
  }
})
</script>
