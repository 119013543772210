<template>
  <div class="imageVisualizerContainer" :clickable="clickableClass">
    <figure @click="onTouchImage" :style="templateSize" class="imageContainer">
      <img :src="src" :alt="alt" />
    </figure>

    <large-image v-model="showBigPicture" :src="src" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, computed } from "@vue/composition-api"
import { PreDefinedSize, OverrideSizes } from "./lib/types"
import { templateSizes } from "./lib/templates"
import LargeImage from "./components/LargeImage.vue"
import "./scss/_imageVisualizer.scss"

export default defineComponent({
  name: "ImageVisualizerRoot",
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    use: {
      type: String as PropType<PreDefinedSize>,
      default: PreDefinedSize.default
    },
    override: {
      type: Object as PropType<OverrideSizes> | null,
      default: null
    },
    notClickable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const showBigPicture = ref(false)

    const onTouchImage = () => {
      showBigPicture.value = true
    }

    const _overrideSizes = computed(() => {
      if (props.override) {
        const { width, height } = props.override

        return {
          width,
          height
        }
      }

      return {}
    })

    const clickableClass = computed(() => {
      return props.notClickable ? "notClickable" : "clickable"
    })

    const templateSize = computed(() => {
      if (!props.override) {
        return templateSizes[props.use]
      }

      return _overrideSizes.value
    })

    return {
      showBigPicture,
      templateSize,
      clickableClass,
      onTouchImage
    }
  },
  components: {
    LargeImage
  }
})
</script>
