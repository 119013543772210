import router, { navigate } from "@/router"
import { LicenseModule } from "@/store/license-server"
import { Dictionary } from "vue-router/types/router"

const sendToOnboarding = (query: Dictionary<string> = {}) => {
  const routeWithLicenseException = router.currentRoute.meta?.licenseRedirectException

  if (!routeWithLicenseException) {
    navigate({ name: "Onboarding", query })
  }
}

export const validateLicenseServer = async () => {
  const licenseModule = LicenseModule()

  try {
    const licenseReadyToUse = await licenseModule.getLicenseState()

    if (!licenseReadyToUse) {
      licenseModule.resetLicenseServer()
      sendToOnboarding()
    }
  } catch {
    sendToOnboarding()
  }
}
