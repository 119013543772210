import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"content-class":"bar_menu","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("status_question")])],1)]}}])},[_c('ul',{staticClass:"listMenu"},_vm._l((_vm.docs),function(doc){return _c('li',{key:doc.id,staticClass:"listRow",on:{"click":function($event){return _vm.openNewTab(doc.link)}}},[_vm._v(" "+_vm._s(doc.label[_vm.$i18n.locale])+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }