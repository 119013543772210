<template>
  <div class="appCredentials">
    <div class="inputItem" v-for="item in credentials" :key="item.key">
      <v-label v-if="canSeeInput(item)">
        <span>{{ item.name }}</span>

        <v-text-field :value="item.value" dense readonly hide-details>
          <template v-slot:append>
            <v-btn @click.stop="copy(item)" :disabled="loading" icon>
              <v-icon>
                {{ inputIcon(item) }}
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { IAssetConfiguration } from "@/types/workspace"
import { UsersModule } from "@/store/users"
import { copyToClipboard } from "@/utils/ui"

export default defineComponent({
  name: "AppCredentials",
  props: {
    credentials: {
      type: Array as PropType<IAssetConfiguration[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(_, ctx) {
    const usersModule = UsersModule()

    const canSeeInput = (config: IAssetConfiguration) => {
      if (config.key === "id" && !usersModule.hasSupportPermissions) {
        return false
      }

      return true
    }

    const inputIcon = (config: IAssetConfiguration) => {
      const isPassword = config.key === "password"

      return isPassword ? "status_lock_off" : "action_copy"
    }

    const copy = (config: IAssetConfiguration) => {
      switch (config.key) {
        case "password":
          ctx.emit("copy:password")
          break
        default:
          copyToClipboard(config.value)
          break
      }
    }

    return {
      copy,
      inputIcon,
      canSeeInput
    }
  }
})
</script>
