import { translate } from "@/plugins/i18n"
import { UserRoleEnum } from "@/types/user"

export const userRoleOptions = () => {
  return [
    {
      text: translate(`userRoles.admin`),
      value: UserRoleEnum.Admin
    }
  ]
}
