<template>
  <v-data-table :headers="headers" :items="items" :hide-default-footer="hideTableFooter">
    <template v-slot:[`item.name`]="{ item }">
      <a @click="openOrganization(item.id)" :rbt-target="`goTo${toPascal(item.name)}`">
        {{ item.name }}
      </a>
    </template>

    <template v-slot:[`item.creationDate`]="{ item }">
      {{ transformDate(item.creationDate) }}
    </template>

    <template v-slot:[`item.workspaces`]="{ item }">
      <a
        @click="openWorkspacesOfOrganization(item.id)"
        :is-empty="deployedCount(item) === 0"
        :rbt-target="`spaceCountGoTo${toPascal(item.name)}`"
        class="workspacesDeployed"
      >
        {{ deployedMessage(deployedCount(item)) }}
      </a>
    </template>

    <template v-slot:[`item.hasLicense`]="{ item }">
      <v-icon v-if="isCustomerHosted(item)" :color="hasLicenseProp(item).color">
        {{ hasLicenseProp(item).icon }}
      </v-icon>

      <span v-else>N/A</span>
    </template>

    <template v-slot:[`item.isReady`]="{ item }">
      <v-icon :color="organizationIsReady(item).color">
        {{ organizationIsReady(item).icon }}
      </v-icon>
    </template>

    <template v-slot:[`item.organizationType`]="{ item }">
      <div class="organizationType">
        <span>
          {{ organizationTypeLabel(item).text }}
        </span>

        <span v-if="item.organizationType === SaaS" class="tokens" :in-debt="orgInDebt(item)">
          ({{ organizationTypeLabel(item).tokens }} {{ $t("common.tokens") }} )
        </span>
      </div>
    </template>

    <template v-slot:[`item.certificateInfo`]="{ item }">
      <ssl-certificate-cell v-if="item.certificateInfo" :certificate="item.certificateInfo" />
      <span v-else class="noCertificate">{{ t("noCertificate") }}</span>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :rbt-target="`btnOpenMenu${toPascal(item.name)}`"
            :disabled="loading"
            color="primary"
            icon
            dark
          >
            <v-icon>navigate_more_options_vertical</v-icon>
          </v-btn>
        </template>

        <div class="listContainer">
          <div
            v-if="hasGodLevelPermissions"
            @click="$emit('edit', item.id)"
            :rbt-target="`edit${toPascal(item.name)}`"
            class="listItem organization"
          >
            <v-icon left>action_edit</v-icon>
            <span>{{ $t("Admin.organizations.table.menu.edit") }}</span>
          </div>

          <div
            v-else
            @click="$emit('edit', item.id)"
            :rbt-target="`view${toPascal(item.name)}`"
            class="listItem organization"
          >
            <v-icon left>mdi-eye</v-icon>
            <span>{{ $t("Admin.organizations.table.menu.view") }}</span>
          </div>

          <div
            v-if="hasGodLevelPermissions"
            @click="confirmRemoveOrganization(item.id)"
            :rbt-target="`delete${toPascal(item.name)}`"
            class="listItem organization"
          >
            <v-icon left>action_trash</v-icon>
            {{ $t("Admin.organizations.table.menu.delete") }}
          </div>

          <div @click="copy(item.id)" :rbt-target="`copyId${toPascal(item.name)}`" class="listItem organization">
            <v-icon left>action_copy</v-icon>
            {{ $t("Admin.organizations.table.menu.copyId") }}
          </div>
        </div>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { OrganizationItem, IOrganization, OrganizationType } from "@/types/organization"
import { copyToClipboard } from "@/utils/ui"
import { createTranslationModule, translate, translatePlural } from "@/plugins/i18n"
import { resolveChangeOrganizationId, warnOrganizationUpdate } from "@/utils/event-bus"
import { AdminModule } from "@/store/admin"
import { UsersModule } from "@/store/users"
import { navigate } from "@/router"
import Headers from "../../lib/organization-table-headers"
import Utils from "@/utils/utils"
import SslCertificateCell from "./_SSLCertificateCell.vue"

export default defineComponent({
  name: "TableOrganization",
  props: {
    items: {
      type: Array as PropType<OrganizationItem[]>,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const headers = computed(Headers)
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const t = createTranslationModule("Admin.organizations.tableCell")

    const hideTableFooter = computed(() => {
      return props.items.length <= 5
    })

    const hasGodLevelPermissions = computed(() => {
      return usersModule.hasGodPermissions
    })

    const isCustomerHosted = (organization: IOrganization) => {
      return organization.organizationType === OrganizationType.CustomerHosted
    }

    const changeOrganization = async (organizationId: string) => {
      resolveChangeOrganizationId(organizationId)
      adminModule.changeOrganization(organizationId)
      await usersModule.switchOrganization(organizationId)
      warnOrganizationUpdate()
    }

    const openOrganization = async (organizationId: string) => {
      await changeOrganization(organizationId)
      navigate({ name: "Organization", params: { organizationId } })
    }

    const openWorkspacesOfOrganization = async (organizationId: string) => {
      await changeOrganization(organizationId)
      navigate({ name: "WorkspacesList" })
    }

    const confirmRemoveOrganization = async (organizationId: string) => {
      const org = _findOrganization(organizationId)?.name.trim() ?? ""
      const text = translate("Admin.organizations.confirm.warning", { org })
      const title = translate("Admin.organizations.confirm.title", { org })
      const strictLabel = translate("Admin.organizations.confirm.passphraseDeleteOrganization", { org })

      if (await raiseConfirmation({ title, text, strictLabel })) {
        ctx.emit("delete", organizationId)
      }
    }

    const orgIsSaaS = (organization: IOrganization) => {
      return organization?.organizationType === OrganizationType.SaaS
    }

    const organizationTypeLabel = (organization: IOrganization) => {
      const isSaaS = orgIsSaaS(organization)

      if (organization?.organizationType) {
        return {
          text: translate(`organizationTypes.${organization.organizationType}`),
          tokens: isSaaS ? organization.tokens : null
        }
      }

      return {
        text: "Unknown",
        tokens: null
      }
    }

    const deployedCount = (organization: OrganizationItem) => {
      return organization.deployedWorkspaces.filter(workspace => !workspace.isCore).length
    }

    const deployedMessage = (deploys: number) => {
      const noDeploys = translate("Admin.organizations.tableCell.nothingDeployed")
      const amountDeployed = translatePlural("Admin.organizations.tableCell.workspacesDeployed", deploys, {
        count: `${deploys}`
      })

      return deploys > 0 ? amountDeployed : noDeploys
    }

    const hasLicenseProp = (organization: OrganizationItem) => {
      const hasLicense = organization.deployedWorkspaces.some(workspace => workspace.isCore)

      return {
        icon: hasLicense ? "mdi-check" : "mdi-close",
        color: hasLicense ? "success" : "error"
      }
    }

    const organizationIsReady = (organization: OrganizationItem) => {
      const isReady = organization.isReady

      return {
        icon: isReady ? "mdi-check" : "mdi-close",
        color: isReady ? "success" : "error"
      }
    }

    const orgInDebt = (organization: IOrganization) => {
      if (orgIsSaaS(organization)) {
        return organization.tokens <= 0
      }
      return false
    }

    const transformDate = (date: string) => {
      return Utils.dateWithNamedMonth(date)
    }

    const copy = (text: string) => {
      copyToClipboard(text)
    }

    const toPascal = (text: string) => {
      return Utils.toPascal(text)
    }

    const _findOrganization = (organizationId: string) => {
      return props.items.find(org => org.id === organizationId)
    }

    return {
      ...OrganizationType,
      t,
      toPascal,
      orgInDebt,
      orgIsSaaS,
      organizationIsReady,
      isCustomerHosted,
      copy,
      hasLicenseProp,
      hasGodLevelPermissions,
      organizationTypeLabel,
      deployedMessage,
      openWorkspacesOfOrganization,
      deployedCount,
      openOrganization,
      transformDate,
      confirmRemoveOrganization,
      hideTableFooter,
      headers
    }
  },
  components: {
    SslCertificateCell
  }
})
</script>
