<template>
  <div class="wrapOpenButton">
    <v-menu :nudge-bottom="4" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" :disabled="disabled" :loading="loading" large color="primary">
          {{ $t("TriCaster.buttons.open") }}
          <v-icon right :size="32">mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <ul class="listMenu">
        <li @click="$emit('open:nice-dcv')" class="listRow">{{ t("niceDcv") }}</li>
        <li @click="$emit('open:ndi-bridge')" class="listRow">{{ t("ndiBridge") }}</li>

        <li v-for="link in assetLinks" :key="link.link" @click="() => getLinkWithCredentials(link)" class="listRow">
          {{ t("open", { label: link.label }) }}
        </li>
      </ul>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import { ApplicationLink, IWorkspaceAsset } from "@/types/workspace"
import { notifyCustomMessage } from "../../lib/webview-communication"
import { useStore } from "@/composable/useStore"
import Router from "@/router"

export default defineComponent({
  name: "OpenButton",
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const { spaceModule } = useStore()
    const t = createTranslationModule("TriCaster.openOptions.")

    const workspace = computed(() => {
      return spaceModule.watching
    })

    const app = computed(() => {
      const appIdParam = Router.currentRoute.params.appId
      return workspace.value.schema?.assets.find(_app => _app.id === appIdParam) as IWorkspaceAsset
    })

    const assetLinks = computed(() => {
      const links = app.value?.applicationLinks
      return links ? links : []
    })

    const hasAssetLinks = computed(() => {
      return Boolean(app && app.value.applicationLinks && app.value.applicationLinks.length > 0)
    })

    const _openBlank = (link: string) => {
      window.open(link, "_blank")
    }

    const getLinkWithCredentials = (appLink: ApplicationLink) => {
      const encode = (snippet: string) => encodeURIComponent(snippet)

      if (appLink.credentials) {
        const { username, password } = appLink.credentials

        notifyCustomMessage({
          variant: "livepanel",
          message: {
            link: appLink.link,
            username,
            password
          }
        })

        _openBlank(`${appLink.link}?username=${username}&password=${encode(password)}`)
      } else {
        notifyCustomMessage({
          variant: "livepanel",
          message: {
            error: "Missing credentials",
            text: "Configuration missing, please contact support."
          }
        })
      }
    }

    return {
      app,
      hasAssetLinks,
      assetLinks,
      getLinkWithCredentials,
      t
    }
  }
})
</script>
