<template>
  <div class="otherOptions">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="isRunning || loading || !canSeeActionButtons"
          rbt-target="openOptions"
          large
        >
          <v-icon>navigate_more_options_vertical</v-icon>
        </v-btn>
      </template>

      <v-list class="otherOptionsList">
        <v-list-item @click="refresh">
          <v-list-item-title>
            <div class="optionItem">
              <v-icon left>action_refresh</v-icon>
              <span>{{ $t("WorkspaceEdit.otherOptions.buttons.refresh") }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isDeployed && hasAdminPermissions" @click="confirmDeployThenEmit">
          <v-list-item-title>
            <div class="optionItem">
              <v-icon left>action_reset</v-icon>
              <span>{{ $t("WorkspaceEdit.otherOptions.buttons.redeploy") }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isDeployed && isEligible" @click="openFileTransferDialog">
          <v-list-item-title>
            <div class="optionItem">
              <v-icon left>mdi-file-swap-outline</v-icon>
              <span>{{ $t("WorkspaceEdit.otherOptions.buttons.fileTransfer") }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="vpcPermission" @click="openVpcPeering">
          <v-list-item-title>
            <div class="optionItem">
              <v-icon left>mdi-lan-connect</v-icon>
              <span>{{ $t("WorkspaceEdit.otherOptions.buttons.vpcPeering") }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="canDownloadDotNowFile" @click="$emit('download:dot-now-file')">
          <v-list-item-title>
            <div class="optionItem">
              <v-icon left>mdi-file-cog-outline</v-icon>
              <span>{{ $t("WorkspaceEdit.otherOptions.buttons.downloadDotNowFile") }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isDeployed" @click="$emit('open:auto-shutdown')" robot-target="autoShutdown">
          <v-list-item-title>
            <div class="optionItem">
              <v-icon left>multimedia_seconds</v-icon>
              <span>{{ $t("WorkspaceEdit.otherOptions.buttons.openAutoShutdown") }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="isDeployed || isStatus(Archived) || itFailed" @click="confirmDestroyThenEmit">
          <v-list-item-title>
            <div class="optionItem destroy">
              <v-icon left>action_close_circle</v-icon>
              <span>{{ $t("WorkspaceEdit.otherOptions.buttons.destroy") }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-else @click="confirmDeleteThenEmit" rbt-target="removeDraftWorkspace">
          <v-list-item-title>
            <div class="optionItem">
              <v-icon left>action_trash</v-icon>
              <span>{{ $t("WorkspaceEdit.otherOptions.buttons.delete") }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { WorkspaceStates } from "@/config/workspace-states"
import { WorkspaceStatusEnum, WorkspaceTemplate } from "@/types/workspace"
import { AdminModule, UsersModule } from "@/store"
import { OrganizationType } from "@/types/organization"

export default defineComponent({
  name: "OtherOptions",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()
    const adminModule = AdminModule()

    const workspaceStatus = computed(() => {
      return props.workspace.status
    })

    const isDeployed = computed(() => {
      return WorkspaceStates.isDeployed(workspaceStatus.value)
    })

    const itFailed = computed(() => {
      return WorkspaceStates.failed(workspaceStatus.value)
    })

    const isRunning = computed(() => {
      return WorkspaceStates.isLoading(workspaceStatus.value)
    })

    const isEligible = computed(() => {
      return props.workspace.fileTransferEligible
    })

    const canDownloadDotNowFile = computed(() => {
      const isSaaSOrganization = adminModule.organizationDetail.organizationType === OrganizationType.SaaS
      return isSaaSOrganization && usersModule.hasAdminPermissions
    })

    const canSeeActionButtons = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const vpcPermission = computed(() => {
      const isOwner = props.workspace.userId === usersModule.selfDetail.id
      const isAdminLevel = usersModule.hasAdminPermissions
      const templateAllowsCustomPeering = props.workspace.customPeeringAllowed

      return isDeployed.value && templateAllowsCustomPeering && (isOwner || isAdminLevel)
    })

    const hasAdminPermissions = computed(() => {
      return usersModule.hasAdminPermissions
    })

    const confirmDeleteThenEmit = async () => {
      const title = translate("WorkspaceEdit.otherOptions.confirm.deleteTitle")
      const text = translate("WorkspaceEdit.otherOptions.confirm.delete")

      if (await raiseConfirmation({ text, title })) {
        ctx.emit("delete")
      }
    }

    const confirmDestroyThenEmit = async () => {
      const title = translate("WorkspaceEdit.otherOptions.confirm.deleteTitle")
      const text = translate("WorkspaceEdit.otherOptions.confirm.destroy")
      const strictLabel = translate("WorkspaceEdit.otherOptions.confirm.deleteSnippet")
      const buttonConfirmLabel = translate("WorkspaceEdit.otherOptions.confirm.confirmDelete")

      if (await raiseConfirmation({ title, text, strictLabel, buttonConfirmLabel })) {
        ctx.emit("destroy")
      }
    }

    const confirmDeployThenEmit = async () => {
      const title = translate("WorkspaceEdit.otherOptions.confirm.confirmRedeployTitle")
      const text = translate("WorkspaceEdit.otherOptions.confirm.confirmRedeploy")

      if (await raiseConfirmation({ title, text })) {
        ctx.emit("deploy")
      }
    }

    const isStatus = (status: WorkspaceStatusEnum) => {
      return props.workspace.status === status
    }

    const refresh = () => {
      ctx.emit("refresh")
    }

    const openFileTransferDialog = () => {
      ctx.emit("open:file-transfer")
    }

    const openVpcPeering = () => {
      ctx.emit("open:vpc-peering")
    }

    return {
      ...WorkspaceStatusEnum,
      canDownloadDotNowFile,
      vpcPermission,
      itFailed,
      openVpcPeering,
      isEligible,
      openFileTransferDialog,
      hasAdminPermissions,
      canSeeActionButtons,
      confirmDeleteThenEmit,
      confirmDeployThenEmit,
      confirmDestroyThenEmit,
      refresh,
      isDeployed,
      isRunning,
      isStatus
    }
  }
})
</script>
