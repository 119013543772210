<template>
  <div v-if="viewSettings.vizNow" class="appContainer" session>
    <div v-if="contentReady" class="transitionItem">
      <router-view class="animateSimple" />
      <app-footer />
    </div>
  </div>

  <div v-else-if="viewSettings.appLauncher" class="appLauncherContainer">
    <router-view class="animateSimple" />
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import { getPlatformViewSettings } from "@/utils/view-settings"
import { eventBus } from "@/main"
import router, { navigateAttemptedRoute } from "@/router"
import AppFooter from "@/components/appFooter/AppFooterRoot.vue"

export default defineComponent({
  name: "AuthParent",
  setup() {
    const viewSettings = ref(getPlatformViewSettings())
    const usersModule = UsersModule()

    const contentReady = computed(() => {
      return Boolean(usersModule.selfDetail?.id)
    })

    const onStart = async () => {
      const isAuthenticatedRoute = router.currentRoute.meta?.requiresAuth

      if (!isAuthenticatedRoute) {
        navigateAttemptedRoute()
      }
    }

    const _updateViewSettings = () => {
      viewSettings.value = getPlatformViewSettings()
    }

    onStart()
    eventBus.$on("swap-view-settings", _updateViewSettings)

    return {
      viewSettings,
      contentReady
    }
  },
  components: {
    AppFooter
  }
})
</script>
