<template>
  <h3 class="averageTime">
    {{ feedback }}
  </h3>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "AverageTime",
  props: {
    feedback: {
      type: String,
      required: true
    }
  }
})
</script>
