import { v4 as uuid } from "uuid"
import { VpcItem } from "./type"

export const createVpcStructure = () => {
  return {
    id: uuid(),
    isNew: true,
    name: "Untitled",
    ownerAccountId: "",
    peerVpcId: "",
    peerCidr: "",
    peeringConnectionId: "",
    peerWorkspaceId: "",
    requiresManualAction: false,
    peerRegion: "",
    peeringStatus: null
  } as VpcItem
}
