const REGEX_ARN = /^arn:aws:iam::\d{12}:role\/[\w+=,.@-]+/
const REGEX_ACCOUNT_ID = /::(.*):/

export const validateArn = (arnCode: string, feedback = "Invalid ARN") => {
  const regexValidation = REGEX_ARN.test(arnCode)

  if (regexValidation) {
    return true
  }

  return feedback
}

export const extractAccountId = (arnCode: string) => {
  const match = arnCode.match(REGEX_ACCOUNT_ID)
  let accountId = ""

  if (match?.length) {
    match.forEach(snippet => {
      if (!snippet.includes(":") && !isNaN(Number(snippet))) {
        accountId = snippet
      }
    })
  }

  return accountId
}
