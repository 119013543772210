import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"instructions"},[_c('Dialog',{attrs:{"title":_vm.$t('WorkspaceEdit.instructions.title'),"max-width":"60vw","instruction":"","hide-actions":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"rbt-target":"openInstruction","large":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("vizartist_show_scene_script")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.instructions.buttons.open")))])],1)]}},{key:"root",fn:function(){return [_c('div',{attrs:{"id":"instructionsModal"}},[_c('div',{staticClass:"instructionWrapper"},[(_vm.instructionsEditorActive)?_c('div',{staticClass:"editorWrap"},[_c('html-editor',{model:{value:(_vm.tempInstruction),callback:function ($$v) {_vm.tempInstruction=$$v},expression:"tempInstruction"}})],1):_c('div',{staticClass:"wrapContent"},[(_vm.loading)?_c(VProgressLinear,{staticClass:"my-0",attrs:{"color":"primary","indeterminate":""}}):_vm._e(),_c('html-viewer',{attrs:{"content":_vm.instructionMsg,"overflow-auto":""}})],1)]),(_vm.canEditPermission)?_c('div',{staticClass:"instructionController"},[(_vm.instructionsEditorActive)?_c('div',{staticClass:"editingControllers"},[_c(VBtn,{attrs:{"large":""},on:{"click":_vm.cancel}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_remove_circle_outline")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.instructions.buttons.cancel")))])],1),_c(VBtn,{attrs:{"color":"primary","rbt-target":"saveInstructions","large":""},on:{"click":_vm.save}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_save")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.instructions.buttons.save")))])],1)],1):_c(VBtn,{attrs:{"disabled":_vm.loading,"rbt-target":"editInstructions","large":""},on:{"click":function($event){_vm.instructionsEditorActive = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_edit")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.instructions.buttons.edit")))])],1)],1):_vm._e()])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }