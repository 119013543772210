import { getEnv } from "@/utils/env"

const VIZ_NOW_ACCOUNT_ID = getEnv("VUE_APP_ONBOARDING_VIZ_NOW_ACCOUNT_ID")

export default {
  deployAgentArn() {
    return `https://console.aws.amazon.com/iam/home#/roles$new?step=tags&roleType=crossAccount&isThirdParty&accountID=324880187172&externalID=SpaceliftIntegration@*&roleName=viz-now-deploy-infra`
  },
  vizNowArn(awsExternalId: string) {
    return `https://console.aws.amazon.com/iam/home#/roles$new?step=tags&roleType=crossAccount&isThirdParty&accountID=${VIZ_NOW_ACCOUNT_ID}&externalID=${awsExternalId}&roleName=viz-now-manage`
  }
}
