import { Dictionary } from "@/types/core"
import { UserRoleEnum } from "@/types/user"
import { roleImportanceByOrder } from "@/config/user-role-importance"

const ROLE_IMPORTANCE: Dictionary<number> = {}

const _getRolePoints = () => {
  if (Object.values(ROLE_IMPORTANCE).length === 0) {
    roleImportanceByOrder.reverse().forEach((roleName, index) => {
      ROLE_IMPORTANCE[roleName] = index
    })
  }

  return ROLE_IMPORTANCE
}

export const getRoleImportance = (role: UserRoleEnum) => {
  const rolePoints = _getRolePoints()
  return rolePoints[role]
}
