<template>
  <value-setter v-if="isType(ValueType.createOnly)" @done="edit" :loading="loading" :current-value="currentValue" />

  <value-selector
    v-else
    @done="edit"
    :loading="loading"
    :current-value="currentValue"
    :options="tagOptions"
    :type="valueSetterType"
  />
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { TagOptions } from "@/types/organization"
import ValueSetter from "../../tagSearchField/components/_ValueSetter.vue"
import ValueSelector from "../../tagSearchField/components/_ValueSelector.vue"

export enum ValueType {
  createOnly = "CreateOnly",
  selectOnly = "SelectOnly",
  createOrSelect = "CreateOrSelect"
}

export default defineComponent({
  name: "HandleSelector",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    currentValue: {
      type: String,
      default: ""
    },
    settings: {
      type: Object as PropType<TagOptions>,
      required: false
    }
  },
  setup(props, ctx) {
    const valueSetterType = computed(() => {
      if (props.settings && props.settings.options.length > 0) {
        if (props.settings.allowNewValues) {
          return ValueType.createOrSelect
        } else {
          return ValueType.selectOnly
        }
      }

      return ValueType.createOnly
    })

    const tagOptions = computed(() => {
      return props.settings?.options || []
    })

    const isType = (type: ValueType) => {
      return valueSetterType.value === type
    }

    const edit = (value: string) => {
      ctx.emit("edit", value)
    }

    return {
      ValueType,
      valueSetterType,
      edit,
      tagOptions,
      isType
    }
  },
  components: {
    ValueSelector,
    ValueSetter
  }
})
</script>
