<template>
  <div class="nestedWizardFunctions">
    <div v-for="item in settings" :key="item.resourceKey" class="nestedChild" :locked="disabled">
      <component
        v-model="item.settings"
        @validate="emit('validate')"
        :label="item.label"
        :was-deployed="wasDeployed"
        :is="useComponent(item.type)"
        :resource-key="item.resourceKey"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, PropType } from "@vue/composition-api"
import { WizardConfigItem, WizardWidget } from "@/types/wizard"
import { IAssetModel, WorkspaceTemplate } from "@/types/workspace"
import Utils from "@/utils/utils"
import * as Widget from "./index"
import "./scss/_wizard.scss"

export default defineComponent({
  name: "NestedWizardFunctions",
  props: {
    value: {
      type: Array as PropType<WizardConfigItem[]>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: true
    },
    wasDeployed: {
      type: Boolean,
      default: false
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    app: {
      type: Object as PropType<IAssetModel>,
      required: true
    }
  },
  setup(props, ctx) {
    const settings = Utils.vModel(props, ctx.emit)

    const useComponent = (widgetType: WizardWidget) => {
      const widget = Widget[widgetType]

      if (widget) {
        return widget
      } else {
        throw Error(`Invalid widget, tried using ${widgetType}`)
      }
    }

    provide("workspace", props.workspace)
    provide("app", props.app)

    return {
      emit: ctx.emit,
      settings,
      useComponent
    }
  },
  components: {
    ...Widget
  }
})
</script>
