<template>
  <div id="share" rbt-target="shareContainer">
    <tooltip :text="tooltipLabel">
      <v-switch
        v-if="canChangePrivacy"
        v-model="workspace.isShared"
        @change="changeSharedState"
        :label="publicLabel"
        :disabled="loading"
        color="primary"
        class="my-0 py-0"
        rbt-target="shareWorkspace"
        hide-details
      />
    </tooltip>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { translate } from "@/plugins/i18n"
import { UsersModule } from "@/store/users"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "Share",
  props: {
    value: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const workspace = Utils.vModel(props, ctx.emit)
    const usersModule = UsersModule()

    const canChangePrivacy = computed(() => {
      const isOwner = workspace.value.userId === usersModule.selfDetail.id
      return usersModule.hasAdminPermissions || isOwner
    })

    const publicLabel = computed(() => {
      return workspace.value.isShared
        ? translate("WorkspaceEdit.share.label.shared")
        : translate("WorkspaceEdit.share.label.notShared")
    })

    const tooltipLabel = computed(() => {
      if (!canChangePrivacy.value) {
        return translate("WorkspaceEdit.share.tooltip.noPermission")
      }

      return workspace.value.isShared
        ? translate("WorkspaceEdit.share.tooltip.shared")
        : translate("WorkspaceEdit.share.tooltip.notShared")
    })

    const changeSharedState = () => {
      ctx.emit("change", workspace.value.isShared)
    }

    return {
      canChangePrivacy,
      changeSharedState,
      tooltipLabel,
      publicLabel,
      workspace
    }
  },
  components: {
    Tooltip
  }
})
</script>
