<template>
  <div class="textEditor">
    <v-form @submit.prevent ref="Form">
      <v-text-field
        v-model="tempText"
        @change="change"
        @keydown.esc="cancel"
        :rules="rules"
        :hide-details="shouldHideDetails"
        :rbt-target="cypressAnchor"
      />

      <div class="iconControllers">
        <v-btn @click="change" rbt-target="saveChanges" icon>
          <v-icon>action_save</v-icon>
        </v-btn>

        <v-btn @click="cancel" rbt-target="discardChanges" icon>
          <v-icon>action_close</v-icon>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api"
import { MandatoryTextField } from "@/utils/input-rules"
import { hideDetails } from "@/utils/input-utils"
import { VForm } from "@/types/core"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "Editor",
  props: {
    value: {
      type: String,
      required: true
    },
    noRules: {
      type: Boolean,
      default: false
    },
    cypressAnchor: {
      type: String,
      required: false
    }
  },
  setup(props, ctx) {
    const actualValue = Utils.vModel(props, ctx.emit)
    const Form = ref<null | VForm>(null)
    const tempText = ref<string>(props.value)
    const rules = props.noRules ? false : MandatoryTextField

    const shouldHideDetails = computed(() => {
      if (rules) {
        return hideDetails<string>(rules, tempText.value)
      }

      return true
    })

    const change = () => {
      if (Form.value && Form.value.validate()) {
        actualValue.value = tempText.value
        ctx.emit("change", tempText.value)
      }
    }

    const cancel = () => {
      ctx.emit("cancel")
    }

    return {
      rules,
      shouldHideDetails,
      tempText,
      change,
      cancel,
      Form
    }
  }
})
</script>
