<template>
  <div id="notFound">
    <notify-not-found v-if="isAuthenticated" />
    <no-auth-screen v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { sessionOn } from "@/auth"
import WorkspacesList from "@/views/workspaceList/WorkspacesRoot.vue"
import Login from "@/views/login/LoginRoot.vue"
import NoAuthScreen from "./components/NoAuth.vue"
import NotifyNotFound from "./components/NotifyNotFound.vue"
import "./scss/_notFound.scss"

export default defineComponent({
  components: {
    WorkspacesList,
    Login,
    NoAuthScreen,
    NotifyNotFound
  },
  setup() {
    const isAuthenticated = computed(() => {
      return sessionOn()
    })

    return {
      isAuthenticated
    }
  }
})
</script>
