import { FileProgress, Id } from "@/types/core"

const PROGRESS = {
  beforeCreate: "pending",
  created: "inLine",
  running: "running",
  done: "complete",
  error: "error"
}

const ERROR = {
  invalidProgressValue: "Progress max value is 100",
  restartingCompletedFile: "File is already complete, but trying to update progress"
}

interface CachedFileProgress {
  [key: string]: FileProgress
}

export default class FileProgressHandler {
  private buffer = 0
  private progressStatus = PROGRESS.beforeCreate
  private filename!: string
  private fileId: string | null = null

  private cachedFiles: CachedFileProgress = {}

  private insertFile(filename: string): void {
    if (!filename) {
      return
    }

    if (!this.cachedFiles[filename]) {
      this.cachedFiles[filename] = {
        id: null,
        name: filename,
        progress: 0,
        error: this.progressStatus === PROGRESS.error
      }
    }

    this.filename = filename
    this.buffer = 0
    this.progressStatus = PROGRESS.created
  }

  private updateCache(): void {
    if (this.cachedFiles[this.filename]) {
      this.cachedFiles[this.filename] = {
        id: this.fileId,
        name: this.filename,
        progress: this.buffer,
        error: this.progressStatus === PROGRESS.error
      }

      this.cachedFiles = Object.assign({}, { ...this.cachedFiles })
    }
  }

  constructor(filename = "") {
    this.insertFile(filename)
  }

  public get getProgress(): FileProgress {
    return {
      id: this.fileId,
      progress: this.buffer,
      name: this.filename,
      error: this.progressStatus === PROGRESS.error
    }
  }

  public get status(): string {
    return this.progressStatus
  }

  public get fileCount(): number {
    return Object.keys(this.cachedFiles).length
  }

  public restart(filename: string): void {
    this.insertFile(filename)
  }

  public finish(): void {
    this.progress(100)
  }

  public error(): void {
    this.progressStatus = PROGRESS.error
    this.buffer = 0
    this.breakProgress(this.filename)
  }

  public trackProgress(filename: string, progress: number, id: null | Id = null): number {
    this.cachedFiles[filename] = {
      id: id,
      name: filename,
      progress: progress,
      error: this.progressStatus === PROGRESS.error
    }

    this.cachedFiles = Object.assign({}, { ...this.cachedFiles })

    if (this.filename === filename) {
      this.buffer = progress
      return this.buffer
    }

    return 0
  }

  public progress(value: number, id: null | string = null): void {
    if (this.status === PROGRESS.done) throw ERROR.restartingCompletedFile
    if (value > 100) throw ERROR.invalidProgressValue

    if (value < 100) {
      this.progressStatus = PROGRESS.running
    } else {
      this.progressStatus = PROGRESS.done
    }

    if (id) {
      this.fileId = id
    }

    this.buffer = value
    this.updateCache()
  }

  public breakProgress(filename: string): void {
    const file = this.cachedFiles[filename]

    file.error = true
    file.progress = 0
  }

  public release(filename: string): void {
    delete this.cachedFiles[filename]
  }

  public track(filename: string): FileProgress | null {
    return this.cachedFiles[filename] ?? null
  }
}
