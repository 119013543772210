import { SimpleItem } from "@/types/core"
import { FeedbackPayload } from "@/types/feedback"
import Utils from "@/utils/utils"

const getFeedType = (feedbackType: string | SimpleItem | null): string | null => {
  const selectItem = Utils.isType<SimpleItem>(feedbackType, "id")

  if (!feedbackType) {
    return null
  } else if (selectItem) {
    return selectItem.id
  }

  return feedbackType as string
}

export const feedbackAdaptor = (payload: FeedbackPayload): FeedbackPayload => {
  const feedbackType = getFeedType(payload.feedbackType)

  return {
    ...payload,
    ...(feedbackType && { feedbackType })
  }
}
