<template>
  <div id="uploadContainer">
    <div class="wrapList" v-if="fileList.length">
      <file-list
        @remove="removeFile"
        :disabled="disabled"
        :loading="loading || appRequestingData"
        :file-list="fileList"
      />
    </div>

    <div class="wrapUpload">
      <uploader
        @loading="status => $emit('loading', status)"
        @complete="beforeComplete"
        @upload="autoRemoveOldFiles"
        @remove:duplicates="removeBulkFiles"
        :multiple="multiple"
        :context-id="internalContextId"
        :context="collectionContext"
        :second-context-key="secondContextKey"
        :standalone="standalone"
        :is-empty="fileList.length === 0"
        :clearOnReady="clearOnReady"
        :disabled="disabled"
        :formats="formats"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, provide, ComputedRef } from "@vue/composition-api"
import { v4 as uuidv4 } from "uuid"
import { MediaContext, UploadContext } from "./template/context"
import { FileUploadPayload } from "@/types/core"
import { UploadModule } from "@/store/upload"
import Uploader from "./components/Uploader.vue"
import FileList from "./components/FileList.vue"
import FileMetadata from "./FileMetadata.vue"
import "./scss/_upload.scss"

export { MediaContext as UploadContext }

export default defineComponent({
  name: "UploaderRoot",
  props: {
    fileList: {
      type: Array as PropType<FileUploadPayload[]>,
      default: () => []
    },
    formats: {
      type: String,
      default: "*"
    },
    multiple: {
      type: Boolean,
      default: true
    },
    standalone: {
      type: Boolean,
      default: true
    },
    context: {
      type: String,
      required: true
    },
    contextId: {
      type: String,
      default: uuidv4()
    },
    secondContextKey: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearOnReady: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const appRequestingData = ref(false)
    const uploadModule = UploadModule()
    const internalContextId = ref(props.contextId)

    provide<ComputedRef<FileUploadPayload[]>>(
      "fileList",
      computed(() => props.fileList)
    )

    const collectionContext = computed(() => {
      const ctxName = UploadContext[props.context]

      if (ctxName) {
        return ctxName
      }

      throw Error("UPLOAD ERROR: Context invalid")
    })

    const beforeComplete = (contextId: string) => {
      ctx.emit("complete", contextId)

      if (props.standalone) {
        internalContextId.value = uuidv4()
      }
    }

    const removeBulkFiles = async (files: FileUploadPayload[]) => {
      await Promise.all(
        files.map(async _file => {
          await removeFile(_file)
        })
      )
    }

    const _removeOldFiles = async (file: FileUploadPayload, buffer: FileMetadata) => {
      try {
        appRequestingData.value = true

        await removeBulkFiles(props.fileList)

        ctx.emit("upload", file, buffer)
      } finally {
        appRequestingData.value = false
      }
    }

    const autoRemoveOldFiles = async (file: FileUploadPayload, buffer: FileMetadata) => {
      if (props.multiple === false && props.fileList.length > 0) {
        _removeOldFiles(file, buffer)
      } else {
        ctx.emit("upload", file, buffer)
      }
    }

    const removeFile = async (file: FileUploadPayload): Promise<void> => {
      appRequestingData.value = true

      try {
        await uploadModule.removeFile(file)
        ctx.emit("update")
        ctx.emit("update:removed-file")
      } finally {
        appRequestingData.value = false
      }
    }

    return {
      internalContextId,
      beforeComplete,
      collectionContext,
      appRequestingData,
      removeBulkFiles,
      removeFile,
      autoRemoveOldFiles
    }
  },
  components: {
    Uploader,
    FileList
  }
})
</script>
