<template>
  <div @click="$emit('open:app', app)" :locked="appsLocked || locked" :type="type" class="workspaceAppCard">
    <div :type="type" :status="workspace.status" :online-status="app.onlineStatus" class="appHeaderContainer">
      <app-header
        @set:online="state => $emit('set:online', state, appId)"
        :online-state="app.onlineStatus"
        :is-deployed="appIsDeployed"
        :loading="loading"
        :workspace="workspace"
        :app-id="app.id"
        :running-since="app.runningSince"
        :flag-type="type"
      />
    </div>

    <div class="appBodyContainer" rbt-target="appContainer">
      <app-body
        @copy:password="$emit('copy:password')"
        :app-is-deployed="appIsDeployed"
        :is-deployed="workspaceIsDeployed"
        :loading="loading"
        :app-model="appModel"
        :workspace="workspace"
        :app="app"
        :flag-type="type"
      />
    </div>

    <div v-if="appIsForWindowsInstance && !wasRemoved" class="appFooterContainer">
      <app-footer
        @open:instance="$emit('open:instance')"
        @download:shortcut="$emit('download:shortcut')"
        @download:app-launcher="$emit('download:dot-now')"
        :app="app"
        :loading="loading"
        :is-online="appIsOnline"
        :flag-type="type"
        :is-deployed="appIsDeployed"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import { AppDiffType } from "@/utils/workspaceDiff"
import { WorkspaceStates } from "@/config/workspace-states"
import AppHeader from "./AppHeader.vue"
import AppBody from "./AppBody.vue"
import AppFooter from "./AppFooter.vue"

import {
  IWorkspaceAsset,
  WorkspaceOnlineStatusEnum,
  WorkspaceStatusEnum,
  InstancePlatform,
  WorkspaceTemplate
} from "@/types/workspace"

export default defineComponent({
  name: "AppCard",
  props: {
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    deploy: {
      type: String as PropType<WorkspaceStatusEnum>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    type: {
      type: String as PropType<AppDiffType>,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props) {
    const workspaceModule = WorkspaceModule()

    const appsLocked = computed(() => {
      return WorkspaceStates.isLoading(props.deploy)
    })

    const appModel = computed(() => {
      return workspaceModule.assetModels.find(app => app.key === props.app.type)
    })

    const appId = computed(() => {
      return props.app.id
    })

    const appIsForWindowsInstance = computed(() => {
      const { windows } = InstancePlatform
      return [windows, null].includes(props.app.instancePlatform)
    })

    const appIsDeployed = computed(() => {
      const deployedAssetCollection: IWorkspaceAsset[] | undefined = props.workspace.schemaDeployed?.assets

      if (deployedAssetCollection) {
        return deployedAssetCollection.some(_app => _app.id === appId.value)
      }

      return false
    })

    const wasRemoved = computed(() => {
      return props.type === "removed"
    })

    const appIsOnline = computed(() => {
      return props.app.onlineStatus === WorkspaceOnlineStatusEnum.Online
    })

    const workspaceIsDeployed = computed(() => {
      return WorkspaceStates.isDeployed(props.workspace.status)
    })

    return {
      workspaceIsDeployed,
      wasRemoved,
      appIsForWindowsInstance,
      appIsOnline,
      appModel,
      appsLocked,
      appIsDeployed,
      appId
    }
  },
  components: {
    AppHeader,
    AppBody,
    AppFooter
  }
})
</script>
