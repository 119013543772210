import { IOrganization } from "./organization"

export enum UserRoleEnum {
  SysAdmin = "sysAdmin",
  Support = "supportUser",
  Admin = "admin",
  User = "user",
  ServiceUser = "serviceUser"
}

export interface CreateUserPayload {
  firstName: string
  lastName: string
  email: string
  role: UserRoleEnum
}

export interface IUser {
  id: string
  email: string
  firstName: string
  lastName: string
  initials: string
  organizationId: string
  organization: IOrganization
  role: UserRoleEnum
  creationDate: Date
  modifyDate?: Date
  disabled: boolean
  acceptedTerms: boolean
  changeLogViewed: boolean
}

export interface EditUserPayload {
  id: string
  firstName: string
  lastName: string
  role: string
  disabled: boolean
}
