<template>
  <div class="appContainer">
    <div class="transitionItem">
      <router-view class="animateSimple" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import router, { navigate } from "@/router"

export default defineComponent({
  name: "NonAuthParent",
  setup() {
    const _resolvedPath = computed(() => {
      const { name, params, query } = router.currentRoute
      return router.resolve({ name: name as string, params, query }).href
    })

    const _cacheResolvedPath = () => {
      sessionStorage.setItem("attemptedRouteDirect", _resolvedPath.value)
    }

    const onStart = async () => {
      const isAuthenticatedRoute = router.currentRoute.meta?.requiresAuth

      if (isAuthenticatedRoute) {
        _cacheResolvedPath()
        navigate({ name: "Login" })
      }
    }

    onStart()
  }
})
</script>
