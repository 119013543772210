const CHAT_ID = "C68D1D7F46EBED0335DCA804983A9350A"

interface ChatMetadata {
  workspaceId: string
  email: string
  fullName: string
  organizationId: string
}

const links = {
  js: [
    "https://uschat3.contivio.com/Chat2/ContivioChatPlugin.js",
    "https://uschat3.contivio.com/Chat2/contivio-configuration-plugin.js"
  ],
  css: [`https://uschat3.contivio.com/Chat2/CustomStyles/${CHAT_ID}.css`]
}

export const updateChatMetadata = (chatMetadata: ChatMetadata) => {
  const chatMetadataEvent = new CustomEvent("chat:metadata", {
    bubbles: true,
    detail: {
      workspaceId: chatMetadata.workspaceId,
      email: chatMetadata.email,
      fullName: chatMetadata.fullName,
      organizationId: chatMetadata.organizationId
    }
  })

  document.dispatchEvent(chatMetadataEvent)
}

export const addChat = () => {
  links.css.forEach(link => {
    const cssLink = document.createElement("link")
    cssLink.setAttribute("rel", "stylesheet")
    cssLink.href = link
    cssLink.classList.add("chatLink")
    document.head.appendChild(cssLink)
  })

  links.js.forEach(link => {
    const jsLink = document.createElement("script")
    jsLink.src = link
    jsLink.classList.add("chatLink")
    document.head.appendChild(jsLink)
  })
}

export const removeChat = () => {
  const nodeAnchors = ["#chat-button", ".chat-window", ".eye-capture-container"]

  document.head.querySelectorAll(".chatLink").forEach(node => {
    if (node) {
      const $parent = node.parentNode
      $parent?.removeChild(node)
    }
  })

  _removeContivioSignatures()

  nodeAnchors.forEach(anchor => {
    const $node = document.querySelector(anchor)

    if ($node) {
      const $parent = $node.parentNode
      $parent?.removeChild($node)
    }
  })
}

const _removeContivioSignatures = () => {
  document.body.classList.remove("contivio-desktop")
  document.body.removeAttribute("contivio-lang")

  document.querySelectorAll("contivio-config").forEach(node => {
    const $parent = node.parentElement
    $parent?.removeChild(node)
  })
}
