<template>
  <div class="timer autoShutdownSetting">
    <div class="timerSetting">
      <div class="inputRow">
        <p>{{ t("shutdownAfter") }}</p>
        <v-text-field v-model="shutdownLabel" disabled hide-details />
        <p>{{ t("hrsUptime") }}</p>
      </div>

      <v-slider
        v-model="settings.shutdownTimerInHours"
        :max="defaults.MAX_SHUTDOWN_TIME"
        :min="defaults.MIN_SHUTDOWN_TIME"
        :step="defaults.SHUTDOWN_STEP_VALUE"
        :tick-labels="tickLabels"
        :height="1"
        :readonly="readonly"
        class="animateFadeUp sliderForTimer"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { ShutdownSettings } from "@/views/appLauncher/lib/types"
import { createTranslationModule } from "@/plugins/i18n"
import Utils from "@/utils/utils"

const defaults = {
  MAX_SHUTDOWN_TIME: 12,
  MIN_SHUTDOWN_TIME: 1,
  SHUTDOWN_STEP_VALUE: 0.5
}

export default defineComponent({
  name: "TimerSettings",
  props: {
    value: {
      type: Object as PropType<ShutdownSettings>,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const t = createTranslationModule("AutoShutdown.timerSettings")
    const settings = Utils.vModel(props, ctx.emit)

    const shutdownLabel = computed(() => {
      const [h, min] = `${settings.value.shutdownTimerInHours}`.split(".").map(s => Number(s))
      return `${h}:${min ? "30" : "00"}`
    })

    const tickLabels = computed(() => {
      const tickCount = []

      for (let x = 1; x < defaults.MAX_SHUTDOWN_TIME + 1; x += 0.5) {
        const suffix = t("hours", {}, 1)

        if (x === parseInt(`${x}`)) {
          tickCount.push(x + suffix)
        } else {
          tickCount.push("")
        }
      }

      return tickCount
    })

    return {
      t,
      tickLabels,
      shutdownLabel,
      settings,
      defaults
    }
  }
})
</script>
