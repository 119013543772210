<template>
  <div class="loopContainer" :loading="loading">
    <div
      v-for="template in templates"
      @click="editTemplate(template)"
      :key="template.id"
      :disabled="!hasGodLevelPermissions"
      class="animateFadeUp templateItem"
    >
      <span class="templateTitle">
        <div class="name">
          {{ template.title }}

          <tooltip :text="$t('Admin.spaceTemplate.templates.tooltip.templateName')">
            <v-icon>status_info</v-icon>
          </tooltip>
        </div>

        <span v-if="template.description" class="description">
          {{ template.description }}
        </span>

        <span v-else class="emptyDescription">{{ $t("Admin.spaceTemplate.templates.noDescription") }}</span>
      </span>

      <span class="templateTags">
        <span class="plan">{{ template.plan }}</span>
        <span class="tags">{{ templateTags(template.tags) }}</span>
      </span>

      <span v-if="deployPackage(template.deployPackageId)" class="templateDeployPackage">
        <span class="packageName">
          {{ deployPackage(template.deployPackageId).name }}

          <tooltip :text="$t('Admin.spaceTemplate.templates.tooltip.packageSelected')">
            <v-icon right>status_info</v-icon>
          </tooltip>
        </span>

        <span class="packageDescription">
          {{ deployPackage(template.deployPackageId).description }}
        </span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceRawTemplate } from "@/types/workspace"
import { IDeployPackage } from "@/types/deployPackages"
import { UsersModule } from "@/store"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "TemplateOptions",
  props: {
    templates: {
      type: Array as PropType<WorkspaceRawTemplate[]>,
      required: true
    },
    deployPackages: {
      type: Array as PropType<IDeployPackage[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()

    const hasGodLevelPermissions = computed(() => {
      return usersModule.hasGodPermissions
    })

    const templateTags = (tags: string[]) => {
      const collectedTags = tags.reduce((tagCollection: string, tag) => {
        return tagCollection + `${tag}, `
      }, "")

      return collectedTags.substr(0, collectedTags.length - 2)
    }

    const deployPackage = (packageId: string) => {
      if (packageId) {
        return props.deployPackages.find(packageItem => packageItem.id === packageId)
      }

      return null
    }

    const editTemplate = (template: WorkspaceRawTemplate) => {
      if (hasGodLevelPermissions.value) {
        ctx.emit("edit", template)
      }
    }

    return {
      editTemplate,
      hasGodLevelPermissions,
      deployPackage,
      templateTags
    }
  },
  components: {
    Tooltip
  }
})
</script>
