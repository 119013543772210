<template>
  <ul class="sheetLinks">
    <li v-for="item in sheetLinks()" :key="item.name">
      <a :href="item.link" ref="noreferrer" target="_blank">{{ item.text }}</a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import sheetLinks from "../template/links"

export default defineComponent({
  name: "SheetLinks",
  setup() {
    return {
      sheetLinks
    }
  }
})
</script>
