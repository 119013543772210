import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

import en from "vuetify/src/locale/en"
import pt from "vuetify/src/locale/pt"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      dark: {
        primary: "#6078ea",
        accent: "#e89210",
        error: "#f75b5e",
        info: "#fcdc5c",
        success: "#4de18a",
        warning: "#fcdc5c",
        dark: "#333333",
        black: "#1e2226"
      }
    }
  },
  lang: {
    locales: { en, pt },
    current: "en"
  }
})
