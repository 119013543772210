<template>
  <div id="appLauncherCollection">
    <grid-card
      v-for="card in spaces"
      @click="goToAppLauncher(card.id)"
      @click:ctrl="goToAppLauncher(card.id)"
      :key="card.id"
      :workspace="card"
      prevent-navigation
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { WorkspaceModule } from "@/store"
import Loader from "@/utils/loader"
import GridCard from "../workspaceList/components/gridView/GridCard.vue"
import Router, { navigate } from "@/router"
import "./appLauncherCollection.scss"

export default defineComponent({
  name: "AppLauncherCollection",
  setup() {
    const workspaceModule = WorkspaceModule()
    const loader = Loader()

    const spaces = computed(() => {
      return workspaceModule.myWorkspaces
    })

    const goToAppLauncher = (workspaceId: string) => {
      navigate({ name: "AppLauncher", params: { workspaceId } })
    }

    const goToAppLauncherNewTab = (workspaceId: string) => {
      const space = spaces.value.find(_space => _space.id === workspaceId)

      if (space) {
        const appId = space.schema.assets.slice(-1)[0]?.id

        if (appId) {
          const routeData = Router.resolve({
            name: "AppLauncher",
            params: { workspaceId, appId }
          })

          window.open(routeData.href, "_blank")
        }
      }
    }

    const _redirectIfNoSpaceVariety = () => {
      if (workspaceModule.myWorkspaces.length === 1) {
        const space = workspaceModule.myWorkspaces[0]

        if (space?.id) {
          navigate({
            name: "AppLauncher",
            params: { workspaceId: space.id }
          })
        }
      }
    }

    loader.run(async () => {
      await workspaceModule.getMyWorkspaces()
      _redirectIfNoSpaceVariety()
    })

    return {
      goToAppLauncherNewTab,
      goToAppLauncher,
      spaces,
      loader
    }
  },
  components: {
    GridCard
  }
})
</script>
