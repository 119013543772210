<template>
  <nested-item
    :app="iterableText"
    :service="service"
    :has-changes="hasChanges"
    :readonly="service.firstDeployOnly && wasDeployed"
  >
    <v-checkbox
      v-model="service.active"
      @validate="validate"
      :disabled="isMandatory || (service.firstDeployOnly && wasDeployed)"
      :label="label[$i18n.locale]"
      rbt-target="wizardSectionContainer"
      class="my-0"
      hide-details
    />

    <template v-slot:child>
      <ul v-if="service.widget && service.widget.options" class="textCollection">
        <li v-if="service.widget.options.length === 0" class="emptyListButton">
          <v-btn @click="addRow">
            <v-icon>action_add</v-icon>
          </v-btn>
        </li>

        <li v-for="(widget, index) in service.widget.options" :key="index" class="textItem">
          <div class="wrapInputRow">
            <v-label>
              <span>{{ service.widget.label.value1[$i18n.locale] }}</span>

              <v-text-field
                v-model="widget.value1"
                :rules="service.widget.rule ? getRule(service.widget.rule.value1) : []"
                class="my-0 py-0"
              />
            </v-label>

            <v-label>
              <span>{{ service.widget.label.value2[$i18n.locale] }}</span>

              <v-text-field
                v-model="widget.value2"
                :rules="service.widget.rule ? getRule(service.widget.rule.value2) : []"
                class="my-0 py-0"
              />
            </v-label>
          </div>

          <div class="controller">
            <v-btn @click="remove(index)" icon>
              <v-icon>action_trash</v-icon>
            </v-btn>

            <v-btn v-if="index + 1 === service.widget.options.length" @click="addRow" icon>
              <v-icon>action_add</v-icon>
            </v-btn>
          </div>
        </li>
      </ul>
    </template>
  </nested-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WizardSettings, WidgetTextIterable } from "@/types/wizard"
import { useValidate } from "../composable/useValidate"
import WizardWidgetMixin from "../mixin/WizardWidgetMixin"
import WizardRuleMixin from "../mixin/WizardRuleMixin"
import Utils from "@/utils/utils"

export default defineComponent<typeof WizardWidgetMixin>({
  name: "WizardIterableText",
  mixins: [WizardRuleMixin, WizardWidgetMixin],
  props: {
    value: {
      type: Object as PropType<WizardSettings<WidgetTextIterable>>,
      required: true
    }
  },
  setup(props, ctx) {
    // @ts-expect-error, not reading prop as it should
    const service = Utils.vModel<WizardSettings<WidgetTextIterable>>(props, ctx.emit)
    const { validate } = useValidate(ctx.emit)

    const addRow = () => {
      const widget = service.value.widget

      if (widget) {
        widget.options.push({
          value1: "",
          value2: ""
        })
      }
    }

    const remove = (index: number) => {
      const widget = service.value.widget as WidgetTextIterable
      widget.options.splice(index, 1)
    }

    return {
      validate,
      addRow,
      remove
    }
  }
})
</script>
