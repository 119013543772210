export const AWS = "AWS"
export const AZURE = "Azure"

export const CLOUD_PROVIDER = {
  options: {
    aws: AWS,
    azure: AZURE
  },
  default: AWS
}

const availableCloudProviders = [
  {
    text: AWS,
    value: AWS,
    disabled: false
  },
  {
    text: AZURE,
    value: AZURE,
    disabled: true
  }
]

export default availableCloudProviders
