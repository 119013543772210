import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapOpenButton"},[_c(VMenu,{attrs:{"nudge-bottom":4,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"loading":_vm.loading,"large":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("TriCaster.buttons.open"))+" "),_c(VIcon,{attrs:{"right":"","size":32}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('ul',{staticClass:"listMenu"},[_c('li',{staticClass:"listRow",on:{"click":function($event){return _vm.$emit('open:nice-dcv')}}},[_vm._v(_vm._s(_vm.t("niceDcv")))]),_c('li',{staticClass:"listRow",on:{"click":function($event){return _vm.$emit('open:ndi-bridge')}}},[_vm._v(_vm._s(_vm.t("ndiBridge")))]),_vm._l((_vm.assetLinks),function(link){return _c('li',{key:link.link,staticClass:"listRow",on:{"click":function () { return _vm.getLinkWithCredentials(link); }}},[_vm._v(" "+_vm._s(_vm.t("open", { label: link.label }))+" ")])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }