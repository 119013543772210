import { translate } from "@/plugins/i18n"
import { SelectItem } from "@/types/core"
import Utils from "./utils"

export const MIN_LENGTH = 1
const IP_REGEX = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
const IP_CIDR_REGEX = /^((?:10|172).[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3})$/
const TOKEN_REGEX = /^(0|-?[1-9][0-9]*)$/
const WINDOWS_DIRECTORY = /^[a-zA-Z]:\\(((?![<>:"/\\|?*]).)+((?<![ .])\\)?)*$/
const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
const TIME_FORMAT = /^(?:[01]\d|2[0-3]):[0-5]\d$/
const TERRAFORM_REGEX =
  /^(0|[1-9][0-9]*)\.(0|[1-9][0-9]*)\.(0|[1-9][0-9]*)(?:-((?:0|[1-9][0-9]*|[0-9]*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9][0-9]*|[0-9]*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

export type Rule<T = string | null> = (value: T, feedback?: string) => string | true

export const MandatorySelect: Rule<string | null>[] = [
  (value: string | null): true | string => {
    if (value) {
      return true
    }

    return translate("InputRules.select.mandatory")
  }
]

export const MandatoryComboBox: Rule<SelectItem<string> | string | null>[] = [
  (value: SelectItem<string> | string | null): true | string => {
    const isTypeString = typeof value === "string"
    const isSelectItem = Utils.isType<SelectItem<string | number>>(value, "value")

    if (isTypeString) {
      return translate("InputRules.invalidCombobox")
    } else if (isSelectItem) {
      return true
    }

    return translate("InputRules.select.mandatory")
  }
]

export const MandatoryComboBoxFromList: <T>(list: T[] | SelectItem<T>[]) => Rule<string | null>[] = list => {
  return [
    (value: string | null): true | string => {
      const valid = list.some(item => {
        const validCollectionItem = (list as unknown as string[]).includes(`${value}`)
        const selectItem = item as unknown as SelectItem<string>

        if (Object.hasOwn(selectItem, "value")) {
          if (selectItem.value === value) {
            return true
          }
        }

        return validCollectionItem && Boolean(value)
      })

      return valid || translate("InputRules.select.mandatory")
    }
  ]
}

export const MandatoryMultipleSelect: Rule<string[]>[] = [
  (value: string[]): true | string => {
    if (value.length > 0) {
      return true
    }

    return translate("InputRules.select.mandatory")
  }
]

export const MandatoryTextField: Rule<string>[] = [
  (value: string | null): true | string => {
    const strValue = typeof value === "number" ? String(value) : value

    if (strValue && strValue.length >= MIN_LENGTH && strValue.trim().length >= MIN_LENGTH) {
      return true
    }

    return translate("InputRules.select.mandatory")
  }
]

export const MandatoryWindowsDirectory: Rule<string>[] = [
  (value: string | null): true | string => {
    if (!value) {
      return translate("InputRules.select.mandatory")
    }

    if (WINDOWS_DIRECTORY.test(value)) {
      return true
    }

    return translate("InputRules.invalidWindowsDirectory")
  }
]

export const OptionalWindowsDirectory: Rule<string>[] = [
  (value: string | null): true | string => {
    if (!value || WINDOWS_DIRECTORY.test(value)) {
      return true
    }

    return translate("InputRules.invalidWindowsDirectory")
  }
]

export const MandatoryEmail: Rule<string>[] = [
  (value: string | null): true | string => {
    if (value && EMAIL_REGEX.test(value)) {
      return true
    }

    return translate("InputRules.email")
  }
]

export const TokenRule: Rule<string>[] = [
  (value: string | null): true | string => {
    const hasEnoughLength = String(value).length > 0
    const isNumberTransformable = !isNaN(Number(value))
    const regexIsValid = TOKEN_REGEX.test(`${Number(value)}`)

    if (hasEnoughLength && isNumberTransformable && regexIsValid && Number(value) > 0) {
      return true
    }

    return translate("InputRules.tokenRule")
  }
]

export const MinLengthTextField: (minLength: number) => Rule<string>[] = (minLength: number) => {
  return [
    (value: string | null): true | string => {
      if (value && value.length >= minLength) {
        return true
      }

      return translate("InputRules.select.mandatory")
    }
  ]
}

export const MaxLengthTextField: (maxLength: number) => Rule<string>[] = (maxLength: number) => {
  return [
    (value: string | null): true | string => {
      if (!value) {
        return true
      }

      if (value.length < maxLength) {
        return true
      }

      return translate("InputRules.select.mandatory")
    }
  ]
}

export const MandatoryCheckbox: Rule<boolean | null>[] = [
  (value: boolean | null): true | string => {
    return value || translate("InputRules.select.mandatory")
  }
]

export const MandatoryIp: Rule<string>[] = [
  (value: string): true | string => {
    if (!value) {
      return translate("InputRules.mandatoryIp")
    }

    if (IP_REGEX.test(value)) {
      return true
    }

    return translate("InputRules.invalidIp")
  }
]

export const MandatoryUniqueIP = (value: string, IPCollection: string[]): true | string => {
  if (!value) {
    return translate("InputRules.mandatoryIp")
  } else if (IPCollection.includes(value)) {
    return translate("InputRules.IpIncluded")
  } else if (IP_REGEX.test(value)) {
    return true
  }

  return translate("InputRules.invalidIp")
}

export const TerraformVersionValidation: Rule<string>[] = [
  (value: string): true | string => {
    if (!value) {
      return translate("InputRules.select.mandatory")
    }

    if (TERRAFORM_REGEX.test(value)) {
      return true
    }

    return translate("InputRules.terraformVersion")
  }
]

export const CidrRange: Rule<string>[] = [
  (value: string): true | string => {
    if (!value) {
      return translate("InputRules.mandatoryIp")
    }

    if (!IP_REGEX.test(value)) {
      return translate("InputRules.invalidIp")
    }

    if (IP_CIDR_REGEX.test(value)) {
      return true
    }

    return translate("InputRules.invalidCidrIp")
  }
]

export const TimeFormat: Rule<string>[] = [
  (value: string): true | string => {
    if (!value) {
      return translate("InputRules.timeFormat")
    }

    return TIME_FORMAT.test(value) || translate("InputRules.timeFormat")
  }
]
