<template>
  <component v-model="inputValue" :director="director" :disabled="loading" :loading="loading" :is="component" />
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { InputType, InputItem } from "./lib/type"
import { PluginExceptionAny } from "@/types/core"
import Utils from "@/utils/utils"
import TextInput from "./_Text.vue"
import SelectInput from "./_Select.vue"
import ComboboxInput from "./_ComboBox.vue"
import DateInput from "./_Date.vue"
import DescriptionInput from "./_Description.vue"
import CheckboxInput from "./_Checkbox.vue"
import "./formFactory.scss"

export default defineComponent({
  name: "FormFactory",
  props: {
    value: {
      type: [String, Number, Object, Boolean, Array],
      required: true
    },
    director: {
      type: Object as PropType<InputItem>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const inputValue = Utils.vModel<PluginExceptionAny>(props, ctx.emit)

    const component = computed(() => {
      switch (props.director.type) {
        case InputType.TEXT:
          return TextInput
        case InputType.SELECT:
          return SelectInput
        case InputType.COMBOBOX:
          return ComboboxInput
        case InputType.DATE:
          return DateInput
        case InputType.DESCRIPTION:
          return DescriptionInput
        case InputType.CHECKBOX:
          return CheckboxInput
      }
    })

    return {
      component,
      inputValue,
      InputType
    }
  },
  components: {
    DescriptionInput,
    CheckboxInput,
    DateInput,
    ComboboxInput,
    SelectInput,
    TextInput
  }
})
</script>
