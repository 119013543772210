import { AuthData } from "./type"

const TIMEOUT = {
  timestamp: Date.now(),
  lifeTime: 1000 * 15
}

const registerMemory = <T = unknown>(keyName: string, value: T) => {
  localStorage.setItem(keyName, JSON.stringify(value))
}

const recoverMemory = <T = AuthData>(keyName: string) => {
  const recoveredRaw = localStorage.getItem(keyName ?? "")

  if (recoveredRaw) {
    return JSON.parse(recoveredRaw) as T
  }
}

const isExpired = () => {
  const { timestamp, lifeTime } = TIMEOUT
  return Date.now() > timestamp + lifeTime
}

export const recoverAuthFromMemory = () => {
  const recoveredAuth = recoverMemory("auth")

  if (recoveredAuth) {
    return {
      auth: recoveredAuth,
      expired: isExpired()
    }
  }

  return null
}

export const registerAuthInMemory = (auth: AuthData) => {
  registerMemory("auth", auth)
  TIMEOUT.timestamp = Date.now()
}

export const destroyAuthMemory = () => {
  const recover = recoverAuthFromMemory()

  if (recover && recover.auth) {
    recover.auth.isAuthenticated = false
    registerAuthInMemory(recover.auth)
  }
}
