<template>
  <nested-item
    :app="checkbox"
    :service="service"
    :has-changes="hasChanges"
    :readonly="service.firstDeployOnly && wasDeployed"
  >
    <v-checkbox
      v-model="service.active"
      :disabled="isMandatory || (service.firstDeployOnly && wasDeployed)"
      :label="label[$i18n.locale]"
      rbt-target="wizardSectionContainer"
      class="my-0"
      hide-details
    />

    <template v-slot:child>
      <div class="contained" v-if="service.widget">
        <div class="item" v-for="widget in service.widget.options" :key="widget.value">
          <v-checkbox v-model="widget.selected" :label="widget.label[$i18n.locale]" class="my-0 py-0" hide-details />
        </div>
      </div>
    </template>
  </nested-item>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import WizardWidgetMixin from "../mixin/WizardWidgetMixin"

export default defineComponent({
  name: "WizardCheckbox",
  mixins: [WizardWidgetMixin]
})
</script>
