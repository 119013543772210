import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticClass:"workspaces-table",attrs:{"headers":_vm.headers,"items":_vm.workspaces,"sort-by":_vm.sortConfig.sortBy,"sort-desc":_vm.sortConfig.sortDesc,"custom-sort":_vm.customSort,"itemsPerPage":_vm.itemsPerPage,"disable-pagination":!_vm.showPagination,"hide-default-footer":!_vm.showPagination},on:{"click:row":_vm.redirectToWorkspace,"pagination":_vm.paginationChange,"update:sortBy":function($event){return _vm.$set(_vm.sortConfig, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sortConfig, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sortConfig, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sortConfig, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var w = ref.item;
return [_c('div',{staticClass:"d-flex table-column-title workspaceName"},[_c('workspace-status-image',{attrs:{"workspace":w}}),_c('div',{staticStyle:{"max-width":"calc(100% - 62px)"}},[_c('div',{staticClass:"d-flex justify-start"},[_c('h5',{staticClass:"text-nowrap"},[_vm._v(_vm._s(w.title))]),(w.isShared)?_c(VIcon,{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: _vm.$t('common.shared') }),expression:"{ content: $t('common.shared') }"}],staticClass:"ml-1",attrs:{"left":""}},[_vm._v(" mdi-share-variant ")]):_vm._e(),(_vm.errorIcon(w))?_c(VIcon,[_vm._v(_vm._s(_vm.errorIcon(w)))]):_vm._e()],1),_c('div',[_c('p',{staticClass:"body-1",staticStyle:{"color":"var(--viz-grey-2)"}},[_c('span',{staticClass:"description"},[_vm._v(_vm._s(w.description))])])])])],1)]}},{key:"item.tags",fn:function(ref){
var workspace = ref.item;
return [(_vm.getTags(workspace).tags.length > 0)?_c('div',{staticClass:"d-flex justify-start flex-wrap gap-3 mt-1",staticStyle:{"row-gap":"8px"}},[_vm._l((_vm.availableTags(workspace)),function(tag){return _c(VChip,{key:tag,attrs:{"value":tag}},[_vm._v(_vm._s(tag))])}),(_vm.getTags(workspace).tags.length > _vm.DISPLAY_TAG_LIMIT)?_c('span',[_c('tooltip',{attrs:{"text":_vm.$tc('workspaces.appDetails.tooltip.hiddenTags', _vm.hiddenTagsCount(workspace), {
              count: _vm.hiddenTagsCount(workspace)
            })}},[_c('span',[_vm._v("...")])])],1):_vm._e()],2):_c('div',{staticClass:"noTags"},[_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceWizard.labels.emptyTags")))])])]}},{key:"item.username",fn:function(ref){
            var w = ref.item;
return [_c('span',[_vm._v(_vm._s(w.username))])]}},{key:"item.date",fn:function(ref){
            var w = ref.item;
return [_c('div',{staticClass:"dateWrapper"},[(_vm.lastEditDate(w))?_c('span',[_vm._v(_vm._s(_vm.lastEditDate(w)))]):_vm._e(),(_vm.lastEditedByUser(w))?_c('span',{staticClass:"editedBy"},[_vm._v(" "+_vm._s(_vm.$t("WorkspaceEdit.metadata.lastEditedBy"))+" "+_vm._s(_vm.lastEditedByUser(w))+" ")]):_vm._e()])]}},{key:"item.status",fn:function(ref){
            var w = ref.item;
return [_c('online-switch',{attrs:{"workspace":w,"grid-view":"table"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }