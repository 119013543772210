import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":_vm.width,"max-width":_vm.maxWidth,"persistent":_vm.persistent,"fullscreen":_vm.fullscreen,"content-class":(_vm.stopUsingScroll + " globalDialogPopup " + _vm.customClass)},on:{"click:outside":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,null,{ on: on, attrs: attrs })]}}],null,true),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[(!_vm.hideHeader && _vm.showModal)?_c(VCardTitle,{staticClass:"dialogTitle"},[_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.hideCloseButton)?_c(VBtn,{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: _vm.closeTitle || _vm.$t('common.close') }),expression:"{ content: closeTitle || $t('common.close') }"}],attrs:{"rbt-target":"closeModal","icon":""},on:{"click":_vm.closeModal}},[_c(VIcon,[_vm._v("action_close")])],1):_vm._e()],1):_vm._e(),(_vm.loading)?_c('div',[_c(VProgressLinear,{attrs:{"indeterminate":""}})],1):_vm._e(),_vm._t("root"),_c(VCardText,{staticClass:"py-0"},[_vm._t("default")],2),(!_vm.hideActions)?_c(VCardActions,[_vm._t("actions"),_c(VSpacer),_c(VBtn,{attrs:{"large":""},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.closeTitle || _vm.$t("common.close")))])],2):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }