<template>
  <div id="boxBody" :style="backgroundImage">
    <error-box v-if="hasError" :text="hasError" />

    <version-outdated v-if="showOutdatedVersionWarning" />

    <subscription-warning v-if="showSubscriptionWarning" :subscription="subscription" />

    <shutdown-warning
      v-if="hasShutdownWarningInformation && isOnline"
      @cancel:shutdown="$emit('cancel:shutdown')"
      @postpone:shutdown="$emit('postpone:shutdown')"
      :loading="loading"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum, WorkspaceTemplate } from "@/types/workspace"
import { COMFORTABLE_TIME_RANGE } from "@/config/warning-range"
import { useStore } from "@/composable/useStore"
import { isOutdated } from "@/utils/version-outdated"
import ErrorBox from "./_ErrorBox.vue"
import VersionOutdated from "./_VersionOutdated.vue"
import ShutdownWarning from "./_ShutdownWarning.vue"
import SubscriptionWarning from "./_SubscriptionWarning.vue"
import PackageJson from "../../../../package.json"
import Router from "@/router"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "BoxBody",
  props: {
    background: {
      type: String,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const showOutdatedVersionWarning = ref(false)
    const { spaceModule, adminModule } = useStore()

    const hasError = computed(() => {
      return props.workspace.lastErrorTitle
    })

    const isOnline = computed(() => {
      return props.workspace.onlineStatus === WorkspaceOnlineStatusEnum.Online
    })

    const subscription = computed(() => {
      return adminModule.organizationDetail?.tokenSubscription
    })

    const showSubscriptionWarning = computed(() => {
      if (subscription.value?.type) {
        const expiresAt = subscription.value.expirationDate || Date.now()
        const { days } = Utils.dateRange(Date.now(), expiresAt)

        return days <= COMFORTABLE_TIME_RANGE
      }

      return true
    })

    const backgroundImage = computed(() => {
      return { backgroundImage: `url(${props.background})` }
    })

    const hasShutdownWarningInformation = computed(() => {
      const spaceWillShutdown = spaceModule.watching?.autoShutdownDefinition
      return Boolean(spaceModule.shutdownSettings && spaceWillShutdown)
    })

    const _checkLauncherVersion = () => {
      const { version } = Router.currentRoute.query
      const expectedVersion = PackageJson.appLauncherVersion

      if (version && expectedVersion) {
        if (isOutdated(expectedVersion, version as string)) {
          showOutdatedVersionWarning.value = true
        }
      }
    }

    _checkLauncherVersion()

    return {
      showSubscriptionWarning,
      subscription,
      backgroundImage,
      showOutdatedVersionWarning,
      hasShutdownWarningInformation,
      hasError,
      isOnline
    }
  },
  components: {
    SubscriptionWarning,
    VersionOutdated,
    ShutdownWarning,
    ErrorBox
  }
})
</script>
