<template>
  <div v-if="!disabled" class="uploaderArea">
    <div class="imageThumbs" v-if="fileCollectionContainsFile">
      <div
        class="thumbContainer animateFadeUp"
        v-for="(file, index) in computedFileList"
        :key="`${file.name}-${index}`"
      >
        <file-metadata
          @remove:file="removeFile"
          :file="file"
          :context-id="contextId"
          :buffer="buffer.track(file.name)"
        />
      </div>
    </div>

    <div :dragging="isDraggingFile" class="uploader">
      <input
        @change="onUploadFile"
        @dragover="isDraggingFile = true"
        @dragend="isDraggingFile = false"
        @dragleave="isDraggingFile = false"
        @mouseleave="isDraggingFile = false"
        :title="false"
        :accept="formats"
        :multiple="multiple"
        :key="renderKey"
        ref="Uploader"
        class="inputUpload"
        type="file"
      />

      <div class="uploaderControllers">
        <v-icon left>action_upload_or_export</v-icon>
        <span>{{ $t("Uploader.label") }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { ref, reactive, computed, ComputedRef, inject } from "@vue/composition-api"
import { Dictionary, Context, FileUploadPayload } from "@/types/core"
import { UploadModule } from "@/store/upload"
import { raiseConfirmation, raiseError } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import Utils from "@/utils/utils"
import FileProgress from "../lib/file-progress"
import FileMetadata from "./FileMetadata.vue"

export default defineComponent({
  name: "Uploader",
  components: {
    FileMetadata
  },
  props: {
    formats: {
      type: String,
      default: "*"
    },
    disabled: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      required: true
    },
    standalone: {
      type: Boolean,
      default: true
    },
    context: {
      type: String,
      required: true
    },
    contextId: {
      type: String,
      required: true
    },
    secondContextKey: {
      type: String,
      required: true
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    clearOnReady: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const Uploader = ref<HTMLInputElement | null>(null)
    const isDraggingFile = ref(false)
    const buffer = reactive(new FileProgress())
    const cachedFiles = reactive<Dictionary<File>>({})
    const fileCollection = ref<File[]>([])
    const uploadModule = UploadModule()
    const injectedFileList = inject<ComputedRef<FileUploadPayload[]>>("fileList")
    const renderKey = ref(0)

    const fileCollectionContainsFile = computed<boolean>(() => {
      return fileCollection.value.length > 0
    })

    const computedFileList = computed((): File[] => {
      if (props.multiple) {
        return fileCollection.value
      } else {
        if (fileCollection.value.length >= 2) {
          return fileCollection.value.slice(-1)
        }

        return fileCollection.value
      }
    })

    const _updateFileCollection = (): void => {
      fileCollection.value = []

      for (const filename in cachedFiles) {
        const _filename = Utils.toKebabCase(filename)

        if (cachedFiles[_filename]) {
          fileCollection.value.push(cachedFiles[_filename])
        }
      }
    }

    const _hasOnlyValidFormats = (files: File[]) => {
      if (props.formats) {
        const acceptedFormats = (props.formats as string).split(",").map(format => format.trim())

        return files.every(file => {
          return acceptedFormats.includes(_getFileFormat(file.name))
        })
      }

      return true
    }

    const _getFileFormat = (fileName: string) => {
      let format = fileName

      for (let i = 0; i < 5; i++) {
        if (!format.includes(".")) {
          break
        }

        format = format.slice(format.indexOf(".") + 1)
      }

      return `.${format}`
    }

    const _removeCachedFile = (file: File) => {
      const filename = Utils.toKebabCase(file.name)

      if (cachedFiles[filename]) {
        delete cachedFiles[filename]
        _updateFileCollection()
      }
    }

    const _registerFile = (file: File) => {
      const filename = Utils.toKebabCase(file.name)

      if (file && !cachedFiles[filename]) {
        cachedFiles[filename] = file
        _updateFileCollection()
        return true
      }
      return false
    }

    const _sendFile = async (file: File): Promise<void> => {
      const fileData = {
        context: props.context as Context,
        fileName: file.name,
        mimeType: file.type,
        size: file.size,
        contextItemId: props.contextId as string,
        secondKey: props.secondContextKey as string,
        creationDate: new Date().toISOString(),
        contentsBase64: await Utils.blobToBase64(file)
      }

      if (props.standalone) {
        try {
          buffer.trackProgress(file.name, 1)

          const req = await uploadModule.sendFile(fileData, progress => buffer.trackProgress(file.name, progress))

          ctx.emit("upload", fileData, buffer)
          buffer.trackProgress(file.name, 100, req.id)
          _releaseFile(file.name)
        } catch (err) {
          buffer.breakProgress(file.name)
          throw err
        }
      } else {
        ctx.emit("upload", fileData, buffer)
      }
    }

    const _releaseFile = (filename: string) => {
      if (props.clearOnReady) {
        setTimeout(() => {
          const _filename = Utils.toKebabCase(filename)

          buffer.release(filename)
          removeFile(cachedFiles[_filename])
        }, 1200)
      }
    }

    const _uploadFiles = async (fileList: File[]) => {
      try {
        ctx.emit("loading", true)

        await Promise.all(
          fileList.map(async file => {
            if (_registerFile(file)) {
              buffer.trackProgress(file.name, 1)
              await _sendFile(file)
            }
          })
        )

        ctx.emit("complete", props.contextId)
      } finally {
        ctx.emit("loading", false)
      }
    }

    const _duplicatedFiles = (fileList: File[]): FileUploadPayload[] => {
      const existent: FileUploadPayload[] = []
      const upperFileList = injectedFileList?.value ?? []

      for (const file of upperFileList) {
        const existentFile = fileList.find(_file => _file.name === file.fileName)

        if (existentFile) {
          existent.push(file)
        }
      }

      return existent
    }

    const onUploadFile = async (event: InputEvent) => {
      const $input = Utils.isType<HTMLInputElement>(event.target, "files")
      isDraggingFile.value = false

      if ($input && $input.files) {
        const fileList = [...$input.files]
        const duplicates = _duplicatedFiles(fileList)

        if (!_hasOnlyValidFormats(fileList)) {
          raiseError({ text: translate("Uploader.invalidFileFormat") })
          ctx.emit("remove:duplicates", fileList)
          return
        }

        if (props.isEmpty) {
          _uploadFiles(fileList)
          return
        }

        if (props.multiple === false) {
          if (await raiseConfirmation({ text: translate("Uploader.confirm.removeOldFile") })) {
            _uploadFiles(fileList)
          } else {
            renderKey.value++
          }
        } else if (injectedFileList?.value && injectedFileList.value.length && duplicates.length) {
          if (await raiseConfirmation({ text: translate("Uploader.confirm.updatingFileVersion") })) {
            _uploadFiles(fileList)
            ctx.emit("remove:duplicates", duplicates)
          } else {
            renderKey.value++
          }
        } else {
          _uploadFiles(fileList)
        }
      }
    }

    const removeFile = (file: File) => {
      const dataTransfer = new DataTransfer()
      const uploader = Utils.isType<HTMLInputElement>(Uploader.value, "files")

      _removeCachedFile(file)

      if (uploader && uploader.files) {
        const files = [...uploader.files]

        files.forEach(fileItem => {
          if (fileItem.name !== file.name) {
            dataTransfer.items.add(fileItem)
          }
        })

        const $uploader = Uploader.value as HTMLInputElement
        $uploader.files = dataTransfer.files
      }
    }

    return {
      renderKey,
      computedFileList,
      Uploader,
      isDraggingFile,
      buffer,
      fileCollectionContainsFile,
      injectedFileList,
      removeFile,
      onUploadFile
    }
  }
})
</script>
