<template>
  <div id="controllers">
    <add-apps
      v-model="addAppOpened"
      @add:apps="apps => $emit('add:apps', apps)"
      :workspace="workspace"
      :loading="loading"
    />

    <ip-handler
      @add:ip="ip => $emit('add:ip', ip)"
      @remove:ip="ip => $emit('remove:ip', ip)"
      :workspace="workspace"
      :loading="loading"
    />

    <licenses-board :licenses="licenses" :loading="loading" />

    <dangerous-buttons v-if="!loading" @redeploy="$emit('redeploy')" @destroy="$emit('destroy')" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { LicenseProduct, WorkspaceTemplate } from "@/types/workspace"
import AddApps from "@/views/workspaceEdit/components/header/AddApps.vue"
import IpHandler from "@/views/workspaceEdit/components/header/IpHandler.vue"
import DangerousButtons from "./DangerousButtons.vue"
import Utils from "@/utils/utils"
import LicensesBoard from "./LicensesBoard.vue"

export default defineComponent({
  name: "Controllers",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    licenses: {
      type: Array as PropType<LicenseProduct[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const addAppOpened = Utils.vModel(props, ctx.emit)

    return {
      addAppOpened
    }
  },
  components: {
    LicensesBoard,
    DangerousButtons,
    AddApps,
    IpHandler
  }
})
</script>
