import { SelectItem } from "@/types/core"
import { Rule } from "@/utils/input-rules"

export enum InputType {
  TEXT = "text",
  DESCRIPTION = "description",
  CHECKBOX = "checkbox",
  SELECT = "select",
  COMBOBOX = "combobox",
  DATE = "date",
  ITERABLE = "iterable_group",
  COLLECTION = "structure_collection",
  EMPTY = "empty_space"
}

interface CommonInputConfig<R = string> {
  _id: string
  type: InputType
  key: string
  label?: string
  rules?: Rule<R>[]
  loads?: boolean
  getsDisabled?: boolean
  placeholder?: string
  name?: string
  hideDetails?: boolean
  grow?: boolean
  onPressEnter?: () => void
  onBlur?: () => void
  onInput?: () => void
}

export interface IterableGroup {
  _id: string
  key: string
  type: InputType.ITERABLE
  children: Director | StructureCollection
  grow: boolean
  isCollectionStructure: boolean
  containerClass?: string
}

export interface StructureCollection {
  _id: string
  type: InputType.COLLECTION
  collection: Array<Array<InputItem | IterableGroup>>
}

export type DescriptionInput = TextInput

export interface TextInput extends CommonInputConfig {
  value: string | null
}

export interface CheckboxInput extends CommonInputConfig {
  value: boolean | null
}

export interface DateInput extends CommonInputConfig {
  value: string | null
}

export interface SelectInput<R = string | null> extends CommonInputConfig<R> {
  value: SelectItem<string | number> | string | number | null
  items: SelectItem<string | number>[] | string[] | number[] | unknown[]
}

export type ComboboxInput = SelectInput

export type InputItem = TextInput | SelectInput | ComboboxInput | DateInput

export type Director<T = InputItem | IterableGroup> = Array<Array<T>>

export type DirectorCollection = Director[] | InputType[]
