<template>
  <div class="adminTabContainer">
    <div class="tabHeader">
      <div
        v-for="tab in tabOptions"
        :key="`${tab.value}-header`"
        :rbt-target="toRobotTag(`tab-${tab.value}`)"
        :active="isActive(tab.value)"
        class="tabItem"
      >
        <v-btn @click="changeTab(tab)" :text="!isActive(tab.value)">{{ tab.label }}</v-btn>
      </div>
    </div>

    <div class="tabContent">
      <component :key="currentTab.value" :is="currentTab.component" class="animateSimple" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api"
import { TabItem } from "../lib/type"
import { UsersModule } from "@/store"
import Utils from "@/utils/utils"
import TabOptions from "../lib/tab-options"
import Router from "@/router"

export default defineComponent({
  name: "AdminTabs",
  setup() {
    const usersModule = UsersModule()
    const tabOptions = computed(() => TabOptions(usersModule.selfDetail.role))
    const currentTab = ref<TabItem>(tabOptions.value[0])

    const _updateTabWithHash = () => {
      const tabValue = Router.currentRoute.hash

      if (tabValue) {
        const tabValueWithoutHash = tabValue.slice(1)
        const filteredTab = tabOptions.value.find(tab => tab.value === tabValueWithoutHash)

        if (filteredTab) {
          currentTab.value = filteredTab
        }
      }
    }

    const toRobotTag = (tagValue: string) => {
      return Utils.toCamel(tagValue.split("-").join(" "))
    }

    const isActive = (tabValue: string) => {
      return currentTab.value.value === tabValue
    }

    const changeTab = (tab: TabItem) => {
      currentTab.value = tab
      Router.replace({ hash: tab.value })
    }

    _updateTabWithHash()

    return {
      toRobotTag,
      changeTab,
      isActive,
      tabOptions,
      currentTab
    }
  }
})
</script>
