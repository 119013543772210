<template>
  <div class="commonPageLoader">
    <v-progress-linear color="primary" indeterminate />

    <div class="backgroundWrapper">
      <div class="wrapLoadingContent">
        <div class="loadingLabel">
          <span>{{ text }}</span>
          <span class="dot">.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { translate } from "@/plugins/i18n"
import { TranslateResult } from "vue-i18n"

export default defineComponent({
  name: "CommonPageLoader",
  props: {
    text: {
      type: String as PropType<TranslateResult>,
      default: translate("WorkspaceEdit.label.appLoading")
    }
  }
})
</script>
