<template>
  <div id="metadata">
    <div v-if="showData('cloudZone') && cloudZone" class="item">
      <span class="prefix">{{ $t("WorkspaceEdit.metadata.region") }}:</span>

      <tooltip :text="`CloudZoneAZ: ${cloudZone.az}`">
        <span class="value">
          {{ cloudZone.name }}
        </span>

        <span class="prefix">
          <v-icon :size="12">mdi-chevron-double-right</v-icon>
        </span>

        <span class="value">
          {{ cloudZone.code }}
        </span>
      </tooltip>
    </div>

    <div v-if="showData('owner')" class="item">
      <span class="prefix">{{ $t("WorkspaceEdit.metadata.owner") }}:</span>
      <span class="value">{{ workspace.username }}</span>
    </div>

    <div v-if="showData('template')" class="item">
      <span class="prefix">{{ $t("WorkspaceEdit.metadata.template") }}:</span>

      <tooltip :text="usedTemplated" :disabled="usedTemplated.length < 35">
        <span class="value templateName">{{ usedTemplated }}</span>
      </tooltip>
    </div>

    <div v-if="workspace.stateChange && showData('lastEdited')" class="item">
      <span class="prefix">{{ $t("WorkspaceEdit.metadata.lastEdited") }}:</span>

      <tooltip :text="lastChangedDate">
        <span class="lastEdited">
          <span class="value templateName">{{ lastEdited }}</span>
          <span class="prefix">{{ $t("WorkspaceEdit.metadata.lastEditedBy") }}</span>
          <span class="value templateName">{{ workspace.stateChange.username }}</span>
        </span>
      </tooltip>
    </div>

    <div v-if="isOnline && isDeployed && showData('runningSince')" class="item">
      <span class="prefix">{{ $t("WorkspaceEdit.metadata.runningSince") }}:</span>

      <tooltip :text="$t('WorkspaceEdit.metadata.uptime')">
        <running-for :running-since="runningSince" />
      </tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { WorkspaceTemplate, WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import { WorkspaceStates } from "@/config/workspace-states"
import { translate } from "@/plugins/i18n"
import { Dictionary } from "vue-router/types/router"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import RunningFor from "../shared/RunningFor.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "Metadata",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    hideData: {
      type: Array as PropType<string[]>,
      default: Array
    }
  },
  setup(props) {
    const workspaceModule = WorkspaceModule()

    const usedTemplated = computed(() => {
      const templates = workspaceModule.templates
      const templateItem = templates.find(item => item.id === props.workspace.templateId)

      if (templateItem) {
        return templateItem.title
      }

      return ""
    })

    const cloudZone = computed(() => {
      if (props.workspace.cloudZone && props.workspace.cloudZoneAZ) {
        const regions = workspaceModule.spaceRegions
        const regionDataFromCode = regions.find(region => region.code === props.workspace.cloudZone)

        if (regionDataFromCode) {
          return {
            name: regionDataFromCode.fullName,
            code: props.workspace.cloudZone,
            az: props.workspace.cloudZoneAZ
          }
        }
      }

      return null
    })

    const lastEdited = computed(() => {
      const stateChange = props.workspace.stateChange

      if (stateChange) {
        const { changeDate } = stateChange
        const _time = Utils.lastModified(changeDate)

        if (_time.includes("sec")) {
          return translate("Modified.fewSecondsAgo")
        }

        return _time
      }

      return null
    })

    const lastChangedDate = computed(() => {
      if (props.workspace.stateChange) {
        const { ...onlineStatus } = WorkspaceOnlineStatusEnum as Dictionary<string>
        const date = Utils.dateFormatWithTime(props.workspace.stateChange.changeDate)
        const stateChangeStatus = props.workspace.stateChange.changedTo
        const wasOnlineStatus = Object.values(onlineStatus).includes(stateChangeStatus)
        const snippetCode = wasOnlineStatus ? "workspaceOnlineStatus" : "workspaceDisplayStatus"

        return translate(`${snippetCode}.${stateChangeStatus}`) + ` - ${date}`
      }

      return ""
    })

    const isOnline = computed(() => {
      const { Online } = WorkspaceOnlineStatusEnum
      return props.workspace.onlineStatus === Online
    })

    const isDeployed = computed(() => {
      return WorkspaceStates.isDeployed(props.workspace.status)
    })

    const runningSince = computed(() => {
      return props.workspace.runningSince
    })

    const showData = (key: string) => {
      return !props.hideData.includes(key)
    }

    return {
      cloudZone,
      showData,
      lastChangedDate,
      lastEdited,
      isDeployed,
      isOnline,
      usedTemplated,
      runningSince
    }
  },
  components: {
    Tooltip,
    RunningFor
  }
})
</script>
