import { computed, WritableComputedRef } from "@vue/composition-api"
import { VModel, Dictionary, SelectItem, Username } from "@/types/core"

const ERROR = {
  createSelectKeyError: "Invalid key to index objects",
  revertSelect: "Invalid type, expected list of SelectItem"
}

const TIME = {
  sec: 1000,
  min: 1000 * 60,
  hour: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any

export default class Utils {
  /**
   * @param props VueComponent Props
   * @param emit SetupContext Emit
   * @returns VueComponent computed property as VModel
   *
   * @example In setup context
   *
   * const someValue = Utils.vModel(props, ctx.emit)
   */
  public static vModel<T>(props: VModel<T>, emit: VueEmit): WritableComputedRef<T> {
    return computed({
      get: () => props.value,
      set: value => emit("input", value)
    })
  }

  public static propHas<T>(obj: T, node: string): boolean {
    const dict = this.isType<Dictionary<T & boolean>>(obj, node)

    if (Object.prototype.hasOwnProperty.call(obj, node) && dict) {
      return dict[node] !== false
    }
    return false
  }

  /**
   * Is expected to use this function to validate permissions.
   * Returns an object wrapped in an array for true, and an empty array when false
   *
   * @example
   * const arr = [1,2,3,
   *  ...Utils.insertIf(true, 4),
   *  ...Utils.insertIf(false, 5)
   * ] // [1,2,3,4]
   */
  public static insertIf<T = unknown>(condition: boolean, object: T): T[] {
    return condition ? [object] : []
  }

  /**
   * This method is useful as a type safe strategy
   *
   * @param prop The object containing assertions
   * @param propKeyValues Key values as a single string or list of strings
   * @returns Initial prop value or false
   *
   * @example Single key example
   * interface UserDetail {
   *    id: string;
   *    name: string
   * }
   *
   * const userDetails: UserDetail | null = { id: "1", name: "Hank" }
   *
   * // if user doesn't have an id property, it will be set to false
   * const user = Utils.isType<UserDetail>(userDetails, "id")
   *
   * if (user) {
   *    // no need to assert user as UserDetail,
   *    // making sure user is UserDetail
   *    user.name = "Hank Goldman"
   *    api.editUser(user)
   * } else {
   *    throw new Error("User is invalid")
   * }
   *
   * @example Multiple key example
   *
   * interface MultipleChecks {
   *    city?: string;
   *    country?: string;
   * }
   *
   * const locationOne: MultipleChecks | null = {
   *    city: "Rio de janeiro"
   * }
   *
   * const myLocation = Utils.isType<MultipleChecks>(locationOne, ["city", "country"])
   *
   * if (myLocation) {
   *    // works because city exists in property
   * }
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isType<T>(prop: any | null, propKeyValues: string | string[]): false | T {
    if (Array.isArray(propKeyValues)) {
      const acceptable = propKeyValues.some(key => {
        return this.isType(prop, key)
      })

      if (acceptable) {
        return prop
      }
    }

    if (prop && typeof prop === "object" && (propKeyValues as string) in prop) {
      return prop
    }

    return false
  }

  public static assignType<T>(item: unknown) {
    return item as T
  }

  public static createSelectItems<T extends Dictionary>(list: T[], key: string): SelectItem<T>[] {
    return list.reduce((arr: SelectItem<T>[], item): SelectItem<T>[] => {
      const prop = this.isType<T>(item, key)

      if (prop && typeof prop[key] === "string") {
        return [
          ...arr,
          {
            text: prop[key] as string,
            value: { ...item }
          }
        ]
      }

      throw Error(`Error Utils.createSelectItems, ${ERROR.createSelectKeyError}, tried using ${key}`)
    }, [])
  }

  public static revertSelectItems<T>(list: SelectItem<T>[]): T[] {
    if (list.length === 0) {
      return []
    } else if (this.propHas(list[0], "value")) {
      return list.map(item => item.value)
    }

    throw TypeError(`TypeError Utils.revertSelectItems, ${ERROR.revertSelect}`)
  }

  public static toKebabCase(entry: string): string {
    return entry.trim().replace(/[ ]/g, "-").toLowerCase()
  }

  public static blobToBase64(blobFile: File): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader()
      const base64 = () => `${reader.result}`.slice(`${reader.result}`.indexOf(",") + 1)

      reader.onloadend = () => resolve(base64())
      reader.readAsDataURL(blobFile)
    })
  }

  private static _normalizeConstructors<T>(structure: T & Dictionary<Any> & Any) {
    const types = [Number, String, Boolean]
    let result

    types.forEach(type => {
      if (structure instanceof type) {
        result = type(structure)
      }
    })

    return result
  }

  private static _transformArray<T>(structure: T & Dictionary<Any> & Any) {
    const result: Array<T> = []

    structure.forEach(function (child: Dictionary, index: number) {
      result[index] = Utils.deepClone(child)
    })

    return result
  }

  public static deepClone<T>(structure: T & Dictionary<Any> & Any): T {
    let result

    if (!structure) {
      return structure
    }

    result = Utils._normalizeConstructors(structure)

    if (typeof result == "undefined") {
      if (Object.prototype.toString.call(structure) === "[object Array]") {
        result = Utils._transformArray(structure)
      } else if (typeof structure == "object") {
        if (structure.nodeType && typeof structure.cloneNode == "function") {
          result = structure.cloneNode(true)
        } else if (!structure.prototype) {
          if (structure instanceof Date) {
            result = new Date(structure)
          } else {
            result = {} as Dictionary<Any>

            for (const i in structure) {
              result[i] = Utils.deepClone(structure[i])
            }
          }
        } else {
          if (structure.constructor) {
            result = new structure.constructor()
          } else {
            result = structure
          }
        }
      } else {
        result = structure
      }
    }

    return result as T
  }

  public static dateFormat(date: string | number) {
    const _date = new Date(date)

    const day = `${_date.getDate()}`.padStart(2, "0")
    const month = `${_date.getMonth() + 1}`.padStart(2, "0")
    const year = `${_date.getFullYear()}`

    return `${day}/${month}/${year}`
  }

  public static dateFormatWithTime(date: string | number, utc?: "utc") {
    const _dateFormat = this.dateWithNamedMonth(date)
    const _d = new Date(date)

    if (utc) {
      const timezone = _d.getTimezoneOffset()
      _d.setMinutes(_d.getMinutes() + parseInt(`${timezone}`))
    }

    const h = `${_d.getHours()}`.padStart(2, "0")
    const min = `${_d.getMinutes()}`.padStart(2, "0")

    return `${_dateFormat}, ${h}:${min}`
  }

  public static dateWithNamedMonth(date: string | number) {
    const monthTable = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const newDate = new Date(date)

    const day = `${newDate.getDate()}`.padStart(2, "0")
    const month = monthTable[newDate.getMonth()]
    const year = newDate.getFullYear()

    return `${day} ${month} ${year}`
  }

  public static deepCloneMultiple<T>(structureList: T[]) {
    return JSON.parse(JSON.stringify(structureList)) as T[]
  }

  public static capitalize(value: string) {
    const listedPhrase = value.split(" ")
    const capitalized = listedPhrase.map(word => word.charAt(0).toUpperCase() + word.slice(1))

    return capitalized.join(" ")
  }

  public static toPascal(value: string) {
    const capitalizedPhrase = this.capitalize(value)
    const listedPhrase = capitalizedPhrase.split(" ")

    return listedPhrase.join("")
  }

  public static toCamel(value: string) {
    const [firstWord, ...phrases] = value.split(" ")
    const firstLetter = firstWord[0].toLocaleLowerCase()
    const phraseWithoutFirstWord = phrases.join(" ")
    const capitalizedPhrase = this.capitalize(phraseWithoutFirstWord)

    const listedPhrase = (firstLetter + firstWord.slice(1) + capitalizedPhrase).split(" ")

    return listedPhrase.join("")
  }

  public static lastModified(date: string | number) {
    const newDate = new Date(date).getTime()
    const now = Date.now()

    const second = 1000
    const min = second * 60
    const hour = min * 60
    const last24H = hour * 24

    if (now - newDate < last24H) {
      const msPassedByTheDay = now - newDate

      if (msPassedByTheDay < min) {
        return Math.floor(msPassedByTheDay / second) + " sec ago"
      } else if (msPassedByTheDay < hour) {
        return Math.floor(msPassedByTheDay / min) + " min ago"
      } else {
        return Math.floor(msPassedByTheDay / hour) + "h ago"
      }
    }

    return this.dateWithNamedMonth(date)
  }

  public static filterCompare(word: string | undefined, comparingWord: string | undefined) {
    if (!word || !comparingWord) {
      return word === comparingWord
    }

    return word.toLowerCase().trim().includes(comparingWord.toLocaleLowerCase().trim())
  }

  public static numLocale(number: number) {
    return number.toLocaleString(navigator.languages as string[])
  }

  public static getInitials(user: Username) {
    const { firstName, lastName, email } = user
    const _initials = (word: string) => word.trim().substring(0, 1).toUpperCase()

    if (firstName) {
      let initials = _initials(firstName)
      initials += lastName ? _initials(lastName) : _initials(firstName.substring(1, 2))

      return initials
    } else if (email) {
      return _initials(email) + _initials(email.substring(1, 2))
    }

    return "U"
  }

  public static seconds(sec: number) {
    return 1000 * sec
  }

  public static minutes(min: number) {
    return this.seconds(60) * min
  }

  public static hours(hour: number) {
    return this.minutes(60) * hour
  }

  public static days(day: number) {
    return this.hours(24) * day
  }

  public static dateRange(now: Date | string | number, dateTarget: Date | string | number) {
    const _now = new Date(now).getTime()
    const _target = new Date(dateTarget).getTime()

    if (_now < _target) {
      let rangeDif = _target - _now

      const days = Math.floor(rangeDif / TIME.day)
      rangeDif -= TIME.day * days

      const hours = Math.floor(rangeDif / TIME.hour)
      rangeDif -= TIME.hour * hours

      const min = Math.floor(rangeDif / TIME.min)
      rangeDif -= TIME.min * min

      const sec = Math.ceil(rangeDif / 1000)

      return {
        days,
        hours,
        min,
        sec
      }
    }

    return {
      days: -1,
      hours: -1,
      min: -1,
      sec: -1
    }
  }

  public static getTimer(milliseconds: number, returnType: "with-pad" | "int" = "int") {
    let time = milliseconds
    const leftPad = (number: number) => `${number}`.padStart(2, "0")
    const deduce = (mil: number) => (time -= mil)
    const oneDay = Utils.days(1)
    const oneHour = Utils.hours(1)
    const oneMinute = Utils.minutes(1)
    const oneSecond = Utils.seconds(1)
    const timers = { days: 0, hours: 0, minutes: 0, seconds: 0 } as Dictionary<number>

    if (time >= oneDay) {
      timers.days = Math.floor(time / oneDay)
      deduce(timers.days * oneDay)
    }

    if (time >= oneHour) {
      timers.hours = Math.floor(time / oneHour)
      deduce(timers.hours * oneHour)
    }

    if (time >= oneMinute) {
      timers.minutes = Math.floor(time / oneMinute)
      deduce(timers.minutes * oneMinute)
    }

    if (time >= oneSecond) {
      timers.seconds = Math.ceil(time / oneSecond)
    }

    if (returnType === "with-pad") {
      return Object.keys(timers).reduce((structure, key) => {
        structure[key] = leftPad(timers[key])
        return structure
      }, {} as Dictionary<string>)
    } else {
      return timers as Dictionary<number>
    }
  }

  public static maxLength(text: string, maxLen: number) {
    if (text && text.length >= maxLen) {
      const slicedText = text.slice(0, maxLen)
      const dots = "..."

      if (slicedText.slice(-2).includes("..")) {
        return slicedText.slice(0, maxLen - 3) + dots
      } else if (slicedText.slice(-1).includes(".")) {
        return slicedText.slice(0, maxLen - 2) + dots
      }

      return slicedText + dots
    }
    return text
  }
}
