<template>
  <div class="loginRedirect"></div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import router, { navigate, navigateAttemptedRoute } from "@/router"
import { session } from "@/auth/session"
import { raiseError } from "@/utils/event-bus"

export default defineComponent({
  name: "LoginRedirect",
  setup() {
    const _validateUserAccess = () => {
      const hasAccess = router.currentRoute.meta?.allowAccess

      if (!hasAccess) {
        navigate({ name: "Login" })
      }
    }

    const _validateUserSession = async () => {
      try {
        const user = await session.getUser()

        if (!user) {
          raiseError({ text: "Invalid user", error: "Invalid user" })
        } else {
          navigateAttemptedRoute()
        }
      } catch {
        await session.terminate()
        navigate({ name: "Login" })
      }
    }

    _validateUserAccess()
    _validateUserSession()
  }
})
</script>
