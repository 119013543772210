<template>
  <div id="templateOptions">
    <h3>
      {{ $t("workspaces.selectTemplateToCreateNewWorkspace") }}
    </h3>

    <template-topbar v-model="searchQuery" @filter="applyFilter" :loading="loader.on" />

    <div class="templateContainer">
      <div class="leftBox">
        <div class="tagGroup">
          <label> {{ $t("WorkspaceWizard.labels.tagsBeingUsed") }} </label>

          <v-chip-group v-model="tagsFilter" @change="applyTagFilter" :disabled="loader.on" multiple column>
            <div class="chips" v-if="tags.keys().length || plans.length">
              <v-chip v-for="tag in tags.keys()" :key="tag" :value="tag" class="chipItem">
                <span class="tag">{{ tag }}</span>
                <span class="tagCounter">{{ tags.get(tag) }}</span>
              </v-chip>

              <tooltip
                v-for="(plan, index) in plans"
                :key="plan"
                :text="$t('WorkspaceWizard.labels.templatePlan')"
                :left="index % 2 === 0"
                :right="index % 2 === 1"
              >
                <v-chip :value="plan" class="chipItem">
                  <span class="plan">{{ plan }}</span>
                  <span class="tagCounter">{{ getPlanCount(plan) }}</span>
                </v-chip>
              </tooltip>
            </div>

            <div class="empty" v-else>
              <span>{{ $t("WorkspaceWizard.labels.emptyTags") }}</span>
            </div>
          </v-chip-group>
        </div>
      </div>

      <div class="rightBox">
        <v-progress-linear v-if="loader.on" class="my-0" indeterminate />

        <template-list
          v-model="newWorkspace"
          @open:instructions="openInstructions"
          @next="$emit('next')"
          :items="filteredTemplateList"
        />
      </div>
    </div>

    <instructions-popup v-model="instructionDetails" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import { WorkspaceRawTemplate, CreateWorkspacePayload } from "@/types/workspace"
import { UsersModule } from "@/store/users"
import Dialog from "@/components/global/Dialog.vue"
import TemplateTopbar from "./TemplateTopbar.vue"
import TemplateList from "./TemplateList.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Loader from "@/utils/loader"
import Utils from "@/utils/utils"
import Router, { replace } from "@/router"
import InstructionsPopup from "./InstructionsPopup.vue"

export default defineComponent({
  name: "TemplateRoot",
  props: {
    value: {
      type: Object as PropType<CreateWorkspacePayload>,
      required: true
    }
  },
  setup(props, ctx) {
    const newWorkspace = Utils.vModel(props, ctx.emit)
    const usersModule = UsersModule()
    const loader = Loader()
    const tags = ref(new Map<string, number>())
    const plans = ref<string[]>([])
    const tagsFilter = ref([] as string[])
    const ownerFilter = ref("all")
    const allTemplates = ref<WorkspaceRawTemplate[]>([])
    const filteredTemplateList = ref<WorkspaceRawTemplate[]>([])
    const searchQuery = ref("")
    const workspaceModule = WorkspaceModule()
    const instructionDetails = ref("")

    const _addPlans = (WorkspaceRawTemplate: WorkspaceRawTemplate) => {
      if (WorkspaceRawTemplate.plan) {
        const notAlreadyIncluded = !plans.value.includes(WorkspaceRawTemplate.plan)

        if (notAlreadyIncluded) {
          plans.value.push(WorkspaceRawTemplate.plan)
        }
      }
    }

    const _addTags = (WorkspaceRawTemplate: WorkspaceRawTemplate) => {
      const templateTags = WorkspaceRawTemplate.tags

      if (templateTags && templateTags?.length > 0) {
        templateTags.forEach(tag => {
          if (tag) {
            const tagBeingUsedCount = allTemplates.value.filter(template => {
              if (template.tags) {
                return template.tags.includes(tag)
              }

              return false
            }).length

            if (tagBeingUsedCount > 0) {
              tags.value.set(tag, tagBeingUsedCount)
            }
          }
        })
      }
    }

    const _cleanFilters = () => {
      filteredTemplateList.value = [...allTemplates.value]
    }

    const _applySearchFilter = (searchQuery: string) => {
      const hasValidFilterKey = Boolean(searchQuery)

      if (hasValidFilterKey) {
        const filterKeyWord = searchQuery.trim().toLowerCase()

        const filteredBySearchQuery = allTemplates.value.filter(template => {
          const title = template.title.toLowerCase()

          return title.includes(filterKeyWord)
        })

        filteredTemplateList.value = [...filteredBySearchQuery]
      } else {
        _cleanFilters()
      }
    }

    const _readQueryTags = () => {
      const { tags } = Router.currentRoute.query

      if (tags && typeof tags === "string") {
        tagsFilter.value = tags.split(",").map(tag => Utils.capitalize(tag))
        applyTagFilter()
      }
    }

    const _syncRouteWithQueryTags = () => {
      if (tagsFilter.value.length) {
        const spreadTags = tagsFilter.value.reduce((tagCollection, tag) => {
          if (!tagCollection) {
            tagCollection = `${tag}`
          } else {
            tagCollection += `,${tag}`
          }

          return tagCollection
        }, "")

        replace({ query: { tags: spreadTags } })
      } else {
        replace({ query: {} })
      }
    }

    const getPlanCount = (planName: string) => {
      return allTemplates.value.filter(template => template.plan === planName).length
    }

    const openInstructions = (instructions: string) => {
      instructionDetails.value = instructions
    }

    const applyFilter = () => {
      if (searchQuery.value) {
        tagsFilter.value = []
        _applySearchFilter(searchQuery.value)
      } else {
        _cleanFilters()
      }
    }

    const applyTagFilter = () => {
      if (tagsFilter.value.length > 0) {
        searchQuery.value = ""

        const filteredByTag = allTemplates.value.filter(template => {
          const matchedFilter = ref<boolean>(false)

          if (template.tags) {
            matchedFilter.value = template.tags.some(tag => tagsFilter.value.includes(tag))
          }

          if (!matchedFilter.value && template.plan) {
            matchedFilter.value = tagsFilter.value.includes(template.plan)
          }

          return matchedFilter.value
        })

        filteredTemplateList.value = [...filteredByTag]
      } else {
        _cleanFilters()
      }

      _syncRouteWithQueryTags()
    }

    loader.run(async () => {
      if (usersModule.selectedOrganizationId) {
        const requestedTemplates = await workspaceModule.getTemplates(usersModule.selectedOrganizationId)

        allTemplates.value = requestedTemplates
        tags.value.clear()

        allTemplates.value.forEach(WorkspaceRawTemplate => {
          _addPlans(WorkspaceRawTemplate)
          _addTags(WorkspaceRawTemplate)
        })

        _cleanFilters()
        _readQueryTags()
      }
    })

    return {
      loader,
      openInstructions,
      instructionDetails,
      newWorkspace,
      filteredTemplateList,
      tags,
      tagsFilter,
      ownerFilter,
      plans,
      searchQuery,
      getPlanCount,
      applyTagFilter,
      applyFilter
    }
  },
  components: {
    Dialog,
    InstructionsPopup,
    TemplateTopbar,
    TemplateList,
    Tooltip
  }
})
</script>
