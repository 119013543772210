<template>
  <v-label>
    <span v-if="caption">{{ caption }}</span>

    <load-image
      @click="sendToDashboard"
      :media-context-item-id="orgId"
      :maxWidth="imageSize.width"
      :maxHeight="imageSize.height"
      :class="componentClasses"
      :key="renderKey"
      media-context="organization-logo"
    />
  </v-label>
</template>

<script lang="ts">
import { computed, ref, defineComponent } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import { eventBus } from "@/main"
import LoadImage from "@/components/global/LoadImage.vue"
import Router, { navigate } from "@/router"
import Utils from "@/utils/utils"
import "./scss/_orgLogo.scss"

export default defineComponent({
  name: "OrganizationLogo",
  props: {
    small: {
      type: Boolean,
      default: false
    },
    redirects: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    organizationId: {
      type: String,
      required: false
    },
    caption: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const usersModule = UsersModule()
    const renderKey = ref(0)

    const orgId = computed(() => {
      if (props.organizationId) {
        return props.organizationId
      }

      return usersModule.selectedOrganizationId
    })

    const imageSize = computed(() => {
      if (Utils.propHas(props, "small")) {
        return {
          width: 200,
          height: 32
        }
      } else {
        return {
          width: 300,
          height: 60
        }
      }
    })

    const componentClasses = computed(() => {
      let classes = "topBarGenericLogo"
      const concat = (addedClass: string) => (classes += ` ${addedClass} `).trim()

      if (!props.noPadding) {
        concat("addSpacing")
      }
      if (props.redirects && Router.currentRoute.name !== "WorkspacesList") {
        concat("redirects")
      }

      return classes
    })

    const sendToDashboard = () => {
      if (props.redirects) {
        navigate({ name: "WorkspacesList" })
      }
    }

    const _forceLogoUpdate = () => {
      renderKey.value++
    }

    eventBus.$on("update:platform-logo", _forceLogoUpdate)

    return {
      orgId,
      renderKey,
      imageSize,
      componentClasses,
      sendToDashboard
    }
  },
  components: {
    LoadImage
  }
})
</script>
