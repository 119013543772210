import Utils from "@/utils/utils"

export default {
  appLauncher: Utils.seconds(8),
  workspaceEditPeering: Utils.seconds(60),
  workspaceEdit: Utils.seconds(8),
  appLauncherWallet: Utils.seconds(8),
  workspaceList: Utils.seconds(8),
  workspaceEditAlarm: Utils.seconds(10),
  notifyBrowser: Utils.minutes(1)
}
