<template>
  <li :class="`accordionItem --is-open-${isOpen}`">
    <div @click.stop="$emit('toggle:open', tagDetails.name)" class="accordionHeader">
      <div class="tagBox">
        <span class="tagName">{{ tagDetails.name }}</span>

        <v-icon>mdi-equal</v-icon>

        <ul v-if="tagsInDisplay.length" class="tagsCreated">
          <li v-for="option in tagsInDisplay" :key="option">{{ option }}</li>
          <li v-if="tagsHidden > 0" class="hiddenTagCounter">+ {{ tagsHidden }}</li>
        </ul>

        <small v-else>{{ t("noOptions") }}</small>
      </div>

      <span class="triangle">
        <v-icon>mdi-menu-down</v-icon>
      </span>
    </div>

    <tag-editor v-if="isOpen" @save="save" @remove="confirmRemove" :details="tagDetails.value" :loading="loading" />
  </li>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { OrganizationTag, TagOptions } from "@/types/organization"
import { createTranslationModule } from "@/plugins/i18n"
import { raiseConfirmation } from "@/utils/event-bus"
import TagEditor from "./_TagEditor.vue"

export default defineComponent({
  name: "TagName",
  props: {
    tagDetails: {
      type: Object as PropType<OrganizationTag>,
      required: true
    },
    opened: {
      type: Array as PropType<string[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const t = createTranslationModule("OrganizationPageV2.spaceTags")
    const MAX_TAG_DISPLAY = 3

    const isOpen = computed(() => {
      return props.opened.includes(props.tagDetails.name)
    })

    const tagsInDisplay = computed(() => {
      return props.tagDetails.value.options.slice(0, MAX_TAG_DISPLAY)
    })

    const tagsHidden = computed(() => {
      const totalTagCount = props.tagDetails.value.options.length
      return totalTagCount - tagsInDisplay.value.length
    })

    const save = (details: TagOptions) => {
      ctx.emit("save", {
        name: props.tagDetails.name,
        value: details
      })
    }

    const confirmRemove = async () => {
      if (await raiseConfirmation({ text: t("confirmRemove", { tagName: props.tagDetails.name }) })) {
        ctx.emit("remove", props.tagDetails.name)
      }
    }

    return {
      save,
      confirmRemove,
      tagsInDisplay,
      tagsHidden,
      isOpen,
      t
    }
  },
  components: {
    TagEditor
  }
})
</script>
