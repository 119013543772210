<template>
  <div class="workspaceAlertsContainer">
    <error-alert
      v-if="showDestroyFailedAlert"
      @click="$emit('retry:destroy')"
      @cancel="$emit('unlock:workspace')"
      :feedback="workspace.lastErrorTitle"
    />

    <deployed-with-error-alert
      v-else-if="errorTypeDeployedWithError"
      @redeploy="$emit('redeploy')"
      @destroy="$emit('destroy')"
      :feedback="workspace.lastErrorTitle"
      :disabled="loading"
    />

    <error-alert
      v-else-if="showErrorAlert || !disableErrorAlertActions"
      @click="$emit('unlock')"
      @cancel="$emit('unlock:workspace')"
      :disabled="disableErrorAlertActions"
      :feedback="workspace.lastErrorTitle"
    />

    <update-alert
      v-if="showPendingUpdate && !showErrorAlert && !showNoAppsAlert"
      @click="$emit('redeploy')"
      @discard="$emit('discard')"
    />

    <dns-messages
      v-if="showCertificate && !isRunning"
      @click="$emit('redeploy')"
      @renew:certificate="$emit('renew:certificate')"
      :certificate="certificate"
      :loading="loading"
    />

    <no-apps v-if="showNoAppsAlert" @discard="$emit('discard')" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { CertificateVisibleState, WorkspaceTemplate, WorkspaceStatusEnum } from "@/types/workspace"
import { UsersModule } from "@/store/users"
import { WorkspaceStates, Draft, DeployedWithError } from "@/config/workspace-states"
import ErrorAlert from "./ErrorAlert.vue"
import UpdateAlert from "./UpdateAlert.vue"
import NoApps from "./NoApps.vue"
import DnsMessages from "./DnsMessages.vue"
import DeployedWithErrorAlert from "./DeployedWithErrorAlert.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "WorkspaceAlerts",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const usersModule = UsersModule()

    const showErrorAlert = computed(() => {
      return !!props.workspace.lastErrorTitle
    })

    const updateWarning = computed(() => {
      return !!props.workspace.updateWarning
    })

    const errorTypeDeployedWithError = computed(() => {
      return props.workspace.status === DeployedWithError
    })

    const disableErrorAlertActions = computed(() => {
      const { ArchiveFailed, DeployFailed, DeployedWithError } = WorkspaceStatusEnum
      const errorStates = [ArchiveFailed, DeployFailed, DeployedWithError]

      return !errorStates.includes(props.workspace.status)
    })

    const showDestroyFailedAlert = computed(() => {
      const { DestroyFailed } = WorkspaceStatusEnum
      return props.workspace.status === DestroyFailed
    })

    const showCertificate = computed(() => {
      const { warning, danger } = CertificateVisibleState

      return certificate.value && [warning, danger].includes(certificate.value.visibleState)
    })

    const certificate = computed(() => {
      const { certificateInfo } = props.workspace

      if (certificateInfo && certificateInfo?.status) {
        return certificateInfo
      }

      return false
    })

    const isRunning = computed(() => {
      return WorkspaceStates.isLoading(props.workspace.status)
    })

    const showPendingUpdate = computed(() => {
      const { workspace } = props
      const workspaceDeployed = workspace as WorkspaceTemplate
      const updatedSchema = JSON.stringify(workspace.schema)
      const deployed = Utils.isType<WorkspaceTemplate>(workspaceDeployed, "schemaDeployed")

      if (workspace.status === Draft || isRunning.value) {
        return false
      } else if (deployed && deployed.schemaDeployed) {
        const deployedSchema = JSON.stringify(deployed.schemaDeployed)

        return updatedSchema !== deployedSchema && !showErrorAlert.value && usersModule.hasEditorPermissions
      }

      return false
    })

    const showNoAppsAlert = computed(() => {
      const workspaceSchema = props.workspace.schema.assets

      return workspaceSchema.length === 0
    })

    return {
      updateWarning,
      errorTypeDeployedWithError,
      disableErrorAlertActions,
      showDestroyFailedAlert,
      showNoAppsAlert,
      showPendingUpdate,
      showErrorAlert,
      showCertificate,
      certificate,
      isRunning
    }
  },
  components: {
    DeployedWithErrorAlert,
    NoApps,
    ErrorAlert,
    DnsMessages,
    UpdateAlert
  }
})
</script>
