import { LoaderConfig } from "./type"
import { reactive } from "@vue/composition-api"
import Loader from "./loader"

/**
 * ## Loader class
 *
 * #### Description
 * Used to avoid try catch logic, making functions less verbose.
 * Auto handles loading state using **loader.on**, with built-in
 * customizable warning messages using raiseError and raiseSuccess.
 *
 * #### How to use
 * @example
 * // Default settings
 * const loader = LoadHandler({
 *  alertOnError: true,
 *  alertOnSuccess: false,
 *  throwOnError: false,
 *  debugging: false,
 * })
 */
const LoadHandler = (config: LoaderConfig = {}) => reactive(new Loader(config))

export default LoadHandler
