import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { SystemMessage } from "@/types/messages"

const api = new Request("/v1/SystemMessage/")

export default createService("changeLog", {
  async getSystemMessage() {
    return await api.get<SystemMessage>("")
  },

  async setNewMessage(message: string) {
    return await api.post("", { message })
  },

  async setActive(active: boolean) {
    return await api.put(`SetActive/`, { active })
  }
})
