import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"disabled":_vm.wasRemoved,"nudge-bottom":35,"content-class":"tagEditMenu","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"menuContainer",class:_vm.disabled ? '--click-disabled' : ''},'div',attrs,false),on),[_c('tooltip',{attrs:{"text":_vm.$t('TagPill.clickToEdit'),"disabled":!!_vm.tagValue || _vm.wasRemoved}},[_c('string-max',{attrs:{"custom-class":"tagName","text":_vm.tag.name,"max":_vm.MAX_TAG_NAME_VIEW,"tooltip-disabled":!_vm.tagValue}})],1),(_vm.tagValue)?_c('string-max',{attrs:{"custom-class":"tagValue","text":_vm.tagValue,"max":_vm.MAX_TAG_VALUE_VIEW}}):_vm._e(),_c('tooltip',{attrs:{"text":_vm.$t('WorkspaceEdit.tags.removedTag'),"disabled":!_vm.wasRemoved}},[(!_vm.disabled && !_vm.isRequired)?_c(VBtn,{attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmRemove.apply(null, arguments)}}},[(!_vm.wasRemoved)?_c(VIcon,[_vm._v("mdi-close")]):_c(VIcon,[_vm._v("mdi-restore")])],1):_vm._e()],1)],1)]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('div',{staticClass:"editTagValue"},[(_vm.menuOpen)?_c('handle-selector',{attrs:{"loading":_vm.loading,"current-value":_vm.tag.value,"settings":_vm.settings},on:{"edit":_vm.edit}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }