<template>
  <div class="organizationCard cloudProvider">
    <h3>{{ t("title") }}</h3>

    <p class="label">
      {{ t("subtitle") }}
    </p>

    <div class="cloudProviderContainer">
      <div class="wrapSelectors">
        <v-select
          :value="currentCloudProvider"
          :items="cloudProviderOptions"
          :disabled="!hasMoreThanOneOptionOfProvider"
          hide-details
        />

        <v-select v-model="cloudProviderMaxUsed" :items="cloudProviderCountOptions" hide-details />
      </div>

      <draggable
        v-if="bestRegions.length"
        v-model="bestRegions"
        @start="dragging = true"
        @end="endDragging"
        :key="renderKey"
        tag="ul"
        ghost-class="draggingOver"
        draggable="[draggable]"
        class="draggable"
      >
        <li
          v-for="(region, index) in bestRegions"
          :class="`dragHandler --active-index-${index + 1 <= cloudProviderMaxUsed}`"
          :key="`${region.code}-${region.ping}`"
          draggable
        >
          <div class="handler">
            <v-icon left>mdi-drag</v-icon>

            <span>
              {{ region.fullName }}
            </span>
          </div>

          <span :class="`ping --ping-speed-${getPingSpeed(region.ping)}`">
            <span :class="`graph`">
              <span v-for="n in 5" :key="n"></span>
            </span>

            <span class="pingNumber">{{ region.ping }} ms</span>
          </span>
        </li>
      </draggable>

      <div class="controller">
        <v-btn @click="arrangeZones" large>{{ t("arrangeBestZones") }}</v-btn>

        <v-btn
          v-if="hasModificationToRegions"
          :loading="loader.on"
          @click="saveCloudProviderOrder"
          color="primary"
          large
          >{{ t("save") }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onBeforeUnmount } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import { getPingSpeed } from "@/config/cloud-provider-ping-speed"
import { useStore } from "@/composable/useStore"
import { CloudZone } from "@/types/core"
import availableCloudProviders from "@/config/cloud-provider"
import Loader from "@/utils/loader"
import Utils from "@/utils/utils"
import Draggable from "vuedraggable"

export default defineComponent({
  name: "CloudProvider",
  props: {
    hasTabFocused: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const t = createTranslationModule("OrganizationPageV2.cloudProvider")
    const { organizationModule, adminModule, licenseModule } = useStore()
    const loader = Loader()
    const dragging = ref(false)
    const isMounted = ref(true)
    const renderKey = ref(0)
    const cloudProviderMaxUsed = ref(3)
    const bestRegions = ref<CloudZone[]>([])

    const cloudProviderOptions = computed(() => {
      return availableCloudProviders
    })

    const organization = computed(() => {
      return organizationModule.organizationDetail
    })

    const hasModificationToRegions = computed(() => {
      const cuttingIndex = cloudProviderMaxUsed.value
      const compareBestRegions = bestRegions.value.slice(0, cuttingIndex)
      const compareCachedRegion = adminModule.cloudProviders.slice(0, cuttingIndex)

      return !compareBestRegions.every((region, index) => compareCachedRegion[index]?.name === region.name)
    })

    const currentCloudProvider = computed(() => {
      return organization.value.cloudProvider.name
    })

    const hasMoreThanOneOptionOfProvider = computed(() => {
      return cloudProviderOptions.value.some(option => {
        if (option.value !== currentCloudProvider.value) {
          return !option.disabled
        }
        return false
      })
    })

    const cloudProviderCountOptions = computed((): number[] => {
      const options: number[] = []
      const AWS_MAX_REGIONS_FALLBACK = 10
      const maxRegions = adminModule.cloudProviders.length || AWS_MAX_REGIONS_FALLBACK

      for (let i = 0; i < maxRegions; i++) {
        options.push(i + 1)
      }

      return options
    })

    const arrangeZones = () => {
      bestRegions.value.sort((a, b) => {
        return (a.ping ?? 0) - (b.ping ?? 0)
      })
    }

    const endDragging = () => {
      dragging.value = false
      renderKey.value++
    }

    const saveCloudProviderOrder = () => {
      loader.run(async () => {
        const newZones = bestRegions.value.slice(0, cloudProviderMaxUsed.value).map(zone => zone.code)

        await adminModule.updateOrganization({
          ...organization.value,
          cloudZones: newZones
        })

        await _updateBestRegionsStack()
      })
    }

    const _updateBestRegionsStack = async () => {
      await organizationModule.getOrganizationDetail(organization.value.id)
      _orderUsingOrganizationPreferences()
    }

    const _keepRegionsPingUpdated = async () => {
      const latency = Utils.seconds(2.5)

      if (isMounted.value) {
        setTimeout(async () => {
          if (!dragging.value && props.hasTabFocused) {
            await adminModule.refreshRegionPing(dragging.value)
          }
          _keepRegionsPingUpdated()
        }, latency)
      }
    }

    const _orderUsingOrganizationPreferences = () => {
      const organizationRegions = organization.value.cloudZones as string[]
      cloudProviderMaxUsed.value = organizationRegions.length

      bestRegions.value.sort(_sortLogic)
      adminModule.cloudProviders.sort(_sortLogic)
    }

    const _sortLogic = (zoneA: CloudZone, zoneB: CloudZone) => {
      const organizationRegions = organization.value.cloudZones as string[]
      const isInOrganizationA = organizationRegions.includes(zoneA.code)
      const isInOrganizationB = organizationRegions.includes(zoneB.code)
      const indexA = organizationRegions.indexOf(zoneA.code)
      const indexB = organizationRegions.indexOf(zoneB.code)

      if (isInOrganizationA && !isInOrganizationB) {
        return -1
      } else if (!isInOrganizationA && isInOrganizationB) {
        return 1
      } else if (isInOrganizationA && isInOrganizationB) {
        return indexA - indexB
      } else {
        return 0
      }
    }

    loader.run(async () => {
      await licenseModule.getRegions()
      await adminModule.getCloudZones(licenseModule.regions)
      await _updateBestRegionsStack()

      bestRegions.value = [...adminModule.cloudProviders]
      _orderUsingOrganizationPreferences()
      _keepRegionsPingUpdated()
    })

    onBeforeUnmount(() => {
      isMounted.value = false
    })

    return {
      hasModificationToRegions,
      arrangeZones,
      bestRegions,
      cloudProviderCountOptions,
      cloudProviderMaxUsed,
      cloudProviderOptions,
      currentCloudProvider,
      dragging,
      endDragging,
      getPingSpeed,
      hasMoreThanOneOptionOfProvider,
      loader,
      renderKey,
      saveCloudProviderOrder,
      t
    }
  },
  components: {
    Draggable
  }
})
</script>
