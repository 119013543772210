<template>
  <div id="licenseErrorHandler">
    <div class="containLog">
      <warning-bar v-for="error in errorIterator" :key="error" type="error" :text="error" :title="errorTitle" />
    </div>

    <v-btn @click="reset" color="error" large>
      <span>
        <v-icon left>action_refresh</v-icon>
        {{ $t("Onboarding.licenseServer.button.restart") }}
      </span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api"
import { LicenseModule } from "@/store/license-server"
import WarningBar from "@/components/warningBar/WarningBarRoot.vue"

export default defineComponent({
  name: "LicenseServerErrorHandler",
  setup(_, ctx) {
    const licenseModule = LicenseModule()

    const reset = () => {
      ctx.emit("reset")
    }

    const _errorMsg = computed(() => {
      return licenseModule.workspaceLogged.lastErrorDetails
    })

    const errorIterator = computed(() => {
      if (_errorMsg.value) {
        return _errorMsg.value.split(".:")
      }
      return []
    })

    const errorTitle = computed(() => {
      return licenseModule.workspaceLogged.lastErrorTitle
    })

    return {
      reset,
      errorTitle,
      errorIterator
    }
  },
  components: {
    WarningBar
  }
})
</script>
