<template>
  <v-btn @click="navigateWorkspaceCreation" color="primary" rbt-target="createWorkspace" large>
    <v-icon left>action_add</v-icon>
    <span>{{ $t("MyWorkspaces.button.createNewWorkspace") }}</span>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { navigate } from "@/router"

export default defineComponent({
  name: "BtnCreateWorkspace",
  setup() {
    const navigateWorkspaceCreation = () => {
      navigate({ name: "WorkspaceConfig" })
    }

    return {
      navigateWorkspaceCreation
    }
  }
})
</script>
