<template>
  <div id="tabs">
    <div class="tabHeader">
      <ul class="tabContainer">
        <li
          v-for="tab in tabs"
          :key="`header-${tab.value}`"
          :disabled="currentTabIndex < tab.value"
          :color="tabColor(tab.value)"
        >
          <span>
            <img :src="tab.icon" />
          </span>

          <div class="stepLabel">
            <v-btn
              @click="goToStep(tab.value)"
              :disabled="stepReached < tab.value || lockedTabs"
              :color="tabColor(tab.value)"
              :step-open="stepReached >= tab.value && !lockedTabs"
              class="stepTracker"
              rounded
              icon
              outlined
            >
              <v-icon v-if="stepReached > tab.value" color="success">status_ok</v-icon>

              <span v-else class="white--text">
                {{ tab.value }}
              </span>
            </v-btn>

            <span :active="currentTabIndex <= tab.value">
              {{ tab.label }}
            </span>
          </div>
        </li>
      </ul>
    </div>

    <v-tabs-items v-model="currentTabIndex">
      <v-tab-item v-for="tab in tabs" :key="tab.value" :value="tab.value">
        <v-card flat>
          <v-card-text>
            <component :is="tab.component" @next="nextStep" @back="backStep" @lock="lockTabs" @unlock="unlockTabs" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, provide } from "@vue/composition-api"
import { OrganizationType } from "@/types/organization"
import { AdminModule } from "@/store"
import { navigate } from "@/router"
import tabOptions from "../lib/tab-options"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "OnboardingTabs",
  props: {
    value: {
      type: Number,
      required: true
    },
    stepReached: {
      type: Number,
      required: true
    },
    isCustomerHosted: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    provide("isCustomerHosted", props.isCustomerHosted)

    const currentTabIndex = Utils.vModel(props, ctx.emit)
    const lockedTabs = ref(false)
    const adminModule = AdminModule()

    const _lastStep = computed(() => {
      return tabs.value.slice(-1)[0].value
    })

    const isCustomerHosted = computed(() => {
      return adminModule?.organizationDetail?.organizationType === OrganizationType.CustomerHosted
    })

    const tabs = computed(() => {
      return tabOptions(isCustomerHosted.value)
    })

    const tabColor = computed(() => {
      return (tabValue: number) => {
        if (tabValue === currentTabIndex.value) {
          return "primary"
        } else if (tabValue < currentTabIndex.value) {
          return "success"
        } else {
          return ""
        }
      }
    })

    const lockTabs = () => {
      lockedTabs.value = true
    }

    const unlockTabs = () => {
      lockedTabs.value = false
    }

    const goToStep = (step: number) => {
      currentTabIndex.value = step
    }

    const nextStep = () => {
      if (currentTabIndex.value < _lastStep.value) {
        currentTabIndex.value += 1
      } else if (!isCustomerHosted.value) {
        navigate({ name: "WorkspacesList" })
      }
      ctx.emit("update:step")
    }

    const backStep = () => {
      currentTabIndex.value -= 1
    }

    return {
      currentTabIndex,
      tabs,
      tabColor,
      lockedTabs,
      unlockTabs,
      lockTabs,
      nextStep,
      backStep,
      goToStep
    }
  }
})
</script>
