<template>
  <popup
    v-model="showPopup"
    @confirm="saveSettings"
    :title="t('title')"
    :loading="loader.on"
    width="650px"
    class="autoShutdownSettings"
  >
    <div class="scheduleController shutdownContainer">
      <div>
        <v-checkbox
          @change="() => selectOneCheckbox('schedule')"
          :value="featureController.useSchedule"
          :label="t('scheduleSession.name')"
          :disabled="locked"
          hide-details
        />
      </div>

      <div class="childContainer">
        <p class="label">
          {{ t("scheduleSession.explanation") }}
        </p>

        <scheduler-settings
          v-if="featureController.useSchedule"
          :auto-shutdown-enabled="featureController.useAutoShutdown"
          :readonly="locked"
        />
      </div>
    </div>

    <div class="timerSettingsController shutdownContainer">
      <v-checkbox
        @change="() => selectOneCheckbox('auto-shutdown')"
        :value="featureController.useAutoShutdown"
        :label="t('timerSettings.name')"
        :disabled="locked"
        hide-details
      />

      <div class="childContainer">
        <p class="label">{{ t("timerSettings.explanation") }}</p>

        <timer-settings v-if="featureController.useAutoShutdown" v-model="shutdownSettings" :readonly="locked" />
      </div>
    </div>

    <div v-if="isSaas && featureController.useAutoShutdown" class="shutdownContainer inactivitySettings">
      <v-checkbox
        v-model="shutdownSettings.inactivityTrackerEnabled"
        :disabled="locked"
        :label="t('inactivity.label')"
        hide-details
      />

      <div class="childContainer">
        <p class="label">{{ t("inactivity.explanation") }}</p>
      </div>
    </div>

    <div class="shutdownContainer warningZone">
      <alert-bar v-if="speculationRange" type="info">
        <template v-slot:description>
          <span v-html="t('rangeSpeculation', { time: speculationRange })" />
        </template>
      </alert-bar>

      <alert-bar v-if="showForcedShutdownWarning" :description="t('inactivity.forcedShutdownWarning')" type="warning" />

      <alert-bar
        v-else-if="showInactivityPrecautionWarning"
        :description="t('inactivity.inactivityCheckWarning')"
        :type="shutdownSettings.inactivityTrackerEnabled ? 'info' : 'warning'"
      />
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, onBeforeUnmount } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import { useStore } from "@/composable/useStore"
import { OrganizationType } from "@/types/organization"
import { ShutdownSettings } from "@/views/appLauncher/lib/types"
import Popup from "@/components/popup/PopupRoot.vue"
import Utils from "@/utils/utils"
import Loader from "@/utils/loader"
import TimerSettings from "./timer/_TimerSettings.vue"
import SchedulerSettings from "./schedule/_SchedulerSettings.vue"
import AlertBar from "@/components/alert-bar"
import "./auto-shutdown.scss"

export default defineComponent({
  name: "AutoShutdown",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { spaceModule, organizationModule } = useStore()
    const loader = Loader()
    const t = createTranslationModule("AutoShutdown")
    const showPopup = Utils.vModel(props, ctx.emit)

    const featureController = reactive({
      useSchedule: false,
      useAutoShutdown: false
    })

    const shutdownSettings = computed<ShutdownSettings>({
      get() {
        return spaceModule.shutdownSettings
      },
      set(settings: ShutdownSettings) {
        spaceModule.shutdownSettings = settings
      }
    })

    const isSaas = computed(() => {
      return organizationModule.organizationDetail.organizationType === OrganizationType.SaaS
    })

    const showForcedShutdownWarning = computed(() => {
      const { useAutoShutdown, useSchedule } = featureController

      if (useAutoShutdown || useSchedule) {
        if (isSaas.value) {
          return featureController.useAutoShutdown && !shutdownSettings.value.inactivityTrackerEnabled
        }

        return !featureController.useSchedule
      }

      return false
    })

    const showInactivityPrecautionWarning = computed(() => {
      if (isSaas.value) {
        return shutdownSettings.value.inactivityTrackerEnabled && featureController.useAutoShutdown
      }

      return false
    })

    const speculationRange = computed(() => {
      const { scheduledOnOff, shutdownSettings } = spaceModule
      const { useAutoShutdown, useSchedule } = featureController
      const startTime = scheduledOnOff.autoStartupAt
      const endTime = scheduledOnOff.autoShutdownAt

      if (!useAutoShutdown && !useSchedule) {
        return null
      }

      if (useAutoShutdown) {
        const hours = shutdownSettings.shutdownTimerInHours
        const [h, min] = `${hours}`.split(".")

        if (min) {
          return t("time.hour", { hh: h, mm: "30" })
        } else {
          return t("time.hour", { hh: h, mm: "00" })
        }
      }

      if (startTime && endTime) {
        const startDate = new Date(startTime).getTime()
        const endDate = new Date(endTime).getTime()
        const { days, hours, min } = Utils.dateRange(startDate, endDate)

        if (startDate >= endDate) {
          return null
        } else {
          if (days > 0) {
            return t("time.days", { days }, days)
          } else if (hours > 0) {
            return t("time.hour", { hh: hours, mm: min })
          } else if (min > 0) {
            return t("time.min", { mm: min })
          }
        }
      }

      return null
    })

    const selectOneCheckbox = (checkboxKeyName: "schedule" | "auto-shutdown") => {
      if (checkboxKeyName === "schedule") {
        featureController.useAutoShutdown = false
        featureController.useSchedule = !featureController.useSchedule
        spaceModule.scheduledOnOff.scheduledOnOffEnabled = featureController.useSchedule
        shutdownSettings.value.inactivityTrackerEnabled = false
        spaceModule.shutdownSettings.autoShutdownEnabled = false
      } else if (checkboxKeyName === "auto-shutdown") {
        featureController.useSchedule = false
        featureController.useAutoShutdown = !featureController.useAutoShutdown
        shutdownSettings.value.autoShutdownEnabled = featureController.useAutoShutdown
        spaceModule.scheduledOnOff.scheduledOnOffEnabled = false
      }
    }

    const saveSettings = () => {
      loader.run(async () => {
        const { useAutoShutdown, useSchedule } = featureController

        if (useSchedule) {
          await _useScheduleOnly()
        } else if (useAutoShutdown) {
          await _useAutoShutdownOnly()
        } else {
          await _disableAllShutdown()
        }

        showPopup.value = false
      })
    }

    const _init = () => {
      featureController.useAutoShutdown = shutdownSettings.value.autoShutdownEnabled
      featureController.useSchedule = spaceModule.scheduledOnOff.scheduledOnOffEnabled
    }

    const _useScheduleOnly = async () => {
      const id = spaceModule.watching.id
      const { autoShutdownAt, autoStartupAt } = spaceModule.scheduledOnOff

      if (autoShutdownAt || autoStartupAt) {
        await Promise.all([
          spaceModule.setShutdownSettings(id, {
            ...shutdownSettings.value,
            inactivityTrackerEnabled: false,
            autoShutdownEnabled: false
          }),
          spaceModule.setScheduledOnOff(id, {
            ...spaceModule.scheduledOnOff,
            scheduledOnOffEnabled: true
          })
        ])
      } else {
        await _disableAllShutdown()
      }
    }

    const _useAutoShutdownOnly = async () => {
      const id = spaceModule.watching.id

      await Promise.all([
        spaceModule.setShutdownSettings(id, {
          ...shutdownSettings.value,
          autoShutdownEnabled: true
        }),
        spaceModule.setScheduledOnOff(id, {
          autoShutdownAt: null,
          autoStartupAt: null,
          scheduledOnOffEnabled: false
        })
      ])
    }

    const _disableAllShutdown = async () => {
      const id = spaceModule.watching.id

      await Promise.all([
        spaceModule.setShutdownSettings(id, {
          ...shutdownSettings.value,
          autoShutdownEnabled: false
        }),
        spaceModule.setScheduledOnOff(id, {
          autoShutdownAt: null,
          autoStartupAt: null,
          scheduledOnOffEnabled: false
        })
      ])
    }

    loader.run(async () => {
      const id = spaceModule.watching.id

      await Promise.all([
        spaceModule.getAutoShutdownSettings(id),
        spaceModule.getScheduledOnOff(id),
        organizationModule.getOrganizationDetail(spaceModule.watching.organizationId)
      ])

      _init()
    })

    onBeforeUnmount(() => {
      loader.run(async () => {
        const id = spaceModule.watching.id

        await Promise.all([spaceModule.getAutoShutdownSettings(id), spaceModule.getScheduledOnOff(id)])
      })
    })

    return {
      t,
      showInactivityPrecautionWarning,
      showForcedShutdownWarning,
      saveSettings,
      speculationRange,
      featureController,
      loader,
      shutdownSettings,
      selectOneCheckbox,
      isSaas,
      showPopup
    }
  },
  components: {
    AlertBar,
    Popup,
    SchedulerSettings,
    TimerSettings
  }
})
</script>
