<template>
  <div class="alertErrorType">
    <generic-alert
      @cancel="confirmDiscard"
      :cancel-text="$t('WorkspaceEdit.alerts.buttons.discardChanges')"
      type="warning"
      icon="status_warning"
    >
      <p>
        {{ $t("WorkspaceEdit.alerts.feedbacks.noApps") }}
      </p>
    </generic-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import GenericAlert from "./GenericAlert.vue"

export default defineComponent({
  name: "NoApps",
  setup(_, ctx) {
    const confirmDiscard = async () => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.alerts.confirmation.confirmDiscard") })) {
        ctx.emit("discard")
      }
    }

    return {
      confirmDiscard
    }
  },
  components: {
    GenericAlert
  }
})
</script>
