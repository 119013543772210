<template>
  <div
    @click="$emit('click')"
    :selected="selected"
    :locked="locked"
    :class="availableClass"
    class="appItem"
    rbt-target="appItem"
  >
    <div class="cardContent">
      <div class="leftBox">
        <img :src="`/${app.icon}`" />
      </div>

      <div class="rightBox">
        <span class="appName">{{ app.text }}</span>

        <div class="appCounter">
          <span class="beingUsed">{{ app.currentCount }}</span>
          <span>/</span>
          <span class="total">{{ app.maxCount }}</span>
        </div>
      </div>

      <v-icon v-if="selected" color="primary" class="selectionIcon animateFadeUp"> status_ok_circle </v-icon>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { AppItem } from "@/types/workspace"

export default defineComponent({
  name: "MiniAppCard",
  props: {
    app: {
      type: Object as PropType<AppItem>,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const availableClass = computed(() => {
      return props.app.isAvailable ? "available" : "unavailable"
    })

    return {
      availableClass
    }
  }
})
</script>
