<template>
  <div id="dataCollector">
    <v-label>
      <span>{{ $t("Onboarding.deployAgent.form.title") }}</span>

      <div class="inputWithIcon">
        <v-text-field v-model="arnCode" @input.native="validateArnCode" :rules="inputRules" />

        <v-icon v-if="arnInputStatusIcon" :color="arnInputStatusIcon.color">
          {{ arnInputStatusIcon.icon }}
        </v-icon>
      </div>
    </v-label>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "@vue/composition-api"
import { validateArn } from "../../lib/arn-validation"
import { translate } from "@/plugins/i18n"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "DeployAgentForm",
  props: {
    value: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const validArn = ref(false)
    const arnCode = Utils.vModel(props, ctx.emit)

    const arnInputStatusIcon = computed(() => {
      if (validArn.value) {
        return {
          color: "success",
          icon: "status_ok_circle"
        }
      } else if (arnCode.value.length > 3) {
        return {
          color: "error",
          icon: "action_remove_circle"
        }
      }

      return false
    })

    const arnRegexCheckRule = () => {
      if (validateArn(arnCode.value)) {
        return true
      }

      return translate("Onboarding.ruleFeedback.invalidArn")
    }

    const validateArnCode = () => {
      const validRegex = arnRegexCheckRule() === true
      validArn.value = validRegex
    }

    return {
      inputRules: [arnRegexCheckRule],
      arnCode,
      arnInputStatusIcon,
      validateArnCode
    }
  }
})
</script>
