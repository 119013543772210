<template>
  <div id="feedbackList">
    <div
      v-for="(feedback, index) in items"
      :key="feedback.id"
      :class="`animationDelay-${index}`"
      :resolved="feedback.solvedTemp"
      class="feedbackItem animateFadeUp"
    >
      <span>
        <transition-checkbox
          v-model="feedback.solved"
          @pre:resolve="resolved => preResolve(resolved, feedback)"
          @resolve="resolved => feedbackSolve(resolved, feedback)"
          :loading="loading"
        />
      </span>

      <span>
        {{ transformDate(feedback.createdAt) }}
      </span>

      <span class="emojiSection">
        <figure v-if="feedback.rate !== null">
          <img :src="emojis[feedback.rate - 1]" />
        </figure>
      </span>

      <span class="feedbackType" :not-valid="!translateFeedbackType(feedback.feedbackType).valid">
        {{ translateFeedbackType(feedback.feedbackType).feedback }}
      </span>

      <span class="comments">
        <div v-if="feedback.comment" class="alignRow">
          <span class="comment">
            {{ feedback.comment }}
          </span>

          <v-btn @click="copyComment(feedback.comment)" icon>
            <v-icon>action_copy</v-icon>
          </v-btn>
        </div>

        <span v-if="feedback.email" class="userEmail">
          <v-icon>mdi-email-outline</v-icon>
          {{ feedback.email }}
        </span>

        <div v-if="feedback.uploadId" class="imageFeedback">
          <load-image
            v-if="feedback.uploadId"
            :media-context-item-id="feedback.uploadId"
            :media-context="feedbackContext"
            download
            auto-width
          />
        </div>
      </span>
    </div>

    <div v-if="emptyList" class="emptyList">
      <span>{{ $t("Admin.feedback.emptyList") }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { FeedbackItem, FeedbackType } from "@/types/feedback"
import { copyToClipboard } from "@/utils/ui"
import { ContextMedia } from "@/types/media"
import { translate } from "@/plugins/i18n"
import Utils from "@/utils/utils"
import Emojis from "@/components/feedbackDialog/templates/emojis"
import LoadImage from "@/components/global/LoadImage.vue"
import ImageVisualizer from "@/components/imageVisualizer/ImageVisualizerRoot.vue"
import TransitionCheckbox from "./TransitionCheckbox.vue"

export default defineComponent({
  name: "FeedbackList",
  props: {
    value: {
      type: Array as PropType<FeedbackItem[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const items = Utils.vModel<FeedbackItem[]>(props, ctx.emit)
    const emojis = [Emojis.horrible, Emojis.sad, Emojis.neutral, Emojis.happy, Emojis.love]

    const feedbackContext = computed(() => {
      return ContextMedia.feedbackFiles
    })

    const emptyList = computed(() => {
      return items.value.length === 0
    })

    const transformDate = (date: string) => {
      return Utils.dateWithNamedMonth(date)
    }

    const feedbackSolve = (solved: boolean, feedback: FeedbackItem) => {
      ctx.emit("resolve", { ...feedback, solved })
    }

    const preResolve = (solved: boolean, feedback: FeedbackItem) => {
      const feedbackIndex = items.value.findIndex(_feedback => _feedback.id === feedback.id)

      if (feedbackIndex !== -1) {
        const _feedback = items.value[feedbackIndex]
        _feedback.solvedTemp = solved
      }
    }

    const translateFeedbackType = (feedbackType: FeedbackType) => {
      const { general, bug, featureRequest, productRequest } = FeedbackType
      const validFeedbackTypes = [general, bug, featureRequest, productRequest]

      if (validFeedbackTypes.includes(feedbackType)) {
        return {
          valid: true,
          feedback: translate(`Admin.feedback.feedbackTypes.${feedbackType}`)
        }
      }

      return {
        valid: false,
        feedback: ""
      }
    }

    const copyComment = (comment: string) => {
      if (comment) {
        copyToClipboard(comment)
      }
    }

    return {
      preResolve,
      feedbackSolve,
      emojis,
      items,
      emptyList,
      translateFeedbackType,
      feedbackContext,
      copyComment,
      transformDate
    }
  },
  components: {
    TransitionCheckbox,
    ImageVisualizer,
    LoadImage
  }
})
</script>
