import { useStore } from "@/composable/useStore"
import { ref, computed, onBeforeUnmount } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import { AutoShutdownStatus } from "@/types/workspace"
import Utils from "@/utils/utils"

interface Shutdown {
  due?: string | null
  inactive?: string | null
  programmed?: string | null
}

export const useShutdown = () => {
  const { spaceModule } = useStore()
  const t = createTranslationModule("AutoShutdown.time")
  const now = ref(Date.now())
  const mounted = ref(true)

  const shutdownEnabled = computed(() => {
    return Boolean(spaceModule.watching.autoShutdownDefinition?.scheduledToShutdownAt)
  })

  const shutdown = computed<Shutdown | false>(() => {
    if (_spaceShutdown.value) {
      return _spaceShutdown.value
    } else if (_programmedShutdown.value) {
      return _programmedShutdown.value
    }

    return false
  })

  const _programmedShutdown = computed(() => {
    const enabled = spaceModule.shutdownSettings.autoShutdownEnabled

    if (enabled) {
      const settings = spaceModule.shutdownSettings
      const [h, min] = `${settings.shutdownTimerInHours}`.split(".").map(s => Number(s))
      const time = t("hour", { hh: h, mm: min ? "30" : "00" })

      return {
        programmed: time
      }
    }

    return false
  })

  const _spaceShutdown = computed(() => {
    if (shutdownEnabled.value) {
      const settings = spaceModule.watching.autoShutdownDefinition

      if (settings) {
        const isInactive = settings.status === AutoShutdownStatus.checkingInactivity

        if (isInactive) {
          const { min } = Utils.dateRange(now.value, settings.scheduledToShutdownAt)
          const dueTime = t("min", { mm: min })

          return {
            inactive: dueTime
          }
        } else {
          const { hours, min } = Utils.dateRange(now.value, settings.scheduledToShutdownAt)
          const dueTime = hours > 0 ? t("hour", { hh: hours, mm: min }) : t("min", { mm: min })

          return {
            due: dueTime
          }
        }
      }
    }

    return false
  })

  const _keepNowUpdated = () => {
    now.value = Date.now()

    setTimeout(() => {
      if (mounted.value) {
        _keepNowUpdated()
      }
    }, 1000)
  }
  _keepNowUpdated()

  onBeforeUnmount(() => {
    mounted.value = false
  })

  return {
    shutdown
  }
}
