<template>
  <v-btn
    v-if="showDeployButton && canSeeActionButtons"
    @click="deploy"
    :disabled="loading || isDisabled"
    color="primary"
    large
  >
    <span>{{ $t("WorkspaceEdit.deploy.buttonLabel") }}</span>
  </v-btn>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { WorkspaceTemplate } from "@/types/workspace"
import { Draft } from "@/config/workspace-states"
import { translate } from "@/plugins/i18n"
import { UsersModule } from "@/store"

export default defineComponent({
  name: "DeployButton",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()

    const deploy = async () => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.deploy.confirmationLabel") })) {
        ctx.emit("deploy")
      }
    }

    const showDeployButton = computed(() => {
      return props.workspace.status === Draft
    })

    const canSeeActionButtons = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const isDisabled = computed(() => {
      const workspaceSchema = props.workspace.schema.assets

      return workspaceSchema.length === 0
    })

    return {
      deploy,
      canSeeActionButtons,
      isDisabled,
      showDeployButton
    }
  }
})
</script>
