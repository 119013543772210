import { FeedbackItem } from "@/types/feedback"
import { FeedbackTypeStatistic, FeedbackRateStatistic } from "../lib/type"

const _mapFeedbackTypes = (feedbackCollection: FeedbackItem[]) => {
  return feedbackCollection.reduce(
    (types, feedback) => {
      if (feedback.feedbackType && types[feedback.feedbackType] !== undefined) {
        types[feedback.feedbackType]++
      } else {
        types.notSpecified++
      }

      return types
    },
    {
      bug: 0,
      general: 0,
      featureRequest: 0,
      productRequest: 0,
      notSpecified: 0
    }
  )
}

const _mapRates = (feedbackCollection: FeedbackItem[]) => {
  return feedbackCollection.reduce(
    (rates, feedback) => {
      if (feedback.rate !== null) {
        switch (feedback.rate) {
          case 0:
            rates.horrible++
            break
          case 1:
            rates.sad++
            break
          case 2:
            rates.neutral++
            break
          case 3:
            rates.happy++
            break
          case 4:
            rates.love++
            break
          default:
            break
        }
      }

      return rates
    },
    {
      horrible: 0,
      sad: 0,
      neutral: 0,
      happy: 0,
      love: 0
    }
  )
}

const _getPercent = (targetValue: number, statistic: FeedbackTypeStatistic | FeedbackRateStatistic) => {
  let totalAmount = 0

  Object.values(statistic).forEach(value => {
    totalAmount += value
  })

  return Math.floor((targetValue * 100) / totalAmount)
}

export const feedbackStatistics = (feedbackCollection: FeedbackItem[]) => {
  const feedbacks = _mapFeedbackTypes(feedbackCollection)
  const rates = _mapRates(feedbackCollection)

  return {
    feedbackType: {
      bug: _getPercent(feedbacks.bug, feedbacks),
      general: _getPercent(feedbacks.general, feedbacks),
      featureRequest: _getPercent(feedbacks.featureRequest, feedbacks),
      productRequest: _getPercent(feedbacks.productRequest, feedbacks),
      notSpecified: _getPercent(feedbacks.notSpecified, feedbacks)
    },
    rate: {
      horrible: _getPercent(rates.horrible, rates),
      sad: _getPercent(rates.sad, rates),
      neutral: _getPercent(rates.neutral, rates),
      happy: _getPercent(rates.happy, rates),
      love: _getPercent(rates.love, rates)
    },
    feedbackTypeRegistered: feedbacks,
    rateRegistered: rates
  }
}
