<template>
  <div
    id="popupContainer"
    v-if="showPopup"
    @keydown.esc="close"
    :rbt-target="rbtTargetTag"
    :no-padding="noPadding"
    class="animateFadeUp"
  >
    <div class="backdropFilter">
      <div v-if="closeOnClickOut && !loading" @click="close" class="target" />
    </div>

    <div class="boxWrapper">
      <div class="popupHeader">
        <span>
          <slot name="title">{{ title }}</slot>

          <help-box v-if="helpBox">
            <v-icon :size="14">status_question</v-icon>

            <template v-slot:content>
              <slot name="helpBox"> Missing content </slot>
            </template>
          </help-box>
        </span>

        <v-btn v-if="closeOnClickOut" @click="close" :disabled="loading" rbt-target="closePopup" icon>
          <v-icon>action_close</v-icon>
        </v-btn>
      </div>

      <div :style="popupContentWidth" class="popupMainContent">
        <div v-if="loading" class="loadingContainer">
          <v-progress-circular color="primary" indeterminate />
        </div>

        <!-- Main Slot -->
        <div class="mainContent">
          <slot />
        </div>
      </div>

      <div v-if="!hideFooter" class="popupFooter">
        <slot name="footer">
          <v-btn v-if="!hideButtonCancel" @click.stop="close" :disabled="loading" rbt-target="popupCancel" large>
            {{ compButtonCancelText }}
          </v-btn>

          <v-btn
            v-if="!hideButtonConfirm"
            @click.stop="confirm"
            :disabled="loading || disabled"
            :color="confirmationColor"
            rbt-target="popupConfirm"
            large
          >
            {{ compButtonConfirmText }}
          </v-btn>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { TranslateResult } from "vue-i18n"
import Utils from "@/utils/utils"
import HelpBox from "@/components/helpBox/HelpBox.vue"
import "./scss/_popup.scss"

export default defineComponent({
  name: "PopupRoot",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "50vw"
    },
    minWidth: {
      type: String,
      default: ""
    },
    closeOnClickOut: {
      type: Boolean,
      default: true
    },
    askConfirmBeforeClose: {
      type: Boolean,
      default: false
    },
    confirmationText: {
      type: String,
      default: ""
    },
    confirmationColor: {
      type: String,
      default: "primary"
    },
    buttonConfirmText: {
      type: String,
      default: ""
    },
    buttonCancelText: {
      type: String,
      default: ""
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideButtonCancel: {
      type: Boolean,
      default: false
    },
    hideButtonConfirm: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    helpBox: {
      type: Boolean,
      default: false
    },
    rbtTarget: {
      type: [String, Boolean],
      default: false
    }
  },
  setup(props, ctx) {
    const showPopup = Utils.vModel(props, ctx.emit)

    const rbtTargetTag = computed(() => {
      if (!props.rbtTarget) {
        return `popup${Utils.toPascal(props.title as string)}`
      } else {
        return `popup${Utils.toPascal(props.rbtTarget as string)}`
      }
    })

    const popupContentWidth = computed(() => {
      return { width: props.width, minWidth: props.minWidth }
    })

    const compConfirmationText = computed(() => {
      const { confirmationText } = props
      return confirmationText ? confirmationText : translate("Popup.confirm.close")
    })

    const compButtonConfirmText = computed(() => {
      const { buttonConfirmText } = props
      return buttonConfirmText ? buttonConfirmText : translate("common.confirm")
    })

    const compButtonCancelText = computed(() => {
      const { buttonCancelText } = props
      return buttonCancelText ? buttonCancelText : translate("common.cancel")
    })

    const close = async () => {
      if (props.askConfirmBeforeClose) {
        if (await raiseConfirmation({ text: compConfirmationText.value })) {
          showPopup.value = false
          ctx.emit("close")
        }
      } else {
        showPopup.value = false
        ctx.emit("close")
      }
    }

    const confirm = () => {
      ctx.emit("confirm")
    }

    return {
      close,
      rbtTargetTag,
      confirm,
      compButtonCancelText,
      compButtonConfirmText,
      popupContentWidth,
      showPopup
    }
  },
  components: {
    HelpBox
  }
})
</script>
