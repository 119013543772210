import { WorkspaceStatusEnum, WorkspaceOnlineStatusEnum, IWorkspaceAsset } from "@/types/workspace"

const { Starting, Stopping, Online } = WorkspaceOnlineStatusEnum
const AppAborting = WorkspaceOnlineStatusEnum.Aborting

export const {
  Deployed,
  DeployedWithError,
  ValidationNoAction,
  Draft,
  Aborting,
  ArchiveFailed,
  Deploying,
  DeployFailed,
  Destroying,
  DestroyFailed,
  Archiving,
  Archived,
  Restoring
} = WorkspaceStatusEnum

export const archivingStates = [Archiving, Restoring]

export const deployedStates = [Deployed, DeployedWithError, ValidationNoAction]

export const deployingStates = [Destroying, Aborting, Deploying]

export const loadingStates = [...deployingStates, ...archivingStates]

export const errorStates = [DeployFailed, DestroyFailed, ArchiveFailed]

export const appLoadingStates = [Starting, Stopping, AppAborting]

/**
 * Used to validate if workspace is on deployed state
 *
 * @param status Workspace.status as WorkspaceStatusEnum
 * @returns Boolean
 * @example
 *
 * v-if="workspaceIsDeployed(workspace.status)"
 */
export const workspaceIsDeployed = (status: WorkspaceStatusEnum) => deployedStates.includes(status)

/**
 * Used to validate if workspace is on loading state
 *
 * @param status Workspace.status as WorkspaceStatusEnum
 * @returns Boolean
 * @example
 *
 * v-if="workspaceIsLoading(workspace.status)"
 */
export const workspaceIsLoading = (status: WorkspaceStatusEnum) => loadingStates.includes(status)

/**
 * Used to validate if workspace is on failed state
 *
 * @param status Workspace.status as WorkspaceStatusEnum
 * @returns Boolean
 * @example
 *
 * v-if="workspaceFailed(workspace.status)"
 */
export const workspaceFailed = (status: WorkspaceStatusEnum) => errorStates.includes(status)

/**
 * Used to validate if app is on loading state
 *
 * @param status App.onlineStatus as WorkspaceOnlineStatusEnum
 * @returns Boolean
 * @example
 *
 * v-if="appLoading(workspace.status)"
 */
export const appLoading = (status: WorkspaceOnlineStatusEnum) => appLoadingStates.includes(status || null)

/**
 * Validates all apps from schema
 *
 * @param apps Workspace.schema.assets as IWorkspaceAsset[]
 * @returns Boolean
 * @example
 *
 * v-if="anyAppLoading(workspace.schema.assets)"
 */
export const anyAppLoading = (apps: IWorkspaceAsset[]) => apps.some(app => appLoading(app.onlineStatus))

/**
 * Checks if workspace is online
 *
 * @param apps Workspace.onlineStatus || app.onlineStatus as WorkspaceOnlineStatusEnum
 * @returns Boolean
 * @example
 *
 * isOnline(workspace.onlineStatus || app.onlineStatus)
 */
export const isOnline = (onlineStatus: WorkspaceOnlineStatusEnum) => onlineStatus === Online

export const WorkspaceStates = {
  isDeployed: workspaceIsDeployed,
  isLoading: workspaceIsLoading,
  failed: workspaceFailed,
  appLoading,
  anyAppLoading
}
