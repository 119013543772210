<template>
  <div :state="workspace.status" :online-state="workspace.onlineStatus" class="headerCloudStates">
    <div class="deployCloud">
      <img :src="spaceIconStatus" width="48px" height="48px" />
    </div>

    <div class="deployLabel">
      <span class="wrapLabel">
        <span class="deployStateLabel">{{ deployedState }}</span>
        <span v-if="isLoadingState" class="dot">.</span>
      </span>

      <span v-if="isDeployed" class="onlineApps">
        <span v-if="isOn">{{ apps.online }} / {{ apps.total }}</span>
        <span>{{ $t(`WorkspaceEdit.onlineStatus.${workspace.onlineStatus}`) }}</span>
      </span>

      <span v-if="isLoadingState" class="subStateLabel">
        <span>{{ $t(`workspaceDisplayStatus.${workspace.status}`) }}</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { workspaceStatusIcon } from "@/utils/cloud-state-icons"
import { WorkspaceStates, isOnline } from "@/config/workspace-states"
import { translate } from "@/plugins/i18n"

export default defineComponent({
  name: "HeaderCloudState",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props) {
    const deployedState = computed(() => {
      const status = props.workspace.status
      return translate(`workspaceStatus.${status}`)
    })

    const isDeployed = computed(() => {
      return WorkspaceStates.isDeployed(props.workspace.status)
    })

    const isOn = computed(() => {
      if (isDeployed.value) {
        return isOnline(props.workspace.onlineStatus)
      }
      return false
    })

    const isLoadingState = computed(() => {
      return WorkspaceStates.isLoading(props.workspace.status)
    })

    const apps = computed(() => {
      const schema = props.workspace.schema

      const appsOnline = schema.assets.filter(app => {
        return isOnline(app.onlineStatus)
      }).length

      return {
        online: appsOnline,
        total: schema.assets.length
      }
    })

    const spaceIconStatus = computed(() => {
      return workspaceStatusIcon(props.workspace, isOn.value)
    })

    return {
      apps,
      deployedState,
      isLoadingState,
      spaceIconStatus,
      isDeployed,
      isOn
    }
  }
})
</script>
