<template>
  <ul :class="`tagPillsContainer --empty-${!Boolean(items.length)}`">
    <li v-for="item in items" :key="item.name" :is-new="isNewTag(item.name)" :was-removed="wasRemoved(item.name)">
      <tooltip :text="t('newTag')" :disabled="!isNewTag(item.name) || wasRemoved(item.name)" bottom>
        <tag-menu
          @edit="edit"
          @delete="deleteTag"
          @restore="restoreTag"
          :tag="item"
          :loading="loading"
          :was-removed="wasRemoved(item.name)"
          :settings="getSettings(item.name)"
          :disabled="disabled"
        />
      </tooltip>
    </li>

    <li>
      <tag-search-field
        @create="edit"
        @edit="edit"
        :label="$t('WorkspaceEdit.createTag')"
        :items="nameOptions"
        :tags-created="items.length"
        :loading="loading"
        :forbidden-names="forbiddenNames"
        :used-tags="items"
        :disabled="disabled"
        icon="action_add_circle"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { OrganizationTag, TagOptions } from "@/types/organization"
import { SpaceTag } from "@/types/workspace"
import { createTranslationModule } from "@/plugins/i18n"
import TagMenu from "./components/_TagMenu.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import TagSearchField from "../tagSearchField/TagSearchField.vue"
import "./tagPill.scss"

export default defineComponent({
  name: "TagPills",
  props: {
    items: {
      type: Array as PropType<Array<SpaceTag>>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    valueOptions: {
      type: Array as PropType<OrganizationTag[]>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    forbiddenNames: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    nameOptions: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    tagsNotDeployed: {
      type: Array as PropType<SpaceTag[]>,
      required: true
    },
    tagsRemoved: {
      type: Array as PropType<SpaceTag[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const t = createTranslationModule("WorkspaceEdit.tags.")

    const getSettings = (tagName: string) => {
      const tag = props.valueOptions.find(_tag => _tag.name === tagName)
      return tag?.value as TagOptions
    }

    const wasRemoved = (tagName: string) => {
      return props.tagsRemoved.some(tag => tag.name === tagName)
    }

    const edit = (tag: SpaceTag) => {
      ctx.emit("edit", tag)
    }

    const deleteTag = (tagName: string) => {
      ctx.emit("delete", tagName)
    }

    const restoreTag = (tag: SpaceTag) => {
      ctx.emit("restore", tag)
    }

    const isNewTag = (tagName: string) => {
      return props.tagsNotDeployed.some(tag => tag.name === tagName)
    }

    return {
      t,
      restoreTag,
      wasRemoved,
      isNewTag,
      getSettings,
      deleteTag,
      edit
    }
  },
  components: {
    Tooltip,
    TagSearchField,
    TagMenu
  }
})
</script>
