<template>
  <div id="boxFooter">
    <div class="containControllers">
      <v-btn @click="triggerShowMoreInfo" class="moreDetailsHandler" text>
        <v-icon :size="20" left>mdi-chevron-down</v-icon>
        <span>{{ t("moreInfoLabel") }}</span>

        <v-icon
          v-if="(sslShowWarning || isSubscriptionNearExpiration) && certificateFeedbackSettings"
          :size="18"
          :color="certificateFeedbackSettings.color"
          right
        >
          {{ certificateFeedbackSettings.icon }}
        </v-icon>
      </v-btn>

      <div class="btnContainer">
        <when-deployed
          v-if="isDeployed && (checkStatus(Offline) || checkStatus(Online))"
          @set:status="status => $emit('set:status', status)"
          @open:ndi-bridge="$emit('open:ndi-bridge')"
          @open:nice-dcv="$emit('open:nice-dcv')"
          @open:both="$emit('open:both')"
          @archive="$emit('archive')"
          :loading="loading"
          :is-online="isOnline"
        />

        <has-error v-else-if="workspaceWithError" @click="$emit('deploy')" :loading="loading" />

        <pending-deploy v-else-if="pendingDeploy" @click="$emit('deploy')" :loading="loading" />

        <archived-logic
          v-else-if="containsArchiveLogic"
          @restore="$emit('restore')"
          :status="status"
          :loading="loading"
          :app-id="app.id"
        />

        <deploying-logic v-else-if="isDeploying" :status="status" :loading="loading" :app-id="app.id" />

        <online-logic
          v-else-if="isDeployed && onlineStatusChanging"
          :status="onlineStatus"
          :loading="loading"
          :app-id="app.id"
        />
      </div>
    </div>

    <more-details-container
      v-if="showMoreDetails"
      @open:ip-config="$emit('open:ip-config')"
      :app="app"
      :workspace="workspace"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from "@vue/composition-api"
import { Archived, archivingStates, appLoadingStates, WorkspaceStates } from "@/config/workspace-states"
import { useCertificate } from "../../composable/useCertificate"
import { translate } from "@/plugins/i18n"
import { useStore } from "@/composable/useStore"
import * as WarningDays from "@/config/warning-range"
import WhenDeployed from "./_WhenDeployed.vue"
import ArchivedLogic from "./_ArchivedLogic.vue"
import OnlineLogic from "./_OnlineLogic.vue"
import DeployingLogic from "./_DeployingLogic.vue"
import PendingDeploy from "./_PendingDeploy.vue"
import ProgressBar from "../ProgressBar.vue"
import HasError from "./_HasError.vue"
import MoreDetailsContainer from "./_MoreDetailsContainer.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"

import {
  CertificateInfo,
  CertificateStatus,
  IWorkspaceAsset,
  WorkspaceOnlineStatusEnum,
  WorkspaceStatusEnum,
  WorkspaceTemplate
} from "@/types/workspace"

export default defineComponent({
  name: "BoxFooter",
  props: {
    onlineStatus: {
      type: String as PropType<WorkspaceOnlineStatusEnum>,
      required: true
    },
    status: {
      type: String as PropType<WorkspaceStatusEnum>,
      required: true
    },
    isDeploying: {
      type: Boolean,
      default: false
    },
    isDeployed: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: false
    },
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    certificateInfo: {
      type: Object as PropType<CertificateInfo<CertificateStatus | null>>,
      required: false
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props) {
    const { adminModule } = useStore()
    const { sslShowWarning } = useCertificate()
    const showMoreDetails = ref(false)
    const TRANSLATION_PREFIX = "TriCaster.boxFooter."
    const t = (snippet: string) => translate(TRANSLATION_PREFIX + snippet)

    const checkStatus = (status: WorkspaceOnlineStatusEnum | WorkspaceStatusEnum) => {
      return status === props.onlineStatus || status === props.status
    }

    const triggerShowMoreInfo = () => {
      showMoreDetails.value = !showMoreDetails.value
    }

    const isSubscriptionNearExpiration = computed(() => {
      const subscription = adminModule.organizationDetail?.tokenSubscription

      if (subscription && subscription?.expirationDate) {
        return Utils.dateRange(Date.now(), subscription.expirationDate)?.days <= WarningDays.COMFORTABLE_TIME_RANGE
      }

      return false
    })

    const certificate = computed(() => {
      const { certificateInfo } = props

      if (certificateInfo && certificateInfo?.status) {
        return certificateInfo
      }

      return false
    })

    const certificateFeedbackSettings = computed(() => {
      const warningSettings = { icon: "status_warning", color: "warning" }
      const errorSettings = { icon: "status_error", color: "error" }
      const hasCertificate = Boolean(certificate.value)
      const subscriptionExpiring = isSubscriptionNearExpiration.value

      if (!hasCertificate) {
        return errorSettings
      } else if (subscriptionExpiring) {
        const subscription = adminModule.organizationDetail?.tokenSubscription
        const { days } = Utils.dateRange(Date.now(), subscription.expirationDate)
        const expired = days <= 0

        if (expired) {
          return errorSettings
        } else {
          return warningSettings
        }
      } else if (certificate.value) {
        return certificate.value.visibleState === "danger" ? errorSettings : warningSettings
      }

      return ""
    })

    const onlineStatusChanging = computed(() => {
      return appLoadingStates.includes(props.onlineStatus)
    })

    const pendingDeploy = computed(() => {
      return props.status === WorkspaceStatusEnum.Draft
    })

    const workspaceWithError = computed(() => {
      return WorkspaceStates.failed(props.status)
    })

    const containsArchiveLogic = computed(() => {
      return [...archivingStates, Archived].includes(props.status)
    })

    const isOnline = computed(() => {
      return checkStatus(WorkspaceOnlineStatusEnum.Online)
    })

    return {
      ...WorkspaceOnlineStatusEnum,
      sslShowWarning,
      triggerShowMoreInfo,
      isSubscriptionNearExpiration,
      t,
      checkStatus,
      certificateFeedbackSettings,
      showMoreDetails,
      containsArchiveLogic,
      workspaceWithError,
      pendingDeploy,
      isOnline,
      onlineStatusChanging
    }
  },
  components: {
    Tooltip,
    MoreDetailsContainer,
    OnlineLogic,
    PendingDeploy,
    ProgressBar,
    HasError,
    DeployingLogic,
    ArchivedLogic,
    WhenDeployed
  }
})
</script>
