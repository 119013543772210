<template>
  <v-tooltip
    :top="hasDirection ? false : true"
    :right="right"
    :bottom="bottom"
    :left="left"
    :disabled="disabled"
    :color="color"
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot />
      </span>
    </template>

    <span>
      <slot name="text">
        {{ text }}
      </slot>
    </span>
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { TranslateResult } from "vue-i18n"

export default defineComponent({
  name: "Tooltip",
  props: {
    text: {
      type: String as PropType<TranslateResult | string>,
      required: true
    },
    color: {
      type: String,
      default: "black",
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    top: {
      type: Boolean,
      default: true,
      required: false
    },
    right: {
      type: Boolean,
      required: false
    },
    bottom: {
      type: Boolean,
      required: false
    },
    left: {
      type: Boolean,
      required: false
    },
    maxWidth: {
      type: Number,
      default: 500,
      required: false
    }
  },
  setup(props) {
    return {
      hasDirection: computed(() => {
        const { right, bottom, left } = props

        return right || bottom || left
      })
    }
  }
})
</script>
