<template>
  <auto-shutdown v-model="showPopup" :disabled="disabled" :workspace="workspace" :loading="loading" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import Utils from "@/utils/utils"
import AutoShutdown from "@/views/appLauncher/components/autoShutdownSettings"

export default defineComponent({
  name: "AutoShutdownPopup",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const showPopup = Utils.vModel(props, ctx.emit)

    return {
      showPopup
    }
  },
  components: {
    AutoShutdown
  }
})
</script>
