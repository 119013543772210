import { useStore } from "@/composable/useStore"
import { computed } from "@vue/composition-api"
import Utils from "@/utils/utils"

export const useSchedule = () => {
  const { spaceModule } = useStore()

  const schedule = computed(() => {
    if (scheduleEnabled.value) {
      const startTime = spaceModule.scheduledOnOff.autoStartupAt
      const endTime = spaceModule.scheduledOnOff.autoShutdownAt

      return {
        start: startTime ? Utils.dateFormatWithTime(startTime) : null,
        end: endTime ? Utils.dateFormatWithTime(endTime) : null
      }
    }

    return false
  })

  const scheduleClasses = computed(() => {
    const classes = ["spanContainer"]

    if (schedule.value) {
      const start = spaceModule.scheduledOnOff.autoStartupAt
      const end = spaceModule.scheduledOnOff.autoShutdownAt

      if (start && end) {
        const startTime = new Date(start).getTime()
        const endTime = new Date(end).getTime()

        if (endTime < startTime) {
          classes.push("reverseRow")
        }
      }
    }

    return classes.join(" ")
  })

  const scheduleEnabled = computed(() => {
    const scheduleEnabled = spaceModule.scheduledOnOff.scheduledOnOffEnabled

    if (scheduleEnabled) {
      const startTime = spaceModule.scheduledOnOff.autoStartupAt
      const endTime = spaceModule.scheduledOnOff.autoShutdownAt

      return startTime || endTime
    }

    return false
  })

  return {
    scheduleEnabled,
    schedule,
    scheduleClasses
  }
}
