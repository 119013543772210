<template>
  <div class="certificateInfo" :status="status">
    <v-icon :class="isValidatingCertificate ? 'spinning' : ''" :color="status">
      {{ icon }}
    </v-icon>

    <span v-if="daysRemaining > 0">
      {{ $tc("Admin.organizations.tableCell.closeToExpiration", daysRemaining, { daysRemaining }) }}
    </span>

    <span v-else-if="isValidatingCertificate"> {{ t("validating") }} </span>

    <span v-else-if="certificateHasFailed"> {{ t("failed") }} </span>

    <span v-else> {{ t("expired") }} </span>
  </div>
</template>

<script lang="ts">
import { createTranslationModule } from "@/plugins/i18n"
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { CertificateInfo, CertificateVisibleState } from "@/types/workspace"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "SSLCertificate",
  props: {
    certificate: {
      type: Object as PropType<CertificateInfo>,
      required: true
    }
  },
  setup(props) {
    const t = createTranslationModule("Admin.organizations.tableCell")

    const daysRemaining = computed(() => {
      if (props.certificate.expiresAt) {
        return Utils.dateRange(Date.now(), props.certificate.expiresAt).days
      }
      return 0
    })

    const status = computed(() => {
      const { visibleState, status } = props.certificate
      const { notVisible, danger } = CertificateVisibleState

      if (visibleState === notVisible) {
        return status !== "failed" ? "success" : "error"
      } else if (visibleState === danger) {
        return "error"
      } else {
        return visibleState
      }
    })

    const certificateHasFailed = computed(() => {
      return props.certificate.status === "failed"
    })

    const isValidatingCertificate = computed(() => {
      return props.certificate.status === "validating"
    })

    const icon = computed(() => {
      const { visibleState } = props.certificate
      const { notVisible, warning, danger } = CertificateVisibleState

      if (certificateHasFailed.value) {
        return "mdi-alert-circle"
      } else if (isValidatingCertificate.value) {
        return "mdi-loading"
      }

      switch (visibleState) {
        case warning:
          return "mdi-alert"
        case danger:
          return "mdi-close"
        case notVisible:
          return "mdi-check"
      }
    })

    return {
      t,
      icon,
      isValidatingCertificate,
      certificateHasFailed,
      daysRemaining,
      status
    }
  }
})
</script>
