<template>
  <div id="cloudConfig" class="onboardingItem stepTwo">
    <div class="contentTitle">
      <label>
        <span class="field-label" />
      </label>

      <div class="stepController">
        <v-btn @click="backStep" :disabled="loader.on" large>
          {{ $t("Onboarding.buttons.back") }}
        </v-btn>

        <v-btn @click="nextStep" :loading="loader.on" large color="primary">
          <span v-if="isCustomerHosted">{{ $t("Onboarding.buttons.next") }}</span>
          <span v-else>{{ $t("Onboarding.buttons.finish") }}</span>
        </v-btn>
      </div>
    </div>

    <div class="cloudConfigContainer spaced">
      <div class="awsStepsContainer">
        <create-role-step-new-ui @copy:snippet-json-permission="copyJsonPermission" />
      </div>

      <div class="awsForm">
        <v-form ref="Form">
          <viz-now-form v-model="arnConfig" />
        </v-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, inject, ref } from "@vue/composition-api"
import { LicenseModule } from "@/store/license-server"
import { UUID } from "../../lib/config"
import { VForm } from "@/types/core"
import { extractAccountId } from "../../lib/arn-validation"
import { copyToClipboard } from "@/utils/ui"
import VizNowForm from "./VizNowForm.vue"
import Utils from "@/utils/utils"
import Loader from "@/utils/loader"
import Snippets from "../../lib/copy-snippet"
import CreateRoleStepNewUi from "./CreateRoleStepNewUI.vue"

export default defineComponent({
  name: "VizNowRoot",
  setup(_, ctx) {
    const Form = ref<null | VForm>(null)
    const uuid = UUID
    const licenseModule = LicenseModule()
    const loader = Loader()
    const isCustomerHosted = inject<boolean>("isCustomerHosted")

    const arnConfig = reactive({
      arnCode: "",
      externalId: uuid
    })

    const backStep = () => {
      ctx.emit("back")
    }

    const copyJsonPermission = () => {
      copyToClipboard(Snippets.vizNowTabInlinePolicy)
    }

    const nextStep = async () => {
      const form = Utils.isType<VForm>(Form.value, "validate")

      if (form && form.validate()) {
        loader.run(async () => {
          licenseModule.licenseServer.vizrtAwsRoleArn = arnConfig.arnCode
          licenseModule.licenseServer.vizrtAwsRoleExternalId = arnConfig.externalId
          licenseModule.licenseServer.awsAccountId = extractAccountId(arnConfig.arnCode)

          await registerRegions()
          await registerLicenseSettings()

          ctx.emit("next")
        })
      }
    }

    const registerRegions = async () => {
      await licenseModule.setCloudZonesToOrganization()
    }

    const registerLicenseSettings = async () => {
      await licenseModule.setLicenseProviderSettings()
    }

    return {
      loader,
      Form,
      arnConfig,
      uuid,
      isCustomerHosted,
      copyJsonPermission,
      nextStep,
      backStep
    }
  },
  components: {
    CreateRoleStepNewUi,
    VizNowForm
  }
})
</script>
