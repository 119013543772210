import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":_vm.hideTableFooter},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"rbt-target":("goTo" + (_vm.toPascal(item.name)))},on:{"click":function($event){return _vm.openOrganization(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.creationDate))+" ")]}},{key:"item.workspaces",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"workspacesDeployed",attrs:{"is-empty":_vm.deployedCount(item) === 0,"rbt-target":("spaceCountGoTo" + (_vm.toPascal(item.name)))},on:{"click":function($event){return _vm.openWorkspacesOfOrganization(item.id)}}},[_vm._v(" "+_vm._s(_vm.deployedMessage(_vm.deployedCount(item)))+" ")])]}},{key:"item.hasLicense",fn:function(ref){
var item = ref.item;
return [(_vm.isCustomerHosted(item))?_c(VIcon,{attrs:{"color":_vm.hasLicenseProp(item).color}},[_vm._v(" "+_vm._s(_vm.hasLicenseProp(item).icon)+" ")]):_c('span',[_vm._v("N/A")])]}},{key:"item.isReady",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"color":_vm.organizationIsReady(item).color}},[_vm._v(" "+_vm._s(_vm.organizationIsReady(item).icon)+" ")])]}},{key:"item.organizationType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"organizationType"},[_c('span',[_vm._v(" "+_vm._s(_vm.organizationTypeLabel(item).text)+" ")]),(item.organizationType === _vm.SaaS)?_c('span',{staticClass:"tokens",attrs:{"in-debt":_vm.orgInDebt(item)}},[_vm._v(" ("+_vm._s(_vm.organizationTypeLabel(item).tokens)+" "+_vm._s(_vm.$t("common.tokens"))+" ) ")]):_vm._e()])]}},{key:"item.certificateInfo",fn:function(ref){
var item = ref.item;
return [(item.certificateInfo)?_c('ssl-certificate-cell',{attrs:{"certificate":item.certificateInfo}}):_c('span',{staticClass:"noCertificate"},[_vm._v(_vm._s(_vm.t("noCertificate")))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"rbt-target":("btnOpenMenu" + (_vm.toPascal(item.name))),"disabled":_vm.loading,"color":"primary","icon":"","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("navigate_more_options_vertical")])],1)]}}],null,true)},[_c('div',{staticClass:"listContainer"},[(_vm.hasGodLevelPermissions)?_c('div',{staticClass:"listItem organization",attrs:{"rbt-target":("edit" + (_vm.toPascal(item.name)))},on:{"click":function($event){return _vm.$emit('edit', item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_edit")]),_c('span',[_vm._v(_vm._s(_vm.$t("Admin.organizations.table.menu.edit")))])],1):_c('div',{staticClass:"listItem organization",attrs:{"rbt-target":("view" + (_vm.toPascal(item.name)))},on:{"click":function($event){return _vm.$emit('edit', item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-eye")]),_c('span',[_vm._v(_vm._s(_vm.$t("Admin.organizations.table.menu.view")))])],1),(_vm.hasGodLevelPermissions)?_c('div',{staticClass:"listItem organization",attrs:{"rbt-target":("delete" + (_vm.toPascal(item.name)))},on:{"click":function($event){return _vm.confirmRemoveOrganization(item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_trash")]),_vm._v(" "+_vm._s(_vm.$t("Admin.organizations.table.menu.delete"))+" ")],1):_vm._e(),_c('div',{staticClass:"listItem organization",attrs:{"rbt-target":("copyId" + (_vm.toPascal(item.name)))},on:{"click":function($event){return _vm.copy(item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_copy")]),_vm._v(" "+_vm._s(_vm.$t("Admin.organizations.table.menu.copyId"))+" ")],1)])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }