<template>
  <div class="deployed">
    <v-switch v-model="serverOnline" @change="changeServerStatus" :label="serverStatus" hide-details />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "@vue/composition-api"

export default defineComponent({
  name: "DeployedStage",
  setup(_, ctx) {
    const serverOnline = ref(true)

    const serverStatus = computed(() => {
      return serverOnline.value ? "ONLINE" : "OFFLINE"
    })

    const changeServerStatus = () => {
      ctx.emit("update:server", serverStatus.value)
    }

    return {
      serverOnline,
      serverStatus,
      changeServerStatus
    }
  }
})
</script>
