import Utils from "@/utils/utils"
import { Director, InputType, InputItem } from "./type"

const DEV_ENV = process.env.NODE_ENV === "development"

export const isDetailedDirector = (structure: Director | unknown, throwError = DEV_ENV) => {
  if (_assert(_validateList(structure), "Director structure must be populated array", throwError)) {
    const detailedStructure = structure as InputItem[][]
    const acceptedTypeCollection = Object.values(InputType)

    const isDetailedDirector = _assert(
      detailedStructure.every(row => {
        return row.every(detailedItem => {
          const isNormalInput = Utils.isType<InputItem>(detailedItem, "value")
          const isIterableGroup = Utils.isType<InputItem>(detailedItem, "children")
          const acceptableType = Boolean(isNormalInput || isIterableGroup)

          return acceptedTypeCollection.includes(detailedItem.type) && acceptableType
        })
      }),
      "Structure does not match detailed director",
      throwError
    )

    if (isDetailedDirector) {
      return structure as Director as InputItem[][]
    }
  }

  return false
}

const _assert = (condition: boolean, message: string, throwError = DEV_ENV) => {
  const errorHeader = `FormFactory error: `

  if (!condition && throwError) {
    throw Error(errorHeader + message)
  }

  return condition
}

const _validateList = (structure: Director | unknown) => {
  if (Array.isArray(structure) && structure.length > 0) {
    return structure.every(item => Array.isArray(item) && item.length > 0)
  }

  return false
}
