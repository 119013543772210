<template>
  <v-data-table :headers="headers" :items="users" class="userTable">
    <template v-slot:[`item.firstName`]="{ item }">
      <tooltip :text="disabledIcon(item).text">
        <v-icon :color="disabledIcon(item).color" rbt-target="userActiveState" left>
          {{ disabledIcon(item).icon }}
        </v-icon>
      </tooltip>

      <span>{{ item.firstName }} {{ item.lastName }}</span>
      <small v-if="item.id === myId" class="highlighter"> (This is you)</small>
    </template>

    <template v-slot:[`item.role`]="{ item }">
      <span>
        {{ $t(`OrganizationUsers.roles.${item.role}`) }}
      </span>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :disabled="userHasMorePermissionThenI(item.role)"
            rbt-target="moreOptionsBtn"
            icon
          >
            <v-icon>navigate_more_options_vertical</v-icon>
          </v-btn>
        </template>

        <div class="listMenu">
          <div @click="$emit('edit:user', item)" class="listRow" rbt-target="editUserBtn">
            <v-icon left>action_edit</v-icon>
            <span>{{ $t("OrganizationUsers.table.menu.edit") }}</span>
          </div>

          <div v-if="item.disabled" @click="changeUserActiveState(item)" class="listRow" rbt-target="activateUser">
            <v-icon left>status_ok_circle</v-icon>
            <span>{{ $t("OrganizationUsers.table.menu.activateUser") }}</span>
          </div>

          <div v-else @click="changeUserActiveState(item)" class="listRow" rbt-target="disableUser">
            <v-icon left>status_no</v-icon>
            <span>{{ $t("OrganizationUsers.table.menu.disableUser") }}</span>
          </div>

          <div @click="confirmRemoveUser(item)" class="listRow" rbt-target="removeUser">
            <v-icon left>action_trash</v-icon>
            <span>{{ $t("OrganizationUsers.table.menu.remove") }}</span>
          </div>
        </div>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import { IUser, UserRoleEnum } from "@/types/user"
import { translate } from "@/plugins/i18n"
import { raiseConfirmation } from "@/utils/event-bus"
import { getRoleImportance } from "@/utils/role-importance"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Headers from "../lib/table-header"

export default defineComponent({
  name: "UserTable",
  props: {
    users: {
      type: Array as PropType<IUser[]>,
      required: true
    }
  },
  setup(_, ctx) {
    const headers = computed(Headers)
    const usersModule = UsersModule()

    const myId = computed(() => {
      return usersModule.selfDetail.id
    })

    const userHasMorePermissionThenI = (role: UserRoleEnum) => {
      const myRole = usersModule.selfDetail.role
      const userBeingChecked = role

      if (usersModule.hasGodPermissions) {
        return false
      } else if (usersModule.hasSupportPermissions) {
        return getRoleImportance(myRole) <= getRoleImportance(userBeingChecked)
      } else {
        return getRoleImportance(myRole) < getRoleImportance(userBeingChecked)
      }
    }

    const confirmRemoveUser = async (user: IUser) => {
      const userFullName = `${user.firstName} ${user.lastName}`
      const confirmText = "OrganizationUsers.confirm.removeUser"

      if (await raiseConfirmation({ text: translate(confirmText, { user: userFullName }) })) {
        ctx.emit("remove:user", user)
      }
    }

    const changeUserActiveState = (user: IUser) => {
      ctx.emit("edit:user-state", user)
    }

    const disabledIcon = (user: IUser) => {
      const successIcon = "status_ok_circle"
      const badIcon = "status_no"
      const disabledSnippet = translate("OrganizationUsers.tooltip.disabled")
      const activeSnippet = translate("OrganizationUsers.tooltip.active")

      return {
        icon: user.disabled ? badIcon : successIcon,
        color: user.disabled ? "error" : "success",
        text: user.disabled ? disabledSnippet : activeSnippet
      }
    }

    return {
      myId,
      userHasMorePermissionThenI,
      changeUserActiveState,
      confirmRemoveUser,
      disabledIcon,
      headers
    }
  },
  components: {
    Tooltip
  }
})
</script>
