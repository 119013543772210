<template>
  <div class="htmlViewer" rbt-target="htmlViewer" :overflow-auto="overflowAuto">
    <h1 v-if="title">{{ title }}</h1>

    <div v-html="content" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import "./scss/_htmlEditor.scss"

export default defineComponent({
  name: "HtmlViewer",
  props: {
    content: {
      type: String,
      default: ""
    },
    title: {
      type: [String, Boolean],
      default: false
    },
    overflowAuto: {
      type: Boolean,
      default: false
    }
  }
})
</script>
