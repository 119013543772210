export const isOutdated = (version1: string, version2: string) => {
  const v1 = version1.split(".").map(Number)
  const v2 = version2.split(".").map(Number)

  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    const _v1 = v1[i] || 0
    const _v2 = v2[i] || 0

    if (_v1 > _v2) {
      return true
    } else if (_v2 > _v1) {
      return false
    }
  }

  return false
}
