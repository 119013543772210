<template>
  <v-checkbox
    v-model="markupValue"
    @change="feedbackSolve"
    :label="$t('Admin.feedback.resolved')"
    :disabled="loading"
    color="primary"
    class="my-0 py-0"
    hide-details
  />
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "TransitionCheckbox",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const checked = Utils.vModel(props, ctx.emit)
    const markupValue = ref(checked.value)

    const feedbackSolve = (isResolved: boolean) => {
      ctx.emit("pre:resolve", isResolved)

      setTimeout(() => {
        if (markupValue.value === isResolved) {
          checked.value = isResolved
          ctx.emit("resolve", isResolved)
        }
      }, 350)
    }

    return {
      feedbackSolve,
      markupValue,
      checked
    }
  }
})
</script>
