<template>
  <div id="adminConfig">
    <admin-tabs v-if="canSeePageContent" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api"
import { UsersModule } from "@/store"
import AdminTabs from "./components/AdminTabs.vue"
import "./scss/_adminConfig.scss"

export default defineComponent({
  name: "AdminConfigRoot",
  setup() {
    const usersModule = UsersModule()

    const canSeePageContent = computed(() => {
      return usersModule.hasSupportPermissions
    })

    return {
      canSeePageContent
    }
  },
  components: {
    AdminTabs
  }
})
</script>
