/**
 * @license - https://freesound.org/people/Breviceps/sounds/447910/
 */

import Pong from "./asset/pong.wav"

export const playSound = {
  pong() {
    try {
      const audio = new Audio(Pong)
      audio.volume = 0.5
      audio.play()
    } catch {
      return
    }
  }
}
