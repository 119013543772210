import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":_vm.hideTableFooter},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","rbt-target":_vm.toRobotTag(("buttonMenu" + (item.name))),"icon":"","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("navigate_more_options_vertical")])],1)]}}],null,true)},[_c('div',{staticClass:"listContainer"},[_c('div',{staticClass:"listItem organization",attrs:{"rbt-target":_vm.toRobotTag(("menuItemEdit" + (item.name)))},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_edit")]),_vm._v(" "+_vm._s(_vm.$t("Admin.deployPackage.table.menu.edit"))+" ")],1),_c('div',{staticClass:"listItem organization",attrs:{"rbt-target":_vm.toRobotTag(("menuItemDelete" + (item.name)))},on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_trash")]),_vm._v(" "+_vm._s(_vm.$t("Admin.deployPackage.table.menu.delete"))+" ")],1),_c('div',{staticClass:"listItem organization",attrs:{"rbt-target":_vm.toRobotTag(("menuItemCopyId" + (item.name)))},on:{"click":function($event){return _vm.copy(item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_copy")]),_vm._v(" "+_vm._s(_vm.$t("Admin.deployPackage.table.menu.copyId"))+" ")],1)])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }