<template>
  <div id="presentationRoot">
    <v-progress-linear v-if="loader.on" color="primary" indeterminate />

    <div v-if="loader.on" class="backgroundWrapper">
      <div class="wrapLoadingContent">
        <div class="loadingLabel">
          <span>{{ $t("WorkspaceEdit.label.appLoading") }}</span>
          <span class="dot">.</span>
        </div>
      </div>
    </div>

    <large-presentation
      v-if="app && !errorDetail.hasError"
      @set:status="setStatus"
      @archive="archiveWorkspace"
      @restore="restoreWorkspace"
      @deploy="deployWorkspace"
      @open:nice-dcv="downloadDcv"
      @open:ndi-bridge="downloadNowFile"
      @open:both="downloadDcvThenNowFile"
      @open:ip-config="showIpConfig = true"
      @open:auto-shutdown="showAutoShutdownSettings = true"
      @cancel:shutdown="cancelShutdown"
      @postpone:shutdown="postponeAutoShutdown"
      :workspace="workspace"
      :app="app"
      :loading="appLoader.on"
      :is-archiving="isArchiving"
    />

    <error-report
      v-else-if="errorDetail.hasError"
      @destroy:space="destroySpace"
      :loading="appLoader.on"
      :message="errorDetail.msg"
    />

    <warning-shutdown-popup v-model="showWarningShutdownPopup" @shutdown="shutDown" />

    <ip-config-popup v-if="showIpConfig" v-model="showIpConfig" />

    <auto-shutdown-settings
      v-if="showAutoShutdownSettings"
      v-model="showAutoShutdownSettings"
      :workspace="workspace"
      :loading="appLoader.on"
      :disabled="appIsOn || appIsLoading"
    />
  </div>
</template>

<script lang="ts">
import { IWorkspaceAsset, WorkspaceOnlineStatusEnum, WorkspaceStatusEnum } from "@/types/workspace"
import { OrganizationModule, AdminModule, UsersModule, WorkspaceModule } from "@/store"
import { WorkspaceStates } from "@/config/workspace-states"
import { AppMode } from "./lib/types"
import { copyToClipboard } from "@/utils/ui"
import { raiseError, raiseSuccess } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { RegisteredIp, RequestErrorResponse } from "@/types/core"
import { clearAllTimers } from "./lib/estimated-time"
import { waitComplete } from "@/utils/wait-complete"
import { addChat, removeChat, updateChatMetadata } from "@/utils/insert-chat"
import { notifyStatusChange, notifyCustomMessage, registerWebviewEvent } from "./lib/webview-communication"
import Commands from "@/config/command-keys"
import Timer from "@/config/app-loop-timers"
import LargePresentation from "./LargePresentation.vue"
import WarningShutdownPopup from "./components/_WarningShutdownPopup.vue"
import IpConfigPopup from "./components/_IpConfigPopup.vue"
import Loader from "@/utils/loader"
import Router, { navigate } from "@/router"
import ErrorReport from "./components/errorReport"
import AutoShutdownSettings from "./components/autoShutdownSettings"
import "./scss/_appLauncher.scss"

import {
  defineComponent,
  ref,
  reactive,
  computed,
  onBeforeUnmount,
  onBeforeUpdate,
  onMounted
} from "@vue/composition-api"

const { Starting, Stopping, Online } = WorkspaceOnlineStatusEnum

export default defineComponent({
  name: "PresentationRoot",
  setup() {
    const workspaceModule = WorkspaceModule()
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const organizationModule = OrganizationModule()
    const loader = Loader({ alertOnError: false, throwOnError: true })
    const appLoader = Loader()
    const ownIp = ref("")
    const showWarningShutdownPopup = ref(false)
    const showIpConfig = ref(false)
    const showAutoShutdownSettings = ref(false)
    const errorDetail = reactive({ hasError: false, msg: "" })
    const _isMounted = ref(true)
    const _lastKnownSpaceStatus = ref("")
    const _lastKnownSpaceOnlineStatus = ref("")

    const _organizationId = computed(() => {
      return workspace.value.organizationId
    })

    const hasEnoughTokens = computed(() => {
      const tokenCount = organizationModule.walletDetail.amount ?? 0
      return tokenCount > 0
    })

    const workspace = computed(() => {
      return workspaceModule.watching
    })

    const app = computed(() => {
      const appIdParam = Router.currentRoute.params.appId
      return workspace.value.schema?.assets.find(_app => _app.id === appIdParam) as IWorkspaceAsset
    })

    const isIpAllowed = computed(() => {
      if (workspace.value && workspace.value?.id) {
        const authorizedIps = workspace.value.authorizedIps.map(ipItem => ipItem.ip)

        return authorizedIps.includes(adminModule.ownIp)
      }

      return false
    })

    const workspaceId = computed(() => {
      return Router.currentRoute.params.workspaceId
    })

    const isArchiving = computed(() => {
      const { Archiving, Restoring } = WorkspaceStatusEnum
      const _archivingStatus = [Archiving, Restoring]

      return _archivingStatus.includes(workspace.value?.status)
    })

    const appIsOn = computed(() => {
      return app.value?.onlineStatus === Online
    })

    const appIsLoading = computed(() => {
      const appLoading = WorkspaceStates.appLoading(app.value.onlineStatus)
      const spaceLoading = WorkspaceStates.isLoading(workspace.value.status)

      return appLoading || spaceLoading
    })

    const _isComponentMounted = () => {
      return _isMounted.value
    }

    const _reinforceAppExistence = () => {
      const apps = workspace.value.schema?.assets

      if (apps) {
        const noExistentApp = apps.length === 0

        if (noExistentApp) {
          navigate({ name: "AppLauncherPicker" })
        } else if (!noExistentApp && !app.value) {
          const lastAddedApp = apps.slice(-1)[0]
          navigate({ name: "AppLauncher", params: { appId: lastAddedApp.id } })
        }
      }
    }

    const _getOwnIp = async () => {
      ownIp.value = await adminModule.getOwnIp()
    }

    const _validateHasOwnIp = async () => {
      const allowedIps = workspace.value.authorizedIps
      const isOwnIpAllowed = allowedIps.some(user => user.ip === ownIp.value)

      if (!isOwnIpAllowed) {
        await addIp({ ip: ownIp.value, description: usersModule.selfDetail.email })
      }
    }

    const _getDomain = async () => {
      if (WorkspaceStates.isDeployed(workspace.value.status)) {
        await adminModule.getWorkspaceAppInstanceLink(workspace.value.id, app.value.id)
      }
    }

    const _getConnectionUrlThenCopyPassword = async () => {
      const appId = app.value.id
      const { connectionUrl, password } = await adminModule.getWorkspaceAppInstanceLink(workspace.value.id, appId)

      if (await copyToClipboard(password)) {
        raiseSuccess({ text: translate("WorkspaceEdit.alerts.feedbacks.passwordCopied") })
      }

      return connectionUrl
    }

    const _allowIPThenRedirectUser = () => {
      appLoader.run(async () => {
        await _validateHasOwnIp()
        const connectionUrl = await _getConnectionUrlThenCopyPassword()

        setTimeout(() => {
          window.location.href = connectionUrl
        }, 2000)
      })
    }

    const _recursiveUpdateWallet = () => {
      setTimeout(async () => {
        await organizationModule.getWallet(_organizationId.value)

        if (_isMounted.value) {
          _recursiveUpdateWallet()
        }
      }, Timer.appLauncherWallet)
    }

    const _recursiveUpdateWorkspace = () => {
      setTimeout(async () => {
        await workspaceModule.getWorkspace(workspaceId.value)
        _detectSpaceChange()

        notifyCustomMessage({
          variant: "autoshutdown",
          message: {
            autoShutdownInformation: workspace.value.autoShutdownDefinition
          }
        })

        if (_isMounted.value) {
          _recursiveUpdateWorkspace()
        }
      }, Timer.appLauncher)
    }

    const _sendNdiBridgeInformationToAppLauncher = async (spaceId: string, appId: string) => {
      const ndiConfiguration = await workspaceModule.getNdiBridgeSettings(spaceId, appId)

      notifyCustomMessage({
        variant: "ndibridge",
        message: { action: "open" },
        data: ndiConfiguration
      })
    }

    const _downloadNowFile = async () => {
      await appLoader.run(async () => {
        const workspaceName = workspace.value.title
        const appId = app.value.id
        const spaceId = workspace.value.id
        const appName = workspace.value.schemaDeployed?.assets.find(app => app.id === appId)?.description

        if (appName) {
          const fileName = `${workspaceName} - ${appName}`
          await adminModule.downloadDotNowFile(workspace.value.id, fileName, appId)
          await _sendNdiBridgeInformationToAppLauncher(spaceId, appId)
        } else {
          raiseError({ text: translate("WorkspaceEdit.alert.invalidApp") })
        }
      })
    }

    const _runQueryCommands = async () => {
      const { redirect_dcv, download_dcv, download_now } = Router.currentRoute.query
      const isOnlineStatusChanging = [Stopping, Starting, null].includes(app.value?.onlineStatus)
      const _parse = (snippet: unknown) => JSON.parse(snippet as string)

      if (!isOnlineStatusChanging && appIsOn.value) {
        if (redirect_dcv && _parse(redirect_dcv)) {
          _allowIPThenRedirectUser()
        }
        if (download_now && _parse(download_now)) {
          _downloadNowFile()
        }
        if (download_dcv && _parse(download_dcv)) {
          await _allowIPThenDownloadDCV()
        }
      }
    }

    const _checkLoadingState = async () => {
      await waitComplete(
        async () => {
          await workspaceModule.getWorkspace(workspaceId.value)
          const isDeploying = WorkspaceStates.isLoading(workspace.value.status)
          const appsLoading = WorkspaceStates.anyAppLoading(workspace.value.schema.assets)
          const releaseFlag = !isArchiving.value && !isDeploying && !appsLoading
          _detectSpaceChange()

          if (releaseFlag) {
            clearAllTimers()
            await _runQueryCommands()
          }

          return releaseFlag
        },
        { isAlive: _isComponentMounted, interval: 2000 }
      )
    }

    const _appCallShortcuts = async () => {
      const { mode } = Router.currentRoute.query
      const isOnlineStatusChanging = WorkspaceStates.anyAppLoading(workspace.value.schema.assets)
      const appMode = mode as AppMode
      const setter = mode === AppMode.open ? Starting : Stopping

      if (!isOnlineStatusChanging) {
        if (appMode) {
          const appIsOn = app.value?.onlineStatus === Online

          if (appIsOn && setter === Stopping) {
            await setStatus(setter)
          } else if (!appIsOn && setter === Starting && hasEnoughTokens.value) {
            await setStatus(setter)
          }
        }

        await _runQueryCommands()
      } else {
        _checkLoadingState()
      }
    }

    const _updateLastKnownStatus = () => {
      const { onlineStatus, status } = workspace.value
      _lastKnownSpaceOnlineStatus.value = onlineStatus
      _lastKnownSpaceStatus.value = status
    }

    const _detectSpaceChange = () => {
      const { onlineStatus, status } = workspace.value
      const differentOnlineStatus = onlineStatus !== _lastKnownSpaceOnlineStatus.value
      const differentStatus = status !== _lastKnownSpaceStatus.value

      if (differentOnlineStatus || differentStatus) {
        _updateLastKnownStatus()
        notifyStatusChange(workspace.value)
      }
    }

    const addIp = async (ipProp: RegisteredIp) => {
      appLoader.run(async () => {
        if (!isIpAllowed.value) {
          await workspaceModule.authorizeIP(ipProp, workspace.value.id)
        }
      })
    }

    const _allowIPThenDownloadDCV = async () => {
      await _validateHasOwnIp()
      await adminModule.downloadWorkspaceShortcut(workspaceId.value, app.value?.id ?? "")
    }

    const _declareError = (error: unknown) => {
      if (typeof error === "object") {
        const err = error as typeof Object

        if ("details" in err) {
          const requestError = error as RequestErrorResponse

          errorDetail.hasError = true
          errorDetail.msg = requestError?.details || requestError?.message
        }
      }
    }

    const _updateChatInfo = () => {
      const id = workspaceId.value
      const fullName = `${usersModule.selfDetail.firstName} ${usersModule.selfDetail.lastName}`
      const email = usersModule.selfDetail.email
      const orgId = usersModule.selfDetail.organizationId

      updateChatMetadata({ workspaceId: id, fullName, email, organizationId: orgId })
    }

    const _setWebviewListeners = () => {
      registerWebviewEvent(Commands.APP_LAUNCHER_STOP_APP, () => setStatus(WorkspaceOnlineStatusEnum.Stopping))
      registerWebviewEvent(Commands.APP_LAUNCHER_START_APP, () => setStatus(WorkspaceOnlineStatusEnum.Starting))
      registerWebviewEvent(Commands.APP_LAUNCHER_AUTO_SHUTDOWN_POSTPONE, () => postponeAutoShutdown())
      registerWebviewEvent(Commands.APP_LAUNCHER_AUTO_SHUTDOWN_CANCEL, () => cancelShutdown())
    }

    const destroySpace = () => {
      appLoader.run(async () => {
        await workspaceModule.destroy(workspaceId.value)
        Router.go(0)
      })
    }

    const shutDown = () => {
      appLoader.run(async () => {
        const { Stopping } = WorkspaceOnlineStatusEnum

        await workspaceModule.setAppOnlineStatus(workspace.value.id, Stopping, app.value.id)
        clearAllTimers()
        _checkLoadingState()
      })
    }

    const setStatus = async (status: WorkspaceOnlineStatusEnum) => {
      const { Stopping } = WorkspaceOnlineStatusEnum

      if (status === Stopping) {
        showWarningShutdownPopup.value = true
      } else {
        await appLoader.run(async () => {
          await workspaceModule.setAppOnlineStatus(workspace.value.id, status, app.value.id)
          clearAllTimers()
          _checkLoadingState()
        })
      }
    }

    const archiveWorkspace = async () => {
      await appLoader.run(async () => {
        await workspaceModule.archive(workspace.value.id)
        clearAllTimers()
        await _checkLoadingState()
      })
    }

    const restoreWorkspace = async () => {
      await appLoader.run(async () => {
        await workspaceModule.restore(workspace.value.id)
        clearAllTimers()
        await _checkLoadingState()
      })
    }

    const deployWorkspace = async () => {
      await appLoader.run(async () => {
        const shouldRenewCertificate = false
        await workspaceModule.deploy(workspace.value.id, shouldRenewCertificate)
        clearAllTimers()
        await _checkLoadingState()
      })
    }

    const downloadDcv = async () => {
      await appLoader.run(async () => {
        await _allowIPThenDownloadDCV()
      })
    }

    const downloadNowFile = async () => {
      await appLoader.run(async () => {
        await _downloadNowFile()
      })
    }

    const downloadDcvThenNowFile = async () => {
      await downloadDcv()
      await downloadNowFile()
    }

    const cancelShutdown = async () => {
      await appLoader.run(async () => {
        await workspaceModule.cancelAutoShutdown(workspace.value.id)
        await workspaceModule.getWorkspace(workspaceId.value)

        notifyCustomMessage({
          variant: "autoshutdown-cancelled",
          message: {
            autoShutdownInformation: workspace.value.autoShutdownDefinition
          }
        })
      })
    }

    const postponeAutoShutdown = async () => {
      await appLoader.run(async () => {
        await workspaceModule.postponeAutoShutdown(workspace.value.id)
        await workspaceModule.getWorkspace(workspaceId.value)

        notifyCustomMessage({
          variant: "autoshutdown",
          message: {
            autoShutdownInformation: workspace.value.autoShutdownDefinition
          }
        })
      })
    }

    loader.run(async () => {
      try {
        await Promise.all([
          workspaceModule.getWorkspace(workspaceId.value),
          workspaceModule.getRegions(),
          workspaceModule.getAutoShutdownSettings(workspaceId.value),
          _getOwnIp(),
          _getDomain()
        ])

        await organizationModule.getWallet(_organizationId.value)

        _updateLastKnownStatus()
        _recursiveUpdateWallet()
        _recursiveUpdateWorkspace()
        _appCallShortcuts()
        _setWebviewListeners()
      } catch (err) {
        _declareError(err)
      }
    })

    appLoader.run(async () => {
      await _checkLoadingState()
    })

    onBeforeUpdate(() => {
      _reinforceAppExistence()
    })

    onBeforeUnmount(() => {
      _isMounted.value = false
      removeChat()
    })

    onMounted(() => {
      _updateChatInfo()
    })

    addChat()

    return {
      app,
      isArchiving,
      appIsOn,
      appIsLoading,
      appLoader,
      cancelShutdown,
      postponeAutoShutdown,
      destroySpace,
      showAutoShutdownSettings,
      loader,
      workspace,
      showWarningShutdownPopup,
      WorkspaceOnlineStatusEnum,
      shutDown,
      showIpConfig,
      downloadNowFile,
      downloadDcv,
      setStatus,
      deployWorkspace,
      restoreWorkspace,
      archiveWorkspace,
      downloadDcvThenNowFile,
      errorDetail,
      _lastKnownSpaceStatus,
      _lastKnownSpaceOnlineStatus
    }
  },
  components: {
    AutoShutdownSettings,
    ErrorReport,
    IpConfigPopup,
    WarningShutdownPopup,
    LargePresentation
  }
})
</script>
