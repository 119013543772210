<template>
  <div v-if="hasContent" class="imageWrapper" :download="download" :loading="appRequestingData">
    <div v-if="logo" @click="onClick" class="backgroundFill" :style="backgroundStyle" />

    <slot v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { IMedia } from "@/types/media"
import { UploadModule } from "@/store/upload"
import { Context } from "@/types/core"
import { raiseError } from "@/utils/event-bus"
import { downloadFromBase64 } from "@/utils/download"

export default defineComponent({
  props: {
    mediaContext: {
      type: String as PropType<Context>,
      required: true
    },
    mediaContextItemId: {
      type: String,
      required: true
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 150
    },
    maxHeight: {
      type: Number,
      default: 150
    },
    download: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const uploadStore = UploadModule()
    const uploads = ref<IMedia[]>([])
    const appRequestingData = ref(false)

    const hasContent = computed(() => {
      const isLoading = appRequestingData.value
      const hasSrcLink = !!src.value

      return isLoading || hasSrcLink
    })

    const backgroundStyle = computed(() => {
      if (!props.autoWidth) {
        return {
          backgroundImage: `url(${src.value})`,
          width: `${props.maxWidth}px`,
          height: `${props.maxHeight}px`
        }
      } else {
        return {
          backgroundImage: `url(${src.value})`
        }
      }
    })

    const getUploadList = async () => {
      try {
        appRequestingData.value = true

        const req = await uploadStore.getContextId({
          contextId: props.mediaContextItemId,
          context: props.mediaContext,
          ignoreExhibition: false
        })

        uploads.value = req
      } catch (err) {
        raiseError({ text: err as string, error: err })
      } finally {
        appRequestingData.value = false
      }
    }

    getUploadList()

    const logo = computed(() => {
      const media = uploads.value

      if (media && media.slice(-1).length) {
        return media[0]
      }

      return false
    })

    const src = computed(() => {
      if (logo.value && logo.value?.contentsBase64) {
        const mimeType = `data:${logo.value.mimeType};base64,`

        if (logo.value.contentsBase64.includes(mimeType)) {
          return logo.value.contentsBase64
        } else {
          return mimeType + logo.value.contentsBase64
        }
      }

      return false
    })

    const onClick = () => {
      ctx.emit("click")

      if (props.download && logo.value && logo.value?.contentsBase64) {
        downloadFromBase64(logo.value.contentsBase64, logo.value.fileName)
      }
    }

    return {
      onClick,
      appRequestingData,
      hasContent,
      logo,
      src,
      backgroundStyle
    }
  }
})
</script>

<style lang="scss">
.imageWrapper {
  position: relative;
  min-width: 106px;
  min-height: 32px;

  .backgroundFill {
    display: flex;
    background-size: contain;
    background-position: left center;
  }

  .loadingContainer {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background: $vizNow-color-7;
    }
  }
}
</style>
