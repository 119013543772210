<template>
  <div id="controllers">
    <other-options
      @refresh="$emit('refresh')"
      @delete="$emit('delete')"
      @destroy="$emit('destroy')"
      @open:file-transfer="$emit('open:file-transfer')"
      @deploy="$emit('deploy')"
      @open:vpc-peering="$emit('open:vpc-peering')"
      @download:dot-now-file="$emit('download:dot-now-file')"
      @open:auto-shutdown="$emit('open:auto-shutdown')"
      :workspace="workspace"
      :loading="loading"
      :eligible-apps="workspace.fileTransferEligible"
    />

    <add-apps
      v-model="addAppOpened"
      @add:apps="apps => $emit('add:apps', apps)"
      :workspace="workspace"
      :loading="loading"
    />

    <instructions @save="value => $emit('save:instructions', value)" :workspace="workspace" :loading="loading" />

    <ip-handler
      @add:ip="ip => $emit('add:ip', ip)"
      @remove:ip="ip => $emit('remove:ip', ip)"
      :workspace="workspace"
      :loading="loading"
    />

    <assigned-users
      @add:user="user => $emit('add:user', user)"
      @remove:user="user => $emit('remove:user', user)"
      :workspace="workspace"
      :loading="loading"
    />

    <archive-button @archive="$emit('archive')" :workspace="workspace" :loading="loading" />

    <deploy-button @deploy="$emit('deploy')" :workspace="workspace" :loading="loading" />

    <restore-button @click="$emit('restore')" :workspace="workspace" :loading="loading" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import AddApps from "./AddApps.vue"
import Instructions from "./Instructions.vue"
import IpHandler from "./IpHandler.vue"
import AssignedUsers from "./AssignedUsers.vue"
import OtherOptions from "./OtherOptions.vue"
import DeployButton from "./DeployButton.vue"
import RestoreButton from "./RestoreButton.vue"
import ArchiveButton from "./ArchiveButton.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "Controllers",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const addAppOpened = Utils.vModel(props, ctx.emit)

    return {
      addAppOpened
    }
  },
  components: {
    DeployButton,
    RestoreButton,
    AddApps,
    Instructions,
    AssignedUsers,
    OtherOptions,
    ArchiveButton,
    IpHandler
  }
})
</script>
