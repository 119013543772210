export interface IMedia {
  id: string
  context: ContextMedia | string
  contextItemId: string
  secondKey: string
  description: string
  fileName: string
  mimeType: string
  contentsBase64?: string
  size: number
  creationDate: Date
  createdByUserId: string
}

export enum ContextMedia {
  assetFiles = "workspace-asset-files",
  organizationLogo = "organization-logo",
  feedbackFiles = "feedback-files"
}
