import { Dictionary, Id, RegisteredIp } from "./core"
import { CloudProvider } from "./global"
import { WizardConfigItem, WizardWidget } from "./wizard"
import { OrganizationCertificateStatus } from "./organization"

export enum WorkspaceStatusEnum {
  Idle = "idle",
  Draft = "draft",
  Aborting = "aborting",
  Deploying = "deploying",
  Deployed = "deployed",
  DeployFailed = "deploy-failed",
  Destroying = "destroying",
  DestroyFailed = "destroy-failed",
  DeployedWithError = "deployed-with-error",
  ArchiveFailed = "archive-failed",
  Archiving = "archiving",
  Restoring = "restoring",
  Archived = "archived",
  /** @deprecated */
  ValidationNoAction = "validation-no-action"
}

export enum WorkspaceOnlineStatusEnum {
  Starting = "starting",
  Online = "online",
  Stopping = "stopping",
  Offline = "offline",
  Aborting = "aborting",
  Archived = "archived"
}

export enum WorkspaceErrorLevelEnum {
  Error = "error",
  Warning = "warning"
}

export enum SuitePlanEnum {
  Ultimate = "Ultimate",
  Premium = "Premium",
  Essential = "Essential"
}

export interface SortingConfig {
  sortBy: string[]
  sortDesc: boolean[]
}

export interface IAssetConfigurationDefinition {
  type: AssetConfigurationType
  name: string
  defaultValue?: string
}

export interface CreateWorkspacePayload {
  templateId: string
  authorizedIps: RegisteredIp[]
  workspaceWizardConfiguration?: WizardConfigItem[]
  organizationId: string
  title?: string
  description?: string
  tags?: string[]
}

export type AssetConfigurationType = "string" | "files" | null

export interface IAssetConfiguration {
  type: AssetConfigurationType
  key: string
  value: string
  name: string
}

export interface ApplicationLink {
  label: string
  link: string
  ipRequired: boolean
  credentials: {
    username: string
    password: string
  } | null
}

export interface IWorkspaceAsset {
  id: string
  type: string
  description: string
  applicationLinks: ApplicationLink[]
  configurations: IAssetConfiguration[]
  allowedInstanceTypes: IInstanceType[]
  deployOutputs: IAssetConfiguration[]
  onlineStatus: WorkspaceOnlineStatusEnum
  userConfigurations: WizardConfigItem[] | null
  deployedInstanceType?: IInstanceType | null
  runningSince: string | null
  onlineError: string | null
  instancePlatform: (InstancePlatform & string) | null
}

export enum InstancePlatform {
  linux = "Linux",
  windows = "Windows"
}

export interface IWorkspaceSchema {
  assets: IWorkspaceAsset[]
  tags: SpaceTag[]
}

export interface IWorkspacePermissions {
  userId: string
  canEdit: boolean
}

export interface IAuthorizedIp {
  ip: string
  description: string
  createdByUserId?: string
  creationDate?: Date
}

export enum CertificateVisibleState {
  warning = "warning",
  danger = "danger",
  notVisible = "notVisible"
}

export enum CertificateStatus {
  newCertificateAvailable = "newCertificateAvailable",
  certificateIncluded = "certificateIncluded",
  notAvailable = "notAvailable",
  organizationCertificateExpired = "organizationCertificateExpired",
  workspaceCertificateExpired = "workspaceCertificateExpired",
  certificateAvailable = "certificateAvailable",
  validating = "validating",
  failed = "failed"
}

export interface CertificateInfo<Status = CertificateStatus | OrganizationCertificateStatus | null> {
  expiresAt: string | null
  status: Status
  visibleState: CertificateVisibleState
  certificateError: string | null
}

export interface SpaceTag {
  name: string
  value: string
}

export interface AutoStartupDefinition {
  scheduledToStartupAt: string | null
  scheduledAt: string | null
}

export interface WorkspaceTemplate {
  id: string
  title: string
  templateId: string
  plan: SuitePlanEnum
  description: string
  organizationId: string
  username: string
  cloudZone: string
  cloudZoneAZ: string
  instructions: string
  schema: IWorkspaceSchema
  status: WorkspaceStatusEnum
  authorizedIps: IAuthorizedIp[]
  onlineStatus: WorkspaceOnlineStatusEnum
  userId: string | null
  isShared: boolean
  fileTransferEligible: boolean
  lastErrorLevel: WorkspaceErrorLevelEnum | null
  lastErrorTitle: string | null
  lastErrorDetails: string | null
  assignedUsers: IWorkspacePermissions[]
  creationDate: string
  modifyDate: string
  schemaDeployed: IWorkspaceSchema | null
  certificateInfo: CertificateInfo<CertificateStatus | null>
  stateChange: StateChange | null
  updateWarning: string | null
  customPeeringAllowed: boolean
  vpcCidr: string | null
  autoStartupDefinition: AutoStartupDefinition | null
  runningSince?: string
  autoShutdownDefinition: AutoShutdownInformation | null
}

export enum AutoShutdownStatus {
  autoShutdownEnabled = "autoShutdownEnabled",
  nearAutoShutdown = "nearAutoShutdown",
  checkingInactivity = "checkingInactivity",
  scheduled = "autoShutdownScheduled"
}
export interface AutoShutdownInformation {
  inactiveForInMinutes: number
  scheduledToShutdownAt: string
  scheduledAt: string
  inactivityShutdownAt: string
  status: AutoShutdownStatus
}

export interface StateChange {
  changeDate: string
  componentTarget: string
  username: string
  assetId: string
  changedTo: string
}

export interface WorkspaceListItem extends Dictionary<string | null | StateChange> {
  id: string
  title: string
  username: string
  modifyDate: string
  creationDate: string
  onlineStatus: WorkspaceOnlineStatusEnum
  status: WorkspaceStatusEnum
  stateChange: StateChange | null
}

export interface IAllowedApp {
  maxCount?: number
}

export interface WorkspaceRawTemplate {
  id: string
  plan: SuitePlanEnum
  title: string
  description: string
  tags: string[]
  source: string
  schema: IWorkspaceSchema
  date: Date
  instructions: string
  deployPackageId: string
  allowedApps: Dictionary<IAllowedApp>
}

export interface IAssetModel {
  id: string
  key: string
  banners: string[]
  text: string
  description: string
  icon: string
  category: string
  manualUrl: string
  configurations: Record<string, string | IAssetConfigurationDefinition>
  disabled: boolean
  defaultInstanceType?: IAssetInstanceType[]
  availableInstanceTypes?: ICloudProvider[]
  userConfigurations: WizardConfigItem[] | null
  requireLicenseServer: boolean
  hideFromAssetsToolbox?: boolean
  instancePlatform?: string
}

export interface AppItem {
  currentCount: number
  maxCount: number
  description: string
  category: string
  icon: string
  isAvailable: boolean
  key: string
  manualUrl: string
  text: string
  extraInformation: {
    inputs: string
    outputs: string
    estimatedCost: string
  }
}

export interface AddNewAssetPayload {
  assetModelType: string
}

export interface UpdateAssetInstanceTypePayload {
  code: string
}

interface WizardPayloadBase {
  resourceKey: string
  type: WizardWidget
  active: boolean
}

export interface PayloadWizardWithValue extends WizardPayloadBase {
  value: string | number | Id | null
}

export interface PayloadWizardWithKeyPairValue extends WizardPayloadBase {
  value: string
}

export interface PayloadWizardWithOptions extends WizardPayloadBase {
  options: Dictionary<string | number>[]
}

export type UpdateAssetUserConfigurationPayload =
  | PayloadWizardWithKeyPairValue
  | PayloadWizardWithValue
  | PayloadWizardWithOptions

export interface UpdateAssetPayload {
  description: string
  allowedInstanceTypes: UpdateAssetInstanceTypePayload[]
  userConfigurations: UpdateAssetUserConfigurationPayload[]
}

export type LicenseServer = WorkspaceTemplate

export enum VizGraphicApp {
  vectarPlus = "VectarPlus",
  vizTrio = "VizTrio",
  vizMosart = "VizMosart"
}

export interface WorkspacePreBuildSettings {
  vectar: {
    use: boolean
    spaceSize: number
    max: number
    min: number
  }
  ndi: {
    use: boolean
    remote: boolean
    bridge: boolean
  }
  intercom: {
    use: boolean
  }
  vizGraphic: {
    use: boolean
    app: VizGraphicApp
  }
}

export interface LicenseProduct {
  containerId: string | null
  freeLicenses: number
  product: string
  totalLicenses: number
}

export interface ICloudProvider {
  id?: string
  name: CloudProvider
  instanceTypes?: IInstanceType[]
}

export interface IInstanceType {
  code: string
  cpUsGInstances?: number
  cpUsStdInstances?: number
}

export interface IAssetInstanceType extends IInstanceType {
  cloudProviderName: string
}

export interface NdiConfiguration {
  mode: string
  encryptionKey: string
  hostIp: string
  port: number
}
