<template>
  <div class="wrapSaasSettings">
    <div class="inputRow pt-0">
      <div class="inputLabel animateFadeUp animationDelay-1">
        <span>{{ t("purchaseOrder") }}</span>

        <v-text-field
          v-model="saasSettings.purchaseOrder"
          :disabled="loading"
          :rules="rules.text"
          name="purchaseOrder"
          rbt-target="inputPurchaseOrder"
          hide-details
        />
      </div>

      <div class="inputLabel animateFadeUp animationDelay-2">
        <span>{{ t("type") }}</span>

        <v-select
          v-model="saasSettings.type"
          :disabled="loading"
          :rules="rules.select"
          :items="saasCollectionOfSubscriptions"
          rbt-target="selectSubscriptions"
          name="subscriptionTier"
          hide-details
        >
          <template v-slot:item="{ item }">
            <span :rbt-target="`option${toPascal(item)}`">{{ item }}</span>
          </template>
        </v-select>
      </div>

      <div class="inputLabel animateFadeUp animationDelay-3">
        <span>{{ t("startDate") }}</span>

        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDateFormat"
              v-bind="attrs"
              v-on="on"
              :disabled="loading"
              :rules="rules.text"
              name="startDate"
              rbt-target="inputStartDate"
              chips
              hide-details
              readonly
            />
          </template>

          <v-date-picker v-model="saasSettings.startDate" rbt-target="calendarStartDate" no-title scrollable />
        </v-menu>
      </div>

      <div class="inputLabel animateFadeUp animationDelay-4">
        <span>{{ t("expirationDate") }}</span>

        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="expirationDateFormat"
              v-bind="attrs"
              v-on="on"
              :disabled="loading"
              :rules="rules.text"
              name="expirationDate"
              rbt-target="inputExpirationDate"
              chips
              hide-details
              readonly
            />
          </template>

          <v-date-picker
            v-model="saasSettings.expirationDate"
            rbt-target="calendarExpirationDate"
            no-title
            scrollable
          />
        </v-menu>
      </div>
    </div>

    <div class="inputRow pt-0">
      <div class="inputLabel animateFadeUp animationDelay-1">
        <span>{{ t("startingTokens") }}</span>

        <v-text-field
          v-model="startingTokens"
          :disabled="loading"
          :rules="rules.token"
          name="startingTokens"
          rbt-target="inputStartingTokens"
          hide-details
        />
      </div>

      <div class="inputLabel animateFadeUp animationDelay-1">
        <span>{{ t("subscribedTokens") }}</span>

        <v-text-field
          v-model="subscribedTokens"
          :disabled="loading"
          :rules="rules.token"
          name="subscribedTokens"
          rbt-target="subscribedTokens"
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { SaaSSettings } from "@/types/organization"
import { MandatoryTextField, TokenRule, MandatorySelect } from "@/utils/input-rules"
import { ConfigurationModule } from "@/store"
import { translate } from "@/plugins/i18n"
import { toInteger } from "./lib/transaction-history-table-config"
import { EXPIRATION_TIME, date } from "@/config/token-expiration-date"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "CreateSaasForm",
  props: {
    value: {
      type: Object as PropType<SaaSSettings>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const saasSettings = Utils.vModel(props, ctx.emit)
    const configurationModule = ConfigurationModule()
    const rules = { text: MandatoryTextField, select: MandatorySelect, token: TokenRule }
    const t = (snippet: string) => translate("Admin.organizations.createPopup.subscriptionFields." + snippet)

    const startDateFormat = computed({
      get() {
        return Utils.dateFormat(saasSettings.value.startDate)
      },
      set(value: string) {
        saasSettings.value.startDate = new Date(value).toISOString()
      }
    })

    const expirationDateFormat = computed({
      get() {
        return Utils.dateFormat(saasSettings.value.expirationDate)
      },
      set(value: string) {
        saasSettings.value.expirationDate = new Date(value).toISOString()
      }
    })

    const saasCollectionOfSubscriptions = computed(() => {
      return configurationModule.subscriptionTypes
    })

    const startingTokens = computed({
      get() {
        return toInteger(saasSettings.value.startingTokens)
      },
      set(value: string) {
        saasSettings.value.startingTokens = toInteger(value)
      }
    })

    const subscribedTokens = computed({
      get() {
        return toInteger(saasSettings.value.subscribedTokens)
      },
      set(value: string) {
        saasSettings.value.subscribedTokens = toInteger(value)
      }
    })

    const toPascal = (text: string) => {
      return Utils.toPascal(text)
    }

    const _updateSettingsDate = () => {
      saasSettings.value.startDate = date()
      saasSettings.value.expirationDate = date(EXPIRATION_TIME)
    }

    _updateSettingsDate()

    return {
      t,
      rules,
      toPascal,
      saasSettings,
      subscribedTokens,
      startingTokens,
      saasCollectionOfSubscriptions,
      expirationDateFormat,
      startDateFormat
    }
  }
})
</script>
