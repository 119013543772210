import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('ul',{staticClass:"assetList",class:_vm.items.length ? '' : 'emptyList'},_vm._l((_vm.items),function(item){return _c('li',{key:item.id,staticClass:"applicationItem"},[_c('div',{staticClass:"iconBox"},[_c('figure',[_c('img',{attrs:{"src":("/" + (item.icon)),"alt":item.text}})])]),_c('div',{staticClass:"descriptionBox"},[_c('div',{staticClass:"wrapTitle"},[_c('h3',[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"menuContainer"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":22}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('ul',{staticClass:"listMenu"},[_c('li',{staticClass:"listRow",on:{"click":function($event){return _vm.edit(item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-square-edit-outline")]),_c('span',[_vm._v(_vm._s(_vm.t("edit")))])],1),_c('li',{staticClass:"listRow",on:{"click":function($event){return _vm.copyRawData(item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-copy")]),_c('span',[_vm._v(_vm._s(_vm.t("copy")))])],1),_c('li',{staticClass:"listRow",on:{"click":function($event){return _vm.duplicate(item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-duplicate")]),_c('span',[_vm._v(_vm._s(_vm.t("duplicate")))])],1),(false)?_c('li',{staticClass:"listRow",on:{"click":function($event){return _vm.remove(item.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-delete-outline")]),_c('span',[_vm._v(_vm._s(_vm.t("delete")))])],1):_vm._e()])])],1)]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(item.description))]),_c('div',{staticClass:"appDetails"},[(item.extraInformation)?_c('div',{staticClass:"outputs"},[_c('ul',[(item.extraInformation.inputs)?_c('li',[_vm._v(_vm._s(item.extraInformation.inputs)+" inputs")]):_vm._e(),(item.extraInformation.outputs)?_c('li',[_vm._v(_vm._s(item.extraInformation.outputs)+" outputs")]):_vm._e()])]):_vm._e(),(item.extraInformation && item.extraInformation.estimatedCost)?_c('div',{staticClass:"estimatedPrice"},[_c('p',[_vm._v("Estimated: "+_vm._s(item.extraInformation.estimatedCost)+" Credits per hour")])]):_vm._e()])])])}),0):_c('div',{staticClass:"emptyList"},[_c('span',[_vm._v("No assets")])])}
var staticRenderFns = []

export { render, staticRenderFns }