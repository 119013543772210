<template>
  <div id="workspaceNotFound" class="backgroundWrapper errorFeedback">
    <h3>
      <v-icon :size="28" color="error" left>status_error</v-icon>
      <span>{{ $t("WorkspaceEdit.withError.title") }}</span>
    </h3>

    <p>{{ $t("WorkspaceEdit.withError.subtitle") }}</p>

    <div v-if="errorFeedback && canSeeErrorDetails" class="errorFeedback">
      {{ errorFeedback }}
    </div>

    <div class="controllers">
      <v-btn
        v-if="errorFeedback && canSeeErrorDetails"
        @click="copyToClipboard(errorFeedback)"
        color="error"
        class="mt-3"
        large
      >
        <v-icon left>action_copy</v-icon>
        <span>{{ $t("WorkspaceEdit.withError.copyError") }}</span>
      </v-btn>

      <v-btn @click="goToWorkspaceList" color="primary" class="mt-3" large>
        <span>{{ $t("WorkspaceEdit.withError.navigateToSpaceList") }}</span>
      </v-btn>

      <v-btn v-if="hasRemovePermissions" @click="$emit('remove')" color="error" class="mt-3" large>
        <span>{{ $t("WorkspaceEdit.withError.removeSpace") }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api"
import { navigate } from "@/router"
import { copyToClipboard } from "@/utils/ui"
import { UsersModule } from "@/store"

export default defineComponent({
  name: "WorkspaceWithError",
  props: {
    errorFeedback: {
      type: String,
      default: ""
    }
  },
  setup() {
    const usersModule = UsersModule()

    const hasRemovePermissions = computed(() => {
      return usersModule.hasSupportPermissions
    })

    const goToWorkspaceList = () => {
      navigate({ name: "WorkspacesList" })
    }

    const canSeeErrorDetails = computed(() => {
      return usersModule.hasEditorPermissions
    })

    return {
      hasRemovePermissions,
      canSeeErrorDetails,
      goToWorkspaceList,
      copyToClipboard
    }
  }
})
</script>
