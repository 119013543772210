<template>
  <popup
    v-model="isOpen"
    :title="$t('Admin.organizations.editPopupTitle', { organizationName })"
    @close="$emit('update:organization')"
    width="85vw"
    no-padding
    hide-footer
  >
    <div id="editOrganizationPopup">
      <div class="organizationDetails">
        <div class="splitRow">
          <div class="leftBox">
            <div class="inputRow pb-0">
              <div class="inputLabel">
                <span>{{ t("input.orgName") }}</span>
                <simple-text-edit
                  @save="name => $emit('save:organization-name', name, organizationBeingEdited.id)"
                  :text="organizationBeingEdited.name"
                  :disabled="loading || !hasGodLevelPermissions"
                  always-visible
                />
              </div>

              <div class="inputLabel">
                <span>{{ t("input.orgType") }}</span>
                <span>{{ organizationTypeName }}</span>
              </div>
            </div>

            <div v-if="subscriptionSettings" class="inputRow py-0">
              <div class="inputLabel">
                <span>{{ t("subscriptionFields.type") }}</span>
                <span>{{ subscriptionSettings.type }}</span>
              </div>

              <div class="inputLabel">
                <span>{{ t("subscriptionFields.purchaseOrder") }}</span>
                <span>{{ subscriptionSettings.purchaseOrder }}</span>
              </div>

              <div class="inputLabel">
                <span>{{ t("subscriptionFields.expires") }}</span>
                <span>{{ formatDate(subscriptionSettings.expirationDate) }}</span>
              </div>
            </div>

            <div v-if="subscriptionSettings" class="inputRow py-0">
              <div class="inputLabel">
                <span>{{ t("subscriptionFields.subscribedTokens") }}</span>
                <span>{{ subscriptionSettings.subscribedTokens }}</span>
              </div>

              <div class="inputLabel">
                <span>{{ t("subscriptionFields.organizationTokens") }}</span>
                <span>{{ tokenCount }}</span>
              </div>

              <div class="inputLabel">
                <span>{{ t("subscriptionFields.startDate") }}</span>
                <span>{{ formatDate(subscriptionSettings.startDate) }}</span>
              </div>
            </div>
          </div>

          <div v-if="hasGodLevelPermissions" class="rightBox">
            <div class="inputLabel">
              <span>{{ $t("Admin.organizations.createPopup.input.logo") }}</span>

              <uploader
                @complete="$emit('upload:complete')"
                @update="$emit('upload:complete')"
                :context-id="organizationBeingEdited.id"
                :multiple="false"
                :file-list="organizationLogoCollection"
                context="organization"
              />
            </div>

            <div v-if="isSaaS" class="tokenControllers">
              <v-btn @click="$emit('add:token', organizationBeingEdited.id)" :disabled="loading" large>
                <v-icon left>action_add_circle</v-icon>
                <span>{{ $t("Admin.organizations.createPopup.addTokens") }}</span>
              </v-btn>

              <v-btn @click="$emit('remove:token', organizationBeingEdited.id)" :disabled="loading" large>
                <v-icon left>action_trash</v-icon>
                <span>{{ $t("Admin.organizations.createPopup.removeTokens") }}</span>
              </v-btn>

              <v-btn
                v-if="subscriptionSettings"
                @click="$emit('edit:subscription', organizationBeingEdited.id)"
                :disabled="loading"
                large
              >
                <v-icon left>mdi-pencil</v-icon>
                <span>{{ $t("Admin.organizations.createPopup.editSubscription") }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs">
        <span @click="tab = TabOption.TEMPLATE" :active="isActive(TabOption.TEMPLATE)" class="tabSelector">
          {{ $t("Admin.organizations.createPopup.tabs.templates") }}
        </span>

        <span
          v-if="isSaaS"
          @click="tab = TabOption.TRANSACTION_HISTORY"
          class="tabSelector"
          :active="isActive(TabOption.TRANSACTION_HISTORY)"
        >
          {{ $t("Admin.organizations.createPopup.tabs.transactionHistory") }}
        </span>
      </div>

      <workspace-templates
        v-if="tab === TabOption.TEMPLATE"
        @update:workspace-template="templates => $emit('save:workspace-template', templates)"
        :workspace-templates="organizationActiveTemplates"
        :loading="loading"
      />

      <transaction-history v-else-if="tab === TabOption.TRANSACTION_HISTORY && isSaaS" />
    </div>
  </popup>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref, PropType } from "@vue/composition-api"
import { raiseError } from "@/utils/event-bus"
import { AdminModule, UsersModule } from "@/store"
import { IMedia } from "@/types/media"
import { translate } from "@/plugins/i18n"
import { TokenRule } from "@/utils/input-rules"
import { OrganizationType } from "@/types/organization"
import Utils from "@/utils/utils"
import Popup from "@/components/popup/PopupRoot.vue"
import WorkspaceTemplates from "./_WorkspaceTemplates.vue"
import Uploader from "@/components/uploadv2/UploadRoot.vue"
import SimpleTextEdit from "@/components/simpleTextEdit/SimpleTextEditRoot.vue"
import TransactionHistory from "./_TransactionHistory.vue"

enum TabOption {
  TEMPLATE = "1",
  TRANSACTION_HISTORY = "2"
}

export default defineComponent({
  name: "EditOrganizationPopup",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    organizationLogoCollection: {
      type: Array as PropType<IMedia[]>,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const isOpen = Utils.vModel(props, ctx.emit)
    const tokenRule = TokenRule
    const tab = ref<TabOption>(TabOption.TEMPLATE)
    const t = (snippet: string) => translate("Admin.organizations.createPopup." + snippet)

    const organizationBeingEdited = computed(() => {
      return adminModule.organizationDetail
    })

    const organizationName = computed(() => {
      return organizationBeingEdited.value.name
    })

    const hasGodLevelPermissions = computed(() => {
      return usersModule.hasGodPermissions
    })

    const organizationTypeName = computed(() => {
      const organizationType = organizationBeingEdited.value?.organizationType

      if (organizationType) {
        return translate(`organizationTypes.${organizationBeingEdited.value?.organizationType}`)
      }

      return "Unknown"
    })

    const tokenCount = computed(() => {
      return organizationBeingEdited.value.tokens ?? 0
    })

    const isSaaS = computed(() => {
      const organizationType = organizationBeingEdited.value?.organizationType
      return organizationType === OrganizationType.SaaS
    })

    const organizationActiveTemplates = computed(() => {
      return organizationBeingEdited.value?.workspaceTemplates ?? []
    })

    const organizationTokens = computed(() => {
      return organizationBeingEdited.value.tokens ?? 0
    })

    const subscriptionSettings = computed(() => {
      if (isSaaS.value) {
        return organizationBeingEdited.value.tokenSubscription
      }
      return null
    })

    const isActive = (tabOption: TabOption) => {
      return tab.value === tabOption
    }

    const formatDate = (date: string) => {
      return Utils.dateFormat(date)
    }

    const _assertOrganizationExists = () => {
      if (!organizationBeingEdited.value) {
        raiseError({ text: "Invalid organization id" })
        isOpen.value = false
      }
    }

    onBeforeMount(() => {
      _assertOrganizationExists()
    })

    return {
      t,
      formatDate,
      isActive,
      tab,
      hasGodLevelPermissions,
      tokenCount,
      organizationName,
      subscriptionSettings,
      tokenRule,
      isSaaS,
      organizationTokens,
      organizationTypeName,
      organizationActiveTemplates,
      organizationBeingEdited,
      isOpen,
      TabOption
    }
  },
  components: {
    TransactionHistory,
    SimpleTextEdit,
    Popup,
    Uploader,
    WorkspaceTemplates
  }
})
</script>
