<template>
  <div class="valueSetter">
    <input
      v-model="keyValue"
      @input="validateInput"
      @keydown.enter="setValue"
      :disabled="loading"
      ref-key="setterInput"
      class="setterInput"
      placeholder="Setting value"
    />

    <button @click="setValue" :status="inputStatus" :disabled="loading" class="iconEnter">
      <v-icon>{{ icon }}</v-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "@vue/composition-api"
import { MaxLengthTextField } from "@/utils/input-rules"
import { MAX_TAG_VALUE_LENGTH } from "../lib/max-character"

enum InputStatus {
  ERROR = "error",
  SUCCESS = "success",
  IDLE = "idle"
}

export default defineComponent({
  name: "ValueSetter",
  props: {
    currentValue: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const keyValue = ref("")
    const inputStatus = ref(InputStatus.IDLE)

    const icon = computed(() => {
      switch (inputStatus.value) {
        case InputStatus.ERROR:
          return "mdi-close"
        case InputStatus.SUCCESS:
        case InputStatus.IDLE:
          return "mdi-check"
      }
    })

    const validateInput = () => {
      const rule = MaxLengthTextField(MAX_TAG_VALUE_LENGTH)[0]

      if (!keyValue.value) {
        inputStatus.value = InputStatus.IDLE
        return true
      } else if (rule(keyValue.value) === true) {
        inputStatus.value = InputStatus.SUCCESS
        return true
      } else {
        inputStatus.value = InputStatus.ERROR
        return false
      }
    }

    const setValue = () => {
      if (validateInput()) {
        ctx.emit("done", keyValue.value)
      }
    }

    const _useCurrentValueFromProps = () => {
      if (props.currentValue) {
        keyValue.value = props.currentValue
        validateInput()
      }
    }

    const _focusInput = () => {
      setTimeout(() => {
        const $setterInput = document.querySelector("[ref-key='setterInput']") as HTMLElement | null

        if ($setterInput) {
          $setterInput.focus()
        }
      }, 100)
    }

    _focusInput()
    _useCurrentValueFromProps()

    return {
      setValue,
      validateInput,
      InputStatus,
      inputStatus,
      icon,
      keyValue
    }
  }
})
</script>
