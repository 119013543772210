import { defineStore } from "pinia"
import { CloudProvider } from "@/services"
import { AppReadyState, ArnUpdate, CloudZone, RegisteredIp } from "@/types/core"
import { Workspace, Deployment, Admin, Organization } from "@/services"
import { WorkspaceTemplate } from "@/types/workspace"

export const LicenseModule = defineStore("licenseServer", {
  state: () => ({
    regions: [] as CloudZone[],
    lockLicense: false,
    currentLicense: {} as WorkspaceTemplate,
    workspaceLogged: {} as WorkspaceTemplate,
    appReadyState: {} as AppReadyState,
    workspaceId: "",
    defaultCidrIP: "",
    licenseServer: {
      cloudProvider: "",
      selectedRegions: [] as CloudZone[],
      authorizedIps: [] as RegisteredIp[],
      organizationId: "",
      spaceliftAwsRoleArn: "",
      vizrtAwsRoleArn: "",
      vizrtAwsRoleExternalId: "",
      awsAccountId: "",
      domainName: null as string | null,
      ipRange: ""
    }
  }),
  actions: {
    resetLicenseServer() {
      this.currentLicense = {} as WorkspaceTemplate

      this.licenseServer.organizationId = ""
      this.licenseServer.authorizedIps = []
    },

    async getRegions() {
      const regions = await CloudProvider.getRegions()
      this.regions = regions.data.data.regions
    },

    async getDefaultCidrIP() {
      const req = await Organization.getDefaultCidrIP()
      this.defaultCidrIP = req.data.data.defaultCidr
    },

    async insertCidrBlocks(organizationId: string, ip: string) {
      const req = await Organization.insertCidrBlocks(organizationId, ip)
      return req.data.data
    },

    async getLicenseServer(organizationId: string) {
      if (organizationId) {
        const license = await Workspace.getLicenseServer(organizationId)

        if (license?.data?.data) {
          this.currentLicense = license.data.data
        }
      }
    },

    async getLicenseState() {
      const license = await Organization.getLicenseServerState()
      this.appReadyState = license.data.data
      return this.appReadyState.readyToUse
    },

    async validateDomainName(domainName: string) {
      const req = await Organization.validateDomainName(domainName)

      return req.data.data.available
    },

    async registerDomainName(domainName: string, organizationId: string) {
      await Organization.pickDomainName(domainName, organizationId)
    },

    async resetLicense() {
      await Deployment.resetLicense(this.workspaceLogged.id)
    },

    async registerLicense() {
      this.lockLicense = true

      try {
        await this.createDeployThenPrepareDeploy()
      } finally {
        this.lockLicense = false
      }
    },

    async deployLicense(workspaceId: string, renewCertificate: boolean) {
      await Deployment.deploy(workspaceId, renewCertificate)
    },

    async createDeployThenPrepareDeploy() {
      const { authorizedIps, organizationId } = this.licenseServer
      const renewCertificate = false
      let workspaceId = ""

      try {
        await this.getLicenseServer(this.licenseServer.organizationId)
        workspaceId = this.currentLicense.id
      } catch {
        const licenseServer = await Workspace.createLicenseServer(authorizedIps, organizationId)
        workspaceId = licenseServer.data.data.id
      }

      this.workspaceId = workspaceId
      await Deployment.deploy(this.workspaceId, renewCertificate)
    },

    async setLicenseProviderSettings() {
      const { ...settings } = this.licenseServer

      await Admin.setProviderSettings({
        organizationId: settings.organizationId,
        spaceliftAwsRoleArn: settings.spaceliftAwsRoleArn,
        vizrtAwsRoleArn: settings.vizrtAwsRoleArn,
        vizrtAwsRoleExternalId: settings.vizrtAwsRoleExternalId,
        awsDeployAccountId: settings.awsAccountId,
        dnsName: settings.domainName,
        ipRange: settings.ipRange
      })
    },

    async updateArns(arnConfig: ArnUpdate) {
      await Admin.setProviderSettings(arnConfig)
    },

    async setCloudZonesToOrganization() {
      const { ...settings } = this.licenseServer
      const regions = settings.selectedRegions.map(region => region.code)

      await Admin.updateOrganizationDetails({
        id: settings.organizationId,
        cloudProviderName: settings.cloudProvider,
        cloudZones: regions
      })
    },

    async getWorkspaceDeployStatus() {
      const req = await Workspace.getWorkspace(this.workspaceId)
      const workspace = req.data.data

      this.workspaceLogged = workspace as WorkspaceTemplate

      return workspace
    }
  }
})
