<template>
  <div id="uploadIntegratedFileList">
    <ul>
      <li v-for="file in fileList" :key="file.id">
        <span>
          <a v-if="isDownloadable(file)" @click="() => downloadFile(file)">{{ file.fileName }}</a>
          <span v-else>{{ file.fileName }}</span>
        </span>

        <v-btn @click="() => removeFile(file)" :disabled="loading || disabled" icon>
          <v-icon>action_trash</v-icon>
        </v-btn>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "@vue/composition-api"
import { FileUploadPayload } from "@/types/core"
import { raiseConfirmation } from "@/utils/event-bus"
import { download } from "@/utils/download"

export default defineComponent({
  props: {
    fileList: {
      type: Array as PropType<FileUploadPayload[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup(_, ctx) {
    const removeFile = async (file: FileUploadPayload): Promise<void> => {
      if (await raiseConfirmation()) {
        ctx.emit("remove", file)
      }
    }

    const isDownloadable = computed(() => (file: FileUploadPayload): boolean => {
      return !!file.contentsBase64
    })

    const downloadFile = (file: FileUploadPayload) => {
      download(file)
    }

    return {
      isDownloadable,
      removeFile,
      downloadFile
    }
  }
})
</script>
