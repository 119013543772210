<template>
  <div id="organizationPicker" v-if="hasSupportPermissions">
    <v-form ref="Form">
      <v-combobox
        v-model="selectedOrganization"
        :key="pickerRenderKey"
        :loading="loader.on"
        :disabled="loader.on"
        :items="organizationList"
        rbt-target="organizationList"
        auto-select-first
        hide-details
      />
    </v-form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, nextTick } from "@vue/composition-api"
import { warnOrganizationUpdate } from "@/utils/event-bus"
import { UsersModule } from "@/store/users"
import { VForm } from "@/types/core"
import { AdminModule } from "@/store/admin"
import { eventBus } from "@/main"
import Loader from "@/utils/loader"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "OrganizationPicker",
  setup() {
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const pickerRenderKey = ref(0)
    const loader = Loader()
    const Form = ref<VForm | null>(null)

    const selectedOrganization = computed({
      get() {
        return adminModule.selectedOrganization
      },
      set(value) {
        adminModule.selectedOrganization = Utils.deepClone(value)

        _handleOrganizationChange()
      }
    })

    const organizationList = computed(() => {
      return adminModule.simpleOrganizationCollection.map(organization => {
        return {
          text: organization.name,
          value: organization.id
        }
      })
    })

    const hasSupportPermissions = computed(() => {
      return usersModule.hasSupportPermissions
    })

    const _updateRender = () => {
      nextTick(() => {
        pickerRenderKey.value++
      })
    }

    const _forcedUpdateRender = () => {
      _updateRender()

      nextTick(() => {
        if (Form.value) {
          Form.value.reset()
        }
      })
    }

    const _setOrganization = (organizationId: string) => {
      const org = organizationList.value.find(org => org.value === organizationId)

      if (org) {
        adminModule.selectedOrganization = org
      }
    }

    const _goBackToInitialValue = () => {
      const selectedOrganizationId = usersModule.selectedOrganizationId
      _setOrganization(selectedOrganizationId)

      _updateRender()
    }

    const _handleOrganizationChange = async () => {
      const selectedOrgId = adminModule.selectedOrganization?.value
      const isNewOrganizationId = selectedOrgId !== usersModule.selectedOrganizationId

      if (!selectedOrgId) {
        _goBackToInitialValue()
        return
      }

      if (isNewOrganizationId) {
        loader.run(async () => {
          await usersModule.switchOrganization(selectedOrgId)

          nextTick(() => {
            warnOrganizationUpdate()
          })
        })
      }
    }

    loader.run(async () => {
      await adminModule.getSimpleOrganizationCollection()
      await usersModule.getMyDetails()

      const orgId = await usersModule.updateOrganizationID()
      _setOrganization(orgId)
    })

    eventBus.$on("resolve:organizationId", _forcedUpdateRender)
    eventBus.$on("update:organizationId", _forcedUpdateRender)

    return {
      Form,
      selectedOrganization,
      loader,
      adminModule,
      pickerRenderKey,
      organizationList,
      hasSupportPermissions
    }
  }
})
</script>
