<template>
  <div v-if="dnsInfo" class="gridItem">
    <span>{{ t("dnsInfo") }}</span>
    <span>{{ dnsInfo }}</span>

    <copy-btn :text="dnsInfo" />
  </div>
</template>

<script lang="ts">
import { useStore } from "@/composable/useStore"
import { createTranslationModule } from "@/plugins/i18n"
import { defineComponent, computed } from "@vue/composition-api"
import CopyBtn from "@/components/copyBtn"

export default defineComponent({
  name: "DnsLabel",
  setup() {
    const t = createTranslationModule("TriCaster.boxFooter.moreInfo.")
    const { adminModule } = useStore()

    const dnsInfo = computed(() => {
      const livePanelLinkDetail = adminModule.appConnectionDetail.connectionUrl

      if (livePanelLinkDetail) {
        return _cleanDomainLink(livePanelLinkDetail)
      }

      return null
    })

    const _cleanDomainLink = (link: string) => {
      let _link = link

      if (_link.includes("http")) {
        _link = _link.slice(_link.indexOf("://") + 3)
      }
      if (_link.includes(".com")) {
        _link = _link.slice(0, _link.indexOf(".com") + 4)
      }

      return _link
    }

    return {
      t,
      dnsInfo
    }
  },
  components: {
    CopyBtn
  }
})
</script>
