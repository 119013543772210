<template>
  <div id="organizationUser">
    <navigate-back />

    <v-progress-linear v-if="loader.on" color="primary" class="my-0 py-0" indeterminate />

    <div class="backgroundWrapper">
      <div class="headerController">
        <div class="searchWrapper">
          <search v-model="searchSnippet" />
        </div>

        <div class="controllersWrapper">
          <controllers @create:user="userCreatorOpened = true" :disabled="loader.on" />
        </div>
      </div>

      <user-table
        @edit:user="openUserEditor"
        @remove:user="removeUser"
        @edit:user-state="changeUserState"
        :users="filteredUsers"
      />
    </div>

    <user-editor v-if="userEditorOpened" v-model="userEditorOpened" @save="saveUserData" :user="userBeingModified" />

    <user-creator v-if="userCreatorOpened" v-model="userCreatorOpened" @create="createUsers" />
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent } from "@vue/composition-api"
import { AdminModule, UsersModule } from "@/store"
import { IUser, UserRoleEnum } from "@/types/user"
import Loader from "@/utils/loader"
import NavigateBack from "@/components/navigateBack/NavigateBack.vue"
import Search from "@/components/search/Search.vue"
import Controllers from "./components/Controllers.vue"
import UserTable from "./components/UserTable.vue"
import Router from "@/router"
import UserEditor from "./components/UserEditor.vue"
import UserCreator from "./components/UserCreator.vue"
import "./scss/_organizationUser.scss"

const { Admin, User, ServiceUser } = UserRoleEnum

export default defineComponent({
  name: "OrganizationUserRoot",
  setup() {
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const loader = Loader()
    const userEditorOpened = ref(false)
    const userBeingModified = ref({} as IUser)
    const userCreatorOpened = ref(false)
    const searchSnippet = ref("")

    const users = computed(() => {
      if (usersModule.hasSupportPermissions) {
        const allTypesOfUsers = adminModule.organizationUsers
        return allTypesOfUsers
      } else {
        const onlyOrganizationUsers = adminModule.organizationUsers.filter(user => {
          const lowLevelRoles = [Admin, User, ServiceUser]
          return lowLevelRoles.includes(user.role)
        })

        return onlyOrganizationUsers
      }
    })

    const filteredUsers = computed(() => {
      const search = searchSnippet.value.trim()

      if (search) {
        const matchesText = (textOne: string, textTwo: string) => {
          return textOne.toLowerCase().includes(textTwo.trim().toLowerCase())
        }

        return users.value.filter(user => {
          const matchesName = matchesText(`${user.firstName} ${user.lastName}`, search)
          const matchesEmail = matchesText(user.email, search)

          return matchesName || matchesEmail
        })
      }

      return users.value
    })

    const openUserEditor = (user: IUser) => {
      userEditorOpened.value = true
      userBeingModified.value = user
    }

    const saveUserData = (user: IUser) => {
      const { organizationId } = Router.currentRoute.params

      loader.run(async () => {
        await adminModule.editUser(user, organizationId)
      })
    }

    const removeUser = (user: IUser) => {
      const { organizationId } = Router.currentRoute.params

      loader.run(async () => {
        await adminModule.deleteUser(user.id, organizationId)
        await adminModule.getOrganizationUsers(organizationId)
      })
    }

    const createUsers = async (users: IUser[]) => {
      const { organizationId } = Router.currentRoute.params

      loader.run(async () => {
        try {
          await Promise.all(users.map(async user => await adminModule.createUser(user, organizationId)))
        } finally {
          await adminModule.getOrganizationUsers(organizationId)
        }
      })
    }

    const changeUserState = (user: IUser) => {
      saveUserData({ ...user, disabled: !user.disabled })
    }

    loader.run(async () => {
      const { organizationId } = Router.currentRoute.params
      await adminModule.getOrganizationUsers(organizationId)
    })

    return {
      createUsers,
      loader,
      searchSnippet,
      userCreatorOpened,
      saveUserData,
      removeUser,
      changeUserState,
      filteredUsers,
      openUserEditor,
      userEditorOpened,
      userBeingModified,
      users
    }
  },
  components: {
    NavigateBack,
    UserCreator,
    UserEditor,
    Search,
    Controllers,
    UserTable
  }
})
</script>
