import { WorkspaceTemplate, WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { FilterStatusOption, FilterStatusTranslation } from "./type"

import {
  deployedStates,
  deployingStates,
  errorStates,
  workspaceIsDeployed,
  Draft,
  Archived,
  Archiving,
  Restoring
} from "@/config/workspace-states"

const { Online, Offline } = WorkspaceOnlineStatusEnum

const _translateStatus = (status: FilterStatusOption[]) => {
  return status.reduce((translatedList: FilterStatusTranslation[], _status) => {
    switch (_status) {
      case FilterStatusOption.deployedOnline:
        translatedList.push({
          status: deployedStates,
          onlineStatus: Online
        })
        break

      case FilterStatusOption.deployedOffline:
        translatedList.push({
          status: deployedStates,
          onlineStatus: Offline
        })
        break

      case FilterStatusOption.deploying:
        translatedList.push({
          status: deployingStates,
          onlineStatus: null
        })
        break

      case FilterStatusOption.archiving:
        translatedList.push({
          status: [Archiving],
          onlineStatus: null
        })
        break

      case FilterStatusOption.restoring:
        translatedList.push({
          status: [Restoring],
          onlineStatus: null
        })
        break

      case FilterStatusOption.archived:
        translatedList.push({
          status: [Archived],
          onlineStatus: null
        })
        break

      case FilterStatusOption.draft:
        translatedList.push({
          status: [Draft],
          onlineStatus: null
        })
        break

      case FilterStatusOption.failed:
        translatedList.push({
          status: errorStates,
          onlineStatus: null
        })
        break
    }

    return translatedList
  }, []) as FilterStatusTranslation[]
}

const _filterExistentDeployedItems = (translatedList: FilterStatusTranslation[], list: WorkspaceTemplate[]) => {
  return translatedList.filter(translatedStatus => {
    const onlineStatus = translatedStatus.onlineStatus

    if (!deployedStates.some(state => translatedStatus.status.includes(state)) || !onlineStatus) {
      return false
    }

    return Boolean(
      list.find(workspace => {
        return workspace.onlineStatus && workspace.onlineStatus.includes(onlineStatus)
      })
    )
  })
}

export const applyStatusFilter = (statusList: FilterStatusOption[], list: WorkspaceTemplate[]) => {
  const translatedList = _translateStatus(statusList)
  const onlyDeployedItems = _filterExistentDeployedItems(translatedList, list)

  return list.filter(workspace => {
    const hasMatchingStatus = translatedList.some(translatedStatus =>
      translatedStatus.status.includes(workspace.status)
    )

    if (!workspaceIsDeployed(workspace.status)) {
      return hasMatchingStatus
    } else if (onlyDeployedItems.length > 0) {
      const hasMatchingOnlineStatus = onlyDeployedItems.some(translatedStatus => {
        const _onlineStatus = translatedStatus.onlineStatus as string
        return _onlineStatus.includes(workspace.onlineStatus)
      })

      return hasMatchingOnlineStatus && hasMatchingStatus
    }

    return false
  })
}
