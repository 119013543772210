<template>
  <Dialog :title="$t('WorkspaceEdit.userHandler.title')" max-width="40vw" instruction hide-actions>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" rbt-target="addUser" large>
        <v-icon left>content_user</v-icon>
        <span>{{ $t("WorkspaceEdit.userHandler.buttons.open") }}</span>
      </v-btn>
    </template>

    <template v-slot:root>
      <v-progress-linear v-if="loading" color="primary" class="my-0 py-0" indeterminate />

      <div id="userHandler" class="displayData">
        <div v-if="usersInvitedToSpace.length > 0" class="userCollection collection">
          <div v-for="user in usersInvitedToSpace" :key="user.id" class="userItem collectionItem">
            <div v-if="user.id" class="labels">
              <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
              <span class="separator">-</span>
              <span class="email">{{ user.email }}</span>

              <span v-if="isYourUser(user.id)" class="highlighted">
                ( {{ $t("WorkspaceEdit.userHandler.label.you") }} )
              </span>
            </div>

            <v-btn
              v-if="canRemoveUsers"
              @click="removeUser(user)"
              :loading="loading"
              rbt-target="removeUserFromList"
              icon
            >
              <v-icon>action_trash</v-icon>
            </v-btn>
          </div>
        </div>

        <div v-else class="emptyUserCollection">
          <span>{{ $t("WorkspaceEdit.userHandler.label.noUsers") }}</span>
        </div>

        <div v-if="canInviteUsers" class="addUser formContainer">
          <v-form v-model="canAddUser" ref="Form" @submit.prevent>
            <v-combobox
              v-model="newUser"
              @keydown.enter="addUser"
              :rules="MandatorySelect"
              :disabled="loading"
              :items="eligibleUsersToBeInvitedFromOrganization"
              :label="$t('WorkspaceEdit.userHandler.input.userCollection')"
              :key="comboboxRenderKey"
              ref="Picker"
              rbt-target="usersInput"
              hide-details
              auto-select-first
            />

            <v-btn
              @click="addUser"
              :disabled="loading || loader.on || !canAddUser"
              color="primary"
              rbt-target="saveUserToList"
              large
            >
              <v-icon left>action_add</v-icon>
              <span>{{ $t("WorkspaceEdit.userHandler.buttons.addUser") }}</span>
            </v-btn>
          </v-form>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { computed, defineComponent, ref, nextTick, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { MandatorySelect } from "@/utils/input-rules"
import { IUser } from "@/types/user"
import { UsersModule } from "@/store/users"
import { VForm, SelectItem, VCombobox } from "@/types/core"
import { OrganizationModule } from "@/store/organization"
import Dialog from "@/components/global/Dialog.vue"
import Loader from "@/utils/loader"

interface User {
  id: string
}

export default defineComponent({
  name: "AssignedUsers",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()
    const organizationModule = OrganizationModule()
    const newUser = ref<SelectItem<IUser> | null>(null)
    const Form = ref<null | VForm>(null)
    const loader = Loader()
    const Picker = ref<null | VCombobox<IUser>>(null)
    const canAddUser = ref(false)
    const comboboxRenderKey = ref(0)

    const usersInvitedToSpace = computed(() => {
      const userList = organizationModule.users

      return props.workspace.assignedUsers.reduce((collection: IUser[], user): IUser[] => {
        const userFound = userList.find(_user => _user.id === user.userId)

        if (userFound) {
          collection.push(userFound)
        }

        return collection
      }, [])
    })

    const canInviteUsers = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const eligibleUsersToBeInvitedFromOrganization = computed(() => {
      return organizationModule.users.reduce((collection: SelectItem<IUser>[], user): SelectItem<IUser>[] => {
        const isOwner = props.workspace.userId === user.id
        const alreadyIncluded = Boolean(usersInvitedToSpace.value.find(_user => user.id === _user?.id))

        if (!isOwner && !alreadyIncluded) {
          collection.push({
            text: `${user.firstName} ${user.lastName} - ${user.email}`,
            value: user
          })
        }

        return collection
      }, [])
    })

    const canRemoveUsers = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const _resetInput = () => {
      if (Form.value && Picker.value) {
        Form.value.reset()
        Form.value.resetValidation()
        Picker.value.internalSearch = ""

        nextTick(() => {
          comboboxRenderKey.value++
        })
      }
    }

    const addUser = () => {
      if (Form.value && Form.value?.validate()) {
        ctx.emit("add:user", newUser.value?.value)
        _resetInput()
      }
    }

    const removeUser = (user: User) => {
      ctx.emit("remove:user", user)
    }

    const isYourUser = (userId: string) => {
      const selfId = usersModule.selfDetail.id
      return userId === selfId
    }

    loader.run(async () => {
      await organizationModule.getUsersFromOrganization(props.workspace.organizationId)
    })

    return {
      comboboxRenderKey,
      canAddUser,
      MandatorySelect,
      Picker,
      Form,
      newUser,
      loader,
      eligibleUsersToBeInvitedFromOrganization,
      usersInvitedToSpace,
      canInviteUsers,
      isYourUser,
      removeUser,
      canRemoveUsers,
      addUser
    }
  },
  components: {
    Dialog
  }
})
</script>
