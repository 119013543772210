<template>
  <div id="spaceTemplate" class="backgroundWrapper">
    <div class="summary">
      <h1>{{ $t("Admin.spaceTemplate.summary.title") }}</h1>
      <p>{{ $t("Admin.spaceTemplate.summary.subTitle") }}</p>
    </div>

    <div class="templateOptionContainer">
      <div class="templateHeader">
        <div class="containSearch">
          <search v-model="searchSnippet" />
        </div>
      </div>

      <div class="templateBox">
        <div class="templateFilterContainer">
          <tag-filter v-model="activeTags" :templates="workspaceTemplates" />
        </div>

        <div class="templateContent">
          <span v-if="loader.loading" class="loaderSection">
            <v-progress-linear class="py-0 my-0" color="primary" indeterminate />
          </span>

          <div class="wrapContent" :class="filteredTemplates.length ? '' : 'emptyList animateFadeUp'">
            <template-options
              v-if="filteredTemplates.length"
              @edit="editTemplate"
              :loading="loader.loading"
              :templates="filteredTemplates"
              :deploy-packages="deployPackages"
            />

            <small v-else>
              <span>{{ $t("Admin.spaceTemplate.noTemplates") }}</span>
            </small>
          </div>
        </div>
      </div>

      <template-editor
        v-model="templateEditorOpen"
        @confirm="editDeployPackageId"
        :key="templateBeingModified.id"
        :template="templateBeingModified"
        :deploy-packages="deployPackages"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent } from "@vue/composition-api"
import { WorkspaceRawTemplate } from "@/types/workspace"
import { AdminModule } from "@/store/admin"
import { TemplateEditorPayload } from "../../lib/type"
import TemplateOptions from "./TemplateOptions.vue"
import Loader from "@/utils/loader"
import Search from "@/components/search/Search.vue"
import TagFilter from "./Filter.vue"
import TemplateEditor from "./TemplateEditor.vue"

export default defineComponent({
  name: "SpaceTemplate",
  setup() {
    const adminModule = AdminModule()
    const loader = Loader()
    const activeTags = ref<string[]>([])
    const searchSnippet = ref("")
    const templateBeingModified = ref({} as WorkspaceRawTemplate)
    const templateEditorOpen = ref(false)

    const workspaceTemplates = computed(() => {
      return adminModule.workspaceTemplates
    })

    const deployPackages = computed(() => {
      return adminModule.deployPackages
    })

    const filteredTemplates = computed(() => {
      if (activeTags.value.length > 0) {
        return _filterByTags()
      } else {
        return _filterBySnippet()
      }
    })

    const _compare = (wordOne: string, wordTwo: string) => {
      return wordOne.toLowerCase().trim().includes(wordTwo.toLowerCase().trim())
    }

    const _filterByTags = () => {
      return workspaceTemplates.value.filter(item => {
        return activeTags.value.some(tag => {
          if (item.tags.includes(tag)) {
            return true
          } else {
            return _compare(item.plan ?? "", tag)
          }
        })
      })
    }

    const _filterBySnippet = () => {
      return workspaceTemplates.value.filter(item => {
        return _compare(item.title, searchSnippet.value)
      })
    }

    const editTemplate = (template: WorkspaceRawTemplate) => {
      templateBeingModified.value = template
      templateEditorOpen.value = true
    }

    const editDeployPackageId = (templatePayload: TemplateEditorPayload) => {
      loader.run(async () => {
        const { templateId, selectedTemplate, instructions } = templatePayload
        await adminModule.editWorkspaceTemplate(templateId, selectedTemplate)
        await adminModule.setWorkspaceTemplateInstruction(templateId, instructions)
        await adminModule.getAllWorkspaceTemplates()
      })
    }

    loader.run(async () => {
      await Promise.all([adminModule.getAllWorkspaceTemplates(), adminModule.getDeployPackages()])
    })

    return {
      editDeployPackageId,
      templateEditorOpen,
      templateBeingModified,
      searchSnippet,
      editTemplate,
      activeTags,
      workspaceTemplates,
      deployPackages,
      filteredTemplates,
      loader
    }
  },
  components: {
    TagFilter,
    TemplateEditor,
    Search,
    TemplateOptions
  }
})
</script>
