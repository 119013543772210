<template>
  <div class="contentSheet">
    <h2>Vizrt Privacy Policy</h2>

    <h3>Information regarding the processing of your Personal Data</h3>

    <p>
      Vizrt and its legal entities respects privacy and protects the Personal Data we process about you. All processing
      of Personal Data (as defined below) is made in accordance with the GDPR. The following describes how we collect,
      process and share personal data belonging to clients, business partners, suppliers and other organizations with
      which Vizrt has a business relationship.
    </p>

    <h3>What Personal Data will be processed?</h3>

    <p>
      Vizrt will collect and process your name, address, gender, country of residence, D.O.B, email, telephone number,
      purchasing behavior, company details, site usage data and platform preferences.
    </p>

    <h3>What is the purpose of processing your Personal Data?</h3>

    <p>
      When you access or sign up to any of the Vizrt services, activities or online content, such as newsletters, case
      studies, live webcasts and chats, forums, web and mobile notifications, telephone or text to Vizrt, book tickets
      for a Vizrt event or create an account using the Vizrt online registration system we may receive personal
      information about you. This can consist of information such as your name, email address, postal address, telephone
      or mobile number, gender or date of birth, as well as information collected about your use of Vizrt services (such
      as what you read, watch or interact with on the vizrt websites and platforms).Please note that sometimes we will
      require you to provide additional personal information, and sometimes sensitive personal information (e.g. if
      you're sending in an application for a job or submitting a specific support request. When we do this, we will
      provide further information about why we are collecting this information and how we will use it.
    </p>

    <h3>What are the legal grounds for processing your Personal Data?</h3>

    <p>To access and use of websites or other online services</p>

    <p>
      When entering one of our websites, or using an online service, we will record information necessary to provide you
      with access, for the operation of the website and for us to comply with security and legal requirements in
      relation to operating our site, such as passwords, IP address and browser settings. We also collect information
      about your activities during your visit in order to personalize your website experience, such as recording your
      preferences and settings, and to collect statistics to help us improve and further develop our websites, products
      and services.
    </p>

    <h3>Responding to your request for information, order, or support</h3>

    <p>
      When you contact us (online or offline) in connection with a request for information, to order a product or
      service, to provide you with support, or to participate in a forum or other social computing tool, we collect
      information necessary to fulfil your request, to grant you access to the product or service, to provide you with
      support and to be able to contact you. For instance, we collect your name and contact information, details about
      your request and your agreement with us and the fulfillments, delivery and invoicing of your order and we may
      include client satisfaction survey information. We retain such information for administrative purposes, defending
      our rights, and in connection with our relationship with you. When you provide your name and contact information
      to register in connection with such a request, the registration may serve to identify you when you visit our
      websites. For ordering of most services and products we require you to have registered a Vizrt Account.
      Registration may also allow you to customize and control your privacy settings.
    </p>

    <ul>
      <li>Your use of Vizrt connected services</li>
      <li>HR and Recruitment</li>
      <li>Support</li>
      <li>Product Forums</li>
      <li>Product Demos</li>
      <li>Documentation</li>
    </ul>

    <p>
      Vizrt collect information about your use of Vizrt services to enable product features to operate, improve your
      user experience, tailor our interactions with you, inform our clients on the overall use of the services, provide
      support and improve and develop our products and services.
    </p>

    <h4>Viz University</h4>

    <p>
      In order to deliver performance of service, Vizrt will collect the following Personal Data from users of the Vizrt
      University training platform: Name, address, gender, country of residence, D.O.B, email, telephone number,
      purchasing behavior, company details, site usage data including exams taken, grades and certification, and
      platform preferences.
    </p>

    <h4>Vizrt Support</h4>

    <p>
      In order to deliver performance of contract, Vizrt will collect the following Personal Data from users of the
      Vizrt Support platform: Name, address, gender, country of residence, D.O.B, email, telephone number, purchasing
      behavior, company details, site usage data including case notes, Digital Communication and platform preferences.
    </p>

    <h4>Vizrt Careers</h4>

    <p>
      In order to deliver performance of service, Vizrt will collect the following Personal Data from users of the Vizrt
      Careers platform: Name, address, gender, country of residence, D.O.B, email, telephone number, purchasing
      behavior, company details, site usage data including resumes, Digital Communication and platform preferences. More
      information
    </p>

    <h4>Vizrt Forums</h4>

    <p>
      In order to provide the best possible service, Vizrt will collect the following Personal Data from users of the
      Vizrt forums: Name, address, gender, country of residence, D.O.B, email, telephone number, purchasing behavior,
      company details, site usage data including public posts, digital Communication and platform preferences.
    </p>

    <h4>Product Demos and Free Viz Artist</h4>

    <p>
      In order to deliver performance of contract, Vizrt will collect the following Personal Data from users of the
      Vizrt product demos and Free Viz Artist: Name, address, gender, country of residence, D.O.B, email, telephone
      number, purchasing behavior, company details, site usage data including public posts, digital Communication and
      platform preferences.
    </p>

    <h4>Marketing</h4>

    <p>
      Most information we collect about you comes from our direct interactions with you. When you register for an event
      we may collect information (online or offline) in relation to the event organization, and during an event, such as
      participation in sessions and survey results. We combine the personal information we collect to develop aggregate
      analysis and business intelligence for conducting our business and for marketing purposes. When visiting our
      websites or using our services we may provide you with personalized information. You can always opt-out from
      receiving personalized communication through the drop down consent form on all web properties or by sending an
      e-mail to privacy@vizrt.com
    </p>

    <h4>Third-party software and services</h4>

    <p>
      Some Vizrt software and services utilize third-party technology, software or services. These services are subject
      to their own Terms of Services and Privacy Policies as described. See https://www.vizrt.com/thirdparties for a
      complete overview.
    </p>

    <h4>Consent</h4>

    <p>
      When we process your Personal Data for enhanced user experience, we do so based on your consent. Your consent is
      collected by checking the boxes below or through the drop down consent forms shown to all first time visitors to
      Vizrt platforms. You may also withdraw your consent at any time by contacting us at privacy@vizrt.com. Please note
      that this may result in us not being able to fulfil our commitments to you.
    </p>

    <p>Contact for personal data removal</p>

    <h4>Who has access to your Personal Data?</h4>

    <p>
      Vizrt is a multinational company with cross-border, cross-functional teams, and our employees frequently travel
      while still needing access to data in order to perform their duties. As Vizrt is a global company your Personal
      Data will be accessible from countries outside of EU/EEA, predominantly, but not limited to, our global offices in
      America, Thailand, Argentina, Australia, China, India, Indonesia, Japan, Korea, Brazil, Malaysia, Dubai, Myanmar,
      Hong Kong & Macau, Philippines, Russia, Singapore, South Africa, Taiwan, Vietnam. These countries may have a lower
      level of protection than within the EU/EEA. When transferring Personal Data to countries outside the EU/EEA, Vizrt
      ensures that your Personal Data is subject to an adequate level of protection, providing appropriate safeguards
      where necessary.
    </p>

    <p>
      Please be aware that in certain circumstances, personal information may be subject to disclosure to government
      agencies pursuant to judicial proceeding, court order, or legal process. We may also share your personal
      information to protect the rights or property of Vizrt when we have reasonable grounds to believe that such rights
      or property have been or could be affected.
    </p>

    <p>
      We have implemented appropriate technical and organizational measures to protect your Personal Data against loss
      or unlawful access. The number of persons with access to your Personal Data is limited. Only employees or other
      individuals associated with Vizrt org that need to process your Personal Data in accordance with the purposes
      above will have access to your Personal Data.
    </p>

    <h4>For how long is your Personal Data stored?</h4>

    <p>
      Vizrt will not retain personal information longer than necessary to fulfil the purposes for which it is processed,
      including the security of our processing complying with legal and regulatory obligations (e.g. audit, accounting
      and statutory retention terms), handling disputes, and for the establishment, exercise or defense of legal claims
      in the countries where we do business.
    </p>

    <h4>What are your rights?</h4>

    <p>
      Vizrt is the controller of the processing of your Personal Data. This means that we are responsible for your
      Personal Data being processed correctly and in accordance with applicable laws. For more information please
      contact privacy@vizrt.com
    </p>

    <p>
      You are entitled to know what Personal Data we are processing regarding you, and you can request a copy of such
      data. You are entitled to have incorrect Personal Data regarding you corrected, and in some cases you may request
      that we delete your Personal Data (if, for example, the Personal Data is no longer necessary for the purpose for
      which it was collected or if you withdraw your consent and the processing was based on such consent). You also
      have the right to object to certain processing of your Personal Data, and request that the processing of your
      Personal Data be limited. Please note that limitation or deletion of your Personal Data may result in us not being
      able to fulfil our commitments towards you. You are also entitled to extract your Personal Data in a
      machine-readable format and to transfer the Personal Data to another controller.
    </p>

    <p>
      If you have questions regarding how we process Personal Data concerning you, you are most welcome to contact us at
      privacy@vizrt.com or by mail to the address above.
    </p>

    <p>
      If you have any objections or complaints with the way we process your Personal Data, you have the right to lodge a
      complaint.
    </p>

    <sheet-link />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import SheetLink from "./SheetLinks.vue"

export default defineComponent({
  name: "PrivacyPolicySheet",
  components: {
    SheetLink
  }
})
</script>
