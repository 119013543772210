<template>
  <div class="centered">
    <enough-tokens v-if="checkStatus(Archived)">
      <template v-slot="{ disabled }">
        <v-btn :loading="loading" :disabled="disabled" @click="$emit('restore')" color="primary" large>
          {{ $t("TriCaster.buttons.restore") }}
        </v-btn>
      </template>
    </enough-tokens>

    <progress-bar
      v-else
      :progress-key="archiveKey"
      :app-id="appId"
      :online-status="status"
      :progress-director="checkStatus(Archiving) ? DIRECTOR.archiving : DIRECTOR.restoring"
      :last-modified="lastModifiedTime"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { ProgressCacheKey } from "../ProgressBar.vue"
import { DIRECTOR } from "../../lib/progress-step-director"
import ComponentLogicMixin from "./component-logic.mixin"
import EnoughTokens from "../_EnoughTokens.vue"

export default defineComponent({
  name: "ArchivedLogic",
  mixins: [ComponentLogicMixin],
  setup() {
    const archiveKey = ProgressCacheKey.ARCHIVING_WORKSPACE

    return {
      DIRECTOR,
      archiveKey
    }
  },
  components: {
    EnoughTokens
  }
})
</script>
