<template>
  <div class="organizationCard organizationTags">
    <h3>{{ t("title") }}</h3>

    <p class="label">
      {{ t("subtitle") }}
    </p>

    <tag-pill-bundler
      @edit="updateTags"
      @delete="deleteTags"
      :tags="organizationTags"
      :loading="loading || loader.on"
      :value-options="[]"
      :tags-not-deployed="[]"
      :tags-removed="[]"
      :used-tags="[]"
      :forbidden-names="[]"
      icon="action_add_circle"
      exclude-forbidden-names
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { AdminModule, OrganizationModule } from "@/store"
import { createTranslationModule } from "@/plugins/i18n"
import { OrganizationTag } from "@/types/organization"
import TagPillBundler from "@/components/tags/TagPillBundler.vue"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "OrganizationTags",
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const adminModule = AdminModule()
    const organizationModule = OrganizationModule()
    const loader = Loader()
    const t = createTranslationModule("OrganizationPageV2.globalOrganizationTags")

    const organizationTags = computed(() => {
      return organizationModule.organizationDetail.tags
    })

    const organizationId = computed(() => {
      return organizationModule.organizationDetail.id as string
    })

    const updateTags = (tag: OrganizationTag) => {
      loader.run(async () => {
        await adminModule.editOrganizationGlobalTag(organizationId.value, tag)
        await organizationModule.getOrganizationDetail(organizationId.value)
      })
    }

    const deleteTags = (tagName: string) => {
      loader.run(async () => {
        await adminModule.removeOrganizationGlobalTag(organizationId.value, tagName)
        await organizationModule.getOrganizationDetail(organizationId.value)
      })
    }

    return {
      t,
      updateTags,
      deleteTags,
      loader,
      organizationTags,
      organizationModule
    }
  },
  components: {
    TagPillBundler
  }
})
</script>
