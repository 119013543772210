import { translate } from "@/plugins/i18n"
import { HeaderItem } from "@/types/core"
import { computed } from "@vue/composition-api"

export const itemsPerPage = -1

export const toInteger = (value: string | number) => {
  const int = parseInt(`${value}`)
  return isNaN(int) ? `${value}`.replace(/\D+/g, "") : `${int}`
}

export const tableHeader = computed<HeaderItem[]>(() => {
  return [
    {
      value: "creationDate",
      text: translate("Admin.organizations.transactionTable.date"),
      sortable: false
    },
    {
      value: "id",
      text: translate("Admin.organizations.transactionTable.id"),
      sortable: false
    },
    {
      value: "description",
      text: translate("Admin.organizations.transactionTable.label"),
      sortable: false
    },
    {
      value: "initialAmount",
      text: translate("Admin.organizations.transactionTable.value"),
      sortable: false
    },
    {
      value: "usedAmount",
      text: translate("Admin.organizations.transactionTable.remaining"),
      sortable: false
    },
    {
      value: "activationDate",
      text: translate("Admin.organizations.transactionTable.activationDate"),
      sortable: false
    },
    {
      value: "expirationDate",
      text: translate("Admin.organizations.transactionTable.expires"),
      sortable: false
    }
  ]
})
