<template>
  <popup
    v-model="showModal"
    @confirm="save"
    :button-confirm-text="$t('common.save')"
    :close-on-click-out="!showVersionHandler"
    :disabled="showVersionHandler"
    :loading="loading"
    :title="popupTitle"
    :rbt-target="isEditingMode ? 'isEditing' : 'isCreating'"
    width="60vw"
  >
    <div class="editorContainer">
      <v-form @submit.prevent ref="Form">
        <div class="newDeployPackageContainer">
          <v-label>
            <span>{{ $t("Admin.deployPackage.editPopup.input.packageName") }} *</span>

            <v-text-field
              v-model="itemBeingModified.name"
              :disabled="showVersionHandler"
              :rules="rules"
              rbt-target="inputName"
              autofocus
            />
          </v-label>

          <v-label>
            <span>{{ $t("Admin.deployPackage.editPopup.input.description") }} *</span>

            <v-text-field
              v-model="itemBeingModified.description"
              :disabled="showVersionHandler"
              :rules="rules"
              rbt-target="inputDescription"
            />
          </v-label>
        </div>
      </v-form>

      <div class="versionHandlerContainer">
        <div v-if="!showVersionHandler" class="versionRow">
          <div class="versionController">
            <v-label>
              <span class="versionChipContainer">
                <strong>{{ $t("Admin.deployPackage.editPopup.currentVersion") }}</strong>
                <v-chip color="primary" small>v{{ packageData.currentVersion || 0 }}</v-chip>
              </span>
            </v-label>

            <v-btn @click="openVersionHandler" color="primary">
              <span>{{ $t("Admin.deployPackage.editPopup.releaseVersion") }}</span>
            </v-btn>
          </div>

          <version-list
            @set:current="(versionId, deployPackageId) => $emit('set:current', versionId, deployPackageId)"
            @download="versionId => $emit('download', versionId)"
            :current-version="packageData.currentVersion"
            :versions="versions"
          />
        </div>

        <version-handler
          v-model="terraformVersion"
          @release="release"
          @cancel="showVersionHandler = false"
          :latest-version="latestVersionValue"
          :version="currentVersionDetails"
          v-else
        />
      </div>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, reactive, PropType, ref, computed } from "@vue/composition-api"
import { IDeployPackage, IDeployPackageVersion } from "@/types/deployPackages"
import { MinLengthTextField } from "@/utils/input-rules"
import { createTranslationModule } from "@/plugins/i18n"
import { VForm } from "@/types/core"
import Utils from "@/utils/utils"
import Popup from "@/components/popup/PopupRoot.vue"
import VersionHandler from "./VersionHandler.vue"
import VersionList from "./VersionList.vue"

interface NewVersionData {
  description: string
  version: number
  size: number
  zipFileBase64: string
  serviceVersion: number
}

export default defineComponent({
  name: "EditorDeployPackage",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    packageData: {
      type: Object as PropType<IDeployPackage>,
      required: true
    },
    versions: {
      type: Array as PropType<IDeployPackageVersion[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const showModal = Utils.vModel(props, ctx.emit)
    const itemBeingModified = reactive({ ...props.packageData })
    const showVersionHandler = ref(props.packageData.currentVersion === null)
    const terraformVersion = ref("")
    const Form = ref<VForm | null>(null)
    const rules = MinLengthTextField(2)

    const isEditingMode = computed(() => {
      return Boolean(currentVersionDetails.value)
    })

    const popupTitle = computed(() => {
      const t = createTranslationModule("Admin.deployPackage.editPopup")

      if (isEditingMode.value) {
        return t("editTitle")
      } else {
        return t("createTitle")
      }
    })

    const currentTerraformVersion = computed(() => {
      const targetVersion = props.packageData.currentVersion

      const selectedPackage = props.versions.find(packageData => {
        return packageData.version === targetVersion
      })

      if (selectedPackage) {
        return selectedPackage.terraformVersion
      }

      return ""
    })

    const currentVersionDetails = computed(() => {
      return props.versions.find(version => {
        return version.version === props.packageData.currentVersion
      })
    })

    const latestVersionValue = computed(() => {
      const sortedVersions = props.versions.sort(
        (packageA, packageB) => parseInt(packageB?.version ?? 0) - parseInt(packageA?.version ?? 0)
      )

      return parseInt(sortedVersions[0]?.version ?? 0)
    })

    const openVersionHandler = () => {
      terraformVersion.value = currentTerraformVersion.value
      showVersionHandler.value = true
    }

    const release = (newVersionData: NewVersionData) => {
      ctx.emit("release", {
        date: new Date().toISOString(),
        deployPackageId: props.packageData.id,
        description: newVersionData.description,
        size: newVersionData.size,
        zipFileBase64: newVersionData.zipFileBase64,
        version: newVersionData.version,
        terraformVersion: terraformVersion.value,
        serviceVersion: newVersionData.serviceVersion
      })

      showVersionHandler.value = false
      terraformVersion.value = ""
    }

    const save = () => {
      const isForm = Utils.isType<VForm>(Form.value, "validate")

      if (isForm && isForm.validate()) {
        ctx.emit("save", itemBeingModified)
      }
    }

    return {
      isEditingMode,
      popupTitle,
      release,
      currentVersionDetails,
      Form,
      save,
      terraformVersion,
      showVersionHandler,
      openVersionHandler,
      currentTerraformVersion,
      itemBeingModified,
      showModal,
      latestVersionValue,
      rules
    }
  },
  components: {
    VersionHandler,
    VersionList,
    Popup
  }
})
</script>
