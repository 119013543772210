import { eventBus } from "@/main"
import { EventBusConfirmation } from "@/types/core"
import { AlertParams, MandatoryAlertParam } from "@/components/alert/lib/setup"
import { LocalStorageCache } from "@auth0/auth0-spa-js"

/**
 * @component ConfirmDialogRoot.vue
 * @param config EventBusConfirmation
 *
 * @example
 * if (await raiseConfirmation()) {
 *  raiseSuccess({ text: "Just confirmed" })
 * } else {
 *  raiseError({ text: "Just canceled" })
 * }
 *
 * raiseConfirmation({
 *  onConfirm: () => raiseSuccess({ text: "Just confirmed" }),
 *  onCancel: () => raiseError({ text: "Just canceled" })
 * })
 */
export const raiseConfirmation = (config?: EventBusConfirmation): Promise<boolean> => {
  eventBus.$emit("confirm-modal", config)

  return new Promise(resolve => {
    eventBus.$on("cancel:confirm-modal", () => resolve(false))
    eventBus.$on("confirm:confirm-modal", () => resolve(true))
  })
}

/**
 * @component AlertRoot.vue
 * @param err AlertParams
 *
 * @example
 * raiseError({ text: "Something went wrong", timeout: 2000, icon: false })
 * raiseError() // same thing as above
 */
export const raiseError = (err?: AlertParams, allowNoSession = false): void => {
  const localCache = new LocalStorageCache()
  const hasSessionRegistered = localCache.allKeys().length > 0

  if (hasSessionRegistered || allowNoSession) {
    eventBus.$emit("alert:error", err)

    if (err?.error) {
      throw err.error
    }
  }
}

/**
 * @component AlertRoot.vue
 * @param err AlertParams
 *
 * @example
 * raiseError({ text: "Successfully sent", timeout: 800, icon: false })
 * raiseError() // same thing as above
 */
export const raiseSuccess = (prop?: AlertParams): void => {
  eventBus.$emit("alert:success", prop)
}

export const raiseInfo = (prop: MandatoryAlertParam): void => {
  eventBus.$emit("alert:info", prop)
}

export const raiseWarning = (prop: MandatoryAlertParam): void => {
  eventBus.$emit("alert:warning", prop)
}

export const validateLicense = (): void => {
  eventBus.$emit("validate:license")
}

export const warnOrganizationUpdate = (): void => {
  eventBus.$emit("update:organizationId")
}

export const sessionRefreshed = () => {
  eventBus.$emit("session:refreshed")
}

export const resolveChangeOrganizationId = (organizationId: string) => {
  eventBus.$emit("resolve:organizationId", organizationId)
}

export const raiseChangeLogs = () => {
  eventBus.$emit("open:changeLog")
}

export const updatePlatformLogo = () => {
  eventBus.$emit("update:platform-logo")
}

export const forceRedrawPlatformViewSettings = () => {
  eventBus.$emit("swap-view-settings")
}
