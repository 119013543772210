<template>
  <div class="transactionTable">
    <v-data-table
      :headers="tableHeader"
      :items="walletTokens"
      :items-per-page="itemsPerPage"
      :item-class="rowClass"
      show-expand
      hide-default-footer
      expand-icon="mdi-menu-up"
      class="elevation-1"
    >
      <template v-slot:[`item.creationDate`]="{ item }">
        {{ toDateFormat(item.creationDate) }}
      </template>

      <template v-slot:[`item.initialAmount`]="{ item }">
        <span class="initialAmount"> + {{ item.initialAmount }} </span>
      </template>

      <template v-slot:[`item.usedAmount`]="{ item }">
        <span class="usedAmount">
          {{ calculateRemainingValue(item) }}
        </span>
      </template>

      <template v-slot:[`item.expirationDate`]="{ item }">
        <div v-if="item.expirationDate">
          <span v-if="!isExpired(item)">{{ toDateFormatWithTime(item.expirationDate) }}</span>
          <span expired v-else>{{ t("expired") }}</span>
        </div>

        <span v-else>{{ t("neverExpires") }}</span>
      </template>

      <template v-slot:[`item.activationDate`]="{ item }">
        <div v-if="item.activationDate">
          <span>{{ toDateFormat(item.activationDate) }}</span>
        </div>

        <span v-else>{{ t("neverExpires") }}</span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ul v-for="(transaction, index) in item.transactions" :key="index">
            <li
              v-for="keyName in Object.keys(transaction)"
              :class="keyName"
              :key="keyName"
              :style="{ gridArea: keyName }"
              :debt="transaction.amount < 0"
            >
              {{ getInnerTableItem(transaction, keyName) }}
            </li>
          </ul>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { tableHeader, itemsPerPage } from "./lib/transaction-history-table-config"
import { OrganizationModule } from "@/store"
import { WalletToken, WalletTransaction } from "@/types/organization"
import { translate } from "@/plugins/i18n"
import { Dictionary } from "@/types/core"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "TransactionHistoryRoot",
  setup() {
    const t = (snippet: string) => translate("Admin.organizations.transactionTable." + snippet)
    const organizationModule = OrganizationModule()

    const wallet = computed(() => {
      return organizationModule.walletDetail
    })

    const walletTokens = computed(() => {
      return wallet?.value?.walletTokens ?? []
    })

    const toDateFormat = (date: string) => {
      return Utils.dateFormat(date)
    }

    const toDateFormatWithTime = (date: string) => {
      return Utils.dateFormatWithTime(date)
    }

    const calculateRemainingValue = (walletToken: WalletToken) => {
      const { remainingAmount } = walletToken
      return Utils.numLocale(remainingAmount ?? 0)
    }

    const isExpired = (walletToken: WalletToken) => {
      const { expirationDate } = walletToken

      if (expirationDate) {
        return Date.now() > new Date(expirationDate).getTime()
      }

      return false
    }

    const rowClass = (item: WalletToken) => {
      let generatedClass = isExpired(item) ? "expired" : ""
      generatedClass += item.transactions.length > 0 ? " transactions" : " noTransactions"

      return generatedClass.trim()
    }

    const getInnerTableItem = (item: WalletTransaction, key: string) => {
      const _iterableItem = item as unknown as Dictionary<string>

      switch (key) {
        case "transactionDate":
          return toDateFormatWithTime(item.transactionDate)
        case "amount":
          return Math.abs(item.amount)
        default:
          return _iterableItem[key]
      }
    }

    return {
      t,
      toDateFormat,
      toDateFormatWithTime,
      calculateRemainingValue,
      isExpired,
      rowClass,
      getInnerTableItem,
      walletTokens,
      tableHeader,
      itemsPerPage
    }
  }
})
</script>
