<template>
  <ul class="accordionContainer">
    <tag-item
      v-for="tagDetail in tags"
      @toggle:open="toggleOpen"
      @save="save"
      @remove="remove"
      :key="tagDetail.name"
      :tag-details="tagDetail"
      :opened="openedTags"
      :loading="loading"
    />
  </ul>
</template>

<script lang="ts">
import { OrganizationTag } from "@/types/organization"
import { defineComponent, PropType } from "@vue/composition-api"
import TagItem from "./_TagItem.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "TagAccordion",
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true
    },
    tags: {
      type: Array as PropType<OrganizationTag[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const openedTags = Utils.vModel(props, ctx.emit)

    const toggleOpen = (tagName: string) => {
      const isOpen = openedTags.value.includes(tagName)

      if (isOpen) {
        const indexOfTag = openedTags.value.indexOf(tagName)
        openedTags.value.splice(indexOfTag, 1)
      } else {
        openedTags.value.push(tagName)
      }
    }

    const save = (details: OrganizationTag) => {
      ctx.emit("save", details)
    }

    const remove = (tagName: string) => {
      ctx.emit("remove", tagName)
    }

    return {
      openedTags,
      toggleOpen,
      save,
      remove
    }
  },
  components: {
    TagItem
  }
})
</script>
