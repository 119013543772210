<template>
  <popup
    v-if="appModel && appModel.key"
    v-model="showModal"
    :title="customTitle"
    :close-on-click-out="!preventAccidentalClose || hardLock"
    min-width="330px"
    width="unset"
    no-padding
  >
    <div id="appDetailsDialog">
      <dialog-header
        v-model="appValue"
        @set:online="state => $emit('set:online', state, app.id)"
        @save="save"
        :app="app"
        :app-model="appModel"
        :workspace="workspace"
        :loading="loading"
      />

      <v-progress-linear v-if="loading" color="primary" class="my-0 py-0" indeterminate />

      <warn-changes
        v-if="hasAnyChanges && isDeployed"
        v-model="viewChanges"
        @discard:app-changes="$emit('discard:app-changes', app.id)"
        :loading="disableInputs"
      />

      <warn-app-error
        v-if="appHasErrorsNeedsRestart"
        @app:retry="$emit('app:restart', app.id)"
        :app-error-message="app.onlineError"
        :loading="disableInputs"
      />

      <warn-app-error
        v-if="deployedWithError"
        @app:retry="$emit('redeploy')"
        :app-error-message="$t('WorkspaceEdit.alerts.feedbacks.deployedWithError')"
        :loading="disableInputs"
      />

      <div class="containDetails" :disabled="viewChanges">
        <div v-if="wizardPreDeployConfig.length" class="verticalContainer">
          <div class="item">
            <v-form ref="WizardForm">
              <div class="itemTitle">
                <tooltip :text="$t('WorkspaceEdit.appDetails.tooltip.preDeployWarning')">
                  <v-icon v-if="isNew" color="warning" left>status_warning</v-icon>
                  <v-icon v-else left>status_question</v-icon>
                </tooltip>
                <span>{{ $t("WorkspaceEdit.appDetails.widgetTitle.settingsPreDeploy") }}</span>
              </div>

              <wizard-config
                v-model="wizardPreDeployConfig"
                @validate="validateWizardForm"
                :disabled="disableInputs"
                :was-deployed="wasDeployed"
                :workspace="workspace"
                :app="app"
              />
            </v-form>
          </div>
        </div>

        <div class="verticalContainer">
          <div class="item">
            <div class="itemTitle">
              <span>{{ $t("WorkspaceEdit.appDetails.widgetTitle.instanceType") }}</span>
            </div>

            <div class="instanceTypeContainer">
              <deployed-instance v-if="app.deployedInstanceType" :name="app.deployedInstanceType.code" />

              <allowed-instance-types
                v-model="appValue"
                :available-instance-types="allowedInstances"
                :disabled="disableInputs"
                :has-changes="hasChanges('allowedInstanceTypes')"
              />
            </div>
          </div>
        </div>

        <div v-if="wizardPostDeployConfig.length" class="verticalContainer">
          <div class="item">
            <v-form ref="WizardForm">
              <div class="itemTitle">
                <span>{{ $t("WorkspaceEdit.appDetails.widgetTitle.settings") }}</span>
              </div>

              <wizard-config
                v-model="wizardPostDeployConfig"
                @validate="validateWizardForm"
                :disabled="disableInputs"
                :was-deployed="wasDeployed"
                :workspace="workspace"
                :app="app"
              />
            </v-form>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <dialog-footer
        @cancel="cancel"
        @remove="remove"
        @save="save"
        :disable-save="viewChanges || isEmptySave"
        :loading="loading"
      />
    </template>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, nextTick, PropType } from "@vue/composition-api"
import { IWorkspaceAsset, WorkspaceStatusEnum } from "@/types/workspace"
import { WorkspaceModule } from "@/store/workspace"
import { UsersModule } from "@/store/users"
import { VForm } from "@/types/core"
import { hasSchemaChanges, appChanges } from "@/utils/workspaceDiff"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { areEqualStructures } from "@/utils/workspaceDiff"
import { WizardConfigItem, Widget } from "@/types/wizard"
import { WorkspaceStates } from "@/config/workspace-states"
import Utils from "@/utils/utils"
import Dialog from "@/components/global/Dialog.vue"
import DialogHeader from "./DialogHeader.vue"
import AppDescription from "./AppDescription.vue"
import DeployedInstance from "./DeployedInstance.vue"
import AllowedInstanceTypes from "./AllowedInstanceTypes.vue"
import DialogFooter from "./DialogFooter.vue"
import WizardConfig from "@/components/wizardWidget/Wizard.vue"
import WarnChanges from "./WarnChanges.vue"
import WarnAppError from "./WarnAppError.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Popup from "@/components/popup/PopupRoot.vue"
import DomTools from "@/utils/dom"

export default defineComponent({
  name: "AppDetailsRoot",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      default: () => ({} as IWorkspaceAsset)
    },
    loading: {
      type: Boolean,
      required: true
    },
    hardLock: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const _appReference = ref(Utils.deepClone(props.app) as IWorkspaceAsset)
    const showModal = Utils.vModel(props, ctx.emit)
    const appTemp = ref(Utils.deepClone(props.app) as IWorkspaceAsset)
    const workspaceModule = WorkspaceModule()
    const usersModule = UsersModule()
    const WizardForm = ref<VForm>()
    const viewChanges = ref(false)

    const _cloudProvider = computed(() => {
      return usersModule.selfDetail.organization.cloudProvider.name
    })

    const _appIsChangingOnlineStatus = computed(() => {
      return WorkspaceStates.appLoading(props.app.onlineStatus)
    })

    const _hasEditorPermission = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const deployedWithError = computed(() => {
      return workspace.value.status === WorkspaceStatusEnum.DeployedWithError
    })

    const disableInputs = computed(() => {
      return props.loading || props.hardLock || !_hasEditorPermission.value || _appIsChangingOnlineStatus.value
    })

    const customTitle = computed(() => {
      const assetsDeployed = workspace.value.schemaDeployed?.assets ?? []
      const isNew = !assetsDeployed.some(app => props.app.id === app.id)
      const warningTitle = translate("WorkspaceEdit.appDetails.draftTitle")
      const defaultTitle = translate("WorkspaceEdit.appDetails.title")

      return isNew ? warningTitle : defaultTitle
    })

    const preventAccidentalClose = computed(() => {
      return !areEqualStructures(_appReference.value, appTemp.value)
    })

    const isDeployed = computed(() => {
      return WorkspaceStates.isDeployed(workspaceModule.watching.status)
    })

    const wasDeployed = computed(() => {
      const assets = workspaceModule.watching.schemaDeployed?.assets
      const appWasDeployed = Boolean(assets?.some(app => app.id === props.app.id))

      return appWasDeployed
    })

    const appHasErrorsNeedsRestart = computed(() => {
      const isDeployed = WorkspaceStates.isDeployed(workspace.value.status)
      const hasFailed = WorkspaceStates.failed(workspace.value.status)

      return Boolean((isDeployed || hasFailed) && props.app.onlineError)
    })

    const appValue = computed({
      get() {
        if (viewChanges.value && workspace.value.schemaDeployed) {
          const assets = workspace.value.schemaDeployed.assets
          const schemaDeployedAsset = assets.find(app => app.id === props.app.id)

          return schemaDeployedAsset as IWorkspaceAsset
        }

        return appTemp.value
      },
      set(value: IWorkspaceAsset) {
        appTemp.value = value
      }
    })

    const appModel = computed(() => {
      return workspaceModule.assetModels.find(asset => asset.key === props.app.type)
    })

    const wizardPreDeployConfig = computed({
      get() {
        const wizard = appValue.value.userConfigurations

        if (wizard && wizard.length) {
          return wizard.filter(widget => widget.settings.firstDeployOnly)
        }

        return []
      },
      set(wizardConfig: WizardConfigItem<Widget>[]) {
        appValue.value.userConfigurations = wizardConfig
      }
    })

    const wizardPostDeployConfig = computed({
      get() {
        const wizard = appValue.value.userConfigurations

        if (wizard && wizard.length) {
          return wizard.filter(widget => !widget.settings.firstDeployOnly)
        }

        return []
      },
      set(wizardConfig: WizardConfigItem<Widget>[]) {
        appValue.value.userConfigurations = wizardConfig
      }
    })

    const allowedInstances = computed(() => {
      const instances = appModel.value?.availableInstanceTypes?.find(instance => {
        return instance.name === _cloudProvider.value
      })

      return instances?.instanceTypes
    })

    const isNew = computed(() => {
      const assets = workspace.value.schemaDeployed?.assets
      return Boolean(!assets?.find(app => app.id === props.app.id))
    })

    const workspace = computed(() => {
      return workspaceModule.watching
    })

    const isEmptySave = computed(() => {
      return !preventAccidentalClose.value
    })

    const hasAnyChanges = computed(() => {
      const modified = appChanges(workspace.value)?.modified

      if (modified) {
        return modified.some(appId => props.app.id === appId)
      }

      return false
    })

    const cancel = async () => {
      const text = translate("WorkspaceEdit.appDetails.confirm.cancel")

      if (!preventAccidentalClose.value || (await raiseConfirmation({ text }))) {
        showModal.value = false
      }
    }

    const hasChanges = (key: string) => {
      return hasSchemaChanges({ workspace: workspace.value, appId: props.app.id, key })
    }

    const validateWizardForm = () => {
      if (WizardForm.value && appModel.value) {
        WizardForm.value.validate()

        nextTick(() => {
          const form = WizardForm.value as VForm
          DomTools.scrollElementIntoView(".error--text", form.$el)
        })
      }
    }

    const save = async () => {
      const form = Utils.isType<VForm>(WizardForm.value, "validate")

      if (form) {
        if (form.validate()) {
          if (await raiseConfirmation({ text: translate("WorkspaceEdit.appDetails.confirm.save") })) {
            ctx.emit("save", appTemp.value)
            showModal.value = false
          }
        } else {
          nextTick(() => {
            DomTools.scrollElementIntoView(".error--text", form.$el)
          })
        }
      } else {
        if (await raiseConfirmation({ text: translate("WorkspaceEdit.appDetails.confirm.save") })) {
          ctx.emit("save", appTemp.value)
          showModal.value = false
        }
      }
    }

    const remove = () => {
      ctx.emit("remove", props.app.id)
      showModal.value = false
    }

    onMounted(() => {
      _appReference.value = Utils.deepClone(appTemp.value) as IWorkspaceAsset
    })

    return {
      isEmptySave,
      validateWizardForm,
      deployedWithError,
      wizardPreDeployConfig,
      wizardPostDeployConfig,
      customTitle,
      appHasErrorsNeedsRestart,
      preventAccidentalClose,
      hasAnyChanges,
      hasChanges,
      viewChanges,
      save,
      wasDeployed,
      disableInputs,
      remove,
      isNew,
      workspace,
      isDeployed,
      appTemp,
      appValue,
      showModal,
      appModel,
      cancel,
      allowedInstances,
      WizardForm
    }
  },
  components: {
    Tooltip,
    Dialog,
    WarnAppError,
    WarnChanges,
    AllowedInstanceTypes,
    DeployedInstance,
    DialogHeader,
    DialogFooter,
    AppDescription,
    Popup,
    WizardConfig
  }
})
</script>
