import { defineStore } from "pinia"
import { Configuration } from "@/services"

export const ConfigurationModule = defineStore("configuration", {
  state: () => ({
    subscriptionTypes: [] as string[],
    forbiddenTagNames: [] as string[]
  }),
  actions: {
    async getSubscriptionTypes() {
      const req = await Configuration.getSubscriptionTypes()
      this.subscriptionTypes = req.data.data
    },

    async getForbiddenSpaceTags() {
      if (this.forbiddenTagNames.length === 0) {
        const req = await Configuration.getForbiddenSpaceTags()
        this.forbiddenTagNames = req.data.data
      }
    }
  }
})
