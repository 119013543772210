<template>
  <div class="largeBackground">
    <Dialog v-model="showBigPicture" fullscreen>
      <div @click.exact="zoomIn" @click.ctrl="zoomOut" class="largeImageContainer">
        <div :style="backgroundImage" class="backgroundLargeImage" />
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api"
import Dialog from "@/components/global/Dialog.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "LargeImage",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const zoomLevel = ref(1)
    const showBigPicture = Utils.vModel(props, ctx.emit)

    const _zoomTick = 0.2
    const _maxZoom = 1.6
    const _minZoom = 0.8

    const backgroundImage = computed(() => {
      return `background-image: url(${props.src}); transform: scale(${zoomLevel.value});`
    })

    const zoomIn = () => {
      if (zoomLevel.value + _zoomTick < _maxZoom) {
        zoomLevel.value += _zoomTick
      }
    }

    const zoomOut = () => {
      if (zoomLevel.value > _minZoom) {
        zoomLevel.value -= 0.2
      }
    }

    return {
      zoomLevel,
      showBigPicture,
      backgroundImage,
      zoomIn,
      zoomOut
    }
  },
  components: {
    Dialog
  }
})
</script>
