import * as Store from "@/store"

export const useStore = () => {
  const adminModule = Store.AdminModule()
  const usersModule = Store.UsersModule()
  const organizationModule = Store.OrganizationModule()
  const configurationModule = Store.ConfigurationModule()
  const licenseModule = Store.LicenseModule()
  const feedbackModule = Store.FeedbackModule()
  const uploadModule = Store.UploadModule()
  const spaceModule = Store.WorkspaceModule()

  return {
    adminModule,
    usersModule,
    organizationModule,
    licenseModule,
    configurationModule,
    feedbackModule,
    uploadModule,
    spaceModule
  }
}
