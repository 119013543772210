<template>
  <div class="itemCard">
    <tooltip
      :text="app.description || ''"
      :disabled="!app.description || app.description === appDescriptionWithMaxLenMask"
      bottom
    >
      <div class="cardPreviewImage" :is-using-app-logo="isUsingAppLogo">
        <img :src="bannerURL" />
      </div>

      <div class="cardDescription">
        <span class="appName">
          <strong>{{ appName }}</strong>
        </span>

        <span class="description">{{ appDescriptionWithMaxLenMask }}</span>

        <div class="inputOutput">
          <strong>
            {{ t("input", appInputs, { input: appInputs }) }} /
            {{ t("output", appOutput, { output: appOutput }) }}
          </strong>
        </div>

        <div class="estimatedCost">
          {{ t("estimatedCost", appEstimatedCost, { cost: appEstimatedCost }) }}
        </div>
      </div>

      <div class="cardFooter">
        <v-btn @click="$emit('click')" :disabled="loading" large> Select </v-btn>
      </div>
    </tooltip>
  </div>
</template>

<script lang="ts">
import { WorkspaceModule } from "@/store"
import { Dictionary } from "@/types/core"
import { AppItem } from "@/types/workspace"
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { translatePlural } from "@/plugins/i18n"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "AppItemCard",
  props: {
    app: {
      type: Object as PropType<AppItem>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const TRANSLATION_SNIPPET = "AppLauncherPicker.app."
    const workspaceModule = WorkspaceModule()

    const _assetModel = computed(() => {
      return workspaceModule.assetModels.find(_assetModel => _assetModel.key === props.app.key)
    })

    const _appLogo = computed(() => {
      const bannerMockBackground = "products/launcher/app-launcher-example.png"
      const appLogo = _assetModel?.value?.icon

      return appLogo ? appLogo : bannerMockBackground
    })

    const isUsingAppLogo = computed(() => {
      const appHasLogo = _assetModel?.value?.icon ?? false
      return _assetModel.value && _assetModel?.value?.banners?.length === 0 && appHasLogo
    })

    const appName = computed(() => {
      return props.app.text
    })

    const appInputs = computed(() => {
      return props.app?.extraInformation?.inputs ?? "0"
    })

    const appOutput = computed(() => {
      return props.app?.extraInformation?.outputs ?? "0"
    })

    const appEstimatedCost = computed(() => {
      return props.app?.extraInformation?.estimatedCost ?? "0"
    })

    const bannerURL = computed(() => {
      if (_assetModel.value && _assetModel.value?.banners?.length) {
        return `/${_assetModel.value.banners}`
      }

      return `/${_appLogo.value}`
    })

    const appDescriptionWithMaxLenMask = computed(() => {
      const _description = props.app.description ?? ""
      const MAX_LEN = 80

      if (_description.length >= MAX_LEN) {
        const slicedSnippet = _description.slice(0, MAX_LEN)
        const indexOfFinalDots = slicedSnippet.slice(-6).indexOf(".")

        if (indexOfFinalDots !== -1) {
          _description.slice(0, MAX_LEN - indexOfFinalDots).trim() + "..."
        } else {
          return _description.slice(0, MAX_LEN).trim() + "..."
        }
      }

      return _description
    })

    const t = (snippet: string, plural: string, config: Dictionary<string>) => {
      return translatePlural(TRANSLATION_SNIPPET + snippet, Number(plural), config)
    }

    return {
      t,
      appInputs,
      appOutput,
      appEstimatedCost,
      appDescriptionWithMaxLenMask,
      isUsingAppLogo,
      bannerURL,
      appName
    }
  },
  components: {
    Tooltip
  }
})
</script>
