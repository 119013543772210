import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { IUser } from "@/types/user"
import { Id } from "@/types/core"

const api = new Request("/v1/Users/")

export default createService("users", {
  async myDetails() {
    return await api.get<IUser>("GetMyDetails/")
  },

  async acceptTerms() {
    return await api.put("AcceptTerms/")
  },

  async getOrgUsers(organizationId: string) {
    return await api.get<IUser[]>(`GetOrganizationUsers/${organizationId}`)
  },

  async updateOrganization(userId: Id, organizationId: Id) {
    return await api.post(`${userId}/UpdateOrganization`, {
      organizationId
    })
  }
})
