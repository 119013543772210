<template>
  <div class="nestedWizardFunctions">
    <div v-for="item in settings" :key="item.resourceKey" class="nestedChild">
      <component v-model="item.settings" :label="item.label" :is="useComponent(item.type)" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WizardConfigItem, WizardWidget } from "@/types/wizard"
import Utils from "@/utils/utils"
import * as Widget from "@/components/wizardWidget"

export default defineComponent({
  name: "NestedWizardFunctions",
  props: {
    value: {
      type: Array as PropType<WizardConfigItem[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const settings = Utils.vModel(props, ctx.emit)

    const useComponent = (widgetType: WizardWidget) => {
      const widget = Widget[widgetType]

      if (widget) {
        return widget
      } else {
        throw Error(`Invalid widget, tried using ${widgetType}`)
      }
    }

    return {
      settings,
      useComponent
    }
  },
  components: {
    ...Widget
  }
})
</script>
