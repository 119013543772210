import { translate } from "@/plugins/i18n"
import { HeaderItem } from "@/types/core"

const headers: () => HeaderItem[] = () => {
  return [
    {
      text: translate("OrganizationUsers.table.name"),
      value: "firstName",
      sortable: true
    },
    {
      text: translate("OrganizationUsers.table.email"),
      value: "email",
      sortable: true
    },
    {
      text: translate("OrganizationUsers.table.role"),
      value: "role",
      sortable: false
    },
    {
      text: "",
      value: "action",
      sortable: false
    }
  ]
}

export default headers
