<template>
  <div id="ipHandler">
    <div class="topBar">{{ $t("Onboarding.ipRelated.title") }}</div>

    <div class="ipTable">
      <v-progress-linear v-if="appRequestingData" color="primary" indeterminate />

      <div class="gridRow" v-for="(registry, index) in registeredIps" :key="`${registry.ip}-${index}`">
        <div class="gridCell">{{ registry.ip }}</div>

        <div class="gridCell">
          {{ registry.description }}
          <small>
            <span class="ownerTag">{{ ownerTag(registry.ip) }}</span>
          </small>
        </div>

        <div class="gridCell action" v-if="canRemoveIp(registry.ip)">
          <v-btn @click="removeIp(index)" :disabled="loading" icon>
            <v-icon>action_trash</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="inputContainer">
      <v-form v-model="formValue" ref="Form">
        <v-text-field
          v-model="newIp.ip"
          :label="$t('Onboarding.ipRelated.inputs.ipAddress')"
          :rules="IPRule"
          :disabled="loading"
        />

        <v-text-field
          v-model="newIp.description"
          :label="$t('Onboarding.ipRelated.inputs.ipDescription')"
          :rules="MandatoryTextField"
          :disabled="loading"
          hide-details
        />

        <v-btn @click="formAddIp" :disabled="loading || appRequestingData || !formValue" color="primary" large>
          {{ $t("Onboarding.ipRelated.button.allowIp") }}
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed } from "@vue/composition-api"
import { MandatoryUniqueIP, MandatoryTextField } from "@/utils/input-rules"
import { AdminModule } from "@/store/admin"
import { translate } from "@/plugins/i18n"
import { VForm, RegisteredIp } from "@/types/core"
import { raiseError } from "@/utils/event-bus"
import { LicenseModule } from "@/store/license-server"
import { UsersModule } from "@/store/users"

export default defineComponent({
  name: "IpHandler",
  setup() {
    const registeredIps = ref<RegisteredIp[]>([])
    const adminModule = AdminModule()
    const licenseModule = LicenseModule()
    const usersModule = UsersModule()
    const appRequestingData = ref(false)
    const Form = ref<VForm | null>(null)
    const formValue = ref(false)

    const IPRule = [
      (value: string) => {
        const ips = registeredIps.value.map(IPDetails => IPDetails.ip)
        return MandatoryUniqueIP(value, ips)
      }
    ]

    const newIp = reactive({
      ip: "",
      description: ""
    })

    const loading = computed(() => {
      return licenseModule.lockLicense
    })

    const ownerName = computed(() => {
      const { firstName, lastName } = usersModule.selfDetail
      let name = ""

      if (firstName) {
        name = firstName

        if (lastName) {
          name += ` ${lastName}`
        }
      }

      return name
    })

    const _updateLicenseModuleIps = () => {
      licenseModule.licenseServer.authorizedIps = [...registeredIps.value]
    }

    const _getOwnIp = async () => {
      appRequestingData.value = true

      try {
        await adminModule.getOwnIp()

        if (!_addLoggedIps()) {
          addIp({
            ip: adminModule.ownIp,
            description: ownerName.value
          })
        }
      } finally {
        appRequestingData.value = false
      }
    }

    const _addLoggedIps = () => {
      const { authorizedIps } = licenseModule.licenseServer
      const { workspaceLogged } = licenseModule

      if (authorizedIps.length > 1) {
        registeredIps.value = [...authorizedIps]
      } else if (workspaceLogged?.authorizedIps) {
        const { authorizedIps } = workspaceLogged

        if (authorizedIps.length > 1) {
          registeredIps.value = [...authorizedIps]
        }
      }

      return registeredIps.value.length > 1
    }

    const canRemoveIp = (ip: string) => {
      return ip !== adminModule.ownIp
    }

    const addIp = (ipDetails: RegisteredIp = newIp) => {
      registeredIps.value.push({ ...ipDetails })
      _updateLicenseModuleIps()
    }

    const removeIp = (index: number) => {
      registeredIps.value.splice(index, 1)
      _updateLicenseModuleIps()
    }

    const formAddIp = () => {
      if (Form.value && Form.value.validate()) {
        const isDuplicatedIp = registeredIps.value.find(item => item.ip === newIp.ip)

        if (!isDuplicatedIp) {
          addIp()
          Form.value.reset()
        } else {
          raiseError({ text: translate("Onboarding.ipRelated.warning.ipAlreadyInserted") })
        }
      }
    }

    const ownerTag = (ip: string) => {
      if (ip === adminModule.ownIp) {
        return translate("Onboarding.ipRelated.myIp")
      }

      return ""
    }

    _getOwnIp()

    return {
      formValue,
      registeredIps,
      appRequestingData,
      newIp,
      MandatoryTextField,
      IPRule,
      Form,
      loading,
      ownerTag,
      removeIp,
      formAddIp,
      canRemoveIp
    }
  }
})
</script>
