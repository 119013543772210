<template>
  <div>
    <h1>Hello world</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { getEnv } from "@/utils/env"

export default defineComponent({
  name: "Honeypot",
  setup() {
    const sendToTrap = () => {
      const honeypotLink = getEnv<string>("VUE_APP_HONEYPOT_LINK")
      window.location.href = honeypotLink
    }

    sendToTrap()

    return
  }
})
</script>
