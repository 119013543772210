<template>
  <v-btn v-if="buttonVersion" @click="copyContent" icon>
    <v-icon :size="16">mdi-content-copy</v-icon>
  </v-btn>

  <v-icon v-else @click="copyContent" :size="16" class="copyBtn">mdi-content-copy</v-icon>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { copyToClipboard } from "@/utils/ui"
import "./_copy-btn.scss"

export default defineComponent({
  name: "CopyBtn",
  props: {
    text: {
      type: String,
      required: true
    },
    buttonVersion: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const copyContent = () => {
      copyToClipboard(props.text)
    }

    return {
      copyContent
    }
  }
})
</script>
