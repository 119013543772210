import { IWorkspaceAsset, WorkspaceTemplate } from "@/types/workspace"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Iterable = any

export interface WorkspaceLevelDiff {
  added: IWorkspaceAsset[]
  removed: IWorkspaceAsset[]
  current: IWorkspaceAsset[]
  modified: string[]
}

export enum AppDiffType {
  added = "added",
  removed = "removed",
  current = "current"
}

export interface SchemaComparisonConfig {
  workspace: WorkspaceTemplate
  appId: string
  key: string
}
