<template>
  <div class="organizationCard updateArn">
    <h3>{{ t("title") }}</h3>

    <p class="label">
      {{ t("subtitle") }}
    </p>

    <v-form v-model="validForm" ref="Form" class="wrapInputHandler">
      <div class="inputLabel">
        <span>
          <span>
            {{ t("deployArn") }}
          </span>

          <help-box>
            <v-icon size="16">mdi-help-circle-outline</v-icon>

            <template v-slot:content>
              <span v-html="t('deployAgentTooltip', { link: deployAgentARNLink })" />

              <v-btn @click="copyDeployRoleTrustPolicy" color="primary" class="mt-2">
                {{ t("deployRoleButtons.copyTrustPolicy") }}
              </v-btn>

              <v-btn @click="copyDeployRoleInlinePolicy" color="primary" class="mt-2">
                {{ t("deployRoleButtons.copyInlinePolicy") }}
              </v-btn>
            </template>
          </help-box>
        </span>

        <v-text-field
          v-model="deployRoleArn"
          @change="forceValidateForm"
          :disabled="loader.on"
          :rules="[rules.validateArn]"
        />
      </div>

      <div class="inputLabel">
        <span>
          <span>{{ t("manageArn") }}</span>

          <help-box>
            <v-icon size="16">mdi-help-circle-outline</v-icon>

            <template v-slot:content>
              <span v-html="t('manageRoleTooltip', { link: vizNowARNLink })" />

              <v-btn @click="copyManageRoleInlinePolicy" color="primary" class="mt-2">
                {{ t("manageRoleButtons.copyInlinePolicy") }}
              </v-btn>
            </template>
          </help-box>
        </span>

        <v-text-field
          v-model="manageRoleArn"
          @change="forceValidateForm"
          :disabled="loader.on"
          :rules="[rules.validateArn, rules.unique]"
        />
      </div>

      <v-btn @click="changeArn" :disabled="!isValidArn || !validForm" :loading="loader.on" color="primary">
        <v-icon>mdi-content-save-all-outline</v-icon>
      </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, inject, computed } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import { copyToClipboard } from "@/utils/ui"
import { extractAccountId, validateArn } from "@/views/onboarding/lib/arn-validation"
import { VForm } from "@/types/core"
import { LicenseModule } from "@/store"
import { IOrganization } from "@/types/organization"
import { raiseSuccess } from "@/utils/event-bus"
import Loader from "@/utils/loader"
import ArnCodeSnippets from "@/views/onboarding/lib/copy-snippet"
import HelpBox from "@/components/helpBox/HelpBox.vue"
import ArnLinks from "./lib/arn-links"

export default defineComponent({
  name: "UpdateArn",
  setup() {
    const licenseModule = LicenseModule()
    const t = createTranslationModule("OrganizationPageV2.arnUpdate")
    const organization = inject("organization") as IOrganization
    const loader = Loader()
    const Form = ref<VForm>()
    const validForm = ref(false)
    const deployRoleArn = ref("")
    const manageRoleArn = ref("")

    const awsExternalId = computed(() => {
      return crypto.randomUUID()
    })

    const isValidArn = computed(() => {
      const validDeployRole = validateArn(deployRoleArn.value) === true
      const validManageRole = validateArn(manageRoleArn.value) === true

      if (validDeployRole && validManageRole) {
        return deployRoleArn.value !== manageRoleArn.value
      }
      return false
    })

    const deployAgentARNLink = computed(() => {
      return ArnLinks.deployAgentArn()
    })

    const vizNowARNLink = computed(() => {
      return ArnLinks.vizNowArn(awsExternalId.value)
    })

    const copyDeployRoleTrustPolicy = () => {
      copyToClipboard(ArnCodeSnippets.trustPolicy)
    }

    const copyDeployRoleInlinePolicy = () => {
      copyToClipboard(ArnCodeSnippets.inlinePolicy)
    }

    const copyManageRoleInlinePolicy = () => {
      copyToClipboard(ArnCodeSnippets.vizNowTabInlinePolicy)
    }

    const changeArn = () => {
      const awsDeployAccountId = extractAccountId(deployRoleArn.value)

      loader.run(async () => {
        await licenseModule.updateArns({
          organizationId: organization.id,
          spaceliftAwsRoleArn: deployRoleArn.value,
          vizrtAwsRoleArn: manageRoleArn.value,
          vizrtAwsRoleExternalId: awsExternalId.value,
          awsDeployAccountId
        })

        raiseSuccess({ text: t("successWarning") })
      })
    }

    const forceValidateForm = () => {
      Form.value?.validate()
    }

    return {
      changeArn,
      copyDeployRoleTrustPolicy,
      copyDeployRoleInlinePolicy,
      copyManageRoleInlinePolicy,
      deployAgentARNLink,
      deployRoleArn,
      forceValidateForm,
      Form,
      isValidArn,
      loader,
      manageRoleArn,
      t,
      validForm,
      vizNowARNLink,
      rules: {
        validateArn: (value: string) => validateArn(value, t("ruleFeedback.invalidArn")),
        unique: (value: string) => value !== deployRoleArn.value || t("ruleFeedback.notUnique")
      }
    }
  },
  components: {
    HelpBox
  }
})
</script>
