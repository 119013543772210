<template>
  <div id="instructionsPopup">
    <popup v-model="showModal" :title="$t('WorkspaceWizard.instructionPopup.title')" hide-footer>
      <html-viewer :content="instructions" />
    </popup>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import HtmlViewer from "@/components/htmlEditor/HtmlViewer.vue"
import Utils from "@/utils/utils"
import Popup from "@/components/popup/PopupRoot.vue"

export default defineComponent({
  name: "InstructionPopup",
  props: {
    value: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const instructions = Utils.vModel(props, ctx.emit)

    const showModal = computed({
      get() {
        return !!instructions.value
      },
      set(value: boolean) {
        if (!value) {
          instructions.value = ""
        }
      }
    })

    return {
      showModal,
      instructions
    }
  },
  components: {
    Tooltip,
    HtmlViewer,
    Popup
  }
})
</script>
