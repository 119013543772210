<template>
  <div class="wrapForm">
    <div class="newPeeringTitle">
      <h3 v-if="isVpcType(CreationType.EXTERNAL_PEERING)">{{ t("externalVpc") }}</h3>
      <h3 v-else-if="isVpcType(CreationType.SPACE_PEERING)">{{ t("vizNowSpace") }}</h3>

      <span class="mandatoryLabel">
        <strong class="mandatory">*</strong>
        {{ t("mandatoryFields") }}
      </span>
    </div>

    <v-form v-model="validForm">
      <div class="inputs">
        <div class="inputLabel">
          <span>{{ t("inputs.name") }} <strong class="mandatory">*</strong></span>

          <v-text-field v-model="vpc.name" :rules="MandatoryTextField" :disabled="!isEditable" hide-details />
        </div>

        <div v-if="isVpcType(CreationType.EXTERNAL_PEERING)" class="inputLabel">
          <span>{{ t("inputs.ownerAccountId") }}</span>

          <v-text-field
            v-model="vpc.ownerAccountId"
            :rules="optionalOwnerAccountId"
            :disabled="!isEditable"
            :placeholder="t('inputs.ownerAccountIdPlaceholder')"
            hide-details
          />
        </div>

        <div v-if="isVpcType(CreationType.EXTERNAL_PEERING)" class="inputLabel">
          <span>{{ t("inputs.peerVpcId") }} <strong class="mandatory">*</strong></span>

          <v-text-field
            v-model="vpc.peerVpcId"
            @change="useVpcIdAsName"
            :rules="MandatoryTextField"
            :disabled="!isEditable"
            hide-details
          />
        </div>

        <div v-if="isVpcType(CreationType.EXTERNAL_PEERING)" class="inputLabel">
          <span>{{ t("inputs.peerCidr") }} <strong class="mandatory">*</strong></span>

          <v-text-field v-model="vpc.peerCidr" :rules="MandatoryTextField" :disabled="!isEditable" hide-details />
        </div>

        <div v-if="isVpcType(CreationType.EXTERNAL_PEERING)" class="inputLabel">
          <span>{{ t("inputs.peerRegion") }} <strong class="mandatory">*</strong></span>

          <v-text-field v-model="vpc.peerRegion" :rules="MandatoryTextField" :disabled="!isEditable" hide-details />
        </div>

        <div v-if="isVpcType(CreationType.SPACE_PEERING)" class="inputLabel">
          <span>{{ t("inputs.selectSpace") }}</span>

          <v-select
            v-if="isNewVpc"
            v-model="selectedSpace"
            @change="useVpcSpacePeeringName"
            :rules="MandatorySelect"
            :disabled="!isEditable"
            :items="workspaceCollection"
            hide-details
          />
          <v-select v-else v-model="selectedSpaceTitle" :items="[selectedSpaceTitle]" disabled />
        </div>
      </div>

      <div v-if="isNewVpc" class="controller">
        <v-btn @click="$emit('remove:new-vpc', vpc.id)" large>{{ t("buttons.cancel") }}</v-btn>
        <v-btn @click="createVpc" color="primary" large>{{ t("buttons.create") }}</v-btn>
      </div>

      <div v-else class="controller">
        <v-btn @click="$emit('remove:old-vpc', vpc.id)" color="error" large>{{ t("buttons.delete") }}</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { MandatoryTextField, MandatorySelect } from "@/utils/input-rules"
import { AwsVpcConfig, VpcItem, CreationType, VpcPeerWorkspace } from "./lib/type"
import { WorkspaceStatusEnum, WorkspaceTemplate } from "@/types/workspace"
import { WorkspaceModule } from "@/store"
import { optionalOwnerAccountId } from "./lib/input-rules"
import { translate } from "@/plugins/i18n"
import { defaults } from "./lib/defaults"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "VpcWatcher",
  props: {
    value: {
      type: Object as PropType<VpcItem>,
      required: true
    },
    vpcCollection: {
      type: Array as PropType<VpcItem[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const workspaceModule = WorkspaceModule()
    const vpc = Utils.vModel(props, ctx.emit)
    const validForm = ref(false)

    const isEditable = computed(() => {
      return isNewVpc.value
    })

    const usingPlaceholderName = computed(() => {
      const defaultNames = Object.values(defaults)
      return defaultNames.includes(vpc.value.name)
    })

    const isNewVpc = computed(() => {
      return vpc.value.isNew
    })

    const workspaceCollection = computed(() => {
      const idCollectionOfPeeredSpaces = props.vpcCollection.reduce((collection: string[], _vpc) => {
        if (_vpc.peerWorkspaceId) {
          collection.push(_vpc.peerWorkspaceId)
        }
        return collection
      }, [])

      return _selectItemsOfSpaces.value.map(item => {
        return {
          ...item,
          disabled: idCollectionOfPeeredSpaces.includes(item.value.id)
        }
      })
    })

    const adaptorVpcPayload = computed<AwsVpcConfig | VpcPeerWorkspace>(() => {
      if (vpc.value.creationType === CreationType.EXTERNAL_PEERING) {
        return {
          id: vpc.value.id,
          name: vpc.value.name,
          ownerAccountId: vpc.value.ownerAccountId || null,
          peerVpcId: vpc.value.peerVpcId,
          peerRegion: vpc.value.peerRegion,
          peerCidr: vpc.value.peerCidr,
          peeringType: CreationType.EXTERNAL_PEERING
        } as AwsVpcConfig
      } else {
        return {
          id: vpc.value.id,
          name: vpc.value.name,
          peerWorkspaceId: vpc.value.peerWorkspaceId,
          peeringType: CreationType.SPACE_PEERING
        }
      }
    })

    const selectedSpace = computed<WorkspaceTemplate | null>({
      get() {
        const _space = _selectItemsOfSpaces.value.find(space => {
          return space.value.id === vpc.value.peerWorkspaceId
        })

        if (_space) {
          return _space.value
        }

        return null
      },
      set(space: WorkspaceTemplate | null) {
        if (space) {
          vpc.value.peerWorkspaceId = space.id
        }
      }
    })

    const selectedSpaceTitle = computed(() => {
      return selectedSpace.value?.title ?? ""
    })

    const _selectItemsOfSpaces = computed(() => {
      const availableWorkspaces = workspaceModule.myWorkspacesFromOrganization.filter(space => {
        const thisWorkspaceId = workspaceModule.watching.id
        const isDeployed = space.status === WorkspaceStatusEnum.Deployed
        const notTheCurrentWorkspace = space.id !== thisWorkspaceId

        return isDeployed && notTheCurrentWorkspace
      })

      return Utils.createSelectItems(availableWorkspaces, "title")
    })

    const t = (snippet: string) => {
      const translationPrefix = "WorkspaceEdit.multiVpc."
      return translate(translationPrefix + snippet)
    }

    const isVpcType = (type: CreationType) => {
      const isNew = vpc.value.isNew

      if (isNew) {
        return vpc.value.creationType === type
      } else {
        const hasChosenSpace = !!vpc.value.peerWorkspaceId
        const typeIsSpacePeering = type === CreationType.SPACE_PEERING

        if (typeIsSpacePeering) {
          return hasChosenSpace
        } else {
          return !hasChosenSpace
        }
      }
    }

    const createVpc = () => {
      if (validForm.value) {
        ctx.emit("create:new-vpc", adaptorVpcPayload.value)
      }
    }

    const useVpcIdAsName = () => {
      const usingGeneratedName = vpc.value.name.includes("Peering ")

      if (usingPlaceholderName.value || usingGeneratedName) {
        vpc.value.name = translate("WorkspaceEdit.multiVpc.peeringWithVpcId", { vpcId: vpc.value.peerVpcId })
      }
    }

    const useVpcSpacePeeringName = () => {
      vpc.value.name = _getGeneratedSpaceVpcName(vpc.value.peerWorkspaceId)
    }

    const _getGeneratedSpaceVpcName = (peeredSpaceId: string) => {
      const spacePeered = workspaceModule.myWorkspacesFromOrganization.find(space => space.id === peeredSpaceId)?.title
      const thisSpace = workspaceModule.watching.title

      if (spacePeered) {
        return translate("WorkspaceEdit.multiVpc.peeringWithSpaces", { thisSpace, spacePeered })
      }

      return translate("WorkspaceEdit.multiVpc.peeringWithUnknown", { thisSpace })
    }

    return {
      t,
      useVpcSpacePeeringName,
      useVpcIdAsName,
      CreationType,
      createVpc,
      validForm,
      vpc,
      selectedSpaceTitle,
      adaptorVpcPayload,
      isVpcType,
      selectedSpace,
      isNewVpc,
      isEditable,
      workspaceCollection,
      MandatorySelect,
      MandatoryTextField,
      optionalOwnerAccountId
    }
  }
})
</script>
