<template>
  <div id="organizationPageV2">
    <div class="summary">
      <h3>{{ $t("OrganizationPage.title") }}</h3>
      <p>{{ $t("OrganizationPage.subTitle") }}</p>
    </div>

    <div class="tabContainer" :class="loader.on ? '--tab-container-loading' : ''">
      <div class="tabs">
        <div
          v-for="tab in tabOptions"
          @click="selectTab(tab.name)"
          :key="tab.name"
          :class="`tabItem tabName--${tab.name} --is-active-${tab.name === tabItem}`"
        >
          <v-icon left>{{ tab.icon }}</v-icon>
          <span>{{ t(`tabs.${tab.name}`) }}</span>
        </div>
      </div>

      <v-progress-linear v-if="loader.on" indeterminate color="primary" />

      <div class="tabContentContainer">
        <div v-for="tab in tabOptions" :key="tab.name" :class="`tabContentItem tabContent--${tab.name}`">
          <component
            v-if="hasOrganizationLoaded"
            v-show="tab.name === tabItem"
            @update:organization="getOrganizationDetails"
            :is="tab.component"
            :key="organizationId"
            :loading="loader.on"
            :has-tab-focused="tab.name === tabItem"
            class="animateFadeUp"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, provide } from "@vue/composition-api"
import { OrganizationModule, UsersModule } from "@/store"
import { tabCollection } from "./lib/tab-options"
import { createTranslationModule } from "@/plugins/i18n"
import { eventBus } from "@/main"
import { OrganizationType } from "@/types/organization"
import Router, { replace } from "@/router"
import NavigateBack from "@/components/navigateBack/NavigateBack.vue"
import Loader from "@/utils/loader"
import "./organizationPage.scss"
import router from "@/router"

export default defineComponent({
  name: "OrganizationPage",
  setup() {
    const t = createTranslationModule("OrganizationPageV2")
    const tabItem = ref(tabCollection[0].name)
    const loader = Loader()
    const organizationModule = OrganizationModule()
    const usersModule = UsersModule()

    const tabOptions = computed(() => {
      return tabCollection
    })

    const organizationId = computed(() => {
      return organizationModule?.organizationDetail?.id ?? ""
    })

    const organizationDetails = computed(() => {
      provide("organization", organizationModule.organizationDetail)
      return organizationModule.organizationDetail
    })

    const hasOrganizationLoaded = computed(() => {
      return Boolean(organizationDetails.value?.name)
    })

    const selectTab = (tabName: string) => {
      tabItem.value = tabName
      router.replace({ query: { tab: tabName } })
    }

    const getOrganizationDetails = async () => {
      const organizationId = Router.currentRoute.params.organizationId
      await organizationModule.getOrganizationDetail(organizationId)
      await _getWalletDetails(organizationId)
    }

    const _resolveUrl = () => {
      const routeOrganizationId = Router.currentRoute.params.organizationId

      if (!routeOrganizationId) {
        const userOrganizationId = usersModule.selectedOrganizationId
        replace({ params: { organizationId: userOrganizationId } })
      }
    }

    const _onChangeOrganization = () => {
      loader.run(async () => {
        const userOrganizationId = usersModule.selectedOrganizationId
        replace({ params: { organizationId: userOrganizationId } })

        await getOrganizationDetails()
      })
    }

    const _getWalletDetails = async (organizationId: string) => {
      const { SaaS } = OrganizationType
      const isCreditBasedOrganization = organizationDetails.value?.organizationType === SaaS

      if (isCreditBasedOrganization) {
        await organizationModule.getWallet(organizationId)
      }
    }

    const _resolveTab = () => {
      const { query } = router.currentRoute

      if (typeof query?.tab === "string") {
        tabItem.value = query.tab
      }
    }

    loader.run(async () => {
      _resolveUrl()
      _resolveTab()
      await getOrganizationDetails()
    })

    eventBus.$on("update:organizationId", _onChangeOrganization)

    return {
      t,
      hasOrganizationLoaded,
      getOrganizationDetails,
      organizationId,
      organizationDetails,
      loader,
      selectTab,
      tabItem,
      tabOptions
    }
  },
  components: {
    NavigateBack
  }
})
</script>
