<template>
  <div class="licenseCard appCard">
    <div class="stageHandler cardHeader" :deploy-stage="deployStage">
      <idle-stage v-if="idleStage" :stage="deployStage" />

      <deploying-stage v-else-if="progressStage" :stage="deployStage" :progress="progress" />

      <deployed-stage
        v-else-if="deployed"
        @update:server="status => $emit('update:server', status)"
        :stage="deployStage"
      />

      <stage-error v-else :stage="deployStage" />
    </div>

    <div class="cardContent">
      <h3>Vizrt License server</h3>
      <p>{{ $t("Onboarding.card.description") }}</p>
    </div>

    <div class="cardFooter">
      <div class="controllers">
        <v-btn v-tippy="{ content: $t('workspaces.downloadZipWithLink') }" disabled large>
          <v-icon left>action_file_download_or_import</v-icon>
          <span>Shortcut</span>
        </v-btn>

        <v-btn disabled large>
          <v-icon left>content_folder_closed</v-icon>
          <span>Open</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceStatusEnum } from "@/types/workspace"
import { workspaceIsLoading, workspaceIsDeployed } from "@/config/workspace-states"
import DeployingStage from "./DeployingStage.vue"
import DeployedStage from "./DeployedStage.vue"
import IdleStage from "./IdleStage.vue"
import StageError from "./StageError.vue"

export default defineComponent({
  name: "LicenseCard",
  props: {
    deployStage: {
      type: String as PropType<WorkspaceStatusEnum>,
      required: true
    },
    progress: {
      type: [String, Number],
      default: 0
    },
    progressLabel: {
      type: String,
      default: "Deploying"
    }
  },
  setup(props) {
    const idleStage = computed(() => {
      const { Draft, Idle } = WorkspaceStatusEnum
      return [Draft, Idle].includes(props.deployStage)
    })

    const progressStage = computed(() => {
      return workspaceIsLoading(props.deployStage)
    })

    const deployed = computed(() => {
      return workspaceIsDeployed(props.deployStage)
    })

    return {
      idleStage,
      progressStage,
      deployed
    }
  },
  components: {
    DeployingStage,
    DeployedStage,
    IdleStage,
    StageError
  }
})
</script>
