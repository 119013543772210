<template>
  <popup
    v-model="openPopUp"
    @confirm="confirm"
    :loading="loading"
    :title="popupTitle"
    :button-confirm-text="confirmationText"
    width="800px"
    class="createAsset"
  >
    <v-form ref="Form">
      <asset-form v-model="asset" :category-list="assetCategoryList" ref="assetForm" />
    </v-form>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, nextTick, ref } from "@vue/composition-api"
import { ViewType } from "./lib/type"
import { VForm } from "@/types/core"
import { createTranslationModule } from "@/plugins/i18n"
import { WorkspaceApplication } from "@/types/asset"
import { WorkspaceModule } from "@/store"
import Popup from "@/components/popup/PopupRoot.vue"
import AssetForm from "./_AssetForm.vue"
import Utils from "@/utils/utils"
import Vue from "vue"

export default defineComponent({
  name: "CreateAsset",
  props: {
    value: {
      type: Object as PropType<WorkspaceApplication>,
      required: true
    },
    view: {
      type: String as PropType<ViewType>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const workspaceModule = WorkspaceModule()
    const Form = ref<VForm | null>(null)
    const assetForm = ref<null | Vue.Component>(null)
    const asset = Utils.vModel(props, ctx.emit)
    const t = createTranslationModule("Admin.assetHandler.")

    const popupTitle = computed(() => {
      const creatingSnippet = t("header.createNew")
      const editingSnippet = t("header.editAsset")

      return props.view === ViewType.CREATING ? creatingSnippet : editingSnippet
    })

    const openPopUp = computed({
      get() {
        const { CREATING, EDITING } = ViewType
        return [CREATING, EDITING].includes(props.view)
      },
      set(value) {
        if (!value) {
          ctx.emit("view:change", ViewType.LISTING)
        }
      }
    })

    const assetCategoryList = computed(() => {
      return workspaceModule.assetModels.map(asset => asset.category)
    })

    const confirmationText = computed(() => {
      return props.view === ViewType.CREATING ? t("popupForm.buttonCreate") : t("popupForm.buttonEdit")
    })

    const confirm = () => {
      if (Form.value && Form.value.validate()) {
        const isCreating = props.view === ViewType.CREATING

        if (isCreating) {
          ctx.emit("create")
        } else {
          ctx.emit("edit")
        }
      } else {
        nextTick(() => _scrollIntoView())
      }
    }

    const _scrollIntoView = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const assetFormComponent = Utils.isType<any>(assetForm.value, "$el")

      if (assetFormComponent) {
        const $assetForm = assetFormComponent.$el as HTMLElement

        if ($assetForm) {
          const $errorTextInput = $assetForm.querySelector(".error--text")

          if ($errorTextInput) {
            if ($errorTextInput.parentNode) {
              const $parentNode = $errorTextInput.parentNode as HTMLElement

              $parentNode.scrollIntoView({
                behavior: "smooth",
                block: "start"
              })
            } else {
              $errorTextInput.scrollIntoView({
                behavior: "smooth",
                block: "start"
              })
            }
          }
        }
      }
    }

    return {
      assetForm,
      assetCategoryList,
      confirmationText,
      asset,
      popupTitle,
      confirm,
      Form,
      openPopUp
    }
  },
  components: {
    AssetForm,
    Popup
  }
})
</script>
