export enum PreDefinedSize {
  small = "small",
  medium = "medium",
  large = "large",
  default = small
}

export interface OverrideSizes {
  width: string
  height: string
}

export interface TemplateSizes {
  small: OverrideSizes
  medium: OverrideSizes
  large: OverrideSizes
  default: OverrideSizes
}
