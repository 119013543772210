<template>
  <popup v-model="popupOpen" :loading="loader.on" width="650px" title="A">
    <template v-slot:title>
      <span>{{ t("title") }}</span>

      <tooltip :text="t('titleTooltip')">
        <v-icon>status_question</v-icon>
      </tooltip>
    </template>

    <div id="autoShutdownContent">
      <h3>{{ t("titleH3") }}</h3>

      <p v-if="isSaaSAccount">
        {{ t("subTitleSaaS") }}
      </p>

      <p v-else>
        {{ t("subTitleCustomerHosted") }}
      </p>

      <ul>
        <li>
          <v-checkbox
            v-model="shutdownSettings.autoShutdownEnabled"
            :label="autoShutdownLabel"
            :disabled="disabled"
            dense
            hide-details
          />

          <v-slider
            v-if="shutdownSettings.autoShutdownEnabled"
            v-model="shutdownSettings.shutdownTimerInHours"
            :max="defaults.MAX_SHUTDOWN_TIME"
            :min="defaults.MIN_SHUTDOWN_TIME"
            :step="defaults.SHUTDOWN_STEP_VALUE"
            :tick-labels="tickLabels"
            :height="1"
            :disabled="disabled"
            class="animateFadeUp"
          />
        </li>

        <li v-if="isSaaSAccount">
          <v-checkbox
            v-model="shutdownSettings.inactivityTrackerEnabled"
            :label="t('inactivityLabel')"
            :disabled="disabled"
            dense
            hide-details
          />
        </li>
      </ul>
    </div>

    <template v-slot:footer>
      <v-btn @click="popupOpen = false" large>{{ t("closeButton") }}</v-btn>

      <tooltip v-if="!disabled" :disabled="!isOnline" :text="t('alreadyOnlineWarning')">
        <v-btn @click="saveChanges" color="primary" large>{{ t("saveButton") }}</v-btn>
      </tooltip>
    </template>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { createTranslationModule, translatePlural } from "@/plugins/i18n"
import { WorkspaceOnlineStatusEnum, WorkspaceTemplate } from "@/types/workspace"
import { OrganizationModule, WorkspaceModule } from "@/store"
import Popup from "@/components/popup/PopupRoot.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"
import Loader from "@/utils/loader"
import "./auto-shutdown.scss"

const defaults = {
  MAX_SHUTDOWN_TIME: 10,
  MIN_SHUTDOWN_TIME: 1,
  SHUTDOWN_STEP_VALUE: 0.5
}

export default defineComponent({
  name: "AutoShutdownRoot",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const popupOpen = Utils.vModel(props, ctx.emit)
    const workspaceModule = WorkspaceModule()
    const organizationModule = OrganizationModule()
    const loader = Loader()
    const t = createTranslationModule("TriCaster.autoShutdownPopup.")

    const isSaaSAccount = computed(() => {
      return organizationModule.organizationType === "SaaS"
    })

    const shutdownSettings = computed(() => {
      return workspaceModule.shutdownSettings
    })

    const autoShutdownLabel = computed(() => {
      if (shutdownSettings.value.autoShutdownEnabled) {
        const shutdownTime = shutdownSettings.value.shutdownTimerInHours
        const timer = `${shutdownTime}`.includes(".5") ? `${shutdownTime}`.replace(".5", "h 30min") : `${shutdownTime}h`

        return t("shutdownAfter", {
          time: `${timer}`
        })
      } else {
        return t("autoShutdown")
      }
    })

    const isOnline = computed(() => {
      return props.workspace.onlineStatus === WorkspaceOnlineStatusEnum.Online
    })

    const tickLabels = computed(() => {
      const tickCount = []

      for (let x = 1; x < defaults.MAX_SHUTDOWN_TIME + 1; x += 0.5) {
        const suffix = translatePlural("TriCaster.autoShutdownPopup.hours", x)

        if (x === parseInt(`${x}`)) {
          tickCount.push(x + suffix)
        } else {
          tickCount.push("")
        }
      }

      return tickCount
    })

    const saveChanges = () => {
      _saveShutdownSettings()

      setTimeout(() => (popupOpen.value = false), 500)
    }

    const getAutoShutdownSettings = () => {
      loader.run(async () => {
        await workspaceModule.getAutoShutdownSettings(props.workspace.id)
      })
    }

    const _saveShutdownSettings = () => {
      loader.run(async () => {
        await workspaceModule.setShutdownSettings(props.workspace.id, shutdownSettings.value)
      })
    }

    getAutoShutdownSettings()

    loader.run(async () => {
      if (!organizationModule.organizationType) {
        await organizationModule.getOrganizationDetail(props.workspace.organizationId)
      }
    })

    return {
      t,
      isSaaSAccount,
      isOnline,
      saveChanges,
      shutdownSettings,
      tickLabels,
      defaults,
      popupOpen,
      autoShutdownLabel,
      loader
    }
  },
  components: {
    Popup,
    Tooltip
  }
})
</script>
