import buttonCreateRole from "../assets/buttonCreateRole.png"
import agentCreateRoleNoAdmin from "../assets/agent_createRoleNoAdmin.png"
import trustRelationShipDeployAgent from "../assets/trustRelationShip_deployAgent.png"
import codeDeployAgentTrustRelationship from "../assets/codeDeployAgentTrustRelationship.png"
import viewRole from "../assets/viewRole.png"
import createInlinePolicy from "../assets/createInlinePolicy.png"
import permission from "../assets/permission.png"
import policyName from "../assets/policyName.png"
import arn from "../assets/arn.png"
import pasteArnCodeDeployAgent from "../assets/pasteArnCodeDeployAgent.png"
import createRoleVizNowManage from "../assets/createRoleVizNowManage.png"
import viewManageRole from "../assets/viewManageRole.png"
import createInlineButton from "../assets/createInlineButton.png"
import jsonCodeHere from "../assets/jsonCodeHere.png"
import policyNameManage from "../assets/policyNameManage.png"
import copyArnManage from "../assets/copyArnManage.png"
import pasteArnCodeManage from "../assets/pasteArnCodeManage.png"
import createPolicyButton from "../assets/createPolicyButton.png"

const exports = {
  createPolicyButton,
  pasteArnCodeManage,
  copyArnManage,
  policyNameManage,
  jsonCodeHere,
  createInlineButton,
  viewManageRole,
  createRoleVizNowManage,
  pasteArnCodeDeployAgent,
  arn,
  policyName,
  permission,
  createInlinePolicy,
  viewRole,
  codeDeployAgentTrustRelationship,
  trustRelationShipDeployAgent,
  buttonCreateRole,
  agentCreateRoleNoAdmin
}

export default exports
