<template>
  <v-switch
    v-model="isOnline"
    @click.stop
    :loading="isRunningProcess"
    :key="switchRefreshKey"
    :readonly="isRunningProcess"
    :label="$t(`WorkspaceEdit.onlineStatus.${onlineState}`)"
    :disabled="loading"
    class="my-0 py-0"
    hide-details
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum } from "@/types/workspace"

export default defineComponent({
  name: "OnlineSwitch",
  props: {
    onlineState: {
      type: String as PropType<WorkspaceOnlineStatusEnum>,
      default: "offline"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const switchRefreshKey = ref(0)

    const isOnline = computed({
      get() {
        const { Online, Starting } = WorkspaceOnlineStatusEnum
        const onlineStates = [Online, Starting]

        return onlineStates.includes(props.onlineState)
      },
      set(stateOnline: boolean) {
        const state = stateOnline ? "starting" : "stopping"

        switchRefreshKey.value++
        ctx.emit("change", state)
      }
    })

    const isRunningProcess = computed(() => {
      const { Starting, Stopping } = WorkspaceOnlineStatusEnum
      const runningStates = [Starting, Stopping]

      return runningStates.includes(props.onlineState)
    })

    return {
      isOnline,
      isRunningProcess,
      switchRefreshKey,
      ...WorkspaceOnlineStatusEnum
    }
  }
})
</script>
