<template>
  <div id="spaceTemplateInstruction">
    <div class="instructionWrapper">
      <div class="editorWrap">
        <v-label>
          <span>{{ $t("common.instructions") }}</span>
        </v-label>

        <html-editor v-model="instruction" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import HtmlEditor from "@/components/htmlEditor/HtmlEditor.vue"
import Dialog from "@/components/global/Dialog.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "Instructions",
  props: {
    value: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const instruction = Utils.vModel(props, ctx.emit)

    return {
      instruction
    }
  },
  components: {
    Dialog,
    HtmlEditor
  }
})
</script>
