<template>
  <div class="buttonWrapper">
    <button class="windowButton">
      <v-icon :size="20">mdi-window-minimize</v-icon>
    </button>

    <button class="windowButton specialHighlight">
      <v-icon :size="20">mdi-window-close</v-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "WindowsButtons"
})
</script>
