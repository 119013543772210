<template>
  <ul v-if="links.length" class="applicationLinks">
    <li v-for="link in links" :key="link.label">
      <a @click.stop="navigateTo(link.link, link.ipRequired)" href="#" class="dnsItem">
        {{ link.label }}
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType, inject } from "@vue/composition-api"
import { ApplicationLink } from "@/types/workspace"

export default defineComponent({
  name: "Application-Links",
  props: {
    links: {
      type: Array as PropType<ApplicationLink[]>,
      default: () => []
    }
  },
  setup() {
    const validateOwnIp = inject<() => Promise<void>>("validateOwnIp")

    const navigateTo = async (url: string, ipRequired: boolean) => {
      if (ipRequired && validateOwnIp) {
        await validateOwnIp()
      }

      window.open(url, "_blank")
    }

    return {
      navigateTo
    }
  }
})
</script>
