<template>
  <popup v-model="showPopup" @confirm="create" :loading="loading" :title="t('title')">
    <div class="pasteJsonContainer">
      <p>{{ t("youCanDoThis") }}</p>
      <p>{{ t("howToDo") }}</p>

      <div id="monaco" ref="monacoEl"></div>
    </div>
  </popup>
</template>

<script lang="ts">
import type { WorkspaceApplication } from "@/types/asset"
import { defineComponent, ref, nextTick, onMounted } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import Utils from "@/utils/utils"
import Popup from "@/components/popup/PopupRoot.vue"
import * as monaco from "monaco-editor/esm/vs/editor/editor.api"
import "monaco-editor/min/vs/editor/editor.main.css"

export default defineComponent({
  name: "PasteJsonPopup",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const t = createTranslationModule("Admin.assetHandler.rawDataPopup")
    const showPopup = Utils.vModel(props, ctx.emit)
    const json = ref("")
    const monacoEl = ref<HTMLElement>()
    const editor = ref()

    const create = () => {
      const newAsset: WorkspaceApplication = JSON.parse(editor.value.getValue())
      ctx.emit("create", newAsset)
    }

    onMounted(() => {
      nextTick(() => {
        if (monacoEl.value) {
          editor.value = monaco.editor.create(monacoEl.value, {
            value: "{}",
            language: "json",
            theme: "vs-dark",
            renderLineHighlight: "all",
            minimap: {
              enabled: true
            },
            stickyScroll: {
              enabled: false
            }
          })
        } else {
          throw "Couldn't load monaco"
        }
      })
    })

    return {
      t,
      editor,
      create,
      showPopup,
      json,
      monacoEl
    }
  },
  components: {
    Popup
  }
})
</script>
