import { Dictionary } from "vue-router/types/router"
import happy from "@/assets/emoji/happy.png"
import horrible from "@/assets/emoji/horrible.png"
import love from "@/assets/emoji/love.png"
import neutral from "@/assets/emoji/neutral.png"
import sad from "@/assets/emoji/sad.png"

export default {
  happy,
  horrible,
  love,
  neutral,
  sad
} as Dictionary<typeof happy>
