import { UserRoleEnum } from "@/types/user"
import { translate } from "@/plugins/i18n"

const _roles = Object.values(UserRoleEnum)

const userRoleItems = () => {
  return _roles.map(role => {
    return {
      text: translate(`userRoles.${role}`),
      value: role
    }
  })
}

export { userRoleItems }
