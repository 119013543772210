<template>
  <div class="itemRow" :app="app" :readonly="readonly">
    <div class="wrapWizardItemHandler" :has-changes="hasChanges">
      <slot />
    </div>

    <v-expand-transition v-if="service.active && service.documentation">
      <div v-html="service.documentation[$i18n.locale]" class="containDoc animateFadeUp" />
    </v-expand-transition>

    <v-expand-transition v-if="service.active || service.required">
      <div class="animatedDiv">
        <div :has-changes="hasChanges" class="nestedItem animateFadeUp">
          <slot name="child" />
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WizardWidget, WizardSettings } from "@/types/wizard"
import "../scss/_wizard.scss"

export default defineComponent({
  name: "NestedItem",
  props: {
    app: {
      type: String as PropType<WizardWidget>,
      required: true
    },
    service: {
      type: Object as PropType<WizardSettings<WizardWidget>>,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: false
    }
  }
})
</script>
