import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"

const api = new Request("/v1/configuration/")

export default createService("configuration", {
  async getSubscriptionTypes() {
    return await api.get<string[]>("SubscriptionType/")
  },

  async getForbiddenSpaceTags() {
    return await api.get<string[]>("ReservedTagKeys")
  }
})
