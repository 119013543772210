import { Id, SimpleItem } from "@/types/core"

export enum FeedbackType {
  general = "general",
  bug = "bug",
  featureRequest = "featureRequest",
  productRequest = "productRequest",
  notSpecified = "notSpecified"
}

export interface FeedbackPayload {
  id?: Id
  feedbackType: string | FeedbackType | SimpleItem | null
  comment: string
  rate: null | number
  uploadId: string | null
  useEmail: boolean
}

export interface FeedbackItem {
  id: Id
  feedbackType: FeedbackType | null
  comment: string
  rate: null | number
  createdAt: string
  solvedAt: string | null
  solved: boolean
  uploadId: string | null
  email: string | null
  solvedTemp?: boolean
}
