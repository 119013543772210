import WizardCheckbox from "./components/WizardCheckbox.vue"
import WizardSlider from "./components/WizardSlider.vue"
import WizardRadio from "./components/WizardRadio.vue"
import WizardText from "./components/WizardText.vue"
import WizardComboBox from "./components/WizardComboBox.vue"
import WizardIterableText from "./components/WizardIterableText.vue"

export const checkbox = WizardCheckbox
export const slider = WizardSlider
export const radio = WizardRadio
export const text = WizardText
export const comboBox = WizardComboBox
export const iterableText = WizardIterableText
