<template>
  <div class="ipHandler">
    <v-btn @click="showPopup = true" rbt-target="ipHandler" large>
      <v-icon left>vizartist_add_super_channel</v-icon>
      <span>{{ $t("WorkspaceEdit.ipHandler.buttons.open") }}</span>
    </v-btn>

    <popup
      v-model="showPopup"
      :title="$t('WorkspaceEdit.ipHandler.title')"
      :loading="loading"
      hide-footer
      no-padding
      help-box
    >
      <template v-slot:helpBox>
        <div class="helpBoxContent">
          {{ $t("WorkspaceEdit.ipHandler.helpBox") }}
        </div>
      </template>

      <template>
        <div id="ipHandler" class="displayData">
          <div class="ipCollection collection" rbt-target="ipCollection">
            <div v-for="(user, index) in allowedIps" :key="`${user.ip}-${index}`" class="ipItem collectionItem">
              <div class="labels">
                <span class="ip">{{ user.ip }}</span>
                <span class="separator">-</span>
                <span class="description">{{ user.description }}</span>

                <span v-if="isOwnIp(user.ip)" class="ownIp highlighted">
                  ( {{ $t("WorkspaceEdit.ipHandler.label.ownIp") }} )
                </span>
              </div>

              <v-btn v-if="hasEditorPermissions" @click="removeIp(user)" rbt-target="removeIp" icon>
                <v-icon>action_trash</v-icon>
              </v-btn>
            </div>
          </div>

          <div v-if="hasEditorPermissions || ownIpNotIncluded" class="addIp formContainer">
            <v-form v-model="formValue" v-if="hasEditorPermissions" ref="Form" @submit.prevent>
              <v-text-field
                v-model="newIp.ip"
                @keypress.enter="addIp"
                :rules="IPRule"
                :label="$t('WorkspaceEdit.ipHandler.input.ip')"
                :disabled="loading"
                rbt-target="ipInput"
              />

              <v-text-field
                v-model="newIp.description"
                @keypress.enter="addIp"
                :rules="MandatoryTextField"
                :disabled="loading"
                :label="$t('WorkspaceEdit.ipHandler.input.description')"
                rbt-target="ipDescription"
              />

              <v-btn
                @click="addIp"
                :disabled="loading || loader.on || !formValue"
                color="primary"
                rbt-target="addIp"
                large
              >
                <v-icon left>action_add</v-icon>
                <span>{{ $t("WorkspaceEdit.ipHandler.buttons.addIp") }}</span>
              </v-btn>
            </v-form>

            <v-btn v-if="ownIpNotIncluded" @click="addOwnIp" :disabled="loading" rbt-target="addOwnIp" large>
              <v-icon left>action_settings</v-icon>
              <span>{{ $t("WorkspaceEdit.ipHandler.buttons.addOwnIp") }}</span>
            </v-btn>
          </div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, nextTick, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { AdminModule } from "@/store/admin"
import { MandatoryUniqueIP, MandatoryTextField } from "@/utils/input-rules"
import { UsersModule } from "@/store/users"
import { VForm, RegisteredIp } from "@/types/core"
import Loader from "@/utils/loader"
import Popup from "@/components/popup/PopupRoot.vue"

export default defineComponent({
  name: "IpHandler",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const newIp = reactive<RegisteredIp>({ ip: "", description: "" })
    const showPopup = ref(false)
    const ownIp = ref("")
    const Form = ref<null | VForm>(null)
    const loader = Loader()
    const formValue = ref(true)

    const allowedIps = computed(() => {
      return props.workspace.authorizedIps
    })

    const ownIpNotIncluded = computed(() => {
      const ownIpFromCollection = allowedIps.value.find(ipProp => ipProp.ip === ownIp.value)

      return Boolean(!ownIpFromCollection)
    })

    const hasEditorPermissions = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const IPRule = [
      (value: string) => {
        const ips = allowedIps.value.map(IPDetails => IPDetails.ip)
        return MandatoryUniqueIP(value, ips)
      }
    ]

    const addOwnIp = () => {
      newIp.ip = ownIp.value
      newIp.description = usersModule.selfDetail.email

      nextTick(addIp)
    }

    const isOwnIp = (ip: string) => {
      return ownIp.value === ip
    }

    const addIp = () => {
      if (Form.value && Form.value?.validate()) {
        ctx.emit("add:ip", { ...newIp })

        Form.value.reset()
        Form.value.resetValidation()
      }
    }

    const removeIp = (ipProp: RegisteredIp) => {
      ctx.emit("remove:ip", ipProp)
    }

    loader.run(async () => {
      const myIp = await adminModule.getOwnIp()
      ownIp.value = myIp
    })

    return {
      formValue,
      MandatoryTextField,
      MandatoryUniqueIP,
      IPRule,
      Form,
      addOwnIp,
      ownIpNotIncluded,
      hasEditorPermissions,
      removeIp,
      addIp,
      isOwnIp,
      newIp,
      allowedIps,
      loader,
      showPopup
    }
  },
  components: {
    Popup
  }
})
</script>
