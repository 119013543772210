<template>
  <div class="resetFilterHandler animateFadeUp">
    <p>{{ $t("MyWorkspaces.noCorrespondingFilterMatch") }}</p>

    <v-btn @click="$emit('click')" large>
      <span>{{ $t("MyWorkspaces.filterButtons.resetFilter") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "ResetFilterHandler"
})
</script>
