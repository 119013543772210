import { defineStore } from "pinia"
import { Users, ChangeLog, SystemMessage, Documentation } from "@/services"
import { IUser, UserRoleEnum } from "@/types/user"
import { SystemMessage as SystemMsg } from "@/types/messages"
import { DocumentationItem } from "@/types/documentation"
import * as OrgHelper from "@/utils/organization-helper"

const { SysAdmin, Admin, Support, User, ServiceUser } = UserRoleEnum

export const UsersModule = defineStore("users", {
  state: () => ({
    selfDetail: {} as IUser,
    selectedOrganizationId: "",
    changeLog: "",
    previewSystemMessage: false,
    documentationLinks: [] as DocumentationItem[],
    systemMessage: {
      message: "",
      active: false
    } as SystemMsg
  }),
  getters: {
    hasGodPermissions: state => {
      const currentRole = state.selfDetail.role
      return [SysAdmin].includes(currentRole)
    },
    hasSupportPermissions: state => {
      const currentRole = state.selfDetail.role
      return [SysAdmin, Support].includes(currentRole)
    },
    hasAdminPermissions: state => {
      const currentRole = state.selfDetail.role
      return [SysAdmin, Support, Admin].includes(currentRole)
    },
    hasEditorPermissions: state => {
      const currentRole = state.selfDetail.role
      return [SysAdmin, Support, Admin, User].includes(currentRole)
    },
    hasReadonlyPermissions: state => {
      const currentRole = state.selfDetail.role
      return [SysAdmin, Support, Admin, User, ServiceUser].includes(currentRole)
    },
    pendingChangeLogs: state => {
      if (state.selfDetail) {
        return !state.selfDetail?.changeLogViewed ?? false
      }

      return false
    },
    pendingAcceptPrivacyPolicy: state => {
      if (state?.selfDetail) {
        return !state.selfDetail?.acceptedTerms ?? false
      }

      return false
    }
  },
  actions: {
    async getMyDetails(action: "forced" | "default" = "default"): Promise<IUser> {
      if (!this.selfDetail.id || action === "forced") {
        const req = await Users.myDetails()

        this.selfDetail = req.data.data

        if (!this.selectedOrganizationId) {
          this.selectedOrganizationId = this.selfDetail.organization.id
        }
      }

      return this.selfDetail
    },

    async getDocumentationLinks() {
      const req = await Documentation.getDocumentationLinks()
      this.documentationLinks = req.data.data
    },

    async getSystemMessage(): Promise<void> {
      const req = await SystemMessage.getSystemMessage()
      this.systemMessage = { ...req.data.data }
    },

    async getChangeLogs(): Promise<void> {
      const req = await ChangeLog.getCurrentChangeLogs()
      this.changeLog = req.data.data.changeLog
    },

    async changeLogViewed(): Promise<void> {
      await ChangeLog.viewed()
    },

    async acceptTerms(): Promise<void> {
      await Users.acceptTerms()
      await this.getMyDetails("forced")
    },

    async updateOrganizationID(): Promise<string> {
      const cache = OrgHelper.recoverCachedOrganization()

      if (cache && cache.organizationId) {
        this.selectedOrganizationId = cache.organizationId
      }

      return this.selectedOrganizationId
    },

    async switchOrganization(organizationId: string): Promise<void> {
      if (this.hasSupportPermissions) {
        OrgHelper.cacheInOrganization(organizationId)

        if (this.selfDetail.id) {
          await Users.updateOrganization(this.selfDetail.id, organizationId)
          await this.getMyDetails("forced")
        }

        this.updateOrganizationID()
      }
    },

    clearCachedOrganization() {
      OrgHelper.cleanCachedOrganization()
      this.selectedOrganizationId = ""
    }
  }
})
