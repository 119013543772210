<template>
  <popup v-model="isOpen" :title="$t('WorkspaceEdit.fileTransfer.title')" width="60vw" hide-footer no-padding>
    <div id="fileTransfer">
      <div class="mainContentWrapper animateFadeUp">
        <v-form v-model="fileTransferForm" ref="Form">
          <directory-handler v-model="folderConfig" :workspace-name="workspaceName" />
        </v-form>
      </div>

      <controller @close="isOpen = false" @download="setFileTransferConfig" :can-download="canDownload" />
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import { FileTransferConfig } from "@/types/fileTransfer"
import { VForm } from "@/types/core"
import Popup from "@/components/popup/PopupRoot.vue"
import Utils from "@/utils/utils"
import DirectoryHandler from "./components/DirectoryHandler.vue"
import Controller from "./components/Controller.vue"
import "./scss/_fileTransfer.scss"

export default defineComponent({
  name: "FileTransfer",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const folderConfig = ref<FileTransferConfig>({ download: "", upload: "" })
    const isOpen = Utils.vModel(props, ctx.emit)
    const workspaceModule = WorkspaceModule()
    const Form = ref<null | VForm>(null)
    const fileTransferForm = ref(false)

    const canDownload = computed(() => {
      const { download, upload } = folderConfig.value
      return fileTransferForm.value && download !== upload
    })

    const workspaceName = computed(() => {
      return workspaceModule.watching.title
    })

    const setFileTransferConfig = () => {
      ctx.emit("set:file-transfer", folderConfig.value)
    }

    return {
      canDownload,
      folderConfig,
      fileTransferForm,
      Form,
      workspaceName,
      setFileTransferConfig,
      isOpen
    }
  },
  components: {
    DirectoryHandler,
    Popup,
    Controller
  }
})
</script>
