import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { PlatformAsset, WorkspaceApplication } from "@/types/asset"

const api = new Request("/v1/Assets/")

export default createService("assets", {
  async getPlatformAssets() {
    return await api.get<PlatformAsset[]>("")
  },

  async getPlatformAssetById(assetId: string) {
    return await api.get<WorkspaceApplication>(`${assetId}`)
  },

  async delete(assetId: string) {
    return await api.delete(`${assetId}`)
  },

  async create(assetDetails: WorkspaceApplication) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...asset } = assetDetails
    return await api.post<WorkspaceApplication>("", asset)
  },

  async edit(assetDetails: WorkspaceApplication) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...asset } = assetDetails
    return await api.put(`${assetDetails.id}`, asset)
  }
})
