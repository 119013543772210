<template>
  <img v-if="logo" :src="src" :alt="alt" class="scaledImage animateFadeUp" />
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { IMedia } from "@/types/media"
import { UploadModule } from "@/store/upload"
import { Context } from "@/types/core"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "ScaledImage",
  props: {
    alt: {
      type: String,
      default: ""
    },
    mediaContext: {
      type: String as PropType<Context>,
      required: true
    },
    mediaContextItemId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const uploadStore = UploadModule()
    const uploads = ref<IMedia[]>([])
    const loader = Loader()

    const backgroundStyle = computed(() => {
      return {
        backgroundImage: `url(${src.value})`
      }
    })

    const logo = computed(() => {
      const media = uploads.value

      if (media && media.slice(-1).length) {
        return media[0]
      }

      return false
    })

    const src = computed(() => {
      if (logo.value && logo.value?.contentsBase64) {
        const mimeType = `data:${logo.value.mimeType};base64,`

        if (logo.value.contentsBase64.includes(mimeType)) {
          return logo.value.contentsBase64
        } else {
          return mimeType + logo.value.contentsBase64
        }
      }

      return false
    })

    loader.run(async () => {
      const req = await uploadStore.getContextId({
        contextId: props.mediaContextItemId,
        context: props.mediaContext,
        ignoreExhibition: false
      })

      uploads.value = req
    })

    return {
      logo,
      src,
      backgroundStyle
    }
  }
})
</script>
