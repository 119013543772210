import { UserRoleEnum, CreateUserPayload } from "@/types/user"

export interface User {
  firstName?: string
  lastName?: string
  email?: string
  role?: UserRoleEnum
}

const DEFAULTS = {
  userData: {
    firstName: "",
    lastName: "",
    email: "",
    role: UserRoleEnum.ServiceUser
  } as CreateUserPayload
}

const NewUser = function (userData: User = {}): User {
  return {
    ...DEFAULTS.userData,
    ...userData
  }
}

export { NewUser }
