<template>
  <div id="workspaceNotFound" class="backgroundWrapper">
    <h3>{{ $t("WorkspaceEdit.notFound.title") }}</h3>
    <p>{{ $t("WorkspaceEdit.notFound.subtitle") }}</p>

    <v-btn @click="goToWorkspaceList" color="primary" class="mt-3" large>
      <span>{{ $t("WorkspaceEdit.notFound.navigateToSpaceList") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { navigate } from "@/router"

export default defineComponent({
  name: "WorkspaceNotFound",
  setup() {
    const goToWorkspaceList = () => {
      navigate({ name: "WorkspacesList" })
    }

    return {
      goToWorkspaceList
    }
  }
})
</script>
