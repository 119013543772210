<template>
  <div class="customSelectContainer">
    <div class="customSelect">
      <span v-if="Boolean(selectValue)" class="handler">{{ selectValue }}</span>
      <span v-else class="noValue">{{ t("noValueSelected") }}</span>

      <button @click="setValue" :status="inputStatus" :disabled="loading" class="iconEnter">
        <v-icon>mdi-check</v-icon>
      </button>
    </div>

    <tag-list v-model="selectValue" @enter="handleEnterPressed" :items="items" :used-tags="[]" />
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, computed } from "@vue/composition-api"
import { InputStatus } from "../lib/type"
import { createTranslationModule } from "@/plugins/i18n"
import { MandatorySelect } from "@/utils/input-rules"
import TagList from "./_TagList.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "CustomSelect",
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const t = createTranslationModule("TagPill.")
    const selectValue = Utils.vModel(props, ctx.emit)

    const selectIsValid = computed(() => {
      if (props.mandatory) {
        return MandatorySelect[0](selectValue.value) === true
      }

      return true
    })

    const inputStatus = computed(() => {
      if (props.mandatory) {
        return selectIsValid.value ? InputStatus.SUCCESS : InputStatus.ERROR
      }

      return InputStatus.SUCCESS
    })

    const setValue = () => {
      if (inputStatus.value === InputStatus.SUCCESS) {
        ctx.emit("change", selectValue.value)
      }
    }

    const handleEnterPressed = () => {
      nextTick(() => {
        ctx.emit("change", selectValue.value)
      })
    }

    return {
      t,
      handleEnterPressed,
      setValue,
      inputStatus,
      selectValue
    }
  },
  components: {
    TagList
  }
})
</script>
