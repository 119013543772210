<template>
  <div class="statusLabel" :state="statusState" :online-state="onlineStatus" :running="isRunning">
    <span class="status">
      {{ translatedStatus }}
    </span>

    <span v-if="isDeployed && isOnline"> {{ appCount }} </span>

    <span v-if="isDeployed" class="onlineStatus">
      {{ translatedOnlineStatus }}
    </span>

    <span v-if="isDeployed && isOnline && runningSince"> <running-for :running-since="runningSince" /> </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceStatusEnum, WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { translate } from "@/plugins/i18n"
import RunningFor from "@/views/workspaceEdit/components/shared/RunningFor.vue"

export default defineComponent({
  name: "StatusLabel",
  props: {
    statusState: {
      type: String as PropType<WorkspaceStatusEnum>,
      required: true
    },
    isRunning: {
      type: Boolean,
      required: true
    },
    runningSince: {
      type: String,
      default: false
    },
    isDeployed: {
      type: Boolean,
      required: true
    },
    onlineStatus: {
      type: String as PropType<WorkspaceOnlineStatusEnum>,
      default: false
    },
    appsOnline: {
      type: Number,
      required: true
    },
    appsDeployed: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const isOnline = computed(() => {
      const { Online } = WorkspaceOnlineStatusEnum
      return props.onlineStatus === Online
    })

    const translatedStatus = computed(() => {
      const status = props.statusState
      return translate(`workspaceStatus.${status}`)
    })

    const appCount = computed(() => {
      return `${props.appsOnline}/${props.appsDeployed}`
    })

    const translatedOnlineStatus = computed(() => {
      const onlineStatus = props.onlineStatus
      return translate(`workspaceOnlineStatus.${onlineStatus}`)
    })

    return {
      appCount,
      isOnline,
      translatedOnlineStatus,
      translatedStatus
    }
  },
  components: {
    RunningFor
  }
})
</script>
