<template>
  <div id="largePresentation">
    <div class="contentContainer">
      <div class="contentWrapper">
        <h2 v-if="appGoingOnline" :class="appGoingOnline ? 'animateFadeUp' : ''">
          {{ $t("TriCaster.title", { userFullName: userName, appName: app.description }).trim() }}
        </h2>

        <presentation-box
          @set:status="status => $emit('set:status', status)"
          @open:dcv="$emit('open:dcv')"
          @archive="$emit('archive')"
          @restore="$emit('restore')"
          @deploy="$emit('deploy')"
          @open:ndi-bridge="$emit('open:ndi-bridge')"
          @open:nice-dcv="$emit('open:nice-dcv')"
          @open:both="$emit('open:both')"
          @open:ip-config="$emit('open:ip-config')"
          @open:auto-shutdown="$emit('open:auto-shutdown')"
          @cancel:shutdown="$emit('cancel:shutdown')"
          @postpone:shutdown="$emit('postpone:shutdown')"
          :loading="loading"
          :workspace="workspace"
          :app="app"
          :is-archiving="isArchiving"
        />

        <average-time v-if="bottomFeedback" :key="bottomFeedback" :feedback="bottomFeedback" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, provide, PropType } from "@vue/composition-api"
import { IWorkspaceAsset, WorkspaceTemplate, WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { UsersModule } from "@/store"
import { feedbacksBasedOnWorkspaceStatus } from "./lib/step-feedbacks"
import { workspaceFailed } from "@/config/workspace-states"
import FixedTopBar from "./components/FixedTopBar.vue"
import PresentationBox from "./components/PresentationBox.vue"
import AverageTime from "./components/AverageTime.vue"

export default defineComponent({
  name: "LargePresentation",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    isArchiving: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const usersModule = UsersModule()
    provide("app", props.app)

    const bottomFeedback = computed(() => {
      return feedbacksBasedOnWorkspaceStatus(props.workspace.status, props.app.onlineStatus)
    })

    const userName = computed(() => {
      const { firstName, lastName } = usersModule.selfDetail

      return `${firstName} ${lastName}`.trim()
    })

    const appGoingOnline = computed(() => {
      const { Starting } = WorkspaceOnlineStatusEnum
      return props.app.onlineStatus === Starting
    })

    const hasError = computed(() => {
      return workspaceFailed(props.workspace.status)
    })

    return {
      hasError,
      bottomFeedback,
      appGoingOnline,
      userName
    }
  },
  components: {
    AverageTime,
    FixedTopBar,
    PresentationBox
  }
})
</script>
