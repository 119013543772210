<template>
  <div class="stepController">
    <v-btn @click="$emit('close')" large>{{ $t("WorkspaceEdit.fileTransfer.controller.cancel") }}</v-btn>

    <tooltip
      :text="$t('WorkspaceEdit.fileTransfer.controller.downloadTooltip')"
      :disabled="canDownload"
      :max-width="300"
    >
      <v-btn @click="$emit('download')" color="primary" :disabled="!canDownload" large>
        {{ $t("WorkspaceEdit.fileTransfer.controller.downloadFileTransfer") }}
      </v-btn>
    </tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "StepController",
  emits: ["close", "download"],
  props: {
    canDownload: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Tooltip
  }
})
</script>
