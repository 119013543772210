import { translate } from "@/plugins/i18n"
import { HeaderItem } from "@/types/core"

const headers: () => HeaderItem[] = () => {
  return [
    {
      text: translate("Admin.deployPackage.tableHeader.name"),
      align: "start",
      value: "name",
      sortable: true
    },
    {
      text: translate("Admin.deployPackage.tableHeader.description"),
      align: "start",
      value: "description",
      sortable: false
    },
    {
      text: translate("Admin.deployPackage.tableHeader.version"),
      align: "start",
      value: "currentVersion",
      sortable: true
    },
    {
      text: translate("Admin.deployPackage.tableHeader.action"),
      align: "start",
      value: "action",
      sortable: false
    }
  ]
}

export default headers
