<template>
  <div class="paginationHandler">
    <span>{{ pageLabel }}</span>

    <div class="group">
      <v-btn @click="pagination.previous()" :disabled="!pagination.hasPreviousPage" icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn @click="pagination.next()" :disabled="!pagination.hasNextPage" icon>
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { Pagination } from "@/utils/pagination"
import "./scss/_paginationHandler.scss"

export default defineComponent({
  name: "PaginationHandler",
  props: {
    pagination: {
      type: Object as PropType<Pagination>,
      required: true
    }
  },
  setup(props) {
    const pageLabel = computed(() => {
      return `${props.pagination.page} / ${props.pagination.lastPage}`
    })

    return {
      pageLabel
    }
  }
})
</script>
