<template>
  <div id="ipRangeHandler">
    <div class="wrapInput">
      <div class="inputLabel">
        <span>
          <span>{{ $t("Onboarding.stepOne.label.cidr") }}</span>

          <help-box :text="$t('Onboarding.stepOne.helpBox.cidr')">
            <v-icon size="16">mdi-help-circle-outline</v-icon>
          </help-box>
        </span>

        <div class="ipInputWithSuffix">
          <v-text-field v-model="blockedIpRange.ip" :rules="rules" :placeholder="'172.16.0.0'" dense />

          <tooltip :text="$t('Onboarding.stepOne.helpBox.ipMask')" bottom>
            <v-select
              v-model="blockedIpRange.mask"
              :items="CIDR_BLOCK_MASKS.masks"
              :menu-props="{ offsetY: true }"
              hide-details
              small
            />
          </tooltip>
        </div>
      </div>

      <p>{{ $t("Onboarding.stepOne.label.cidrMaxRange", { spaceCount: ipRangeMaxCount }) }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { CidrRange } from "@/utils/input-rules"
import { CIDR_BLOCK_MASKS } from "@/config/cidr-block-masks"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"
import HelpBox from "@/components/helpBox/HelpBox.vue"

export default defineComponent({
  name: "IPRangeHandler",
  props: {
    value: {
      type: Object as PropType<{ id: string; mask: string }>,
      required: true
    }
  },
  setup(props, ctx) {
    const blockedIpRange = Utils.vModel(props, ctx.emit)
    const rules = CidrRange

    const ipRangeMaxCount = computed(() => {
      return blockedIpRange.value.mask === CIDR_BLOCK_MASKS.default ? "124" : "16"
    })

    return {
      CIDR_BLOCK_MASKS,
      rules,
      ipRangeMaxCount,
      blockedIpRange
    }
  },
  components: {
    Tooltip,
    HelpBox
  }
})
</script>
