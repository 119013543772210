<template>
  <popup
    v-model="showModal"
    @confirm="confirmCreate"
    :title="$t('OrganizationUsers.createUsers.title')"
    :close-on-click-out="usersBeingCreated.length === 0"
    :hide-footer="hasNoNewUsers"
  >
    <div id="createUserContainer">
      <div
        v-html="$t('OrganizationUsers.counter', { count: usersBeingCreated.length, maxCount: MAX_USERS_PER_CREATION })"
        class="summary"
      />

      <div id="formContainer">
        <v-form ref="Form">
          <div v-if="usersBeingCreated.length" class="newUserContainer">
            <div v-for="(user, index) in usersBeingCreated" :key="`${user.email}-${index}`" class="newUserRow">
              <div class="containChildren">
                <span class="userPersonalData">
                  <span class="userName">{{ user.firstName }} {{ user.lastName }}</span>
                  <span class="email">({{ user.email }})</span>
                </span>

                <span class="separator">-</span>

                <div class="userRole">{{ $t(`OrganizationUsers.roles.${user.role}`) }}</div>
              </div>

              <v-btn @click="removeUserFromCreation(index)" rbt-target="removeNewUserBtn" icon>
                <v-icon>action_trash</v-icon>
              </v-btn>
            </div>
          </div>

          <div class="itemRow">
            <v-text-field
              v-model="newUserData.email"
              :placeholder="$t('OrganizationUsers.userEditor.input.email')"
              :rules="rules.email"
              rbt-target="emailInput"
              hide-details
            />

            <v-text-field
              v-model="newUserData.firstName"
              :placeholder="$t('OrganizationUsers.userEditor.input.firstName')"
              :rules="rules.text"
              rbt-target="firstNameInput"
              hide-details
            />

            <v-text-field
              v-model="newUserData.lastName"
              :placeholder="$t('OrganizationUsers.userEditor.input.lastName')"
              rbt-target="lastNameInput"
              hide-details
            />

            <tooltip :text="roleExplanation(newUserData)">
              <v-select
                v-model="newUserData.role"
                :placeholder="$t('OrganizationUsers.userEditor.input.role')"
                :items="userRoles"
                rbt-target="roleInput"
                hide-details
              />
            </tooltip>

            <v-btn
              @click="addNewUser"
              :disabled="usersBeingCreated.length >= MAX_USERS_PER_CREATION"
              color="primary"
              rbt-target="createUserBtn"
              large
            >
              <v-icon left>action_add</v-icon>
              {{ $t("OrganizationUsers.userEditor.button.addUser") }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </popup>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from "@vue/composition-api"
import { MandatoryTextField, MandatoryEmail } from "@/utils/input-rules"
import { getRoleImportance } from "@/utils/role-importance"
import { userRoleItems } from "../lib/user-roles"
import { NewUser, User } from "../lib/new-user-factory"
import { UserRoleEnum } from "@/types/user"
import { UsersModule } from "@/store/users"
import { translate } from "@/plugins/i18n"
import { VForm } from "@/types/core"
import Popup from "@/components/popup/PopupRoot.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "CreateUsers",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const showModal = Utils.vModel(props, ctx.emit)
    const usersModule = UsersModule()
    const usersBeingCreated = ref([] as User[])
    const MAX_USERS_PER_CREATION = 2
    const Form = ref<null | VForm>(null)
    const newUserData = ref(NewUser())

    const rules = {
      text: MandatoryTextField,
      email: MandatoryEmail
    }

    const hasNoNewUsers = computed(() => {
      return usersBeingCreated.value.length === 0
    })

    const userRoles = computed(() => {
      const currentRoleLevel = getRoleImportance(usersModule.selfDetail.role)

      return userRoleItems().filter(roleItem => {
        const { hasGodPermissions, hasSupportPermissions } = usersModule

        if (hasGodPermissions) {
          return true
        } else if (hasSupportPermissions) {
          return roleItem.value !== UserRoleEnum.SysAdmin
        } else {
          return currentRoleLevel >= getRoleImportance(roleItem.value)
        }
      })
    })

    const roleExplanation = (user: User) => {
      return translate(`OrganizationUsers.roleExplanation.${user.role}`)
    }

    const _validateDuplicatedEmail = () => {
      const hasDuplicatedEmail = usersBeingCreated.value.some(createdUser => {
        return Utils.filterCompare(createdUser.email, newUserData.value.email)
      })

      if (hasDuplicatedEmail) {
        newUserData.value.email = ""
      }

      return !hasDuplicatedEmail
    }

    const addNewUser = () => {
      const form = Utils.isType<VForm>(Form.value, "validate")

      if (form && form.validate() && _validateDuplicatedEmail()) {
        usersBeingCreated.value.push({ ...newUserData.value })
        newUserData.value = NewUser()
        form.resetValidation()
      }
    }

    const removeUserFromCreation = (index: number) => {
      usersBeingCreated.value.splice(index, 1)
    }

    const confirmCreate = () => {
      if (usersBeingCreated.value.length > 0) {
        ctx.emit("create", usersBeingCreated.value)
      }

      showModal.value = false
    }

    return {
      Form,
      roleExplanation,
      rules,
      userRoles,
      newUserData,
      confirmCreate,
      MAX_USERS_PER_CREATION,
      usersBeingCreated,
      removeUserFromCreation,
      hasNoNewUsers,
      addNewUser,
      showModal
    }
  },
  components: {
    Tooltip,
    Popup
  }
})
</script>
