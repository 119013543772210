<template>
  <ul class="appOptions">
    <li v-for="(app, index) in apps" :key="app.key" :class="`animateFadeUp animationDelay-${index}`">
      <app-iterable-item @click="$emit('click', app)" :app="app" :loading="loading" />
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { AppItem } from "@/types/workspace"
import AppIterableItem from "./AppIterableItem.vue"

export default defineComponent({
  name: "AppOptions",
  props: {
    apps: {
      type: Array as PropType<AppItem[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    AppIterableItem
  }
})
</script>
