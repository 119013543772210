<template>
  <div class="alertErrorType">
    <generic-alert
      @click="$emit('cancel')"
      icon="action_remove_box"
      :action="$t('WorkspaceEdit.alerts.buttons.unlockWorkspace')"
      :disabled="disabled"
      type="error"
    >
      <p>
        {{ feedbackMsg }}
      </p>
    </generic-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { translate } from "@/plugins/i18n"
import GenericAlert from "./GenericAlert.vue"

export default defineComponent({
  name: "ErrorAlert",
  props: {
    feedback: {
      type: String,
      default: translate("WorkspaceEdit.alert.defaultUnlockError")
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const feedbackMsg = computed(() => {
      return props.feedback ?? translate("WorkspaceEdit.alert.defaultUnlockError")
    })

    return {
      feedbackMsg
    }
  },
  components: {
    GenericAlert
  }
})
</script>
