<template>
  <div class="tagPill previewPill">
    <string-max class="tagName" :text="name" :max="MAX_TAG_VALUE_VIEW" />
    <span v-if="!justName" class="tagValue">Set tag value</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { MAX_TAG_VALUE_VIEW } from "../../tagSearchField/lib/max-character"
import StringMax from "@/components/stringMax"

export default defineComponent({
  name: "PreviewPill",
  props: {
    name: {
      type: String,
      required: true
    },
    justName: {
      type: Boolean,
      default: false
    }
  },
  setup(_, ctx) {
    const cancelPillCreation = () => {
      ctx.emit("cancel")
    }

    return {
      MAX_TAG_VALUE_VIEW,
      cancelPillCreation
    }
  },
  components: {
    StringMax
  }
})
</script>
