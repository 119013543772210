import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"deployPackageVersionList"}},[_vm._l((_vm.items),function(deploy,index){return _c('div',{key:((deploy.id) + "-" + (_vm.items.length)),staticClass:"versionItem animateFadeUp",class:("delay-" + index)},[_c('span',{staticClass:"version"},[(deploy.version)?_c(VChip,{attrs:{"color":_vm.chipColor(deploy.version),"small":""}},[_vm._v("v"+_vm._s(deploy.version))]):_c('small',[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionList.empty")))])],1),_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.lastModified(deploy.date))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.fileSize(deploy.size))+" Kb ")]),_c('span',[(deploy.terraformVersion)?_c('span',[_vm._v(_vm._s(deploy.terraformVersion))]):_c('small',{staticClass:"empty"},[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionList.noTerraformVersion")))])]),_c('span',[_c('span',[_vm._v(_vm._s(_vm.getPrettyServiceVersion(deploy.serviceVersion)))])]),_c('span',{staticClass:"description"},[(deploy.description)?_c('span',[_vm._v(_vm._s(deploy.description))]):_c('small',[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionList.noDescription")))])]),_c('span',[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("navigate_more_options_vertical")])],1)]}}],null,true)},[_c('div',{staticClass:"listMenu"},[_c('div',{staticClass:"listRow",attrs:{"disabled":!!_vm.chipColor(deploy.version)},on:{"click":function($event){return _vm.$emit('set:current', deploy.id, deploy.deployPackageId)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("content_campaign")]),_vm._v(" "+_vm._s(_vm.$t("Admin.deployPackage.versionList.setCurrentVersion"))+" ")],1),_c('div',{staticClass:"listRow",on:{"click":function($event){return _vm.$emit('download', deploy.id)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_download_or_import")]),_vm._v(" "+_vm._s(_vm.$t("Admin.deployPackage.versionList.downloadTemplate"))+" ")],1)])])],1)])}),(_vm.items.length === 0)?_c('div',{staticClass:"emptyText"},[_c('small',[_vm._v(_vm._s(_vm.$t("Admin.deployPackage.versionList.noVersionsYet")))])]):_vm._e(),_c('div',{staticClass:"paginationController"},[_c('span',[_vm._v(_vm._s(_vm.pageLabel))]),_c('div',{staticClass:"group"},[_c(VBtn,{attrs:{"disabled":!_vm.paginatedVersions.hasPreviousPage,"icon":""},on:{"click":function($event){return _vm.paginatedVersions.previous()}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{attrs:{"disabled":!_vm.paginatedVersions.hasNextPage,"icon":""},on:{"click":function($event){return _vm.paginatedVersions.next()}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }