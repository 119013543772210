<template>
  <v-data-table
    @click:row="redirectToWorkspace"
    @pagination="paginationChange"
    :headers="headers"
    :items="workspaces"
    :sort-by.sync="sortConfig.sortBy"
    :sort-desc.sync="sortConfig.sortDesc"
    :custom-sort="customSort"
    :itemsPerPage="itemsPerPage"
    :disable-pagination="!showPagination"
    :hide-default-footer="!showPagination"
    class="workspaces-table"
  >
    <template v-slot:[`item.title`]="{ item: w }">
      <div class="d-flex table-column-title workspaceName">
        <workspace-status-image :workspace="w" />

        <div style="max-width: calc(100% - 62px)">
          <div class="d-flex justify-start">
            <h5 class="text-nowrap">{{ w.title }}</h5>

            <v-icon v-if="w.isShared" left v-tippy="{ content: $t('common.shared') }" class="ml-1">
              mdi-share-variant
            </v-icon>

            <v-icon v-if="errorIcon(w)">{{ errorIcon(w) }}</v-icon>
          </div>

          <div>
            <p class="body-1" style="color: var(--viz-grey-2)">
              <span class="description">{{ w.description }}</span>
            </p>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:[`item.tags`]="{ item: workspace }">
      <div
        v-if="getTags(workspace).tags.length > 0"
        class="d-flex justify-start flex-wrap gap-3 mt-1"
        style="row-gap: 8px"
      >
        <v-chip v-for="tag in availableTags(workspace)" :key="tag" :value="tag">{{ tag }}</v-chip>

        <span v-if="getTags(workspace).tags.length > DISPLAY_TAG_LIMIT">
          <tooltip
            :text="
              $tc('workspaces.appDetails.tooltip.hiddenTags', hiddenTagsCount(workspace), {
                count: hiddenTagsCount(workspace)
              })
            "
          >
            <span>...</span>
          </tooltip>
        </span>
      </div>

      <div v-else class="noTags">
        <span>{{ $t("WorkspaceWizard.labels.emptyTags") }}</span>
      </div>
    </template>

    <template v-slot:[`item.username`]="{ item: w }">
      <span>{{ w.username }}</span>
    </template>

    <template v-slot:[`item.date`]="{ item: w }">
      <div class="dateWrapper">
        <span v-if="lastEditDate(w)">{{ lastEditDate(w) }}</span>

        <span v-if="lastEditedByUser(w)" class="editedBy">
          {{ $t("WorkspaceEdit.metadata.lastEditedBy") }} {{ lastEditedByUser(w) }}
        </span>
      </div>
    </template>

    <template v-slot:[`item.status`]="{ item: w }">
      <online-switch :workspace="w" grid-view="table" />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { localStorageRef } from "@/utils/ui"
import { WorkspaceTemplate, WorkspaceListItem, SortingConfig, WorkspaceErrorLevelEnum } from "@/types/workspace"
import { navigate } from "@/router"
import { workspaceSort } from "../../lib/sorting"
import { WorkspaceModule } from "@/store/workspace"
import WorkspaceStatusImage from "./WorkspaceStatusImage.vue"
import OnlineSwitch from "../shared/OnlineSwitchRoot.vue"
import Headers from "../../lib/table-view-headers"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"
import { getTags } from "@/utils/get-space-tags"

export default defineComponent({
  name: "TableView",
  props: {
    workspaces: {
      type: Array as PropType<WorkspaceTemplate[]>,
      default: () => []
    }
  },
  setup(props) {
    const DISPLAY_TAG_LIMIT = 4
    const workspaceModule = WorkspaceModule()
    const headers = Headers()
    const itemsPerPage = localStorageRef("WorkspacesList_itemsPerPage", 10)

    const showPagination = computed(() => {
      return props.workspaces.length > itemsPerPage.value
    })

    const errorIcon = (workspace: WorkspaceTemplate) => {
      const { Error } = WorkspaceErrorLevelEnum
      const error = "status_error"
      const warning = "status_warning"

      if (workspace.lastErrorLevel) {
        return workspace.lastErrorLevel === Error ? error : warning
      }

      return false
    }

    const sortConfig = computed({
      get() {
        return workspaceModule.sortingConfig
      },
      set(value: SortingConfig) {
        workspaceModule.sortingConfig = value
      }
    })

    const lastEditedByUser = (workspace: WorkspaceTemplate) => {
      if (workspace.stateChange) {
        return workspace.stateChange.username
      }

      return false
    }

    const lastEditDate = (workspace: WorkspaceTemplate) => {
      if (workspace.stateChange && workspace.stateChange.changeDate) {
        return Utils.dateFormatWithTime(workspace.stateChange.changeDate)
      } else if (workspace.modifyDate) {
        return Utils.dateFormatWithTime(workspace.modifyDate)
      } else {
        return Utils.dateFormatWithTime(workspace.creationDate)
      }
    }

    const availableTags = (workspace: WorkspaceTemplate) => {
      return getTags(workspace)
        .tags.map(tag => tag.name)
        .slice(0, DISPLAY_TAG_LIMIT)
    }

    const hiddenTagsCount = (workspace: WorkspaceTemplate) => {
      return Math.floor(getTags(workspace).tags.length - availableTags(workspace).length)
    }

    const customSort = (items: WorkspaceTemplate[], sortBy: string[], _sortDesc: boolean[]) => {
      const workspaceList = Utils.assignType<WorkspaceListItem[]>(items)

      if (workspaceList) {
        return workspaceSort(workspaceList, sortBy[0], _sortDesc[0])
      }

      return items
    }

    const redirectToWorkspace = (workspace: WorkspaceTemplate) => {
      navigate({ name: "WorkspaceEdit", params: { id: workspace.id } })
    }

    const paginationChange = (args: { itemsPerPage: number }) => {
      itemsPerPage.value = args.itemsPerPage
    }

    return {
      getTags,
      lastEditDate,
      lastEditedByUser,
      errorIcon,
      headers,
      sortConfig,
      itemsPerPage,
      showPagination,
      paginationChange,
      customSort,
      redirectToWorkspace,
      availableTags,
      hiddenTagsCount,
      DISPLAY_TAG_LIMIT
    }
  },
  components: {
    WorkspaceStatusImage,
    OnlineSwitch,
    Tooltip
  }
})
</script>
