<template>
  <div :class="classes">
    <v-icon class="iconSection">
      {{ icon }}
    </v-icon>
    <div class="descriptionSection">
      <slot name="description">
        {{ description }}
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import "./alert-bar.scss"

type AlertType = "warning" | "info" | "error"

export default defineComponent({
  name: "AlertBar",
  props: {
    type: {
      type: String as PropType<AlertType>,
      default: "info"
    },
    description: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const icon = computed(() => {
      switch (props.type) {
        case "warning":
          return "status_warning"
        case "error":
          return "status_error"
        default:
          return "vizartist_show_scene_info_editor"
      }
    })

    const classes = computed(() => {
      const classes = ["alert-bar"]

      if (props.type === "error") {
        classes.push("--type-error")
      } else if (props.type === "warning") {
        classes.push("--type-warning")
      } else {
        classes.push("--type-info")
      }

      return classes.join(" ")
    })

    return {
      classes,
      icon
    }
  }
})
</script>
