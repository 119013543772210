export enum PingSpeedGraph {
  veryFast = "very-fast",
  fast = "fast",
  slowish = "slowish",
  slow = "slow",
  verySlow = "very-slow"
}

const veryFast = 20
const fast = 80
const slowish = 120
const slow = 200

export const getPingSpeed = (speed: number | undefined) => {
  if (isNaN(Number(speed))) {
    return PingSpeedGraph.verySlow
  } else {
    speed = speed as number

    if (speed <= veryFast) {
      return PingSpeedGraph.veryFast
    } else if (speed <= fast) {
      return PingSpeedGraph.fast
    } else if (speed <= slowish) {
      return PingSpeedGraph.slowish
    } else if (speed <= slow) {
      return PingSpeedGraph.slow
    } else {
      return PingSpeedGraph.verySlow
    }
  }
}
