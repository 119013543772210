import { MandatoryComboBoxFromList, MandatoryTextField, Rule } from "@/utils/input-rules"
import { SelectItem } from "@/types/core"
import { v4 as uuid } from "uuid"

import {
  ComboboxInput,
  DateInput,
  Director,
  InputItem,
  InputType,
  IterableGroup,
  SelectInput,
  TextInput,
  DescriptionInput,
  CheckboxInput,
  StructureCollection
} from "./type"

interface InputConfig<R = string> {
  _id?: string
  type?: InputType
  placeholder?: string
  label?: string
  rules?: Rule<R>[]
  hideDetails?: boolean
  getsDisabled?: boolean
  grow?: boolean
  loads?: boolean
}

interface IterableConfig {
  _id?: string
  key?: string
  label?: string
  type?: InputType.ITERABLE
  children?: InputItem[]
  isCollectionStructure?: boolean
  grow?: boolean
  containerClass?: string
}

interface SelectInputConfig<T> extends InputConfig {
  items?: T[]
}

interface ComboboxInputConfig<T> extends InputConfig {
  items?: T[]
}

const Config = {
  TEXT: {
    placeholder: "",
    label: "",
    rules: MandatoryTextField,
    hideDetails: true,
    getDisabled: true,
    grow: false,
    loads: false,
    mandatory: true
  } as InputConfig,
  CHECKBOX: {
    placeholder: "",
    label: "",
    rules: [],
    hideDetails: true,
    getDisabled: true,
    grow: false,
    loads: false
  } as InputConfig,
  DESCRIPTION: {
    placeholder: "",
    label: "",
    rules: MandatoryTextField,
    hideDetails: true,
    getDisabled: true,
    grow: false,
    loads: false
  } as InputConfig,
  DATE: {
    placeholder: "",
    label: "",
    rules: MandatoryTextField,
    hideDetails: true,
    getDisabled: true,
    grow: false,
    loads: false
  } as InputConfig,
  SELECT: {
    items: [],
    placeholder: "",
    label: "",
    rules: MandatoryTextField,
    hideDetails: true,
    getDisabled: true,
    grow: false,
    loads: false
  } as SelectInputConfig<string[] | SelectItem<string>[]>,
  COMBOBOX: {
    items: [],
    placeholder: "",
    label: "",
    rules: MandatoryTextField,
    hideDetails: true,
    getDisabled: true,
    grow: false,
    loads: false
  } as ComboboxInputConfig<string[] | SelectItem<string>[]>,
  ITERABLE: {
    label: "",
    isCollectionStructure: false,
    type: InputType.ITERABLE
  }
}

/**
 *
 * @param key Used to generate a key value structure
 * @param value Predefined input value, if blank this should be an empty string
 * @example
 *
 * TextComponent("userName", "Leo", {
 *   placeholder: "Type the user's name",
 *   label: "User name",
 *   label: "$tUser.userName", // translates using snippet User.userName
 * // Default config --
 *   rules: MandatoryTextField,2
 *   hideDetails: true,
 *   getsDisabled: true,
 *   grow: false,
 *   loads: false
 * })
 */
export const TextComponent = (key: string, value: string, config = {} as InputConfig): TextInput => {
  return {
    ...Config.TEXT,
    ...config,
    _id: uuid(),
    type: InputType.TEXT,
    value,
    key
  } as TextInput
}

/**
 *
 * @param key Used to generate a key value structure
 * @param value Predefined input value, if blank this should be an empty string
 * @example
 *
 * DescriptionComponent("description", "Lorem description", {
 *   placeholder: "Type some description here",
 *   label: "Description",
 *   label: "$tUser.userName", // translates using snippet User.userName
 * // Default config --
 *   rules: MandatoryTextField,
 *   hideDetails: true,
 *   getsDisabled: true,
 *   grow: false,
 *   loads: false
 * })
 */
export const DescriptionComponent = (key: string, value: string, config = {} as InputConfig): DescriptionInput => {
  return {
    ...Config.DESCRIPTION,
    ...config,
    _id: uuid(),
    type: InputType.DESCRIPTION,
    value,
    key
  } as DescriptionInput
}

/**
 *
 * @param key Used to generate a key value structure
 * @param value Predefined input value, if blank this should be an empty string
 * @example
 *
 * TextComponent("userName", "Leo", {
 *   placeholder: "Type the user's name",
 *   label: "User name",
 *   label: "$tUser.userName", // translates using snippet User.userName
 * // Default config --
 *   rules: MandatoryTextField,
 *   hideDetails: true,
 *   getsDisabled: true,
 *   grow: false,
 *   loads: false
 * })
 */
export const CheckboxComponent = (key: string, value: boolean, config = {} as InputConfig): CheckboxInput => {
  return {
    ...Config.CHECKBOX,
    ...config,
    _id: uuid(),
    type: InputType.CHECKBOX,
    value,
    key
  } as CheckboxInput
}

/**
 *
 * @param key Used to generate a key value structure
 * @param value Predefined input value, if blank this should be an empty string
 * @example
 *
 * DateComponent("birthday", "1993-10-19", {
 *   placeholder: "Use the date picker",
 *   label: "Birthday",
 *   label: "$tUser.birthday", // translates using snippet User.birthday
 * // Default config --
 *   rules: MandatoryTextField,
 *   hideDetails: true,
 *   getsDisabled: true,
 *   grow: false,
 *   loads: false
 * })
 */
export const DateComponent = (key: string, value: string, config = {} as InputConfig): DateInput => {
  return {
    ...Config.DATE,
    ...config,
    _id: uuid(),
    type: InputType.DATE,
    value,
    key
  } as DateInput
}

/**
 *
 * @param key Used to generate a key value structure
 * @param value Predefined input value, if blank this should be an empty string
 * @example
 *
 * SelectComponent("favoriteColor", "Blue", ["Blue", "Red"], {
 *   placeholder: "Pick favorite color",
 *   label: "Favorite color",
 *   label: "$tUser.favoriteColor", // translates using snippet User.favoriteColor
 * // Default config --
 *   rules: MandatoryTextField,
 *   hideDetails: true,
 *   getsDisabled: true,
 *   grow: false,
 *   loads: false,
 * })
 */
export const SelectComponent = <T>(
  key: string,
  value: string,
  items: T[],
  config = {} as SelectInputConfig<T>
): SelectInput => {
  return {
    ...Config.SELECT,
    ...config,
    _id: uuid(),
    type: InputType.SELECT,
    value,
    items,
    key
  } as SelectInput
}

/**
 *
 * @param key Used to generate a key value structure
 * @param value Predefined input value, if blank this should be an empty string
 * @example
 *
 * ComboboxComponent("favoriteSport", "Soccer", ["Soccer", "Tennis"], {
 *   placeholder: "Pick favorite sport",
 *   label: "Favorite sport",
 *   label: "$tUser.favoriteSport", // translates using snippet User.favoriteSport
 * // Default config --
 *   rules: MandatoryComboBoxFromList(["Soccer", "Tennis"]),
 *   hideDetails: true,
 *   getsDisabled: true,
 *   grow: false,
 *   loads: false,
 * })
 */
export const ComboboxComponent = <T>(
  key: string,
  value: string,
  items: T[],
  config = {} as ComboboxInputConfig<T>
): ComboboxInput => {
  return {
    ...Config.COMBOBOX,
    ...config,
    _id: uuid(),
    type: InputType.COMBOBOX,
    rules: MandatoryComboBoxFromList<T>(items),
    value,
    items,
    key
  } as ComboboxInput
}

/**
 *
 * @param key Used to generate a key value structure, this will hold the nested children
 * @param componentCollection The collection of components
 * @example
 *
 * IterableComponent("favoriteFood", [TextComponent("foodName"), TextComponent("restaurant")], {
 *   label: "Favorite Food",
 *   // Default config --
 *   isCollectionStructure: false,
 * })
 */
export const IterableComponent = (
  key: string,
  componentCollection: Director | StructureCollection,
  config = {} as IterableConfig
): IterableGroup => {
  return {
    ...Config.ITERABLE,
    ...config,
    _id: uuid(),
    type: InputType.ITERABLE,
    key,
    children: componentCollection,
    grow: true
  } as IterableGroup
}

export const StructureCollectionComponent = (
  collection: Array<Array<InputItem | IterableGroup>>
): StructureCollection => {
  return {
    _id: uuid(),
    type: InputType.COLLECTION,
    collection
  }
}
