<template>
  <v-menu content-class="bar_menu" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>status_question</v-icon>
      </v-btn>
    </template>

    <ul class="listMenu">
      <li v-for="doc in docs" @click="openNewTab(doc.link)" :key="doc.id" class="listRow">
        {{ doc.label[$i18n.locale] }}
      </li>
    </ul>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { DocumentationItem } from "@/types/documentation"

export default defineComponent({
  name: "DocumentationLinks",
  props: {
    docs: {
      type: Array as PropType<DocumentationItem[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const openNewTab = async (link: string) => {
      window.open(link, "_blank")
    }

    return {
      openNewTab
    }
  }
})
</script>
