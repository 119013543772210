<template>
  <div class="navigateBack" :style="customizedPadding">
    <v-btn @click="sendToWorkspaces" class="dense" large>
      <v-icon>navigate_expand_collapse_left</v-icon>
    </v-btn>

    <org-logo :organization-id="organizationId" small no-padding redirects />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api"
import { navigate } from "@/router"
import OrgLogo from "@/components/organizationLogo/OrganizationLogo.vue"
import "./scss/_navigateBack.scss"

export default defineComponent({
  name: "NavigateBack",
  props: {
    padding: {
      type: String,
      default: "16px 0"
    },
    organizationId: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const sendToWorkspaces = () => {
      navigate({ name: "WorkspacesList" })
    }

    const customizedPadding = computed(() => {
      if (props.padding) {
        return {
          padding: props.padding
        }
      } else {
        return {}
      }
    })

    return { sendToWorkspaces, customizedPadding }
  },
  components: {
    OrgLogo
  }
})
</script>
