<template>
  <popup v-model="showPopup" title="" width="550px" hideFooter>
    <div class="passwordCopyHandler">
      <h3>{{ $t("WorkspaceEdit.copyPasswordPopup.title") }}</h3>

      <p v-if="hasConnectionLink">
        {{ $t("WorkspaceEdit.copyPasswordPopup.subTitle") }}
      </p>

      <v-btn @click="copyPassword" color="primary" large>
        {{ $t("WorkspaceEdit.copyPasswordPopup.button") }}
      </v-btn>

      <div class="inputLabel">
        <span>{{ $t("WorkspaceEdit.copyPasswordPopup.inputLabel") }}</span>
        <v-text-field :value="instanceDetails.password" readonly />
      </div>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { InstanceDetails } from "../../lib/types"
import { copyToClipboard, newTab } from "@/utils/ui"
import { raiseSuccess } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import Popup from "@/components/popup/PopupRoot.vue"

export default defineComponent({
  name: "CopyPasswordPopup",
  props: {
    instanceDetails: {
      type: Object as PropType<InstanceDetails>,
      required: true
    }
  },
  setup(props, ctx) {
    const _closePopup = () => {
      const { connectionUrl } = props.instanceDetails

      if (connectionUrl) {
        newTab(connectionUrl)
      }

      ctx.emit("clear")
    }

    const hasConnectionLink = computed(() => {
      return Boolean(props.instanceDetails.connectionUrl)
    })

    const copyPassword = async () => {
      const { password } = props.instanceDetails

      if (await copyToClipboard(password)) {
        raiseSuccess({ text: translate("WorkspaceEdit.alerts.feedbacks.passwordCopied") })
      }

      _closePopup()
    }

    const showPopup = computed({
      get() {
        return Boolean(props.instanceDetails.password)
      },
      set() {
        _closePopup()
      }
    })

    return {
      hasConnectionLink,
      showPopup,
      copyPassword
    }
  },
  components: {
    Popup
  }
})
</script>
