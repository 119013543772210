<template>
  <div class="errorBox yellowBorder">
    <div class="wrapDetails">
      <v-icon color="warning">status_warning</v-icon>

      <div class="errorDetail">
        <p v-if="showInactivityWarning">
          <span v-if="inactiveFor && isInactive">
            {{ t("inactivityWarningWithMinutes", { inactiveFor }) }}
          </span>

          <span v-else>
            {{ t("inactivityWarning") }}
          </span>
        </p>

        <p v-else v-html="shutdownWarningMessage" />
      </div>
    </div>

    <div v-if="isInactive || showShutdownWarning" class="barFunctions">
      <v-btn @click="cancelAutoShutdown" :disabled="loading" large>
        {{ t("cancel") }}
      </v-btn>

      <v-btn @click="$emit('postpone:shutdown')" :disabled="loading" color="primary" large>
        {{ t("stayOnline") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { AutoShutdownInformation, AutoShutdownStatus } from "@/types/workspace"
import { createTranslationModule, translatePlural } from "@/plugins/i18n"
import { inactivityThreshold } from "@/config/app-launcher-inactivity-time"
import { raiseConfirmation } from "@/utils/event-bus"
import { useStore } from "@/composable/useStore"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "InactivityWarning",
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(_, ctx) {
    const { spaceModule } = useStore()
    const t = createTranslationModule("TriCaster.autoShutdownWarning.")

    const shutdownWarningMessage = computed(() => {
      if (lastingMinutes.value === "-1") {
        return t("goingOfflineInDelayed", { scheduledHours: shutsDownAt.value })
      } else {
        if (hasInactivityCheck.value) {
          return t("goingOfflineInWithInactivityCheck", { lastingMinutes: lastingMinutes.value })
        } else {
          return t("goingOfflineIn", { lastingMinutes: lastingMinutes.value })
        }
      }
    })

    const hasInactivityCheck = computed(() => {
      return spaceModule.shutdownSettings.inactivityTrackerEnabled
    })

    const autoShutdownInformation = computed(() => {
      return spaceModule.watching.autoShutdownDefinition as AutoShutdownInformation
    })

    const shutsDownAt = computed(() => {
      const { scheduledToShutdownAt } = autoShutdownInformation.value
      return Utils.dateFormatWithTime(scheduledToShutdownAt)
    })

    const inactiveFor = computed(() => {
      const { inactiveForInMinutes } = autoShutdownInformation.value

      if (inactiveForInMinutes) {
        return `${inactiveForInMinutes} ${translatePlural("common.time.minute", inactiveForInMinutes)}`
      }

      return null
    })

    const isInactive = computed(() => {
      const { inactiveForInMinutes } = autoShutdownInformation.value
      const threshold = inactivityThreshold / (1000 * 60)
      const hasBeenInactiveForLong = inactiveForInMinutes >= threshold

      return showInactivityWarning.value && hasBeenInactiveForLong
    })

    const lastingMinutes = computed(() => {
      const { scheduledToShutdownAt } = autoShutdownInformation.value
      return _getTime(scheduledToShutdownAt)
    })

    const showInactivityWarning = computed(() => {
      return autoShutdownInformation.value.status === AutoShutdownStatus.checkingInactivity
    })

    const showShutdownWarning = computed(() => {
      return autoShutdownInformation.value.status === AutoShutdownStatus.nearAutoShutdown
    })

    const cancelAutoShutdown = async () => {
      if (await raiseConfirmation({ text: t("confirmCancelAutoShutdown") })) {
        ctx.emit("cancel:shutdown")
      }
    }

    const _getTime = (time: string) => {
      const { min, hours } = Utils.dateRange(Date.now(), time)

      if (hours > 0) {
        return `${hours} ${translatePlural("common.time.hour", hours)}`
      } else if (min > 0) {
        return `${min} ${translatePlural("common.time.minute", min)}`
      } else {
        return "-1"
      }
    }

    return {
      t,
      cancelAutoShutdown,
      isInactive,
      inactiveFor,
      showInactivityWarning,
      shutdownWarningMessage,
      shutsDownAt,
      showShutdownWarning
    }
  }
})
</script>
