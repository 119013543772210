<template>
  <v-text-field
    v-model="beforeUpdateSnippet"
    :placeholder="compPlaceholder"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    rbt-target="searchInput"
    class="searchInput"
    clearable
    hide-details
  />
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api"
import { translate } from "@/plugins/i18n"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "SearchInput",
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "action_search"
    }
  },
  setup(props, ctx) {
    const searchSnippet = Utils.vModel(props, ctx.emit)

    const beforeUpdateSnippet = computed({
      get() {
        if (searchSnippet.value === null) {
          return ""
        }

        return searchSnippet.value
      },
      set(value: string | null) {
        searchSnippet.value = value ?? ""
      }
    })

    const compPlaceholder = computed(() => {
      const { placeholder } = props
      return placeholder ? placeholder : translate("common.typeToSearch")
    })

    return {
      beforeUpdateSnippet,
      compPlaceholder,
      searchSnippet
    }
  },
  emits: ["change", "input"]
})
</script>
