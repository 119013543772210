<template>
  <span v-if="onlineStatus === Starting">
    {{
      $tc("workspaces.appsGoingOnline", appsGoingToStatus(Starting), {
        appsGoingOnline: appsGoingToStatus(Starting)
      })
    }}
  </span>

  <span v-else-if="onlineStatus === Stopping">
    {{
      $tc("workspaces.appsGoingOffline", appsGoingToStatus(Stopping), {
        appsGoingOffline: appsGoingToStatus(Stopping)
      })
    }}
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum, IWorkspaceAsset } from "@/types/workspace"

export default defineComponent({
  name: "GoingToLabel",
  props: {
    onlineStatus: {
      type: String as PropType<WorkspaceOnlineStatusEnum>,
      required: true
    },
    appsDeployed: {
      type: Array as PropType<IWorkspaceAsset[] | null>,
      required: true
    }
  },
  setup(props) {
    const appsGoingToStatus = (status: WorkspaceOnlineStatusEnum) => {
      if (props.appsDeployed) {
        return props.appsDeployed.filter(app => app.onlineStatus === status).length
      }

      return 0
    }

    return {
      appsGoingToStatus,
      ...WorkspaceOnlineStatusEnum
    }
  }
})
</script>
