<template>
  <div id="licenseBoard">
    <v-btn @click="showPopup = true" large>
      <v-icon left>mdi-license</v-icon>
      <span>{{ $t("LicenseCore.controllers.licenseText") }}</span>
    </v-btn>

    <popup v-model="showPopup" :title="$t('LicenseCore.popup.licenses')" :loading="loading" hide-footer no-padding>
      <template>
        <div class="wrapLicenseTable">
          <v-data-table
            :headers="headers"
            :items="licenses"
            :items-per-page="itemsPerPage"
            :disable-pagination="!showPagination"
            :hide-default-footer="!showPagination"
          >
            <template v-slot:[`item.freeLicenses`]="{ item }">
              <span>{{ item.freeLicenses }} / {{ item.totalLicenses }}</span>
            </template>

            <template v-slot:[`item.containerId`]="{ item }">
              <span class="containerId">
                {{ item.containerId }}

                <v-btn v-if="item.containerId" @click="copyToClipboard(item.containerId)" class="ml-2" icon>
                  <v-icon>action_copy</v-icon>
                </v-btn>
              </span>
            </template>
          </v-data-table>
        </div>
      </template>
    </popup>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { LicenseProduct } from "@/types/workspace"
import { copyToClipboard } from "@/utils/ui"
import Popup from "@/components/popup/PopupRoot.vue"
import Headers from "../../lib/license-table-header"

export default defineComponent({
  name: "LicenseBoard",
  props: {
    licenses: {
      type: Array as PropType<LicenseProduct[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const showPopup = ref(false)
    const itemsPerPage = 10

    const headers = computed(() => {
      return Headers()
    })

    const showPagination = computed(() => {
      return props.licenses.length > itemsPerPage
    })

    return {
      copyToClipboard,
      showPagination,
      itemsPerPage,
      headers,
      showPopup
    }
  },
  components: {
    Popup
  }
})
</script>
