import Utils from "@/utils/utils"
import { InternalLink, RouteSettings } from "./type"
import { translate } from "@/plugins/i18n"

const adminLinks: (settings: RouteSettings) => InternalLink[] = (settings: RouteSettings) => {
  return [
    {
      route: { name: "WorkspacesList" },
      label: translate("AdminMenu.spaces"),
      icon: "navigate_view_grid_thumbnails_medium",
      "rbt-target": "workspaceList"
    },
    ...Utils.insertIf(settings.isCustomerHosted, {
      route: { name: "CoreLicenses" },
      label: translate("AdminMenu.coreLicenses"),
      icon: "vizengine_playout_configuration",
      "rbt-target": "core"
    }),
    {
      route: { name: "OrganizationUsers", params: { organizationId: settings.selectedOrganizationId } },
      label: translate("AdminMenu.organizationUsers"),
      icon: "content_users",
      "rbt-target": "organizationUsers"
    },
    {
      route: { name: "Organization", params: { organizationId: settings.selectedOrganizationId } },
      label: translate("AdminMenu.organization"),
      icon: "action_settings",
      "rbt-target": "organization"
    }
  ]
}

export default adminLinks
