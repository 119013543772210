import { defineStore } from "pinia"
import { Feedback } from "@/services"
import { FeedbackPayload } from "@/types/feedback"

export const feedback = defineStore("feedback", {
  state: () => ({
    sent: false
  }),
  actions: {
    async sendFeedback(feedbackPayload: FeedbackPayload): Promise<void> {
      await Feedback.send(feedbackPayload)
    }
  }
})
