import { OrganizationItem } from "@/types/organization"
import { reactive, ref, computed } from "@vue/composition-api"

const FilterOrganizationType = {
  ALL: "all",
  CUSTOMER_HOSTED: "CustomerHosted",
  SAAS: "SaaS"
}

const IsReadyOption = {
  ALL: "all",
  READY: "true",
  NOT_READY: "false"
}

const SSLCertificateStatus = {
  ALL: "all",
  closeToExpire: "CloseToExpire",
  expired: "Expired",
  noCertificate: "NoCertificate",
  noWarnings: "NoWarnings",
  failed: "Failed"
}

const filterSetup = () => {
  const organizations = ref<OrganizationItem[]>([])

  const filterSettings = reactive({
    organizationType: FilterOrganizationType.ALL as string,
    isReady: IsReadyOption.ALL as string,
    sslCertificate: SSLCertificateStatus.ALL as string,
    search: ""
  })

  const _filterOrganizationTypes = (orgs: OrganizationItem[]) => {
    const { ALL } = FilterOrganizationType

    if (filterSettings.organizationType === ALL) {
      return orgs
    }

    return orgs.filter(org => org.organizationType === filterSettings.organizationType)
  }

  const _filterIsReady = (orgs: OrganizationItem[]) => {
    const { ALL } = IsReadyOption

    if (filterSettings.isReady === ALL) {
      return orgs
    }

    return orgs.filter(org => JSON.stringify(org.isReady) === filterSettings.isReady)
  }

  const _filterSearch = (orgs: OrganizationItem[]) => {
    const lowerCase = (text: string) => (text ?? "").trim().toLocaleLowerCase()
    const text = lowerCase(filterSettings.search ?? "")

    if (text) {
      return orgs.filter(org => lowerCase(org.name).includes(text) || lowerCase(org.createdByUser).includes(text))
    }

    return orgs
  }

  const _filterSSLCertificate = (orgs: OrganizationItem[]) => {
    const { ALL } = SSLCertificateStatus

    if (filterSettings.sslCertificate === ALL) {
      return orgs
    }

    switch (filterSettings.sslCertificate) {
      case SSLCertificateStatus.closeToExpire:
        return orgs.filter(organization => organization.certificateInfo?.visibleState === "warning")

      case SSLCertificateStatus.expired:
        return orgs.filter(organization => organization.certificateInfo?.visibleState === "danger")

      case SSLCertificateStatus.failed:
        return orgs.filter(organization => organization.certificateInfo?.status === "failed")

      case SSLCertificateStatus.noCertificate:
        return orgs.filter(organization => !organization?.certificateInfo)

      case SSLCertificateStatus.noWarnings:
        return orgs.filter(organization => {
          const hasSSLMessage = ["warning", "danger"].includes(organization?.certificateInfo?.visibleState as string)
          const certificateExists = Boolean(organization?.certificateInfo)
          const notFailed = organization?.certificateInfo?.status !== "failed"

          return !hasSSLMessage && certificateExists && notFailed
        })

      case SSLCertificateStatus.ALL:
        return orgs

      default:
        return orgs
    }
  }

  const filteredOrganizations = computed(() => {
    let organizationCollection = organizations.value

    organizationCollection = _filterSearch(organizationCollection)
    organizationCollection = _filterOrganizationTypes(organizationCollection)
    organizationCollection = _filterIsReady(organizationCollection)
    organizationCollection = _filterSSLCertificate(organizationCollection)

    return organizationCollection
  })

  const resetFilterSettings = () => {
    filterSettings.organizationType = FilterOrganizationType.ALL as string
    filterSettings.isReady = IsReadyOption.ALL as string
    filterSettings.search = ""
  }

  const setOrganizationCollection = (orgCollection: OrganizationItem[]) => {
    organizations.value = orgCollection
  }

  return {
    setOrganizationCollection,
    resetFilterSettings,
    filterSettings,
    filteredOrganizations,
    FilterOrganizationType,
    IsReadyOption,
    SSLCertificateStatus
  }
}

export const filterSetupInstance = filterSetup()
