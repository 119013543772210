import { SimpleItem } from "@/types/core"
import { FeedbackPayload } from "@/types/feedback"
import { translate } from "@/plugins/i18n"
import { computed } from "@vue/composition-api"
import Utils from "@/utils/utils"
import * as Rules from "@/utils/input-rules"

const staticFeedbackTypes: () => SimpleItem[] = () => [
  { id: "general", name: translate("FeedbackDialog.inputOptions.general") },
  { id: "bug", name: translate("FeedbackDialog.inputOptions.bug") },
  { id: "featureRequest", name: translate("FeedbackDialog.inputOptions.featureRequest") },
  { id: "productRequest", name: translate("FeedbackDialog.inputOptions.productRequest") }
]

export default function (feedbackProp: FeedbackPayload) {
  const feedbackSubjectLabel = computed(() => {
    const feedType = Utils.isType<SimpleItem>(feedbackProp.feedbackType, "name")

    if (feedType && feedType.name) {
      return feedType.name
    }

    return translate("FeedbackDialog.inputs.issue")
  })

  const feedbackPendingAnswer = computed(() => {
    if (feedbackProp.rate) {
      return false
    }
    if (feedbackProp.feedbackType) {
      return false
    }
    return true
  })

  const feedbackRules = computed(() => {
    const noRules = {
      select: [],
      textarea: []
    }

    if (feedbackProp.feedbackType) {
      return {
        select: Rules.MandatorySelect,
        textarea: Rules.MandatoryTextField
      }
    }

    return noRules
  })

  const onFinishUpload = (uploadedFileId: string) => {
    feedbackProp.uploadId = uploadedFileId
  }

  return {
    feedbackProp,
    feedbackSubjectLabel,
    feedbackPendingAnswer,
    feedbackRules,
    feedbackOptions: computed(() => Utils.createSelectItems<SimpleItem>(staticFeedbackTypes(), "name")),
    onFinishUpload
  }
}
