import { WorkspaceOnlineStatusEnum, WorkspaceStatusEnum, WorkspaceTemplate } from "@/types/workspace"
import { errorStates } from "@/config/workspace-states"
import { ref, onBeforeUnmount } from "@vue/composition-api"
import * as Notification from "@/utils/web-notifications"
import { translate as t } from "@/plugins/i18n"
import Utils from "@/utils/utils"

export const useNotification = () => {
  const COOL_DOWN_TIME = Utils.seconds(10)
  const _coolDownDate = ref(Date.now())

  const browserNotification = async (newSpace: WorkspaceTemplate, oldSpace: WorkspaceTemplate) => {
    const statusChanged = newSpace.status !== oldSpace.status
    const onlineStatusChanged = newSpace.onlineStatus !== oldSpace.onlineStatus

    await Notification.getNotificationPermission()

    if (statusChanged) {
      _handleStatusChange(newSpace.status)
    } else if (onlineStatusChanged) {
      _handleOnlineStatusChange(newSpace.onlineStatus)
    }
  }

  const _verifyNotInCoolDown = () => {
    if (Date.now() >= _coolDownDate.value) {
      _coolDownDate.value = Date.now() + COOL_DOWN_TIME
      return true
    }

    return false
  }

  const _handleStatusChange = (newStatus: WorkspaceStatusEnum) => {
    const { Draft, Deployed } = WorkspaceStatusEnum
    const worthNotifying = [Draft, Deployed]

    if (worthNotifying.includes(newStatus)) {
      const status = t("BrowserTab.state." + newStatus)

      if (_verifyNotInCoolDown()) {
        Notification.notifyUser(
          t("BrowserNotification.taskDone"),
          t("BrowserNotification.taskDoneDescription", { status })
        )
      }
    } else if (errorStates.includes(newStatus)) {
      if (_verifyNotInCoolDown()) {
        Notification.notifyUser(t("BrowserNotification.errorTitle"), t("BrowserNotification.errorDescription"))
      }
    }
  }

  const _handleOnlineStatusChange = (newStatus: WorkspaceOnlineStatusEnum) => {
    const { Online } = WorkspaceOnlineStatusEnum

    if (newStatus === Online) {
      const status = t("BrowserTab.state.online")

      if (_verifyNotInCoolDown()) {
        Notification.notifyUser(
          t("BrowserNotification.taskDone"),
          t("BrowserNotification.taskDoneDescription", { status })
        )
      }
    }
  }

  Notification.getNotificationPermission()

  onBeforeUnmount(() => {
    Notification.destroyEvents()
  })

  return {
    browserNotification
  }
}
