import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('popup',{attrs:{"loading":_vm.loading || _vm.loader.on,"min-width":"850px","title":"VPC Peering","hide-footer":""},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('div',{attrs:{"id":"multiVpc"}},[_c('p',[_vm._v(_vm._s(_vm.t("explanation")))]),_c('span',{staticClass:"preTitle"},[_vm._v(" "+_vm._s(_vm.$t("WorkspaceEdit.workspace"))+" ")]),_c('div',{staticClass:"spacesContainer"},[_c('div',{staticClass:"spaceList"},[_c('div',{staticClass:"scrollContainer"},[_c('ul',[_vm._l((_vm.vpcCollection),function(vpcItem,index){return _c('li',{key:vpcItem.id,staticClass:"spaceItem animateFadeUpFast",class:("animationDelay-" + index),attrs:{"showing":_vm.isShowing(vpcItem)},on:{"click":function($event){return _vm.selectVpc(vpcItem)}}},[_c('span',{staticClass:"vpcStatus"},[(_vm.isNew(vpcItem))?_c(VIcon,{attrs:{"color":"warning"}},[_vm._v("status_warning")]):(_vm.showIcon(vpcItem.peeringStatus))?_c('tooltip',{attrs:{"text":_vm.$t(("WorkspaceEdit.vpcPeering.status." + (vpcItem.peeringStatus)))}},[_c(VIcon,{staticClass:"spinning",attrs:{"size":16,"color":"#fff"}},[_vm._v("status_spinner_indeterminate")])],1):_c('span',{staticClass:"statusCircle",attrs:{"status":vpcItem.peeringStatus}})],1),_c('span',{staticClass:"vpcName"},[_vm._v(_vm._s(vpcItem.name || "Untitled"))]),(_vm.isExternalVpc(vpcItem) && !_vm.isShowing(vpcItem))?_c('span',{staticClass:"externalLabel"},[_c('small',[_vm._v(_vm._s(_vm.t("external")))])]):(_vm.isShowing(vpcItem))?_c('span',{staticClass:"statusLabel"},[_c('small',[_vm._v(_vm._s(_vm.translateVpcStatus(vpcItem)))])]):_vm._e()])}),(_vm.vpcCollection.length === 0)?_c('li',{staticClass:"emptyList"},[_c('small',[_vm._v(_vm._s(_vm.t("noPeering")))])]):_vm._e()],2)]),_c('div',{staticClass:"createNewPeering"},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('spotlight-element',{attrs:{"label":_vm.t('spotlightCreateNewVpc'),"active":_vm.createVpcIndicatorIsActive,"right":""}},[_c(VBtn,_vm._g(_vm._b({attrs:{"rbt-target":"moreOptionsBtn","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("action_add_circle")])],1)],1)]}}])},[_c('ul',{staticClass:"listMenu"},[_c('li',{staticClass:"listRow",attrs:{"rbt-target":"editUserBtn"},on:{"click":function($event){return _vm.startNewPeering(_vm.CreationType.SPACE_PEERING)}}},[_c('span',[_vm._v(_vm._s(_vm.t("addNewVpcOptions.peerWithSpace")))])]),_c('li',{staticClass:"listRow",attrs:{"rbt-target":"editUserBtn"},on:{"click":function($event){return _vm.startNewPeering(_vm.CreationType.EXTERNAL_PEERING)}}},[_c('span',[_vm._v(_vm._s(_vm.t("addNewVpcOptions.externalPeering")))])])])])],1)]),_c('div',{staticClass:"newVpcSection"},[(_vm.vpc)?_c('vpc-watcher',{attrs:{"vpc-collection":_vm.vpcCollection},on:{"remove:new-vpc":_vm.removeNewVpc,"remove:old-vpc":_vm.removeOldVpc,"create:new-vpc":_vm.createVpc},model:{value:(_vm.vpc),callback:function ($$v) {_vm.vpc=$$v},expression:"vpc"}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }