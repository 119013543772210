import { translate } from "@/plugins/i18n"
import { Dictionary } from "@/types/core"

interface DefaultConfig {
  text: string
  timeout: number
  icon: string | false
}

const _onError = (): DefaultConfig => ({
  text: translate("Alert.error"),
  timeout: 2000,
  icon: "action_remove_circle"
})

const _onSuccess = (): DefaultConfig => ({
  text: translate("Alert.success"),
  timeout: 800,
  icon: "status_ok_circle"
})

const _onInfo = (): DefaultConfig => ({
  text: "",
  timeout: 1500,
  icon: "content_concept"
})

const _onWarning = (): DefaultConfig => ({
  text: "",
  timeout: 1500,
  icon: "status_warning"
})

export const FLAGS = {
  error: 0,
  success: 1,
  info: 2,
  warning: 3
}

export const CONFIG: Dictionary<() => DefaultConfig> = {
  "0": _onError,
  "1": _onSuccess,
  "2": _onInfo,
  "3": _onWarning
}

export const COLORS: Dictionary<string> = {
  "0": "error",
  "1": "success",
  "2": "info",
  "3": "warning"
}
