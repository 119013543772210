<template>
  <div class="_spotlightElement">
    <slot />

    <div v-if="active" :direction="bubbleDirection" class="bubbleLabel">
      <span>{{ label }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { Dictionary } from "@/types/core"
import "./spotlight-element.scss"

export default defineComponent({
  name: "SpotlightElement",
  props: {
    label: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    top: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const bubbleDirection = computed(() => {
      const { top, right, bottom, left } = props
      const directions = { top, right, bottom, left } as Dictionary<boolean>
      const defaultDirection = "top"
      const selectedDirection = Object.keys(directions).filter(direction => directions[direction])

      if (selectedDirection.length > 0) {
        return selectedDirection[0]
      } else {
        return defaultDirection
      }
    })

    return {
      bubbleDirection
    }
  }
})
</script>
