<template>
  <div id="directoryHandler">
    <div class="firstBlock">
      <h4>{{ $t("WorkspaceEdit.fileTransfer.steps.one") }}</h4>

      <div class="directoryPicker">
        <div class="upload">
          <h5>
            <v-icon left>action_upload_or_export</v-icon>
            {{ $t("WorkspaceEdit.fileTransfer.folders.upload") }}
          </h5>

          <div class="inputs">
            <v-text-field
              v-model="mainFolderDirectory.upload"
              :placeholder="$t('WorkspaceEdit.fileTransfer.placeholder.upload')"
              :rules="mandatoryInputs"
              name="upload"
              hide-details
            />

            <span class="label">{{ $t("WorkspaceEdit.fileTransfer.explanation.upload") }}</span>
          </div>
        </div>

        <div class="download">
          <h5>
            <v-icon left>action_download_or_import</v-icon>
            {{ $t("WorkspaceEdit.fileTransfer.folders.download") }}
          </h5>

          <div class="inputs">
            <v-text-field
              v-model="mainFolderDirectory.download"
              :placeholder="$t('WorkspaceEdit.fileTransfer.placeholder.download')"
              :rules="mandatoryInputs"
              name="download"
              hide-details
            />

            <span class="label">{{ $t("WorkspaceEdit.fileTransfer.explanation.download") }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="secondBlock">
      <h4>{{ $t("WorkspaceEdit.fileTransfer.steps.two") }}</h4>

      <p>{{ $t("WorkspaceEdit.fileTransfer.explanation.runInstructions") }}</p>

      <p
        v-if="temporarilyHiddenUntilDocRelease"
        v-html="$t('WorkspaceEdit.fileTransfer.explanation.documentation', { docLink })"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { FileTransferConfig } from "@/types/fileTransfer"
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { MandatoryWindowsDirectory } from "@/utils/input-rules"
import { UsersModule } from "@/store"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "DirectoryHandler",
  props: {
    value: {
      type: Object as PropType<FileTransferConfig>,
      required: true
    },
    workspaceName: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()
    const mainFolderDirectory = Utils.vModel(props, ctx.emit)
    const docLink = "https://confluence.vizrt.com/pages/viewpage.action?spaceKey=NOW&title=.File+Transfer+v1.0"
    const mandatoryInputs = MandatoryWindowsDirectory

    const temporarilyHiddenUntilDocRelease = computed(() => {
      return usersModule.hasGodPermissions
    })

    return {
      docLink,
      mandatoryInputs,
      temporarilyHiddenUntilDocRelease,
      mainFolderDirectory
    }
  }
})
</script>
