import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { WizardConfigItem } from "@/types/wizard"

const api = new Request("/v1/WorkspaceTemplates/")

export default createService("workspaceTemplate", {
  async getSettings(workspaceTemplateId: string) {
    return await api.get<WizardConfigItem[]>(`${workspaceTemplateId}/WizardConfiguration/`)
  }
})
