<template>
  <div class="appHeader">
    <div v-if="['added', 'removed'].includes(flagType)">
      <span v-if="flagType === 'added' && workspaceDeploying">
        {{ $t("WorkspaceEdit.appCard.label.deployingNew") }}
      </span>

      <span v-else>
        {{ $t(`WorkspaceEdit.appCard.label.${flagType}`) }}
      </span>

      <app-card-warning :app-id="appId" :flag-type="flagType" />
    </div>

    <div v-else-if="isDeployed" class="deployedHeader">
      <online-switch @change="state => $emit('set:online', state)" :online-state="onlineState" :loading="loading" />

      <div class="wrapCornerElements">
        <span v-if="runningSince && isOnline">
          <running-for :running-since="runningSince" />
        </span>

        <app-card-warning :app-id="appId" :flag-type="flagType" />
      </div>
    </div>

    <div v-else>
      <span>{{ $t(`workspaceStatus.${workspace.status}`) }}</span>
      <app-card-warning :app-id="appId" :flag-type="flagType" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum, WorkspaceTemplate, WorkspaceStatusEnum } from "@/types/workspace"
import { WorkspaceStates } from "@/config/workspace-states"
import { AppDiffType } from "@/utils/workspaceDiff"
import OnlineSwitch from "./OnlineSwitch.vue"
import RunningFor from "../shared/RunningFor.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import AppCardWarning from "./_AppCardWarning.vue"

export default defineComponent({
  name: "AppHeader",
  props: {
    onlineState: {
      type: String as PropType<WorkspaceOnlineStatusEnum>,
      required: false
    },
    isDeployed: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    appId: {
      type: String,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    runningSince: {
      type: [String, Number, Date],
      default: null
    },
    flagType: {
      type: String as PropType<AppDiffType>,
      required: true
    }
  },
  setup(props) {
    const isOnline = computed(() => {
      const { Online } = WorkspaceOnlineStatusEnum
      return props.onlineState === Online
    })

    const workspaceDeploying = computed(() => {
      return WorkspaceStates.isLoading(props.workspace.status)
    })

    return {
      ...WorkspaceStatusEnum,
      workspaceDeploying,
      isOnline
    }
  },
  components: {
    Tooltip,
    RunningFor,
    OnlineSwitch,
    AppCardWarning
  }
})
</script>
