<template>
  <div class="addingAppsContainer">
    <Dialog
      v-model="showModal"
      :title="$t('WorkspaceEdit.addApps.title')"
      max-width="75vw"
      custom-class="dialogAddAppsContainer"
      hide-actions
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="canSeePermission"
          v-bind="attrs"
          v-on="on"
          :disabled="isDisabled || loading"
          :color="buttonColor"
          rbt-target="openApps"
          large
        >
          <v-icon left>action_add_circle</v-icon>
          <span>{{ $t("WorkspaceEdit.addApps.buttonLabel") }}</span>
        </v-btn>
      </template>

      <apps-container v-if="showModal" :workspace="workspace" @add:apps="addApps" @cancel="cancel" />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceTemplate, AddNewAssetPayload } from "@/types/workspace"
import { WorkspaceStates } from "@/config/workspace-states"
import { UsersModule } from "@/store/users"
import Dialog from "@/components/global/Dialog.vue"
import AppsContainer from "./apps/AppsContainer.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "AddApps",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const showModal = Utils.vModel(props, ctx.emit)
    const usersModule = UsersModule()

    const isDisabled = computed(() => {
      return WorkspaceStates.isLoading(props.workspace.status) || WorkspaceStates.failed(props.workspace.status)
    })

    const buttonColor = computed(() => {
      const workspaceSchema = props.workspace.schema.assets
      const mandatoryAction = workspaceSchema.length === 0

      return mandatoryAction ? "primary" : "default"
    })

    const canSeePermission = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const cancel = () => {
      showModal.value = false
    }

    const addApps = (apps: AddNewAssetPayload[]) => {
      showModal.value = false
      ctx.emit("add:apps", apps)
    }

    return {
      addApps,
      isDisabled,
      buttonColor,
      canSeePermission,
      showModal,
      cancel
    }
  },
  components: {
    AppsContainer,
    Dialog
  }
})
</script>
