class Env {
  public getEnv<T>(name: string): T {
    return window?.configs?.[name] || process.env[name] || ""
  }

  get VUE_APP_VERSION(): string {
    return process.env.VUE_APP_VERSION
  }
  get BASE_URL(): string {
    return process.env.BASE_URL
  }
  get NODE_ENV(): string {
    return process.env.NODE_ENV
  }

  get VUE_APP_BASE_API(): string {
    return this.getEnv("VUE_APP_BASE_API")
  }
  get VUE_APP_AUTH0_DOMAIN(): string {
    return this.getEnv("VUE_APP_AUTH0_DOMAIN")
  }
  get VUE_APP_AUTH0_CLIENTID(): string {
    return this.getEnv("VUE_APP_AUTH0_CLIENTID")
  }
  get VUE_APP_AUTH0_AUDIENCE(): string {
    return this.getEnv("VUE_APP_AUTH0_AUDIENCE")
  }
  get VUE_APP_UPLOAD_MAX_FILE_SIZE_MB(): number {
    return +this.getEnv<number>("VUE_APP_UPLOAD_MAX_FILE_SIZE_MB")
  }
  get VUE_APP_RELEASE_NAME(): string {
    return this.getEnv("VUE_APP_RELEASE_NAME")
  }
}

const env = new Env()

export default env
