import { CLOUD_PROVIDER } from "@/config/cloud-provider"
import { reactive } from "@vue/composition-api"
import { CreateOrganizationPayload, OrganizationType, SaaSSettings } from "@/types/organization"
import { CreateUserPayload, UserRoleEnum } from "@/types/user"

export const NewOrganization = () =>
  reactive<CreateOrganizationPayload>({
    cloudProviderName: CLOUD_PROVIDER.default,
    name: "",
    cloudZones: [],
    organizationType: OrganizationType.CustomerHosted,
    subscription: null
  })

export const SaasSettings = () =>
  reactive<SaaSSettings>({
    purchaseOrder: "",
    type: "Basic",
    startingTokens: "0",
    subscribedTokens: "0",
    startDate: "",
    expirationDate: ""
  })

export const NewUser = () =>
  reactive<CreateUserPayload>({
    firstName: "",
    lastName: "",
    email: "",
    role: UserRoleEnum.Admin
  })
