import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","content-class":"bar_menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VAvatar,{attrs:{"color":"#384db1"}},[_c('span',{staticStyle:{"color":"var(--viz-white)"}},[_vm._v(_vm._s(_vm.userInitials))])])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.$emit('logout')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("action_power_on_off")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("login.signOut")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }