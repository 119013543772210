<template>
  <div class="appBackground">
    <div id="footer">
      <span>© {{ currentYear }} Vizrt Group. All rights reserved.</span>

      <terms />

      <span>
        <a @click="raiseChangeLogs">v{{ version }}</a>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { raiseChangeLogs } from "@/utils/event-bus"
import Terms from "@/components/termsAndConditions/TermsRoot.vue"
import env from "@/utils/env"
import "./scss/_appFooter.scss"

export default defineComponent({
  name: "AppFooterRoot",
  setup() {
    const currentYear = computed(() => {
      return new Date().getFullYear()
    })

    return {
      raiseChangeLogs,
      currentYear,
      version: env.VUE_APP_VERSION
    }
  },
  components: {
    Terms
  }
})
</script>
