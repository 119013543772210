<template>
  <div id="awsSteps">
    <div class="stepByStep">
      <h3>{{ t("title") }}</h3>

      <p v-html="t('installNiceDCV', { link1: awsLink.niceDCV, link2: awsLink.DCVGuide })" />

      <h3>{{ t("awsIntegration") }}</h3>

      <p v-html="t('assumeIAMRoleDocumentation', { link: awsLink.assumingIAMRole })" />

      <ul>
        <li>{{ t("increaseQuota") }}</li>
        <li>{{ t("reserveInstanceTypes") }}</li>
        <li>{{ t("createS3BucketForFiles") }}</li>
        <li>{{ t("manageSecurityGroups") }}</li>
        <li>{{ t("createVpcPeering") }}</li>
        <li>{{ t("startStopInstances") }}</li>
        <li>{{ t("emergencyDestroyInstances") }}</li>
      </ul>

      <p>{{ t("why") }}</p>

      <h3>{{ t("settingUpIntegration") }}</h3>

      <ol>
        <li>
          <p v-html="t('vizNowIAMRole')" />

          <ul>
            <li>
              <p>
                {{ t("awsIAMAdmin") }}
              </p>
            </li>

            <li>
              <p
                v-html="
                  t('createRoleLink', {
                    link1: awsLink.creatingIAMRoleWithoutAdmin,
                    link2: awsLink.administratorAccess
                  })
                "
              />
            </li>

            <li>
              <p>{{ t("whereNextButton") }}</p>
            </li>

            <li>
              <p>
                <span v-html="t('roleName')" />

                <v-btn @click="copy('viz-now-manage')" color="primary" class="ml-1" icon>
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </p>

              <image-visualizer
                :src="assetExports.createRoleVizNowManage"
                :use="PreDefinedSize.medium"
                alt="Create Role VizNow Manage"
              />
            </li>

            <li>
              <p v-html="t('whereCreateRoleButton')" />

              <image-visualizer
                :src="assetExports.buttonCreateRole"
                :use="PreDefinedSize.medium"
                alt="Button create role"
                not-clickable
              />
            </li>
          </ul>
        </li>

        <li>
          <p v-html="t('inlinePermission')" />

          <ul>
            <li>
              <p v-html="t('viewRoleGreenTopBar')" />

              <image-visualizer
                :src="assetExports.viewManageRole"
                :use="PreDefinedSize.medium"
                alt="View manage role"
              />
            </li>

            <li>
              <p v-html="t('whereCreateInlinePermissionButton')" />

              <image-visualizer
                :src="assetExports.createInlineButton"
                :use="PreDefinedSize.medium"
                alt="Create inline button"
              />
            </li>
          </ul>
        </li>

        <li>
          <p>{{ t("createInlinePolicy") }}</p>

          <ul>
            <li>
              <p>{{ t("copyFollowingCode") }}</p>

              <code-json-permission @copy="$emit('copy:snippet-json-permission')" />
            </li>

            <li>
              <p v-html="t('whereToPasteCode')" />

              <image-visualizer :src="assetExports.jsonCodeHere" :use="PreDefinedSize.medium" alt="JSONCode" />
            </li>

            <li>
              <p>
                <span v-html="t('policyName')" />

                <v-btn @click="copy('viz-now-manage')" color="primary" class="ml-1" icon>
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </p>

              <image-visualizer :src="assetExports.policyNameManage" :use="PreDefinedSize.medium" alt="Policy name" />
            </li>

            <li>
              <p v-html="t('whereCreatePolicyButton')" />

              <image-visualizer
                :src="assetExports.createPolicyButton"
                :use="PreDefinedSize.medium"
                alt="Button create role"
                not-clickable
              />
            </li>
          </ul>
        </li>

        <li>
          <p>{{ t("configuringArnVizNow") }}</p>

          <ul>
            <li>
              <p>{{ t("locateArn") }}</p>

              <image-visualizer :src="assetExports.copyArnManage" :use="PreDefinedSize.medium" alt="Copy ARN button" />
            </li>

            <li>
              <p v-html="t('pasteArnCode')" />

              <image-visualizer
                :src="assetExports.pasteArnCodeManage"
                :use="PreDefinedSize.medium"
                alt="Paste ARN code into form"
              />
            </li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { vizNow } from "../../lib/step-by-step-links"
import { PreDefinedSize } from "@/components/imageVisualizer/lib/types"
import { translate } from "@/plugins/i18n"
import { Dictionary } from "vue-router/types/router"
import { copyToClipboard } from "@/utils/ui"
import ExternalLink from "./Link.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import CodeJsonPermission from "./CodeJSONPermission.vue"
import ImageVisualizer from "@/components/imageVisualizer/ImageVisualizerRoot.vue"
import assetExports from "../../lib/asset-export"

export default defineComponent({
  name: "AwsSetup",
  setup() {
    const t = (snippet: string, ...args: Dictionary<string>[]) => {
      const translationPrefix = "Onboarding.vizNow.newAwsUI"
      return translate(translationPrefix + `.${snippet}`, ...args)
    }

    const copy = (text: string) => {
      copyToClipboard(text)
    }

    return {
      t,
      copy,
      PreDefinedSize,
      awsLink: vizNow,
      assetExports
    }
  },
  components: {
    CodeJsonPermission,
    ExternalLink,
    ImageVisualizer,
    Tooltip
  }
})
</script>
