<template>
  <div id="feedbackCard">
    <div class="feedbackHeader">
      <span class="dialogTitle">{{ $t("FeedbackDialog.title") }}</span>

      <div class="controller">
        <v-btn @click="menuState = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="feedbackContent">
      <p>{{ $t("FeedbackDialog.contentQuestion") }}</p>
      <emoji-btn v-model="feedback.rate" />

      <div class="feedbackForm">
        <v-form ref="feedbackForm">
          <v-label for="inputFeedbackType">
            <span>{{ $t("FeedbackDialog.inputs.type") }}</span>

            <v-select
              v-model="feedback.feedbackType"
              :rules="feedbackRules.select"
              :items="feedbackOptions"
              id="inputFeedbackType"
              clearable
            />
          </v-label>

          <v-label for="inputFeedbackComment">
            {{ $t("FeedbackDialog.inputs.textarea", { feedType: feedbackSubjectLabel }) }}

            <v-textarea
              v-model="feedback.comment"
              :placeholder="$t('FeedbackDialog.label.comment')"
              :rules="feedbackRules.textarea"
              id="inputFeedbackComment"
            />
          </v-label>

          <upload
            v-if="uploadUpdated"
            :context-id="uploadId"
            :multiple="false"
            @complete="onFinishUpload"
            @loading="handleLoading"
            formats="image/png, image/jpeg, image/gif, image/jpg"
            context="feedback"
            class="uploadWidget"
          />

          <v-checkbox
            v-model="feedback.useEmail"
            :label="$t('FeedbackDialog.inputs.checkbox', { email: user.email })"
          />
        </v-form>

        <div class="feedbackController">
          <v-btn
            @click="rippleSend"
            :disabled="feedbackPendingAnswer"
            :loading="loading || appRequestingData"
            color="primary"
            large
            >{{ $t("FeedbackDialog.button.submit") }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, nextTick, PropType } from "@vue/composition-api"
import { VForm } from "@/types/core"
import { FeedbackPayload } from "@/types/feedback"
import { raiseError, raiseSuccess } from "@/utils/event-bus"
import { v4 as uuidv4 } from "uuid"
import { UsersModule } from "@/store/users"
import EmojiBtn from "./EmojiBtn.vue"
import Utils from "@/utils/utils"
import FeedbackModule from "../templates/feedback-payload"
import Upload from "@/components/uploadv2/UploadRoot.vue"

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    feedback: {
      type: Object as PropType<FeedbackPayload>,
      required: true
    }
  },
  setup(props, ctx) {
    const menuState = Utils.vModel(props, ctx.emit)
    const usersModule = UsersModule()
    const feedbackForm = ref(null)
    const appRequestingData = ref(false)
    const uploadUpdated = ref(true)
    const feedbackModule = FeedbackModule(props.feedback)
    const uploadId = ref(uuidv4())

    const user = computed(() => {
      return usersModule.selfDetail
    })

    const _refreshForm = () => {
      const Form = Utils.isType<VForm>(feedbackForm.value, "validate")

      if (Form) {
        Form.reset()
      }

      feedbackModule.feedbackProp.rate = 0
      feedbackModule.feedbackProp.useEmail = false
      uploadId.value = uuidv4()

      uploadUpdated.value = false
      nextTick(() => (uploadUpdated.value = true))
    }

    const rippleSend = () => {
      const Form = Utils.isType<VForm>(feedbackForm?.value, "validate")

      if (Form && Form.validate()) {
        ctx.emit("send")
        _refreshForm()
      }
    }

    const handleLoading = (status: boolean) => {
      appRequestingData.value = status
    }

    return {
      menuState,
      feedbackForm,
      appRequestingData,
      uploadUpdated,
      uploadId,
      raiseError,
      raiseSuccess,
      handleLoading,
      rippleSend,
      user,
      ...feedbackModule
    }
  },
  components: {
    EmojiBtn,
    Upload
  }
})
</script>
