<template>
  <div id="feedback">
    <v-progress-linear v-if="loader.on" color="primary" class="py-0 my-0" indeterminate />

    <div class="backgroundWrapper">
      <div class="wrapTitle">
        <h1>{{ $t("Admin.feedback.title") }}</h1>
        <feedback-statistics v-if="hasEnoughDataForStatistics" :items="unFilteredFeedbacks" />
      </div>

      <div class="wrapList" :hide-resolved="!toolbarConfig.showResolved">
        <feedback-toolbar v-model="toolbarConfig" @change="restartPagination" />
        <feedback-list v-model="feedbacks" @resolve="resolveFeedback" :loading="loader.on" />
        <pagination-handler :pagination="pagination" :key="feedbacks.length" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from "@vue/composition-api"
import { FILTER_INITIAL_VALUE } from "./lib/feedback-filter-type"
import { ToolbarConfig } from "./lib/type"
import { FeedbackItem, FeedbackType } from "@/types/feedback"
import { AdminModule } from "@/store/admin"
import { Pagination } from "@/utils/pagination"
import Loader from "@/utils/loader"
import FeedbackList from "./components/FeedbackList.vue"
import FeedbackToolbar from "./components/FeedbackToolbar.vue"
import FeedbackStatistics from "./components/FeedbackStatistics.vue"
import PaginationHandler from "@/components/paginationHandler/PaginationHandlerRoot.vue"
import "./scss/feedback.scss"

export default defineComponent({
  name: "Feedback",
  setup() {
    const adminModule = AdminModule()
    const loader = Loader()
    const pagination = reactive(new Pagination<FeedbackItem>([]))

    const toolbarConfig = reactive<ToolbarConfig>({
      search: "",
      filter: FILTER_INITIAL_VALUE,
      showResolved: false
    })

    const feedbacks = computed(() => {
      return pagination.items
    })

    const unFilteredFeedbacks = computed(() => {
      return adminModule.feedbacks
    })

    const hasEnoughDataForStatistics = computed(() => {
      return unFilteredFeedbacks.value.length > 0
    })

    const _applySearch = (feedback: FeedbackItem[]) => {
      const { search } = toolbarConfig

      if (search && search.length) {
        return feedback.filter(item => {
          const comment = item?.comment ?? ""
          const email = item?.email ?? ""

          return comment.includes(search) || email.includes(search)
        })
      }

      return feedback
    }

    const _applyFilter = (feedback: FeedbackItem[]) => {
      const { filter } = toolbarConfig

      if (filter !== FILTER_INITIAL_VALUE) {
        if (filter === FeedbackType.notSpecified) {
          return feedback.filter(item => {
            return !item.feedbackType
          })
        } else {
          return feedback.filter(item => {
            return item.feedbackType === toolbarConfig.filter
          })
        }
      }

      return feedback
    }

    const _applyResolvedFilter = (feedback: FeedbackItem[]) => {
      const { showResolved } = toolbarConfig

      if (!showResolved) {
        return feedback.filter(item => {
          return !item.solved
        })
      }

      return feedback
    }

    const _setupPagination = () => {
      pagination.set(adminModule.feedbacks, { itemsPerPage: 15 })

      pagination.setFilter(items => {
        let feedbackFiltered = _applyFilter(items)
        feedbackFiltered = _applySearch(feedbackFiltered)

        return _applyResolvedFilter(feedbackFiltered)
      })
    }

    const resolveFeedback = (feedback: FeedbackItem) => {
      loader.run(async () => {
        await adminModule.resolveFeedback(feedback)
      })
    }

    const restartPagination = () => {
      pagination.jumpToPage(1)
    }

    loader.run(async () => {
      await adminModule.getFeedbacks()
      _setupPagination()
    })

    return {
      resolveFeedback,
      pagination,
      unFilteredFeedbacks,
      feedbacks,
      restartPagination,
      hasEnoughDataForStatistics,
      loader,
      toolbarConfig
    }
  },
  components: {
    PaginationHandler,
    FeedbackList,
    FeedbackStatistics,
    FeedbackToolbar
  }
})
</script>
