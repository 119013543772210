<template>
  <div class="organizationCard organizationTags">
    <h3>{{ t("title") }}</h3>

    <p class="label">
      {{ t("subtitle") }}
    </p>

    <tag-accordion
      v-model="openedTags"
      @save="save"
      @remove="remove"
      :tags="tags"
      :loading="loader.on"
      ref="Accordion"
    />

    <v-form v-model="validForm" @submit.prevent ref="Form">
      <v-text-field
        v-model="newTagName"
        @keypress.enter="addNewTag"
        :placeholder="t('placeholderTagName')"
        :rules="rules"
      />
      <v-btn @click="addNewTag" :disabled="loader.on || !validForm || !newTagName" large>{{ t("addTag") }}</v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, computed } from "@vue/composition-api"
import { AdminModule, ConfigurationModule, OrganizationModule } from "@/store"
import { createTranslationModule } from "@/plugins/i18n"
import { OrganizationTag } from "@/types/organization"
import { VForm } from "@/types/core"
import TagPillBundler from "@/components/tags/TagPillBundler.vue"
import Loader from "@/utils/loader"
import TagAccordion from "./accordion"

export default defineComponent({
  name: "SpaceTags",
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const t = createTranslationModule("OrganizationPageV2.spaceTags")
    const organizationModule = OrganizationModule()
    const adminModule = AdminModule()
    const configurationModule = ConfigurationModule()
    const loader = Loader()
    const newTagName = ref("")
    const Form = ref<VForm>()
    const Accordion = ref<VForm>()
    const validForm = ref(false)
    const openedTags = ref<string[]>([])

    const rules = computed(() => {
      return [
        (value: string) => {
          const forbiddenNames: string[] = configurationModule.forbiddenTagNames

          if (forbiddenNames.length > 0) {
            const _lower = (word: string) => word.toLocaleLowerCase().trim()
            const containsForbiddenNames = !forbiddenNames.every(name => _lower(name) !== _lower(value))

            return containsForbiddenNames ? t("containsForbiddenNames") : true
          }

          return true
        }
      ]
    })

    const tags = computed(() => {
      return organizationModule.organizationDetail.spaceTagConfigurations?.allowedTags ?? []
    })

    const organizationId = computed(() => {
      return organizationModule.organizationDetail.id
    })

    const addNewTag = () => {
      if (Form.value && Form.value.validate()) {
        save({
          name: newTagName.value,
          value: {
            options: [],
            requiredOnNewSpaces: false,
            allowNewValues: true
          }
        })

        _openTag(newTagName.value)

        newTagName.value = ""
        Form.value.resetValidation()
      }
    }

    const save = (tagDetail: OrganizationTag) => {
      loader.run(async () => {
        await adminModule.editOrganizationTag(organizationId.value, tagDetail)
        await organizationModule.getOrganizationDetail(organizationId.value)
      })
    }

    const remove = (tagName: string) => {
      loader.run(async () => {
        await adminModule.removeOrganizationTag(organizationId.value, tagName)
        await organizationModule.getOrganizationDetail(organizationId.value)
      })
    }

    const _openTag = (tagName: string) => {
      const isAlreadyOpen = openedTags.value.includes(tagName)

      if (!isAlreadyOpen) {
        openedTags.value.push(tagName)

        setTimeout(() => {
          nextTick(() => _scrollIntoTagView(tagName))
        }, 1000)
      }
    }

    const _scrollIntoTagView = (tagName: string) => {
      if (Accordion.value?.$el) {
        const $container = Accordion.value.$el
        const nodeCollection = [...$container.childNodes]

        const $node = nodeCollection.find(node => {
          if (node.textContent) {
            const textContent = node.textContent
            return textContent.includes(tagName)
          }

          return false
        }) as HTMLElement | false

        if ($node) {
          const $anchor = $node.querySelector(".--scroll-anchor")

          if ($anchor) {
            $anchor.scrollIntoView({
              behavior: "smooth"
            })
          }
        }
      }
    }

    return {
      Accordion,
      openedTags,
      Form,
      addNewTag,
      newTagName,
      save,
      remove,
      rules,
      loader,
      tags,
      t,
      validForm
    }
  },
  components: {
    TagAccordion,
    TagPillBundler
  }
})
</script>
