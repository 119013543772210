<template>
  <div class="alignItems">
    <span>{{ $t("MyWorkspaces.filterLabels.owner") }}</span>

    <v-select v-model="selectedOwnerId" :items="userOptions" :menu-props="{ offsetY: true }" hide-details />
  </div>
</template>

<script lang="ts">
import Utils from "@/utils/utils"
import { defineComponent, PropType, computed } from "@vue/composition-api"
import { SelectItem } from "@/types/core"
import { translate } from "@/plugins/i18n"

export default defineComponent({
  name: "UserOptions",
  props: {
    value: {
      type: String,
      required: true
    },
    users: {
      type: Array as PropType<SelectItem<string>[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const selectedOwnerId = Utils.vModel(props, ctx.emit)

    const userOptions = computed(() => {
      return [{ text: translate("MyWorkspaces.filterLabels.allUsers"), value: "any" }, ...props.users]
    })

    return {
      selectedOwnerId,
      userOptions
    }
  }
})
</script>
