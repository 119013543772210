<template>
  <div id="cloudProvider" class="spaced cloudContentContainer">
    <div class="item">
      <div class="inputLabel">
        <span>
          <span>{{ $t("Onboarding.stepOne.inputs.provider") }}</span>

          <help-box :text="$t('Onboarding.stepOne.helpBox.cloudProvider')">
            <v-icon size="16">mdi-help-circle-outline</v-icon>
          </help-box>
        </span>

        <v-select v-model="settings.cloudProvider" :items="cloudProviderOptions" :menu-props="{ offsetY: true }" />
      </div>
    </div>

    <div class="item">
      <div class="regionHandler">
        <div class="inputLabel">
          <span>
            <span>{{ $t("Onboarding.stepOne.inputs.regions") }}</span>

            <help-box :text="$t('Onboarding.stepOne.helpBox.preferredRegion')">
              <v-icon size="16">mdi-help-circle-outline</v-icon>
            </help-box>
          </span>

          <div class="alignItems">
            <v-select
              v-model="settings.cloudZones"
              :items="selectItems"
              :rules="MandatoryMultipleSelect"
              :loading="appRequestingData"
              :hide-details="settings.cloudZones.length > 0"
              :menu-props="{ offsetY: true }"
              class="regionPicker"
              multiple
              clearable
            />

            <tooltip :text="$t('Onboarding.stepOne.tooltip.refresh')">
              <v-btn @click="refreshZones" :loading="appRequestingData" icon>
                <v-icon>action_refresh</v-icon>
              </v-btn>
            </tooltip>
          </div>
        </div>
      </div>

      <draggable
        v-model="settings.cloudZones"
        v-if="settings.cloudZones.length > 0"
        tag="ul"
        class="listContainer"
        ghost-class="draggingOver"
        draggable=".dragHandle"
      >
        <list-item v-for="zone in settings.cloudZones" :key="zone.text" class="dragHandle">
          <div class="leftContainer">
            <span>
              <v-icon left size="22">mdi-reorder-horizontal</v-icon>
            </span>

            <span class="d-flex align-center">
              {{ zone.fullName }}

              <tooltip v-if="zone.ping" :text="$t('Onboarding.stepOne.tooltip.latency')">
                <span class="ping" :color="pingColor(zone.ping)">
                  <small>{{ zone.ping }}ms</small>
                </span>
              </tooltip>
            </span>
          </div>

          <v-btn v-if="settings.cloudZones.length > 1" @click="removeRegion(zone)" icon>
            <v-icon>action_trash</v-icon>
          </v-btn>
        </list-item>
      </draggable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { MandatoryMultipleSelect } from "@/utils/input-rules"
import { LicenseModule } from "@/store/license-server"
import { AdminModule } from "@/store/admin"
import { CloudZone } from "@/types/core"
import CloudProviderOptions from "../../lib/cloud-provider-options"
import Draggable from "vuedraggable"
import ListItem from "@/components/listItem/ListItemRoot.vue"
import Utils from "@/utils/utils"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import HelpBox from "@/components/helpBox/HelpBox.vue"

export interface CloudSettings {
  cloudZones: CloudZone[]
  cloudProvider: string
}

export default defineComponent({
  name: "CloudProvider",
  props: {
    value: {
      type: Object as PropType<CloudSettings>,
      required: true
    },
    preventAutoFill: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const appRequestingData = ref(false)
    const adminModule = AdminModule()
    const cloudProviderOptions = CloudProviderOptions()
    const licenseModule = LicenseModule()
    const settings = Utils.vModel<CloudSettings>(props, ctx.emit)

    const selectItems = computed(() => {
      return Utils.createSelectItems(adminModule.bestRegions, "fullName")
    })

    const _getAllRegions = async () => {
      if (licenseModule.regions.length === 0) {
        await licenseModule.getRegions()
      }
    }

    const _pendingRegions = () => {
      const currentRegions = settings.value.cloudZones
      const regionIndex = 3 - currentRegions.length

      const availableRegions = adminModule.bestRegions
        .filter(region => {
          return !currentRegions.find(_r => _r.name === region.name)
        })
        .sort((zoneA, zoneB) => (zoneA?.ping ?? 0) - (zoneB?.ping ?? 0))

      return availableRegions.slice(0, regionIndex)
    }

    const _updateSelectedCloudZonePing = () => {
      settings.value.cloudZones = settings.value.cloudZones.map(_zone => {
        const updatedZone = adminModule.bestRegions.find(_z => _z.name === _zone.name)

        return {
          ..._zone,
          ping: updatedZone?.ping ?? _zone.ping
        }
      })
    }

    const _getSupportedRegions = async () => {
      try {
        appRequestingData.value = true

        await _getAllRegions()
        await adminModule.getSupportedRegions(licenseModule.regions)

        _updateSelectedCloudZonePing()

        if (settings.value.cloudZones.length < 3) {
          settings.value.cloudZones = [...settings.value.cloudZones, ..._pendingRegions()]
        }

        ctx.emit("refresh:pending", settings.value.cloudZones)
      } finally {
        appRequestingData.value = false
      }
    }

    if (!props.preventAutoFill) {
      _getSupportedRegions()
    }

    const pingColor = (ping: number) => {
      if (ping <= 100) {
        return "fastPing"
      } else if (ping < 300) {
        return "slowPing"
      } else {
        return "verySlowPing"
      }
    }

    const removeRegion = (region: CloudZone) => {
      const indexOfRegion = settings.value.cloudZones.findIndex(item => item.code === region.code)

      if (indexOfRegion !== -1) {
        settings.value.cloudZones.splice(indexOfRegion, 1)
      }
    }

    const refreshZones = () => {
      _getSupportedRegions()
    }

    return {
      appRequestingData,
      adminModule,
      settings,
      cloudProviderOptions,
      MandatoryMultipleSelect,
      selectItems,
      refreshZones,
      pingColor,
      removeRegion
    }
  },
  components: {
    Draggable,
    HelpBox,
    Tooltip,
    ListItem
  }
})
</script>
