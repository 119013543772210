<template>
  <div class="tagEditor">
    <div class="preDefinedTags">
      <v-chip v-for="tag in preDefinedTags" :key="tag" small>
        {{ tag }}
      </v-chip>
    </div>

    <div class="newTags">
      <v-chip v-for="tag in newWorkspaceTags" :key="tag" small>
        {{ tag }}

        <v-btn @click="removeTag(tag)" class="animateFadeLeft" icon rounded>
          <v-icon class="removeTag" size="12"> action_close </v-icon>
        </v-btn>
      </v-chip>

      <v-btn v-if="!addingNewTag" @click="addingNewTag = true" icon>
        <v-icon>action_add</v-icon>
      </v-btn>

      <div class="controller" v-else>
        <v-text-field
          v-model="newTagName"
          @keypress.enter="addTag"
          @keypress.esc="addingNewTag = false"
          label="Add new tag"
          autofocus
          hide-details
          dense
        />

        <div class="alignedButtons">
          <v-btn @click="addTag" icon>
            <v-icon>action_save</v-icon>
          </v-btn>

          <v-btn @click="addingNewTag = false" icon>
            <v-icon>action_close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "TagEditor",
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const workspaceModule = WorkspaceModule()
    const newWorkspaceTags = Utils.vModel(props, ctx.emit)
    const addingNewTag = ref(false)
    const newTagName = ref("")

    const preDefinedTags = computed(() => {
      return workspaceModule.selectedTemplate.schema.tags
    })

    const addTag = () => {
      if (newTagName.value.length >= 2) {
        newWorkspaceTags.value.push(newTagName.value)

        addingNewTag.value = false
        newTagName.value = ""
      } else {
        addingNewTag.value = false
      }
    }

    const removeTag = (tag: string) => {
      if (tag && newWorkspaceTags.value.includes(tag)) {
        const indexOfTag = newWorkspaceTags.value.indexOf(tag)

        newWorkspaceTags.value.splice(indexOfTag, 1)
      }
    }

    return {
      newWorkspaceTags,
      addingNewTag,
      newTagName,
      preDefinedTags,
      removeTag,
      addTag
    }
  }
})
</script>
