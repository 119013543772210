<template>
  <tooltip v-if="shouldShowAlert && isEditorOrAbove" :text="textTooltip">
    <v-icon :color="icon.color" :size="16">
      {{ icon.use }}
    </v-icon>
  </tooltip>
</template>

<script lang="ts">
import type { IWorkspaceAsset } from "@/types/workspace"
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceStates } from "@/config/workspace-states"
import { appChanges, AppDiffType } from "@/utils/workspaceDiff"
import { useStore } from "@/composable/useStore"
import { translate } from "@/plugins/i18n"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "AppCardWarning",
  props: {
    appId: {
      type: String,
      required: true
    },
    flagType: {
      type: String as PropType<AppDiffType>,
      required: true
    }
  },
  setup(props) {
    const { spaceModule, usersModule } = useStore()

    const isDeployed = computed(() => {
      const space = spaceModule.watching
      return WorkspaceStates.isDeployed(space.status)
    })

    const app = computed(() => {
      return spaceModule.watching.schema.assets.find(app => {
        return app.id === props.appId
      }) as IWorkspaceAsset
    })

    const appHasError = computed(() => {
      const hasFailed = WorkspaceStates.failed(spaceModule.watching.status)
      return Boolean(app.value.onlineError) || hasFailed
    })

    const isEditorOrAbove = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const shouldShowAlert = computed(() => {
      const space = spaceModule.watching
      const hasFailed = WorkspaceStates.failed(space.status)

      if (hasFailed) {
        return true
      } else if (isDeployed.value) {
        return wasModified.value || appHasError.value
      }

      return false
    })

    const iconColor = computed(() => {
      const isError = icon.value.includes("status_error")
      return isError ? "error" : "warning"
    })

    const icon = computed(() => {
      const warningIcon = "status_warning"
      const dangerIcon = "status_error"

      if (appHasError.value) {
        return dangerIcon
      } else {
        return warningIcon
      }
    })

    const wasModified = computed(() => {
      const space = spaceModule.watching
      const changes = appChanges(space)
      return changes?.modified.includes(app.value.id)
    })

    const textTooltip = computed(() => {
      const errorFallback = translate("WorkspaceEdit.appCard.label.somethingIsWrong")

      if (appHasError.value) {
        return app.value.onlineError || errorFallback
      } else if (wasModified.value) {
        return translate("WorkspaceEdit.appCard.label.modified")
      } else {
        return ""
      }
    })

    return {
      icon: {
        use: icon.value,
        color: iconColor.value
      },
      textTooltip,
      isEditorOrAbove,
      shouldShowAlert
    }
  },
  components: {
    Tooltip
  }
})
</script>
