<template>
  <span class="externalLink">
    <a :href="src.link" target="_blank" rel="nofollow noreferrer">{{ src.label }}</a>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { ExternalLink } from "../../lib/step-by-step-links"

export default defineComponent({
  name: "ExternalLink",
  props: {
    src: {
      type: Object as PropType<ExternalLink>,
      required: true
    }
  }
})
</script>
