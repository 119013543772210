import { WorkspaceTemplate } from "@/types/workspace"

export const applyLastModifiedFilter = (dateTargetInMs: number, list: WorkspaceTemplate[]) => {
  return list.filter(workspace => {
    if (workspace?.stateChange) {
      const workspaceLastModifiedInMs = new Date(workspace.stateChange.changeDate).getTime()

      return workspaceLastModifiedInMs >= dateTargetInMs
    }

    return false
  })
}
