<template>
  <div class="dnsMessages">
    <generic-alert
      v-if="shouldSeeAlert && warningMessage"
      @click="confirmThenEmitRedeploy"
      icon="status_warning"
      :action="actionText"
      :disabled="disabled"
      :loading="loading"
      :type="colorType"
    >
      <p>{{ warningMessage }}</p>
    </generic-alert>
  </div>
</template>

<script lang="ts">
import { CertificateVisibleState, CertificateInfo, CertificateStatus } from "@/types/workspace"
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { UsersModule } from "@/store/users"
import GenericAlert from "./GenericAlert.vue"
import Utils from "@/utils/utils"
import Router from "@/router"

export default defineComponent({
  name: "DNSMessages",
  props: {
    certificate: {
      type: Object as PropType<CertificateInfo<CertificateStatus>>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()

    const colorType = computed(() => {
      const { warning, danger } = CertificateVisibleState
      const currentState = props.certificate.visibleState

      return currentState === danger ? danger : warning
    })

    const confirmThenEmitRedeploy = async () => {
      const { ...T } = CertificateStatus
      const redeployItems = [T.newCertificateAvailable, T.workspaceCertificateExpired, T.certificateAvailable]

      if (redeployItems.includes(props.certificate.status)) {
        if (await raiseConfirmation({ text: translate("WorkspaceEdit.alerts.confirmation.confirmRedeploy") })) {
          ctx.emit("click")
        }
      } else {
        const routeIsWorkspace = Router.currentRoute.name === "WorkspaceEdit"
        const snippetInLicense = "WorkspaceEdit.alerts.confirmation.renewCertificateThenDeployInLicense"
        const snippetInWorkspace = "WorkspaceEdit.alerts.confirmation.renewCertificateThenDeployInWorkspace"
        const snippet = routeIsWorkspace ? snippetInWorkspace : snippetInLicense

        if (await raiseConfirmation({ text: translate(snippet) })) {
          ctx.emit("renew:certificate")
        }
      }
    }

    const shouldSeeAlert = computed(() => {
      const hasPermissions = isAdminOrEditor.value

      if (!hasPermissions) {
        const { newCertificateAvailable } = CertificateStatus
        const currentStatus = props.certificate.status

        return Boolean(newCertificateAvailable !== currentStatus)
      }

      return true
    })

    const actionText = computed(() => {
      const { ...T } = CertificateStatus
      const _t = (snippet: string) => translate(`WorkspaceEdit.dnsAction.${snippet}`)
      const renewItems = [T.certificateIncluded, T.organizationCertificateExpired]
      const redeployItems = [T.newCertificateAvailable, T.workspaceCertificateExpired, T.certificateAvailable]
      const status = props.certificate.status

      if (renewItems.includes(status) && usersModule.hasAdminPermissions) {
        return _t("renew")
      } else if (redeployItems.includes(status) && usersModule.hasEditorPermissions) {
        return _t("redeploy")
      }

      return false
    })

    const isAdminOrEditor = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const _expirationDate = computed(() => {
      if (props.certificate.expiresAt) {
        return Utils.dateWithNamedMonth(props.certificate.expiresAt)
      }

      return null
    })

    const warningMessage = computed(() => {
      const status = props.certificate.status
      const state = props.certificate.visibleState
      let snippet = `WorkspaceEdit.dnsStatus.${status}.noPermissions`

      if (isAdminOrEditor.value) {
        switch (props.certificate.status) {
          case CertificateStatus.certificateIncluded:
          case CertificateStatus.newCertificateAvailable:
            snippet = `WorkspaceEdit.dnsStatus.${status}.${state}`
            break

          default:
            snippet = `WorkspaceEdit.dnsStatus.${status}.withPermissions`
            break
        }
      }

      if (_expirationDate.value) {
        return translate(snippet, { expirationDate: _expirationDate.value })
      }

      return ""
    })

    return {
      shouldSeeAlert,
      warningMessage,
      actionText,
      colorType,
      confirmThenEmitRedeploy
    }
  },
  components: {
    GenericAlert
  }
})
</script>
