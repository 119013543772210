import { WorkspaceTemplate } from "@/types/workspace"
import { CommunicationStructure } from "./types"

type Callback = () => void

const variants = {
  STATUS: "status"
}

const _getWebview = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w = window as any
  const webview = w?.chrome?.webview

  return webview
}

export const registerWebviewEvent = (actionName: string, callback: Callback) => {
  window.addEventListener(actionName, () => {
    callback()
  })
}

export const webviewCommunicator = () => {
  const webview = _getWebview()

  const sendMessage = <T, Data>(message: string | CommunicationStructure<T, Data>) => {
    if (webview && webview?.postMessage) {
      webview.postMessage(message)
    }
  }

  return {
    sendMessage
  }
}

export const webView = webviewCommunicator()

export const notifyStatusChange = (workspace: WorkspaceTemplate) => {
  const { status, onlineStatus } = workspace

  webView.sendMessage({
    variant: variants.STATUS,
    message: {
      status,
      onlineStatus
    }
  })
}

export const notifyCustomMessage = <T, Data>(communication: CommunicationStructure<T, Data>) => {
  webView.sendMessage(communication)
}
