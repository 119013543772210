import { NavigationGuardNext, Route } from "vue-router"
import { session } from "@/auth/session"
import { raiseError } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { validateLicense } from "@/utils/event-bus"
import { PlatformViewType } from "@/types/core"

const sendTo = (name: string, to: Route, next: NavigationGuardNext<Vue>) => {
  if (to.name !== name) {
    return next({ name })
  }
}

const _updateTitle = (route: Route) => {
  if (route.meta?.title) {
    document.title = `Viz Now - ${route.meta.title}`
  } else {
    document.title = `Viz Now`
  }
}

export const authGuard = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const isValidUser = session.auth.isAuthenticated
  const nextRouteNeedsAuthPermission = to.meta?.requiresAuth ?? true
  const requireSysAdmin = to.meta?.requireSysAdmin ?? false
  const requireAdmin = to.meta?.requireAdmin ?? false

  if (!session.auth.initiated) {
    return next()
  }

  if (isValidUser) {
    validateLicense()
  }

  _updateTitle(to)

  if (isValidUser && !nextRouteNeedsAuthPermission) {
    return sendTo("Root", to, next)
  } else if (!isValidUser && nextRouteNeedsAuthPermission) {
    raiseError({ text: translate("common.notAuthorized") })
    return sendTo("Login", to, next)
  } else if (isValidUser && nextRouteNeedsAuthPermission) {
    const isSysAdmin = session.auth.isSysAdmin
    const isAdmin = session.auth.isAdmin
    const isSupport = session.auth.isSupport

    if (requireSysAdmin && !isSysAdmin && !isSupport) {
      return sendTo("Root", to, next)
    } else if (requireAdmin && !isSysAdmin && !isSupport && !isAdmin) {
      return sendTo("Root", to, next)
    }
  }

  if (to.meta?.strictViewSettings) {
    const hasNoAccess = !validateCanAccessStrictView(to.meta.strictViewSettings)

    if (hasNoAccess) {
      return sendTo("Root", to, next)
    }
  }

  return next()
}

export const validateCanAccessStrictView = (strictView: string | null) => {
  const { APP_LAUNCHER, VIZ_NOW } = PlatformViewType
  const viewSettings = localStorage.getItem("view-settings")
  const appLauncherAvailable = viewSettings === APP_LAUNCHER
  const vizNowAvailable = viewSettings === VIZ_NOW

  const noPermission =
    (!appLauncherAvailable && strictView === APP_LAUNCHER) || (!vizNowAvailable && strictView === VIZ_NOW)

  return !noPermission
}
