export enum FilterStatusOption {
  deployedOnline = "deployed-online",
  deployedOffline = "deployed-offline",
  deploying = "deploying",
  archived = "archived",
  archiving = "archiving",
  restoring = "restoring",
  failed = "failed",
  draft = "draft"
}

export interface FilterStatusTranslation {
  status: string[]
  onlineStatus: string | null
}

export interface AdvancedFilterConfig {
  ownerId: string | "any"
  lastModified: number | "any"
  status: FilterStatusOption[]
  search: string
}
