import { SelectItem } from "@/types/core"
import { CLOUD_PROVIDER } from "@/config/cloud-provider"

const providerOptions: () => SelectItem<string>[] = () => [
  { text: CLOUD_PROVIDER.options.aws, value: CLOUD_PROVIDER.options.aws },
  { text: CLOUD_PROVIDER.options.azure, value: CLOUD_PROVIDER.options.azure, disabled: true }
]

export default providerOptions

export const DEFAULTS = {
  cloudProvider: CLOUD_PROVIDER.options.aws
}
