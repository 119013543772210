<template>
  <div class="organizationCard">
    <h3>{{ t("title") }}</h3>

    <div class="organizationContainer">
      <div v-if="logo" class="logoSection" :style="{ backgroundImage: `url(${logo})` }" />

      <v-label>
        <span>{{ t("name") }}</span>

        <div class="wrapInputHandler">
          <v-text-field
            v-if="hasPermissions"
            v-model="organizationPlaceholderName"
            @keypress.enter="changeName"
            :disabled="loading"
            hide-details
          />

          <span v-else>
            <strong>{{ organizationPlaceholderName }}</strong>
          </span>

          <v-btn
            v-if="hasPermissions"
            @click="changeName"
            :disabled="!detectedNewName"
            :loading="loading && detectedNewName"
            color="primary"
          >
            <v-icon>mdi-content-save-all-outline</v-icon>
          </v-btn>
        </div>
      </v-label>
    </div>

    <div class="organizationContainer subscription">
      <v-label>
        <span>{{ t("type") }}</span>
        <strong>{{ t(`organizationType.${organization.organizationType}`) }}</strong>
      </v-label>

      <v-label v-if="walletDetails.subscriptionType && creditBasedOrganization && walletDetails">
        <span>{{ t("subscription") }}</span>
        <strong>{{ walletDetails.subscriptionType }}</strong>
      </v-label>

      <v-label v-if="creditBasedOrganization && walletDetails">
        <span>{{ t("credits") }}</span>
        <strong>{{ credits }}</strong>
      </v-label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { IMedia } from "@/types/media"
import { OrganizationType } from "@/types/organization"
import { createTranslationModule } from "@/plugins/i18n"
import { OrganizationModule } from "@/store"

export default defineComponent({
  name: "OrganizationDetails",
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    fileList: {
      type: Array as PropType<IMedia[]>,
      required: true
    },
    hasPermissions: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const organizationModule = OrganizationModule()
    const t = createTranslationModule("OrganizationPageV2.organizationDetails")
    const organizationPlaceholderName = ref(organizationModule.organizationDetail.name)

    const logo = computed(() => {
      const media = props.fileList

      if (media && media.slice(-1).length) {
        if (media[0] && media[0]?.contentsBase64) {
          const mimeType = `data:${media[0].mimeType};base64,`
          const hasMimeType = media[0].contentsBase64.includes(mimeType)

          if (hasMimeType) {
            return media[0].contentsBase64
          } else {
            return mimeType + media[0].contentsBase64
          }
        }
      }

      return false
    })

    const organization = computed(() => {
      return organizationModule.organizationDetail
    })

    const creditBasedOrganization = computed(() => {
      return organization.value?.organizationType === OrganizationType.SaaS
    })

    const detectedNewName = computed(() => {
      return organizationPlaceholderName.value !== organization.value.name
    })

    const walletDetails = computed(() => {
      return organizationModule.walletDetail
    })

    const credits = computed(() => {
      if (walletDetails.value.amount) {
        return `${walletDetails.value.amount}`.padStart(2, "0")
      }
      return 0
    })

    const changeName = () => {
      ctx.emit("change-name", organizationPlaceholderName.value)
    }

    return {
      t,
      credits,
      walletDetails,
      creditBasedOrganization,
      organization,
      changeName,
      organizationPlaceholderName,
      detectedNewName,
      logo
    }
  }
})
</script>
