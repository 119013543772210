<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    :disabled="wasRemoved"
    :nudge-bottom="35"
    content-class="tagEditMenu"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="menuContainer" :class="disabled ? '--click-disabled' : ''">
        <tooltip :text="$t('TagPill.clickToEdit')" :disabled="!!tagValue || wasRemoved">
          <string-max custom-class="tagName" :text="tag.name" :max="MAX_TAG_NAME_VIEW" :tooltip-disabled="!tagValue" />
        </tooltip>

        <string-max v-if="tagValue" custom-class="tagValue" :text="tagValue" :max="MAX_TAG_VALUE_VIEW" />

        <tooltip :text="$t('WorkspaceEdit.tags.removedTag')" :disabled="!wasRemoved">
          <v-btn v-if="!disabled && !isRequired" :disabled="loading" @click.stop="confirmRemove" icon>
            <v-icon v-if="!wasRemoved">mdi-close</v-icon>
            <v-icon v-else>mdi-restore</v-icon>
          </v-btn>
        </tooltip>
      </div>
    </template>

    <div class="editTagValue">
      <handle-selector
        v-if="menuOpen"
        @edit="edit"
        :loading="loading"
        :current-value="tag.value"
        :settings="settings"
      />
    </div>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { SpaceTag } from "@/types/workspace"
import { TagOptions } from "@/types/organization"
import { MAX_TAG_NAME_VIEW, MAX_TAG_VALUE_VIEW } from "../../tagSearchField/lib/max-character"
import HandleSelector from "../../tagSearchField/components/_HandleSelector.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import StringMax from "@/components/stringMax"

enum ValueType {
  createOnly = "CreateOnly",
  selectOnly = "SelectOnly",
  createOrSelect = "CreateOrSelect"
}

export default defineComponent({
  name: "TagMenu",
  props: {
    tag: {
      type: Object as PropType<SpaceTag>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    settings: {
      type: Object as PropType<TagOptions>,
      required: false
    },
    wasRemoved: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const menuOpen = ref(false)

    const valueSetterType = computed(() => {
      if (props.settings && props.settings.options.length > 0) {
        if (props.settings.allowNewValues) {
          return ValueType.createOrSelect
        } else {
          return ValueType.selectOnly
        }
      }

      return ValueType.createOnly
    })

    const tagOptions = computed(() => {
      if (props.settings) {
        return props.settings.options
      }

      return []
    })

    const isRequired = computed(() => {
      return props?.settings?.requiredOnNewSpaces ?? false
    })

    const tagValue = computed(() => {
      return props.tag.value || false
    })

    const isEmpty = computed(() => {
      return !props.tag.value
    })

    const isType = (type: ValueType) => {
      return valueSetterType.value === type
    }

    const confirmRemove = async () => {
      if (props.wasRemoved) {
        ctx.emit("restore", props.tag)
      } else if (await raiseConfirmation({ text: translate("TagPill.confirmRemove", { tagName: props.tag.name }) })) {
        ctx.emit("delete", props.tag.name)
      }
    }

    const edit = (value: string) => {
      ctx.emit("edit", {
        name: props.tag.name,
        value
      })

      menuOpen.value = false
    }

    return {
      isRequired,
      tagOptions,
      isType,
      ValueType,
      confirmRemove,
      tagValue,
      isEmpty,
      edit,
      menuOpen,
      MAX_TAG_NAME_VIEW,
      MAX_TAG_VALUE_VIEW
    }
  },
  components: {
    StringMax,
    HandleSelector,
    Tooltip
  }
})
</script>
