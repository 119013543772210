<template>
  <div class="dialogFooterContainer">
    <v-btn @click="$emit('cancel')" rbt-target="cancelOperation" :disabled="loading" large>
      <v-icon left>action_remove_circle</v-icon>
      <span>{{ $t("WorkspaceEdit.appDetails.buttons.cancel") }}</span>
    </v-btn>

    <v-btn @click="deleteApp" rbt-target="deleteAppDetails" :disabled="!hasPermission || loading" large>
      <v-icon left>action_trash</v-icon>
      <span>{{ $t("WorkspaceEdit.appDetails.buttons.delete") }}</span>
    </v-btn>

    <v-btn
      @click="save"
      color="primary"
      rbt-target="saveAppDetails"
      :disabled="!hasPermission || loading || disableSave"
      large
    >
      <v-icon left>action_save</v-icon>
      <span>{{ $t("WorkspaceEdit.appDetails.buttons.save") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { UsersModule } from "@/store"

export default defineComponent({
  name: "DialogFooter",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disableSave: {
      type: Boolean,
      default: false
    }
  },
  setup(_, ctx) {
    const usersModule = UsersModule()

    const hasPermission = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const deleteApp = async () => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.appDetails.confirm.deleteApp") })) {
        ctx.emit("remove")
      }
    }

    const save = async () => {
      ctx.emit("save")
    }

    return {
      hasPermission,
      deleteApp,
      save
    }
  }
})
</script>
