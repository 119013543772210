import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { UploadReqContextId, FileUploadPayload } from "@/types/core"
import { IMedia } from "@/types/media"

const api = new Request("/v1/Media/")

export default createService("upload", {
  async getContextId(payload: UploadReqContextId) {
    const { context, contextId, ignoreExhibition } = payload
    const endpoint = `GetByContextItemId/${context}/${contextId}/${ignoreExhibition ?? true}`

    return await api.get<IMedia[]>(endpoint)
  },

  async sendFile(payload: FileUploadPayload, progressFeedback: (progress: number) => number) {
    return await api.upload("", payload, progressFeedback)
  },

  async removeFile(fileId: string): Promise<void> {
    await api.delete(fileId)
  }
})
