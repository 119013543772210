<template>
  <div class="addTokens">
    <div class="group">
      <div class="inputLabel">
        <span>{{ t("purchaseId") }}</span>
        <v-text-field
          v-model="addTokenPayload.purchaseId"
          :disabled="loading"
          :rules="rules.text"
          :placeholder="t('placeholder.purchaseId')"
          name="purchaseId"
          hide-details
        />
      </div>
    </div>

    <div class="inputLabel">
      <span>{{ t("label") }}</span>
      <v-text-field
        v-model="addTokenPayload.description"
        :disabled="loading"
        :rules="rules.text"
        :placeholder="t('placeholder.label')"
        name="description"
        hide-details
      />
    </div>

    <div class="group">
      <div class="inputLabel">
        <span>{{ t("activationDate") }}</span>

        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="activationDate"
              v-bind="attrs"
              v-on="on"
              :disabled="loading"
              :rules="rules.text"
              name="startDate"
              chips
              hide-details
              readonly
            />
          </template>

          <v-date-picker v-model="addTokenPayload.activationDate" no-title scrollable />
        </v-menu>
      </div>

      <div class="inputLabel">
        <span>{{ t("expirationDate") }}</span>

        <v-menu transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="expirationDateFormat"
              v-bind="attrs"
              v-on="on"
              :disabled="loading"
              name="expirationDate"
              clearable
              chips
              hide-details
              readonly
            />
          </template>

          <v-date-picker v-model="addTokenPayload.expirationDate" no-title scrollable />
        </v-menu>
      </div>
    </div>

    <div class="group">
      <div class="inputLabel">
        <span>{{ t("amount") }}</span>
        <v-text-field
          v-model="tokenAmount"
          :disabled="loading"
          :rules="rules.token"
          :placeholder="t('placeholder.amount')"
          hide-details
        />
      </div>

      <div class="inputLabel">
        <span>{{ t("newBalance") }}</span>
        <v-text-field :value="newBalance" :disabled="true" hide-details />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { AddTokenPayload } from "@/types/organization"
import { toInteger } from "./lib/transaction-history-table-config"
import { rules } from "./lib/rules"
import { translate } from "@/plugins/i18n"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "AddTokenInputs",
  props: {
    value: {
      type: Object as PropType<AddTokenPayload>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    tokens: {
      type: Number,
      required: true
    }
  },
  setup(props, ctx) {
    const addTokenPayload = Utils.vModel(props, ctx.emit)
    const t = (snippet: string) => translate("Admin.organizations.createPopup.addTokenFields." + snippet)

    const tokenAmount = computed({
      get() {
        return toInteger(addTokenPayload.value.initialAmount)
      },
      set(value: string) {
        addTokenPayload.value.initialAmount = Number(toInteger(value))
      }
    })

    const activationDate = computed({
      get() {
        return Utils.dateFormat(addTokenPayload.value.activationDate)
      },
      set(value: string) {
        addTokenPayload.value.activationDate = new Date(value).toISOString()
      }
    })

    const expirationDateFormat = computed({
      get() {
        if (addTokenPayload.value.expirationDate) {
          return Utils.dateFormat(addTokenPayload.value.expirationDate)
        }
        return ""
      },
      set(value: string) {
        const date = new Date(value).toISOString()
        addTokenPayload.value.expirationDate = value ? date : ""
      }
    })

    const newBalance = computed(() => {
      const newValue = Number(toInteger(addTokenPayload.value.initialAmount))
      return Math.floor(newValue + props.tokens)
    })

    return {
      t,
      activationDate,
      rules,
      expirationDateFormat,
      tokenAmount,
      newBalance,
      addTokenPayload
    }
  }
})
</script>
