import { translate } from "@/plugins/i18n"

const DEFAULTS = {
  title: () => translate("Confirmation.title"),
  text: () => translate("Confirmation.text"),
  onConfirm: new Function(),
  onCancel: new Function(),
  confirmText: () => translate("common.confirm"),
  cancelText: () => translate("common.cancel")
}

export { DEFAULTS }
