import { translate } from "@/plugins/i18n"
import { Dictionary } from "@/types/core"

const DAY_IN_MS = 1000 * 60 * 60 * 24

export const DATES = {
  today: Date.now() - DAY_IN_MS,
  thisWeek: Date.now() - DAY_IN_MS * 7,
  lastWeek: Date.now() - DAY_IN_MS * 14,
  thisMonth: Date.now() - DAY_IN_MS * 31,
  lastMonth: Date.now() - DAY_IN_MS * 62
} as Dictionary<number>

export const structuredDates = () => {
  return Object.keys(DATES).map(key => {
    return {
      text: translate(`MyWorkspaces.lastModified.${key}`),
      value: DATES[key]
    }
  })
}
