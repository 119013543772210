export type Context = string & ("workspace-asset-files" | "organization-logo" | "feedback-files")

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PluginExceptionAny = any

export enum PlatformViewType {
  APP_LAUNCHER = "app-launcher",
  VIZ_NOW = "viz-now"
}

export interface Dictionary<T = unknown> {
  [key: string]: T
}

export interface VModel<T = unknown> {
  value: T
}

export interface AppReadyState {
  readyToUse: boolean
  warning: string | null
}

export interface Username {
  firstName: string
  lastName?: string
  email?: string
}

export type Id = string

export interface FileProgress {
  id: null | Id
  name: string
  progress: number
  error: boolean
}

export interface FileUploadPayload {
  id?: Id
  fileName: string
  context: Context
  mimeType: string
  contentsBase64: string
  size: number
  contextItemId: Id
  creationDate: string
  secondKey?: string
  description?: string
}
export interface UploadReqContextId {
  context: Context
  contextId: string
  ignoreExhibition?: boolean
}

export interface SelectItem<T> {
  text: string
  value: T
  disabled?: boolean
}

export interface SimpleItem extends Dictionary {
  id: Id
  name: string
}

export interface VForm extends Vue {
  validate: () => boolean
  reset: () => void
  focus: () => void
  resetValidation: () => void
  $el: Element
}

export interface VCombobox<T> extends Vue {
  computedItems: SelectItem<T>[]
  internalSearch: string
}

export interface EventBusConfirmation {
  onConfirm?: () => void
  onCancel?: () => void
  text?: string
  title?: string
  strictLabel?: string
  buttonConfirmLabel?: string
  buttonCancelLabel?: string
}
export interface CloudZone {
  name: string
  fullName: string
  code: string
  public: boolean
  zones: string[]
  ping?: number
  zone_limit?: number
}

export interface PublicAWS {
  zone: string
  name: string
  fullName: string
}

export interface HeaderItem {
  text: string
  value: string
  sortable?: boolean
  align?: string
  cellClass?: string
}

export interface RegisteredIp {
  ip: string
  description: string
}

export interface ProviderSettings {
  organizationId: Id
  spaceliftAwsRoleArn: string
  vizrtAwsRoleArn: string
  vizrtAwsRoleExternalId: string
  awsDeployAccountId: string
  dnsName: string | null
  ipRange: string
}

export interface ArnUpdate {
  organizationId: Id
  spaceliftAwsRoleArn: string
  awsDeployAccountId: string
  vizrtAwsRoleArn: string
  vizrtAwsRoleExternalId: string
}

export interface PayloadUpdateRegion {
  id: Id
  cloudProviderName: string
  cloudZones: string[]
}

export interface ICloudProvider {
  name: string
}

export interface TranslationOptions extends Dictionary<string> {
  en: string
  pt: string
}

export interface CachedOrganization {
  organizationId: string
}

export type RequestErrorResponse = { httpStatusCode: number; message: string; details: string }
