<template>
  <popup
    v-model="details.open"
    @confirm="handleConfirmation"
    :loading="loader.on"
    :title="$t('Admin.organizations.editSubscriptionPopup.title')"
    button-confirm-text="Edit"
    class="editSubscription"
    width="500px"
  >
    <v-form v-model="validForm" ref="Form">
      <form-factory v-if="hasSubscriptions" v-model="formDirector" :loading="loader.on" />
    </v-form>
  </popup>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, computed, nextTick, PropType } from "@vue/composition-api"
import { AdminModule, ConfigurationModule } from "@/store"
import { EditSubscriptionPayload } from "@/types/organization"
import { FormGenerator } from "@/components/formFactory/"
import { VForm } from "@/types/core"
import Utils from "@/utils/utils"
import Loader from "@/utils/loader"
import FormFactory from "@/components/formFactory"
import Popup from "@/components/popup/PopupRoot.vue"
import subscriptionDirector from "./lib/edit-subscription-form-director"

interface Details {
  id: string
  open: boolean
}

export default defineComponent({
  name: "EditSubscription",
  props: {
    value: {
      type: Object as PropType<Details>,
      required: true
    }
  },
  setup(props, ctx) {
    const configurationModule = ConfigurationModule()
    const adminModule = AdminModule()
    const Form = ref<null | VForm>(null)
    const validForm = ref(false)
    const details = Utils.vModel(props, ctx.emit)
    const loader = Loader()

    const formDirector = reactive(
      subscriptionDirector({
        subscriptionItems: configurationModule.subscriptionTypes,
        currentSubscription: adminModule.organizationDetail.tokenSubscription
      })
    )

    const hasSubscriptions = computed(() => {
      return configurationModule.subscriptionTypes.length > 0
    })

    const handleConfirmation = () => {
      if (Form.value && Form.value.validate() && validForm.value) {
        loader.run(async () => {
          const payload = FormGenerator.getProduct<EditSubscriptionPayload>(formDirector)
          const organizationId = details.value.id

          await adminModule.editOrganizationSubscription(organizationId, payload)
          ctx.emit("update:organization")

          nextTick(() => (details.value.open = false))
        })
      }
    }

    return {
      handleConfirmation,
      Form,
      validForm,
      formDirector,
      hasSubscriptions,
      loader,
      details
    }
  },
  components: {
    Popup,
    FormFactory
  }
})
</script>
