<template>
  <div class="centered">
    <progress-bar
      :progress-key="archiveKey"
      :app-id="appId"
      :online-status="status"
      :progress-director="checkStatus(Starting) ? DIRECTOR.starting : DIRECTOR.stopping"
      :last-modified="lastModifiedTime"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { ProgressCacheKey } from "../ProgressBar.vue"
import { DIRECTOR } from "../../lib/progress-step-director"
import { STOPPING_TIMER, STARTING_TIMER } from "@/config/app-timer-speculation"
import ComponentLogicMixin from "./component-logic.mixin"

export default defineComponent({
  name: "OnlineLogic",
  mixins: [ComponentLogicMixin],
  setup() {
    const archiveKey = ProgressCacheKey.APP_LAUNCHER_CACHE_KEY

    return {
      DIRECTOR,
      archiveKey,
      STOPPING_TIMER,
      STARTING_TIMER
    }
  }
})
</script>
