<template>
  <v-snackbar v-model="show" :class="color" top dismissible>
    <span>
      <v-icon v-if="icon">{{ icon }}</v-icon>
      {{ text }}

      <div v-if="showDetails || smallDetail" class="detailedError">
        <v-btn v-tippy="$t('Alert.copyErrorDetail')" @click="copy(detailsText)" class="floatingCopy" icon>
          <v-icon>action_copy</v-icon>
        </v-btn>

        <span>
          {{ detailsText }}
        </span>
      </div>
    </span>

    <template v-slot:action="{ attrs, on }">
      <v-btn
        v-if="hasDetailText"
        v-bind="attrs"
        v-on="on"
        @click="showDetails = !showDetails"
        :disabled="smallDetail"
        icon
      >
        <v-icon color="error">{{ detailsIcon }}</v-icon>
      </v-btn>

      <v-btn icon small v-bind="attrs" @click="show = false">
        <v-icon>action_close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { copyToClipboard } from "@/utils/ui"
import Setup from "./lib/setup"

export default defineComponent({
  name: "Alert",
  setup() {
    const copy = (msg: string) => {
      copyToClipboard(msg, true)
    }

    return {
      copy,
      ...Setup()
    }
  }
})
</script>
