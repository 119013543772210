<template>
  <div class="tagBundler">
    <tag-pill
      @edit="updateTag"
      @delete="deleteTag"
      @restore="restoreTag"
      :key="tags.length"
      :loading="loading || internalLoader.on"
      :items="tags"
      :value-options="valueOptions"
      :tags-not-deployed="tagsNotDeployed"
      :forbidden-names="forbiddenTags"
      :name-options="nameOptions"
      :tags-removed="tagsRemoved"
      :disabled="disabled"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { SpaceTag } from "@/types/workspace"
import { ConfigurationModule } from "@/store"
import { OrganizationTag } from "@/types/organization"
import TagPill from "./tagPill/TagPillRoot.vue"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "TagPillBundler",
  props: {
    tags: {
      type: Array as PropType<SpaceTag[]>,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    excludeForbiddenNames: {
      type: Boolean,
      default: false
    },
    valueOptions: {
      type: Array as PropType<OrganizationTag[]>,
      default: () => []
    },
    tagsNotDeployed: {
      type: Array as PropType<SpaceTag[]>,
      default: () => []
    },
    tagsRemoved: {
      type: Array as PropType<SpaceTag[]>,
      default: () => []
    }
  },
  setup(props, ctx) {
    const internalLoader = Loader()
    const configurationModule = ConfigurationModule()

    const nameOptions = computed(() => {
      return props.valueOptions.map(tag => tag.name)
    })

    const forbiddenTags = computed(() => {
      return configurationModule.forbiddenTagNames || []
    })

    const updateTag = (tag: SpaceTag) => {
      ctx.emit("edit", tag)
    }

    const restoreTag = (tag: SpaceTag) => {
      ctx.emit("restore", tag)
    }

    const deleteTag = (tagName: string) => {
      ctx.emit("delete", tagName)
    }

    internalLoader.run(async () => {
      if (props.excludeForbiddenNames) {
        await configurationModule.getForbiddenSpaceTags()
      }
    })

    return {
      restoreTag,
      nameOptions,
      forbiddenTags,
      internalLoader,
      updateTag,
      deleteTag
    }
  },
  components: {
    TagPill
  }
})
</script>
