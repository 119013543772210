import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { FileTransferConfig } from "@/types/fileTransfer"
import { WorkspaceTemplate } from "@/types/workspace"

const api = new Request("/v1/FileTransferConfig/")

export default createService("fileTransfer", {
  async setFileTransferConfig(fileTransferConfig: FileTransferConfig, workspaceId: string) {
    return await api.post<WorkspaceTemplate>(`${workspaceId}`, { ...fileTransferConfig })
  },

  async downloadFileTransfer(workspaceId: string) {
    const endpoint = `${workspaceId}/download`
    const fileName = "transferConsole.zip"

    await api.downloadItem(endpoint, fileName, "application/zip")
  }
})
