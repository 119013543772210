<template>
  <div id="preHeader">
    <div class="containTags">
      <tag-pill-bundler
        @edit="updateTag"
        @delete="deleteTag"
        @restore="restoreTag"
        :tags="spaceTags"
        :loading="loading"
        :value-options="organizationTags"
        :tags-not-deployed="tagsNotDeployed"
        :tags-removed="tagsRemoved"
        :disabled="tagEditorDisabled"
        exclude-forbidden-names
      />
    </div>

    <div class="containBoxes">
      <div class="leftBox">
        <div class="titleContainer animateFadeUp animationDelay-5">
          <img :src="cloudIcon" />

          <editable-title @update="save" :workspace="workspace" :loading="loading" />
        </div>
      </div>

      <div class="rightBox">
        <status-display @change:app-status="changeAppStatus" @abort="$emit('abort')" :workspace="workspace" />
        <metadata :workspace="workspace" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceStatusEnum, WorkspaceTemplate } from "@/types/workspace"
import { workspaceStatusIcon } from "@/utils/cloud-state-icons"
import { OrganizationModule, ConfigurationModule, UsersModule } from "@/store"
import { WorkspaceStates } from "@/config/workspace-states"
import { SpaceTag } from "@/types/workspace"
import { getTags } from "@/utils/get-space-tags"
import TagPillBundler from "@/components/tags"
import EditableTitle from "./EditableTitle.vue"
import StatusDisplay from "./StatusDisplay.vue"
import Metadata from "./Metadata.vue"

export default defineComponent({
  name: "PreHeaderRoot",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const organizationModule = OrganizationModule()
    const configurationModule = ConfigurationModule()
    const usersModule = UsersModule()

    const spaceIsLoading = computed(() => {
      return WorkspaceStates.isLoading(props.workspace.status)
    })

    const forbiddenTagNames = computed(() => {
      return configurationModule.forbiddenTagNames
    })

    const tagEditorDisabled = computed(() => {
      const noPermission = !usersModule.hasEditorPermissions
      return spaceIsLoading.value || noPermission
    })

    const organizationTags = computed(() => {
      return organizationModule.organizationDetail?.spaceTagConfigurations?.allowedTags || []
    })

    const spaceTags = computed(() => {
      return getTags(props.workspace).tags
    })

    const tagsNotDeployed = computed(() => {
      if (props.workspace.status !== WorkspaceStatusEnum.Draft) {
        return getTags(props.workspace).tagsNotDeployed
      }

      return []
    })

    const tagsRemoved = computed(() => {
      return getTags(props.workspace).removedTags
    })

    const save = (_workspace = props.workspace) => {
      ctx.emit("save", _workspace)
    }

    const cloudIcon = computed(() => {
      return workspaceStatusIcon(props.workspace)
    })

    const updateTag = (tag: SpaceTag) => {
      ctx.emit("tag:update", tag)
    }

    const changeAppStatus = (state: "online" | "offline") => {
      ctx.emit("change:app-status", state)
    }

    const createTag = (tag: SpaceTag) => {
      ctx.emit("tag:create", tag)
    }

    const deleteTag = (tagName: string) => {
      ctx.emit("tag:delete", tagName)
    }

    const restoreTag = (tag: SpaceTag) => {
      ctx.emit("tag:restore", tag)
    }

    return {
      tagEditorDisabled,
      restoreTag,
      tagsRemoved,
      tagsNotDeployed,
      spaceIsLoading,
      forbiddenTagNames,
      deleteTag,
      spaceTags,
      createTag,
      changeAppStatus,
      cloudIcon,
      updateTag,
      save,
      organizationTags
    }
  },
  components: {
    TagPillBundler,
    Metadata,
    StatusDisplay,
    EditableTitle
  }
})
</script>
