<template>
  <li class="listItem">
    <slot />
  </li>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import "./scss/_listItem.scss"

export default defineComponent({
  name: "ListItem"
})
</script>
