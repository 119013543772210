import { Dictionary } from "vue-router/types/router"
import { CloudZone } from "./core"
import { ICloudProvider, CertificateInfo } from "./workspace"
import { IMedia } from "@/types/media"

export interface TagOptions {
  options: string[]
  requiredOnNewSpaces: boolean
  allowNewValues: boolean
}
export interface OrganizationTag {
  name: string
  value: TagOptions
}

export interface GlobalTag {
  name: string
  value: string
}

export interface IOrganization {
  id: string
  name: string
  dnsName: string
  createdByUser: string
  creationDate: string
  cloudProvider: ICloudProvider
  cloudZones: string[]
  workspaceTemplates: string[]
  certificateInfo: CertificateInfo | null
  spaceTagConfigurations: {
    allowedTags: OrganizationTag[]
    spaceTagsSettings: Dictionary<string>
  }
  cidrBlocks: CidrBlock[]
  organizationType: OrganizationType
  tokenSubscription: Subscription
  tokens: number
  tags: GlobalTag[]
}

export enum OrganizationCertificateStatus {
  validating = "validating",
  available = "available",
  failed = "failed"
}

export interface Subscription {
  expirationDate: string
  purchaseOrder: string
  startDate: string
  startingTokens: number | string
  subscribedTokens: number | string
  type: string
}

export interface WalletTransaction {
  amount: number
  description: string
  transactionDate: string
}

export interface WalletToken {
  id: string
  initialAmount: number
  usedAmount: number
  remainingAmount: number
  description: string
  tokenState: string
  creationDate: string
  expirationDate: string | null
  activationDate: string | null
  transactions: WalletTransaction[]
}

export interface EditSubscriptionPayload {
  subscription: string
  expires: string
  monthlyCredits: number | string
  description: string
  purchaseOrder: string
}

export interface Wallet {
  amount: number
  timeLeft: number
  subscriptionType: string
  walletTokens: WalletToken[]
}

export interface AddTokenPayload {
  purchaseId: string
  description: string
  initialAmount: number
  activationDate: string
  expirationDate: string
}

export interface RemoveTokenPayload {
  amount: number
  description: string
}

export interface SimpleOrganizationItem {
  id: string
  name: string
  createDate: string
  createdByUser: string
}

export interface OrganizationItem {
  id: string
  name: string
  creationDate: string
  createdByUser: string
  certificateInfo: CertificateInfo | null
  organizationType: OrganizationType
  isReady: boolean
  deployedWorkspaces: {
    id: string
    isCore: boolean
  }[]
}

export interface CidrBlock {
  cidrBlock: string
  stillAvailable: number
}

export interface OrganizationFileList {
  id: string
  name: string
  fileList: IMedia[]
}

export interface ILicenseServerInfo {
  isDeploying: boolean
  region: string
  instanceId: string
  ip: string
}

export interface SaaSSettings {
  purchaseOrder: string
  type: string
  startingTokens: string
  subscribedTokens: string
  startDate: string
  expirationDate: string
}

export interface CreateOrganizationPayload {
  cloudProviderName: string
  cloudZones: CloudZone[]
  name: string
  organizationType: OrganizationType
  subscription: SaaSSettings | null
}

export enum OrganizationType {
  CustomerHosted = "CustomerHosted",
  SaaS = "SaaS"
}
