import { computed, defineComponent, shallowRef, PropType } from "@vue/composition-api"
import { hideDetails } from "@/utils/input-utils"
import { WizardSettings, WidgetText, WizardRule } from "@/types/wizard"
import { Rule, MinLengthTextField } from "@/utils/input-rules"
import i18n from "@/plugins/i18n"

export default defineComponent({
  name: "WizardMixin",
  props: {
    value: {
      type: Object as PropType<WizardSettings<WidgetText>>,
      required: true
    }
  },
  setup(props) {
    const inputRules = computed(() => {
      const rule = props.value.widget?.rule
      const baseRule = shallowRef<Rule<string>[]>([])

      if (rule) {
        baseRule.value.push(...getRule(rule), ...MinLengthTextField(1))
      }

      return baseRule.value
    })

    const shouldHideDetails = computed(() => {
      const rule = props.value.widget?.rule as WizardRule
      const value = props.value.widget?.value as string

      return getHideDetails(rule, value)
    })

    const getHideDetails = (rule: WizardRule, value: string) => {
      if (rule) {
        return hideDetails(getRule(rule), value)
      }

      return true
    }

    const getRule = (rule: WizardRule) => {
      const locale = i18n.locale

      if (rule) {
        const { regex, feedback } = rule
        return [(value: string) => new RegExp(regex).test(value) || feedback[locale]]
      }

      return []
    }

    return {
      getRule,
      getHideDetails,
      inputRules,
      shouldHideDetails
    }
  }
})
