<template>
  <div class="valueSetter">
    <custom-select v-if="isSelectType" v-model="keyValue" @change="setValue" :items="items" mandatory />

    <custom-combobox v-else-if="isComboboxType" v-model="keyValue" @change="setValue" :items="items" mandatory />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { MandatorySelect } from "@/utils/input-rules"
import { ValueType } from "./_HandleSelector.vue"
import CustomSelect from "./_CustomSelect.vue"
import CustomCombobox from "./_CustomCombobox.vue"

export default defineComponent({
  name: "ValueSelector",
  props: {
    currentValue: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array as PropType<string[]>,
      required: false
    },
    type: {
      type: String as PropType<ValueType>,
      required: true
    }
  },
  setup(props, ctx) {
    const keyValue = ref<string | null>(props.currentValue ?? null)

    const items = computed(() => {
      return props.options ?? []
    })

    const isSelectType = computed(() => {
      return props.type === ValueType.selectOnly
    })

    const isComboboxType = computed(() => {
      return props.type === ValueType.createOrSelect
    })

    const setValue = () => {
      ctx.emit("done", keyValue.value)
    }

    const _validateInputTypes = () => {
      if (!isSelectType.value && !isComboboxType.value) {
        throw Error("Value selector expected type Input Select or Input Combobox and got neither.")
      }
    }

    _validateInputTypes()

    return {
      isSelectType,
      isComboboxType,
      setValue,
      MandatorySelect,
      keyValue,
      items
    }
  },
  components: {
    CustomCombobox,
    CustomSelect
  }
})
</script>
