<template>
  <div id="versionHandler" class="animateFadeUp">
    <div class="inputs">
      <v-form ref="Form">
        <v-label>
          <span>{{ $t("Admin.deployPackage.versionHandler.description") }}</span>
          <v-text-field v-model="newVersionDescription" rbt-target="inputVersionDescription" />
        </v-label>

        <v-label>
          <span>{{ $t("Admin.deployPackage.versionHandler.version") }}</span>
          <v-text-field :value="newVersion" rbt-target="inputVersion" readonly />
        </v-label>

        <v-label>
          <span>{{ $t("Admin.deployPackage.versionHandler.serviceVersion") }}</span>
          <v-select v-model="serviceVersion" :items="serviceVersionOptions" rbt-target="selectServiceVersion">
            <template v-slot:item="{ item }">
              <span :rbt-target="toRobotTag(`option ${item.text}`)">{{ item.text }}</span>
            </template>
          </v-select>
        </v-label>

        <v-label>
          <span>{{ $t("Admin.deployPackage.versionHandler.terraformVersion") }} *</span>
          <v-text-field v-model="terraformVersion" :rules="rules" rbt-target="inputTerraformVersion" />
        </v-label>
      </v-form>
    </div>

    <form-uploader
      @upload="storeUploadedFile"
      formats="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
    />

    <div class="controller">
      <v-btn @click="$emit('cancel')" rbt-target="buttonCancelRelease" large>{{
        $t("Admin.deployPackage.versionHandler.button.cancel")
      }}</v-btn>

      <v-btn @click="release" color="primary" rbt-target="buttonRelease" large>{{
        $t("Admin.deployPackage.versionHandler.button.release")
      }}</v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import type { VForm } from "@/types/core"
import type { IDeployPackageVersion } from "@/types/deployPackages"
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { useStore } from "@/composable/useStore"
import { raiseError } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { TerraformVersionValidation } from "@/utils/input-rules"
import FormUploader from "@/components/formUploader/FormUploader.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "VersionHandler",
  props: {
    value: {
      type: String,
      required: true
    },
    latestVersion: {
      type: Number,
      default: 0
    },
    version: {
      type: Object as PropType<IDeployPackageVersion>,
      default: () => ({} as IDeployPackageVersion)
    }
  },
  setup(props, ctx) {
    const { adminModule } = useStore()
    const Form = ref<null | VForm>(null)
    const rules = TerraformVersionValidation
    const terraformVersion = Utils.vModel(props, ctx.emit)
    const newVersionDescription = ref("")
    const serviceVersion = ref(props.version?.serviceVersion || 0)
    const fileList = ref<File[]>([])

    const newVersion = computed(() => {
      const thisVersion = Number(props.latestVersion)
      return `${thisVersion + 1}`
    })

    const serviceVersionOptions = computed(() => {
      return adminModule.deployPackageServiceVersions.map(packageItem => {
        return {
          value: packageItem.id,
          text: packageItem.prettyName
        }
      })
    })

    const storeUploadedFile = (files: File[]) => {
      fileList.value = files
    }

    const toRobotTag = (name: string) => {
      return Utils.toCamel(name)
    }

    const release = async () => {
      const form = Utils.isType<VForm>(Form.value, "validate")

      if (form && form.validate()) {
        if (fileList.value.length > 0) {
          ctx.emit("release", {
            version: newVersion.value,
            description: newVersionDescription.value,
            size: fileList.value[0].size,
            zipFileBase64: await Utils.blobToBase64(fileList.value[0]),
            serviceVersion: serviceVersion.value
          })
        } else {
          raiseError({ text: translate("Admin.deployPackage.versionHandler.errorUpload") })
        }
      }
    }

    return {
      Form,
      toRobotTag,
      rules,
      release,
      serviceVersion,
      serviceVersionOptions,
      newVersion,
      terraformVersion,
      newVersionDescription,
      storeUploadedFile
    }
  },
  components: {
    FormUploader
  }
})
</script>
