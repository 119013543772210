import { raiseError } from "../event-bus"
import { zoneEndpoint } from "./config"
import { Dictionary, CloudZone } from "@/types/core"

export default class CloudHandler {
  private registeredZones: CloudZone[] = []
  private cachedZones: Dictionary<CloudZone> = {}

  private registerLatency(zone: CloudZone) {
    if (!this.cachedZones[zone.fullName]) {
      this.cachedZones[zone.fullName] = zone
    } else {
      this.cachedZones[zone.fullName].ping = zone.ping
    }
  }

  private async pingZones(zones = this.registeredZones) {
    await Promise.all(
      zones.map(async zone => {
        const ping = await CloudHandler.ping(zone.code)

        this.registerLatency({ ...zone, ping })
      })
    )
  }

  public async getZones(supportedZones: string[]): Promise<CloudZone[]> {
    const filteredZones: CloudZone[] = []

    supportedZones.forEach(zone => {
      this.registeredZones.forEach(regZone => {
        if (regZone.zones.find(item => item.includes(zone))) {
          filteredZones.push(regZone)
        }
      })
    })

    await this.pingZones(filteredZones)

    return Object.values(this.cachedZones).sort((a, b) => {
      return (a.ping ?? 0) - (b.ping ?? 0)
    })
  }

  public register(zones: CloudZone[]): void {
    this.registeredZones = [...zones]
  }

  constructor(zones: CloudZone[] = []) {
    this.register(zones)
  }

  public static async ping(zone: string): Promise<number> {
    const startTime = Date.now()

    try {
      await fetch(zoneEndpoint(zone))
    } catch (err) {
      raiseError({ text: err as string, error: err })
    }

    return Date.now() - startTime
  }
}

export const ping = async (zone: CloudZone): Promise<number> => {
  return await CloudHandler.ping(zone.code)
}
