<template>
  <v-expand-transition v-if="showAlert && hasValidInput" :duration="300" mode="out-in">
    <div class="animatedLayer">
      <div class="warningBarContainer">
        <v-alert icon="status_no" prominent text :type="type">
          <template v-slot:prepend>
            <div class="circleIcon">
              <v-icon size="23" :color="type" left>{{ displayIcon }}</v-icon>
            </div>
          </template>

          <div class="content">
            <h2>{{ title }}</h2>
            <p>{{ text }}</p>
          </div>

          <template v-slot:close>
            <v-btn v-if="!hideCloseButton" @click="showAlert = false" icon color="danger">
              <v-icon color="error">action_close</v-icon>
            </v-btn>
          </template>
        </v-alert>
      </div>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "@vue/composition-api"
import { translate } from "@/plugins/i18n"
import { WarningTypes } from "./lib/type"
import { TranslateResult } from "vue-i18n"
import "./scss/_warningBar.scss"

export default defineComponent({
  name: "WarningBarRoot",
  props: {
    text: {
      type: String as PropType<TranslateResult | null>,
      default: WarningTypes.error as TranslateResult | null
    },
    icon: {
      type: [String, Boolean],
      default: false
    },
    title: {
      type: String as PropType<TranslateResult | null>,
      default: translate("common.error") as TranslateResult | null
    },
    type: {
      type: String,
      required: true
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const showAlert = ref(true)

    const hasValidInput = computed(() => {
      return `${props.text}`.length > 0
    })

    const displayIcon = computed(() => {
      const { error, warning } = WarningTypes
      const hasIcon = Boolean(props.icon)

      if (hasIcon) {
        return props.icon
      }

      switch (props.type) {
        case warning:
          return "status_warning"
        case error:
        default:
          return "action_remove_circle"
      }
    })

    return {
      hasValidInput,
      showAlert,
      displayIcon
    }
  }
})
</script>
