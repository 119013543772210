import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { DocumentationItem } from "@/types/documentation"

const api = new Request("/v1/Documentation/")

export default createService("documentation", {
  async getDocumentationLinks() {
    return await api.get<DocumentationItem[]>("")
  }
})
