import { WorkspaceTemplate } from "@/types/workspace"
import { Dictionary } from "@/types/core"
import { getTags } from "@/utils/get-space-tags"

export const filterWorkspaceByStatus = (status: string[], workspaces: WorkspaceTemplate[]): WorkspaceTemplate[] => {
  return workspaces.filter(space => status.includes(space.status))
}

export const _pattern = (snippet: string): string => (snippet ?? "").trim().toLocaleLowerCase()

export const searchUsingSnippet = (snippet: string, workspaces: WorkspaceTemplate[]): WorkspaceTemplate[] => {
  return workspaces.filter(workspace => {
    const snippetValue = _pattern(snippet)
    const iterationKeys = ["id", "title", "description", "username"]
    const _workspace = workspace as WorkspaceTemplate & Dictionary<string>

    const foundWithIterationKeys = iterationKeys.some(key => {
      return _pattern(_workspace[key]).includes(snippetValue)
    })

    const foundWithTags = getTags(workspace).tags.some(tag => {
      return _pattern(tag.name).includes(snippetValue)
    })

    return foundWithIterationKeys || foundWithTags
  })
}
