import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"licenseBoard"}},[_c(VBtn,{attrs:{"large":""},on:{"click":function($event){_vm.showPopup = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-license")]),_c('span',[_vm._v(_vm._s(_vm.$t("LicenseCore.controllers.licenseText")))])],1),_c('popup',{attrs:{"title":_vm.$t('LicenseCore.popup.licenses'),"loading":_vm.loading,"hide-footer":"","no-padding":""},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[[_c('div',{staticClass:"wrapLicenseTable"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.licenses,"items-per-page":_vm.itemsPerPage,"disable-pagination":!_vm.showPagination,"hide-default-footer":!_vm.showPagination},scopedSlots:_vm._u([{key:"item.freeLicenses",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.freeLicenses)+" / "+_vm._s(item.totalLicenses))])]}},{key:"item.containerId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"containerId"},[_vm._v(" "+_vm._s(item.containerId)+" "),(item.containerId)?_c(VBtn,{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(item.containerId)}}},[_c(VIcon,[_vm._v("action_copy")])],1):_vm._e()],1)]}}],null,true)})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }