<template>
  <div v-if="organizationId" class="workspaceListContainer">
    <div class="wrapTitle">
      <scaled-image
        :media-context="MediaContext.organization"
        :media-context-item-id="organizationId"
        alt="Company Logo"
      />
    </div>

    <div class="workspaceHeaderWrap">
      <div class="filterWrap">
        <h4 rbt-target="workspaceListTitle">{{ $t("MyWorkspaces.title") }}</h4>
        <search v-model="advancedFilterConfig.search" />
        <advanced-filter v-model="advancedFilterConfig" :users="userIdCollectionFromWorkspaces" />
      </div>

      <div class="workspaceConfig">
        <view-type-handler v-model="viewMode" />
        <btn-create-workspace v-if="canCreateWorkspaces" />
      </div>
    </div>

    <v-progress-linear v-if="loader.on" class="my-0 py-0" indeterminate />

    <workspaces-list-handler
      @clear:filter="resetFilter"
      :has-filter-configurations="hasFilterConfigurations"
      :workspaces="workspacesFiltered"
      :view-mode="viewMode"
      :can-create-workspaces="canCreateWorkspaces"
    />
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent, onBeforeUnmount } from "@vue/composition-api"
import { MediaContext } from "@/components/uploadv2/template/context"
import { WorkspaceModule } from "@/store/workspace"
import { Dictionary, SelectItem } from "@/types/core"
import { UsersModule } from "@/store/users"
import { AdvancedFilterConfig } from "@/utils/advancedFilter/type"
import { advancedFilter } from "@/utils/advancedFilter"
import { searchUsingSnippet } from "./lib/workspace-helpers"
import { workspaceSort } from "./lib/sorting"
import { WorkspaceTemplate, WorkspaceListItem } from "@/types/workspace"
import { translate } from "@/plugins/i18n"
import { PlatformViewType } from "@/types/core"
import { navigate } from "@/router"
import Timer from "@/config/app-loop-timers"
import LoadImage from "@/components/global/LoadImage.vue"
import WorkspacesListHandler from "./components/WorkspaceListHandler.vue"
import Loader from "@/utils/loader"
import BtnCreateWorkspace from "./components/header/BtnCreateWorkspace.vue"
import ViewTypeHandler from "./components/header/ViewTypeHandler.vue"
import Search from "@/components/search/Search.vue"
import AdvancedFilter from "./components/header/AdvancedFilter.vue"
import Utils from "@/utils/utils"
import ScaledImage from "@/components/scaledImg/ScaledImage.vue"
import Router from "@/router"
import Commands from "@/config/command-keys"
import "./scss/_workspaces.scss"

export default defineComponent({
  name: "WorkspaceRoot",
  setup() {
    const usersModule = UsersModule()
    const workspaceModule = WorkspaceModule()
    const loader = Loader()
    const viewMode = ref("")
    const shouldSelfUpdateList = ref(true)

    const advancedFilterConfig = computed({
      get() {
        return workspaceModule.advancedFilterConfig
      },
      set(filterConfig: AdvancedFilterConfig) {
        workspaceModule.advancedFilterConfig = filterConfig
      }
    })

    const organizationId = computed(() => {
      return usersModule.selectedOrganizationId
    })

    const hasFilterConfigurations = computed(() => {
      const { ownerId, lastModified, status, search } = advancedFilterConfig.value

      const ownerIdNotDefault = ownerId !== "any"
      const lastModifiedNotDefault = lastModified !== "any"
      const snippetSearched = search.length > 0

      return ownerIdNotDefault || lastModifiedNotDefault || snippetSearched || status.length > 0
    })

    const canCreateWorkspaces = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const myWorkspaces = computed(() => {
      return workspaceModule.myWorkspaces
    })

    const sortedWorkspace = computed(() => {
      const workspaceList = Utils.assignType<WorkspaceListItem[]>(myWorkspaces.value)

      if (workspaceList) {
        return workspaceSort(workspaceList, "status", true) as unknown as WorkspaceTemplate[]
      }

      return myWorkspaces.value
    })

    const workspacesFiltered = computed(() => {
      const search = advancedFilterConfig.value.search
      let spaceCollection = sortedWorkspace.value

      if (search) {
        spaceCollection = searchUsingSnippet(search, sortedWorkspace.value)
      }

      return advancedFilter(advancedFilterConfig.value, spaceCollection)
    })

    const userIdCollectionFromWorkspaces = computed(() => {
      const cachedUserCollection = {} as Dictionary<SelectItem<string>>

      cachedUserCollection[usersModule.selfDetail.id] = {
        text: translate("MyWorkspaces.filterLabels.myWorkspaces"),
        value: usersModule.selfDetail.id
      }

      myWorkspaces.value.forEach(workspace => {
        const userId = workspace.userId

        if (userId && !cachedUserCollection[userId]) {
          cachedUserCollection[userId] = {
            text: workspace.username || "Unknown user",
            value: userId
          }
        }
      })

      return Object.values(cachedUserCollection)
    })

    const _updateTabTitle = () => {
      const route = Router.currentRoute

      if (route.name === "WorkspacesList") {
        const title = document.title
        const fixedTitle = title.includes("(") ? title.slice(0, title.indexOf("(")) : title

        document.title = `${fixedTitle} (${myWorkspaces.value.length})`
      }
    }

    const _getWorkspaces = async () => {
      await workspaceModule.getMyWorkspaces()
      _updateTabTitle()
    }

    const _keepListUpdated = async () => {
      if (shouldSelfUpdateList.value) {
        await _getWorkspaces()
        setTimeout(_keepListUpdated, Timer.workspaceList)
      }
    }

    const _adjustRouteBasedOnViewSettings = () => {
      const { VIEW_SETTINGS } = Commands
      const { APP_LAUNCHER } = PlatformViewType

      const fromLauncher = localStorage.getItem(VIEW_SETTINGS) === APP_LAUNCHER

      if (fromLauncher) {
        navigate({ name: "AppLauncherCollection" })
      }
    }

    const resetFilter = () => {
      advancedFilterConfig.value = {
        ownerId: "any",
        lastModified: "any",
        status: [],
        search: ""
      }
    }

    loader.run(async () => {
      await _getWorkspaces()
      _updateTabTitle()
      _keepListUpdated()
    })

    _adjustRouteBasedOnViewSettings()
    onBeforeUnmount(() => (shouldSelfUpdateList.value = false))

    return {
      resetFilter,
      hasFilterConfigurations,
      userIdCollectionFromWorkspaces,
      workspacesFiltered,
      loader,
      advancedFilterConfig,
      canCreateWorkspaces,
      viewMode,
      organizationId,
      MediaContext
    }
  },
  components: {
    ViewTypeHandler,
    Search,
    ScaledImage,
    AdvancedFilter,
    BtnCreateWorkspace,
    LoadImage,
    WorkspacesListHandler
  }
})
</script>
