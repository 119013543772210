<template>
  <div id="workspaceWizard" class="backgroundWrapper animateFadeUp">
    <h5>{{ $t("WorkspaceWizard.description") }}</h5>

    <nested-functions v-model="defaultSettings" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WizardConfigItem } from "@/types/wizard"
import NestedFunctions from "./NestedFunctions.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "WizardRoot",
  props: {
    value: {
      type: Array as PropType<WizardConfigItem[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const defaultSettings = Utils.vModel(props, ctx.emit)

    return {
      defaultSettings
    }
  },
  components: {
    NestedFunctions
  }
})
</script>
