<template>
  <div class="wrapFormFactory" :nested="_nested">
    <div v-for="(row, rowIndex) in dictionaryDirector" :key="rowIndex" :class="_containerClass" class="rowContainer">
      <div
        v-for="(item, itemIndex) in row"
        :key="item._id"
        :class="configClass(item)"
        :collection-parent="isCollectionParent(rowIndex, itemIndex)"
        class="rowItem"
      >
        <span v-if="isType(item.type, 'input')" :has-button="canBeRemoved(item, rowIndex)">
          <input-type-handler v-model="item.value" :director="item" :loading="loading" />

          <v-btn v-if="canBeRemoved(item, rowIndex)" @click="removeById(item._id)" class="removeItem" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </span>

        <div v-if="is(item.type, InputType.ITERABLE)" :last-element="row.length === itemIndex" class="mapperContainer">
          <span v-if="item.label" class="inputLabel">{{ getLabel(item.label) }}</span>

          <form-factory
            v-if="isChildrenIterable(item)"
            v-model="item.children"
            :loading="loading"
            :_original-structure="structure"
            :_container-class="item.containerClass"
            _nested
          />

          <form-factory
            v-else
            v-model="item.children.collection"
            :loading="loading"
            :_original-structure="structure"
            :_container-class="item.containerClass"
            _nested
          />

          <v-btn v-if="item.isCollectionStructure" @click="insertNewInput(item._id)" large>
            <v-icon left>action_add_box</v-icon>
            <span>{{ $t("FormFactory.addMore") }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, PropType } from "@vue/composition-api"
import { isDetailedDirector } from "./lib/assert"
import { Director, InputItem, IterableGroup, InputType, StructureCollection } from "./lib/type"
import { Dictionary } from "@/types/core"
import { getLabel } from "./composable/get-label"
import { PluginExceptionAny } from "@/types/core"
import { v4 as uuid } from "uuid"
import Adaptor, { isType } from "./lib/payload-adaptor"
import Utils from "@/utils/utils"
import InputTypeHandler from "./_InputTypeHandler.vue"
import "./formFactory.scss"

export default defineComponent({
  name: "FormFactory",
  props: {
    value: {
      type: Array as PropType<Director>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    _originalStructure: {
      type: Object as PropType<Dictionary<PluginExceptionAny>>,
      required: false
    },
    _nested: {
      type: Boolean,
      default: false
    },
    _containerClass: {
      type: String,
      default: ""
    }
  },
  setup(props, ctx) {
    const director = Utils.vModel(props, ctx.emit)

    const structure = computed(() => {
      if (props._originalStructure) {
        return props._originalStructure
      }
      return Adaptor<InputItem>(director.value)
    })

    const _cachedStructureUsingIdKeys = computed(() => {
      const _idStructure = ref({} as Dictionary<InputItem | IterableGroup>)

      const _getId = (item: InputItem | IterableGroup, _structure = {} as Dictionary<InputItem | IterableGroup>) => {
        _structure[item._id] = item

        if (Object.hasOwn(item, "children")) {
          const iterableGroup = item as IterableGroup
          const isIterable = Array.isArray(iterableGroup.children)

          if (isIterable) {
            const iterableChildren = iterableGroup.children as Director

            iterableChildren.forEach(row => {
              row.forEach(child => {
                _getId(child, _structure)
              })
            })
          }
        }

        return _structure
      }

      director.value.forEach(row => row.forEach(child => _getId(child, _idStructure.value)))

      return _idStructure.value
    })

    const isChildrenIterable = (iterableGroup: IterableGroup) => {
      return Array.isArray(iterableGroup.children)
    }

    const isCollectionParent = (rowIndex: string | number, inputIndex: number) => {
      const row = director.value[Number(rowIndex)]
      const input = row[inputIndex]
      const inputSibling = row[inputIndex + 1]

      if (row && input && inputSibling && row.length > 1) {
        return "children" in inputSibling || "collection" in inputSibling
      }

      return false
    }

    const is = (type: InputType, typeMatch: InputType) => {
      return type === typeMatch
    }

    const canBeRemoved = (input: InputItem | IterableGroup, rowIndex: number | string) => {
      const iterableGroup = Utils.isType<IterableGroup>(input, "children")

      if (iterableGroup) {
        return false
      }
      return props._nested && Number(rowIndex) > 0
    }

    const removeById = (id: string) => {
      const rowIndex = director.value.findIndex(row => row.find(item => item._id === id))

      if (rowIndex !== -1) {
        director.value.splice(rowIndex, 1)
      }
    }

    const configClass = (inputDirector: InputItem | IterableGroup) => {
      const input = Utils.isType<InputItem | IterableGroup>(inputDirector, "_id")

      if (input) {
        const growClass = input?.grow ? "flexGrow" : ""

        return [growClass].join(" ")
      }

      return ""
    }

    const hasKey = (key: string, item: InputItem | IterableGroup) => {
      return Object.hasOwn(item, key)
    }

    const insertNewInput = (id: string) => {
      const _dictionary = _findInputConfigById(id)

      if (_dictionary && _dictionary.type === InputType.ITERABLE) {
        const iterableGroup = _dictionary as IterableGroup
        const isIterable = Array.isArray(iterableGroup.children)

        if (iterableGroup) {
          if (isIterable) {
            const iterableChildren = iterableGroup.children as Director
            const _clonedStructure = _getClonedStructure(iterableChildren.slice(-1)[0])

            iterableChildren.push(_clonedStructure)
          } else {
            const structureCollection = iterableGroup.children as StructureCollection
            const iterableChildren = structureCollection.collection as Director
            const _clonedStructure = _getClonedStructure(iterableChildren.slice(-1)[0])

            iterableChildren.push(_clonedStructure)
          }
        }
      }
    }

    const _findInputConfigById = (id: string): InputItem | IterableGroup | null => {
      const cachedInput = Utils.isType<InputItem | IterableGroup>(_cachedStructureUsingIdKeys.value[id], "_id")

      if (cachedInput) {
        return cachedInput
      }

      return null
    }

    const _getClonedStructure = (directorChildren: Array<InputItem | IterableGroup>) => {
      const _parsedStructure = JSON.parse(JSON.stringify(directorChildren)) as Array<InputItem | IterableGroup>

      _parsedStructure.forEach(child => {
        const isInput = Utils.isType<InputItem>(child, "value")

        if (isInput) {
          const _originalInput = directorChildren[0] as InputItem
          isInput.rules = _originalInput.rules
        } else {
          const iterableGroup = child as IterableGroup
          const isIterable = Array.isArray(iterableGroup.children)

          if (isIterable) {
            const iterableChildren = iterableGroup.children as Director

            iterableChildren.forEach(row =>
              row.forEach(child => {
                const originalInput = _findInputConfigById(child._id) as InputItem

                if (originalInput && Object.hasOwn(originalInput, "rules")) {
                  const inputItem = child as InputItem
                  inputItem.rules = originalInput.rules

                  child._id = uuid()
                }
              })
            )
          }
        }

        child._id = uuid()
      })

      return _parsedStructure as Array<InputItem | IterableGroup>
    }

    isDetailedDirector(director.value)

    return {
      is,
      configClass,
      hasKey,
      insertNewInput,
      getLabel,
      canBeRemoved,
      removeById,
      isChildrenIterable,
      isType,
      isCollectionParent,
      _cachedStructureUsingIdKeys,
      structure,
      director,
      dictionaryDirector: director as Dictionary<PluginExceptionAny>,
      InputType
    }
  },
  components: {
    InputTypeHandler
  }
})
</script>
