<template>
  <div v-if="hasPermission && !locked" @click="$emit('click')" class="addAddCardButtonContainer">
    <v-icon size="40"> action_add_circle </v-icon>
  </div>
</template>

<script lang="ts">
import { UsersModule } from "@/store"
import { defineComponent, computed } from "@vue/composition-api"

export default defineComponent({
  name: "AddAppSign",
  props: {
    locked: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const usersModule = UsersModule()

    const hasPermission = computed(() => {
      return usersModule.hasEditorPermissions
    })

    return {
      hasPermission
    }
  }
})
</script>
