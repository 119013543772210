<template>
  <div class="allowedInstanceTypes">
    <label>
      <span>{{ $t("WorkspaceEdit.appDetails.labels.allowedInstanceType") }}:</span>
    </label>

    <draggable
      v-model="asset.allowedInstanceTypes"
      v-if="asset.allowedInstanceTypes && asset.allowedInstanceTypes.length"
      :disabled="disabled"
      tag="ul"
      class="listContainer"
      ghost-class="draggingOver"
      draggable=".dragHandle"
    >
      <list-item
        v-for="(instanceType, index) in asset.allowedInstanceTypes"
        :key="instanceType.code"
        class="dragHandle"
      >
        <span>
          <v-icon left size="22">mdi-reorder-horizontal</v-icon>
          <span :highlight="hasChanges">{{ instanceType.code }}</span>
        </span>

        <v-btn
          v-if="asset.allowedInstanceTypes.length > 1"
          v-tippy="{ content: $t('common.remove') }"
          @click="removeItem(index)"
          :disabled="disabled"
          rbt-target="removeInstanceType"
          color="error"
          icon
        >
          <v-icon>action_trash</v-icon>
        </v-btn>
      </list-item>
    </draggable>

    <div v-else class="body-2">
      {{ $t("workspaces.noAllowedInstanceTypes") }}
    </div>

    <div class="insertInstance">
      <v-autocomplete
        v-model="selectedInstanceType"
        :items="availableInstanceTypesFiltered"
        :disabled="disabled || availableInstanceTypesFiltered.length === 0"
        item-text="code"
        item-value="code"
        append-icon="navigate_double_arrows"
        hide-details
      />

      <v-btn
        @click="allowSelectedInstanceType"
        :disabled="!selectedInstanceType || disabled"
        color="primary"
        rbt-target="addInstanceType"
        large
      >
        {{ $t("common.add") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, onMounted } from "@vue/composition-api"
import { IInstanceType, IWorkspaceAsset } from "@/types/workspace"
import Draggable from "vuedraggable"
import ListItem from "@/components/listItem/ListItemRoot.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "AllowedInstances",
  components: {
    Draggable,
    ListItem
  },
  props: {
    value: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    availableInstanceTypes: {
      type: Array as PropType<IInstanceType[]>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const asset = Utils.vModel<IWorkspaceAsset>(props, ctx.emit)
    const selectedInstanceType = ref("")

    const availableInstanceTypesFiltered = computed(() => {
      const allowedInstances = asset.value.allowedInstanceTypes

      return props.availableInstanceTypes.filter(instanceType => {
        return !allowedInstances.find(i => i.code == instanceType.code)
      })
    })

    const _autoSelectFirstInstance = () => {
      const hasAvailableInstances = availableInstanceTypesFiltered.value.length > 0

      if (hasAvailableInstances) {
        selectedInstanceType.value = availableInstanceTypesFiltered.value[0].code
      } else {
        selectedInstanceType.value = ""
      }
    }

    const allowSelectedInstanceType = () => {
      if (!asset.value.allowedInstanceTypes) {
        asset.value.allowedInstanceTypes = []
      }

      asset.value.allowedInstanceTypes.push({
        code: selectedInstanceType.value
      })

      _autoSelectFirstInstance()
    }

    const removeItem = (index: number) => {
      asset.value.allowedInstanceTypes.splice(index, 1)
      _autoSelectFirstInstance()
    }

    onMounted(() => _autoSelectFirstInstance())

    return {
      availableInstanceTypesFiltered,
      selectedInstanceType,
      asset,
      allowSelectedInstanceType,
      removeItem
    }
  }
})
</script>
