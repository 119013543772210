import { Director, IterableGroup, InputType, InputItem, StructureCollection, CheckboxInput } from "./type"
import { isDetailedDirector } from "./assert"
import { Dictionary, PluginExceptionAny } from "@/types/core"
import Utils from "@/utils/utils"

let lastId = Date.now()

const uuid = () => `${lastId++}`

const _validKeyStructure = (structure: Dictionary<string>, key: string) => {
  const keyIsDuplicate = Object.keys(structure).includes(key)

  if (keyIsDuplicate) {
    throw Error(`Payload adaptor FormFactory - Duplicated key: ${key}`)
  }

  return !keyIsDuplicate
}

export const isType = (type: InputType, target: "input" | "group" | "empty") => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ITERABLE, EMPTY, ..._types } = InputType
  const isInputType = Object.values(_types as unknown as InputType).includes(type)

  if (target === "input") {
    return isInputType
  } else if (target === "group") {
    return type === ITERABLE
  }
}

/**
 * Empties the director structure to default values and
 * should remove all added collection items except from
 * the first one
 *
 * @example
 * emptyProduct([
 *   [
 *     TextComponent("userName", "Leo", { label: "User name" }),
 *     TextComponent("userRole", "admin", { label: "User role" })
 *   ]
 * ]): {
 *   userName: "",
 *   userRole: ""
 * }
 */
export const emptyProduct = (director: Director): void => {
  director.forEach(row => {
    row.forEach(rowItem => {
      if (isType(rowItem.type, "input")) {
        const input = rowItem as InputItem
        const { TEXT, DESCRIPTION, CHECKBOX, DATE } = InputType
        const textTypes = [TEXT, DESCRIPTION, DATE]

        if (textTypes.includes(input.type)) {
          input.value = ""
        } else if (input.type === CHECKBOX) {
          const checkbox = input as CheckboxInput
          checkbox.value = false
        } else {
          input.value = null
        }
      } else if (isType(rowItem.type, "group")) {
        const iterableGroup = rowItem as IterableGroup
        const isIterable = Array.isArray(iterableGroup.children)

        if (isIterable) {
          emptyProduct(iterableGroup.children as Director)
        } else {
          const structureCollection = iterableGroup.children as StructureCollection

          if (structureCollection.collection.length > 1) {
            _removeArrayItemsExceptFromFirst(structureCollection.collection)
          }

          emptyProduct(structureCollection.collection)
        }
      }
    })
  })
}

/**
 * Returns the Director structure in key structure using the key
 * and the value. This function should be used to extract the object
 * values from the Director structure, before sending as payload.
 *
 * This function expects a generic type to help determine the return value
 *
 * @example
 *
 * getProduct<UserDetails>([
 *   [
 *     TextComponent("userName", "Leo", { label: "User name" }),
 *     TextComponent("userRole", "admin", { label: "User role" })
 *   ]
 * ]): {
 *   userName: "Leo",
 *   userRole: "admin"
 * }
 *
 * interface UserDetails {
 *   userName: string
 *   userRole: string
 * }
 */
export const getProduct = <T>(director: Director): T => {
  isDetailedDirector(director)

  return director.reduce((structureCollection, row) => {
    row.forEach(inputItem => {
      if (_validKeyStructure(structureCollection, inputItem.key) && isType(inputItem.type, "input")) {
        const input = inputItem as InputItem
        const dictionary = structureCollection as unknown as Dictionary<string>

        dictionary[input.key] = input.value as unknown as string
      } else if (isType(inputItem.type, "group")) {
        const iterableGroup = inputItem as IterableGroup
        const dictionary = structureCollection as unknown as Dictionary<string | Director>
        const usingIterableChildren = Array.isArray(iterableGroup.children)

        if (usingIterableChildren) {
          const iterableChildren = iterableGroup.children as Director

          if (iterableGroup.isCollectionStructure) {
            dictionary[iterableGroup.key] = iterableChildren.map(child => getProduct([child]))
          } else {
            dictionary[iterableGroup.key] = getProduct(iterableChildren)
          }
        } else {
          const childrenNonIterable = iterableGroup.children as StructureCollection
          dictionary[iterableGroup.key] = childrenNonIterable.collection.map(child => getProduct([child]))
        }
      }
    })

    return structureCollection
  }, {} as Dictionary<string>) as unknown as T
}

/**
 * Population function to automatically update the FormFactory director structure
 * using data received from the API, this is usually used in editor forms.
 *
 * @example
 *
 * populateProduct({
 *   username: "user123",
 *   password: "pass123hehe",
 *   details: {
 *     email: "newuser@user.com"
 *   }
 * }, [
 *   [FormGenerator.components.text("username", "")]
 *   [FormGenerator.components.text("password", "")]
 *   [FormGenerator.components.group("details", [
 *     [FormGenerator.components.text("email", "")]
 *   ], { isCollectionStructure: false })]
 * ])
 */
export const populateProduct = (payload: Dictionary<PluginExceptionAny>, directorStructure: Director) => {
  directorStructure.forEach(row =>
    row.forEach(child => {
      if (isType(child.type, "input")) {
        const input = child as InputItem

        input.value = payload[input.key] as PluginExceptionAny
      } else if (isType(child.type, "group")) {
        const iterableGroup = child as IterableGroup
        const isIterableType = iterableGroup.isCollectionStructure && Array.isArray(iterableGroup.children)
        const payloadIsNotNull = Boolean(payload[iterableGroup.key])

        if (payloadIsNotNull) {
          if (isIterableType) {
            _populateIterableChildren(payload, iterableGroup)
          } else {
            if (Array.isArray(iterableGroup.children)) {
              const nestedDirector = iterableGroup.children as Director
              populateProduct(payload[iterableGroup.key], nestedDirector)
            } else {
              const structureCollection = iterableGroup.children as StructureCollection
              _populateNonIterableChildren(payload[iterableGroup.key], structureCollection)
            }
          }
        }
      }
    })
  )
}

const _populateIterableChildren = (payload: Dictionary<PluginExceptionAny>, iterableGroup: IterableGroup) => {
  const iterableChildren = iterableGroup.children as Director
  const payloadItemCollection = payload[iterableGroup.key] as Array<Dictionary<PluginExceptionAny>>

  payloadItemCollection.forEach(item => {
    populateProduct(item, iterableChildren)
  })
}

const _populateNonIterableChildren = (
  payload: Array<Dictionary<PluginExceptionAny>>,
  directorInputCollection: StructureCollection
) => {
  if (payload) {
    const collectionContainer = directorInputCollection.collection as Array<Array<InputItem | IterableGroup>>

    _cloneArrayItems(collectionContainer, payload.length)

    payload.forEach((payloadItem, payloadIndex) => {
      Object.keys(payloadItem).forEach(inputKey => {
        const rowOfInputs = collectionContainer[payloadIndex]
        const collectionItem = rowOfInputs.find(input => input.key === inputKey)

        if (collectionItem) {
          if (isType(collectionItem.type, "input")) {
            const input = collectionItem as InputItem
            input.value = payloadItem[inputKey]
          } else if (isType(collectionItem.type, "group")) {
            const iterableGroup = collectionItem as IterableGroup
            const collectionStructure = iterableGroup.children as StructureCollection

            _populateNonIterableChildren(payloadItem[iterableGroup.key], collectionStructure)
          }
        }
      })
    })
  }
}

const _cloneArrayItems = (collection: Array<Array<InputItem | IterableGroup>>, count: number) => {
  const cloneRef = collection.slice(-1)[0]

  if (cloneRef) {
    const clonedStructure = cloneRef.map(input => {
      const clonedItem = Utils.deepClone<InputItem>(input)

      clonedItem._id = uuid()

      return clonedItem
    })

    while (collection.length < count) {
      collection.push(clonedStructure)
    }
  }
}

const _removeArrayItemsExceptFromFirst = <T>(array: T[]) => {
  array.splice(1, array.length)
}

export default getProduct
