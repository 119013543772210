<template>
  <div class="wrapCodeViewer">
    <div @click="$emit('copy')" class="overlay"></div>

    <v-btn icon @click="$emit('copy')" class="controllerCopy">
      <v-icon>action_copy</v-icon>
    </v-btn>

    <pre class="json">  
      <code class="json">
        <span class="symbol s-1">{</span>
          <span class="key s-2">"Version"</span>: <span class="string">"2012-10-17"</span>,
          <span class="key s-2">"Statment"</span>: <span class="symbol">[</span>
              <span class="symbol s-3">{</span>
                <span class="key s-4">"Sid"</span>: <span class="string">"VizNowDeployInfra0"</span>,
                <span class="key s-4">"Effect"</span>: <span class="string">"Allow"</span>,
                <span class="key s-4">"Action"</span>: <span class="symbol">[</span>
                    <span class="string s-5">"iam:*",</span>
                    <span class="string s-5">"s3:*",</span>
                    <span class="string s-5">"kms:*",</span>
                    <span class="string s-5">"route53:*",</span>
                    <span class="string s-5">"sts:*",</span>
                    <span class="string s-5">"ec2:*",</span>
                    <span class="string s-5">"tag:*",</span>
                    <span class="string s-5">"logs:*"</span>
                <span class="symbol s-4">]</span>,
                <span class="key s-4">"Resource"</span>: <span class="string">"*"</span>
              <span class="symbol s-3">}</span>
          <span class="symbol s-2">]</span>
        <span class="symbol s-1">}</span>
      </code>
    </pre>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "CodeSnippet"
})
</script>
