<template>
  <v-btn
    v-if="showRestoreButton && canSeeActionButtons"
    @click="$emit('click')"
    :disabled="loading"
    color="primary"
    large
  >
    <span>{{ $t("WorkspaceEdit.restoreButton") }}</span>
  </v-btn>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { Archived } from "@/config/workspace-states"
import { UsersModule } from "@/store"

export default defineComponent({
  name: "DeployButton",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const usersModule = UsersModule()

    const showRestoreButton = computed(() => {
      return props.workspace.status === Archived
    })

    const canSeeActionButtons = computed(() => {
      return usersModule.hasEditorPermissions
    })

    return {
      canSeeActionButtons,
      showRestoreButton
    }
  }
})
</script>
