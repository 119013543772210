<template>
  <div class="emojiContainer">
    <figure class="emojiWrapper">
      <img :src="emoji(name)" :alt="name" />
    </figure>
    <span class="percentValue">{{ percentValue }}%</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import Emojis from "@/components/feedbackDialog/templates/emojis"

export default defineComponent({
  name: "EmojiContainer",
  props: {
    name: {
      type: String,
      required: true
    },
    percentValue: {
      type: Number,
      required: true
    }
  },
  setup() {
    const emoji = (name: string) => {
      return Emojis[name]
    }

    return {
      emoji
    }
  }
})
</script>
