<template>
  <div id="boxHeader">
    <div class="statusLabel">
      <div class="columnBlock">
        <span v-if="isDeploying">{{ $t(`workspaceStatus.${status}`) }}</span>
        <span v-else-if="isDeployed">{{ $t(`workspaceOnlineStatus.${onlineStatus}`) }}</span>
        <span v-else-if="needsToBeDeployed">{{ $t("TriCaster.pendingDeploy") }}</span>
        <span v-else>{{ $t(`workspaceStatus.${status}`) }}</span>

        <tooltip v-if="hasError" :disabled="!hasGodPermission" :text="errorMsg">
          <span class="errorDescription">
            {{ $t("TriCaster.genericErrorMessage") }}
          </span>
        </tooltip>
      </div>
    </div>

    <div class="rightBlock">
      <span class="tokenCounter">
        <span class="counter" :in-debt="tokenAmount < 0">{{ tokensLeft }}</span>

        <span v-if="hasCosts && hasHoursLeft" class="timeLeft">{{ hoursLeft }}</span>

        <tooltip v-if="hasCosts && showWarningSign" :text="warningIconTooltipText">
          <template v-slot:text>
            <div class="tooltipLowCreditWarning">
              <strong>{{ warningIconTooltipText }}</strong>

              <span>
                {{ $t("TriCaster.boxHeader.tokenWarning.generic") }}
              </span>
            </div>
          </template>

          <v-icon color="warning"> status_warning </v-icon>
        </tooltip>
      </span>

      <v-menu offsetY>
        <template v-slot:activator="{ on, attrs }">
          <span class="verticalMenuIcon">
            <v-icon v-bind="attrs" v-on="on" color="#D0D0D1" :size="24"> mdi-dots-vertical </v-icon>
          </span>
        </template>

        <ul class="listMenu">
          <li @click="$emit('open:auto-shutdown')" class="listRow">
            <v-icon left>multimedia_seconds</v-icon>
            <span>{{ $t("TriCaster.boxHeader.menu.autoShutdown") }}</span>
          </li>
        </ul>
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts">
import { translate, translatePlural } from "@/plugins/i18n"
import { OrganizationModule, UsersModule } from "@/store"
import { WorkspaceOnlineStatusEnum, WorkspaceStatusEnum } from "@/types/workspace"
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { Dictionary } from "@/types/core"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "BoxHeader",
  props: {
    onlineStatus: {
      type: String as PropType<WorkspaceOnlineStatusEnum>,
      required: true
    },
    status: {
      type: String as PropType<WorkspaceStatusEnum>,
      required: true
    },
    isDeploying: {
      type: Boolean,
      default: false
    },
    isDeployed: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const userModule = UsersModule()
    const organizationModule = OrganizationModule()

    const needsToBeDeployed = computed(() => {
      return props.status === WorkspaceStatusEnum.Draft
    })

    const errorMsg = computed(() => {
      const genericErrorMsg = translate("TriCaster.genericErrorMessage")
      return userModule.hasGodPermissions ? props.errorMessage ?? "Unknown error" : genericErrorMsg
    })

    const tokenAmount = computed(() => {
      return organizationModule.walletDetail?.amount ?? 0
    })

    const tokensLeft = computed(() => {
      const tokens = Utils.numLocale(tokenAmount.value)
      return translatePlural("TriCaster.boxHeader.tokensLeft", tokenAmount.value, { tokens })
    })

    const countDownNumbers = computed(() => {
      const millisecondsLeft = organizationModule.walletDetail?.timeLeft ?? 0
      return Utils.getTimer(millisecondsLeft) as Dictionary<number>
    })

    const showWarningSign = computed(() => {
      const DANGEROUS_HOURS_LEFT = 6
      const timeLeft = organizationModule.walletDetail?.timeLeft ?? 0

      return timeLeft <= Utils.hours(DANGEROUS_HOURS_LEFT)
    })

    const warningIconTooltipText = computed(() => {
      const tokenSnippet = hasHoursLeft.value ? "almostOutOfCredits" : "outOfCredits"
      return translate("TriCaster.boxHeader.tokenWarning." + tokenSnippet)
    })

    const hoursLeft = computed(() => {
      const { ...int } = countDownNumbers.value
      const _timeLeft = (time: string) => `(~${time})`.trim()
      const t = (snippet: string, time: string | number) =>
        translatePlural("common.time." + snippet, Number(time), { time: `${time}` })

      if (int.days) {
        return _timeLeft(t("day", int.days))
      } else if (int.hours) {
        return _timeLeft(t("hour", int.hours))
      } else if (int.minutes) {
        return _timeLeft(t("minute", int.minutes))
      } else {
        return _timeLeft(t("second", int.seconds))
      }
    })

    const hasHoursLeft = computed(() => {
      return organizationModule.walletDetail.timeLeft > 0
    })

    const hasCosts = computed(() => {
      return typeof organizationModule.walletDetail.timeLeft === "number"
    })

    return {
      hasCosts,
      warningIconTooltipText,
      hasHoursLeft,
      hasGodPermission: userModule.hasGodPermissions,
      showWarningSign,
      countDownNumbers,
      tokenAmount,
      tokensLeft,
      errorMsg,
      hoursLeft,
      needsToBeDeployed
    }
  },
  components: {
    Tooltip
  }
})
</script>
