<template>
  <div class="deploying">
    <span class="upperContent">
      <span>
        {{ $t(`Onboarding.licenseStage.${stage}`) }}
        <span class="dot">.</span>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceStatusEnum } from "@/types/workspace"

export default defineComponent({
  name: "DeployingStage",
  props: {
    stage: {
      type: String as PropType<WorkspaceStatusEnum>,
      required: true
    },
    progress: {
      type: [String, Number],
      required: true
    }
  }
})
</script>
