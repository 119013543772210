<template>
  <div id="feedbackDialog">
    <v-menu v-model="showDialog" top :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" :loading="loader.on" class="animateFadeUp" large dark>
          <div class="emojiWrap">
            <span>{{ $t("FeedbackDialog.title") }}</span>
            <img :src="emoji.happy" class="emoji ml-2" />
          </div>
        </v-btn>
      </template>

      <feedback-card v-model="showDialog" @send="sendFeedback" :feedback="feedback" :loading="loader.on" />
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "@vue/composition-api"
import { FeedbackPayload } from "@/types/feedback"
import { feedback as FeedbackModule } from "@/store/feedback"
import { raiseSuccess } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { feedbackAdaptor } from "./lib/feedback-adaptor"
import FeedbackCard from "./components/FeedbackCard.vue"
import Emojis from "./templates/emojis"
import Loader from "@/utils/loader"
import "./scss/_feedback.scss"

export default defineComponent({
  name: "FeedbackDialogRoot",
  setup() {
    const feedbackStore = FeedbackModule()
    const loader = Loader()

    const feedback = reactive<FeedbackPayload>({
      feedbackType: null,
      comment: "",
      useEmail: false,
      uploadId: null,
      rate: null
    })

    const emoji = Emojis
    const showDialog = ref(false)

    const sendFeedback = () => {
      loader.run(async () => {
        await feedbackStore.sendFeedback(feedbackAdaptor(feedback))
        feedback.uploadId = null

        raiseSuccess({ text: translate("FeedbackDialog.alert.success") })
        showDialog.value = false
      })
    }

    return {
      emoji,
      showDialog,
      feedback,
      loader,
      sendFeedback
    }
  },
  components: {
    FeedbackCard
  }
})
</script>
