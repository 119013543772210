import { useStore } from "@/composable/useStore"
import { computed } from "@vue/composition-api"
import Utils from "@/utils/utils"
import { createTranslationModule } from "@/plugins/i18n"
import * as WarningTime from "@/config/warning-range"

export const useCertificate = () => {
  const t = createTranslationModule("TriCaster.boxFooter.moreInfo")
  const { spaceModule } = useStore()

  const space = computed(() => {
    return spaceModule.watching
  })

  const certificate = computed(() => {
    return space.value.certificateInfo
  })

  const sslFeedback = computed(() => {
    const classWildCard = "--expiration-zone-"

    if (certificate.value) {
      const expirationDate = certificate.value.expiresAt as string
      const expires = (date: string) => t("subscriptionEndDate", { date })

      if (_sslExpireTime.value > 0) {
        if (_sslExpireTime.value <= WarningTime.WARNING_TIME_RANGE) {
          return {
            state: t("sslStates.verified"),
            text: t("timeLeftLabel", { days: _sslExpireTime.value }, _sslExpireTime.value),
            date: expires(Utils.dateWithNamedMonth(expirationDate)),
            color: classWildCard + "danger"
          }
        } else if (_sslExpireTime.value <= WarningTime.COMFORTABLE_TIME_RANGE) {
          return {
            state: t("sslStates.verified"),
            text: t("timeLeftLabel", { days: _sslExpireTime.value }, _sslExpireTime.value),
            date: expires(Utils.dateWithNamedMonth(expirationDate)),
            color: classWildCard + "warning"
          }
        } else {
          return {
            state: t("sslStates.verified"),
            text: "",
            date: expires(Utils.dateWithNamedMonth(expirationDate)),
            color: ""
          }
        }
      }

      if (expirationDate) {
        return {
          state: t("sslStates.expired"),
          color: classWildCard + "danger",
          date: Utils.dateWithNamedMonth(expirationDate),
          text: t("expired")
        }
      }
    }

    return {
      state: t("sslStates.missing")
    }
  })

  const sslShowWarning = computed((): boolean => {
    return !sslFeedback.value || Boolean(!sslFeedback.value.color)
  })

  const _sslExpireTime = computed(() => {
    const expireTime = certificate.value.expiresAt
    const hasExpiringTime = expireTime !== null

    if (hasExpiringTime) {
      const { days } = Utils.dateRange(Date.now(), expireTime)

      return days
    }

    return 0
  })

  return {
    sslShowWarning,
    certificate,
    sslFeedback
  }
}
