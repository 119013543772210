<template>
  <div v-if="cloudZone" class="gridItem">
    <span>{{ t("region") }}</span>

    <tooltip :text="t('availabilityZone', { zone: cloudZone.az })">
      <span class="value">
        {{ cloudZone.name }}
      </span>

      <span class="prefix">
        <v-icon :size="12">mdi-chevron-double-right</v-icon>
      </span>

      <span class="value">
        {{ cloudZone.code }}
      </span>
    </tooltip>

    <copy-btn :text="cloudZone.code" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { useStore } from "@/composable/useStore"
import { createTranslationModule } from "@/plugins/i18n"
import CopyBtn from "@/components/copyBtn"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "RegionLabel",
  setup() {
    const { spaceModule } = useStore()
    const t = createTranslationModule("TriCaster.boxFooter.moreInfo.")

    const space = computed(() => {
      return spaceModule.watching
    })

    const cloudZone = computed(() => {
      if (space.value.cloudZone && space.value.cloudZoneAZ) {
        const regions = spaceModule.spaceRegions
        const regionDataFromCode = regions.find(region => region.code === space.value.cloudZone)

        if (regionDataFromCode) {
          return {
            name: regionDataFromCode.fullName,
            code: space.value.cloudZone,
            az: space.value.cloudZoneAZ
          }
        }
      }

      return null
    })

    return {
      t,
      cloudZone
    }
  },
  components: {
    CopyBtn,
    Tooltip
  }
})
</script>
