<template>
  <div v-if="(showSystemMessage && canShow) || isPreviewActive" id="systemViewMessage" :name="routeName">
    <div v-html="systemMessage" />

    <v-icon @click="close" size="12">action_close</v-icon>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onBeforeUnmount } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import Router from "@/router"
import "./scss/_systemMessageViewer.scss"

export default defineComponent({
  name: "SystemMessageViewer",
  setup(_, ctx) {
    const usersModule = UsersModule()
    const canShow = ref(true)
    const _continueLoopReq = ref(true)

    const isPreviewActive = computed(() => {
      return usersModule.previewSystemMessage
    })

    const routeName = computed(() => {
      return Router.currentRoute.name as string
    })

    const systemMessage = computed(() => {
      return usersModule.systemMessage.message
    })

    const showSystemMessage = computed(() => {
      const { message, active } = usersModule.systemMessage

      return message?.length > 8 && active
    })

    const close = () => {
      if (!usersModule.previewSystemMessage) {
        canShow.value = false
        sessionStorage.setItem("systemMessageShow", `${canShow.value}`)
        ctx.emit("close")
      }
    }

    const autoRefresh = async () => {
      const canShowDataFromSession = sessionStorage.getItem("systemMessageShow") !== "false"
      const currentRoute = Router.currentRoute.name

      canShow.value = canShowDataFromSession

      if (canShowDataFromSession && currentRoute !== "AdminConfig") {
        await usersModule.getSystemMessage()
      }

      if (_continueLoopReq.value) {
        setTimeout(() => autoRefresh(), 2000)
      }
    }

    autoRefresh()

    onBeforeUnmount(() => {
      _continueLoopReq.value = false
    })

    return {
      close,
      routeName,
      canShow,
      showSystemMessage,
      isPreviewActive,
      systemMessage
    }
  }
})
</script>
