<template>
  <popup v-model="popupOpen" :title="t('title')" width="700px" :loading="loader.on" hide-footer>
    <div class="ipConfigContainer">
      <ul v-if="ipCollection.length" class="ipCollection">
        <li v-for="ipDetail in ipCollection" :key="ipDetail.ip">
          <div class="ipMeta">
            <span>{{ ipDetail.ip }}</span>
            <span> - {{ ipDetail.description }}</span>
            <span v-if="isMyIp(ipDetail.ip)" class="ownIp">{{ t("form.yourIp") }}</span>
          </div>

          <v-icon @click="removeIp(ipDetail)" :disabled="loader.on" :size="16"> mdi-delete </v-icon>
        </li>
      </ul>

      <div v-else class="emptyIpCollection">
        <span>{{ t("ipCollectionEmpty") }}</span>
      </div>

      <div class="addIpContainer">
        <v-form v-model="validForm" ref="IpConfigForm">
          <v-text-field
            v-model="newIpProspect.ip"
            :rules="[v => MandatoryUniqueIP(v, ipStrList)]"
            :disabled="loader.on"
            :label="t('form.ip')"
          />

          <v-text-field
            v-model="newIpProspect.description"
            :disabled="loader.on"
            :rules="MandatoryTextField"
            :label="t('form.description')"
          />
        </v-form>

        <div class="btnContainer">
          <v-btn @click="addIp" :disabled="!validForm || loader.on" large>
            <v-icon left>mdi-plus</v-icon>
            <span>{{ t("formBtns.addIp") }}</span>
          </v-btn>

          <v-btn @click="addMyIp" v-if="!ownIpIsIncludedInCollection" :disabled="loader.on" color="primary" large>
            <v-icon left>mdi-cog-outline</v-icon>
            <span>{{ t("formBtns.addMyIp") }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, ref, computed, reactive } from "@vue/composition-api"
import { AdminModule, UsersModule, WorkspaceModule } from "@/store"
import { MandatoryTextField, MandatoryUniqueIP } from "@/utils/input-rules"
import { RegisteredIp, VForm, Dictionary } from "@/types/core"
import { translate } from "@/plugins/i18n"
import Loader from "@/utils/loader"
import Popup from "@/components/popup/PopupRoot.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "IpConfigPopup",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const workspaceModule = WorkspaceModule()
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const popupOpen = Utils.vModel(props, ctx.emit)
    const IpConfigForm = ref<VForm | null>(null)
    const newIpProspect = reactive({ ip: "", description: "" })
    const validForm = ref(false)
    const loader = Loader()
    const t = (snippet: string) => translate("TriCaster.ipConfig." + snippet)

    const ipCollection = computed(() => {
      return workspaceModule.watching?.authorizedIps ?? []
    })

    const ipStrList = computed(() => {
      return ipCollection.value.map(detail => detail.ip)
    })

    const ownIp = computed(() => {
      return adminModule.ownIp
    })

    const workspaceId = computed(() => {
      return workspaceModule.watching.id
    })

    const ownIpIsIncludedInCollection = computed(() => {
      return ipCollection.value.some(detail => detail.ip === ownIp.value)
    })

    const isMyIp = (ip: string) => {
      return ip === ownIp.value
    }

    const _cleanIpForm = () => {
      Object.keys(newIpProspect).forEach(key => {
        const _formContents = newIpProspect as Dictionary<string>
        _formContents[key] = ""
      })

      if (IpConfigForm.value) {
        IpConfigForm.value.resetValidation()
      }
    }

    const addIp = () => {
      if (IpConfigForm.value && IpConfigForm.value.validate()) {
        loader.run(async () => {
          await authorizeIp(newIpProspect)
          _cleanIpForm()
        })
      }
    }

    const getOwnIp = async () => {
      await adminModule.getOwnIp()
    }

    const addMyIp = async () => {
      await loader.run(async () => {
        await authorizeIp({
          ip: ownIp.value,
          description: usersModule.selfDetail.email
        })
      })
    }

    const removeIp = (ip: RegisteredIp) => {
      loader.run(async () => {
        await workspaceModule.revokeIP(ip, workspaceId.value)
      })
    }

    const authorizeIp = async (ip: RegisteredIp) => {
      await workspaceModule.authorizeIP(ip, workspaceId.value)
    }

    loader.run(async () => {
      if (!ownIp.value) {
        await getOwnIp()
      }
    })

    return {
      t,
      addIp,
      addMyIp,
      validForm,
      ipCollection,
      ownIpIsIncludedInCollection,
      newIpProspect,
      popupOpen,
      loader,
      ipStrList,
      isMyIp,
      removeIp,
      IpConfigForm,
      MandatoryUniqueIP,
      MandatoryTextField
    }
  },
  components: {
    Popup
  }
})
</script>
