<template>
  <popup
    v-model="details.isOpen"
    :title="titleDescription"
    :loading="loader.on"
    :confirmation-color="popupControllerSettings.color"
    :button-confirm-text="popupControllerSettings.text"
    @confirm="validateFormThenAddToken"
    class="manageTokens"
    width="500px"
  >
    <v-form ref="Form">
      <add-token-inputs
        v-if="is(Action.ADD)"
        v-model="addingTokenPayload"
        :tokens="currentAmountOfTokens"
        :loading="loader.on"
      />

      <remove-token-inputs
        v-else-if="is(Action.REMOVE)"
        v-model="removeTokenPayload"
        :tokens="currentAmountOfTokens"
        :loading="loader.on"
      />
    </v-form>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType, reactive } from "@vue/composition-api"
import { AddTokenPayload, RemoveTokenPayload } from "@/types/organization"
import { AdminModule, OrganizationModule } from "@/store"
import { VForm } from "@/types/core"
import { EXPIRATION_TIME } from "@/config/token-expiration-date"
import RemoveTokenInputs from "./_RemoveTokenInputs.vue"
import Popup from "@/components/popup/PopupRoot.vue"
import Loader from "@/utils/loader"
import AddTokenInputs from "./_AddTokenInputs.vue"
import Utils from "@/utils/utils"
import { translate } from "@/plugins/i18n"

enum Action {
  ADD = "add",
  REMOVE = "remove"
}

interface TransactionDetail {
  id: string
  action: Action
  isOpen: boolean
}

const date = (dateTime = 0) => new Date(Date.now() + dateTime).toISOString().slice(0, 10)

export default defineComponent({
  name: "EditTokenTransaction",
  props: {
    value: {
      type: Object as PropType<TransactionDetail>,
      required: true
    }
  },
  setup(props, ctx) {
    const adminModule = AdminModule()
    const details = Utils.vModel(props, ctx.emit)
    const loader = Loader()
    const organizationModule = OrganizationModule()
    const Form = ref<VForm | null>(null)

    const addingTokenPayload = reactive<AddTokenPayload>({
      purchaseId: "",
      description: "",
      initialAmount: 0,
      activationDate: date(),
      expirationDate: date(EXPIRATION_TIME)
    })

    const removeTokenPayload = reactive<RemoveTokenPayload>({
      amount: 0,
      description: ""
    })

    const popupControllerSettings = computed(() => {
      switch (details.value.action) {
        case Action.ADD:
          return {
            text: translate("Admin.organizations.createPopup.controller.add"),
            color: "primary"
          }

        case Action.REMOVE:
          return {
            text: translate("Admin.organizations.createPopup.controller.remove"),
            color: "error"
          }
      }
    })

    const titleDescription = computed(() => {
      const addingTokens = details.value.action === Action.ADD
      const addingTokenSnippet = translate("Admin.organizations.createPopup.addTokenFields.title")
      const removeTokenSnippet = translate("Admin.organizations.createPopup.removeTokenFields.title")

      return addingTokens ? addingTokenSnippet : removeTokenSnippet
    })

    const currentAmountOfTokens = computed(() => {
      return organizationModule.walletDetail.amount || 0
    })

    const organizationId = computed(() => {
      return adminModule.organizationDetail?.id as string
    })

    const _addToken = () => {
      loader.run(async () => {
        await adminModule.addToken(organizationId.value, addingTokenPayload)
        details.value.isOpen = false
        ctx.emit("update:wallet", organizationId.value)
      })
    }

    const _removeToken = () => {
      loader.run(async () => {
        await adminModule.removeToken(organizationId.value, removeTokenPayload)
        details.value.isOpen = false
        ctx.emit("update:wallet", organizationId.value)
      })
    }

    const is = (action: Action) => {
      return details.value.action === action
    }

    const validateFormThenAddToken = () => {
      if (Form.value && Form.value.validate()) {
        switch (details.value.action) {
          case Action.ADD:
            _addToken()
            break
          case Action.REMOVE:
            _removeToken()
            break
        }
      }
    }

    return {
      is,
      validateFormThenAddToken,
      popupControllerSettings,
      removeTokenPayload,
      Form,
      details,
      currentAmountOfTokens,
      addingTokenPayload,
      loader,
      titleDescription,
      Action
    }
  },
  components: {
    RemoveTokenInputs,
    AddTokenInputs,
    Popup
  }
})
</script>
