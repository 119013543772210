import { translate } from "@/plugins/i18n"

export const getLabel = (label?: string) => {
  if (label) {
    const isTranslationSnippet = label.slice(0, 2) === "$t"
    return isTranslationSnippet ? translate(label.slice(2)) : label
  }

  return null
}
