import { translate } from "@/plugins/i18n"
import * as AppTimer from "@/config/app-timer-speculation"

interface ProgressSteps {
  [key: string]: ProgressStepOption
}

export interface ProgressStepOption {
  averageTime: number
  prefix: string
  [key: number]: string
}

export const DIRECTOR: ProgressSteps = {
  archiving: {
    averageTime: AppTimer.ARCHIVING_TIMER,
    prefix: translate("TriCaster.feedbacks.archiving.prefix"),
    10: translate("TriCaster.feedbacks.archiving.first"),
    60: translate("TriCaster.feedbacks.archiving.second"),
    80: translate("TriCaster.feedbacks.archiving.third")
  },
  restoring: {
    averageTime: AppTimer.RESTORING_TIMER,
    prefix: translate("TriCaster.feedbacks.restoring.prefix"),
    10: translate("TriCaster.feedbacks.restoring.first"),
    60: translate("TriCaster.feedbacks.restoring.second"),
    80: translate("TriCaster.feedbacks.restoring.third")
  },
  deploying: {
    averageTime: AppTimer.DEPLOYING_TIMER,
    prefix: translate("TriCaster.feedbacks.deploying.prefix"),
    10: translate("TriCaster.feedbacks.deploying.first"),
    60: translate("TriCaster.feedbacks.deploying.second"),
    80: translate("TriCaster.feedbacks.deploying.third")
  },
  starting: {
    averageTime: AppTimer.STARTING_TIMER,
    prefix: translate("TriCaster.feedbacks.starting.prefix"),
    10: translate("TriCaster.feedbacks.starting.first"),
    60: translate("TriCaster.feedbacks.starting.second"),
    80: translate("TriCaster.feedbacks.starting.third")
  },
  stopping: {
    averageTime: AppTimer.STOPPING_TIMER,
    prefix: translate("TriCaster.feedbacks.stopping.prefix"),
    10: translate("TriCaster.feedbacks.stopping.first"),
    60: translate("TriCaster.feedbacks.stopping.second"),
    80: translate("TriCaster.feedbacks.stopping.third")
  }
}
