import { FileUploadPayload } from "@/types/core"

const convertBase64ToBlob = (base64Image: string): Blob => {
  const parts = base64Image.split(";base64,")
  const imageType = parts[0].split(":")[1]
  const decodedData = atob(parts[0])
  const uInt8Array = new Uint8Array(decodedData.length)

  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: imageType })
}

export const download = (file: FileUploadPayload): void => {
  if (file.contentsBase64) {
    const blob = convertBase64ToBlob(file.contentsBase64)
    const $htmlElement = document.createElement("a")

    $htmlElement.download = file.fileName
    $htmlElement.href = URL.createObjectURL(blob)
    document.body.appendChild($htmlElement)
    $htmlElement.click()
    $htmlElement.remove()
  } else {
    throw Error("File without Base64")
  }
}

export const downloadFromBase64 = (base64: string, fileName: string) => {
  if (base64) {
    const blob = convertBase64ToBlob(base64)
    const $htmlElement = document.createElement("a")

    $htmlElement.download = fileName
    $htmlElement.href = URL.createObjectURL(blob)
    document.body.appendChild($htmlElement)
    $htmlElement.click()
    $htmlElement.remove()
  } else {
    throw Error("File without Base64")
  }
}
