<template>
  <div id="licenseServer">
    <div class="leftContainer">
      <license-card @update:server="rippleServerStatus" :deploy-stage="serverStatus" />
    </div>

    <div class="rightContainer">
      <v-alert v-if="showWarning" class="animateFadeUp" type="warning" dense outlined>
        <template v-slot:prepend>
          <v-icon left color="warning">status_warning</v-icon>
        </template>

        {{ $t("Onboarding.licenseServer.alert.mightTakeLong") }}
      </v-alert>

      <p>{{ $t("Onboarding.licenseServer.after") }}</p>

      <error-handler @reset="$emit('reset')" v-if="error" />

      <ip-handler v-if="showControllers" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceStatusEnum } from "@/types/workspace"
import { workspaceIsLoading } from "@/config/workspace-states"
import IpHandler from "./IpHandler.vue"
import LicenseCard from "./LicenseCard.vue"
import ErrorHandler from "./ErrorHandler.vue"

export default defineComponent({
  name: "LicenseServer",
  props: {
    serverStatus: {
      type: String as PropType<WorkspaceStatusEnum>,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    },
    showControllers: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const rippleServerStatus = (status: string) => {
      ctx.emit("update:server", status)
    }

    const showWarning = computed(() => {
      return workspaceIsLoading(props.serverStatus)
    })

    return {
      rippleServerStatus,
      showWarning
    }
  },
  components: {
    LicenseCard,
    ErrorHandler,
    IpHandler
  }
})
</script>
