import { onMounted, nextTick } from "@vue/composition-api"

export const useValidate = (emitter: (eventName: string) => void) => {
  const validate = () => {
    nextTick(() => {
      emitter("validate")
    })
  }

  onMounted(() => {
    validate()
  })

  return {
    validate
  }
}
