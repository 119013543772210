import { translate } from "@/plugins/i18n"
import { HeaderItem } from "@/types/core"

const headers: () => HeaderItem[] = () => {
  return [
    {
      text: translate("Admin.organizations.tableHeader.name"),
      align: "start",
      value: "name",
      sortable: true
    },
    {
      text: translate("Admin.organizations.tableHeader.date"),
      align: "start",
      value: "creationDate",
      sortable: false
    },
    {
      text: translate("Admin.organizations.tableHeader.creator"),
      align: "start",
      value: "createdByUser",
      sortable: false
    },
    {
      text: translate("Admin.organizations.tableHeader.deployedWorkspaces"),
      align: "start",
      value: "workspaces",
      sortable: false
    },
    {
      text: translate("Admin.organizations.tableHeader.hasLicense"),
      align: "start",
      value: "hasLicense",
      sortable: false
    },
    {
      text: translate("Admin.organizations.tableHeader.isReady"),
      align: "start",
      value: "isReady",
      sortable: false
    },
    {
      text: translate("Admin.organizations.tableHeader.sslCertificateStatus"),
      align: "start",
      value: "certificateInfo",
      sortable: false
    },
    {
      text: translate("Admin.organizations.tableHeader.organizationType"),
      align: "start",
      value: "organizationType",
      sortable: true
    },
    {
      text: translate("Admin.organizations.tableHeader.action"),
      align: "start",
      value: "action",
      sortable: false
    }
  ]
}

export default headers
