<template>
  <div class="typeContainer">
    <span class="itemName">{{ title }}</span>
    <span class="percentValue">{{ percentValue }}%</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "TypeContainer",
  props: {
    title: {
      type: String,
      required: true
    },
    percentValue: {
      type: Number,
      required: true
    }
  }
})
</script>
