<template>
  <div id="cloudConfig" class="onboardingItem stepTwo">
    <div class="contentTitle">
      <label>
        <span class="field-label" />
      </label>

      <div class="stepController">
        <v-btn @click="backStep" large>
          {{ $t("Onboarding.buttons.back") }}
        </v-btn>

        <v-btn @click="nextStep" large color="primary">
          {{ $t("Onboarding.buttons.next") }}
        </v-btn>
      </div>
    </div>

    <div class="cloudConfigContainer spaced">
      <div class="awsStepsContainer">
        <create-role-step-new-ui @copy:snippet="copyAwsSnippet" @copy:snippet-json-permission="copyJsonPermission" />
      </div>

      <div class="awsForm">
        <v-form ref="Form">
          <deploy-agent-form v-model="arnCode" />
        </v-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api"
import { LicenseModule } from "@/store/license-server"
import { VForm } from "@/types/core"
import { copyToClipboard } from "@/utils/ui"
import DeployAgentForm from "./DeployAgentForm.vue"
import Utils from "@/utils/utils"
import CreateRoleStepNewUi from "./CreateRoleStepNewUI.vue"
import Snippets from "../../lib/copy-snippet"

export default defineComponent({
  name: "DeployAgentRoot",
  setup(_, ctx) {
    const Form = ref<null | VForm>(null)
    const licenseModule = LicenseModule()
    const arnCode = ref("")

    const backStep = () => {
      ctx.emit("back")
    }

    const nextStep = () => {
      const form = Utils.isType<VForm>(Form.value, "validate")

      if (form && form.validate()) {
        licenseModule.$state.licenseServer.spaceliftAwsRoleArn = arnCode.value
        ctx.emit("next")
      }
    }

    const copyAwsSnippet = () => {
      copyToClipboard(Snippets.trustPolicy)
    }

    const copyJsonPermission = () => {
      copyToClipboard(Snippets.inlinePolicy)
    }

    return {
      Form,
      arnCode,
      copyJsonPermission,
      copyAwsSnippet,
      nextStep,
      backStep
    }
  },
  components: {
    CreateRoleStepNewUi,
    DeployAgentForm
  }
})
</script>
