<template>
  <div id="login">
    <div class="loginContainer">
      <v-img color="primary" class="companyLogo" :width="155" src="@/assets/vizrt-logo-color.svg" />

      <router-link :to="{ name: 'Honeypot' }" rel="nofollow" style="display: none" aria-hidden="true">
        Confirm
      </router-link>

      <div class="titleWrap">
        <h1>{{ $t("login.welcomeBack") }}</h1>

        <p>{{ $t("login.signInToUse") }}</p>
      </div>

      <v-btn
        @click="openLoginPopup"
        :loading="loader.on"
        :disabled="isAuthenticated"
        rbt-target="loginButton"
        color="primary"
        block
        large
      >
        <span>{{ $t("login.logIn") }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import { session } from "@/auth/session"
import { raiseError } from "@/utils/event-bus"
import { isDevelopment } from "@/utils/env"
import Loader from "@/utils/loader"
import Utils from "@/utils/utils"
import "./scss/_login.scss"

export default defineComponent({
  setup() {
    const usersModule = UsersModule()
    const loader = Loader({ alertOnError: false })

    const isAuthenticated = computed(() => {
      return session.auth.isAuthenticated
    })

    const _isIgnorableError = (err: unknown) => {
      const isError = Utils.isType<Error>(err, "message")

      if (isError) {
        const errorMsg = isError.message
        const ignorableErrors = ["Popup closed"]

        return ignorableErrors.includes(errorMsg)
      }

      return false
    }

    const openLoginPopup = async () => {
      loader.run(async () => {
        try {
          await session.login()

          if (!isDevelopment()) {
            await usersModule.getMyDetails()

            session.updateSelfDetails(usersModule.selfDetail)
          }
        } catch (err) {
          if (!_isIgnorableError(err)) {
            raiseError({ text: err as string, error: err }, true)
          }
        }
      })
    }

    return {
      loader,
      isAuthenticated,
      openLoginPopup
    }
  }
})
</script>
