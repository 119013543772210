import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { ProviderSettings, PayloadUpdateRegion, ArnUpdate } from "@/types/core"
import { IUser, EditUserPayload, CreateUserPayload } from "@/types/user"
import { WorkspaceRawTemplate, LicenseProduct } from "@/types/workspace"
import { BasicDeployPackageData, DeployPackagePayload, ServiceVersion } from "@/types/deployPackages"
import { IDeployPackage, IDeployPackageVersion } from "@/types/deployPackages"

import {
  CreateOrganizationPayload,
  IOrganization,
  OrganizationItem,
  EditSubscriptionPayload,
  SimpleOrganizationItem
} from "@/types/organization"

const api = new Request("/v1/admin/")
const apiDeployment = new Request("/v1/Deployment/")
const apiWorkspaceTemplates = new Request("v1/WorkspaceTemplates/")

export default createService("admin", {
  async getSupportedRegions() {
    return await api.get<string[]>("Organizations/GetSupportedRegions/")
  },

  async editSubscription(organizationId: string, payload: EditSubscriptionPayload) {
    return await api.put<void>(`Organizations/${organizationId}/Subscription`, payload)
  },

  async getOrganizationUsers(organizationId: string) {
    return await api.get<IUser[]>(`Users/${organizationId}`)
  },

  async editUser(userData: EditUserPayload, organizationId: string) {
    const { id, ...editUserPayload } = userData
    return await api.put<IUser>(`Users/${organizationId}/${id}`, editUserPayload)
  },

  async deleteUser(userId: string, organizationId: string) {
    return await api.delete(`Users/${organizationId}/${userId}`)
  },

  async createUser(user: CreateUserPayload, organizationId: string) {
    return await api.post<IUser>(`Users/${organizationId}`, user)
  },

  async getAllWorkspaceTemplates() {
    return await apiWorkspaceTemplates.get<WorkspaceRawTemplate[]>("")
  },

  async changeOrganizationName(organizationName: string, organizationId: string) {
    return await api.put(`Organizations/UpdateName/${organizationId}`, { organizationName })
  },

  async getOrganizationCollection() {
    return await api.get<OrganizationItem[]>("Organizations/")
  },

  async getSimpleOrganizationCollection() {
    return await api.get<SimpleOrganizationItem[]>("Organizations/Simple")
  },

  async resetTermsAndConditions() {
    return await api.post("Users/ResetTermsAcceptance")
  },

  async editWorkspaceTemplate(templateId: string, deployPackageId: string) {
    return await apiWorkspaceTemplates.post(`SetDeployPackage/${templateId}/${deployPackageId}`)
  },

  async setWorkspaceTemplateInstruction(templateId: string, instructions: string) {
    return await apiWorkspaceTemplates.put(`${templateId}/Instructions`, { instructions })
  },

  async createOrganization(createOrganizationPayload: CreateOrganizationPayload) {
    return await api.post<IOrganization>("Organizations", createOrganizationPayload)
  },

  async deleteOrganization(organizationId: string) {
    return await api.delete(`Organizations/${organizationId}`)
  },

  async getOrganizationDetail(organizationId) {
    return await api.get<IOrganization>(`Organizations/${organizationId}`)
  },

  async updateWorkspaceTemplate(workspaceTemplateIds: string[], organizationId: string) {
    return await api.put<boolean>(`Organizations/UpdateWorkspaceTemplates/${organizationId}`, { workspaceTemplateIds })
  },

  async getOwnIp() {
    return await apiDeployment.get<string>("GetIPAddress")
  },

  async createDeployPackage(packageData: BasicDeployPackageData) {
    return await api.post<IDeployPackage>("DeployPackages", packageData)
  },

  async getDeployPackageDetails(deployPackageId: string) {
    return await api.get<IDeployPackage>(`DeployPackages/${deployPackageId}`)
  },

  async downloadDeploySpaceTemplate(deployPackageId: string, versionId: string) {
    return await api.get<IDeployPackageVersion>(`DeployPackages/${deployPackageId}/Versions/${versionId}`)
  },

  async getPackageVersions(deployPackageId: string) {
    return await api.get<IDeployPackageVersion[]>(`DeployPackages/${deployPackageId}/Versions`)
  },

  async releaseDeployPackageVersion(deployPackageId: string, packageData: DeployPackagePayload) {
    return await api.post<IDeployPackageVersion>(`DeployPackages/${deployPackageId}/Versions`, packageData)
  },

  async deleteDeployPackage(deployPackageId: string) {
    return await api.delete(`DeployPackages/${deployPackageId}`)
  },

  async deleteDeployPackageVersion(deployPackageId: string, versionId: string) {
    return await api.delete(`DeployPackages/${deployPackageId}/Versions/${versionId}/`)
  },

  async pickPackageVersion(deployPackageId: string, versionId: string) {
    return await api.put(`DeployPackages/${deployPackageId}/Versions/${versionId}/SetCurrent/`)
  },

  async setProviderSettings(providerSettings: ProviderSettings | ArnUpdate) {
    const { organizationId, ...settings } = providerSettings

    return await api.post(`Organizations/${organizationId}/ProviderSettings`, { ...settings })
  },

  async getDeployPackages() {
    return await api.get<IDeployPackage[]>("DeployPackages")
  },

  async updateDeployPackage(packageData: IDeployPackage) {
    const { name, description } = packageData
    return await api.put(`DeployPackages/UpdateDetails/${packageData.id}`, { name, description })
  },

  async getOrganizationLicenses(organizationId: string) {
    return await api.get<LicenseProduct[]>(`Organizations/${organizationId}/ListLicenses`)
  },

  async updateOrganizationDetails(settings: PayloadUpdateRegion) {
    const { id } = settings
    return await api.put<IOrganization>(`Organizations/UpdateDetails/${id}`, settings)
  },

  async getDeployPackageServiceVersions() {
    return await api.get<{ options: ServiceVersion[] }>("DeployPackages/ServiceVersions")
  }
})
