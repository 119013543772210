<template>
  <div v-if="waitingOnMandatoryConfigurations" class="infoBox preDeployWarning">
    <v-icon color="warning">status_warning</v-icon>

    <div class="description">
      {{ t("preDeployWarning") }}
    </div>
  </div>

  <div v-else-if="notifyPreDeployImprovementPossible" class="infoBox preDeployInfo">
    <v-icon color="primary">vizartist_show_scene_info_editor</v-icon>

    <div class="description">
      {{ t("preDeployInfo") }}
    </div>
  </div>
</template>

<script lang="ts">
import type { AppDiffType } from "@/utils/workspaceDiff"
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { useStore } from "@/composable/useStore"
import { IWorkspaceAsset } from "@/types/workspace"
import { WorkspaceStates } from "@/config/workspace-states"
import { WidgetText, WizardConfigItem } from "@/types/wizard"
import { createTranslationModule } from "@/plugins/i18n"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "PreDeployWarning",
  props: {
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    flagType: {
      type: String as PropType<AppDiffType>,
      required: true
    }
  },
  setup(props) {
    const { spaceModule } = useStore()
    const t = createTranslationModule("WorkspaceEdit.appCard.label")

    const isPreDeploy = computed(() => {
      return !WorkspaceStates.isDeployed(spaceModule.watching.status)
    })

    const isNewlyAddedApp = computed(() => {
      return props.flagType === "added"
    })

    const waitingOnMandatoryConfigurations = computed(() => {
      if (isPreDeploy.value || isNewlyAddedApp.value) {
        const userConfigurations = props.app.userConfigurations
        const hasPendingMandatoryFields = userConfigurations?.some(_validateWidget)

        return hasPendingMandatoryFields
      }

      return false
    })

    const notifyPreDeployImprovementPossible = computed(() => {
      if (isPreDeploy.value || isNewlyAddedApp.value) {
        const userConfigurations = props.app.userConfigurations
        const hasFirstDeployConfiguration = userConfigurations?.some(
          configuration => configuration?.settings?.firstDeployOnly
        )

        return hasFirstDeployConfiguration
      }

      return false
    })

    const _validateWidget = (configuration: WizardConfigItem) => {
      if (configuration.settings.firstDeployOnly) {
        const widgetValueBased = Utils.isType<WidgetText>(configuration.settings.widget, "value")

        if (widgetValueBased && widgetValueBased.rule && configuration.settings.active) {
          const inputIsValid = new RegExp(widgetValueBased.rule.regex).test(widgetValueBased.value)
          const shouldAlertWarning = !inputIsValid

          return shouldAlertWarning
        }
      }

      return false
    }

    return {
      t,
      notifyPreDeployImprovementPossible,
      waitingOnMandatoryConfigurations
    }
  }
})
</script>
