<template>
  <div v-if="schedule" class="schedule">
    <v-icon :size="24" :color="autoShutdownIconConfig.color">
      {{ autoShutdownIconConfig.icon }}
    </v-icon>

    <span>{{ t("scheduledSession") }}</span>
    <div :class="scheduleClasses">
      <span v-if="schedule.start"><span>↑</span> {{ schedule.start }}</span>
      <label v-if="schedule.start && schedule.end">—</label>
      <span v-if="schedule.end"><span>↓</span> {{ schedule.end }}</span>
    </div>

    <button @click="handleClick" class="customButton --icon">
      <v-icon>{{ ICONS.edit }}</v-icon>
    </button>
  </div>

  <div v-else-if="shutdown" class="schedule">
    <v-icon :size="24" :color="autoShutdownIconConfig.color">
      {{ autoShutdownIconConfig.icon }}
    </v-icon>

    <div v-if="shutdown.inactive" class="spanContainer">
      <span>{{ t("checkingInactivity") }}</span>
      <span>{{ shutdown.inactive }}</span>
    </div>

    <div v-else-if="shutdown.due" class="spanContainer">
      <span>{{ t("shuttingDownIn") }}</span>
      <span>{{ shutdown.due }}</span>
    </div>

    <div v-else-if="shutdown.programmed" class="spanContainer">
      <span>{{ t("staysOnlineFor") }}</span>
      <span>{{ shutdown.programmed }}</span>
    </div>

    <v-btn @click="handleClick" icon>
      <v-icon>{{ ICONS.edit }}</v-icon>
    </v-btn>
  </div>

  <v-btn v-else @click="handleClick" :disabled="disableAutoShutdownButton" large>
    <v-icon left>{{ ICONS.clock }}</v-icon>
    <span>{{ t("scheduleSession") }}</span>
  </v-btn>
</template>

<script lang="ts">
import { AutoShutdownStatus } from "@/types/workspace"
import { defineComponent, computed } from "@vue/composition-api"
import { useStore } from "@/composable/useStore"
import { useSchedule } from "../../composable/useSchedule"
import { useShutdown } from "../../composable/useShutdown"
import { APP_COLORS } from "@/config/colors"
import { createTranslationModule } from "@/plugins/i18n"
import { WorkspaceStates } from "@/config/workspace-states"

const ICONS = {
  off: "mdi-timer-off",
  check: "mdi-timer-check",
  alert: "mdi-timer-alert",
  clock: "mdi-clock-outline",
  edit: "action_edit"
}

export default defineComponent({
  name: "AutoShutdownTrigger",
  setup(_, ctx) {
    const { spaceModule } = useStore()
    const { schedule, scheduleEnabled, scheduleClasses } = useSchedule()
    const { shutdown } = useShutdown()
    const t = createTranslationModule("WorkspaceEdit.autoShutdownTrigger")

    const hasAutoShutdownConfigured = computed(() => {
      const { autoShutdownEnabled } = spaceModule.shutdownSettings
      const _schedule = spaceModule.scheduledOnOff
      const hasSchedule = Boolean(_schedule.autoShutdownAt || _schedule.autoStartupAt)
      const scheduleEnabled = _schedule.scheduledOnOffEnabled && hasSchedule

      return scheduleEnabled || autoShutdownEnabled
    })

    const autoShutdownIconConfig = computed(() => {
      const space = spaceModule.watching
      const isOnline = space.onlineStatus === "online"

      if (isOnline) {
        return _iconSettingsWhenOnline.value
      } else {
        return _iconSettingsWhenOffline.value
      }
    })

    const disableAutoShutdownButton = computed(() => {
      const space = spaceModule.watching
      const appLoading = WorkspaceStates.appLoading(space.onlineStatus)
      const isDraft = space.status === "draft"

      if (isDraft) {
        return false
      } else if (appLoading) {
        return true
      }

      if (space.onlineStatus === "offline") {
        return Boolean(hasAutoShutdownConfigured.value || shutdown.value)
      }

      return true
    })

    const _iconSettingsWhenOnline = computed(() => {
      const space = spaceModule.watching
      const shutdownNotConfigured = !space.autoShutdownDefinition

      if (shutdownNotConfigured) {
        return {
          icon: ICONS.off,
          color: APP_COLORS.gray
        }
      } else {
        return {
          icon: ICONS.check,
          color: _iconColor.value
        }
      }
    })

    const _iconSettingsWhenOffline = computed(() => {
      if (hasAutoShutdownConfigured.value) {
        return {
          icon: ICONS.alert,
          color: APP_COLORS.gray
        }
      } else {
        return {
          icon: ICONS.off,
          color: APP_COLORS.gray
        }
      }
    })

    const _iconColor = computed(() => {
      const { nearAutoShutdown } = AutoShutdownStatus
      const space = spaceModule.watching
      const nearShutdown = space?.autoShutdownDefinition?.status === nearAutoShutdown
      const isOnline = space.onlineStatus === "online"

      if (isOnline) {
        if (nearShutdown) {
          return APP_COLORS.yellow
        }

        return APP_COLORS.green
      }

      return APP_COLORS.gray
    })

    const handleClick = () => {
      const space = spaceModule.watching
      const shutdownConfigured = Boolean(space.autoShutdownDefinition)
      const spaceIsOn = space.onlineStatus === "online"

      if (shutdownConfigured && spaceIsOn) {
        ctx.emit("show:postpone")
      } else {
        ctx.emit("show:shutdown")
      }
    }

    return {
      ICONS,
      disableAutoShutdownButton,
      t,
      shutdown,
      scheduleEnabled,
      schedule,
      scheduleClasses,
      hasAutoShutdownConfigured,
      handleClick,
      autoShutdownIconConfig
    }
  }
})
</script>
