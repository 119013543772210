<template>
  <div
    @click.exact="openWorkspaceCurrentTab"
    @click.ctrl="openWorkspaceNewTab"
    :rbt-target="`gridCard${titleInPascal}`"
    class="workspaceGridCard"
  >
    <header-cloud-states :workspace="workspace" />

    <div class="flex-grow-1 px-5 py-4 cursor-pointer d-flex flex-column">
      <div>
        <div class="d-flex justify-start align-center">
          <h5 class="text-nowrap" style="font-size: 24px">
            {{ workspace.title }}
          </h5>

          <grid-card-status-icon :workspace="workspace" error />
          <grid-card-status-icon :workspace="workspace" shared />

          <v-spacer />

          <v-avatar v-tippy="{ content: workspace.username }" :color="isOwner ? '#384db1' : ''">
            <span style="color: var(--viz-white)">{{ getInitials(workspace.username) }}</span>
          </v-avatar>
        </div>

        <div>
          <p class="body-1 description" style="color: var(--viz-grey-2)">
            {{ workspace.description || "&nbsp;" }}
          </p>
        </div>

        <div class="tagListContainer">
          <div>
            <v-chip v-for="tag in availableTags" :key="tag" :value="tag" class="tag">{{ tag }}</v-chip>

            <span v-if="getTags(workspace).tags.length > DISPLAY_TAG_LIMIT">
              <tooltip
                :text="$tc('workspaces.appDetails.tooltip.hiddenTags', hiddenTagsCount, { count: hiddenTagsCount })"
              >
                <span>...</span>
              </tooltip>
            </span>
          </div>
        </div>

        <div class="workspaceAppsContainer">
          <span
            v-for="(asset, index) in (workspace.schemaDeployed || workspace.schema).assets.filter(a => a.type != 'vpc')"
            :key="asset.id"
            class="limitSize"
          >
            <span v-if="index > 0">, </span>
            <tooltip :text="onlineStatusLabel(asset)">
              <span
                class="asset-description"
                :is-online="asset.onlineStatus || 'offline'"
                :class="{ [workspace.status]: true, [asset.onlineStatus]: true }"
              >
                {{ asset.description }}
              </span>
            </tooltip>
          </span>
        </div>
      </div>
    </div>

    <online-switch :workspace="workspace" grid-view="grid" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "@vue/composition-api"
import { WorkspaceTemplate, IWorkspaceAsset } from "@/types/workspace"
import { translate } from "@/plugins/i18n"
import { getTags } from "@/utils/get-space-tags"
import { UsersModule } from "@/store/users"
import OnlineSwitch from "../shared/OnlineSwitchRoot.vue"
import GridCardStatusIcon from "./GridCardStatusIcon.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import HeaderCloudStates from "./HeaderCloudStates.vue"
import Router, { navigate } from "@/router"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "GridCard",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    preventNavigation: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup(props, ctx) {
    const DISPLAY_TAG_LIMIT = 5
    const usersModule = UsersModule()

    const isOwner = computed(() => {
      return props.workspace.userId === usersModule.selfDetail.id
    })

    const titleInPascal = computed(() => {
      return Utils.toPascal(props.workspace.title)
    })

    const onlineStatusLabel = (asset: IWorkspaceAsset) => {
      if (asset.onlineStatus) {
        return asset.onlineStatus.toUpperCase()
      }

      return translate("workspaceOnlineStatus.offline").toUpperCase()
    }

    const openWorkspaceNewTab = () => {
      if (props.preventNavigation) {
        ctx.emit("click:ctrl")
        return
      }

      const routeData = Router.resolve({ name: "WorkspaceEdit", params: { id: props.workspace.id } })
      window.open(routeData.href, "_blank")
    }

    const openWorkspaceCurrentTab = () => {
      if (props.preventNavigation) {
        ctx.emit("click")
        return
      }

      navigate({
        name: "WorkspaceEdit",
        params: {
          id: props.workspace.id
        }
      })
    }

    const getInitials = (userName: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [firstName, lastName, _] = userName.split(" ")

      return Utils.getInitials({ firstName, lastName })
    }

    const availableTags = computed(() => {
      return getTags(props.workspace)
        .tags.map(tag => tag.name)
        .slice(0, DISPLAY_TAG_LIMIT)
    })

    const hiddenTagsCount = computed(() => {
      return Math.floor(getTags(props.workspace).tags.length - availableTags.value.length)
    })

    return {
      titleInPascal,
      getTags,
      openWorkspaceCurrentTab,
      openWorkspaceNewTab,
      onlineStatusLabel,
      getInitials,
      availableTags,
      hiddenTagsCount,
      DISPLAY_TAG_LIMIT,
      isOwner
    }
  },
  components: {
    OnlineSwitch,
    GridCardStatusIcon,
    HeaderCloudStates,
    Tooltip
  }
})
</script>

<style lang="scss">
.workspaces-grid-card {
  background-color: $vizNow-color-5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  overflow: hidden;
  filter: brightness(1);
  transition: filter 0.3s ease;
  &:hover {
    filter: brightness(1.2);
  }

  .asset-description {
    color: var(--viz-grey-2);
    &.deployed.online {
      color: #17eb8c;
    }
    &.deployed.starting,
    &.deployed.stopping,
    &.deployed.aborting {
      animation: workspace-asset-description-blinker 2s linear infinite;
    }
  }
  .tags-list {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      background-image: linear-gradient(to right, rgba(54, 57, 61, 0), rgba(54, 57, 61, 1) 90%);
      width: 2em;
      height: 100%;
    }
  }
}
@keyframes workspace-asset-description-blinker {
  50% {
    color: var(--viz-white);
  }
}
</style>
