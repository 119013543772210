<template>
  <div class="centered">
    <v-btn
      v-if="isOnline"
      :disabled="loading && !isActiveIndex(SetOffline)"
      :loading="isActiveIndex(SetOffline)"
      @click="setOnlineStatus(SetOffline)"
      large
    >
      {{ $t("TriCaster.buttons.goOffline") }}
    </v-btn>

    <enough-tokens>
      <template v-slot="{ disabled }">
        <open-button
          v-if="isOnline"
          @open:ndi-bridge="handleOpenClick('open:ndi-bridge')"
          @open:nice-dcv="handleOpenClick('open:nice-dcv')"
          @open:both="handleOpenClick('open:both')"
          :disabled="(loading && !isActiveIndex(OpenDcv)) || disabled"
          :loading="isActiveIndex(OpenDcv)"
        />
      </template>
    </enough-tokens>

    <v-btn
      v-if="!isOnline"
      :disabled="loading && !isActiveIndex(Archive)"
      :loading="isActiveIndex(Archive)"
      @click="archive"
      large
    >
      {{ $t("TriCaster.buttons.archive") }}
    </v-btn>

    <enough-tokens>
      <template v-slot="{ disabled }">
        <v-btn
          v-if="!isOnline"
          :disabled="(loading && !isActiveIndex(SetOnline)) || disabled"
          :loading="isActiveIndex(SetOnline)"
          @click="setOnlineStatus(SetOnline)"
          color="primary"
          large
        >
          {{ $t("TriCaster.buttons.goOnline") }}
        </v-btn>
      </template>
    </enough-tokens>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum } from "@/types/workspace"
import OpenButton from "./_OpenButton.vue"
import EnoughTokens from "../_EnoughTokens.vue"

enum ButtonIndex {
  Default = -1,
  SetOffline = 1,
  OpenDcv = 2,
  Archive = 3,
  SetOnline = 4
}

export default defineComponent({
  name: "WhenDeployed",
  props: {
    isOnline: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const lastButtonClickedIndex = ref<ButtonIndex>(-1)

    const isActiveIndex = (buttonIndex: ButtonIndex) => {
      return buttonIndex === lastButtonClickedIndex.value && props.loading
    }

    const handleOpenClick = (emit: string) => {
      lastButtonClickedIndex.value = ButtonIndex.OpenDcv
      ctx.emit(emit)
    }

    const archive = () => {
      ctx.emit("archive")
      lastButtonClickedIndex.value = ButtonIndex.Archive
    }

    const setOnlineStatus = (buttonFunction = ButtonIndex.SetOnline) => {
      const { Starting, Stopping } = WorkspaceOnlineStatusEnum
      const status = buttonFunction === ButtonIndex.SetOnline ? Starting : Stopping

      ctx.emit("set:status", status)
      lastButtonClickedIndex.value = buttonFunction
    }

    return {
      ...ButtonIndex,
      isActiveIndex,
      handleOpenClick,
      archive,
      setOnlineStatus
    }
  },
  components: {
    OpenButton,
    EnoughTokens
  }
})
</script>
