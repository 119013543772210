<template>
  <div class="controllersBar">
    <v-btn @click="$emit('back')" class="dense">
      <v-icon>navigate_expand_collapse_left</v-icon>
    </v-btn>

    <v-btn @click="resetSettings">
      <span>{{ $t("WorkspaceWizard.buttons.reset") }}</span>
    </v-btn>

    <v-btn @click="confirmCreate" color="primary">
      <span>{{ $t("WorkspaceWizard.buttons.create") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"

export default defineComponent({
  name: "WizardTopbarController",
  setup(_, ctx) {
    const _confirmReset = async () => {
      return await raiseConfirmation({
        title: translate("WorkspaceWizard.warning.confirmation.resetTitle"),
        text: translate("WorkspaceWizard.warning.confirmation.reset")
      })
    }

    const _confirmCreate = async () => {
      return await raiseConfirmation({
        title: translate("WorkspaceWizard.warning.confirmation.createTitle"),
        text: translate("WorkspaceWizard.warning.confirmation.create")
      })
    }

    const resetSettings = async () => {
      if (await _confirmReset()) {
        ctx.emit("reset")
      }
    }

    const confirmCreate = async () => {
      if (await _confirmCreate()) {
        ctx.emit("create")
      }
    }

    return {
      resetSettings,
      confirmCreate
    }
  }
})
</script>
