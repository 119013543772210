<template>
  <div id="workspaceEditV2">
    <navigate-back :key="workspace.organizationId" :organization-id="workspace.organizationId" />

    <div v-if="!loader.on && !is404 && !workspaceErrorDetail" class="workspaceContent">
      <pre-header
        @save="saveWorkspaceChanges"
        @change:app-status="changeAppStatus"
        @abort="abortChanges"
        @tag:update="updateTag"
        @tag:create="updateTag"
        @tag:delete="deleteTag"
        @tag:restore="restoreTag"
        :workspace="workspace"
        :key="workspaceStatus"
        :loading="workspaceLoading.on"
      />

      <header-controller
        v-model="addAppOpened"
        @change:shared="changePrivacy"
        @save:instructions="saveInstruction"
        @add:ip="addIp"
        @remove:ip="removeIp"
        @add:user="addUser"
        @remove:user="removeUser"
        @add:apps="addApps"
        @refresh="refreshSelf"
        @refresh:vpc="refreshVpc"
        @delete="deleteSelf"
        @destroy="destroySelf"
        @deploy="deploy"
        @archive="archive"
        @restore="restoreWorkspace"
        @set:file-transfer="setThenDownloadFileTransfer"
        @download:dot-now-file="downloadDotNowFile"
        @update:vpc="updateVpcData"
        :workspace="workspace"
        :loading="workspaceLoading.on"
        :locked="appIsLocked"
        :key="`${workspaceStatus}-${workspace.onlineStatus}`"
      />

      <v-progress-linear v-if="workspaceLoading.on" class="my-0" color="primary" indeterminate />

      <div class="backgroundWrapper">
        <workspace-alerts
          @unlock="unlockWorkspace"
          @unlock:workspace="unlockOnly"
          @redeploy="deploy"
          @discard="discardChanges"
          @retry:destroy="unlockDestroy"
          @renew:certificate="renewCertificate"
          @destroy="destroySelf"
          :workspace="workspace"
          :loading="workspaceLoading.on"
        />

        <app-container
          @set:online="setOnline"
          @copy:password="copyAppPassword"
          @open:app="openApp"
          @open:instance="openInstance"
          @download:shortcut="downloadShortcut"
          @add:apps="addAppOpened = true"
          @download:dot-now="downloadDotNowFileForApp"
          :loading="workspaceLoading.on"
          :workspace="workspace"
          :locked="appIsLocked"
        />

        <copy-password-popup @clear="clearInstanceDetails" :instance-details="instanceDetails" />
      </div>
    </div>

    <workspace-with-error
      v-else-if="!loader.on && workspaceErrorDetail"
      @remove="deleteSelf"
      :error-feedback="workspaceErrorFeedback"
    />

    <workspace-not-found v-else-if="!loader.on && is404" />

    <div v-else class="wrapLoadingContent">
      <v-progress-linear color="primary" indeterminate />

      <div class="backgroundWrapper loadingLabel">
        <span>{{ $t("WorkspaceEdit.label.loading") }}</span>
        <span class="dot">.</span>
      </div>
    </div>

    <app-details
      v-if="analyzingAppDetails && appDetails"
      v-model="analyzingAppDetails"
      @set:online="setOnline"
      @save="updateApp"
      @remove="removeApp"
      @discard:app-changes="appDiscardChanges"
      @redeploy="deploy"
      @app:restart="appRestart"
      :app="appDetails"
      :loading="workspaceLoading.on"
      :key="appRenderDetailsKey"
      :hard-lock="hardLockAppChanges"
    />

    <dcv-instruction
      v-if="showDcvInstruction.show"
      v-model="showDcvInstruction.show"
      :app-name="showDcvInstruction.appName"
      @on:confirm="onConfirmDcvInstruction"
    />

    <organization-mismatch-popup
      v-if="showOrganizationMismatchPopup"
      v-model="showOrganizationMismatchPopup"
      :workspace="workspace"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onBeforeUnmount, nextTick, provide, reactive } from "@vue/composition-api"
import { WorkspaceTemplate, IWorkspaceAsset, WorkspaceOnlineStatusEnum, AddNewAssetPayload } from "@/types/workspace"
import { raiseError, raiseSuccess } from "@/utils/event-bus"
import { WorkspaceModule, AdminModule, UsersModule, OrganizationModule } from "@/store"
import { RegisteredIp, RequestErrorResponse } from "@/types/core"
import { IUser } from "@/types/user"
import { createTranslationModule, translate } from "@/plugins/i18n"
import { wizardAdaptor } from "./lib/wizard-adaptor"
import { updateFavicon } from "./lib/update-favicon"
import { Archived, WorkspaceStates } from "@/config/workspace-states"
import { FileTransferConfig } from "@/types/fileTransfer"
import { InstanceDetails } from "./lib/types"
import { waitComplete } from "@/utils/wait-complete"
import { copyToClipboard, newTab } from "@/utils/ui"
import { getIfUserConfirmedDcvInstruction } from "./lib/dcv-local-storage-helper"
import { SpaceTag } from "@/types/workspace"
import { getBrowserTitle } from "./lib/get-browser-title"
import { useNotification } from "./composable/useNotification"
import { useSpaceWatcher } from "./composable/useSpaceWatcher"
import Router, { navigate } from "@/router"
import NavigateBack from "@/components/navigateBack/NavigateBack.vue"
import Timer from "@/config/app-loop-timers"
import PreHeader from "./components/preHeader/PreHeaderRoot.vue"
import HeaderController from "./components/header/HeaderRoot.vue"
import Loader from "@/utils/loader"
import AppContainer from "./components/appsContainer/AppContainerRoot.vue"
import AppDetails from "./components/appDetails/AppDetailsRoot.vue"
import WorkspaceAlerts from "./components/alerts/AlertsRoot.vue"
import WorkspaceNotFound from "./components/404/WorkspaceNotFound.vue"
import WorkspaceWithError from "./components/error/WorkspaceWithError.vue"
import CopyPasswordPopup from "./components/shared/CopyPasswordPopup.vue"
import DcvInstruction from "./components/shared/DcvInstruction.vue"
import OrganizationMismatchPopup from "./components/shared/_OrganizationMismatchPopup.vue"
import Utils from "@/utils/utils"
import "./scss/_workspaceEdit.scss"

export default defineComponent({
  name: "WorkspaceEditRoot",
  setup() {
    const loader = Loader({ alertOnError: false })
    const workspaceModule = WorkspaceModule()
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const organizationModule = OrganizationModule()
    const workspaceLoading = Loader()
    const loopRefController = ref<string | false>(Router.currentRoute.params.id)
    const analyzingAppDetails = ref(false)
    const addAppOpened = ref(false)
    const showOrganizationMismatchPopup = ref(false)
    const appRenderDetailsKey = ref(0)
    const workspaceErrorDetail = ref<false | string>(false)
    const ownIp = ref("")
    const instanceDetails = reactive<InstanceDetails>({ password: "", connectionUrl: null })
    const showDcvInstruction = reactive({ show: false, appName: "", appId: "" })

    const { browserNotification } = useNotification()
    const { onStatusChange } = useSpaceWatcher()

    const _workspaceLoading = computed(() => {
      return WorkspaceStates.isLoading(workspaceModule.watching?.status)
    })

    const _appsLoading = computed(() => {
      return WorkspaceStates.anyAppLoading(workspace.value.schema.assets)
    })

    const _appRunningChanges = computed(() => {
      return _workspaceLoading.value || _appsLoading.value
    })

    const _workspaceId = computed(() => {
      return Router.currentRoute.params.id
    })

    const hardLockAppChanges = computed(() => {
      return _workspaceLoading.value
    })

    const workspace = computed(() => {
      return workspaceModule.watching
    })

    const is404 = computed(() => {
      return !workspace.value?.id
    })

    const workspaceErrorFeedback = computed(() => {
      return workspace.value.lastErrorTitle ?? ""
    })

    const appDetails = computed(() => {
      if (workspaceModule.appIdBeingEdited) {
        return workspaceModule.watching.schema.assets.find(asset => asset.id === workspaceModule.appIdBeingEdited)
      }

      return null
    })

    const workspaceStatus = computed(() => {
      if (workspace.value?.status) {
        return workspace.value.status
      }

      return ""
    })

    const appIsLocked = computed(() => {
      const hasError = WorkspaceStates.failed(workspace.value.status)
      const isLoading = WorkspaceStates.isLoading(workspace.value.status)
      const isArchived = workspace.value.status === Archived

      return hasError || isLoading || isArchived
    })

    const _openDcvInstruction = (appId: string) => {
      const app = workspace.value?.schemaDeployed?.assets?.find(_app => _app.id === appId)

      if (app) {
        showDcvInstruction.appName = app.description
        showDcvInstruction.appId = app.id
        showDcvInstruction.show = true
      }
    }

    const _handleError = (reqErrorCode: number, errorMessage: string) => {
      switch (reqErrorCode) {
        case 404:
        case 403:
          break
        case 400:
          raiseError({ text: errorMessage })
          break
        default:
          workspaceErrorDetail.value = errorMessage
      }
    }

    const _updateTabName = () => {
      const route = Router.currentRoute

      if (route.name === "WorkspaceEdit") {
        const translateBrowserTab = createTranslationModule("BrowserTab.")
        const { time, state, title } = getBrowserTitle(workspace.value)
        const status = translateBrowserTab("state." + state)

        if (time) {
          const isCountDown = time.includes("~")

          if (isCountDown) {
            document.title = translateBrowserTab("countDownTabTitle", { time, state: status, title })
          } else {
            document.title = translateBrowserTab("tabTitle", { time, state: status, title })
          }
        } else {
          document.title = translateBrowserTab("tabTitleNoTime", { state: status, title })
        }
      }
    }

    const _registerPassword = (password: string, connectionUrl: string | null) => {
      instanceDetails.password = password
      instanceDetails.connectionUrl = connectionUrl
    }

    const _recursiveUpdateWorkspace = async () => {
      if (loopRefController.value) {
        await _getWorkspace()

        setTimeout(() => {
          _recursiveUpdateWorkspace()
        }, Timer.workspaceEdit)
      }
    }

    const _waitOnWorkspaceComplete = async () => {
      const isAlive = () => {
        return Router.currentRoute.params.id === loopRefController.value
      }

      await waitComplete(
        async () => {
          if (isAlive()) {
            await _getWorkspace()
            const releaseRecursiveUpdate = !_appRunningChanges.value

            return releaseRecursiveUpdate
          } else {
            return true
          }
        },
        {
          isAlive,
          interval: Timer.workspaceEdit
        }
      )
    }

    const clearInstanceDetails = () => {
      _registerPassword("", null)
    }

    const onConfirmDcvInstruction = () => {
      workspaceLoading.run(async () => {
        const { appId } = showDcvInstruction
        await _silentAddIp({ ip: ownIp.value, description: usersModule.selfDetail.email })
        await adminModule.downloadWorkspaceShortcut(workspace.value.id, appId)
      })
    }

    const _getOwnIp = async () => {
      ownIp.value = await adminModule.getOwnIp()
    }

    const _getVpc = async () => {
      const isOwner = workspace.value.userId === usersModule.selfDetail.id
      const hasAdminPermission = usersModule.hasAdminPermissions
      const spaceIsVpcEligible = workspace.value.customPeeringAllowed

      if (spaceIsVpcEligible && (isOwner || hasAdminPermission)) {
        await workspaceModule.getVpc(_workspaceId.value)
        await workspaceModule.getMyWorkspaces()
      }
    }

    const _minLevelRequests = async () => {
      if (usersModule.hasEditorPermissions) {
        await workspaceModule.getAvailableApps(_workspaceId.value)
      }
    }

    const _validateHasOwnIp = async () => {
      await addIp({ ip: ownIp.value, description: usersModule.selfDetail.email })
    }

    const _appGetConnectionUrlAndCopyPassword = async (appId: string) => {
      const { connectionUrl, password } = await adminModule.getWorkspaceAppInstanceLink(workspace.value.id, appId)

      if (!(await copyToClipboard(password))) {
        _registerPassword(password, connectionUrl)
      } else {
        raiseSuccess({ text: translate("WorkspaceEdit.alerts.feedbacks.passwordCopied") })

        setTimeout(() => {
          newTab(connectionUrl)
        }, 2000)
      }

      return connectionUrl
    }

    const _getWorkspace = async () => {
      await workspaceModule.getWorkspace(_workspaceId.value)
      _updateTabName()
    }

    const _validateOrganizationMismatch = () => {
      const spaceOrganizationId = workspace.value.organizationId
      const selectedOrganizationId = usersModule.selectedOrganizationId
      const hasPermission = usersModule.hasSupportPermissions

      if (spaceOrganizationId !== selectedOrganizationId && hasPermission) {
        showOrganizationMismatchPopup.value = true
      }
    }

    const providerOwnIpValidation = async () => {
      await workspaceLoading.run(async () => {
        await _validateHasOwnIp()
      })
    }

    const setThenDownloadFileTransfer = (fileConfig: FileTransferConfig) => {
      workspaceLoading.run(async () => {
        await workspaceModule.setThenDownloadFileTransferConfig(fileConfig, _workspaceId.value)
      })
    }

    const downloadFileTransfer = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.downloadFileTransfer(_workspaceId.value)
      })
    }

    const _silentAddIp = async (ipProp: RegisteredIp) => {
      await _getWorkspace()
      const isIpIncluded = workspace.value.authorizedIps.some(ipItem => ipItem.ip === ipProp.ip)

      if (!isIpIncluded) {
        await workspaceModule.authorizeIP(ipProp, workspace.value.id)
      }
    }

    const _clearVpcMemory = () => {
      workspaceModule.vpc = []
    }

    const unlockDestroy = async () => {
      await unlockWorkspace()
      destroySelf()
    }

    const unlockOnly = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.unlock(_workspaceId.value)
        await workspaceModule.getWorkspace(_workspaceId.value)

        _updateTabName()
      })
    }

    const saveWorkspaceChanges = (_workspace: WorkspaceTemplate) => {
      workspaceLoading.run(async () => {
        await workspaceModule.saveChanges(_workspace)
      })
    }

    const updateApp = (asset: IWorkspaceAsset) => {
      workspaceLoading.run(async () => {
        await workspaceModule.updateApp(asset.id, {
          ...asset,
          userConfigurations: wizardAdaptor(asset)
        })
      })
    }

    const appRestart = (appId: string) => {
      analyzingAppDetails.value = false
      setOnline(WorkspaceOnlineStatusEnum.Starting, appId)
    }

    const removeApp = (assetId: string) => {
      workspaceLoading.run(async () => {
        await workspaceModule.removeApp(assetId)
        analyzingAppDetails.value = false

        await Promise.all([
          workspaceModule.getWorkspace(_workspaceId.value),
          workspaceModule.getAvailableApps(_workspaceId.value)
        ])

        _updateTabName()
      })
    }

    const downloadShortcut = async (appId: string) => {
      workspaceLoading.run(async () => {
        const userConfirmedDcvInstruction = getIfUserConfirmedDcvInstruction()

        if (userConfirmedDcvInstruction) {
          await _silentAddIp({ ip: ownIp.value, description: usersModule.selfDetail.email })
          await adminModule.downloadWorkspaceShortcut(workspace.value.id, appId)
        } else {
          _openDcvInstruction(appId)
        }
      })
    }

    const downloadDotNowFileForApp = async (appId: string) => {
      workspaceLoading.run(async () => {
        const workspaceName = workspace.value.title
        const appName = workspace.value.schemaDeployed?.assets.find(app => app.id === appId)?.description

        if (appName) {
          const fileName = `${workspaceName} - ${appName}`
          await adminModule.downloadDotNowFile(workspace.value.id, fileName, appId)
        } else {
          raiseError({ text: translate("WorkspaceEdit.alert.invalidApp") })
        }
      })
    }

    const downloadDotNowFile = async () => {
      workspaceLoading.run(async () => {
        const fileName = workspace.value.title
        await adminModule.downloadDotNowFile(workspace.value.id, fileName)
      })
    }

    const openInstance = async (appId: string) => {
      workspaceLoading.run(async () => {
        await _silentAddIp({ ip: ownIp.value, description: usersModule.selfDetail.email })
        await _appGetConnectionUrlAndCopyPassword(appId)
      })
    }

    const saveInstruction = (_workspace: WorkspaceTemplate) => {
      workspaceLoading.run(async () => {
        await workspaceModule.saveInstructions(_workspace)
      })
    }

    const addIp = async (ipProp: RegisteredIp) => {
      await workspaceLoading.run(async () => {
        await _silentAddIp(ipProp)
      })
    }

    const updateVpcData = () => {
      workspaceLoading.run(async () => {
        await _getVpc()
      })
    }

    const openApp = (app: IWorkspaceAsset) => {
      if (usersModule.hasEditorPermissions) {
        workspaceModule.appIdBeingEdited = app.id
        analyzingAppDetails.value = true
      }
    }

    const updateTag = (tag: SpaceTag) => {
      workspaceLoading.run(async () => {
        await workspaceModule.updateTag(tag, workspace.value.id)
        await workspaceModule.getWorkspace(workspace.value.id)
      })
    }

    const deleteTag = (tagName: string) => {
      workspaceLoading.run(async () => {
        await workspaceModule.deleteTag(tagName, workspace.value.id)
        await workspaceModule.getWorkspace(workspace.value.id)
      })
    }

    const restoreTag = (tag: SpaceTag) => {
      workspaceLoading.run(async () => {
        await workspaceModule.updateTag(tag, workspace.value.id)
        await workspaceModule.getWorkspace(workspace.value.id)
      })
    }

    const copyAppPassword = async (appId: string) => {
      await workspaceLoading.run(async () => {
        const password = await workspaceModule.getAppPassword(workspace.value.id, appId)

        if (!(await copyToClipboard(password))) {
          _registerPassword(password, null)
        }
      })
    }

    const renewCertificate = async () => {
      await workspaceLoading.run(async () => {
        const certificateRenew = true

        await workspaceModule.deploy(_workspaceId.value, certificateRenew)
        await workspaceModule.getWorkspace(_workspaceId.value)
      })
    }

    const unlockWorkspace = async () => {
      await workspaceLoading.run(async () => {
        await workspaceModule.unlock(_workspaceId.value)
        await deploy()
      })
    }

    const removeIp = (ipProp: RegisteredIp) => {
      workspaceLoading.run(async () => {
        await workspaceModule.revokeIP(ipProp, _workspaceId.value)
      })
    }

    const addUser = (user: IUser) => {
      const isUser = Utils.isType<IUser>(user, "id")

      if (isUser) {
        workspaceLoading.run(async () => {
          await workspaceModule.authorizeUser(user.id, _workspaceId.value)
        })
      }
    }

    const addApps = (apps: AddNewAssetPayload[]) => {
      workspaceLoading.run(async () => {
        for (const app of apps) {
          await workspaceModule.addApp(app)
        }

        await Promise.all([
          workspaceModule.getWorkspace(_workspaceId.value),
          workspaceModule.getAvailableApps(_workspaceId.value)
        ])

        _updateTabName()
      })
    }

    const setOnline = (state: WorkspaceOnlineStatusEnum, instanceId: string) => {
      workspaceLoading.run(async () => {
        await workspaceModule.setAppOnlineStatus(_workspaceId.value, state, instanceId)
        _waitOnWorkspaceComplete()
      })
    }

    const removeUser = (user: IUser) => {
      workspaceLoading.run(async () => {
        await workspaceModule.revokeUser(user.id, _workspaceId.value)
      })
    }

    const changeAppStatus = (status: WorkspaceOnlineStatusEnum) => {
      workspaceLoading.run(async () => {
        await workspaceModule.changeAppStatus(_workspaceId.value, status)
        _waitOnWorkspaceComplete()
      })
    }

    const abortChanges = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.abortChanges(_workspaceId.value)
      })
    }

    const changePrivacy = (shared: boolean) => {
      workspaceLoading.run(async () => {
        await workspaceModule.changePrivacy(_workspaceId.value, shared)
      })
    }

    const refreshSelf = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.getWorkspace(_workspaceId.value)
        await _getVpc()
        _updateTabName()
      })
    }

    const refreshVpc = () => {
      workspaceLoading.run(async () => {
        await _getVpc()
      })
    }

    const deleteSelf = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.delete(_workspaceId.value)
        leaveWorkspace()
      })
    }

    const discardChanges = () => {
      const deployedVersion = Utils.isType<WorkspaceTemplate>(workspace.value, "schemaDeployed")

      if (deployedVersion && deployedVersion.schemaDeployed?.assets) {
        workspaceLoading.run(async () => {
          await workspaceModule.resetWorkspaceSchemaToDeployed()
          await workspaceModule.getAvailableApps(_workspaceId.value)
        })
      }
    }

    const appDiscardChanges = (appId: string) => {
      const deployedVersion = Utils.isType<WorkspaceTemplate>(workspace.value, "schemaDeployed")

      if (deployedVersion && deployedVersion.schemaDeployed?.assets) {
        workspaceLoading.run(async () => {
          await workspaceModule.appDiscardChanges(appId)
          await workspaceModule.getAvailableApps(_workspaceId.value)

          appRenderDetailsKey.value++
        })
      }
    }

    const restoreWorkspace = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.restore(_workspaceId.value)
        _waitOnWorkspaceComplete()
      })
    }

    const destroySelf = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.destroy(_workspaceId.value)
        _waitOnWorkspaceComplete()
      })
    }

    const deploy = async () => {
      await workspaceLoading.run(async () => {
        const certificateRenew = false

        await workspaceModule.deploy(_workspaceId.value, certificateRenew)
        _waitOnWorkspaceComplete()
      })
    }

    const archive = async () => {
      await workspaceLoading.run(async () => {
        await workspaceModule.archive(_workspaceId.value)
        _waitOnWorkspaceComplete()
      })
    }

    const leaveWorkspace = () => {
      navigate({ name: "WorkspacesList" })
    }

    const _runComputations = () => {
      if (loopRefController.value) {
        _updateTabName()
        updateFavicon(workspace.value)

        setTimeout(() => {
          _runComputations()
        }, 1500)
      }
    }

    loader.run(async () => {
      const organizationId = usersModule.selectedOrganizationId

      if (!organizationId) {
        await usersModule.getMyDetails()
      }

      try {
        await Promise.all([
          workspaceModule.getWorkspace(_workspaceId.value),
          workspaceModule.getAssetModels(),
          workspaceModule.getRegions(),
          workspaceModule.getAutoShutdownSettings(_workspaceId.value),
          workspaceModule.getScheduledOnOff(_workspaceId.value),
          _minLevelRequests(),
          _getOwnIp()
        ])

        _validateOrganizationMismatch()

        await organizationModule.getOrganizationDetail(workspace.value.organizationId)
        await workspaceModule.getTemplates(workspace.value.organizationId)
        await _getVpc()

        _runComputations()
        _recursiveUpdateWorkspace()

        onStatusChange((newSpace, oldSpace) => {
          browserNotification(newSpace, oldSpace)
        })

        nextTick(() => {
          _waitOnWorkspaceComplete()
        })
      } catch (err) {
        const reqError = Utils.isType<RequestErrorResponse>(err, "httpStatusCode")

        if (reqError) {
          _handleError(reqError.httpStatusCode, reqError.details)
        } else {
          raiseError({ text: translate("WorkspaceEdit.alert.unexpectedError") })
          workspaceModule.watching.id = ""
        }
      }
    })

    onBeforeUnmount(() => {
      loopRefController.value = false
      workspaceModule.resetCurrentWorkspace()
      workspaceModule.clearAutoShutdown()
      _clearVpcMemory()
      updateFavicon("default")
    })

    provide("validateOwnIp", providerOwnIpValidation)

    return {
      is404,
      updateVpcData,
      showDcvInstruction,
      downloadDotNowFile,
      restoreWorkspace,
      refreshVpc,
      appRestart,
      appRenderDetailsKey,
      appDiscardChanges,
      discardChanges,
      addIp,
      removeIp,
      unlockOnly,
      abortChanges,
      showOrganizationMismatchPopup,
      addUser,
      restoreTag,
      renewCertificate,
      removeUser,
      saveInstruction,
      deleteTag,
      copyAppPassword,
      addAppOpened,
      loader,
      changePrivacy,
      deploy,
      workspaceStatus,
      setOnline,
      appIsLocked,
      changeAppStatus,
      ownIp,
      addApps,
      removeApp,
      updateApp,
      appDetails,
      downloadShortcut,
      openInstance,
      workspace,
      updateTag,
      workspaceLoading,
      saveWorkspaceChanges,
      refreshSelf,
      unlockDestroy,
      deleteSelf,
      destroySelf,
      openApp,
      downloadFileTransfer,
      setThenDownloadFileTransfer,
      analyzingAppDetails,
      archive,
      unlockWorkspace,
      workspaceErrorFeedback,
      downloadDotNowFileForApp,
      instanceDetails,
      clearInstanceDetails,
      onConfirmDcvInstruction,
      workspaceErrorDetail,
      hardLockAppChanges
    }
  },
  components: {
    OrganizationMismatchPopup,
    DcvInstruction,
    CopyPasswordPopup,
    WorkspaceNotFound,
    HeaderController,
    WorkspaceWithError,
    AppContainer,
    WorkspaceAlerts,
    AppDetails,
    NavigateBack,
    PreHeader
  }
})
</script>
