<template>
  <div id="awsSteps">
    <div class="stepByStep">
      <h3>{{ t("title") }}</h3>

      <p>
        <strong>{{ t("disclaimer") }}</strong>
      </p>

      <p v-html="t('why')" />

      <ul>
        <li>
          <p>{{ t("installation") }}</p>
        </li>

        <li>
          <p>{{ t("deploymentAgentAndSpaceLift") }}</p>
        </li>

        <li>
          <p v-html="t('spaceliftDocumentation', { link: awsLink.spaceliftDocs })" />
        </li>
      </ul>

      <h3>{{ t("settingUpIntegration") }}</h3>

      <ol>
        <li>
          <p v-html="t('spaceliftIAMRole')" />

          <ul>
            <li>
              <p v-html="t('awsIAMAdmin')" />
            </li>

            <li>
              <p v-html="t('createRoleLink', { link1: awsLink.creatingIAMWithoutAdmin })" />
            </li>

            <li>
              <p v-html="t('customTrustPolicy')" />

              <code-snippet @copy="$emit('copy:snippet')" />

              <image-visualizer
                :src="assetExports.codeDeployAgentTrustRelationship"
                alt="Code Deploy Agent trust relationship"
                :use="PreDefinedSize.medium"
              />
            </li>

            <li>
              <p v-html="t('whereNextButton')" />
            </li>

            <li>
              <p>
                <span v-html="t('nameTheRole')" />

                <v-btn @click="copy('viz-now-deploy-infra')" color="primary" class="ml-1" icon>
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </p>

              <image-visualizer
                :src="assetExports.agentCreateRoleNoAdmin"
                alt="Creating role"
                :use="PreDefinedSize.medium"
              />
            </li>

            <li>
              <p v-html="t('whereCreateRoleButton')" />

              <image-visualizer
                :src="assetExports.buttonCreateRole"
                :use="PreDefinedSize.medium"
                alt="Create role button"
                not-clickable
              />
            </li>

            <li>
              <p v-html="t('viewRoleGreenTopBar')" />

              <image-visualizer :src="assetExports.viewRole" alt="View Role Button" :use="PreDefinedSize.medium" />
            </li>
          </ul>
        </li>

        <li>
          <p v-html="t('whereInlinePolicyOption')" />

          <image-visualizer
            :src="assetExports.createInlinePolicy"
            :use="PreDefinedSize.medium"
            alt="Create inline policy"
          />
        </li>

        <li>
          <p>{{ t("configuringPermissions") }}</p>

          <ul>
            <li>
              <p>{{ t("copyCode") }}</p>

              <code-json-permission @copy="$emit('copy:snippet-json-permission')" />
            </li>

            <li>
              <p v-html="t('pasteCodeInstruction')" />
            </li>

            <image-visualizer :src="assetExports.permission" :use="PreDefinedSize.medium" alt="Create inline policy" />

            <li>
              <p>
                <span v-html="t('namePolicy')" />

                <v-btn @click="copy('viz-now-deploy-infra-inline-policy')" color="primary" class="ml-1" icon>
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </p>
            </li>

            <image-visualizer :src="assetExports.policyName" :use="PreDefinedSize.medium" alt="Policy name" />

            <li>
              <p v-html="t('whereCreatePolicyButton')" />
            </li>
          </ul>
        </li>

        <li>
          <p>{{ t("configuringArnVizNow") }}</p>

          <ul>
            <li>
              <p>{{ t("copyArnCode") }}</p>
              <image-visualizer :src="assetExports.arn" :use="PreDefinedSize.medium" alt="Copy ARN code" />
            </li>

            <li>
              <p v-html="t('wherePasteArnCode')" />
            </li>

            <image-visualizer
              :src="assetExports.pasteArnCodeDeployAgent"
              :use="PreDefinedSize.medium"
              alt="Paste ARN code"
            />
          </ul>
        </li>
      </ol>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { deployAgent } from "../../lib/step-by-step-links"
import { translate } from "@/plugins/i18n"
import { Dictionary } from "@/types/core"
import { PreDefinedSize } from "@/components/imageVisualizer/lib/types"
import { copyToClipboard } from "@/utils/ui"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import CodeJsonPermission from "./CodeJSONPermission.vue"
import ImageVisualizer from "@/components/imageVisualizer/ImageVisualizerRoot.vue"
import CodeSnippet from "./CodeSnippet.vue"
import assetExports from "../../lib/asset-export"

export default defineComponent({
  name: "AwsSetup",
  setup() {
    const t = (snippet: string, ...args: Dictionary<string>[]) => {
      const translationPrefix = "Onboarding.deployAgent.newAwsUI"
      return translate(translationPrefix + `.${snippet}`, ...args)
    }

    const copy = (text: string) => {
      copyToClipboard(text)
    }

    return {
      t,
      copy,
      PreDefinedSize,
      awsLink: deployAgent,
      assetExports
    }
  },
  components: {
    CodeJsonPermission,
    ImageVisualizer,
    CodeSnippet,
    Tooltip
  }
})
</script>
