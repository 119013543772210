<template>
  <div class="topControllers">
    <div class="containControllers">
      <v-text-field
        v-model="searchFilter"
        @input="filter"
        @click:clear="filter"
        :label="$t('common.typeToSearch')"
        :disabled="loading"
        class="flex-grow-0 flex-shrink-0"
        hide-details
        prepend-inner-icon="action_search"
        clearable
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "TemplateTopbar",
  props: {
    value: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const searchFilter = Utils.vModel(props, ctx.emit)

    const filter = () => {
      ctx.emit("filter")
    }

    return {
      searchFilter,
      filter
    }
  }
})
</script>
