import VizNowIcon from "@/../public/favicons/android-chrome-192x192.png"

const NOTIFICATION_KEY = "+vizNow-notification-browser-permission"

const instance = {
  notification: {} as Notification
}

export const getNotificationPermission = async () => {
  if (!_browserSupportsNotification()) {
    console.warn("Browser doesn't support notifications")
    return false
  }

  if (!_alreadyHasPermission()) {
    await _getBrowserPermission()
  }

  return _alreadyHasPermission()
}

export const notifyUser = async (title: string, text: string) => {
  const userNotLooking = document.visibilityState === "hidden"

  if (userNotLooking && (await getNotificationPermission())) {
    const notification: Notification = new Notification(title, { body: text, icon: VizNowIcon })

    notification.onclick = () => {
      window.focus()
      notification.close()
    }

    if ("close" in instance.notification) {
      instance.notification.close()
      instance.notification = notification
    }

    document.addEventListener("visibilitychange", _closeOnFocusTab)
  }
}

export const destroyEvents = () => {
  document.removeEventListener("visibilitychange", _closeOnFocusTab)
}

const _closeOnFocusTab = () => {
  const hasValidNotification = "close" in instance.notification

  if (document.visibilityState === "visible" && hasValidNotification) {
    instance.notification.close()
  }
}

const _browserSupportsNotification = () => {
  return "Notification" in window
}

const _alreadyHasPermission = () => {
  return sessionStorage.getItem(NOTIFICATION_KEY) === "true"
}

const _getBrowserPermission = async () => {
  await Notification.requestPermission().then((permission): void => {
    const hasPermission = permission === "granted"
    sessionStorage.setItem(NOTIFICATION_KEY, JSON.stringify(hasPermission))
  })
}
