<template>
  <div class="gridItem">
    <span>{{ t("tokens") }}</span>
    <span :class="tokenClassColor">{{ t("tokensLeft", { tokens }) }}</span>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/composable/useStore"
import { createTranslationModule } from "@/plugins/i18n"
import Utils from "@/utils/utils"
import { defineComponent, computed } from "@vue/composition-api"

export default defineComponent({
  name: "TokenLabel",
  setup() {
    const { organizationModule } = useStore()
    const t = createTranslationModule("TriCaster.boxFooter.moreInfo.")

    const wallet = computed(() => {
      return organizationModule?.walletDetail
    })

    const tokens = computed(() => {
      return Utils.numLocale(wallet.value.amount ?? 0)
    })

    const tokenClassColor = computed(() => {
      const classes = []
      const LOW_AMOUNT_OF_TOKENS = 10

      if (parseInt(tokens.value) <= 0) {
        classes.push("--color-danger")
      } else if (parseInt(tokens.value) <= LOW_AMOUNT_OF_TOKENS) {
        classes.push("--color-warning")
      }

      return classes.join(" ")
    })

    return {
      tokenClassColor,
      tokens,
      t
    }
  }
})
</script>
