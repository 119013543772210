import { Director } from "@/components/formFactory/lib/type"
import { FormGenerator } from "@/components/formFactory"
import { TokenRule } from "@/utils/input-rules"
import { Subscription } from "@/types/organization"

export default (configSettings: ConfigSettings) => {
  const { currentSubscription, subscriptionItems } = configSettings

  return [
    [
      FormGenerator.components.text("purchaseOrder", "", {
        label: translationSnippet("purchaseOrderLabel"),
        placeholder: translationSnippet("purchaseOrderPlaceholder"),
        grow: true
      })
    ],
    [
      FormGenerator.components.select("type", currentSubscription.type || subscriptionItems[0], subscriptionItems, {
        label: translationSnippet("subscription"),
        grow: true
      }),
      FormGenerator.components.text("subscribedTokens", "", {
        label: translationSnippet("monthlyCreditsLabel"),
        placeholder: translationSnippet("monthlyCreditsPlaceholder"),
        grow: true,
        rules: TokenRule
      })
    ],
    [FormGenerator.components.text("description", "", { label: translationSnippet("descriptionLabel"), grow: true })],
    [
      FormGenerator.components.date("startDate", _dateFormat(currentSubscription.startDate), {
        label: translationSnippet("startingDateLabel"),
        grow: true
      }),
      FormGenerator.components.date("expirationDate", _expireDate(currentSubscription.startDate), {
        label: translationSnippet("expirationDateLabel"),
        grow: true
      })
    ]
  ] as Director
}

const oneYear = 1000 * 60 * 60 * 24 * 30.5 * 12
const translationSnippet = (snippet: string) => "$tAdmin.organizations.editSubscriptionPopup.form." + snippet

const _expireDate = (startingDate: string | null) => {
  let baseDate = Date.now()

  if (startingDate) {
    baseDate = new Date(startingDate).getTime()
  }
  return new Date(baseDate + oneYear).toISOString().slice(0, 10)
}

const _dateFormat = (date: string) => {
  return date ? date.slice(0, 10) : date
}

interface ConfigSettings {
  subscriptionItems: string[]
  currentSubscription: Subscription
}
