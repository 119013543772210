<template>
  <div id="headerController">
    <div class="leftBox">
      <h3>{{ $t("LicenseCore.subText") }}</h3>
    </div>

    <div class="rightBox">
      <controllers
        v-model="addAppOpened"
        @add:ip="ip => $emit('add:ip', ip)"
        @remove:ip="ip => $emit('remove:ip', ip)"
        @add:apps="apps => $emit('add:apps', apps)"
        @destroy="$emit('destroy')"
        @redeploy="$emit('redeploy')"
        :workspace="workspace"
        :loading="loading || locked"
        :licenses="licenses"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { LicenseProduct, WorkspaceTemplate } from "@/types/workspace"
import Controllers from "./Controllers.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "HeaderRoot",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    licenses: {
      type: Array as PropType<LicenseProduct[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const addAppOpened = Utils.vModel(props, ctx.emit)

    return {
      addAppOpened
    }
  },
  components: {
    Controllers
  }
})
</script>
