import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes},[_c('div',{staticClass:"inputRow"},[_c('span',{staticClass:"firstLabel"},[_vm._v("Start time")]),_c('time-picker',{attrs:{"disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":_vm.readonly,"append-icon":"mdi-calendar-blank","hide-details":"","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.start.startTime.value = null}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VIcon,_vm._g({},on),[_vm._v("mdi-calendar-blank")])]},proxy:true}],null,true),model:{value:(_vm.start.startTimeDate.value),callback:function ($$v) {_vm.$set(_vm.start.startTimeDate, "value", $$v)},expression:"start.startTimeDate.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start.startTime.value),callback:function ($$v) {_vm.$set(_vm.start.startTime, "value", $$v)},expression:"start.startTime.value"}}),(_vm.start.startTimeUTC.value)?_c('span',[_vm._v(_vm._s(_vm.start.startTimeUTC.value)+" UTC")]):_vm._e()],1),_c('div',{staticClass:"inputRow"},[_c('span',{staticClass:"firstLabel"},[_vm._v("End time")]),_c('time-picker',{attrs:{"min-date":_vm.start.startTime.value,"disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":_vm.readonly,"append-icon":"mdi-calendar-blank","hide-details":"","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.end.endTime.value = null}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VIcon,_vm._g({},on),[_vm._v("mdi-calendar-blank")])]},proxy:true}],null,true),model:{value:(_vm.end.endTimeDate.value),callback:function ($$v) {_vm.$set(_vm.end.endTimeDate, "value", $$v)},expression:"end.endTimeDate.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.end.endTime.value),callback:function ($$v) {_vm.$set(_vm.end.endTime, "value", $$v)},expression:"end.endTime.value"}}),(_vm.end.endTimeUTC.value)?_c('span',[_vm._v(_vm._s(_vm.end.endTimeUTC.value)+" UTC")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }