<template>
  <div class="tagEditorContainer">
    <p class="howTo">
      <small>
        <v-icon>mdi-help-circle-outline</v-icon>
        {{ t("howToTags") }}
      </small>
    </p>

    <v-textarea v-model="options" :disabled="loading" hide-details />

    <div class="boxFooter">
      <div class="checks">
        <v-checkbox
          v-model="tempTagDetails.requiredOnNewSpaces"
          :disabled="loading"
          :label="t('checkbox.mandatory')"
          hide-details
        />
        <v-checkbox
          v-model="tempTagDetails.allowNewValues"
          :disabled="loading"
          :label="t('checkbox.canCreate')"
          hide-details
        />
      </div>

      <div class="controller">
        <v-btn @click="$emit('remove')" :disabled="loading">{{ t("editorButton.remove") }}</v-btn>

        <v-btn @click="$emit('save', tempTagDetails)" :disabled="loading" color="primary">
          {{ t("editorButton.save") }}
        </v-btn>
      </div>
    </div>

    <span class="--scroll-anchor" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { TagOptions } from "@/types/organization"
import { createTranslationModule } from "@/plugins/i18n"

export default defineComponent({
  name: "TagEditor",
  props: {
    details: {
      type: Object as PropType<TagOptions>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const t = createTranslationModule("OrganizationPageV2.spaceTags")
    const tempTagDetails = ref({ ...props.details })

    const options = computed({
      get() {
        return tempTagDetails.value.options.join(", ")
      },
      set(value: string) {
        const rawOptions = value.trim().split(",")

        tempTagDetails.value.options = rawOptions
          .map(rawOption => rawOption.trim())
          .filter(rawOption => rawOption.trim())
      }
    })

    return {
      t,
      options,
      tempTagDetails
    }
  }
})
</script>
