<template>
  <div id="progressBar">
    <div class="firstHalf">
      <span>
        <span class="text-uppercase">{{ prefix }}</span>
      </span>

      <span>
        <span>{{ progressText }}</span>
        <span class="dot">.</span>
      </span>
    </div>

    <v-progress-linear :value="percentageComplete" :height="10" color="accent" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, computed, onBeforeUnmount } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { translate } from "@/plugins/i18n"
import { ProgressStepOption } from "../lib/progress-step-director"
export { ProgressKey, ProgressCacheKey } from "../lib/estimated-time"

export default defineComponent({
  name: "ProgressBar",
  props: {
    onlineStatus: {
      type: String as PropType<WorkspaceOnlineStatusEnum>,
      required: true
    },
    lastModified: {
      type: Number,
      required: true
    },
    appId: {
      type: String,
      required: true
    },
    progressDirector: {
      type: Object as PropType<ProgressStepOption>,
      required: true
    }
  },
  setup(props) {
    const _now = ref(Date.now())
    const _averageTime = props.progressDirector.averageTime
    const _mounted = ref(true)

    const _recursiveUpdateTimer = () => {
      if (_mounted.value) {
        _now.value = Date.now()
        setTimeout(_recursiveUpdateTimer, 100)
      }
    }

    const prefix = computed(() => {
      return props.progressDirector.prefix
    })

    const percentageComplete = computed(() => {
      const expectedFinishTime = props.lastModified + _averageTime
      const now = _now.value

      if (now < expectedFinishTime) {
        const timeRemaining = now - expectedFinishTime
        const percentageTimeRemaining = Math.abs(Math.floor((timeRemaining * 100) / _averageTime))

        return Math.max(100 - percentageTimeRemaining)
      }

      return 100
    })

    const progressText = computed(() => {
      const director = props.progressDirector

      const nearestPercentageValue = Object.keys(director).find(_percentKey => {
        return percentageComplete.value <= Number(_percentKey)
      })

      return nearestPercentageValue
        ? director[Number(nearestPercentageValue)]
        : translate("TriCaster.feedbacks.finishing")
    })

    onBeforeUnmount(() => {
      _mounted.value = false
    })

    _recursiveUpdateTimer()

    return {
      prefix,
      percentageComplete,
      progressText
    }
  }
})
</script>
