<template>
  <div v-if="!metaConfigHideNavigation" id="appTopBar" class="animateFadeUp">
    <div class="leftCorner">
      <app-logo />
      <organization-picker v-if="hasSupportPermission" />
    </div>

    <app-menu v-if="organizationDetailFinishedLoading" :key="organizationType" />
    <div v-else />

    <div class="controllers">
      <v-btn
        v-if="hasSupportPermission"
        @click="sendToAdminPage"
        :active="adminPageActive"
        rbt-target="admin"
        class="adminConfigButton"
        large
      >
        <v-icon left>status_lock_on</v-icon>
        {{ $t("AdminMenu.admin") }}
      </v-btn>

      <locale-handler />

      <div class="icons">
        <documentation-links v-if="hasDocs" :loading="loader.on" :docs="docs" />
        <user-menu v-if="user.email" @logout="$emit('logout')" :user="user" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { AdminModule, ConfigurationModule, UsersModule } from "@/store"
import Router, { navigate } from "@/router"
import LocaleHandler from "./components/LocaleHandler.vue"
import AppMenu from "./components/AppMenu.vue"
import UserMenu from "./components/UserMenu.vue"
import AppLogo from "./components/AppLogo.vue"
import OrganizationPicker from "./components/OrganizationPicker.vue"
import DocumentationLinks from "./components/DocumentationLinks.vue"
import Loader from "@/utils/loader"
import "./scss/_topbar.scss"

export default defineComponent({
  name: "AppTopBarRoot",
  setup() {
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const configurationModule = ConfigurationModule()
    const loader = Loader()

    const hasSupportPermission = computed(() => {
      return usersModule.hasSupportPermissions
    })

    const user = computed(() => {
      return usersModule.selfDetail
    })

    const hasDocs = computed(() => {
      return docs.value.length > 0
    })

    const docs = computed(() => {
      return usersModule.documentationLinks
    })

    const metaConfigHideNavigation = computed(() => {
      return Router.currentRoute.meta?.hideNavigation
    })

    const adminPageActive = computed(() => {
      return Router.currentRoute.meta?.pageRelative === "adminConfig"
    })

    const organizationDetailFinishedLoading = computed(() => {
      return Boolean(adminModule.organizationDetail?.id)
    })

    const organizationType = computed(() => {
      return adminModule.organizationDetail?.organizationType ?? ""
    })

    const sendToAdminPage = () => {
      if (hasSupportPermission.value) {
        navigate({ name: "AdminConfig" })
      }
    }

    const _getOrganizationDetails = async () => {
      if (!adminModule.organizationDetail?.id) {
        await adminModule.getOrganizationDetail(usersModule.selectedOrganizationId)
      }
    }

    loader.run(async () => {
      await usersModule.getDocumentationLinks()
      await _getOrganizationDetails()
      await configurationModule.getForbiddenSpaceTags()
    })

    return {
      hasSupportPermission,
      organizationType,
      organizationDetailFinishedLoading,
      metaConfigHideNavigation,
      user,
      loader,
      hasDocs,
      docs,
      adminPageActive,
      sendToAdminPage
    }
  },
  components: {
    AppMenu,
    AppLogo,
    OrganizationPicker,
    DocumentationLinks,
    LocaleHandler,
    UserMenu
  }
})
</script>
