<template>
  <div class="templateList">
    <ul class="templateContainer" v-if="items.length > 0">
      <li
        v-for="(template, index) in items"
        @click="chooseTemplate(template.id)"
        :key="template.id"
        :rbt-target="`workspaceTemplate-${index}`"
        class="templateItem"
      >
        <div class="leftTemplateBox">
          <h4>{{ template.title }}</h4>

          <p>
            <span v-if="template.description" class="lightLabel">
              {{ template.description }}
            </span>

            <span v-else class="lightLabel"> {{ $t("WorkspaceWizard.labels.noDescription") }} </span>
          </p>
        </div>

        <div class="rightTemplateBox">
          <div class="tagGroup">
            <span v-if="template.plan" class="plan"> {{ template.plan }}</span>

            <span class="tags" v-if="template.tags">
              {{ template.tags.join(", ") }}
            </span>
          </div>
        </div>

        <div class="infoBox">
          <tooltip :text="$t('WorkspaceWizard.tooltip.instructions')">
            <v-btn @click.stop="openInstructions(template.instructions)" icon>
              <v-icon :size="18"> status_question </v-icon>
            </v-btn>
          </tooltip>
        </div>
      </li>
    </ul>

    <div v-else class="emptyList">
      <p>{{ $t("WorkspaceWizard.labels.empty") }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import { WorkspaceTemplate, CreateWorkspacePayload } from "@/types/workspace"
import Utils from "@/utils/utils"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "TemplateTable",
  props: {
    value: {
      type: Object as PropType<CreateWorkspacePayload>,
      required: true
    },
    items: {
      type: Array as PropType<WorkspaceTemplate[]>,
      default: Array
    }
  },
  setup(props, ctx) {
    const newWorkspace = Utils.vModel(props, ctx.emit)
    const workspaceModule = WorkspaceModule()

    const _saveSelectedTemplate = (templateId: string) => {
      const selectedTemplate = props.items.find(template => template.id === templateId)

      if (selectedTemplate) {
        workspaceModule.selectedTemplate = selectedTemplate
      }
    }

    const openInstructions = (instruction: string) => {
      ctx.emit("open:instructions", instruction)
    }

    const chooseTemplate = async (templateId: string) => {
      newWorkspace.value.templateId = templateId

      _saveSelectedTemplate(templateId)

      ctx.emit("next")
    }

    return {
      openInstructions,
      chooseTemplate
    }
  },
  components: {
    Tooltip
  }
})
</script>
