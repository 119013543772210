<template>
  <div class="thumbContent">
    <figure :class="statusClass" v-if="fileIsImage">
      <div class="thumbnail" :style="backgroundImage" />
    </figure>

    <div class="thumbMetadata">
      <span class="metaTitle">
        <span>{{ file.name }}</span>

        <span v-if="uploadError">
          <v-btn @click="removeFailedFile" color="danger" icon>
            <v-icon>action_close</v-icon>
          </v-btn>
        </span>
      </span>

      <div class="progressData" :class="`input-${statusClass}`">
        <label for="progress">
          <span>{{ feedbackText }}</span>
        </label>

        <label for="progress">{{ buffer.progress }}%</label>
      </div>

      <div class="viz_progress" :class="`${statusClass}`">
        <v-progress-linear :value="buffer.progress" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { translate } from "@/plugins/i18n"

export default defineComponent({
  name: "Thumbnail",
  props: {
    file: {
      type: File,
      required: true
    },
    buffer: {
      type: Object,
      required: true
    },
    contextId: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const imageUrl = computed((): string | null => {
      return URL.createObjectURL(props.file)
    })

    const uploadComplete = computed(() => props.buffer.progress >= 100)
    const uploadError = computed(() => props.buffer.error)
    const fileIsImage = computed(() => props.file.type.includes("image"))
    const backgroundImage = computed(() => `background-image: url(${imageUrl.value});`)

    const statusClass = computed((): string => {
      if (uploadError.value) {
        return "error"
      }
      if (uploadComplete.value) {
        return "success"
      }
      return "uploading"
    })

    const animationClass = computed(() => {
      if (uploadError.value || uploadComplete.value) {
        return "completeProgress animateFadeOut"
      }
      return ""
    })

    const feedbackText = computed(() => {
      switch (statusClass.value) {
        case "error":
          return translate("Uploader.progress.fail")
        case "success":
          return translate("Uploader.progress.success")
        case "uploading":
          return translate("Uploader.progress.uploading")
      }
    })

    const removeFailedFile = (): void => {
      if (props.buffer.error) {
        ctx.emit("remove:file", props.file)
      }
    }

    return {
      imageUrl,
      backgroundImage,
      statusClass,
      fileIsImage,
      animationClass,
      feedbackText,
      uploadError,
      removeFailedFile
    }
  }
})
</script>
