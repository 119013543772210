<template>
  <div class="createNewDeployContainer">
    <v-btn @click="createNew" rbt-target="createNewDeployButton" color="primary" large>
      <v-icon left>action_add</v-icon>
      <span>{{ $t("Admin.deployPackage.createNewPopup.buttonCreate") }}</span>
    </v-btn>

    <popup
      v-model="showModal"
      @cancel="cancel"
      @confirm="create"
      :button-confirm-text="$t('common.create')"
      :title="$t('Admin.deployPackage.createNewPopup.title')"
      width="40vw"
    >
      <v-form @submit.prevent ref="Form">
        <div class="newDeployPackageContainer">
          <v-label>
            <span>{{ $t("Admin.deployPackage.createNewPopup.input.packageName") }} *</span>

            <v-text-field
              @keypress.enter="create"
              v-model="newDeployData.name"
              :rules="rules.text"
              rbt-target="inputNewDeployName"
              autofocus
            />
          </v-label>

          <v-label>
            <span>{{ $t("Admin.deployPackage.createNewPopup.input.description") }} *</span>

            <v-text-field
              @keypress.enter="create"
              v-model="newDeployData.description"
              :rules="rules.text"
              rbt-target="inputNewDeployDescription"
            />
          </v-label>
        </div>
      </v-form>
    </popup>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "@vue/composition-api"
import { MinLengthTextField, TerraformVersionValidation } from "@/utils/input-rules"
import { VForm } from "@/types/core"
import Dialog from "@/components/global/Dialog.vue"
import Utils from "@/utils/utils"
import Popup from "@/components/popup/PopupRoot.vue"

export default defineComponent({
  name: "CreateNewDeploy",
  setup(_, ctx) {
    const showModal = ref(false)
    const Form = ref<null | VForm>(null)
    const rules = { text: MinLengthTextField(2), version: TerraformVersionValidation }

    const newDeployData = reactive({
      name: "",
      description: ""
    })

    const createNew = () => {
      showModal.value = true

      newDeployData.name = ""
      newDeployData.description = ""
    }

    const cancel = () => {
      showModal.value = false
    }

    const create = () => {
      const isForm = Utils.isType<VForm>(Form.value, "validate")

      if (isForm && isForm.validate()) {
        ctx.emit("create", newDeployData)
        showModal.value = false
      }
    }

    return {
      cancel,
      create,
      rules,
      createNew,
      showModal,
      newDeployData,
      Form
    }
  },
  components: {
    Dialog,
    Popup
  }
})
</script>
