<template>
  <div class="updateAlertContainer">
    <generic-alert
      @click="confirmRedeploy"
      @cancel="confirmDiscard"
      :action="$t('WorkspaceEdit.alerts.buttons.redeploy')"
      :cancel-text="$t('WorkspaceEdit.alerts.buttons.discardChanges')"
      icon="status_warning"
      type="warning"
    >
      <p>{{ $t("WorkspaceEdit.alerts.feedbacks.pendingUpdate") }}</p>
    </generic-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import GenericAlert from "./GenericAlert.vue"

export default defineComponent({
  name: "UpdateAlert",
  setup(_, ctx) {
    const confirmRedeploy = async () => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.alerts.confirmation.confirmRedeploy") })) {
        ctx.emit("click")
      }
    }

    const confirmDiscard = async () => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.alerts.confirmation.confirmDiscard") })) {
        ctx.emit("discard")
      }
    }

    return {
      confirmDiscard,
      confirmRedeploy
    }
  },
  components: {
    GenericAlert
  }
})
</script>
