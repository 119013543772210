<template>
  <popup
    v-model="showPopup"
    @confirm="changeOrganization"
    :title="t('title')"
    :loading="loader.on"
    :button-confirm-text="t('swapBtn')"
    :button-cancel-text="t('cancelSwap')"
    width="550px"
  >
    <div class="mismatchWarning">
      <p v-html="t('warning', { selectedOrganization, spaceOrganization })" />

      <p>
        <span v-html="t('warningAction', { spaceOrganization })" class="warningAction" />
      </p>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { createTranslationModule } from "@/plugins/i18n"
import { AdminModule, UsersModule } from "@/store"
import { resolveChangeOrganizationId, warnOrganizationUpdate } from "@/utils/event-bus"
import Popup from "@/components/popup/PopupRoot.vue"
import Loader from "@/utils/loader"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "OrganizationMismatchPopup",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props, ctx) {
    const loader = Loader()
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const t = createTranslationModule("WorkspaceEdit.organizationMismatchPopup")
    const showPopup = Utils.vModel(props, ctx.emit)

    const selectedOrganization = computed(() => {
      return _getOrganization(usersModule.selectedOrganizationId)
    })

    const spaceOrganization = computed(() => {
      return _getOrganization(props.workspace.organizationId)
    })

    const changeOrganization = () => {
      loader.run(async () => {
        const organizationId = props.workspace.organizationId

        adminModule.changeOrganization(organizationId)
        await usersModule.switchOrganization(organizationId)

        warnOrganizationUpdate()
        resolveChangeOrganizationId(organizationId)

        showPopup.value = false
      })
    }

    const _getOrganization = (id: string) => {
      const organization = adminModule.simpleOrganizationCollection.find(org => org.id === id)

      if (organization) {
        return organization.name
      } else {
        return "Unknown organization"
      }
    }

    return {
      t,
      loader,
      changeOrganization,
      selectedOrganization,
      spaceOrganization,
      showPopup
    }
  },
  components: {
    Popup
  }
})
</script>
