<template>
  <div v-if="showErrorBar" class="errorBox">
    <div class="wrapDetails">
      <v-icon color="error">status_error</v-icon>

      <tooltip :text="text" :disabled="text === slicedText" bottom>
        <div class="errorDetail">
          <p>{{ slicedText }}</p>
        </div>
      </tooltip>
    </div>

    <div class="barFunctions">
      <copy-btn button-version :text="text" />

      <v-btn @click="hideErrorUntilSessionClose" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Utils from "@/utils/utils"
import { defineComponent, ref, computed, onMounted } from "@vue/composition-api"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import CopyBtn from "@/components/copyBtn"

export default defineComponent({
  name: "ErrorBox",
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const TEXT_MAX_LENGTH = 130
    const SESSION_HIDE_ERROR_FLAG = "+appLauncher-hide-error-flag"
    const hideErrorWasTriggered = ref(false)

    const slicedText = computed(() => {
      return Utils.maxLength(props.text, TEXT_MAX_LENGTH)
    })

    const showErrorBar = computed(() => {
      return !hideErrorWasTriggered.value
    })

    const hideErrorUntilSessionClose = () => {
      sessionStorage.setItem(SESSION_HIDE_ERROR_FLAG, JSON.stringify(true))
      hideErrorWasTriggered.value = true
    }

    onMounted(() => {
      const hideErrorFlag = sessionStorage.getItem(SESSION_HIDE_ERROR_FLAG)

      if (hideErrorFlag) {
        hideErrorWasTriggered.value = JSON.parse(hideErrorFlag)
      }
    })

    return {
      hideErrorUntilSessionClose,
      showErrorBar,
      slicedText
    }
  },
  components: {
    Tooltip,
    CopyBtn
  }
})
</script>
