import { PeeringStatus } from "./type"

const { deleting, initiatingRequest, pendingAcceptance, provisioning } = PeeringStatus
const vpcLoadingStates = [deleting, initiatingRequest, pendingAcceptance, provisioning]

const { failed, rejected, expired } = PeeringStatus
const vpcFailedStates = [failed, rejected, expired]

const VpcStatusCategories = {
  ACTIVE: [PeeringStatus.active],
  LOADING: vpcLoadingStates,
  FAILED: vpcFailedStates
}

export enum VpcStatusCategoryName {
  active = "active",
  loading = "loading",
  failed = "failed"
}

export type VpcStatusName = "active" | "failed" | "loading"

export const isVpcStatus = (status: PeeringStatus | null, targetStatus: VpcStatusName) => {
  if (status) {
    switch (targetStatus) {
      case VpcStatusCategoryName.active:
        return VpcStatusCategories.ACTIVE.includes(status)

      case VpcStatusCategoryName.failed:
        return VpcStatusCategories.FAILED.includes(status)

      case VpcStatusCategoryName.loading:
        return VpcStatusCategories.LOADING.includes(status)
    }
  }

  return false
}
