<template>
  <v-menu v-model="showHelpBox" :close-on-content-click="false" :content-class="useContentClass" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>

    <div class="helpBoxContainer">
      <slot name="content">
        {{ text }}
      </slot>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api"
import "./scss/_helpBox.scss"

export default defineComponent({
  name: "HelpBoxMenu",
  props: {
    text: {
      type: String,
      default: "This is a help box!"
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const showHelpBox = ref(false)

    const useContentClass = computed(() => {
      return `${props.customClass} helpBoxMenu`
    })

    return {
      useContentClass,
      showHelpBox
    }
  }
})
</script>
