import { SelectItem } from "@/types/core"
import Utils from "@/utils/utils"

import {
  WidgetComboBox,
  WidgetText,
  Widget,
  WidgetRadio,
  WizardWidget,
  WizardConfigItem,
  WidgetTextIterable,
  WidgetCheckbox
} from "@/types/wizard"

import {
  IWorkspaceAsset,
  UpdateAssetUserConfigurationPayload,
  PayloadWizardWithValue,
  PayloadWizardWithKeyPairValue
} from "@/types/workspace"

type Payload = UpdateAssetUserConfigurationPayload

const _comboboxAdaptor = (widget: WidgetComboBox, config: WizardConfigItem<Widget>) => {
  const isSelectItem = Utils.isType<SelectItem<string | number>>(widget.value, "value")

  if (isSelectItem) {
    widget.value = isSelectItem.value
  }

  return {
    resourceKey: config.resourceKey,
    value: widget.value,
    type: config.type,
    active: config.settings.active
  } as PayloadWizardWithValue
}

const _checkboxAdaptor = (widget: WidgetCheckbox | null, config: WizardConfigItem<Widget>) => {
  const hasWidget = Boolean(widget?.options?.length)

  return {
    resourceKey: config.resourceKey,
    value: hasWidget ? JSON.stringify(widget?.options) : null,
    type: config.type,
    active: config.settings.active
  } as PayloadWizardWithValue
}

const _radioAdaptor = (widget: WidgetRadio | null, config: WizardConfigItem<Widget>) => {
  return {
    resourceKey: config.resourceKey,
    value: widget?.value,
    type: config.type,
    active: config.settings.active
  } as PayloadWizardWithValue
}

const _defaultAdaptor = (widget: WidgetText, config: WizardConfigItem<Widget>) => {
  return {
    resourceKey: config.resourceKey,
    value: widget.value,
    type: config.type,
    active: config.settings.active
  }
}

const _iterableTextAdaptor = (widget: WidgetTextIterable, config: WizardConfigItem<Widget>) => {
  return {
    resourceKey: config.resourceKey,
    value: JSON.stringify(widget.options),
    type: config.type,
    active: config.settings.active
  } as PayloadWizardWithKeyPairValue
}

export const wizardAdaptor = (asset: IWorkspaceAsset): Payload[] => {
  if (asset.userConfigurations) {
    return asset.userConfigurations.map<Payload>(config => {
      const widget = config.settings.widget

      switch (config.type) {
        case WizardWidget.comboBox:
          return _comboboxAdaptor(widget as WidgetComboBox, config)

        case WizardWidget.checkbox:
          return _checkboxAdaptor(widget as WidgetCheckbox, config)

        case WizardWidget.iterableText:
          return _iterableTextAdaptor(widget as WidgetTextIterable, config)

        case WizardWidget.radio:
          return _radioAdaptor(widget as WidgetRadio, config)

        default:
          return _defaultAdaptor(widget as WidgetText, config)
      }
    })
  }

  return []
}
