<template>
  <div class="titleWithDescription" @keydown.esc="cancelAll">
    <div class="titleContainer">
      <v-form v-if="editingTitle" ref="Form" @submit.prevent>
        <div class="controllerContainer">
          <v-text-field v-model="newTitle" @keypress.enter="saveTitle" :rules="inputRule" hide-details autofocus />

          <v-btn @click="saveTitle" icon>
            <v-icon>action_save</v-icon>
          </v-btn>

          <v-btn @click="editingTitle = false" icon>
            <v-icon>action_undo</v-icon>
          </v-btn>
        </div>
      </v-form>

      <div class="workspaceTitleHandler" v-else>
        <h3 class="changeable">{{ workspace.title }}</h3>

        <v-btn @click="editTitle" class="animateFadeUp" icon>
          <v-icon>action_edit</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="descriptionContainer">
      <div v-if="editingDescription" class="controllerContainer">
        <v-text-field v-model="newDescription" @keypress.enter="saveDescription" hide-details autofocus />

        <v-btn @click="saveDescription" icon>
          <v-icon>action_save</v-icon>
        </v-btn>

        <v-btn @click="editingDescription = false" icon>
          <v-icon>action_undo</v-icon>
        </v-btn>
      </div>

      <p class="changeable" :empty="Boolean(!workspace.description)" v-else>
        {{ workspace.description }}

        <v-btn @click="editDescription" class="animateFadeUp" v-tippy="$t('common.description')" icon>
          <v-icon>action_edit</v-icon>
        </v-btn>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType } from "@vue/composition-api"
import { MinLengthTextField } from "@/utils/input-rules"
import { hideDetails } from "@/utils/input-utils"
import { WorkspaceTemplate } from "@/types/workspace"
import { VForm } from "@/types/core"
import Utils from "@/utils/utils"
import "./scss/_workspaceHeader.scss"

export default defineComponent({
  name: "WorkspaceHeader",
  props: {
    value: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props, ctx) {
    const workspace = Utils.vModel(props, ctx.emit)
    const inputRule = MinLengthTextField(3)
    const newTitle = ref(workspace.value.title)
    const newDescription = ref(workspace.value.description)
    const editingTitle = ref(false)
    const editingDescription = ref(false)
    const Form = ref<VForm | null>(null)

    const editTitle = () => {
      newTitle.value = workspace.value.title
      editingTitle.value = true
    }

    const editDescription = () => {
      newDescription.value = workspace.value.description
      editingDescription.value = true
    }

    const shouldHideDetail = (value: string) => {
      return computed(() => {
        return hideDetails(inputRule, value)
      }).value
    }

    const cancelAll = () => {
      editingTitle.value = false
      editingDescription.value = false
    }

    const saveTitle = () => {
      const form = Utils.isType<VForm>(Form.value, "validate")

      if (form && form.validate()) {
        workspace.value.title = newTitle.value
        editingTitle.value = false
        ctx.emit("change")
      }
    }

    const saveDescription = () => {
      workspace.value.description = newDescription.value
      editingDescription.value = false
      ctx.emit("change")
    }

    return {
      workspace,
      inputRule,
      cancelAll,
      shouldHideDetail,
      saveTitle,
      saveDescription,
      editingTitle,
      editingDescription,
      newTitle,
      editTitle,
      editDescription,
      newDescription,
      Form
    }
  }
})
</script>
