<template>
  <div class="wrapWizardSettings">
    <navigate-back padding="16px 0" />

    <template-options v-model="newWorkspaceConfig" v-if="creationStep === 0" @next="creationStep++" />

    <wizard v-else v-model="newWorkspaceConfig" @back="creationStep--" />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import { CreateWorkspacePayload } from "@/types/workspace"
import Wizard from "./components/wizard/WizardRoot.vue"
import TemplateOptions from "./components/templateOptions/TemplateRoot.vue"
import NavigateBack from "@/components/navigateBack/NavigateBack.vue"
import "./scss/_workspaceConfig.scss"

export default defineComponent({
  name: "SettingsRoot",
  setup() {
    const creationStep = ref(0)
    const usersModule = UsersModule()

    const newWorkspaceConfig = reactive<CreateWorkspacePayload>({
      templateId: "",
      authorizedIps: [],
      title: "",
      description: "",
      organizationId: usersModule.selectedOrganizationId,
      tags: []
    })

    return {
      creationStep,
      newWorkspaceConfig
    }
  },
  components: {
    TemplateOptions,
    NavigateBack,
    Wizard
  }
})
</script>
