import { PromiseCallback, LoaderConfig, Feedback, FeedbackFullResponse } from "./type"
import { raiseError, raiseSuccess } from "@/utils/event-bus"
import Utils from "@/utils/utils"

const DEFAULT: LoaderConfig = {
  alertOnError: true,
  alertOnSuccess: false,
  throwOnError: false,
  debugging: false
}

export default class Loader {
  private config!: LoaderConfig
  private loading!: boolean

  constructor(config: LoaderConfig = {}) {
    this.config = { ...DEFAULT, ...config }
    this.loading = false
  }

  private debug(error: unknown) {
    if (this.config.debugging) {
      console.log({ error })
      debugger
    }
  }

  private bisectError(feedback: Feedback, error: unknown) {
    if (feedback) {
      const fullResponse = Utils.isType<FeedbackFullResponse>(feedback, "error")

      if (fullResponse) {
        return fullResponse.error || (error as string)
      }

      return feedback as string
    } else {
      return error as string
    }
  }

  private handleError(feedback: Feedback, error: unknown) {
    const { alertOnError, throwOnError } = this.config
    const errorFeedback = this.bisectError(feedback, error)

    this.debug(error)

    if (alertOnError) {
      raiseError({ text: errorFeedback })
    }

    if (throwOnError) {
      throw error
    }
  }

  private handleSuccess(feedback: Feedback) {
    const fullResponse = Utils.isType<FeedbackFullResponse>(feedback, "success")

    if (fullResponse && this.config.alertOnSuccess) {
      raiseSuccess({ text: fullResponse.success })
    }
  }

  public get on() {
    return this.loading
  }

  /**
   * @example
   * // Primary use case
   * loader.run(async () => {
   *  return await Promise.resolve("ok")
   * }): "ok"
   *
   * // Using customized error message
   * loader.run(async () => {
   *  return await Promise.reject("error")
   * }, "Customized error message"): "error"
   *
   * // Using customized success message.
   * // Note alertOnSuccess must be set to true.
   * loader.run(async () => {
   *  return await Promise.resolve("ok")
   * }, { success: "That worked!" }): "ok"
   *
   * // Sending success and error feedback.
   * loader.run(async () => {
   *  return await Promise.resolve("ok")
   * }, { success: "That worked!", error: "Oops!" }): "ok"
   *
   * // Edge case for complex error handle.
   * // Note throwOnError must be set to true, otherwise it won't throw.
   * try {
   *  loader.run(async () => {
   *    throw "Edge case error"
   *  })
   * } catch(error) {
   *  vm.$emit("error", error)
   *  console.error(error) // "Edge case error"
   * }
   */
  public async run<T>(caller: PromiseCallback<T>, feedback: Feedback = "") {
    try {
      this.loading = true

      const req = await caller.call(this)
      this.handleSuccess(feedback)

      return req
    } catch (error) {
      this.handleError(feedback, error)
    } finally {
      this.loading = false
    }
  }
}
