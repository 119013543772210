<template>
  <v-data-table :headers="headers" :items="items" :hide-default-footer="hideTableFooter">
    <template v-slot:[`item.action`]="{ item }">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" :rbt-target="toRobotTag(`buttonMenu${item.name}`)" icon dark>
            <v-icon>navigate_more_options_vertical</v-icon>
          </v-btn>
        </template>

        <div class="listContainer">
          <div
            @click="$emit('edit', item)"
            :rbt-target="toRobotTag(`menuItemEdit${item.name}`)"
            class="listItem organization"
          >
            <v-icon left>action_edit</v-icon>
            {{ $t("Admin.deployPackage.table.menu.edit") }}
          </div>

          <div
            @click="$emit('delete', item.id)"
            :rbt-target="toRobotTag(`menuItemDelete${item.name}`)"
            class="listItem organization"
          >
            <v-icon left>action_trash</v-icon>
            {{ $t("Admin.deployPackage.table.menu.delete") }}
          </div>

          <div
            @click="copy(item.id)"
            :rbt-target="toRobotTag(`menuItemCopyId${item.name}`)"
            class="listItem organization"
          >
            <v-icon left>action_copy</v-icon>
            {{ $t("Admin.deployPackage.table.menu.copyId") }}
          </div>
        </div>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { copyToClipboard } from "@/utils/ui"
import { IDeployPackage } from "@/types/deployPackages"
import Headers from "../../lib/deploy-package-table-headers"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "TableDeployPackage",
  props: {
    items: {
      type: Array as PropType<IDeployPackage[]>,
      required: true
    }
  },
  setup(props) {
    const headers = computed(Headers)

    const hideTableFooter = computed(() => {
      return props.items.length < 5
    })

    const copy = (text: string) => {
      copyToClipboard(text)
    }

    const toRobotTag = (name: string) => {
      return Utils.toCamel(name)
    }

    return {
      toRobotTag,
      copy,
      hideTableFooter,
      headers
    }
  }
})
</script>
