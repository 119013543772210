<template>
  <div class="inputRow darkerBackground">
    <div class="inputLabel animateFadeUp animationDelay-1">
      <span>{{ $t("Admin.organizations.createPopup.input.email") }}</span>

      <v-text-field
        v-model="newUser.email"
        :disabled="loading"
        :rules="rules.email"
        name="email"
        rbt-target="newUserEmailInput"
        hide-details
      />
    </div>

    <div class="inputLabel animateFadeUp animationDelay-2">
      <span>{{ $t("Admin.organizations.createPopup.input.firstName") }}</span>

      <v-text-field
        v-model="newUser.firstName"
        :disabled="loading"
        :rules="rules.text"
        name="firstName"
        rbt-target="newUserFirstNameInput"
        hide-details
      />
    </div>

    <div class="inputLabel animateFadeUp animationDelay-3">
      <span>{{ $t("Admin.organizations.createPopup.input.lastName") }}</span>

      <v-text-field
        v-model="newUser.lastName"
        :disabled="loading"
        name="lastName"
        rbt-target="newUserLastNameInput"
        hide-details
      />
    </div>

    <div class="inputLabel animateFadeUp animationDelay-4">
      <span>{{ $t("Admin.organizations.createPopup.input.role") }}</span>

      <v-select
        v-model="newUser.role"
        :disabled="loading"
        :rules="rules.select"
        :items="roleOptions"
        name="userRole"
        hide-details
        readonly
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { CreateUserPayload } from "@/types/user"
import { userRoleOptions } from "../../lib/user-role-options"
import { rules } from "./lib/rules"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "CreateAdminForm",
  props: {
    value: {
      type: Object as PropType<CreateUserPayload>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const roleOptions = computed(userRoleOptions)
    const newUser = Utils.vModel(props, ctx.emit)

    return {
      rules,
      roleOptions,
      newUser
    }
  }
})
</script>
