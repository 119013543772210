import { InputItem } from "../lib/type"
import { computed } from "@vue/composition-api"
import { hideDetails } from "@/utils/input-utils"
import { Rule } from "@/utils/input-rules"
import { translate } from "@/plugins/i18n"
import { getLabel } from "./get-label"
import Utils from "@/utils/utils"

interface CommonProp {
  director: InputItem
  label?: string | boolean
  disabled?: boolean
  loading?: boolean
}

interface Props<T> extends CommonProp {
  value: T
  items?: Array<T>
}

export default <T = unknown>(props: Props<T>, emit: VueEmit) => {
  const modelValue = Utils.vModel(props, emit)

  const isDisabled = computed(() => {
    if (props.director?.getsDisabled !== false) {
      if (props.director?.loads !== true) {
        return props.loading || props.disabled
      }

      return props.disabled
    }

    return false
  })

  const isLoading = computed(() => {
    return props.loading && props.director?.loads
  })

  const rules = computed(() => {
    return props.director?.rules ?? []
  })

  const name = computed(() => {
    return props.director?.name ?? null
  })

  const placeholder = computed(() => {
    const placeholder = props.director?.placeholder

    if (placeholder) {
      const isTranslationSnippet = placeholder.slice(0, 2) === "$t"
      return isTranslationSnippet ? translate(placeholder.slice(2)) : placeholder
    }

    return null
  })

  const label = computed(() => {
    return getLabel(props.director?.label)
  })

  const shouldHideDetails = computed(() => {
    const hideDetailsFromConfig = props.director?.hideDetails || props.director?.hideDetails === undefined
    const hasEnoughRules = props.director?.rules && props.director.rules.length > 0
    const isTextInput = typeof props.value === "string"

    if (hideDetailsFromConfig) {
      return true
    }

    if (hasEnoughRules && isTextInput) {
      const value = props.value as unknown as string
      const textRules = props.director.rules as unknown as Rule<string>[]
      return hideDetails<string>(textRules, value)
    }

    return true
  })

  return {
    placeholder,
    label,
    modelValue,
    shouldHideDetails,
    rules,
    name,
    isLoading,
    isDisabled
  }
}
