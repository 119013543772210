<template>
  <div class="addTokens">
    <div class="inputLabel">
      <span>{{ t("label") }}</span>
      <v-text-field
        v-model="removeTokenPayload.description"
        :disabled="loading"
        :rules="rules.text"
        :placeholder="t('placeholder.label')"
        name="description"
        hide-details
      />
    </div>

    <div class="group">
      <div class="inputLabel">
        <span>{{ t("amount") }}</span>
        <v-text-field
          v-model="tokenAmount"
          :disabled="loading"
          :rules="rules.token"
          :placeholder="t('placeholder.amount')"
          hide-details
        />
      </div>

      <div class="inputLabel">
        <span>{{ t("newBalance") }}</span>
        <v-text-field :value="newBalance" :disabled="true" hide-details />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { RemoveTokenPayload } from "@/types/organization"
import { toInteger } from "./lib/transaction-history-table-config"
import { rules } from "./lib/rules"
import { translate } from "@/plugins/i18n"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "RemoveTokenInputs",
  props: {
    value: {
      type: Object as PropType<RemoveTokenPayload>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    tokens: {
      type: Number,
      required: true
    }
  },
  setup(props, ctx) {
    const removeTokenPayload = Utils.vModel(props, ctx.emit)
    const t = (snippet: string) => translate("Admin.organizations.createPopup.removeTokenFields." + snippet)

    const tokenAmount = computed({
      get() {
        return toInteger(removeTokenPayload.value.amount)
      },
      set(value: string) {
        removeTokenPayload.value.amount = Number(toInteger(value))
      }
    })

    const newBalance = computed(() => {
      const newValue = Number(toInteger(removeTokenPayload.value.amount))
      return Math.floor(props.tokens - newValue)
    })

    return {
      t,
      rules,
      tokenAmount,
      newBalance,
      removeTokenPayload
    }
  }
})
</script>
