<template>
  <div class="organizationCard">
    <h3>{{ t("title") }}</h3>

    <uploader
      @complete="$emit('update')"
      @upload="$emit('update')"
      @update:removed-file="notifyUpdate"
      :context-id="organization.id"
      :multiple="false"
      :file-list="fileList"
      :key="renderKey"
      :formats="formatsAllowed"
      context="organization"
      standalone
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject, PropType } from "@vue/composition-api"
import { IMedia } from "@/types/media"
import { IOrganization } from "@/types/organization"
import { createTranslationModule } from "@/plugins/i18n"
import Uploader from "@/components/uploadv2/UploadRoot.vue"

export default defineComponent({
  name: "LogoUploader",
  props: {
    fileList: {
      type: Array as PropType<IMedia[]>,
      required: true
    }
  },
  setup(_, ctx) {
    const t = createTranslationModule("OrganizationPageV2.logoManagement")
    const organization = inject("organization") as IOrganization
    const renderKey = ref(0)

    const formatsAllowed = ".png, .jpg, .jpeg"

    const notifyUpdate = () => {
      renderKey.value++
      ctx.emit("update")
    }

    return {
      t,
      renderKey,
      organization,
      notifyUpdate,
      formatsAllowed
    }
  },
  components: {
    Uploader
  }
})
</script>
