<template>
  <div v-if="apps" id="appsContainer">
    <app-card
      v-for="app in apps.added"
      v-show="hasEditorPermission"
      @set:online="(state, instanceId) => $emit('set:online', state, instanceId)"
      @copy:password="$emit('copy:password', app.id)"
      @open:app="app => $emit('open:app', app)"
      @open:instance="$emit('open:instance', app.id)"
      @download:shortcut="$emit('download:shortcut', app.id)"
      @download:dot-now="$emit('download:dot-now', app.id)"
      :key="app.id"
      :app="app"
      :loading="loading"
      :locked="locked"
      :deploy="workspace.status"
      :workspace="workspace"
      class="animateFadeUp"
      type="added"
    />

    <app-card
      v-for="app in apps.removed"
      v-show="hasEditorPermission"
      @set:online="(state, instanceId) => $emit('set:online', state, instanceId)"
      @copy:password="$emit('copy:password', app.id)"
      @open:app="app => $emit('open:app', app)"
      @open:instance="$emit('open:instance', app.id)"
      @download:shortcut="$emit('download:shortcut', app.id)"
      @download:dot-now="$emit('download:dot-now', app.id)"
      :key="app.id"
      :app="app"
      :loading="loading"
      :locked="locked"
      :deploy="workspace.status"
      :workspace="workspace"
      class="animateFadeUp"
      type="removed"
    />

    <app-card
      v-for="app in apps.current"
      @set:online="(state, instanceId) => $emit('set:online', state, instanceId)"
      @copy:password="$emit('copy:password', app.id)"
      @open:app="app => $emit('open:app', app)"
      @open:instance="$emit('open:instance', app.id)"
      @download:shortcut="$emit('download:shortcut', app.id)"
      @download:dot-now="$emit('download:dot-now', app.id)"
      :key="app.id"
      :app="app"
      :loading="loading"
      :locked="locked"
      :deploy="workspace.status"
      :workspace="workspace"
      class="animateFadeUp"
      type="current"
    />

    <add-app-sign
      v-if="!loading"
      @click="$emit('add:apps')"
      :key="apps.length"
      :locked="locked"
      class="animateFadeUp"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { appChanges } from "@/utils/workspaceDiff"
import { UsersModule } from "@/store"
import AppCard from "./AppCard.vue"
import AddAppSign from "./AddAppSign.vue"

export default defineComponent({
  name: "AppContainer",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const usersModule = UsersModule()

    const apps = computed(() => {
      return appChanges(props.workspace)
    })

    const hasEditorPermission = computed(() => {
      return usersModule.hasEditorPermissions
    })

    return {
      hasEditorPermission,
      apps
    }
  },
  components: {
    AppCard,
    AddAppSign
  }
})
</script>
