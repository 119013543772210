const trustPolicy = `{
	"Version": "2012-10-17",
	"Statement": [
		{
			"Effect": "Allow",
			"Principal": {
				"AWS": "arn:aws:iam::324880187172:root"
			},
			"Action": "sts:AssumeRole",
			"Condition": {
				"StringLike": {
					"sts:ExternalId": "SpaceliftIntegration@*"
				}
			}
		}
	]
}
`

const inlinePolicy = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "VizNowDeployInfra0",
            "Effect": "Allow",
            "Action": [
                "iam:*",
                "s3:*",
                "kms:*",
                "route53:*",
                "sts:*",
                "ec2:*",
                "tag:*",
                "logs:*"
            ],
            "Resource": "*"
        }
    ]
} 
`

const vizNowTabInlinePolicy = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "VizNowManage0",
            "Effect": "Allow",
            "Action": [
                "iam:*",
                "kms:*",
                "route53:*",
                "servicequotas:*",
                "s3:*",
                "ec2:*",
                "tag:*",
                "sts:*",
                "logs:*"
            ],
            "Resource": "*"
        }
    ]
} 
`

export default {
  vizNowTabInlinePolicy,
  trustPolicy,
  inlinePolicy
}
