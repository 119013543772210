<template>
  <div class="alignItems">
    <span>{{ $t("MyWorkspaces.filterLabels.lastModified") }}</span>

    <v-select v-model="lastModified" :items="dateOptions" :menu-props="{ offsetY: true }" hide-details />
  </div>
</template>

<script lang="ts">
import Utils from "@/utils/utils"
import { computed, defineComponent } from "@vue/composition-api"
import { possibleDateOptions } from "@/utils/advancedFilter"
import { translate } from "@/plugins/i18n"

export default defineComponent({
  name: "DateFilter",
  props: {
    value: {
      type: [Number, String],
      required: true
    }
  },
  setup(props, ctx) {
    const lastModified = Utils.vModel(props, ctx.emit)

    const dateOptions = computed(() => {
      return [
        {
          text: translate("MyWorkspaces.filterLabels.allTime"),
          value: "any"
        },
        ...possibleDateOptions()
      ]
    })

    return {
      dateOptions,
      lastModified
    }
  }
})
</script>
