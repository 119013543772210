<template>
  <ul v-if="items.length" class="assetList" :class="items.length ? '' : 'emptyList'">
    <li v-for="item in items" :key="item.id" class="applicationItem">
      <div class="iconBox">
        <figure>
          <img :src="`/${item.icon}`" :alt="item.text" />
        </figure>
      </div>

      <div class="descriptionBox">
        <div class="wrapTitle">
          <h3>{{ item.text }}</h3>

          <div class="menuContainer">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :size="22">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <ul class="listMenu">
                <li @click="edit(item.id)" class="listRow">
                  <v-icon left>mdi-square-edit-outline</v-icon>
                  <span>{{ t("edit") }}</span>
                </li>

                <li @click="copyRawData(item.id)" class="listRow">
                  <v-icon left>mdi-content-copy</v-icon>
                  <span>{{ t("copy") }}</span>
                </li>

                <li @click="duplicate(item.id)" class="listRow">
                  <v-icon left>mdi-content-duplicate</v-icon>
                  <span>{{ t("duplicate") }}</span>
                </li>

                <li v-if="false" @click="remove(item.id)" class="listRow">
                  <v-icon left>mdi-delete-outline</v-icon>
                  <span>{{ t("delete") }}</span>
                </li>
              </ul>
            </v-menu>
          </div>
        </div>

        <p class="description">{{ item.description }}</p>

        <div class="appDetails">
          <div v-if="item.extraInformation" class="outputs">
            <ul>
              <li v-if="item.extraInformation.inputs">{{ item.extraInformation.inputs }} inputs</li>
              <li v-if="item.extraInformation.outputs">{{ item.extraInformation.outputs }} outputs</li>
            </ul>
          </div>

          <div v-if="item.extraInformation && item.extraInformation.estimatedCost" class="estimatedPrice">
            <p>Estimated: {{ item.extraInformation.estimatedCost }} Credits per hour</p>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <div v-else class="emptyList">
    <span>No assets</span>
  </div>
</template>

<script lang="ts">
import { PlatformAsset } from "@/types/asset"
import { defineComponent, PropType } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"

export default defineComponent({
  name: "AssetCollection",
  props: {
    items: {
      type: Array as PropType<PlatformAsset[]>,
      required: true
    }
  },
  setup(_, ctx) {
    const t = createTranslationModule("Admin.assetHandler.menuOptions")

    const edit = (applicationId: string) => {
      ctx.emit("edit", applicationId)
    }

    const copyRawData = (applicationId: string) => {
      ctx.emit("copy:raw-data", applicationId)
    }

    const duplicate = (applicationId: string) => {
      ctx.emit("duplicate", applicationId)
    }

    const remove = (applicationId: string) => {
      ctx.emit("remove", applicationId)
    }

    return {
      t,
      edit,
      copyRawData,
      duplicate,
      remove
    }
  }
})
</script>
