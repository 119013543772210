import { CachedOrganization } from "@/types/core"

export const cacheInOrganization = (organizationId: string) => {
  localStorage.setItem(
    "cached-org",
    JSON.stringify({
      organizationId
    })
  )
}

export const recoverCachedOrganization = (): CachedOrganization | false => {
  const { organizationId } = JSON.parse(localStorage.getItem("cached-org") ?? "{}")

  if (organizationId) {
    return {
      organizationId
    }
  }

  return false
}

export const cleanCachedOrganization = () => {
  localStorage.removeItem("cached-org")
}
