<template>
  <div id="onlineSwitch">
    <status-label
      v-if="isTableView"
      :is-running="isRunningState"
      :is-deployed="isDeployed"
      :online-status="workspace.onlineStatus"
      :status-state="workspace.status"
      :apps-online="appsOnline"
      :apps-deployed="appsCountDeployed"
      :running-since="workspace.runningSince"
    />

    <switch-handler
      v-if="isDeployed"
      @set:status="setOnlineStatus"
      :is-online="isOn"
      :is-changing-online-state="isChangingOnlineState"
      :loading="loader.on"
      :apps-deployed="schemaDeployed"
      :online-status="workspace.onlineStatus"
      :component-type="gridView"
      :workspace="workspace"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import { WorkspaceTemplate, WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { WorkspaceStates, isOnline } from "@/config/workspace-states"
import Loader from "@/utils/loader"
import StatusLabel from "./components/StatusLabel.vue"
import SwitchHandler from "./components/SwitchHandler.vue"

export default defineComponent({
  name: "OnlineSwitch",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    gridView: {
      type: String as PropType<"grid" | "table">,
      required: true
    }
  },
  setup(props) {
    const loader = Loader()
    const workspaceModule = WorkspaceModule()

    const appsOnline = computed(() => {
      const { Online } = WorkspaceOnlineStatusEnum

      return props.workspace.schema?.assets?.filter(app => app.onlineStatus === Online)?.length ?? 0
    })

    const appsCountDeployed = computed(() => {
      return props.workspace?.schemaDeployed?.assets?.length ?? 0
    })

    const schemaDeployed = computed(() => {
      return props.workspace?.schemaDeployed?.assets ?? []
    })

    const isTableView = computed(() => {
      return props.gridView === "table"
    })

    const isDeployed = computed(() => {
      return WorkspaceStates.isDeployed(props.workspace.status)
    })

    const isOn = computed(() => {
      return isOnline(props.workspace.onlineStatus)
    })

    const isRunningState = computed(() => {
      return WorkspaceStates.isLoading(props.workspace.status)
    })

    const isChangingOnlineState = computed(() => {
      const { Aborting, Starting, Stopping } = WorkspaceOnlineStatusEnum
      return [Aborting, Starting, Stopping].includes(props.workspace.onlineStatus)
    })

    const setOnlineStatus = (status: WorkspaceOnlineStatusEnum) => {
      loader.run(async () => {
        const workspaceId = props.workspace.id
        await workspaceModule.changeAppStatus(workspaceId, status)
        await workspaceModule.getMyWorkspaces()
      })
    }

    return {
      loader,
      isTableView,
      schemaDeployed,
      appsOnline,
      isChangingOnlineState,
      appsCountDeployed,
      setOnlineStatus,
      isOn,
      isDeployed,
      isRunningState
    }
  },
  components: {
    SwitchHandler,
    StatusLabel
  }
})
</script>
