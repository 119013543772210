<template>
  <div class="organizationFilterWrap">
    <div class="wrap separator">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            @click="filterSettings.organizationType = FilterOrganizationType.ALL"
            rbt-target="btnFilterOrganizationType"
            text
          >
            <span v-if="filterSettings.organizationType === FilterOrganizationType.ALL">
              {{ t("organizationType.label") }}
            </span>
            <span v-else>
              {{ t(`organizationType.${filterSettings.organizationType}`) }}
            </span>

            <v-icon right v-if="filterSettings.organizationType !== FilterOrganizationType.ALL"> mdi-close </v-icon>
            <v-icon right>mdi-menu-down</v-icon>
          </span>
        </template>

        <v-list class="otherOptionsList">
          <v-list-item
            v-for="orgType of FilterOrganizationType"
            @click="filterSettings.organizationType = orgType"
            :key="orgType"
            :rbt-target="`filterOrganizationType${toPascal(orgType)}`"
          >
            <v-list-item-title>
              <div class="optionItem">
                <span>{{ t(`organizationType.${orgType}`) }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="wrap">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            @click="filterSettings.isReady = IsReadyOption.ALL"
            rbt-target="btnFilterOnboardingStatus"
            text
          >
            <span v-if="filterSettings.isReady === IsReadyOption.ALL">
              {{ t("isReady.label") }}
            </span>
            <span v-else>{{ t(`isReady.${filterSettings.isReady}`) }}</span>
            <v-icon right v-if="filterSettings.isReady !== IsReadyOption.ALL"> mdi-close </v-icon>
            <v-icon right>mdi-menu-down</v-icon>
          </span>
        </template>

        <v-list class="otherOptionsList">
          <v-list-item
            v-for="isReadyKey of IsReadyOption"
            @click="filterSettings.isReady = isReadyKey"
            :key="isReadyKey"
            :rbt-target="`filterOnboardingType${toPascal(isReadyKey)}`"
          >
            <v-list-item-title>
              <div class="optionItem">
                <span>{{ t(`isReady.${isReadyKey}`) }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="wrap">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            @click="filterSettings.sslCertificate = SSLCertificateStatus.ALL"
            rbt-target="btnFilterSslCertificate"
            text
          >
            <span v-if="filterSettings.sslCertificate === SSLCertificateStatus.ALL">
              {{ t("sslCertificate.label") }}
            </span>
            <span v-else>{{ t(`sslCertificate.${filterSettings.sslCertificate}`) }}</span>
            <v-icon right v-if="filterSettings.sslCertificate !== SSLCertificateStatus.ALL"> mdi-close </v-icon>
            <v-icon right>mdi-menu-down</v-icon>
          </span>
        </template>

        <v-list class="otherOptionsList">
          <v-list-item
            v-for="sslStatus of SSLCertificateStatus"
            @click="filterSettings.sslCertificate = sslStatus"
            :key="sslStatus"
            :rbt-target="`filterSslCertificate${toPascal(sslStatus)}`"
          >
            <v-list-item-title>
              <div class="optionItem">
                <span>{{ t(`sslCertificate.${sslStatus}`) }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { filterSetupInstance } from "./lib/org-filter"
import { createTranslationModule } from "@/plugins/i18n"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "OrgFilter",
  setup() {
    const t = createTranslationModule("Admin.organizations.filter")

    const toPascal = (text: string) => {
      return Utils.toPascal(text)
    }

    return {
      t,
      toPascal,
      ...filterSetupInstance
    }
  }
})
</script>
