<template>
  <div class="appFooter">
    <v-btn v-if="singleDownloadOption" @click.stop="downloadAction" :disabled="!app.id || loading || !isDeployed" large>
      <v-icon left>action_file_download_or_import</v-icon>
      <span>{{ $t("WorkspaceEdit.appCard.footer.buttons.download") }}</span>
    </v-btn>

    <v-menu v-else offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="!app.id || loading || !isDeployed"
          class="no-padding downloadOptions"
          large
        >
          <div class="group">
            <v-icon left>action_file_download_or_import</v-icon>
            <span>{{ $t("WorkspaceEdit.appCard.footer.buttons.download") }}</span>
          </div>

          <v-icon :size="25">mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list class="otherOptionsList">
        <v-list-item @click="$emit('download:shortcut')" class="optionItem">
          <tooltip v-if="isDeployed" :text="$t('WorkspaceEdit.appCard.footer.tooltip.niceDcv')" right>
            <v-list-item-title>
              <v-icon left>action_file_download_or_import</v-icon>
              <span>{{ $t("WorkspaceEdit.appCard.footer.buttons.downloadNiceDcv") }}</span>
            </v-list-item-title>
          </tooltip>
        </v-list-item>

        <v-list-item v-if="isSaaS" @click="$emit('download:app-launcher')" class="optionItem">
          <tooltip :text="$t('WorkspaceEdit.appCard.footer.tooltip.appLauncher')" right>
            <v-list-item-title>
              <v-icon left>action_file_download_or_import</v-icon>
              <span>{{ $t("WorkspaceEdit.appCard.footer.buttons.downloadAppLauncher") }}</span>
            </v-list-item-title>
          </tooltip>
        </v-list-item>
      </v-list>
    </v-menu>

    <tooltip :text="$t('LicensePage.appCard.hint.open')" bottom>
      <v-btn @click.stop="$emit('open:instance')" :disabled="!app.id || loading || !isOnline" color="primary" large>
        <v-icon left>content_folder_closed</v-icon>
        <span>{{ $t("WorkspaceEdit.appCard.footer.buttons.open") }}</span>
      </v-btn>
    </tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { AdminModule } from "@/store"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "AppFooter",
  props: {
    app: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    isOnline: {
      type: Boolean,
      required: true
    },
    isDeployed: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const adminModule = AdminModule()

    const isSaaS = computed(() => {
      return adminModule.isSaaS
    })

    const singleDownloadOption = computed(() => {
      return !(isSaaS.value && props.isDeployed)
    })

    const downloadAction = () => {
      if (isSaaS.value) {
        ctx.emit("download:app-launcher")
      } else {
        ctx.emit("download:shortcut")
      }
    }

    return {
      downloadAction,
      singleDownloadOption,
      isSaaS
    }
  },
  components: {
    Tooltip
  }
})
</script>
