import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { CloudZone } from "@/types/core"

const api = new Request("/v1/CloudProviders/")

export default createService("cloudProvider", {
  async getRegions() {
    return await api.get<{ regions: CloudZone[] }>(`Regions`)
  }
})
