<template>
  <popup-container
    v-model="showPopup"
    :loading="loader.on"
    :close-on-click-out="pendingChangeLogs"
    :title="$t('ChangeLogViewer.title')"
    hide-footer
  >
    <div class="viewerContainer">
      <html-viewer :content="changeLogs" overflow-auto />

      <div class="controller">
        <v-btn @click="setViewedState" :disabled="loader.on" color="primary" large>
          <span>{{ $t("ChangeLogViewer.button") }}</span>
        </v-btn>
      </div>
    </div>
  </popup-container>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import { eventBus } from "@/main"
import PopupContainer from "@/components/popup/PopupRoot.vue"
import HtmlViewer from "@/components/htmlEditor/HtmlViewer.vue"
import Loader from "@/utils/loader"
import "./scss/_changeLogViewer.scss"

export default defineComponent({
  name: "ChangeLogViewer",
  setup() {
    const _showPopup = ref(false)
    const usersModule = UsersModule()
    const loader = Loader()

    const showPopup = computed({
      get() {
        return _showPopup.value && canShowChangeLog.value
      },
      set(value: boolean) {
        _showPopup.value = value
      }
    })

    const changeLogs = computed(() => {
      return usersModule.changeLog
    })

    const pendingChangeLogs = computed(() => {
      return !usersModule.pendingChangeLogs
    })

    const canShowChangeLog = computed(() => {
      return !usersModule.pendingAcceptPrivacyPolicy
    })

    const setViewedState = () => {
      loader.run(async () => {
        await usersModule.changeLogViewed()
        await usersModule.getMyDetails("forced")
        showPopup.value = false
      })
    }

    loader.run(async () => {
      await usersModule.getMyDetails()
      await usersModule.getChangeLogs()

      showPopup.value = usersModule.pendingChangeLogs
    })

    eventBus.$on("open:changeLog", () => {
      loader.run(async () => {
        await usersModule.getChangeLogs()
        showPopup.value = true
      })
    })

    return {
      pendingChangeLogs,
      canShowChangeLog,
      setViewedState,
      changeLogs,
      loader,
      showPopup
    }
  },
  components: {
    HtmlViewer,
    PopupContainer
  }
})
</script>
