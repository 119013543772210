<template>
  <v-app id="app" class="neo-styled neo-styled-cloud">
    <alert :session="authenticated" />
    <confirm-dialog />

    <v-main
      v-if="sessionChecked"
      :has-system-message="showBar && showSystemMessage"
      :top-bar-hidden="metaConfigHideNavigation"
    >
      <div class="fixedTopbar">
        <system-message-viewer v-if="showBar && showSystemMessage" @close="forceCloseSystemMessage" />
        <app-top-bar v-if="showBar" @logout="signOut" />
      </div>

      <div class="scrollbarContainer" :session="authenticated" :key="contentKeyRefresher">
        <authenticated-parent v-if="authenticated" />
        <non-authenticated-parent v-else />
      </div>
    </v-main>

    <feedback-dialog v-if="authenticated && showFeedbackSign" />
    <change-log-viewer v-if="authenticated" />
  </v-app>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, onBeforeMount, onMounted, nextTick, ref } from "@vue/composition-api"
import { eventBus } from "@/main"
import { validateLicenseServer } from "@/utils/validate-license-server"
import { cacheInOrganization, recoverCachedOrganization } from "@/utils/organization-helper"
import { UsersModule, AdminModule } from "@/store"
import { session, startSession } from "@/auth/session"
import { PlatformViewType } from "@/types/core"
import { validateCanAccessStrictView } from "@/auth/authGuard"
import { registerWebviewEvent } from "./views/appLauncher/lib/webview-communication"
import { forceRedrawPlatformViewSettings } from "./utils/event-bus"
import Commands from "@/config/command-keys"
import SystemMessageViewer from "@/components/systemMessageViewer/SystemMessageViewer.vue"
import FeedbackDialog from "@/components/feedbackDialog/FeedbackDialogRoot.vue"
import ConfirmDialog from "@/components/confirmDialog/ConfirmDialogRoot.vue"
import Alert from "@/components/alert/AlertRoot.vue"
import AppFooter from "@/components/appFooter/AppFooterRoot.vue"
import AppTopBar from "@/components/appTopBar/TopBarRoot.vue"
import Router, { navigate } from "@/router"
import Loader from "@/utils/loader"
import AuthenticatedParent from "@/views/auth/AuthenticatedParent.vue"
import NonAuthenticatedParent from "@/views/auth/NonAuthenticatedParent.vue"
import ChangeLogViewer from "@/components/changeLogViewer/ChangeLogViewer.vue"
import "@/styles/animations.scss"

export default defineComponent({
  name: "App",
  setup() {
    startSession()
    const { VIZ_NOW, APP_LAUNCHER } = PlatformViewType
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const contentKeyRefresher = ref(0)
    const shouldForceCloseSystemMessage = ref(false)
    const router = reactive(Router)
    const loader = Loader()

    const authenticated = computed(() => {
      return session.auth.isAuthenticated
    })

    const sessionChecked = computed(() => {
      return session.auth.initiated
    })

    const showFeedbackSign = computed(() => {
      const hideFeedbackSign = Router.currentRoute.meta?.hideFeedbackSign
      return !hideFeedbackSign ?? true
    })

    const currentRoute = computed(() => {
      return router.currentRoute
    })

    const metaConfigHideNavigation = computed(() => {
      return currentRoute.value.meta?.hideNavigation
    })

    const showSystemMessage = computed(() => {
      const { message, active } = usersModule.systemMessage
      const { previewSystemMessage } = usersModule
      const showSystemMessage = sessionStorage.getItem("systemMessageShow") !== "false"
      const hasMessage = message?.length >= 2

      return (hasMessage && active && showSystemMessage && !shouldForceCloseSystemMessage.value) || previewSystemMessage
    })

    const showBar = computed(() => {
      const authenticatedRoute = currentRoute.value.meta?.requiresAuth

      if (!authenticatedRoute) {
        return false
      }

      return Boolean(usersModule.selfDetail?.id && authenticated.value)
    })

    const _refresh = () => {
      contentKeyRefresher.value++
    }

    const updateTitle = () => {
      const route = Router.currentRoute

      if (route.meta?.title) {
        document.title = `Viz Now - ${route.meta.title}`
      }
    }

    const sessionTrigger = async () => {
      if (await session.isValidUser()) {
        await usersModule.getMyDetails()
        const userDetail = usersModule.selfDetail

        if (userDetail) {
          await session.updateSelfDetails(userDetail)
          await validateLicenseServer()
          await usersModule.getSystemMessage()
        }
      }
    }

    const signOut = async () => {
      loader.run(async () => {
        usersModule.clearCachedOrganization()
        await session.terminate()
      })
    }

    const changeOrganizationRefresh = async () => {
      await getUserDetails()
      await adminModule.getOrganizationDetail(usersModule.selectedOrganizationId)
      _refresh()
    }

    const forceCloseSystemMessage = () => {
      shouldForceCloseSystemMessage.value = true
    }

    const getUserDetails = async (): Promise<void> => {
      usersModule.updateOrganizationID()
      await session.validate()

      if (authenticated.value) {
        await validateLicenseServer()

        if (!recoverCachedOrganization()) {
          const user = await usersModule.getMyDetails()
          cacheInOrganization(user.organization.id)
          usersModule.updateOrganizationID()
        }
      }
    }

    const _runQueryCommands = () => {
      const platformCommands = router.currentRoute.query?.platformCommands as string | null
      const commands = platformCommands ? platformCommands.split(",") : []

      if (!platformCommands || !commands.length) {
        _assertViewSettingsIsConfigured()
      }

      if (commands.includes("swap-view-settings:app-launcher")) {
        _changePlatformView(APP_LAUNCHER)
      } else if (commands.includes("swap-view-settings:viz-now")) {
        _changePlatformView(VIZ_NOW)
      }
    }

    const _assertViewType = () => {
      const strictView = Router.currentRoute.meta?.strictViewSettings

      if (strictView) {
        const hasNoAccess = !validateCanAccessStrictView(strictView)

        if (hasNoAccess) {
          navigate({ name: "Root" })
        }
      }
    }

    const _changePlatformView = (viewType: PlatformViewType) => {
      localStorage.setItem("view-settings", viewType)

      nextTick(() => {
        forceRedrawPlatformViewSettings()
      })
    }

    const _assertViewSettingsIsConfigured = () => {
      if (localStorage.getItem("view-settings") === null) {
        _changePlatformView(VIZ_NOW)
      }
    }

    _runQueryCommands()
    _assertViewType()

    eventBus.$on("validate:license", validateLicenseServer)
    eventBus.$on("update:organizationId", changeOrganizationRefresh)
    eventBus.$on("session:refreshed", sessionTrigger)

    onBeforeMount(sessionTrigger)
    onBeforeMount(updateTitle)

    onMounted(() => {
      registerWebviewEvent(Commands.SWAP_TO_VIZ_NOW, () => _changePlatformView(VIZ_NOW))
      registerWebviewEvent(Commands.SWAP_TO_APP_LAUNCHER, () => _changePlatformView(APP_LAUNCHER))
    })

    return {
      forceCloseSystemMessage,
      showFeedbackSign,
      metaConfigHideNavigation,
      contentKeyRefresher,
      showSystemMessage,
      sessionChecked,
      authenticated,
      signOut,
      currentRoute,
      showBar,
      session
    }
  },
  components: {
    Alert,
    NonAuthenticatedParent,
    FeedbackDialog,
    ConfirmDialog,
    AuthenticatedParent,
    ChangeLogViewer,
    SystemMessageViewer,
    AppTopBar,
    AppFooter
  }
})
</script>

<style lang="scss">
@import "@vizrt/vizrt.vuetify.neo/neo.scss";
@import "../node_modules/@vizrt/vizrticonfont/dist/vizrt-icon-font.css";
@import "@/styles/styles";
</style>
