<template>
  <nested-item
    :app="checkbox"
    :service="service"
    :has-changes="hasChanges"
    :readonly="service.firstDeployOnly && wasDeployed"
  >
    <v-checkbox
      v-model="service.active"
      :disabled="isMandatory || (service.firstDeployOnly && wasDeployed)"
      :label="label[$i18n.locale]"
      rbt-target="wizardSectionContainer"
      class="my-0"
      hide-details
    />

    <template v-slot:child>
      <div class="widgetContainer" v-if="service.widget">
        <v-radio-group v-model="service.widget.value" hide-details class="my-0 py-0">
          <div v-for="widget in service.widget.options" :key="widget.value" class="item">
            <v-radio :value="widget.value" :label="widget.label[$i18n.locale]" />
          </div>
        </v-radio-group>
      </div>
    </template>
  </nested-item>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import WizardWidgetMixin from "../mixin/WizardWidgetMixin"

export default defineComponent({
  name: "WizardRadio",
  mixins: [WizardWidgetMixin]
})
</script>
