<template>
  <div id="tags">
    <organization-tags :loading="loader.on" />
    <space-tags :loading="loader.on" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { ConfigurationModule } from "@/store"
import OrganizationTags from "./_OrganizationTags.vue"
import SpaceTags from "./_SpaceTags.vue"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "TagsRoot",
  setup() {
    const configurationModule = ConfigurationModule()
    const loader = Loader()

    loader.run(async () => {
      await configurationModule.getForbiddenSpaceTags()
    })

    return {
      loader
    }
  },
  components: {
    SpaceTags,
    OrganizationTags
  }
})
</script>
