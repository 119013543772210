import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},on:{"click:outside":_vm.cancel,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirm.apply(null, arguments)}]},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"confirmDialog"},[_c(VCardTitle,[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.cancel}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v("action_close")])],1)],1),_c(VCardText,[_c('div',{staticClass:"containText",domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.useStrictMode)?_c(VTextField,{attrs:{"placeholder":_vm.strictConfirmation,"hide-details":""},model:{value:(_vm.strictInput),callback:function ($$v) {_vm.strictInput=$$v},expression:"strictInput"}}):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"large":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.buttonCancel))]),_c(VBtn,{staticClass:"primary",attrs:{"large":"","disabled":_vm.disableConfirmButton,"rbt-target":"confirmDialog"},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.buttonConfirm)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }