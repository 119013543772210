<template>
  <div id="domainNamePicker">
    <div class="inputRow">
      <div class="inputLabel">
        <span>
          <span>{{ $t("Onboarding.stepOne.label.preferredDomain") }}</span>

          <help-box :text="$t('Onboarding.stepOne.helpBox.domainName')">
            <v-icon size="16">mdi-help-circle-outline</v-icon>
          </help-box>
        </span>

        <div class="inputWrap">
          <v-text-field
            v-model="domainNamePicked"
            @input="validateDomainName"
            :rules="rule"
            :placeholder="$t('Onboarding.stepOne.inputs.domainNamePlaceholder')"
            :hide-details="hideDetails(domainNamePicked)"
            autofocus
            dense
          />
          <tooltip :text="`${domainNamePicked}.${staticDomain}`" :disabled="!domainNamePicked">
            <span class="domainName"
              >{{ domainNamePicked }}<span v-if="domainNamePicked">.</span>{{ staticDomain }}</span
            >
          </tooltip>

          <span class="domainStatus" :state="domainStateClass">
            <v-icon>{{ domainStateIcon }}</v-icon>
          </span>
        </div>
      </div>
    </div>

    <p v-html="$t('Onboarding.stepOne.label.domainRuleDescription')" class="specifyInputRules" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { translate } from "@/plugins/i18n"
import domainRule, { badDomainName } from "../../lib/domain-name-validation"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"
import HelpBox from "@/components/helpBox/HelpBox.vue"

export default defineComponent({
  name: "DomainName",
  props: {
    value: {
      type: String,
      required: true
    },
    staticDomain: {
      type: String,
      required: true
    },
    domainState: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const domainNamePicked = Utils.vModel(props, ctx.emit)

    const domainStateIcon = computed(() => {
      if (domainNamePicked.value) {
        if (props.domainState) {
          return "status_ok_circle"
        } else {
          return "action_remove_circle"
        }
      }
      return ""
    })

    const domainStateClass = computed(() => {
      if (domainNamePicked.value) {
        return props.domainState ? "pass" : "reject"
      }
      return false
    })

    const rule = [
      (value: string) => {
        return domainRule(value, translate("Onboarding.stepOne.label.invalidDomainName"))
      }
    ]

    const validateDomainName = () => {
      ctx.emit("validate:dns")
    }

    return {
      domainNamePicked,
      validateDomainName,
      domainStateIcon,
      rule,
      domainStateClass,
      hideDetails: badDomainName
    }
  },
  components: {
    Tooltip,
    HelpBox
  }
})
</script>
