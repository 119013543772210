<template>
  <div class="logoContainer" @click="sendRootPage">
    <div class="organizationLogo">
      <v-img width="40" src="@/assets/vizcloud_icon-new-01.png" />
    </div>

    <div class="organizationTitle">
      <span class="containLogo">
        <span class="productName">{{ $t("common.applicationTitle") }}</span>
        <span class="versionTag">{{ releaseName }}</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { navigate } from "@/router"
import env from "@/utils/env"

export default defineComponent({
  name: "AppLogo",
  setup() {
    const sendRootPage = () => {
      navigate({ name: "Root" })
    }

    return {
      sendRootPage,
      releaseName: env.VUE_APP_RELEASE_NAME
    }
  }
})
</script>
