import { translate } from "@/plugins/i18n"
import { HeaderItem } from "@/types/core"

const headers: () => HeaderItem[] = () => {
  return [
    {
      text: "",
      align: "center",
      value: "id",
      sortable: false,
      class: "nodisplay",
      cellClass: "nodisplay"
    },
    {
      text: translate("common.title"),
      align: "start",
      value: "title",
      sortable: true,
      cellClass: "v-align-top cell-fixed-size-30 pl-4"
    },
    { text: "", align: "start", value: "tags", sortable: false, filterable: false, cellClass: "v-align-top" },
    {
      text: translate("common.owner"),
      align: "start",
      value: "username",
      sortable: true,
      cellClass: "v-align-top pt-3 body-1"
    },
    {
      text: translate("common.lastEdit"),
      align: "start",
      value: "date",
      sortable: true,
      cellClass: "v-align-top pt-3 body-1 text-nowrap"
    },
    {
      text: translate("common.status"),
      align: "start",
      value: "status",
      sortable: true,
      cellClass: "v-align-top pt-3 pr-4 body-1"
    }
  ]
}

export default headers
