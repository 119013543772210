<template>
  <div class="appBody">
    <div class="appHeader">
      <img :src="`/${appModel.icon}`" :alt="appModel.text" />

      <div class="rightContainer">
        <h3>
          <span>
            {{ app.description }}
          </span>
        </h3>

        <span class="subTitle">{{ appModel.text }}</span>
      </div>
    </div>

    <div class="appContent">
      <p>
        {{ appModel.description }}

        <a v-if="appModel.manualUrl" @click.stop="openExternalManual">
          {{ $t("WorkspaceEdit.appCard.label.openManual") }}
        </a>
      </p>

      <application-links v-if="isDeployed" :links="app.applicationLinks" />

      <app-credentials
        v-if="isDeployed && isEditorOrAbove && app.deployOutputs.length"
        @copy:password="$emit('copy:password')"
        :credentials="app.deployOutputs"
        :loading="loading"
      />

      <pre-deploy-warning :app="app" :flag-type="flagType" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { IAssetModel, IWorkspaceAsset, WorkspaceTemplate } from "@/types/workspace"
import { raiseConfirmation } from "@/utils/event-bus"
import { WorkspaceStates } from "@/config/workspace-states"
import { translate } from "@/plugins/i18n"
import { UsersModule } from "@/store"
import { appChanges, AppDiffType } from "@/utils/workspaceDiff"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import AppCredentials from "./AppCredentials.vue"
import ApplicationLinks from "./ApplicationLinks.vue"
import PreDeployWarning from "./_PreDeployWarning.vue"

export default defineComponent({
  name: "AppBody",
  props: {
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    appModel: {
      type: Object as PropType<IAssetModel>,
      required: true
    },
    appIsDeployed: {
      type: Boolean,
      default: false
    },
    isDeployed: {
      type: Boolean,
      default: false
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    flagType: {
      type: String as PropType<AppDiffType>,
      required: true
    }
  },
  setup(props) {
    const usersModule = UsersModule()

    const openExternalManual = async () => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.appCard.label.openManualConfirmation") })) {
        window.open(props.appModel.manualUrl, "_manualUrlNew")
      }
    }

    const isEditorOrAbove = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const wasModified = computed(() => {
      const changes = appChanges(props.workspace)
      return changes?.modified.includes(props.app.id)
    })

    const appHasError = computed(() => {
      return Boolean(props.app.onlineError)
    })

    const shouldShowAlert = computed(() => {
      const hasFailed = WorkspaceStates.failed(props.workspace.status)

      if (hasFailed) {
        return true
      } else if (props.isDeployed) {
        return wasModified.value || appHasError.value
      }

      return false
    })

    const icon = computed(() => {
      const warningIcon = "status_warning"
      const dangerIcon = "action_remove_circle"
      const appWasRemoved = props.flagType === "removed"

      if (wasModified.value) {
        return warningIcon
      } else if (appHasError.value || appWasRemoved) {
        return dangerIcon
      } else {
        return false
      }
    })

    const textTooltip = computed(() => {
      if (wasModified.value) {
        return translate("WorkspaceEdit.appCard.label.modified")
      } else {
        return props.app.onlineError ?? ""
      }
    })

    return {
      icon,
      textTooltip,
      wasModified,
      shouldShowAlert,
      isEditorOrAbove,
      openExternalManual
    }
  },
  components: {
    Tooltip,
    ApplicationLinks,
    AppCredentials,
    PreDeployWarning
  }
})
</script>
