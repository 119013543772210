<template>
  <div class="centered">
    <enough-tokens>
      <template v-slot="{ disabled }">
        <v-btn :loading="loading" @click="$emit('click')" :disabled="disabled" color="primary" large>
          {{ $t("TriCaster.buttons.tryAgain") }}
        </v-btn>
      </template>
    </enough-tokens>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { WorkspaceOnlineStatusEnum } from "@/types/workspace"
import EnoughTokens from "../_EnoughTokens.vue"

export default defineComponent({
  name: "WhenDeployed",
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {
      ...WorkspaceOnlineStatusEnum
    }
  },
  components: {
    EnoughTokens
  }
})
</script>
