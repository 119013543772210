<template>
  <div id="dangerousButtons">
    <v-btn @click="$emit('redeploy')" color="primary" large>
      <v-icon left>action_reset</v-icon>
      <span>{{ $t("LicenseCore.controllers.redeployText") }}</span>
    </v-btn>

    <v-btn @click="confirmThenDestroy" color="error" large>
      <v-icon left>status_warning</v-icon>
      <span>{{ $t("LicenseCore.controllers.destroyText") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { translate } from "@/plugins/i18n"
import { raiseConfirmation } from "@/utils/event-bus"
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "DangerousButtons",
  setup(_, ctx) {
    const confirmThenDestroy = async () => {
      const strictLabel = translate("LicenseCore.confirm.destroy.password")
      const text = translate("LicenseCore.confirm.destroy.text")

      if (await raiseConfirmation({ text, strictLabel })) {
        ctx.emit("destroy")
      }
    }

    return {
      confirmThenDestroy
    }
  }
})
</script>
