import { translate } from "@/plugins/i18n"
import { Rule } from "@/utils/input-rules"
import { hideDetails } from "@/utils/input-utils"

const REGEX = /^[a-z]+$/
const MAX_LENGTH = 24

const validateRegex = (value: string) => REGEX.test(value)

const domainNameRule: Rule<string> = (value: string, feedback = "Mandatory text") => {
  if (!value) {
    return translate("InputRules.select.mandatory")
  }

  if (!validateRegex(value) || value.length > MAX_LENGTH) {
    return feedback
  }

  return true
}

export const badDomainName = (value: string) => hideDetails([domainNameRule], value)

export default domainNameRule
