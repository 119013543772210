<template>
  <div class="organizationCard cidrBlock">
    <h3>
      <span>{{ t("title") }}</span>

      <tooltip :text="t('whyDoINeedItTooltip')">
        <small>{{ t("whyDoINeedIt") }}</small>
      </tooltip>
    </h3>

    <p v-html="t('subtitle', { max: availableSpaceCount.max, total: availableSpaceCount.total })" class="label" />

    <ul class="cidrBlockListContainer">
      <li v-for="cidrBlock in cidrBlocks" :key="cidrBlock.cidrBlock">
        <div class="ipDetails">
          <span class="ip">{{ getCidrBlockParts(cidrBlock).ip }}</span>
          <span class="divider">/</span>
          <span class="mask">{{ getCidrBlockParts(cidrBlock).mask }}</span>
        </div>

        <div class="ipControllers">
          <tooltip :text="t('availableSpaces', { availableSpaces: cidrBlock.stillAvailable })">
            <strong :class="getAvailableStatus(cidrBlock.stillAvailable)">
              {{ cidrBlock.stillAvailable }}
            </strong>
          </tooltip>
        </div>
      </li>
    </ul>

    <v-form v-model="hasValidIp" ref="Form" class="cidrBlockForm">
      <v-text-field v-model="newCidrBlock" :placeholder="t('addCidrPlaceholder')" :rules="rules" class="textField" />
      <v-select v-model="cidrMask" :items="cidrMaskOptions" class="selectField" hide-details />

      <v-btn @click="addNewCidrBlock" :loading="loader.on" :disabled="!hasValidIp" color="primary" large>
        {{ t("addCidrBlock") }}
      </v-btn>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, nextTick } from "@vue/composition-api"
import { createTranslationModule } from "@/plugins/i18n"
import { LicenseModule, OrganizationModule } from "@/store"
import { MASK_16, AVAILABLE_SPACES, MASK_22 } from "@/config/cidr-block-masks"
import { CidrBlock } from "@/types/organization"
import { CidrRange } from "@/utils/input-rules"
import { VForm } from "@/types/core"
import Loader from "@/utils/loader"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "CidrBlock",
  setup() {
    const licenseModule = LicenseModule()
    const organizationModule = OrganizationModule()
    const t = createTranslationModule("OrganizationPageV2.cidrBlock")
    const newCidrBlock = ref("")
    const loader = Loader()
    const Form = ref<VForm>()
    const cidrMask = ref(MASK_16)
    const hasValidIp = ref(false)
    const cidrMaskOptions = [MASK_16, MASK_22]

    const organization = computed(() => {
      return organizationModule.organizationDetail
    })

    const cidrBlocks = computed(() => {
      return organization.value?.cidrBlocks ?? []
    })

    const availableSpaceCount = computed(() => {
      return {
        total: cidrBlocks.value.reduce((count, cidr) => (count += cidr.stillAvailable), 0),
        max: cidrBlocks.value.reduce((count, cidr) => {
          const { mask } = getCidrBlockParts(cidr)

          if (mask === MASK_16) {
            return (count += AVAILABLE_SPACES.mask16)
          } else {
            return (count += AVAILABLE_SPACES.mask22)
          }
        }, 0)
      }
    })

    const getAvailableStatus = (cidrBlockAvailability: number) => {
      const availabilityClass = "--available-"
      const dangerZone = 20
      const danger = availabilityClass + "danger"
      const ok = availabilityClass + "ok"

      return cidrBlockAvailability < dangerZone ? danger : ok
    }

    const getCidrBlockParts = (cidrBlock: CidrBlock) => {
      const [ip, mask] = cidrBlock.cidrBlock.split("/")

      return {
        ip,
        mask
      }
    }

    const addNewCidrBlock = () => {
      loader.run(async () => {
        const ip = newCidrBlock.value
        const mask = cidrMask.value

        await licenseModule.insertCidrBlocks(organization.value.id, `${ip}/${mask}`)
        await organizationModule.getOrganizationDetail(organization.value.id)

        newCidrBlock.value = ""
        Form.value?.resetValidation()
        _moveFormScrollDown()
      })
    }

    const _moveFormScrollDown = () => {
      nextTick(() => {
        if (Form.value) {
          const $listContainer = Form.value.$el.parentNode?.querySelector(".cidrBlockListContainer")

          if ($listContainer?.querySelectorAll("li")) {
            const liElementCollection = [...$listContainer?.querySelectorAll("li")]

            liElementCollection.slice(-1)[0].scrollIntoView({
              behavior: "smooth"
            })
          }
        }
      })
    }

    const _ruleAvoidDuplicateIp = (value: string) => {
      if (value) {
        const ipCollection = cidrBlocks.value.map(cidr => {
          const { ip } = getCidrBlockParts(cidr)
          return ip
        })

        const isUnique = ipCollection.every(ip => ip !== newCidrBlock.value)

        if (isUnique) {
          return true
        }
      }

      return t("uniqueIpRuleResponse")
    }

    return {
      addNewCidrBlock,
      availableSpaceCount,
      cidrBlocks,
      cidrMask,
      cidrMaskOptions,
      Form,
      hasValidIp,
      getAvailableStatus,
      getCidrBlockParts,
      loader,
      newCidrBlock,
      t,
      rules: [...CidrRange, _ruleAvoidDuplicateIp]
    }
  },
  components: {
    Tooltip
  }
})
</script>
