<template>
  <v-select
    v-if="canUseTranslation"
    v-model="$i18n.locale"
    v-tippy="{ content: $t('common.changeLanguage') }"
    @change="changeLocale"
    :label="$t('common.language')"
    :items="localeOptions"
    append-icon="navigate_double_arrows"
    rbt-target="languageList"
    hide-details
  />
</template>

<script lang="ts">
import { defineComponent, computed, nextTick } from "@vue/composition-api"
import { locales, changeLocale, setDefaultLocale } from "@/plugins/i18n"
import { UsersModule } from "@/store/users"

export default defineComponent({
  name: "LocaleHandler",
  setup() {
    const usersModule = UsersModule()

    const localeOptions = computed(() => {
      const localeKeys = Object.keys(locales)

      return localeKeys.map(locale => {
        return {
          text: locales[locale],
          value: locale
        }
      })
    })

    const canUseTranslation = computed(() => {
      return usersModule.hasGodPermissions
    })

    nextTick(setDefaultLocale)

    return {
      canUseTranslation,
      localeOptions,
      changeLocale
    }
  }
})
</script>
