import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addTokens"},[_c('div',{staticClass:"group"},[_c('div',{staticClass:"inputLabel"},[_c('span',[_vm._v(_vm._s(_vm.t("purchaseId")))]),_c(VTextField,{attrs:{"disabled":_vm.loading,"rules":_vm.rules.text,"placeholder":_vm.t('placeholder.purchaseId'),"name":"purchaseId","hide-details":""},model:{value:(_vm.addTokenPayload.purchaseId),callback:function ($$v) {_vm.$set(_vm.addTokenPayload, "purchaseId", $$v)},expression:"addTokenPayload.purchaseId"}})],1)]),_c('div',{staticClass:"inputLabel"},[_c('span',[_vm._v(_vm._s(_vm.t("label")))]),_c(VTextField,{attrs:{"disabled":_vm.loading,"rules":_vm.rules.text,"placeholder":_vm.t('placeholder.label'),"name":"description","hide-details":""},model:{value:(_vm.addTokenPayload.description),callback:function ($$v) {_vm.$set(_vm.addTokenPayload, "description", $$v)},expression:"addTokenPayload.description"}})],1),_c('div',{staticClass:"group"},[_c('div',{staticClass:"inputLabel"},[_c('span',[_vm._v(_vm._s(_vm.t("activationDate")))]),_c(VMenu,{attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":_vm.loading,"rules":_vm.rules.text,"name":"startDate","chips":"","hide-details":"","readonly":""},model:{value:(_vm.activationDate),callback:function ($$v) {_vm.activationDate=$$v},expression:"activationDate"}},'v-text-field',attrs,false),on))]}}])},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.addTokenPayload.activationDate),callback:function ($$v) {_vm.$set(_vm.addTokenPayload, "activationDate", $$v)},expression:"addTokenPayload.activationDate"}})],1)],1),_c('div',{staticClass:"inputLabel"},[_c('span',[_vm._v(_vm._s(_vm.t("expirationDate")))]),_c(VMenu,{attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":_vm.loading,"name":"expirationDate","clearable":"","chips":"","hide-details":"","readonly":""},model:{value:(_vm.expirationDateFormat),callback:function ($$v) {_vm.expirationDateFormat=$$v},expression:"expirationDateFormat"}},'v-text-field',attrs,false),on))]}}])},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.addTokenPayload.expirationDate),callback:function ($$v) {_vm.$set(_vm.addTokenPayload, "expirationDate", $$v)},expression:"addTokenPayload.expirationDate"}})],1)],1)]),_c('div',{staticClass:"group"},[_c('div',{staticClass:"inputLabel"},[_c('span',[_vm._v(_vm._s(_vm.t("amount")))]),_c(VTextField,{attrs:{"disabled":_vm.loading,"rules":_vm.rules.token,"placeholder":_vm.t('placeholder.amount'),"hide-details":""},model:{value:(_vm.tokenAmount),callback:function ($$v) {_vm.tokenAmount=$$v},expression:"tokenAmount"}})],1),_c('div',{staticClass:"inputLabel"},[_c('span',[_vm._v(_vm._s(_vm.t("newBalance")))]),_c(VTextField,{attrs:{"value":_vm.newBalance,"disabled":true,"hide-details":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }