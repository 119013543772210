<template>
  <div class="gridItem divisor">
    <span>{{ t("sslCertificate") }}</span>

    <span>
      <span :class="certificateStateClass">{{ certificateState }}</span>
    </span>

    <span v-if="expirationDate" class="date label">{{ t("subscriptionEndDate", { date: expirationDate }) }}</span>

    <span v-if="showCertificateExpirationCountDown">
      <span class="--color-warning">
        {{ t("timeLeftLabel", { days: certificateExpirationDays }, certificateExpirationDays) }}
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/composable/useStore"
import { COMFORTABLE_TIME_RANGE } from "@/config/warning-range"
import { createTranslationModule } from "@/plugins/i18n"
import { defineComponent, computed } from "@vue/composition-api"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "SslLabel",
  setup() {
    const t = createTranslationModule("TriCaster.boxFooter.moreInfo.")
    const { spaceModule } = useStore()
    const WARNING_DAYS_BEFORE_EXPIRE = COMFORTABLE_TIME_RANGE

    const certificate = computed(() => {
      return spaceModule.watching.certificateInfo
    })

    const certificateState = computed(() => {
      const certificateMissing = !hasValidCertificate.value

      if (validatingCertificate.value) {
        return t("sslStates.validating")
      } else if (certificateMissing) {
        return t("sslStates.missing")
      } else if (certificateExpired.value) {
        return t("sslStates.expired")
      } else {
        return t("sslStates.verified")
      }
    })

    const certificateStateClass = computed(() => {
      const classes = []

      if (validatingCertificate.value) {
        classes.push("--color-warning")
      } else if (!hasValidCertificate.value || certificateExpired.value) {
        classes.push("--color-danger")
      }

      return classes.join(" ")
    })

    const showCertificateExpirationCountDown = computed(() => {
      if (hasValidCertificate.value && !validatingCertificate.value) {
        const lowDays = certificateExpirationDays.value <= WARNING_DAYS_BEFORE_EXPIRE
        return lowDays && certificateExpirationDays.value > 0
      }

      return false
    })

    const expirationDate = computed(() => {
      const expires = certificate.value.expiresAt

      if (expires) {
        return Utils.dateWithNamedMonth(expires)
      }

      return null
    })

    const validatingCertificate = computed(() => {
      return certificate.value.status === "validating"
    })

    const certificateExpired = computed(() => {
      if (hasValidCertificate.value && !validatingCertificate.value) {
        return certificateExpirationDays.value <= 0
      }

      return false
    })

    const hasValidCertificate = computed(() => {
      return certificate.value.expiresAt !== null || validatingCertificate.value
    })

    const certificateExpirationDays = computed(() => {
      const expirationDate = certificate.value.expiresAt

      if (expirationDate) {
        const expirationDays = Utils.dateRange(Date.now(), expirationDate)?.days

        if (expirationDays >= 0) {
          return expirationDays
        }
      }

      return 0
    })

    return {
      t,
      showCertificateExpirationCountDown,
      validatingCertificate,
      certificateStateClass,
      hasValidCertificate,
      certificateExpired,
      certificateExpirationDays,
      expirationDate,
      certificateState,
      WARNING_DAYS_BEFORE_EXPIRE
    }
  }
})
</script>
