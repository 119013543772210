import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"appFooter"},[(_vm.singleDownloadOption)?_c(VBtn,{attrs:{"disabled":!_vm.app.id || _vm.loading || !_vm.isDeployed,"large":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadAction.apply(null, arguments)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_file_download_or_import")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.appCard.footer.buttons.download")))])],1):_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"no-padding downloadOptions",attrs:{"disabled":!_vm.app.id || _vm.loading || !_vm.isDeployed,"large":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"group"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_file_download_or_import")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.appCard.footer.buttons.download")))])],1),_c(VIcon,{attrs:{"size":25}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c(VList,{staticClass:"otherOptionsList"},[_c(VListItem,{staticClass:"optionItem",on:{"click":function($event){return _vm.$emit('download:shortcut')}}},[(_vm.isDeployed)?_c('tooltip',{attrs:{"text":_vm.$t('WorkspaceEdit.appCard.footer.tooltip.niceDcv'),"right":""}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_file_download_or_import")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.appCard.footer.buttons.downloadNiceDcv")))])],1)],1):_vm._e()],1),(_vm.isSaaS)?_c(VListItem,{staticClass:"optionItem",on:{"click":function($event){return _vm.$emit('download:app-launcher')}}},[_c('tooltip',{attrs:{"text":_vm.$t('WorkspaceEdit.appCard.footer.tooltip.appLauncher'),"right":""}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_file_download_or_import")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.appCard.footer.buttons.downloadAppLauncher")))])],1)],1)],1):_vm._e()],1)],1),_c('tooltip',{attrs:{"text":_vm.$t('LicensePage.appCard.hint.open'),"bottom":""}},[_c(VBtn,{attrs:{"disabled":!_vm.app.id || _vm.loading || !_vm.isOnline,"color":"primary","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('open:instance')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("content_folder_closed")]),_c('span',[_vm._v(_vm._s(_vm.$t("WorkspaceEdit.appCard.footer.buttons.open")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }