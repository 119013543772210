import { translate } from "@/plugins/i18n"
import { SelectItem } from "@/types/core"
import { FeedbackType } from "@/types/feedback"

enum FeedbackTypeFilter {
  all = "all"
}

export type FilterOption = FeedbackTypeFilter | FeedbackType

export const FILTER_INITIAL_VALUE = FeedbackTypeFilter.all

const feedbackFilterTypeOptions = (): SelectItem<FilterOption>[] => [
  {
    text: translate("Admin.feedback.feedbackTypes.all"),
    value: FeedbackTypeFilter.all
  },
  {
    text: translate("Admin.feedback.feedbackTypes.general"),
    value: FeedbackType.general
  },
  {
    text: translate("Admin.feedback.feedbackTypes.bug"),
    value: FeedbackType.bug
  },
  {
    text: translate("Admin.feedback.feedbackTypes.featureRequest"),
    value: FeedbackType.featureRequest
  },
  {
    text: translate("Admin.feedback.feedbackTypes.productRequest"),
    value: FeedbackType.productRequest
  },
  {
    text: translate("Admin.feedback.feedbackTypes.notSpecified"),
    value: FeedbackType.notSpecified
  }
]

export default feedbackFilterTypeOptions
