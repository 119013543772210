<template>
  <div class="advancedFilter">
    <help-box custom-class="advancedFilterContainer">
      <v-btn :class="`filterButtonTrigger ${isFilterUsed ? 'selected' : ''}`" rbt-target="filter" aria-selected large>
        <v-icon left>action_filter</v-icon>
        <span>{{ $t("MyWorkspaces.filterButtons.filter") }}</span>

        <span v-if="isFilterUsed" class="filterCounter animateFadeUp">
          <span>( {{ usedFilterCount }} )</span>
          <v-icon @click.stop="resetFilter" size="13">action_close</v-icon>
        </span>
      </v-btn>

      <template v-slot:content>
        <div class="filterOptions">
          <div class="filterItem">
            <user-options v-model="advancedConfig.ownerId" :users="users" />
            <date-filter v-model="advancedConfig.lastModified" />
          </div>

          <div class="filterItem">
            <pick-status v-model="advancedConfig.status" />
          </div>

          <div class="controller">
            <v-btn @click="resetFilter" large>
              <span>{{ $t("MyWorkspaces.filterButtons.resetFilter") }}</span>
            </v-btn>
          </div>
        </div>
      </template>
    </help-box>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { AdvancedFilterConfig } from "@/utils/advancedFilter/type"
import { SelectItem } from "@/types/core"
import HelpBox from "@/components/helpBox/HelpBox.vue"
import UserOptions from "./filterComponents/UserOptions.vue"
import DateFilter from "./filterComponents/DateFilter.vue"
import PickStatus from "./filterComponents/PickStatus.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "AdvancedFilter",
  props: {
    value: {
      type: Object as PropType<AdvancedFilterConfig>,
      required: true
    },
    users: {
      type: Array as PropType<SelectItem<string>[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const advancedConfig = Utils.vModel(props, ctx.emit)

    const resetFilter = () => {
      advancedConfig.value.ownerId = "any"
      advancedConfig.value.lastModified = "any"
      advancedConfig.value.status = []
    }

    const usedFilterCount = computed(() => {
      const { ownerId, lastModified, status } = advancedConfig.value
      let counter = 0

      if (ownerId !== "any") {
        counter++
      }
      if (lastModified !== "any") {
        counter++
      }

      counter += status.length

      return counter
    })

    const isFilterUsed = computed(() => {
      return usedFilterCount.value > 0
    })

    return {
      isFilterUsed,
      usedFilterCount,
      resetFilter,
      advancedConfig
    }
  },
  components: {
    HelpBox,
    DateFilter,
    UserOptions,
    PickStatus
  }
})
</script>
