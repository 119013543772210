import { zoneEndpoint } from "./config"
import { CloudZone } from "@/types/core"

export const bestRegions = []

export const updateCloudZonePing = async (
  cloudZones: CloudZone[],
  vizNowSupportedRegions: string[],
  cloudProviders: CloudZone[]
): Promise<CloudZone[]> => {
  const providers = [...cloudProviders]

  if (providers.length) {
    await Promise.all(
      providers.map(async zone => {
        const ping = await _ping(zone.code)
        zone.ping = ping
      })
    )
  } else {
    cloudZones.forEach(_cloudZone => {
      if (vizNowSupportedRegions.some(zone => _cloudZone.code.includes(zone))) {
        providers.push(_cloudZone)
      }
    })

    return updateCloudZonePing(cloudZones, vizNowSupportedRegions, providers)
  }

  return _removeDuplicatedZones(providers)
}

const _ping = async (zone: string) => {
  const startTime = Date.now()

  await fetch(zoneEndpoint(zone))

  return Date.now() - startTime
}

const _removeDuplicatedZones = (zones: CloudZone[]) => {
  return zones.reduce((collection: CloudZone[], zone) => {
    if (collection.every(addedZone => zone.code !== addedZone.code)) {
      collection.push(zone)
    }

    return collection
  }, [])
}
