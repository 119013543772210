<template>
  <div :class="classes">
    <div class="inputRow">
      <span class="firstLabel">Start time</span>

      <time-picker v-model="start.startTime.value" :disabled="readonly">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-on="on"
            v-bind="attrs"
            v-model="start.startTimeDate.value"
            @click:clear="start.startTime.value = null"
            :disabled="readonly"
            append-icon="mdi-calendar-blank"
            hide-details
            readonly
            clearable
          >
            <template v-slot:append>
              <v-icon v-on="on">mdi-calendar-blank</v-icon>
            </template>
          </v-text-field>
        </template>
      </time-picker>

      <span v-if="start.startTimeUTC.value">{{ start.startTimeUTC.value }} UTC</span>
    </div>

    <div class="inputRow">
      <span class="firstLabel">End time</span>

      <time-picker v-model="end.endTime.value" :min-date="start.startTime.value" :disabled="readonly">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-on="on"
            v-bind="attrs"
            v-model="end.endTimeDate.value"
            @click:clear="end.endTime.value = null"
            :disabled="readonly"
            append-icon="mdi-calendar-blank"
            hide-details
            readonly
            clearable
          >
            <template v-slot:append>
              <v-icon v-on="on">mdi-calendar-blank</v-icon>
            </template>
          </v-text-field>
        </template>
      </time-picker>

      <span v-if="end.endTimeUTC.value">{{ end.endTimeUTC.value }} UTC</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { useSchedule } from "./composable/useSchedule"
import TimePicker from "./_TimePicker.vue"

export default defineComponent({
  name: "SchedulerSettings",
  props: {
    autoShutdownEnabled: {
      type: Boolean,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { start, end } = useSchedule()

    const classes = computed(() => {
      const classCollection = ["scheduler", "autoShutdownSetting"]

      if (start.startTime.value && end.endTime.value) {
        if (start.startTime.value > end.endTime.value) {
          classCollection.push("reverse")
        }
      }

      return classCollection.join(" ")
    })

    return {
      classes,
      start,
      end
    }
  },
  components: {
    TimePicker
  }
})
</script>
