<template>
  <div class="feedbackStatisticsContainer">
    <v-btn @click="showModal = true" large>
      <v-icon left>vizartist_zoom_to_whole_spline</v-icon>
      <span>{{ $t("Admin.feedback.statistics.button") }}</span>
    </v-btn>

    <popup v-model="showModal" :title="$t('Admin.feedback.statistics.modalTitle')" width="25vw" hide-footer>
      <div id="feedbackStatistics">
        <div class="feedbackType animateFadeUp animationDelay-2 itemContainer">
          <type-container
            :title="$t('Admin.feedback.feedbackTypes.general')"
            :percent-value="statistics.feedbackType.general"
          />

          <type-container
            :title="$t('Admin.feedback.feedbackTypes.bug')"
            :percent-value="statistics.feedbackType.bug"
          />

          <type-container
            :title="$t('Admin.feedback.feedbackTypes.featureRequest')"
            :percent-value="statistics.feedbackType.featureRequest"
          />

          <type-container
            :title="$t('Admin.feedback.feedbackTypes.productRequest')"
            :percent-value="statistics.feedbackType.productRequest"
          />

          <type-container
            :title="$t('Admin.feedback.feedbackTypes.notSpecified')"
            :percent-value="statistics.feedbackType.notSpecified"
          />

          <div class="totalSection">
            <span>{{ $t("Admin.feedback.statistics.total") }} </span>
            <span>{{ totalFeedbackTypeAmount }}</span>
          </div>
        </div>

        <div class="rate animateFadeUp animationDelay-4 itemContainer">
          <emoji-container name="horrible" :percent-value="statistics.rate.horrible" />
          <emoji-container name="sad" :percent-value="statistics.rate.sad" />
          <emoji-container name="neutral" :percent-value="statistics.rate.neutral" />
          <emoji-container name="happy" :percent-value="statistics.rate.happy" />
          <emoji-container name="love" :percent-value="statistics.rate.love" />

          <div class="totalSection">
            <span>{{ $t("Admin.feedback.statistics.total") }} </span>
            <span>{{ totalRateAmount }}</span>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType } from "@vue/composition-api"
import { FeedbackItem } from "@/types/feedback"
import { feedbackStatistics } from "../lib/feedback-statistics"
import TypeContainer from "./TypeContainer.vue"
import Popup from "@/components/popup/PopupRoot.vue"
import EmojiContainer from "./EmojiContainer.vue"

export default defineComponent({
  name: "FeedbackStatistics",
  props: {
    items: {
      type: Array as PropType<FeedbackItem[]>,
      required: true
    }
  },
  setup(props) {
    const showModal = ref(false)

    const statistics = computed(() => {
      return feedbackStatistics(props.items)
    })

    const totalFeedbackTypeAmount = computed(() => {
      const values = Object.values(statistics.value.feedbackTypeRegistered)
      return sumValues(values)
    })

    const totalRateAmount = computed(() => {
      const values = Object.values(statistics.value.rateRegistered)
      return sumValues(values)
    })

    const sumValues = (values: number[]) => {
      return values.reduce((sum, amount) => (sum += amount), 0)
    }

    return {
      showModal,
      totalFeedbackTypeAmount,
      totalRateAmount,
      statistics
    }
  },
  components: {
    Popup,
    EmojiContainer,
    TypeContainer
  }
})
</script>
