<template>
  <div class="templateFilters">
    <label>{{ $t("Admin.spaceTemplate.filter.title") }}</label>

    <ul v-if="hasTags" class="tagContainer">
      <li
        v-for="tag in uniqueTagKeys"
        @click="toggleActiveTag(tag.name)"
        :key="tag.name"
        :active="isActive(tag.name)"
        class="chipItem animateFadeUp"
      >
        <span>{{ tag.name }}</span>
        <span class="tagCounter">{{ tag.count }}</span>
      </li>

      <li
        v-for="tag in uniquePlanKeys"
        @click="toggleActiveTag(tag.name)"
        :key="tag.name"
        :active="isActive(tag.name)"
        class="planItem chipItem animateFadeUp"
      >
        <span>{{ tag.name }}</span>
        <span class="tagCounter">{{ tag.count }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WorkspaceRawTemplate } from "@/types/workspace"
import Utils from "@/utils/utils"

interface CachedTag {
  [key: string]: {
    name: string
    count: number
  }
}

export default defineComponent({
  name: "TagFilter",
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true
    },
    templates: {
      type: Array as PropType<WorkspaceRawTemplate[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const activeTags = Utils.vModel(props, ctx.emit)

    const hasTags = computed(() => {
      const hasUniqueTags = Boolean(Object.values(uniqueTagKeys.value).length)
      const hasUniquePlans = Boolean(Object.values(uniquePlanKeys.value).length)

      return hasUniqueTags || hasUniquePlans
    })

    const toggleActiveTag = (tag: string) => {
      const tagIndex = activeTags.value.indexOf(tag)

      if (tagIndex !== -1) {
        activeTags.value.splice(tagIndex, 1)
      } else {
        activeTags.value.push(tag)
      }
    }

    const isActive = (tag: string) => {
      return activeTags.value.includes(tag)
    }

    const uniqueTagKeys = computed(() => {
      return props.templates.reduce((tags: CachedTag, template) => {
        template.tags.forEach(tag => {
          if (!tags[tag]) {
            tags[tag] = {
              name: tag,
              count: 1
            }
          } else if (tags[tag]) {
            tags[tag].count++
          }
        })

        return tags
      }, {})
    })

    const uniquePlanKeys = computed(() => {
      return props.templates.reduce((plans: CachedTag, template) => {
        const validTemplatePlan = Boolean(template.plan)

        if (validTemplatePlan && !plans[template.plan]) {
          plans[template.plan] = {
            name: template.plan,
            count: 1
          }
        } else if (plans[template.plan]) {
          plans[template.plan].count++
        }

        return plans
      }, {})
    })

    return {
      isActive,
      hasTags,
      toggleActiveTag,
      uniqueTagKeys,
      uniquePlanKeys
    }
  }
})
</script>
