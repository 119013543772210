import { getDateDnsLocale, translate } from "@/plugins/i18n"
import { raiseError, raiseSuccess } from "@/utils/event-bus"
import { Ref, ref, SetupContext, UnwrapRef, watch } from "@vue/composition-api"
import { AxiosResponse } from "axios"
import { formatDistanceToNowStrict, isSameWeek, format, isSameYear } from "date-fns"
import { IGenericResponse } from "./request"
import app from "@/main"
import router from "@/router"

export const GetRootComponent = (): Vue => app.$children[0]

export const wrapCallbackErrorAsync = async <T = unknown>(cb: () => T): Promise<T> => {
  try {
    return await cb()
  } catch (err) {
    raiseError({ text: err as string, error: err })
    throw err
  }
}

export const wrapCallbackErrorAsyncGeneric = async <T>(
  cb: () => Promise<AxiosResponse<IGenericResponse<T>>>
): Promise<T> =>
  wrapCallbackErrorAsync(async () => {
    const r = await cb()
    if (!r.data.success) throw r.data.error
    return r.data.data
  })

export const formatDate = (d: Date): string =>
  isSameWeek(d, new Date())
    ? formatDistanceToNowStrict(d, { addSuffix: true, locale: getDateDnsLocale() })
    : format(d, isSameYear(d, new Date()) ? translate("date.formatNoYear") : translate("date.formatWithYear"), {
        locale: getDateDnsLocale()
      })

export const formatDateComplete = (d: Date): string =>
  format(d, translate("date.formatComplete"), { locale: getDateDnsLocale() })

export const copyToClipboard = async (text: string, silent = false): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text)

    if (!silent && text) {
      raiseSuccess({ text: translate("common.textCopiedToClipboard") })
    }

    return true
  } catch (err) {
    raiseError({ text: translate("common.textNotCopiedToClipboard") })

    setTimeout(() => {
      throw err
    })

    return false
  }
}

export const localStorageRef = <T>(key: string, defaultValue: T): Ref<UnwrapRef<T>> => {
  const savedValue = localStorage.getItem(key)
  const result = savedValue === null ? ref(defaultValue) : ref<T>(JSON.parse(savedValue) as T)

  watch(result, () => {
    localStorage.setItem(key, JSON.stringify(result.value))
  })

  return result
}

export const newTab = (link: string) => {
  const linkElement = document.createElement("a")
  linkElement.href = link
  linkElement.setAttribute("target", "_blank")
  linkElement.id = "__newTab__"

  document.body.appendChild(linkElement)
  const $linkElement = document.querySelector("#__newTab__") as HTMLElement
  $linkElement.click()
  $linkElement.remove()
}

/**
 * Creates a two way ref for data binding (via v-model)
 * Usage:
 * `const formDetailsValid = twoWayRef(() => value, ctx, false)`
 *
 * @param value The value coming from props
 * @param ctx SetupContext
 * @param defaultValue The default value when initializing
 * @returns Reactive reference for the value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const twoWayRef = <T>(value: () => T, ctx: SetupContext<any>, defaultValue: T): Ref<T> => {
  const v = ref(defaultValue) as Ref<T>
  watch(v, () => {
    ctx.emit("input", v.value)
  })
  watch(
    value,
    () => {
      v.value = value()
    },
    { immediate: true }
  )
  return v
}

/**
 * Creates ref variable binded to the url hash
 * Usage:
 * `const formDetailsValid = urlHashRef("defaultValue")`
 * @param defaultValue The default value when initializing
 * @returns a reactive reference for the variable
 */
export const urlHashRef = (defaultValue = ""): Ref<string> => {
  const variable = ref(getUrlHash() || defaultValue)
  watch(variable, () => {
    router.replace({ hash: "#" + variable.value })
  })
  watch(
    () => router.currentRoute.hash,
    () => {
      variable.value = getUrlHash() || defaultValue
    },
    { immediate: true }
  )
  return variable
}

export const getUrlHash = (): string => router.currentRoute.hash.substring(1)

/**
 * Format bytes as human-readable text.
 * @see https://stackoverflow.com/a/14919494/7649295
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) return bytes + " B"
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : //: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  let u = -1
  const r = 10 ** dp
  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
  return bytes.toFixed(dp) + " " + units[u]
}

export const isLocalhostIp = (ip: string): boolean => ip == "127.0.0.1" || ip == "0.0.0.1"

/**
 * Validates if an IP is valid
 * @see https://stackoverflow.com/a/27434991/7649295
 * @param ip to test
 * @returns true or false
 */
export const isValidIpV4 = (ip: string): boolean =>
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    ip
  )

/**
 * Makes Vue builder import all files under @/assets to make them available to load dynamically (eg path coming from database )
 */
export const assets = require.context("@/assets/")
export const getAssetUrl = (assetName: string): string => assets("./" + assetName)

export const sleep = (ms: number): Promise<never> => new Promise(resolve => setTimeout(resolve, ms))
