<template>
  <div id="assetHandler" class="backgroundWrapper">
    <h1>{{ t("title") }}</h1>

    <v-progress-linear v-if="loader.on" color="primary" class="my-0 py-0" indeterminate />

    <div class="assetBoxContainer">
      <div class="header">
        <div class="searchBar">
          <search v-model="searchSnippet" />
        </div>

        <div class="controller">
          <v-btn @click="openRawDataPopup" large>
            <v-icon left>mdi-content-paste</v-icon>
            <span>{{ t("rawDataPopup.headerButton") }}</span>
          </v-btn>

          <v-btn @click="startCreatingAsset" :disabled="loader.on" color="primary" large>
            <v-icon left>mdi-plus</v-icon>
            <span>{{ t("header.createNew") }}</span>
          </v-btn>
        </div>
      </div>

      <div class="body">
        <asset-collection
          @edit="startEditing"
          @duplicate="duplicateAsset"
          @remove="deleteAsset"
          @copy:raw-data="copyAssetRawData"
          :loading="loader.on"
          :items="assetCollection"
        />

        <create-asset
          v-if="structure"
          v-model="structure"
          @view:change="changeView"
          @edit="editAsset"
          @create="createAsset"
          :view="view"
          :loading="loader.on"
        />

        <paste-json
          v-if="openPasteJsonPopup"
          v-model="openPasteJsonPopup"
          @create="createAssetFromRawDate"
          :loading="loader.on"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "@vue/composition-api"
import { ViewType } from "./lib/type"
import { createTranslationModule } from "@/plugins/i18n"
import { AdminModule, WorkspaceModule } from "@/store"
import { WorkspaceApplication } from "@/types/asset"
import { spaceAsset } from "./lib/new-structure"
import { copyToClipboard } from "@/utils/ui"
import Loader from "@/utils/loader"
import AssetCollection from "./_AssetCollection.vue"
import CreateAsset from "./_CreateAsset.vue"
import Search from "@/components/search/Search.vue"
import PasteJson from "./_PasteJson.vue"
import "./assetHandler.scss"

export default defineComponent({
  name: "AssetHandlerRoot",
  setup() {
    const adminModule = AdminModule()
    const workspaceModule = WorkspaceModule()
    const view = ref<ViewType>(ViewType.LISTING)
    const structure = ref<WorkspaceApplication | null>()
    const loader = Loader()
    const searchSnippet = ref("")
    const openPasteJsonPopup = ref(false)
    const t = createTranslationModule("Admin.assetHandler.")

    const assetCollection = computed(() => {
      return adminModule.platformAssetModel.filter(asset => {
        const _lower = (str: string) => (str ?? "").toLocaleLowerCase().trim()
        const description = _lower(asset?.description)
        const title = _lower(asset?.text)

        if (searchSnippet.value) {
          return [description, title].some(snippet => snippet.includes(_lower(searchSnippet.value)))
        } else {
          return true
        }
      })
    })

    const is = (isView: ViewType) => {
      return view.value === isView
    }

    const changeView = (viewType: ViewType) => {
      view.value = viewType
    }

    const startCreatingAsset = () => {
      changeView(ViewType.CREATING)
      structure.value = spaceAsset()
    }

    const openRawDataPopup = () => {
      openPasteJsonPopup.value = true
    }

    const createAsset = () => {
      loader.run(async () => {
        if (structure.value) {
          await adminModule.createAsset(structure.value)
          await adminModule.getPlatformAssets()

          changeView(ViewType.LISTING)
        }
      })
    }

    const createAssetFromRawDate = async (rawData: WorkspaceApplication) => {
      loader.run(async () => {
        if (rawData) {
          if ("id" in rawData) {
            rawData.id = null
          }

          const req = await adminModule.createAsset(rawData)
          const newAsset = req.data.data
          await adminModule.getPlatformAssets()

          openPasteJsonPopup.value = false
          await startEditing(newAsset.id as string)
        }
      })
    }

    const editAsset = () => {
      loader.run(async () => {
        if (structure.value) {
          await adminModule.editAsset(structure.value)
          await adminModule.getPlatformAssets()

          changeView(ViewType.LISTING)
        }
      })
    }

    const duplicateAsset = (assetId: string) => {
      loader.run(async () => {
        const asset = await adminModule.getPlatformAssetById(assetId)

        if (asset) {
          structure.value = asset

          structure.value.key = ""
          structure.value.id = ""

          changeView(ViewType.CREATING)
        }
      })
    }

    const copyAssetRawData = (assetId: string) => {
      loader.run(async () => {
        const asset = await adminModule.getPlatformAssetById(assetId)
        const assetJsonString = JSON.stringify(asset)

        copyToClipboard(assetJsonString)
      })
    }

    const deleteAsset = (assetId: string) => {
      loader.run(async () => {
        await adminModule.deleteAsset(assetId)
        await adminModule.getPlatformAssets()
      })
    }

    const startEditing = async (assetId: string) => {
      await loader.run(async () => {
        const asset = await adminModule.getPlatformAssetById(assetId)

        if (asset) {
          changeView(ViewType.EDITING)
          structure.value = asset
        }
      })
    }

    loader.run(async () => {
      await workspaceModule.getAssetModels()
      await adminModule.getPlatformAssets()
    })

    return {
      createAssetFromRawDate,
      copyAssetRawData,
      openRawDataPopup,
      openPasteJsonPopup,
      deleteAsset,
      duplicateAsset,
      createAsset,
      searchSnippet,
      editAsset,
      startCreatingAsset,
      structure,
      is,
      changeView,
      startEditing,
      t,
      loader,
      assetCollection,
      ViewType,
      view
    }
  },
  components: {
    PasteJson,
    Search,
    CreateAsset,
    AssetCollection
  }
})
</script>
