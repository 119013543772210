<template>
  <div class="customSelectContainer">
    <div class="customCombobox">
      <input
        v-model="selectValue"
        @input="validateInput"
        @keydown.enter="handleEnter"
        :placeholder="t('customComboboxPlaceholder')"
        ref-key="comboboxInput"
        class="handler"
      />

      <button @click="setValue" :status="inputStatus" :disabled="loading" class="iconEnter">
        <v-icon>{{ icon }}</v-icon>
      </button>
    </div>

    <tag-list
      v-model="selectValue"
      @enter="handleEnter"
      @change="validateInput"
      :items="filteredList"
      :used-tags="[]"
      :auto-select="false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, nextTick, PropType } from "@vue/composition-api"
import { InputStatus } from "../lib/type"
import { createTranslationModule } from "@/plugins/i18n"
import { MaxLengthTextField } from "@/utils/input-rules"
import { MAX_TAG_VALUE_LENGTH } from "../lib/max-character"
import TagList from "./_TagList.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "CustomSelect",
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<string[]>,
      required: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const t = createTranslationModule("TagPill.")
    const selectValue = Utils.vModel(props, ctx.emit)
    const inputStatus = ref(InputStatus.SUCCESS)

    const filteredList = computed(() => {
      const _lowerString = (s: string) => s.toLowerCase().trim()
      return props.items.filter(tagValue => _lowerString(tagValue).includes(_lowerString(selectValue.value)))
    })

    const icon = computed(() => {
      switch (inputStatus.value) {
        case InputStatus.ERROR:
          return "mdi-close"
        case InputStatus.SUCCESS:
        case InputStatus.IDLE:
          return "mdi-check"
      }
    })

    const setValue = () => {
      ctx.emit("change", selectValue.value)
    }

    const handleEnter = () => {
      nextTick(() => {
        if (selectValue.value) {
          setValue()
        }
      })
    }

    const validateInput = () => {
      const rule = MaxLengthTextField(MAX_TAG_VALUE_LENGTH)[0]

      nextTick(() => {
        if (!selectValue.value || rule(selectValue.value) === true) {
          inputStatus.value = InputStatus.SUCCESS
          return true
        } else {
          inputStatus.value = InputStatus.ERROR
          return false
        }
      })
    }

    const _focusInput = () => {
      setTimeout(() => {
        const $comboboxInput = document.querySelector("[ref-key='comboboxInput']") as HTMLElement | null

        if ($comboboxInput) {
          $comboboxInput.focus()
        }
      }, 100)
    }

    _focusInput()

    return {
      t,
      handleEnter,
      validateInput,
      inputStatus,
      icon,
      InputStatus,
      filteredList,
      setValue,
      selectValue
    }
  },
  components: {
    TagList
  }
})
</script>
