<template>
  <tooltip :disabled="hasEnoughTokens" text="_">
    <template v-slot:text>
      <div class="tooltipLowCreditWarning">
        <strong>{{ $t("TriCaster.boxHeader.tokenWarning.outOfCredits") }}</strong>

        <span>
          {{ $t("TriCaster.boxHeader.tokenWarning.generic") }}
        </span>
      </div>
    </template>

    <slot :disabled="!hasEnoughTokens" />
  </tooltip>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { OrganizationModule } from "@/store"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "EnoughTokens",
  setup() {
    const organizationModule = OrganizationModule()

    const hasEnoughTokens = computed(() => {
      const tokenCount = organizationModule.walletDetail.amount ?? 0
      return tokenCount > 0
    })

    return {
      hasEnoughTokens
    }
  },
  components: {
    Tooltip
  }
})
</script>
