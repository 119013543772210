import { RouteConfig } from "vue-router"
import { isDevelopment } from "@/utils/env"
import { PlatformViewType } from "@/types/core"
import { translate } from "@/plugins/i18n"
import Login from "@/views/login/LoginRoot.vue"
import WorkspacesList from "@/views/workspaceList/WorkspacesRoot.vue"
import NotFound from "@/views/notFound/NotFoundRoot.vue"
import Onboarding from "@/views/onboarding/OnboardingRoot.vue"
import Honeypot from "@/views/honeypot/Honeypot.vue"
import WorkspaceCreate from "@/views/workspaceCreate/Root.vue"
import AdminConfig from "@/views/adminConfig/AdminConfigRoot.vue"
import WorkspaceEdit from "@/views/workspaceEdit/WorkspaceEditRoot.vue"
import UsersPage from "@/views/usersOrganizationPage/OrganizationUserRoot.vue"
import LoginRedirect from "@/views/loginRedirect/LoginRedirectRoot.vue"
import PresentationRoot from "@/views/appLauncher/PresentationRoot.vue"
import AppLauncherPickerRoot from "@/views/appLauncherPicker/AppLauncherPickerRoot.vue"
import CoreLicenses from "@/views/coreLicenses/CoreLicensesRoot.vue"
import ForbiddenContent from "@/views/forbiddenContent/ForbiddenContentRoot.vue"
import OrganizationPageV2 from "@/views/organizationPage-v2"
import AppLauncherCollection from "@/views/appLauncherCollection"

const anonymousRoutes: RouteConfig[] = [
  { path: "/login", name: "Login", component: Login, meta: { requiresAuth: false, fillHeight: true } },
  {
    path: "/login/redirect",
    name: "LoginRedirect",
    component: LoginRedirect,
    meta: { requiresAuth: false, allowAccess: isDevelopment() }
  }
]

const regularUserRoutes: RouteConfig[] = [
  {
    path: "/",
    name: "Root",
    redirect: { name: "WorkspacesList" },
    meta: { requiresAuth: true, pageRelative: "workspace" }
  },
  {
    path: "/workspaces",
    name: "WorkspacesList",
    component: WorkspacesList,
    meta: {
      requiresAuth: true,
      pageRelative: "workspace",
      title: translate("RouteNames.workspaceList")
    }
  },
  {
    path: "/workspace/edit/:id",
    name: "WorkspaceEdit",
    component: WorkspaceEdit,
    meta: {
      requiresAuth: true,
      pageRelative: "workspace",
      title: translate("RouteNames.workspaceEdit"),
      strictViewSettings: PlatformViewType.VIZ_NOW
    }
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: Onboarding,
    meta: {
      requiresAuth: true,
      pageRelative: "onboarding",
      title: translate("RouteNames.onboarding"),
      strictViewSettings: PlatformViewType.VIZ_NOW
    }
  },
  {
    path: "/workspace/creation",
    name: "WorkspaceConfig",
    component: WorkspaceCreate,
    meta: { requiresAuth: true, pageRelative: "workspace", title: translate("RouteNames.createWorkspace") }
  },
  {
    path: "/workspace/edit/:workspaceId/app-launcher/",
    name: "AppLauncherPicker",
    component: AppLauncherPickerRoot,
    meta: {
      requiresAuth: true,
      pageRelative: "workspace",
      hideNavigation: true,
      title: translate("RouteNames.appLauncherPicker"),
      hideFeedbackSign: true
    }
  },
  {
    path: "/workspace/edit/:workspaceId/app-launcher/:appId/",
    name: "AppLauncher",
    component: PresentationRoot,
    meta: {
      requiresAuth: true,
      pageRelative: "workspace",
      hideNavigation: true,
      title: translate("RouteNames.appLauncher"),
      hideFeedbackSign: true
    }
  },
  {
    path: "/app-launcher/collection/",
    name: "AppLauncherCollection",
    component: AppLauncherCollection,
    meta: {
      requiresAuth: true,
      pageRelative: "workspace",
      hideNavigation: true,
      title: translate("RouteNames.appLauncherCollection"),
      hideFeedbackSign: true,
      strictViewSettings: PlatformViewType.APP_LAUNCHER
    }
  },
  {
    path: "/forbidden/403",
    name: "ForbiddenContent",
    component: ForbiddenContent,
    meta: {
      requiresAuth: true,
      requireAdmin: false,
      pageRelative: "forbidden",
      title: translate("RouteNames.forbiddenContent")
    }
  }
]

const adminRoutes: RouteConfig[] = [
  {
    path: "/admin/organization-page/:organizationId",
    name: "Organization",
    component: OrganizationPageV2,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
      pageRelative: "organizationPage",
      title: translate("RouteNames.organizationConfig"),
      strictViewSettings: PlatformViewType.VIZ_NOW
    }
  },
  {
    path: "/core/license/:organizationId?/",
    name: "CoreLicenses",
    component: CoreLicenses,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
      pageRelative: "coreLicenses",
      title: translate("RouteNames.coreLicenses"),
      strictViewSettings: PlatformViewType.VIZ_NOW
    }
  },
  {
    path: "/organization/:organizationId/users",
    name: "OrganizationUsers",
    component: UsersPage,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
      pageRelative: "organizationUser",
      title: translate("RouteNames.organizationUsers"),
      strictViewSettings: PlatformViewType.VIZ_NOW
    }
  },
  {
    path: "/admin/config",
    name: "AdminConfig",
    component: AdminConfig,
    meta: {
      requiresAuth: true,
      requireAdmin: true,
      pageRelative: "adminConfig",
      licenseRedirectException: true,
      title: translate("RouteNames.adminPanel"),
      strictViewSettings: PlatformViewType.VIZ_NOW
    }
  }
]

const honeypot: RouteConfig[] = [
  {
    path: "/419207de-7d50-4b23-9651-f426ca5ba1f7",
    name: "Honeypot",
    component: Honeypot,
    meta: { requiresAuth: false }
  }
]

const genericRoutes: RouteConfig[] = [{ path: "*", name: "NotFound", component: NotFound }]

export default [...anonymousRoutes, ...regularUserRoutes, ...adminRoutes, ...genericRoutes, ...honeypot]
