interface SheetLink {
  text: string
  link: string
}

const sheetLinks: () => SheetLink[] = () => [
  {
    text: "Vizrt Reference",
    link: "https://www.vizrt.com/privacy-policy"
  }
]

export default sheetLinks
