import { IWorkspaceAsset, IWorkspaceSchema, WorkspaceTemplate } from "@/types/workspace"
import { WorkspaceLevelDiff, SchemaComparisonConfig, Iterable } from "./type"

const _getModifiedAppIds = (schema: IWorkspaceSchema, schemaDeployed: IWorkspaceSchema): string[] => {
  const modified = schema.assets.filter(app => {
    const appId = app.id
    const appOriginal = JSON.stringify(app)

    return schemaDeployed.assets.some(_app => {
      if (_app.id === appId) {
        const appCurrent = JSON.stringify(_app)

        return appCurrent !== appOriginal
      }

      return false
    })
  })

  return modified.map(app => app.id)
}

const _getWorkspaceDiff = (schema: IWorkspaceSchema, schemaDeployed: IWorkspaceSchema): WorkspaceLevelDiff => {
  const added = schema.assets.filter(app => schemaDeployed.assets.every(_app => app.id !== _app.id))
  const removed = schemaDeployed.assets.filter(app => schema.assets.every(_app => app.id !== _app.id))
  const modified = _getModifiedAppIds(schema, schemaDeployed)
  const current = schema.assets.filter(app => added.every(_app => _app.id !== app.id))

  return {
    added,
    removed,
    current,
    modified
  }
}

const _getBeforeDeployedResponse = (schema: IWorkspaceSchema): WorkspaceLevelDiff => {
  return {
    current: schema.assets,
    added: [],
    removed: [],
    modified: []
  }
}

const _appFind = (assets: IWorkspaceAsset[], appId: string): Iterable => {
  return assets.find(app => app.id === appId) as Iterable
}

export const getDiff = (workspace: WorkspaceTemplate) => {
  const { schema, schemaDeployed } = workspace

  if (schema && schemaDeployed) {
    return _getWorkspaceDiff(schema, schemaDeployed)
  } else if (schema) {
    return _getBeforeDeployedResponse(schema)
  } else {
    return null
  }
}

export const hasSchemaChanges = (schemaComparisonConfig: SchemaComparisonConfig): boolean => {
  const { schema, schemaDeployed } = schemaComparisonConfig.workspace

  if (schema && schemaDeployed) {
    const { appId, key } = schemaComparisonConfig
    const appNew = _appFind(schema.assets, appId)
    const appOld = _appFind(schemaDeployed.assets, appId)

    if (appNew && appOld) {
      return !areEqualStructures(appNew[key], appOld[key])
    }
  }

  return false
}

export const areEqualStructures = <T = unknown>(comparisonItemA: T, comparisonItemB: T): boolean => {
  return JSON.stringify(comparisonItemA) === JSON.stringify(comparisonItemB)
}
