import { translate } from "@/plugins/i18n"
import { Rule } from "@/utils/input-rules"

export const optionalOwnerAccountId: Rule<string | null>[] = [
  (value: string | null): true | string => {
    const ownerAccountIdLength = 12

    if (!value || value?.length === ownerAccountIdLength) {
      return true
    }

    return translate("InputRules.select.mandatory")
  }
]
