<template>
  <div id="statusDisplay" :status="workspace.status" :online-status="workspace.onlineStatus">
    <span class="deployment" :status="workspace.status">
      <span>{{ $t(`WorkspaceEdit.status.${workspace.status}`) }}</span>
      <span v-if="runningState" class="dot">.</span>
    </span>

    <span v-if="isDeployed && !hasError" :online-status="workspace.onlineStatus" class="onlineStatus">
      <span>{{ $t(`WorkspaceEdit.onlineStatus.${workspace.onlineStatus}`) }}</span>
      <span v-if="onlineStateChanging" class="dot">.</span>

      <span v-if="isOnline" class="appCount">
        (
        <span class="online">{{ appCount.online }}</span>
        <span class="total">/{{ appCount.total }}</span>
        )
      </span>
    </span>

    <div v-if="!onlineStateChanging && isDeployed && !hasError" class="action changeStatus">
      <span>{{ $t("WorkspaceEdit.onlineStatus.button.label") }}</span>

      <div class="controllers">
        <v-btn v-if="appCount.online > 0 && !onlineStateChanging" @click="turnApps('offline')">
          <span>{{ $t("WorkspaceEdit.onlineStatus.button.off") }}</span>
        </v-btn>

        <v-btn
          v-if="appCount.online < appCount.total && !onlineStateChanging"
          @click="turnApps('online')"
          color="primary"
        >
          <span>{{ $t("WorkspaceEdit.onlineStatus.button.on") }}</span>
        </v-btn>
      </div>
    </div>

    <div v-else-if="isDeployed && !hasError" class="action abortChanges">
      <span>{{
        $tc("WorkspaceEdit.onlineStatus.button.actionLabel", appCount.appsGoingOn || appCount.appsGoingOff, {
          state: $t(`WorkspaceEdit.onlineStatus.${workspace.onlineStatus}`)
        })
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate, WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import { WorkspaceStates } from "@/config/workspace-states"

export default defineComponent({
  name: "StatusDisplay",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props, ctx) {
    const isDeployed = computed(() => {
      return WorkspaceStates.isDeployed(props.workspace.status)
    })

    const hasError = computed(() => {
      return WorkspaceStates.failed(props.workspace.status)
    })

    const isOnline = computed(() => {
      return props.workspace.onlineStatus === "online"
    })

    const onlineStateChanging = computed(() => {
      return WorkspaceStates.appLoading(props.workspace.onlineStatus)
    })

    const appCount = computed(() => {
      const { Online, Stopping, Starting } = WorkspaceOnlineStatusEnum
      const appsOn = _getAppsByStatus(Online)?.length
      const appsGoingOn = _getAppsByStatus(Starting)?.length
      const appsGoingOff = _getAppsByStatus(Stopping)?.length

      const totalApps = props.workspace.schema.assets.length

      return {
        online: appsOn ?? 0,
        total: totalApps,
        appsGoingOn,
        appsGoingOff
      }
    })

    const runningState = computed(() => {
      return WorkspaceStates.isLoading(props.workspace.status)
    })

    const _getAppsByStatus = (status: WorkspaceOnlineStatusEnum) => {
      const deployedWorkspace = props.workspace as WorkspaceTemplate
      return deployedWorkspace?.schemaDeployed?.assets.filter(app => app.onlineStatus === status)
    }

    const turnApps = async (state: "online" | "offline" | "abort") => {
      if (state === "abort") {
        if (await raiseConfirmation({ text: translate("common.confirmAbort") })) {
          ctx.emit("abort")
        }
      } else {
        const onState = translate(`WorkspaceEdit.onlineStatus.${state}`)
        const confirmText = translate("WorkspaceEdit.confirmChangeOnOff", { onlineStatus: onState })

        if (state === "online" || (await raiseConfirmation({ text: confirmText }))) {
          ctx.emit("change:app-status", state)
        }
      }
    }

    return {
      hasError,
      onlineStateChanging,
      appCount,
      isOnline,
      turnApps,
      runningState,
      isDeployed
    }
  }
})
</script>
