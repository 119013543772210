<template>
  <v-dialog
    v-model="showModal"
    @click:outside="$emit('close')"
    :width="width"
    :max-width="maxWidth"
    :persistent="persistent"
    :fullscreen="fullscreen"
    :content-class="`${stopUsingScroll} globalDialogPopup ${customClass}`"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" class="modalHandler" @click="openModal" />
    </template>

    <v-card>
      <v-card-title v-if="!hideHeader && showModal" class="dialogTitle">
        {{ title }}

        <v-btn
          v-if="!hideCloseButton"
          @click="closeModal"
          v-tippy="{ content: closeTitle || $t('common.close') }"
          rbt-target="closeModal"
          icon
        >
          <v-icon>action_close</v-icon>
        </v-btn>
      </v-card-title>

      <div v-if="loading">
        <v-progress-linear indeterminate />
      </div>

      <slot name="root"></slot>

      <v-card-text class="py-0">
        <slot name="default"></slot>
      </v-card-text>

      <v-card-actions v-if="!hideActions">
        <slot name="actions"></slot>
        <v-spacer></v-spacer>
        <v-btn large @click="closeModal">{{ closeTitle || $t("common.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "@vue/composition-api"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "Popup",
  props: {
    value: {
      type: Boolean,
      default: null
    },
    customClass: {
      type: String,
      default: ""
    },
    hideHeader: Boolean,
    title: String,
    loading: Boolean,
    width: [Number, String],
    maxWidth: [Number, String],
    closeTitle: String,
    hideActions: Boolean,
    hideCloseButton: Boolean,
    persistent: Boolean,
    fullscreen: {
      type: Boolean,
      default: false
    },
    removeScrolls: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close"],
  setup(props, ctx) {
    const showModalParentValue = Utils.vModel(props, ctx.emit)
    const standaloneShowDialogValue = ref(false)

    const isStandalone = computed(() => showModalParentValue.value === null)

    const showModal = computed({
      get() {
        if (isStandalone.value) {
          return standaloneShowDialogValue.value
        } else {
          return showModalParentValue.value
        }
      },
      set(value: boolean) {
        if (isStandalone.value) {
          standaloneShowDialogValue.value = value
        } else {
          showModalParentValue.value = value
        }
      }
    })

    const openModal = () => {
      showModal.value = true
    }

    const closeModal = () => {
      showModal.value = false
      ctx.emit("close")
    }

    const stopUsingScroll = computed(() => {
      if (Utils.propHas(props, "removeScrolls")) {
        return "preventScroll"
      }

      return ""
    })

    return {
      showModal,
      stopUsingScroll,
      closeModal,
      openModal
    }
  }
})
</script>
