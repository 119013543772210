<template>
  <div id="fixedTopBar">
    <div class="logoCorner">
      <v-img width="40" src="@/assets/vizcloud_icon-new-01.png" />
    </div>

    <div class="controllers">
      <user-menu v-if="user.email" @logout="$emit('logout')" :user="user" />
      <windows-buttons />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import AppLogo from "@/components/appTopBar/components/AppLogo.vue"
import UserMenu from "@/components/appTopBar/components/UserMenu.vue"
import WindowsButtons from "./WindowsButtons.vue"

export default defineComponent({
  name: "FixedTopBar",
  props: {
    productName: {
      type: String,
      required: true
    }
  },
  setup() {
    const usersModule = UsersModule()

    const user = computed(() => {
      return usersModule.selfDetail
    })

    return {
      user
    }
  },
  components: {
    AppLogo,
    WindowsButtons,
    UserMenu
  }
})
</script>
