import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { WorkspaceOnlineStatusEnum, WorkspaceTemplate } from "@/types/workspace"

const api = new Request("/v1/Deployment/")

export default createService("deployment", {
  async abortWorkspaceChanges(workspaceId: string) {
    await api.post(`SetOnlineStatus/${workspaceId}/aborting`)
  },

  async resetLicense(licenseId: string) {
    return await api.put(`UnlockStatus/${licenseId}`)
  },

  async downloadWorkspaceShortcut(workspaceId: string, appId: string) {
    const endpoint = `DownloadInstanceConnectionLink/${workspaceId}/${appId}`
    const fileName = `${appId}.dcv`

    await api.downloadItem(endpoint, fileName, "application/zip")
  },

  async downloadDotNowFile(workspaceId: string, workspaceName: string, appId?: string) {
    const endpoint = `DownloadInstanceConnectionLink/${workspaceId}`
    const fileName = `${workspaceName}.now`
    const optionalAppId = appId ? `/${appId}` : ""

    await api.downloadItem(endpoint + optionalAppId + "/?connectionType=now", fileName)
  },

  async appSetOnlineState(workspaceId: string, onlineStatus: WorkspaceOnlineStatusEnum, appId = "") {
    return await api.post<WorkspaceTemplate>(`SetOnlineStatus/${workspaceId}/${onlineStatus}/${appId ?? ""}`)
  },

  async destroy(workspaceId: string) {
    return await api.post(`Destroy/${workspaceId}`)
  },

  async deploy(workspaceId: string, renewCertificate = false) {
    return await api.post<WorkspaceTemplate>(`PrepareDeploy/${workspaceId}`, { renewCertificate })
  },

  async getAppPassword(workspaceId: string, appId: string) {
    return await api.get<string>(`GetInstancePassword/${workspaceId}/${appId}`)
  },

  async getAppInstanceLink(workspaceId: string, appId: string) {
    return await api.get<{ connectionUrl: string; password: string }>(
      `GetInstanceConnectionUrl/${workspaceId}/${appId}`
    )
  },

  async unlockWorkspace(workspaceId: string) {
    return await api.put(`UnlockStatus/${workspaceId}`)
  }
})
