import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { RegisteredIp, Id } from "@/types/core"
import { WizardConfigItem } from "@/types/wizard"
import { AwsVpcConfig, Vpc, VpcPeerWorkspace } from "@/views/workspaceEdit/components/header/multipleVpc/lib/type"
import { ScheduledOnOff, ShutdownSettings, SpaceSchedule } from "@/views/appLauncher/lib/types"

import {
  SpaceTag,
  IAssetModel,
  WorkspaceTemplate,
  LicenseServer,
  CreateWorkspacePayload,
  AppItem,
  AddNewAssetPayload,
  UpdateAssetPayload,
  NdiConfiguration
} from "@/types/workspace"

const api = new Request("/v1/Workspaces/")

export default createService("workspace", {
  async getNdiBridgeSettings(workspaceId: string, assetId: string) {
    return await api.get<NdiConfiguration>(`${workspaceId}/Assets/${assetId}/NdiConfiguration`)
  },

  async postponeAutoShutdown(workspaceId: string, postponeForHours?: number) {
    const endpoint = `${workspaceId}/AutoShutdown/Postpone`
    const query = `?postponeFor=${postponeForHours}`
    return await api.post(`${endpoint}${postponeForHours ? query : ""}`)
  },

  async cancelAutoShutdown(workspaceId: string) {
    const endpoint = `${workspaceId}/AutoShutdown/Cancel`
    return await api.delete(endpoint)
  },

  async refreshInstancesOnlineStatus(workspaceId: string) {
    return await api.post<WorkspaceTemplate>(`RefreshInstancesOnlineStatus/${workspaceId}`)
  },

  async create(config: CreateWorkspacePayload) {
    return await api.post<WorkspaceTemplate>("", config)
  },

  async changeSharedState(workspaceId: string, shared: boolean) {
    return await api.post<boolean>(`SetShared/${workspaceId}`, shared)
  },

  async createLicenseServer(authorizedIps: RegisteredIp[], organizationId: string) {
    return await api.post<LicenseServer>(`${organizationId}/LicenseServer`, { authorizedIps })
  },

  /** @deprecated Use this.create instead */
  async createWorkspace(workspaceTemplate: WorkspaceTemplate) {
    return await api.post<string>("", workspaceTemplate)
  },

  async delete(workspaceId: string) {
    return await api.delete(`${workspaceId}`)
  },

  async getLicenseServer(organizationId: string) {
    return await api.get<WorkspaceTemplate>(`${organizationId}/LicenseServer`)
  },

  async getMyWorkspaces() {
    return await api.get<WorkspaceTemplate[]>("")
  },

  async getMyWorkspacesUsingOrganizationId(organizationId: string) {
    return await api.get<WorkspaceTemplate[]>(`?organizationId=${organizationId}`)
  },

  async getWorkspace(workspaceId: string) {
    return await api.get<WorkspaceTemplate | WorkspaceTemplate>(workspaceId, null, { ignoreCache: true })
  },

  async getVpc(workspaceId: string) {
    return await api.get<Vpc[]>(`${workspaceId}/Peerings`)
  },

  async removeCurrentLicenseServer(workspaceId: string) {
    return await api.delete(workspaceId)
  },

  async addApp(workspaceId: string, app: AddNewAssetPayload) {
    return await api.post(`${workspaceId}/Assets`, app)
  },

  async deleteVpc(workspaceId: string, peeringId: string) {
    return await api.delete(`${workspaceId}/Peerings/${peeringId}`)
  },

  async updateApp(workspaceId: string, appId: string, app: UpdateAssetPayload) {
    return await api.put(`${workspaceId}/Assets/${appId}`, app)
  },

  async deleteApp(workspaceId: string, appId: string) {
    return await api.delete(`${workspaceId}/Assets/${appId}`)
  },

  async resetWorkspaceSchemaToDeployed(workspaceId: string) {
    return await api.put(`${workspaceId}/ResetToSchemaDeployed`)
  },

  async appDiscardChanges(workspaceId: string, appId: string) {
    return await api.put(`${workspaceId}/ResetToSchemaDeployed/${appId}`)
  },

  async createWizardSettings(settings: WizardConfigItem[]) {
    return await api.post<{ id: Id }>(`/Setting`, settings)
  },

  async setShutdownSettings(workspaceId: string, shutdownSettings: ShutdownSettings) {
    return await api.put(`${workspaceId}/Settings/AutoShutdown`, shutdownSettings)
  },

  async getAutoShutdownSettings(workspaceId: string) {
    return await api.get<ShutdownSettings>(`${workspaceId}/Settings/AutoShutdown`)
  },

  async getScheduledOnOff(workspaceId: string) {
    return await api.get<ScheduledOnOff | null>(`${workspaceId}/Settings/ScheduledOnOff`)
  },

  async setScheduledOnOff(workspaceId: string, schedule: SpaceSchedule) {
    return await api.put<ScheduledOnOff | null>(`${workspaceId}/Settings/ScheduledOnOff`, schedule)
  },

  async saveChanges(workspace: WorkspaceTemplate | WorkspaceTemplate) {
    const { description, title } = workspace

    return await api.post<WorkspaceTemplate | WorkspaceTemplate>(`UpdateDetails/${workspace.id}`, {
      description,
      title
    })
  },

  async getAvailableApps(workspaceId: string) {
    return await api.get<AppItem[]>(`${workspaceId}/AvailableApps`)
  },

  async deleteTag(tagName: string, workspaceId: string) {
    return await api.delete(`${workspaceId}/Tags/${tagName}`)
  },

  async updateTag(tag: SpaceTag, workspaceId: string) {
    return await api.put(`${workspaceId}/Tags`, { ...tag })
  },

  async authorizeIp(ipProp: RegisteredIp, workspaceId: string) {
    return await api.post(`AuthorizeIP/${workspaceId}`, ipProp)
  },

  async saveVpc(vpcConfig: AwsVpcConfig | VpcPeerWorkspace, workspaceId: string) {
    return await api.post(`${workspaceId}/Peerings`, vpcConfig)
  },

  async revokeIp(ipProp: RegisteredIp, workspaceId: string) {
    return await api.delete(`UnauthorizeIP/${workspaceId}`, ipProp.ip)
  },

  async authorizeUser(userId: string, workspaceId: string) {
    return await api.post(`AssignUser/${workspaceId}`, userId)
  },

  async archive(workspaceId: string) {
    return await api.post(`${workspaceId}/Archive`)
  },

  async restore(workspaceId: string) {
    return await api.post(`${workspaceId}/Restore`)
  },

  async revokeUser(userId: string, workspaceId: string) {
    return await api.delete(`AssignUser/${workspaceId}/${userId}`)
  },

  async saveInstructions(workspace: WorkspaceTemplate | WorkspaceTemplate) {
    const { instructions } = workspace

    return await api.post(`SaveInstructions/${workspace.id}`, { instructions })
  },

  async saveTagChanges(tags: string[], workspaceId: string) {
    return await api.post(`UpdateTags/${workspaceId}`, tags)
  },

  async getAssetModels() {
    return await api.get<IAssetModel[]>("AssetModels/")
  }
})
