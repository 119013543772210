<template>
  <v-btn v-if="showArchiveButton" @click="archiveConfirmThenEmitAction" :disabled="loading || isDisabled" large>
    <archive-icon />
    <span>{{ $t("WorkspaceEdit.archive.buttonLabel") }}</span>
  </v-btn>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { raiseConfirmation } from "@/utils/event-bus"
import { WorkspaceOnlineStatusEnum, WorkspaceTemplate } from "@/types/workspace"
import { WorkspaceStates } from "@/config/workspace-states"
import { translate } from "@/plugins/i18n"
import { UsersModule, OrganizationModule } from "@/store"
import { OrganizationType } from "@/types/organization"
import ArchiveIcon from "./ArchiveIcon.vue"

export default defineComponent({
  name: "DeployButton",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()
    const organizationModule = OrganizationModule()

    const archiveConfirmThenEmitAction = async () => {
      if (await raiseConfirmation({ text: translate("WorkspaceEdit.archive.confirmationLabel") })) {
        ctx.emit("archive")
      }
    }

    const organization = computed(() => {
      return organizationModule.organizationDetail
    })

    const showArchiveButton = computed(() => {
      const workspaceOffline = props.workspace.onlineStatus === WorkspaceOnlineStatusEnum.Offline
      const isDeployed = WorkspaceStates.isDeployed(props.workspace.status)
      const hasPermission = usersModule.hasEditorPermissions
      const isSaaSOrganization = organization.value.organizationType === OrganizationType.SaaS

      return isDeployed && workspaceOffline && hasPermission && isSaaSOrganization
    })

    const isDisabled = computed(() => {
      const workspaceSchema = props.workspace.schema.assets

      return workspaceSchema.length === 0
    })

    return {
      archiveConfirmThenEmitAction,
      isDisabled,
      showArchiveButton
    }
  },
  components: {
    ArchiveIcon
  }
})
</script>
