<template>
  <div class="onboardingItem stepThree">
    <div class="contentTitle">
      <label>
        <span class="field-label" />
      </label>

      <div class="stepController" v-if="showControllers">
        <v-btn @click="backStep" :disabled="loading || appRequestingData" large>
          {{ $t("Onboarding.buttons.back") }}
        </v-btn>

        <v-btn @click="finish" :disabled="loading || appRequestingData" color="primary" large>
          {{ $t("Onboarding.buttons.finish") }}
        </v-btn>
      </div>
    </div>

    <v-progress-linear v-if="appRequestingData" color="primary" indeterminate />

    <div class="licenseServerContainer">
      <license-server
        @update:server="serverToggleOnline"
        @reset="resetLicense"
        :show-controllers="showControllers"
        :error="deployHasError"
        :server-status="serverStatus"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from "@vue/composition-api"
import { raiseConfirmation, raiseError, raiseSuccess } from "@/utils/event-bus"
import { createSpaceMetaData } from "@/views/workspaceEdit/lib/update-tab"
import { updateFavicon } from "@/views/workspaceEdit/lib/update-favicon"
import { translate } from "@/plugins/i18n"
import { LicenseModule } from "@/store/license-server"
import { WorkspaceTemplate, WorkspaceStatusEnum } from "@/types/workspace"
import { navigate } from "@/router"
import LicenseServer from "./LicenseServer.vue"
import router from "@/router"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "LicenseRoot",
  setup(_, ctx) {
    const ServerStatus = WorkspaceStatusEnum
    const serverStatus = ref(ServerStatus.Idle)
    const appRequestingData = ref(false)
    const licenseModule = LicenseModule()
    const deployHasError = ref(false)
    const preventMemoryLeak = ref(false)

    const loading = computed(() => {
      return licenseModule.lockLicense
    })

    const _setServerStatus = (status: WorkspaceStatusEnum) => {
      serverStatus.value = status
      _updateTabName()
    }

    const showControllers = computed(() => {
      const { Idle, Draft } = ServerStatus
      const idleList = [Idle, Draft]

      return idleList.includes(serverStatus.value)
    })

    const _sendToLicensePage = () => {
      raiseSuccess({ text: translate("Onboarding.licenseServer.warning.deployed") })

      setTimeout(() => {
        navigate({
          name: "CoreLicenses"
        })
      }, 2500)
    }

    const _updateTabName = () => {
      const route = router.currentRoute

      if (route.name === "Onboarding" && licenseModule.workspaceLogged) {
        const space = licenseModule.workspaceLogged

        if (Utils.isType<WorkspaceTemplate>(space, "id")) {
          const { title, online, status } = createSpaceMetaData(space)
          document.title = `${title} - ${translate(status)} ${online}`
        }
      }
    }

    const _updateDeployStatus = async () => {
      const workspace = await licenseModule.getWorkspaceDeployStatus()
      const { ...step } = WorkspaceStatusEnum

      _updateTabName()

      if (preventMemoryLeak.value) {
        return
      }

      ctx.emit("lock")
      appRequestingData.value = true
      deployHasError.value = false

      _setServerStatus(workspace.status)

      switch (workspace.status) {
        case step.Deploying:
        case step.Destroying:
          setTimeout(async () => {
            _updateDeployStatus()
          }, 2000)
          break

        case step.Deployed:
          appRequestingData.value = false
          deployHasError.value = false
          _sendToLicensePage()

          ctx.emit("unlock")
          break

        case step.DeployFailed:
        case step.ArchiveFailed:
          appRequestingData.value = false
          deployHasError.value = true

          ctx.emit("unlock")
          break

        case step.Draft:
        default:
          appRequestingData.value = false
          deployHasError.value = false
          _setServerStatus(ServerStatus.Idle)

          ctx.emit("unlock")
      }

      _updateTabName()
    }

    const backStep = () => {
      ctx.emit("back")
    }

    const finish = async () => {
      if (await raiseConfirmation({ text: translate("Onboarding.warning.confirm") })) {
        appRequestingData.value = true

        try {
          ctx.emit("lock")
          _setServerStatus(ServerStatus.Deploying)

          await licenseModule.registerLicense()
          _updateDeployStatus()
        } catch (err) {
          ctx.emit("unlock")
          appRequestingData.value = false
          _setServerStatus(ServerStatus.Draft)

          raiseError({ text: err as string, error: err })
        }
      }
    }

    const resetLicense = async () => {
      try {
        appRequestingData.value = true
        await licenseModule.resetLicense()
        _updateDeployStatus()
      } catch (err) {
        raiseError({ text: err as string, error: err })
      } finally {
        appRequestingData.value = false
      }
    }

    const serverToggleOnline = (status: boolean) => {
      console.log(`Status updated with: ${status}`)
    }

    onBeforeUnmount(() => {
      preventMemoryLeak.value = true
      updateFavicon("default")
    })

    onMounted(() => {
      _updateDeployStatus()
    })

    return {
      appRequestingData,
      serverStatus,
      loading,
      deployHasError,
      showControllers,
      resetLicense,
      backStep,
      finish,
      serverToggleOnline,
      ...WorkspaceStatusEnum
    }
  },
  components: {
    LicenseServer
  }
})
</script>
