import { WorkspaceTemplate } from "@/types/workspace"
import Utils from "@/utils/utils"

export const createWorkspaceDescription = (workspace: WorkspaceTemplate) => {
  if (workspace.description?.trim()) {
    return workspace.description.trim()
  } else {
    const { creationDate } = workspace

    return Utils.dateWithNamedMonth(creationDate)
  }
}
