import { Dictionary } from "vue-router/types/router"
import { StateChange, WorkspaceTemplate } from "@/types/workspace"
import * as SpeculationTime from "@/config/app-timer-speculation"
import Utils from "@/utils/utils"
import { WorkspaceStates } from "@/config/workspace-states"

interface SnippetFormat {
  time: string
  state: string
  title: string
}

export const getBrowserTitle = (space: WorkspaceTemplate): SnippetFormat => {
  const isDeploying = space.status === "deploying"
  const isDestroying = space.status === "destroying"
  const loadingStatesWithNoTimer = WorkspaceStates.isLoading(space.status)

  if (isDeploying) {
    return _getIsDeployingTitle(space)
  } else if (isDestroying) {
    return _getIsDestroyingTitle(space)
  } else if (loadingStatesWithNoTimer) {
    return _adaptTitleParts(space.status, space.title)
  } else if (space.status === "draft") {
    return _adaptTitleParts("draft", space.title)
  } else {
    switch (space.onlineStatus) {
      case "starting":
        return _getTurningOnTitle(space)
      case "stopping":
        return _getTurningOffTitle(space)
      case "online":
        return _getIsOnlineTitle(space)
      default:
        return _adaptTitleParts("offline", space.title)
    }
  }
}

const _getTurningOnTitle = (space: WorkspaceTemplate): SnippetFormat => {
  const { estimatedFinishTime } = _getBasicData(space, SpeculationTime.STARTING_TIMER)
  return _doRangeLoop("starting", space.title, estimatedFinishTime, "countDown")
}

const _getTurningOffTitle = (space: WorkspaceTemplate): SnippetFormat => {
  const { estimatedFinishTime } = _getBasicData(space, SpeculationTime.STOPPING_TIMER)
  return _doRangeLoop("stopping", space.title, estimatedFinishTime, "countDown")
}

const _getIsDeployingTitle = (space: WorkspaceTemplate): SnippetFormat => {
  const { estimatedFinishTime } = _getBasicData(space, SpeculationTime.DEPLOYING_TIMER)
  return _doRangeLoop("deploying", space.title, estimatedFinishTime, "countDown")
}

const _getIsDestroyingTitle = (space: WorkspaceTemplate): SnippetFormat => {
  const { estimatedFinishTime } = _getBasicData(space, SpeculationTime.DESTROYING_TIMER)
  return _doRangeLoop("destroying", space.title, estimatedFinishTime, "countDown")
}

const _getIsOnlineTitle = (space: WorkspaceTemplate): SnippetFormat => {
  const { lastUpdated } = _getBasicData(space)
  return _doRangeLoop("online", space.title, lastUpdated, "uptime")
}

const _doRangeLoop = (status: string, title: string, targetDate: number, rangeType: "countDown" | "uptime") => {
  const rangeCountDown = Utils.dateRange(Date.now(), targetDate) as Dictionary<number>
  const rangeUptime = Utils.dateRange(targetDate, Date.now()) as Dictionary<number>
  const estimateSymbol = rangeType === "countDown" ? "~ " : ""
  const range = rangeType === "countDown" ? rangeCountDown : rangeUptime
  const [timeName, timeLeft] = _getLargestTimeUnit(range)

  if (timeName !== "sec" && timeLeft > 0) {
    return _adaptTitleParts(status, title, `${estimateSymbol}${_addPad(timeLeft)} ${timeName}`)
  } else if (timeName === "sec" && timeLeft > 5) {
    return _adaptTitleParts(status, title, `${estimateSymbol}${_addPad(timeLeft)} ${timeName}`)
  } else {
    return _adaptTitleParts(status, title, `${estimateSymbol}05 sec`)
  }
}

/**
 * This method assumes you're using Date.dateRange method to
 * get the largest time unit.
 *
 * @returns ["days", int] | ["hours", int] | ["min", int] | ["sec", int]
 *
 * @example
 * _getLargestTimeUnit(Utils.dateRange(Utils.days(5))): ["days", 5]
 *
 * const time = Utils.hours(2) + Utils.seconds(30);
 * _getLargestTimeUnit(Utils.dateRange(time)): ["hours", 2]
 */
const _getLargestTimeUnit = (range: Dictionary<number>): [string, number] => {
  for (const key of Object.keys(range)) {
    if (range[key] > 0) {
      return [key, range[key]]
    }
  }
  return ["", 0]
}

const _getBasicData = (space: WorkspaceTemplate, estimate = 0) => {
  const stateChange = space.stateChange as StateChange
  const lastUpdated = new Date(stateChange?.changeDate).getTime()
  const estimatedFinishTime = lastUpdated + estimate

  return {
    lastUpdated,
    estimatedFinishTime
  }
}

const _addPad = (int: number) => `${int}`.padStart(2, "0")

const _adaptTitleParts = (status: string, title: string, time?: string): SnippetFormat => {
  const useTime = time ? `${time}` : ""

  return {
    time: useTime,
    state: status,
    title: title
  }
}
