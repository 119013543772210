<template>
  <div id="forbiddenContent">
    <navigate-back :key="organizationId" :organization-id="organizationId" />

    <div class="backgroundWrapper message">
      <h2>{{ $t("ForbiddenContent.title") }}</h2>

      <p>
        {{ $t("ForbiddenContent.subtitle") }}
      </p>

      <v-btn @click="goToSpaces" color="primary" class="mt-3" large>
        <span>{{ $t("ForbiddenContent.button") }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "@vue/composition-api"
import { UsersModule } from "@/store/users"
import { navigate } from "@/router"
import NavigateBack from "@/components/navigateBack/NavigateBack.vue"
import "./scss/_forbiddenContent.scss"

export default defineComponent({
  name: "ForbiddenContent",
  setup() {
    const usersModule = UsersModule()

    const organizationId = computed(() => {
      return usersModule.selectedOrganizationId
    })

    const goToSpaces = () => {
      navigate({ name: "WorkspacesList" })
    }

    return {
      goToSpaces,
      organizationId
    }
  },
  components: {
    NavigateBack
  }
})
</script>
