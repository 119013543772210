<template>
  <div id="emptyList" class="backgroundWrapper">
    <h3>{{ $t("workspaces.emptyListLabel.assignmentRequest") }}</h3>
    <h3>{{ $t("workspaces.emptyListLabel.adminRequest") }}</h3>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "EmptyList"
})
</script>
