<template>
  <v-menu
    v-model="isSearchingOrCreating"
    @input="clearSettings"
    :close-on-content-click="false"
    :nudge-bottom="35"
    :key="refKey"
    content-class="tagSearchField"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" class="wrapTagSearchControllers">
        <v-btn
          v-if="tagsCreated === 0 && !isSearchingOrCreating"
          v-on="on"
          @click="startSearching"
          :loading="loading"
          :disabled="disabled"
          large
        >
          <v-icon v-if="icon" :size="16" left>{{ icon }}</v-icon>
          <span>{{ label }}</span>
        </v-btn>

        <v-btn v-else v-on="on" @click="startSearching" :disabled="disabled" :loading="loading" class="iconButton" icon>
          <v-icon :size="16"> action_add_circle </v-icon>
        </v-btn>

        <preview-pill v-if="tagName" :name="tagName" />
      </div>
    </template>

    <div v-if="atStage(SelectingStage.SEARCHING)" class="tagSearchListMenu">
      <div class="searchSection" :has-options="searchedItems.length > 0">
        <input
          v-model="searchSnippet"
          @keydown.enter="confirmCreate"
          @input="updateTagName"
          :disabled="loading"
          :placeholder="placeholder"
          ref-key="searchInput"
          class="searchInput"
        />

        <button @click="changeStateToSetValue" :disabled="loading || !validName" status="success" class="iconEnter">
          <v-icon>mdi-check</v-icon>
        </button>
      </div>

      <tag-list
        v-if="searchedItems.length"
        v-model="tagName"
        @change="confirmNextStage"
        :used-tags="usedTags"
        :items="searchedItems"
      />
    </div>

    <div v-else-if="atStage(SelectingStage.SETTING)" class="tagSetValue">
      <handle-selector v-if="isSearchingOrCreating" @edit="setValue" :loading="loading" :settings="settings" />
    </div>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { SelectingStage } from "../lib/type"
import { MandatoryTextField } from "@/utils/input-rules"
import { translate } from "@/plugins/i18n"
import { OrganizationModule } from "@/store"
import { SpaceTag } from "@/types/workspace"
import TagList from "./components/_TagList.vue"
import PreviewPill from "./components/_PreviewPill.vue"
import Utils from "@/utils/utils"
import HandleSelector from "./components/_HandleSelector.vue"
import "./tagSearchField.scss"

export default defineComponent({
  name: "TagSearchField",
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<string[]>,
      required: true
    },
    tagsCreated: {
      type: Number,
      required: true
    },
    usedTags: {
      type: Array as PropType<SpaceTag[]>,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    },
    forbiddenNames: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const _tempTagName = ref("")
    const organizationModule = OrganizationModule()
    const isSearchingOrCreating = ref(false)
    const searchingStage = ref(SelectingStage.SEARCHING)
    const searchSnippet = ref("")
    const tagName = ref("")
    const refKey = ref(0)

    const validName = computed(() => {
      const basicNameRule = MandatoryTextField[0]
      const forbiddenRule = _validateForbiddenName
      const rules = [basicNameRule, forbiddenRule]

      return rules.every(rule => {
        return rule(_tempTagName.value) === true || rule(searchSnippet.value) === true
      })
    })

    const startedKeyCreation = computed(() => {
      const noTagNameSelected = !tagName.value
      const noTagOption = searchedItems.value.length === 0

      return noTagNameSelected && noTagOption
    })

    const settings = computed(() => {
      const tagSpaceConfig = organizationModule.organizationDetail?.spaceTagConfigurations

      if (tagSpaceConfig && tagSpaceConfig?.allowedTags?.length) {
        const tags = tagSpaceConfig.allowedTags
        const tag = tags.find(tag => tag.name === tagName.value)

        if (tag) {
          return tag.value
        }
      }

      return null
    })

    const searchedItems = computed(() => {
      const search = searchSnippet.value

      if (search) {
        return props.items.filter(tagItem => {
          return Utils.filterCompare(tagItem, search)
        })
      } else {
        return props.items
      }
    })

    const placeholder = computed(() => {
      const prefix = "TagPill."
      const emptyOptions = searchedItems.value.length === 0
      const createTag = translate(prefix + "createTagPlaceholder")
      const searchOption = translate(prefix + "searchPlaceholder")

      return emptyOptions ? createTag : searchOption
    })

    const _validateForbiddenName = (tagName: string) => {
      const forbiddenNames = props.forbiddenNames

      if (forbiddenNames.length > 0) {
        const _lower = (word: string) => word.toLocaleLowerCase().trim()
        return forbiddenNames.every(name => _lower(name) !== _lower(tagName))
      }

      return true
    }

    const updateTagName = () => {
      if (searchSnippet.value && searchedItems.value.length === 0) {
        _tempTagName.value = searchSnippet.value
      } else {
        _tempTagName.value = ""
      }
    }

    const startSearching = () => {
      isSearchingOrCreating.value = true
      searchingStage.value = SelectingStage.SEARCHING
      searchSnippet.value = ""
      _focusInput()
    }

    const atStage = (expectingStage: SelectingStage) => {
      return expectingStage === searchingStage.value
    }

    const setValue = (tagValue: string) => {
      ctx.emit("create", {
        name: tagName.value,
        value: tagValue
      })

      isSearchingOrCreating.value = false
      tagName.value = ""
      _tempTagName.value = ""
      _updateComponentWrapper()
    }

    const changeStateToSetValue = () => {
      tagName.value = searchSnippet.value
      confirmNextStage()
    }

    const confirmCreate = () => {
      if (searchedItems.value.length === 0 && validName.value) {
        changeStateToSetValue()
      }
    }

    const confirmNextStage = () => {
      searchingStage.value = SelectingStage.SETTING
    }

    const clearSettings = () => {
      _tempTagName.value = ""
      tagName.value = ""
      searchSnippet.value = ""
    }

    const _updateComponentWrapper = () => {
      refKey.value++
    }

    const _focusInput = () => {
      setTimeout(() => {
        const $searchInput = document.querySelector("[ref-key='searchInput']") as HTMLElement | null

        if ($searchInput) {
          $searchInput.focus()
        }
      }, 100)
    }

    return {
      _tempTagName,
      placeholder,
      updateTagName,
      confirmCreate,
      clearSettings,
      refKey,
      validName,
      changeStateToSetValue,
      setValue,
      SelectingStage,
      tagName,
      atStage,
      startedKeyCreation,
      confirmNextStage,
      settings,
      isSearchingOrCreating,
      startSearching,
      searchedItems,
      searchSnippet,
      searchingStage
    }
  },
  components: {
    PreviewPill,
    HandleSelector,
    TagList
  }
})
</script>
../lib/type
