<template>
  <div class="warnChanges">
    <div class="wrapAlert">
      <span class="leftBox">
        <v-icon color="warning" left>status_warning</v-icon>

        <span>
          {{ $t("WorkspaceEdit.appDetails.alert.warnChanges") }}
        </span>
      </span>

      <div class="rightBox">
        <v-btn @click="confirmBeforeDiscard" :disabled="loading" text>
          <v-icon left>action_reset</v-icon>
          <span>{{ $t("WorkspaceEdit.appDetails.buttons.resetChanges") }}</span>
        </v-btn>

        <v-btn @click="toggleViewChanges" :color="viewChanges ? 'primary' : ''">
          <v-icon left>{{ viewChanges ? "status_visibility_off" : "status_visibility_on" }}</v-icon>

          <span v-if="viewChanges">{{ $t("WorkspaceEdit.appDetails.buttons.seeCurrent") }}</span>
          <span v-else>{{ $t("WorkspaceEdit.appDetails.buttons.seeChanges") }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { translate } from "@/plugins/i18n"
import { raiseConfirmation } from "@/utils/event-bus"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "WarnChanges",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const viewChanges = Utils.vModel(props, ctx.emit)

    const toggleViewChanges = () => {
      viewChanges.value = !viewChanges.value
    }

    const confirmBeforeDiscard = async () => {
      const snippet = translate("WorkspaceEdit.appDetails.confirm.resetChanges")

      if (await raiseConfirmation({ text: snippet })) {
        ctx.emit("discard:app-changes")
      }
    }

    return {
      confirmBeforeDiscard,
      viewChanges,
      toggleViewChanges
    }
  }
})
</script>
