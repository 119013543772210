import FormFactoryComponent from "./FormFactoryRoot.vue"
import { getProduct, populateProduct } from "./lib/payload-adaptor"
import { Director, StructureCollection } from "./lib/type"
import { Rule } from "@/utils/input-rules"

import {
  ComboboxComponent,
  DateComponent,
  SelectComponent,
  TextComponent,
  IterableComponent,
  DescriptionComponent,
  CheckboxComponent,
  StructureCollectionComponent
} from "./lib/component-builder"

export { Director } from "./lib/type"

interface SimplifiedInputConfig<T = string> {
  key: string
  value?: T
  label?: string
  rules?: Rule[]
  placeholder?: string
}

interface SimplifiedBoxConfig {
  key: string
  collection: Director | StructureCollection
  label?: string
  isCollectionStructure?: boolean
  containerClass?: string
}

export const FormGenerator = {
  getProduct: getProduct,
  components: {
    text: TextComponent,
    select: SelectComponent,
    date: DateComponent,
    combobox: ComboboxComponent,
    description: DescriptionComponent,
    checkbox: CheckboxComponent,
    group: IterableComponent,
    collection: StructureCollectionComponent
  }
}

export {
  TextComponent,
  SelectComponent,
  DateComponent,
  DescriptionComponent,
  ComboboxComponent,
  CheckboxComponent,
  IterableComponent,
  StructureCollectionComponent
} from "./lib/component-builder"

export { getProduct, populateProduct }

export default FormFactoryComponent

const ForComplexStructures = {
  /**
   * This returns the exact same array list but makes it easier to read
   * and identify a new row, instead of an array.
   *
   * @example
   * Row([
   *   Text({ key: "key", label: "Key" }),
   *   Text({ key: "text", label: "Asset Title" }),
   *   Text({ key: "icon", label: "Icon" })
   * ])
   */
  Row: <T>(director: Array<T>) => director,

  /**
   * This returns the exact same list but wraps into an array, this is
   * best used inside List components.
   *
   * @example
   * List(
   *   Column([
   *     Text({ key: "code", label: "Code" })
   *   ])
   * )
   */
  Column: <T>(director: Array<T>) => [director],

  /**
   * Input of type TextComponent with simplified params.
   *
   * @example
   * Text({ key: "code", label: "Code" })
   */
  Text: (config: SimplifiedInputConfig) => TextComponent(config.key, config.value ?? "", { grow: true, ...config }),

  /**
   * Input of type DescriptionComponent with simplified params.
   *
   * @example
   * Description({ key: "code", label: "Code" })
   */
  Description: (config: SimplifiedInputConfig) =>
    DescriptionComponent(config.key, config.value ?? "", { grow: true, ...config }),

  /**
   * Input of type CheckboxComponent with simplified params.
   *
   * @example
   * Description({ key: "requireLicenseServer", label: "Require License Server" })
   */
  Check: (config: SimplifiedInputConfig<boolean>) =>
    CheckboxComponent(config.key, config.value ?? false, { grow: true, ...config }),

  /**
   * Box is an IterableComponent, it's responsible for wrapping iterable
   * structures such as objects and arrays with infinite nesting.
   *
   * When used with List component, the FormFactory component will automatically
   * include duplicate buttons on array structures.
   *
   * @example
   * Row([
   *  Box({
   *    key: "defaultInstanceType",
   *    label: "Default Instance Type",
   *      collection: List(
   *        Column([
   *          Text({ key: "name", label: "Name" }),
   *          Box({
   *            key: "instanceTypes",
   *            label: "Instance Types",
   *            collection: List(Column([Text({ key: "code", label: "Code" })]))
   *          })
   *        ])
   *      )
   *    })
   * ])
   */
  Box: (config: SimplifiedBoxConfig) =>
    IterableComponent(config.key, config.collection, { grow: true, isCollectionStructure: true, ...config }),

  /**
   * List is of type StructureCollectionComponent, responsible for wrapping
   * array type structures.
   *
   * @example
   * Row([
   *   Box({
   *     key: "defaultApplicationLinks",
   *     label: "Default Application Links",
   *     containerClass: "alignColumns",
   *     collection: List(
   *       Column([
   *         Text({ key: "link", label: "Link" }),
   *         Text({ key: "label", label: "Label" })
   *       ])
   *     )
   *   })
   * ])
   */
  List: (director: Director) => StructureCollectionComponent(director)
}

export { ForComplexStructures }

export const Translation = (translationTree: string) => (snippet: string) => {
  const _tree = translationTree
  const formFactoryTranslationPrefix = "$t"
  const translationTreeStructure = _tree.slice(-1) === "." ? _tree.slice(0, _tree.length - 1) : _tree

  return formFactoryTranslationPrefix + `${translationTreeStructure}.${snippet}`
}
