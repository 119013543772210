<template>
  <div class="organizationCard sslDetails">
    <h3>
      <span>{{ t("title") }}</span>

      <tooltip :text="t('whyDoINeedItTooltip')">
        <small>{{ t("whyDoINeedIt") }}</small>
      </tooltip>
    </h3>

    <div v-if="certificateFailedMessage" class="errorBox">
      <v-icon :size="24" color="error">action_remove_circle</v-icon>

      <div class="content">
        <p>
          {{ certificateFailedMessage }}
        </p>

        <v-btn @click="renewCertificate" :loading="loader.on" color="primary" large>
          <span>{{ t("retry") }}</span>
        </v-btn>
      </div>
    </div>

    <div v-if="certificateStatus" class="certificateStatus">
      <v-label>
        <span>{{ t("certificateStatus") }}</span>

        <strong :class="`--status-${certificateStatus}`">
          {{ t(`status.${certificateStatus}`) }}
        </strong>
      </v-label>
    </div>

    <div v-if="!isValidatingCertificate" class="sslExpiresAt">
      <v-label>
        <span>{{ t("expiresAt") }}</span>

        <strong v-if="certificateIsExpired" class="expired">Expired</strong>

        <strong v-else :class="`--visible-state-${visibleState} daysRemaining`">
          <span>{{ certificateExpiresDate }}</span>
          <small v-if="certificateDaysRemaining">{{ certificateDaysRemaining }}</small>
        </strong>
      </v-label>
    </div>

    <v-btn v-if="showRenewButton" @click="renewCertificate" :loading="loader.on" color="primary" large>
      {{ t("renew") }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { createTranslationModule, translatePlural } from "@/plugins/i18n"
import { CertificateStatus, CertificateVisibleState } from "@/types/workspace"
import { OrganizationCertificateStatus } from "@/types/organization"
import { useStore } from "@/composable/useStore"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "SslDetails",
  setup() {
    const { adminModule, organizationModule } = useStore()
    const t = createTranslationModule("OrganizationPageV2.ssl")
    const loader = Loader()

    const organization = computed(() => {
      return organizationModule.organizationDetail
    })

    const certificate = computed(() => {
      return organization.value.certificateInfo
    })

    const certificateFailedMessage = computed(() => {
      const { failed } = CertificateStatus

      if (certificate.value?.status === failed) {
        const defaultErrorMessage = t("defaultErrorMessage")
        return certificate.value.certificateError || defaultErrorMessage
      }

      return false
    })

    const certificateIsExpired = computed(() => {
      if (certificate.value?.expiresAt) {
        return Date.now() >= new Date(certificate.value.expiresAt).getTime()
      }

      return true
    })

    const certificateExpiresDate = computed(() => {
      const expireDate = certificate.value?.expiresAt

      if (expireDate) {
        return Utils.dateWithNamedMonth(expireDate)
      }

      return t("never")
    })

    const visibleState = computed(() => {
      return certificate.value?.visibleState
    })

    const certificateStatus = computed(() => {
      const { validating, failed } = OrganizationCertificateStatus
      const status = (certificate.value?.status ?? "") as OrganizationCertificateStatus

      if ([validating, failed].includes(status)) {
        return certificate.value?.status
      }

      return false
    })

    const isValidatingCertificate = computed(() => {
      return certificate.value?.status === CertificateStatus.validating
    })

    const showRenewButton = computed(() => {
      const { danger, warning } = CertificateVisibleState

      if (certificate.value?.visibleState) {
        const showRenew = [danger, warning].includes(certificate.value?.visibleState)
        const noErrors = Boolean(!certificateFailedMessage.value)

        return showRenew && noErrors
      }

      return false
    })

    const certificateDaysRemaining = computed(() => {
      if (!certificateIsExpired.value && certificate.value?.expiresAt) {
        const days = Utils.dateRange(Date.now(), certificate.value.expiresAt).days
        return translatePlural("OrganizationPageV2.ssl.daysRemaining", days, { daysRemaining: `${days}` })
      }

      return false
    })

    const renewCertificate = () => {
      if (showRenewButton.value || certificateFailedMessage.value) {
        loader.run(async () => {
          await adminModule.renewCertificate(organization.value.id)
          await organizationModule.getOrganizationDetail(organization.value.id)
        })
      }
    }

    return {
      t,
      certificateDaysRemaining,
      certificateStatus,
      isValidatingCertificate,
      renewCertificate,
      loader,
      showRenewButton,
      visibleState,
      certificateExpiresDate,
      certificateIsExpired,
      certificateFailedMessage,
      organization
    }
  },
  components: {
    Tooltip
  }
})
</script>
