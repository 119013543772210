import { ref, computed } from "@vue/composition-api"
import { eventBus } from "@/main"
import { CONFIG, FLAGS, COLORS } from "./config"
import { RequestErrorResponse } from "@/types/core"
import Utils from "@/utils/utils"

export interface AlertParams {
  text?: string
  timeout?: number
  icon?: string | false
  error?: unknown
}

export interface MandatoryAlertParam extends AlertParams {
  text: string
}

interface MandatoryConfig {
  text: string | RequestErrorResponse
  timeout: number
  icon: string | false
}

const Setup = () => {
  const show = ref(false)
  const showDetails = ref(false)
  const text = ref("")
  const detailsText = ref("")
  const icon = ref<string | false>(false)
  const timeout = ref(1500)
  const flagStatus = ref(0)

  const hasDetailText = computed(() => {
    return Boolean(detailsText.value)
  })

  const detailsIcon = computed(() => {
    return showDetails.value || smallDetail.value ? "navigate_up" : "navigate_down"
  })

  const smallDetail = computed(() => {
    return detailsText.value && detailsText.value.length < 35
  })

  const _bisectText = (text: string | RequestErrorResponse) => {
    const error = Utils.isType<RequestErrorResponse>(text, "message")

    if (error) {
      detailsText.value = error.details || ""
      return error.message
    }

    return text as string
  }

  const _resetCachedData = () => {
    showDetails.value = false
    detailsText.value = ""
  }

  const _setConfig = (config: MandatoryConfig) => {
    icon.value = config.icon ?? false
    timeout.value = config.timeout
    text.value = _bisectText(config.text) ?? CONFIG[flagStatus.value]().text
  }

  const _setAlert = (feedback: AlertParams) => {
    _resetCachedData()

    const defaultArgs = CONFIG[flagStatus.value]()
    const args = { ...defaultArgs, ...feedback }
    const config = Utils.isType<MandatoryConfig>(args, ["text", "icon", "timeout"])

    if (config) {
      show.value = true
      _setConfig(config)
    }
  }

  const raiseError = (feedback?: AlertParams) => {
    flagStatus.value = FLAGS.error
    _setAlert(feedback ?? {})
  }

  const raiseSuccess = (feedback?: AlertParams) => {
    flagStatus.value = FLAGS.success
    _setAlert(feedback ?? {})
  }

  const raiseInfo = (feedback: MandatoryAlertParam) => {
    flagStatus.value = FLAGS.info
    _setAlert(feedback ?? {})
  }

  const raiseWarning = (feedback: MandatoryAlertParam) => {
    flagStatus.value = FLAGS.warning
    _setAlert(feedback ?? {})
  }

  const alertColor = computed(() => {
    return COLORS[flagStatus.value]
  })

  eventBus.$on("alert:success", raiseSuccess)
  eventBus.$on("alert:error", raiseError)
  eventBus.$on("alert:info", raiseInfo)
  eventBus.$on("alert:warning", raiseWarning)

  return {
    smallDetail,
    show,
    detailsIcon,
    showDetails,
    text,
    detailsText,
    timeout,
    hasDetailText,
    icon,
    color: alertColor
  }
}

export default Setup
