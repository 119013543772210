<template>
  <div id="preHeader">
    <div class="containBoxes">
      <div class="leftBox">
        <div class="titleContainer animateFadeUp animationDelay-5">
          <img :src="cloudIcon" />

          <h3>{{ $t("LicenseCore.title") }}</h3>
        </div>
      </div>

      <div class="rightBox">
        <status-display @change:app-status="changeAppStatus" @abort="$emit('abort')" :workspace="workspace" />
        <meta-data :workspace="workspace" :hideData="['template']" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { workspaceStatusIcon } from "@/utils/cloud-state-icons"
import StatusDisplay from "@/views/workspaceEdit/components/preHeader/StatusDisplay.vue"
import MetaData from "@/views/workspaceEdit/components/preHeader/Metadata.vue"

export default defineComponent({
  name: "PreHeaderRoot",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const save = (_workspace = props.workspace) => {
      ctx.emit("save", _workspace)
    }

    const cloudIcon = computed(() => {
      return workspaceStatusIcon(props.workspace)
    })

    const changeAppStatus = (state: "online" | "offline") => {
      ctx.emit("change:app-status", state)
    }

    return {
      changeAppStatus,
      cloudIcon,
      save
    }
  },
  components: {
    MetaData,
    StatusDisplay
  }
})
</script>
