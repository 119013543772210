import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSnackbar,{class:_vm.color,attrs:{"top":"","dismissible":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(_vm.hasDetailText)?_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":_vm.smallDetail,"icon":""},on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.detailsIcon))])],1):_vm._e(),_c(VBtn,_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("action_close")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[(_vm.icon)?_c(VIcon,[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.showDetails || _vm.smallDetail)?_c('div',{staticClass:"detailedError"},[_c(VBtn,{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.$t('Alert.copyErrorDetail')),expression:"$t('Alert.copyErrorDetail')"}],staticClass:"floatingCopy",attrs:{"icon":""},on:{"click":function($event){return _vm.copy(_vm.detailsText)}}},[_c(VIcon,[_vm._v("action_copy")])],1),_c('span',[_vm._v(" "+_vm._s(_vm.detailsText)+" ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }