<template>
  <ul v-if="filteredItem.length" class="tagListContainer" :key="refresher">
    <li
      v-for="(tag, index) in filteredItem"
      @click="selectClickedItem(index)"
      :key="`${tag}-${itemIsActive(index)}`"
      :hovering="isHovering(index)"
      :active="itemIsActive(index)"
    >
      <div class="leftBoxWrapper">
        <span class="itemLabel">{{ tag }}</span>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onBeforeUnmount, onMounted, PropType } from "@vue/composition-api"
import { SpaceTag } from "@/types/workspace"
import Utils from "@/utils/utils"
import SelectController from "../lib/select-controller"

export default defineComponent({
  name: "TagList",
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<string[]>,
      required: true
    },
    usedTags: {
      type: Array as PropType<SpaceTag[]>,
      required: true
    },
    autoSelect: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const tagName = Utils.vModel(props, ctx.emit)
    const _hoveringItemIndex = ref(-1)
    const _activeItem = ref(-1)
    const refresher = ref(0)

    const _translatedActiveCollection = computed(() => {
      return filteredItem.value[_activeItem.value]
    })

    const filteredItem = computed(() => {
      const items = props.items
      const tagsUsedName = props.usedTags.map(tag => tag.name)

      return items.filter(tag => !tagsUsedName.includes(tag))
    })

    const itemIsActive = (index: number) => {
      return _activeItem.value === index
    }

    const isHovering = (index: number) => {
      if (_hoveringItemIndex.value < 0 || _hoveringItemIndex.value >= props.items.length) {
        _findClosestTextOption()
      }

      return _hoveringItemIndex.value === index
    }

    const selectClickedItem = (index: number) => {
      _hoveringItemIndex.value = index
      _selectHoveredItem()
    }

    const _keyboardSelector = (event: KeyboardEvent) => {
      const keyCode = event.code

      if (SelectController.down.includes(keyCode)) {
        event.preventDefault()
        _hoveringIndexMoveDown()
      } else if (SelectController.up.includes(keyCode)) {
        event.preventDefault()
        _hoveringIndexMoveUp()
      } else if (SelectController.enter.includes(keyCode)) {
        event.preventDefault()
        _selectHoveredItem()
        ctx.emit("enter", tagName.value)
      }
    }

    const _selectHoveredItem = () => {
      _activeItem.value = _hoveringItemIndex.value

      ctx.emit("change")
      tagName.value = _translatedActiveCollection.value
      refresher.value++
    }

    const _hoveringIndexMoveUp = () => {
      if (_hoveringItemIndex.value === 0) {
        _hoveringItemIndex.value = props.items.length - 1
      } else {
        _hoveringItemIndex.value--
      }
    }

    const _hoveringIndexMoveDown = () => {
      const lastIndex = props.items.length - 1

      if (_hoveringItemIndex.value < lastIndex) {
        _hoveringItemIndex.value++
      } else {
        _hoveringItemIndex.value = 0
      }
    }

    const _hoverToSelectOption = () => {
      const indexOfItem = props.items.indexOf(tagName.value)
      _hoveringItemIndex.value = indexOfItem
    }

    const _findClosestTextOption = () => {
      if (props.autoSelect) {
        const _lower = (s: string) => s.trim().toLowerCase()
        _hoveringItemIndex.value = props.items.findIndex(tagValue => _lower(tagValue).includes(_lower(tagName.value)))
      }
    }

    document.addEventListener("keydown", _keyboardSelector)

    onBeforeUnmount(() => {
      document.removeEventListener("keydown", _keyboardSelector)
    })

    onMounted(() => {
      if (props.autoSelect) {
        _hoverToSelectOption()
      }
    })

    return {
      _hoveringItemIndex,
      filteredItem,
      refresher,
      selectClickedItem,
      isHovering,
      itemIsActive
    }
  }
})
</script>
