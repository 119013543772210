<template>
  <popup v-model="canShowPopup" :title="t('title')" width="500px" no-padding hide-footer>
    <div class="dcvInstructionContainer">
      <div class="wrapDescription">
        <p v-html="t('instruction', { appName })" />

        <p>
          <a :href="DOWNLOAD_NICE_DCV_LINK" target="_blank" rel="nofollow">
            {{ t("downloadBtnLabel") }}
          </a>
        </p>
      </div>

      <div class="footerController">
        <v-checkbox v-model="hideNextInstructions" :label="t('dontShow')" class="my-0 py-0" hide-details />

        <v-btn @click="registerCheckboxThenClose" :disabled="!hideNextInstructions" color="primary" large>
          {{ t("confirmBtn") }}
        </v-btn>
      </div>
    </div>
  </popup>
</template>

<script lang="ts">
import { defineComponent, ref, computed, nextTick } from "@vue/composition-api"
import { DOWNLOAD_NICE_DCV_LINK } from "../../lib/links"
import { setUserConfirmedDcvInstruction, getIfUserConfirmedDcvInstruction } from "../../lib/dcv-local-storage-helper"
import { Dictionary } from "vue-router/types/router"
import { translate } from "@/plugins/i18n"
import Popup from "@/components/popup/PopupRoot.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "DcvInstruction",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    appName: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const showPopup = Utils.vModel(props, ctx.emit)
    const hideNextInstructions = ref(false)

    const userPrefInstruction = computed(() => {
      return getIfUserConfirmedDcvInstruction()
    })

    const t = (snippet: string, options: Dictionary<string> = {}) => {
      return translate(["WorkspaceEdit.dcvInstruction", snippet].join("."), options)
    }

    const canShowPopup = computed({
      get() {
        return showPopup.value && !userPrefInstruction.value
      },
      set(value: boolean) {
        showPopup.value = value
      }
    })

    const registerCheckboxThenClose = () => {
      if (hideNextInstructions.value) {
        setUserConfirmedDcvInstruction()
        ctx.emit("on:confirm")

        nextTick(() => {
          showPopup.value = false
        })
      }
    }

    return {
      DOWNLOAD_NICE_DCV_LINK,
      t,
      registerCheckboxThenClose,
      userPrefInstruction,
      canShowPopup,
      hideNextInstructions,
      showPopup
    }
  },
  components: {
    Popup
  }
})
</script>
