import { session } from "./session"

export default session

/**
 * Checks token and returns token value
 * - Side-effect: When token is invalid gets signed out
 * - Side-effect: Dispatches event session:refresh to eventBus when authentication has new value
 *
 * @returns String
 */
export const sessionToken = async () => await session.fetchTokenSilently()

/**
 * Signs out from Auth0
 * Removes old authentication cached-in at localStorage, setting to isAuthenticated = false
 * Redirects to Login page after complete
 */
export const signOut = async () => await session.terminate()

/**
 * Checks session with Auth0
 * With side-effect, fixes route with user authentication permission
 *
 * @returns Boolean
 */
export const validateSession = async () => await session.validate()

/**
 * Returns the session.auth.isAuthenticated but validates with Auth0 token before
 * Dispatches event session:refresh to eventBus when authentication has new value
 *
 * @returns Boolean
 */
export const checkSession = async () => await session.isValidUser()

/**
 * Returns the session.auth.isAuthenticated flag, without updating Auth0 token
 *
 * @returns Boolean
 */
export const sessionOn = () => session.auth.isAuthenticated

/**
 * Useful to avoid using methods that are only available after Auth0 is initiated
 * When Auth0 connects, session.auth.initiated is set to true
 *
 * @returns Boolean
 */
export const sessionInitiated = () => session.auth.initiated
