import { SpaceTag } from "@/types/workspace"
import { Dictionary } from "vue-router/types/router"

interface Schemas {
  schema: {
    tags: SpaceTag[]
  }
  schemaDeployed: {
    tags: SpaceTag[]
  } | null
}

export const getTags = (schemas: Schemas) => {
  if (!schemas?.schema) {
    return {
      tagsDeployed: [],
      removedTags: [],
      tagsNotDeployed: [],
      tags: []
    }
  }

  const { schema, schemaDeployed } = schemas
  const _schemaDeployed = schemaDeployed || { tags: [] }

  const _cache = {
    atSchema: [] as string[],
    atDeployedSchema: [] as string[],
    tags: {} as Dictionary<Dictionary<SpaceTag>>
  }

  const cache = (key: "atSchema" | "atDeployedSchema", tag: SpaceTag) => {
    if (!_cache[key].includes(tag.name)) {
      _cache[key].push(tag.name)
    }

    if (!_cache.tags[tag.name]) {
      const obj = {} as Dictionary<SpaceTag>
      obj[key] = tag
      _cache.tags[tag.name] = obj
    } else {
      _cache.tags[tag.name][key] = tag
    }
  }

  const getCache = (key: "atSchema" | "atDeployedSchema" | "all") => {
    if (key === "all") {
      const tags = [..._cache.atSchema, ..._cache.atDeployedSchema]

      return tags.filter((tagName, index) => {
        return tags.indexOf(tagName) === index
      })
    }

    return _cache[key]
  }

  const getCachedTag = (tagName: string) => {
    return _cache.tags[tagName] as Dictionary<SpaceTag>
  }

  if (schema) {
    schema.tags.forEach(tag => {
      cache("atSchema", tag)
    })
  }
  if (schemaDeployed) {
    schemaDeployed.tags.forEach(tag => {
      cache("atDeployedSchema", tag)
    })
  }

  const removedTags = _schemaDeployed.tags.filter(tag => {
    const tagsFromSchema = getCache("atSchema")
    return !tagsFromSchema.includes(tag.name)
  })

  const tagsNotDeployed = schema.tags.filter(tag => {
    const tagsFromDeployedSchema = getCache("atDeployedSchema")
    const tagNotYetDeployed = !tagsFromDeployedSchema.includes(tag.name)

    const cachedTag = getCachedTag(tag.name)?.atDeployedSchema

    if (cachedTag) {
      const tagModified = tag.value !== cachedTag.value
      return tagModified
    }

    return tagNotYetDeployed
  })

  const tagsDeployed = _schemaDeployed.tags.filter(tag => {
    const tagsFromSchema = getCache("atSchema")
    return tagsFromSchema.includes(tag.name)
  })

  const tags = getCache("all").map(tagName => {
    return getCachedTag(tagName)?.atSchema || getCachedTag(tagName)?.atDeployedSchema
  })

  return {
    tagsDeployed,
    removedTags,
    tagsNotDeployed,
    tags
  }
}
