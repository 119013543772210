import { WorkspaceModule } from "@/store"
import { WorkspaceTemplate } from "@/types/workspace"
import { computed, watch } from "@vue/composition-api"

type Callback = (newSpace: WorkspaceTemplate, oldSpace: WorkspaceTemplate) => Promise<void> | void

export const useSpaceWatcher = () => {
  const workspaceModule = WorkspaceModule()
  const callbackStack: Callback[] = []

  const workspace = computed(() => {
    return workspaceModule.watching
  })

  const onStatusChange = (callback: Callback) => {
    callbackStack.push(callback)
  }

  watch(workspace, (newSpace, oldSpace) => {
    const statusChanged = newSpace.status !== oldSpace.status
    const onlineStatusChanged = newSpace.onlineStatus !== oldSpace.onlineStatus
    const validChange = Boolean(newSpace?.status && oldSpace?.status)

    if (validChange && (statusChanged || onlineStatusChanged)) {
      callbackStack.forEach(callback => callback(newSpace, oldSpace))
    }
  })

  return {
    onStatusChange
  }
}
