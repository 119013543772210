<template>
  <v-menu offset-y content-class="bar_menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-avatar color="#384db1">
          <span style="color: var(--viz-white)">{{ userInitials }}</span>
        </v-avatar>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="$emit('logout')">
        <v-list-item-icon><v-icon>action_power_on_off</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("login.signOut") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "@vue/composition-api"
import { IUser } from "@/types/user"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "UserMenu",
  props: {
    user: {
      type: Object as PropType<IUser>,
      required: true
    }
  },
  setup(props) {
    const userInitials = computed(() => {
      return Utils.getInitials(props.user)
    })

    return {
      userInitials
    }
  }
})
</script>
