export default class DomTools {
  private static _error(complaint: string, cause: string) {
    throw Error(`DomTools Error: ${complaint} >> ${cause}`)
  }

  /**
   * Scrolls element into view, useful for forms
   *
   * @param elementAnchor Query select ".target" or "#target"
   * @param containerRef The container where the target should reside VForm Element should be fine
   * @example
   * const Form = ref<null | VForm>(null)
   *
   * const onSubmit = () => {
   *  const form = Form.value
   *
   *  if (form && form.validate()) {
   *    ctx.emit("submit")
   *  } else {
   *    DomTools.scrollElementIntoView(".text--error", form.$el)
   *  }
   * }
   *
   */
  public static scrollElementIntoView(elementAnchor: string, containerRef: Element) {
    if (containerRef) {
      const $element = containerRef.querySelector(elementAnchor)

      if ($element) {
        $element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      }

      return
    }

    this._error("Invalid Container Ref", `Received type: ${typeof containerRef}, expected Element`)
  }
}
