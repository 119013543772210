import { WorkspaceTemplate, WorkspaceStatusEnum } from "@/types/workspace"
import deployedOffline from "@/assets/ic-deployed-offline.svg"
import deployedOnline from "@/assets/ic-deployed-online.svg"
import deploying from "@/assets/ic-deploying.svg"
import destroying from "@/assets/ic-destroying.svg"
import draft from "@/assets/ic-draft.svg"
import failed from "@/assets/ic-failed.svg"
import archived from "@/assets/ic-archiving.svg"
import restoring from "@/assets/ic-restoring.svg"

const cloudIcons = {
  offline: deployedOffline,
  online: deployedOnline,
  deploying,
  destroying,
  draft,
  archived,
  restoring,
  failed
}

const {
  Deployed,
  DeployedWithError,
  Deploying,
  Destroying,
  Draft,
  Archiving,
  Archived,
  Restoring,
  ValidationNoAction
} = WorkspaceStatusEnum

export const isWorkspaceOnline = (workspace: WorkspaceTemplate) => {
  const status = workspace.status
  const onlineStatus = workspace.onlineStatus

  return status === (Deployed || DeployedWithError) && onlineStatus === "online"
}

export const workspaceStatusIcon = (workspace: WorkspaceTemplate, isOnline = isWorkspaceOnline(workspace)) => {
  const { online, offline, deploying, destroying, draft, failed, archived, restoring } = cloudIcons

  switch (workspace.status) {
    case Deployed:
    case DeployedWithError:
    case ValidationNoAction:
      return isOnline ? online : offline

    case Deploying:
      return deploying

    case Destroying:
      return destroying

    case Draft:
      return draft

    case Archived:
    case Archiving:
      return archived

    case Restoring:
      return restoring

    default:
      return failed
  }
}

export default cloudIcons
