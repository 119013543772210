<template>
  <div class="appsContainer">
    <div class="appContainerHeader">
      <search-app v-model="snippetSearch" @change="clearFilters('category')" />
    </div>

    <div class="containAppContent">
      <div class="sidebarFilter">
        <categories v-model="categoryTagsSelected" @change="clearFilters('snippet')" :items="appsInCategories" />
      </div>

      <div class="appList">
        <div
          v-for="(category, index) in filteredCategories"
          :class="`categoryRow animateFadeUp animationDelay-${index + 1}`"
          :key="category.key"
        >
          <span class="category">{{ displayCategory(category.key) }}</span>

          <div class="wrapCollection">
            <mini-app-card
              v-for="app in category.items"
              @click="toggleSelection(app)"
              :selected="appSelected(app)"
              :key="app.key"
              :app="app"
              :locked="!hasPermissionToAddApps"
            />
          </div>
        </div>

        <div v-if="filteredCollectionIsEmpty" class="emptyList py-0">
          <span>{{ $t("WorkspaceEdit.addApps.labels.empty") }}</span>
        </div>
      </div>
    </div>

    <div class="addAppsFooter">
      <v-btn @click="$emit('cancel')" large>{{ $t("WorkspaceEdit.addApps.buttons.cancel") }}</v-btn>

      <v-btn
        v-if="selectedApps.length && hasPermissionToAddApps"
        @click="addSelection"
        color="primary"
        class="animateFadeUp"
        rbt-target="addSelected"
        large
      >
        <span>{{ $t("WorkspaceEdit.addApps.buttons.add") }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "@vue/composition-api"
import { CategoryItem } from "../../../lib/types"
import { AppItem, WorkspaceTemplate } from "@/types/workspace"
import { WorkspaceModule, UsersModule } from "@/store"
import { Dictionary } from "@/types/core"
import MiniAppCard from "./MiniAppCard.vue"
import Categories from "./Categories.vue"
import SearchApp from "./Search.vue"

export default defineComponent({
  name: "AppsContainer",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    }
  },
  setup(props, ctx) {
    const workspaceModule = WorkspaceModule()
    const usersModule = UsersModule()
    const selectedApps = ref<AppItem[]>([])
    const categoryTagsSelected = ref<string[]>([])
    const snippetSearch = ref("")

    const exchangedApps = computed(() => {
      return selectedApps.value.map(asset => ({ assetModelType: asset.key }))
    })

    const filteredCollectionIsEmpty = computed(() => {
      return Object.values(filteredCategories.value).length === 0
    })

    const hasPermissionToAddApps = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const appsInCategories = computed(() => {
      const apps = workspaceModule.appItems

      return apps.reduce((categories, appItem) => {
        if (categories[appItem.category]) {
          categories[appItem.category].items.push(appItem)
        } else {
          categories[appItem.category] = {
            key: appItem.category,
            items: [appItem]
          }
        }

        return categories
      }, {} as Dictionary<CategoryItem>)
    })

    const displayCategory = (categoryKey: string) => {
      return categoryKey.replace(/[-]/g, " ")
    }

    const _compareSnippet = (textOne: string, textTwo: string) => {
      return textOne.toLowerCase().includes(textTwo.toLowerCase())
    }

    const _filterTags = () => {
      const tags = categoryTagsSelected.value
      const filter = {} as Dictionary<CategoryItem>

      if (tags.length) {
        for (const key in appsInCategories.value) {
          const category = appsInCategories.value[key]

          if (category && tags.includes(category.key)) {
            filter[key] = category
          }
        }
      }

      return filter
    }

    const _filterSnippet = () => {
      const filter = {} as Dictionary<CategoryItem>
      const snippet = snippetSearch.value

      for (const key in appsInCategories.value) {
        const category = appsInCategories.value[key]

        if (category) {
          const matchesText = category.items.some(app => _compareSnippet(app.text, snippet))

          if (matchesText) {
            filter[key] = category
          }
        }
      }

      return filter
    }

    const filteredCategories = computed(() => {
      if (categoryTagsSelected.value.length > 0) {
        return _filterTags()
      }

      if (snippetSearch.value) {
        return _filterSnippet()
      }

      return appsInCategories.value
    })

    const clearFilters = (clearableType: "category" | "snippet") => {
      switch (clearableType) {
        case "category":
          categoryTagsSelected.value = []
          break
        case "snippet":
          snippetSearch.value = ""
          break
      }
    }

    const appSelected = (app: AppItem) => {
      return Boolean(selectedApps.value.find(_app => _app.key === app.key))
    }

    const toggleSelection = (app: AppItem) => {
      const selectedIndex = selectedApps.value.findIndex(_app => _app.key === app.key)

      if (selectedIndex === -1) {
        selectedApps.value.push(app)
      } else {
        selectedApps.value.splice(selectedIndex, 1)
      }
    }

    const addSelection = () => {
      ctx.emit("add:apps", exchangedApps.value)
      selectedApps.value = []
    }

    return {
      categoryTagsSelected,
      selectedApps,
      displayCategory,
      filteredCollectionIsEmpty,
      hasPermissionToAddApps,
      clearFilters,
      filteredCategories,
      snippetSearch,
      appsInCategories,
      appSelected,
      toggleSelection,
      addSelection
    }
  },
  components: {
    SearchApp,
    Categories,
    MiniAppCard
  }
})
</script>
