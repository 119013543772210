<template>
  <div id="workspaceEditV2" class="coreLicense">
    <navigate-back :key="workspace.organizationId" :organization-id="workspace.organizationId" />

    <div v-if="!loader.on && workspace" class="workspaceContent">
      <pre-header
        @change:app-status="changeAppStatus"
        @abort="abortChanges"
        :workspace="workspace"
        :key="workspaceStatus"
        :loading="workspaceLoading.on"
      />

      <header-controller
        v-model="addAppOpened"
        @add:ip="addIp"
        @remove:ip="removeIp"
        @add:apps="addApps"
        @destroy="destroySelf"
        @redeploy="redeploy"
        :workspace="workspace"
        :loading="workspaceLoading.on"
        :locked="appLocked"
        :licenses="licenseProducts"
      />

      <v-progress-linear v-if="workspaceLoading.on" class="my-0" color="primary" indeterminate />

      <div class="backgroundWrapper">
        <workspace-alerts
          @unlock="unlockWorkspace"
          @unlock:workspace="unlockOnly"
          @redeploy="redeploy"
          @discard="discardChanges"
          @retry:destroy="unlockDestroy"
          @renew:certificate="renewCertificate"
          :workspace="workspace"
          :loading="workspaceLoading.on"
        />

        <cidr-alert @redeploy="redeploy" :feedback="workspace.updateWarning" />

        <app-container
          @set:online="setOnline"
          @copy:password="copyAppPassword"
          @open:app="openApp"
          @open:instance="openInstance"
          @download:shortcut="downloadShortcut"
          @add:apps="addAppOpened = true"
          :loading="workspaceLoading.on"
          :workspace="workspace"
          :locked="appLocked"
        />
      </div>
    </div>

    <div v-else class="wrapLoadingContent">
      <v-progress-linear color="primary" indeterminate />

      <div class="backgroundWrapper loadingLabel">
        <span>{{ $t("WorkspaceEdit.label.loading") }}</span>
        <span class="dot">.</span>
      </div>
    </div>

    <app-details
      v-if="analyzingAppDetails && appDetails"
      v-model="analyzingAppDetails"
      @set:online="setOnline"
      @save="updateApp"
      @remove="removeApp"
      @discard:app-changes="appDiscardChanges"
      @redeploy="redeploy"
      @app:restart="appRestart"
      :app="appDetails"
      :loading="workspaceLoading.on"
      :key="appRenderDetailsKey"
      :app-running-changes="appRunningChanges"
      :deployed-with-error="deployedWithError"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onBeforeUnmount, nextTick, provide } from "@vue/composition-api"
import { WorkspaceModule } from "@/store/workspace"
import { LicenseModule, AdminModule, UsersModule } from "@/store"
import { raiseConfirmation, raiseError, raiseSuccess, validateLicense } from "@/utils/event-bus"
import { RegisteredIp } from "@/types/core"
import { copyToClipboard } from "@/utils/ui"
import { translate } from "@/plugins/i18n"
import { createSpaceMetaData } from "@/views/workspaceEdit/lib/update-tab"
import { wizardAdaptor } from "@/views/workspaceEdit/lib/wizard-adaptor"
import { updateFavicon } from "@/views/workspaceEdit/lib/update-favicon"
import { WorkspaceStates } from "@/config/workspace-states"
import Router, { navigate } from "@/router"
import NavigateBack from "@/components/navigateBack/NavigateBack.vue"
import PreHeader from "./components/preHeader/PreHeaderRoot.vue"
import HeaderController from "./components/header/HeaderControllerRoot.vue"
import AppContainer from "@/views/workspaceEdit/components/appsContainer/AppContainerRoot.vue"
import AppDetails from "@/views/workspaceEdit/components/appDetails/AppDetailsRoot.vue"
import WorkspaceAlerts from "@/views/workspaceEdit/components/alerts/AlertsRoot.vue"
import CidrAlert from "./components/alerts/CidrAlert.vue"
import Utils from "@/utils/utils"
import Loader from "@/utils/loader"
import "@/views/workspaceEdit/scss/_workspaceEdit.scss"
import "./scss/_coreLicenses.scss"

import {
  WorkspaceTemplate,
  IWorkspaceAsset,
  WorkspaceOnlineStatusEnum,
  AddNewAssetPayload,
  WorkspaceStatusEnum
} from "@/types/workspace"

export default defineComponent({
  name: "CoreLicenseRoot",
  setup() {
    const loader = Loader({ alertOnError: false })
    const workspaceModule = WorkspaceModule()
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const licenseModule = LicenseModule()
    const workspaceLoading = Loader()
    const shouldRecursiveUpdate = ref(false)
    const recursiveFnRunning = ref(0)
    const analyzingAppDetails = ref(false)
    const addAppOpened = ref(false)
    const appRenderDetailsKey = ref(0)
    const ownIp = ref("")

    const workspace = computed(() => {
      return workspaceModule.watching
    })

    const licenseId = computed(() => {
      return licenseModule.currentLicense.id
    })

    const appDetails = computed(() => {
      const appId = workspaceModule.appIdBeingEdited

      if (appId) {
        return workspace.value.schema.assets.find(asset => asset.id === appId)
      }

      return null
    })

    const inRunningState = computed(() => {
      return WorkspaceStates.isLoading(workspace.value.status)
    })

    const appLocked = computed(() => {
      return WorkspaceStates.failed(workspace.value.status) || inRunningState.value
    })

    const _notChangingOnlineStatus = computed(() => {
      const onlineStatusComplete = ["online", "offline", null].includes(workspace.value.onlineStatus)

      const appsOnlineStatusNotComplete = workspace.value.schema.assets.some(app => {
        return ["starting", "stopping"].includes(app.onlineStatus)
      })

      return onlineStatusComplete && !appsOnlineStatusNotComplete
    })

    const workspaceStatus = computed(() => {
      if (workspace.value?.status) {
        return workspace.value.status
      }

      return ""
    })

    const _updateTabName = () => {
      const route = Router.currentRoute

      if (route.name === "WorkspaceEdit") {
        const space = workspace.value
        const { title, online, status } = createSpaceMetaData(space)

        document.title = `${title} - ${translate(status)} ${online}`
      }
    }

    const _notDeploying = computed(() => {
      return !WorkspaceStates.isLoading(workspace.value?.status)
    })

    const appRunningChanges = computed(() => {
      return !_notDeploying.value || !_notChangingOnlineStatus.value
    })

    const licenseProducts = computed(() => {
      return adminModule.organizationLicenses ?? []
    })

    const _recursiveUpdate = () => {
      if (shouldRecursiveUpdate.value) {
        if (_notChangingOnlineStatus.value && _notDeploying.value) {
          shouldRecursiveUpdate.value = false
          recursiveFnRunning.value = 0
        } else {
          setTimeout(async () => {
            await workspaceModule.getWorkspace(licenseId.value)
            await licenseModule.getLicenseState()
            _updateTabName()
            _recursiveUpdate()
          }, 1200)
        }
      }
    }

    const _registerRecursiveUpdate = () => {
      if (recursiveFnRunning.value >= 1) {
        return
      } else {
        recursiveFnRunning.value++
        shouldRecursiveUpdate.value = true

        _recursiveUpdate()
      }
    }

    const _getOwnIp = async () => {
      ownIp.value = await adminModule.getOwnIp()
    }

    const _finishedDestroying = async () => {
      return new Promise(resolve => {
        const { Draft } = WorkspaceStatusEnum

        workspaceModule.getWorkspace(licenseId.value).then(license => {
          if (license && license.status.includes(Draft)) {
            resolve(true)
            validateLicense()
            navigate({ name: "Onboarding" })
          } else {
            setTimeout(_finishedDestroying, 2000)
          }
        })
      })
    }

    const _minLevelRequests = async () => {
      if (usersModule.hasEditorPermissions) {
        await workspaceModule.getAvailableApps(licenseModule.currentLicense.id)
      }
    }

    const _validateHasOwnIp = async () => {
      await addIp({ ip: ownIp.value, description: usersModule.selfDetail.email })
    }

    const _appGetConnectionUrlAndCopyPassword = async (appId: string) => {
      const { connectionUrl, password } = await adminModule.getWorkspaceAppInstanceLink(licenseId.value, appId)

      if (await copyToClipboard(password, true)) {
        raiseSuccess({ text: translate("WorkspaceEdit.alerts.feedbacks.passwordCopied") })
      }

      return connectionUrl
    }

    const _appOpenConnection = async (appId: string) => {
      const connectionUrl = await _appGetConnectionUrlAndCopyPassword(appId)

      setTimeout(() => {
        window.open(connectionUrl, "_blank")
      }, 2000)
    }

    const _updateRouteOrganizationId = (organizationId: string) => {
      Router.push({ params: { organizationId } })
    }

    const _getLicense = async () => {
      const routeOrganizationId = Router.currentRoute.params.organizationId
      const selectedOrganizationId = usersModule.selectedOrganizationId
      const orgId = routeOrganizationId || selectedOrganizationId

      if (!licenseId.value) {
        await usersModule.getMyDetails()
      }
      if (!routeOrganizationId) {
        _updateRouteOrganizationId(selectedOrganizationId)
      }

      await licenseModule.getLicenseServer(orgId)
      await adminModule.getOrganizationLicenses(orgId)
    }

    const _getWorkspace = async () => {
      await workspaceModule.getWorkspace(licenseId.value)
      await licenseModule.getLicenseState()
      _updateTabName()
      _registerRecursiveUpdate()
    }

    const unlockDestroy = async () => {
      await unlockWorkspace()
      destroySelf()
    }

    const providerOwnIpValidation = async () => {
      await workspaceLoading.run(async () => {
        await _validateHasOwnIp()
      })
    }

    const unlockOnly = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.unlock(licenseId.value)
        await workspaceModule.getWorkspace(licenseId.value)

        _updateTabName()
      })
    }

    const updateApp = (asset: IWorkspaceAsset) => {
      workspaceLoading.run(async () => {
        await workspaceModule.updateApp(asset.id, {
          ...asset,
          userConfigurations: wizardAdaptor(asset)
        })
      })
    }

    const removeApp = (assetId: string) => {
      workspaceLoading.run(async () => {
        await workspaceModule.removeApp(assetId)
        analyzingAppDetails.value = false

        await Promise.all([
          workspaceModule.getWorkspace(licenseId.value),
          workspaceModule.getAvailableApps(licenseId.value)
        ])

        _updateTabName()
      })
    }

    const downloadShortcut = async (appId: string) => {
      workspaceLoading.run(async () => {
        await _validateHasOwnIp()
        await adminModule.downloadWorkspaceShortcut(licenseId.value, appId)
      })
    }

    const openInstance = async (appId: string) => {
      workspaceLoading.run(async () => {
        await _validateHasOwnIp()
        await _appOpenConnection(appId)
      })
    }

    const addIp = async (ipProp: RegisteredIp) => {
      await workspaceLoading.run(async () => {
        await _getWorkspace()
        const isIpIncluded = workspace.value.authorizedIps.some(ipItem => ipItem.ip === ipProp.ip)

        if (!isIpIncluded) {
          await workspaceModule.authorizeIP(ipProp, licenseId.value)
        }
      })
    }

    const openApp = (app: IWorkspaceAsset) => {
      if (usersModule.hasEditorPermissions) {
        workspaceModule.appIdBeingEdited = app.id
        analyzingAppDetails.value = true
      }
    }

    const copyAppPassword = async (appId: string) => {
      await workspaceLoading.run(async () => {
        const password = await workspaceModule.getAppPassword(licenseId.value, appId)

        if (await copyToClipboard(password, true)) {
          raiseSuccess({ text: translate("WorkspaceEdit.alerts.feedbacks.passwordCopied") })
        }
      })
    }

    const abortChanges = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.abortChanges(licenseId.value)
      })
    }

    const renewCertificate = async () => {
      await workspaceLoading.run(async () => {
        const certificateRenew = true

        await workspaceModule.deploy(licenseId.value, certificateRenew)
        await workspaceModule.getWorkspace(licenseId.value)
      })
    }

    const unlockWorkspace = async () => {
      await workspaceLoading.run(async () => {
        await workspaceModule.unlock(licenseId.value)
        await deploy()
      })
    }

    const removeIp = (ipProp: RegisteredIp) => {
      workspaceLoading.run(async () => {
        await workspaceModule.revokeIP(ipProp, licenseId.value)
      })
    }

    const addApps = (apps: AddNewAssetPayload[]) => {
      workspaceLoading.run(async () => {
        for (const app of apps) {
          await workspaceModule.addApp(app)
        }

        await Promise.all([
          workspaceModule.getWorkspace(licenseId.value),
          workspaceModule.getAvailableApps(licenseId.value)
        ])

        _updateTabName()
      })
    }

    const setOnline = (state: WorkspaceOnlineStatusEnum, instanceId: string) => {
      workspaceLoading.run(async () => {
        await workspaceModule.setAppOnlineStatus(licenseId.value, state, instanceId)
        _registerRecursiveUpdate()
      })
    }

    const discardChanges = () => {
      const deployedVersion = Utils.isType<WorkspaceTemplate>(workspace.value, "schemaDeployed")

      if (deployedVersion && deployedVersion.schemaDeployed?.assets) {
        workspaceLoading.run(async () => {
          await workspaceModule.resetWorkspaceSchemaToDeployed()
          await workspaceModule.getAvailableApps(licenseId.value)
        })
      }
    }

    const appDiscardChanges = (appId: string) => {
      const deployedVersion = Utils.isType<WorkspaceTemplate>(workspace.value, "schemaDeployed")

      if (deployedVersion && deployedVersion.schemaDeployed?.assets) {
        workspaceLoading.run(async () => {
          await workspaceModule.appDiscardChanges(appId)
          await workspaceModule.getAvailableApps(licenseId.value)

          appRenderDetailsKey.value++
        })
      }
    }

    const changeAppStatus = (status: WorkspaceOnlineStatusEnum) => {
      workspaceLoading.run(async () => {
        await workspaceModule.changeAppStatus(licenseId.value, status)
        _registerRecursiveUpdate()
      })
    }

    const destroySelf = () => {
      workspaceLoading.run(async () => {
        await workspaceModule.destroy(licenseId.value)
        await _finishedDestroying()
      })
    }

    const redeploy = async () => {
      const strictLabel = translate("LicenseCore.confirm.redeploy.password")
      const text = translate("LicenseCore.confirm.redeploy.text")

      if (await raiseConfirmation({ text, strictLabel })) {
        await deploy()
      }
    }

    const deploy = async () => {
      await workspaceLoading.run(async () => {
        const certificateRenew = false

        await workspaceModule.deploy(licenseId.value, certificateRenew)
        await workspaceModule.getWorkspace(licenseId.value)
        _registerRecursiveUpdate()
      })
    }

    loader.run(async () => {
      await _getLicense()
      await workspaceModule.getWorkspace(licenseModule.currentLicense.id)

      try {
        await Promise.all([workspaceModule.getAssetModels(), _minLevelRequests(), _getOwnIp()])

        await workspaceModule.getTemplates(Router.currentRoute.params.organizationId)
        _updateTabName()

        nextTick(() => {
          _registerRecursiveUpdate()
        })
      } catch {
        raiseError({ text: translate("WorkspaceEdit.alert.invalidWorkspace") })
      }
    })

    onBeforeUnmount(() => {
      shouldRecursiveUpdate.value = false
      updateFavicon("default")
    })

    provide("validateOwnIp", providerOwnIpValidation)

    return {
      licenseProducts,
      appRunningChanges,
      changeAppStatus,
      appRenderDetailsKey,
      appDiscardChanges,
      discardChanges,
      addIp,
      abortChanges,
      removeIp,
      unlockOnly,
      renewCertificate,
      copyAppPassword,
      addAppOpened,
      loader,
      redeploy,
      setOnline,
      workspaceStatus,
      appLocked,
      ownIp,
      addApps,
      removeApp,
      updateApp,
      appDetails,
      downloadShortcut,
      openInstance,
      workspace,
      workspaceLoading,
      unlockDestroy,
      destroySelf,
      openApp,
      analyzingAppDetails,
      unlockWorkspace
    }
  },
  components: {
    CidrAlert,
    HeaderController,
    AppContainer,
    WorkspaceAlerts,
    AppDetails,
    NavigateBack,
    PreHeader
  }
})
</script>
