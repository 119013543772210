<template>
  <div id="noAuth">
    <div class="offeredContent pageNotFound">
      <v-expand-transition v-if="showAlert" :duration="300" mode="out-in">
        <div class="animatedLayer">
          <v-alert icon="status_no" prominent text type="error">
            <template v-slot:prepend>
              <div class="circleIcon">
                <v-icon size="23">status_no</v-icon>
              </div>
            </template>

            <div class="content">
              <h2>{{ $t("notFound.title") }}</h2>
              <p>{{ $t("notFound.body") }}</p>
            </div>

            <template v-slot:close>
              <v-btn @click="showAlert = false" icon color="danger">
                <v-icon color="error">action_close</v-icon>
              </v-btn>
            </template>
          </v-alert>
        </div>
      </v-expand-transition>

      <login-page />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api"
import LoginPage from "@/views/login/LoginRoot.vue"

export default defineComponent({
  name: "NoAuthScreen",
  setup() {
    const showAlert = ref(true)

    setTimeout(() => {
      showAlert.value = false
    }, 5000)

    return {
      showAlert
    }
  },
  components: {
    LoginPage
  }
})
</script>
