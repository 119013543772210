import { WorkspaceTemplate } from "@/types/workspace"
import { AdvancedFilterConfig } from "./type"
import { applyUserFilter } from "./user-filter"
import { applyLastModifiedFilter } from "./last-modified-filter"
import { applyStatusFilter } from "./status-filter"

export const advancedFilter = (config: AdvancedFilterConfig, list: WorkspaceTemplate[]) => {
  const { ownerId, lastModified, status } = config
  let filteredList = [...list]

  if (ownerId !== "any") {
    filteredList = applyUserFilter(ownerId, filteredList)
  }

  if (lastModified !== "any") {
    filteredList = applyLastModifiedFilter(lastModified, filteredList)
  }

  if (status.length) {
    filteredList = applyStatusFilter(status, filteredList)
  }

  return filteredList
}
