<template>
  <popup
    v-model="popupIsOpen"
    @confirm="handleConfirm"
    :disabled="!formIsValid"
    :loading="loader.on || loading"
    :title="$t('Admin.organizations.createPopup.title')"
    :close-on-click-out="!hasCreatedOrganization"
    :hide-button-cancel="hasCreatedOrganization"
    rbt-target="createOrganization"
    no-padding
  >
    <div id="createOrganizationPopup">
      <v-form v-model="formIsValid" ref="Form">
        <div v-if="!hasCreatedOrganization" class="inputRow">
          <div class="inputLabel">
            <span>{{ $t("Admin.organizations.createPopup.input.orgName") }}</span>
            <v-text-field
              v-model="newOrganization.name"
              :disabled="loader.on || loading"
              :rules="rules.text"
              rbt-target="inputOrganizationName"
              name="organizationName"
              hide-details
            />
          </div>

          <div class="inputLabel">
            <span>{{ $t("Admin.organizations.createPopup.input.orgType") }}</span>
            <v-select
              v-model="newOrganization.organizationType"
              :disabled="loading"
              :rules="rules.select"
              :items="selectOptions"
              rbt-target="selectOrganizationType"
              name="organizationType"
              hide-details
            >
              <template v-slot:item="{ item }">
                <span :rbt-target="`option${toPascal(item.value)}`">
                  {{ item.text }}
                </span>
              </template>
            </v-select>
          </div>
        </div>

        <create-saas-form
          v-if="isSaaSOrganization && !hasCreatedOrganization"
          v-model="saasSettings"
          :loading="loader.on || loading"
        />

        <create-admin-form v-if="hasCreatedOrganization" v-model="newUser" :loading="loader.on || loading" />
      </v-form>
    </div>
  </popup>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/composition-api"
import { rules } from "./lib/rules"
import { AdminModule } from "@/store"
import { VForm } from "@/types/core"
import { OrganizationType, SaaSSettings } from "@/types/organization"
import { organizationTypeOptions } from "../../lib/organization-type-options"
import { NewOrganization, NewUser, SaasSettings } from "./composable/new-organization"
import Loader from "@/utils/loader"
import Utils from "@/utils/utils"
import Popup from "@/components/popup/PopupRoot.vue"
import CreateSaasForm from "./_CreateSaasForm.vue"
import CreateAdminForm from "./_CreateAdminForm.vue"

export default defineComponent({
  name: "CreateOrganizationPopup",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const adminModule = AdminModule()
    const popupIsOpen = Utils.vModel(props, ctx.emit)
    const formIsValid = ref(false)
    const Form = ref<VForm | null>(null)
    const selectOptions = computed(organizationTypeOptions)
    const newOrganization = NewOrganization()
    const saasSettings = SaasSettings()
    const newUser = NewUser()
    const hasCreatedOrganization = ref(false)
    const loader = Loader()
    const organizationId = ref("")

    const isSaaSOrganization = computed(() => {
      return newOrganization.organizationType === OrganizationType.SaaS
    })

    const _subscriptionAdaptor = () => {
      if (isSaaSOrganization.value) {
        const { startDate, expirationDate } = saasSettings
        const saasSettingsClone = Utils.deepClone<SaaSSettings>(saasSettings)
        const _isoDate = (date: string) => new Date(date).toISOString()

        saasSettingsClone.startDate = _isoDate(startDate)
        saasSettingsClone.expirationDate = _isoDate(expirationDate)

        return saasSettingsClone
      }

      return null
    }

    const createOrganization = () => {
      loader.run(async () => {
        const subscription = _subscriptionAdaptor()
        organizationId.value = await adminModule.createOrganization({ ...newOrganization, subscription })
        hasCreatedOrganization.value = true
      })
    }

    const createUser = () => {
      loader.run(async () => {
        await adminModule.createUser(newUser, organizationId.value)
        ctx.emit("close", organizationId.value)
      })
    }

    const handleConfirm = () => {
      if (Form.value && Form.value.validate()) {
        if (hasCreatedOrganization.value) {
          createUser()
        } else {
          createOrganization()
        }
      }
    }

    const toPascal = (text: string) => {
      return Utils.toPascal(text)
    }

    return {
      Form,
      formIsValid,
      popupIsOpen,
      toPascal,
      newOrganization,
      selectOptions,
      hasCreatedOrganization,
      rules,
      newUser,
      isSaaSOrganization,
      saasSettings,
      loader,
      handleConfirm
    }
  },
  components: {
    Popup,
    CreateAdminForm,
    CreateSaasForm
  }
})
</script>
