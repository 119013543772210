<template>
  <div id="changeLogRoot" class="commonTab">
    <div class="containCommunicationItem backgroundWrapper">
      <div class="summary">
        <div class="wrapTitle">
          <h2>{{ $t("Admin.changeLog.title") }}</h2>
          <p>{{ $t("Admin.changeLog.subTitle") }}</p>
        </div>

        <v-btn @click="resetChangeLogViews" :disabled="loader.on" large>
          <v-icon left>mdi-file-document-alert-outline</v-icon>
          {{ $t("Admin.changeLog.buttonReset") }}
        </v-btn>
      </div>

      <html-editor v-model="changeLog" :loading="loader.on" />

      <div class="controllers">
        <v-btn @click="releaseChangeLog" :loading="loader.on" color="primary" large>
          <span>{{ $t("Admin.changeLog.buttonSave") }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "@vue/composition-api"
import { AdminModule } from "@/store/admin"
import { raiseSuccess, raiseConfirmation } from "@/utils/event-bus"
import { translate } from "@/plugins/i18n"
import HtmlEditor from "@/components/htmlEditor/HtmlEditor.vue"
import Loader from "@/utils/loader"

export default defineComponent({
  name: "ChangeLogRoot",
  setup() {
    const adminModule = AdminModule()
    const loader = Loader()
    const changeLog = ref("")

    const getChangeLogs = async () => {
      await loader.run(async () => {
        await adminModule.getCurrentChangeLogs()
        changeLog.value = adminModule.changeLogs
      })
    }

    const releaseChangeLog = async () => {
      if (await raiseConfirmation({ text: translate("Admin.changeLog.confirmation.saveConfirmation") })) {
        loader.run(async () => {
          await adminModule.releaseChangeLogs({ changeLog: changeLog.value })
          await getChangeLogs()

          raiseSuccess({ text: translate("Admin.changeLog.confirmation.success") })
        })
      }
    }

    const resetChangeLogViews = async () => {
      if (await raiseConfirmation({ text: translate("Admin.changeLog.confirmation.resetViews") })) {
        loader.run(async () => {
          await adminModule.resetViews()
        })
      }
    }

    getChangeLogs()

    return {
      loader,
      resetChangeLogViews,
      releaseChangeLog,
      changeLog
    }
  },
  components: {
    HtmlEditor
  }
})
</script>
