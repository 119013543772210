<template>
  <nested-item
    :app="comboBox"
    :service="service"
    :has-changes="hasChanges"
    :readonly="service.firstDeployOnly && wasDeployed"
  >
    <v-checkbox
      v-model="service.active"
      @click="validate"
      :disabled="isMandatory || (service.firstDeployOnly && wasDeployed)"
      :label="label[$i18n.locale]"
      rbt-target="wizardSectionContainer"
      class="my-0"
      hide-details
    />

    <template v-slot:child>
      <div class="widgetContainer" v-if="service.widget">
        <v-combobox
          v-model="service.widget.value"
          :items="comboBoxSelectItems"
          :rules="inputRules"
          :hide-details="shouldHideDetails"
          class="my-0 py-0"
          return-object
        />
      </div>
    </template>
  </nested-item>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { WizardSettings, WidgetComboBox } from "@/types/wizard"
import { MandatoryComboBox } from "@/utils/input-rules"
import { SelectItem } from "@/types/core"
import { useValidate } from "../composable/useValidate"
import WizardWidgetMixin from "../mixin/WizardWidgetMixin"
import WizardRuleMixin from "../mixin/WizardRuleMixin"
import Utils from "@/utils/utils"
import i18n from "@/plugins/i18n"

export default defineComponent({
  name: "WizardComboBox",
  mixins: [WizardWidgetMixin, WizardRuleMixin],
  props: {
    value: {
      type: Object as PropType<WizardSettings<WidgetComboBox>>,
      required: true
    }
  },
  setup(props, ctx) {
    const { validate } = useValidate(ctx.emit)
    const service = Utils.vModel<WizardSettings<WidgetComboBox>>(props, ctx.emit)
    const inputRules = MandatoryComboBox

    const comboBoxSelectItems = computed<SelectItem<string>[]>(() => {
      return (
        props.value.widget?.options.map(item => {
          return {
            text: item.label[i18n.locale],
            value: item.value
          } as SelectItem<string>
        }) ?? []
      )
    })

    if (service.value.widget?.value) {
      const selectItem = comboBoxSelectItems.value?.find(item => item.value === service.value.widget?.value)

      if (selectItem) {
        service.value.widget.value = { ...selectItem }
      }
    }

    return {
      validate,
      inputRules,
      comboBoxSelectItems
    }
  }
})
</script>
