<template>
  <v-menu v-model="activeMenu" :close-on-content-click="false" class="timePicker">
    <template v-slot:activator="{ on, attrs }">
      <div class="inputRow">
        <slot :on="on" :attrs="attrs" name="activator" />

        <v-menu v-model="activeClockMenu" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="time"
              v-on="on"
              v-bind="attrs"
              @click:clear="setTimeToNull"
              :disabled="disabled || !time"
              class="timeFormat"
              append-icon="mdi-clock-outline"
              hide-details
              readonly
              clearable
            >
              <template v-slot:append>
                <v-icon v-if="!disabled && time" v-on="on">mdi-clock-outline</v-icon>
              </template>
            </v-text-field>
          </template>

          <v-time-picker
            v-model="time"
            @click:minute="closeTimePicker"
            :disabled="disabled"
            :allowed-hours="allowedHours"
            :allowed-minutes="allowedMinutes"
            color="primary"
            format="24hr"
            landscape
          />
        </v-menu>
      </div>
    </template>

    <v-date-picker
      v-model="datePickerDate"
      @change="onDateChange"
      :allowed-dates="allowedDates"
      :disabled="disabled"
      color="primary"
      landscape
    />
  </v-menu>
</template>

<script lang="ts">
import Utils from "@/utils/utils"
import { defineComponent, ref, computed, nextTick, watch, PropType } from "@vue/composition-api"
import { TimeFormat } from "@/utils/input-rules"

export default defineComponent({
  name: "TimePicker",
  props: {
    value: {
      type: null as unknown as PropType<number | null>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: [String, Number],
      required: false
    }
  },
  setup(props, ctx) {
    const date = Utils.vModel(props, ctx.emit)
    const activeMenu = ref(false)
    const activeClockMenu = ref(false)

    const datePickerDate = computed({
      get() {
        if (date.value) {
          const timezone = Utils.minutes(new Date().getTimezoneOffset())
          return new Date(date.value - timezone).toISOString().slice(0, 10)
        }

        return ""
      },
      set(newDate: string) {
        const now = new Date()
        const theDate = new Date(newDate)

        theDate.setDate(theDate.getDate())
        theDate.setHours(now.getHours())
        theDate.setMinutes(now.getMinutes())
        theDate.setSeconds(now.getSeconds())

        date.value = theDate.getTime()
      }
    })

    const time = computed({
      get() {
        if (date.value) {
          const formattedDate = Utils.dateFormatWithTime(date.value)
          return formattedDate.slice(formattedDate.indexOf(",") + 2)
        }

        return ""
      },
      set(hour: string) {
        const inputRule = TimeFormat[0]

        if (inputRule(hour) === true) {
          const newDate = new Date(date.value as number)
          const [hh, mm] = hour.split(":")

          newDate.setHours(parseInt(hh))
          newDate.setMinutes(parseInt(mm))

          date.value = newDate.getTime()
        } else {
          date.value = null
        }
      }
    })

    const allowedHours = (hour: number) => {
      if (date.value) {
        const newDateInMsWithAlteredTime = new Date(date.value).setHours(hour)
        return newDateInMsWithAlteredTime >= Date.now()
      }

      return false
    }

    const allowedMinutes = (min: number) => {
      if (date.value) {
        const newDateInMsWithAlteredTime = new Date(date.value).setMinutes(min)
        return newDateInMsWithAlteredTime >= Date.now()
      }

      return false
    }

    const allowedDates = (fromCalendarDate: string) => {
      const now = new Date()
      const calendarDate = new Date(fromCalendarDate)

      calendarDate.setDate(calendarDate.getDate() + 1)
      calendarDate.setHours(now.getHours())
      calendarDate.setMinutes(now.getMinutes())

      return calendarDate.getTime() >= Date.now()
    }

    const setTimeToNull = () => {
      date.value = null
    }

    const onDateChange = () => {
      nextTick(() => {
        if (datePickerDate.value) {
          activeMenu.value = false
          activeClockMenu.value = true
        }
      })
    }

    const closeTimePicker = () => {
      activeClockMenu.value = false
    }

    return {
      closeTimePicker,
      onDateChange,
      setTimeToNull,
      allowedHours,
      allowedMinutes,
      allowedDates,
      activeClockMenu,
      time,
      datePickerDate,
      date,
      activeMenu
    }
  }
})
</script>
