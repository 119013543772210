<template>
  <tooltip :text="text" :disabled="tooltipDisabled || text === label">
    <span :class="customClass" class="stringMax">{{ label }}</span>
  </tooltip>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "StringMax",
  props: {
    text: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    customClass: {
      type: String,
      default: ""
    },
    tooltipDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const label = computed(() => {
      if (props.text.length >= props.max) {
        return `${props.text.slice(0, props.max)}...`
      }
      return props.text
    })

    return {
      label
    }
  },
  components: {
    Tooltip
  }
})
</script>
