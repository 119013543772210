<template>
  <div class="appDialogHeader">
    <div class="leftContainer">
      <img v-if="appModel" :src="`/${appModel.icon}`" :alt="appModel.text" />

      <div class="appTitle">
        <div v-if="!titleEditorOpen" class="editableItem">
          <h3>{{ app.description }}</h3>

          <v-btn v-if="canEdit" @click="titleEditorOpen = true" :loading="loading" icon>
            <v-icon>action_edit</v-icon>
          </v-btn>
        </div>

        <editor v-model="appTemp.description" @change="rippleChange" @cancel="titleEditorOpen = false" v-else />

        <p class="subTitle">
          {{ appModel.text }}
        </p>
      </div>
    </div>

    <div class="rightContainer">
      <online-switch
        v-if="appDeployed"
        @change="state => $emit('set:online', state)"
        :online-state="app.onlineStatus"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "@vue/composition-api"
import { IWorkspaceAsset, IAssetModel, WorkspaceTemplate } from "@/types/workspace"
import { UsersModule } from "@/store"
import OnlineSwitch from "../appsContainer/OnlineSwitch.vue"
import Utils from "@/utils/utils"
import Editor from "../preHeader/Editor.vue"

export default defineComponent({
  name: "DialogHeader",
  props: {
    value: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    appModel: {
      type: Object as PropType<IAssetModel>,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()
    const appTemp = Utils.vModel(props, ctx.emit)
    const titleEditorOpen = ref(false)

    const canEdit = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const appDeployed = computed(() => {
      const workspaceDeployed = Utils.isType<WorkspaceTemplate>(props.workspace, "schemaDeployed")

      if (workspaceDeployed) {
        const searchedApp = workspaceDeployed.schemaDeployed?.assets?.find(app => {
          return app.id === props.app.id
        })

        return Boolean(searchedApp)
      }

      return false
    })

    const rippleChange = () => {
      titleEditorOpen.value = false
      ctx.emit("save")
    }

    return {
      canEdit,
      appTemp,
      rippleChange,
      titleEditorOpen,
      appDeployed
    }
  },
  components: {
    OnlineSwitch,
    Editor
  }
})
</script>
