<template>
  <div class="workspaces-list">
    <reset-filter-handler v-if="showResetFilterHandler" @click="$emit('clear:filter')" class="backgroundWrapper" />

    <empty-list v-else-if="showEmptyList" />

    <table-view v-else-if="viewModeIsTableView" :workspaces="workspaces" />

    <div v-else class="workspaceGridContainer backgroundWrapper">
      <grid-card v-for="(workspace, index) in workspaces" :key="workspace.id" :workspace="workspace" :index="index" />

      <div
        v-if="canCreateWorkspaces"
        @click="navigateWorkspaceCreation"
        rbt-target="createWorkspaceAlt"
        class="addSpace"
      >
        <v-icon x-large color="var(--viz-grey-3)">action_add_circle</v-icon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { navigate } from "@/router"
import { UsersModule } from "@/store/users"
import GridCard from "./gridView/GridCard.vue"
import TableView from "./tableView/TableView.vue"
import ResetFilterHandler from "./ResetFilterHandler.vue"
import EmptyList from "./EmptyList.vue"

export default defineComponent({
  name: "WorkspaceListHandler",
  emits: ["clear:filter"],
  props: {
    workspaces: {
      type: Array as PropType<WorkspaceTemplate[]>,
      required: true
    },
    viewMode: {
      type: String,
      required: true
    },
    canCreateWorkspaces: {
      type: Boolean,
      default: false
    },
    hasFilterConfigurations: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const usersModule = UsersModule()

    const viewModeIsTableView = computed(() => {
      return props.viewMode === "table"
    })

    const showEmptyList = computed(() => {
      const noWorkspaces = props.workspaces.length === 0
      return !usersModule.hasEditorPermissions && noWorkspaces
    })

    const showResetFilterHandler = computed(() => {
      const { hasFilterConfigurations, workspaces } = props

      return hasFilterConfigurations && workspaces.length === 0
    })

    const navigateWorkspaceCreation = () => {
      navigate({ name: "WorkspaceConfig" })
    }

    return {
      showEmptyList,
      showResetFilterHandler,
      navigateWorkspaceCreation,
      viewModeIsTableView
    }
  },
  components: {
    TableView,
    GridCard,
    EmptyList,
    ResetFilterHandler
  }
})
</script>
