<template>
  <div id="dataCollector">
    <v-label>
      <span>External ID</span>

      <div class="inputWithIcon">
        <v-text-field
          v-model="arnConfig.externalId"
          :disabled="isCustomerHosted"
          :rules="uuidRule"
          class="mb-2"
          hide-details
        />

        <v-icon v-if="validExternalId" color="success"> status_ok_circle </v-icon>
        <v-icon color="error" v-else> action_remove_circle </v-icon>
      </div>
    </v-label>

    <v-label>
      <span>Account ID</span>

      <div class="inputWithIcon">
        <v-text-field :value="awsAccountId" class="mb-2" disabled readonly hide-details />

        <v-icon color="success" v-if="awsAccountId"> status_ok_circle </v-icon>
        <v-icon color="error" v-else> action_remove_circle </v-icon>
      </div>
    </v-label>

    <v-label>
      <span>{{ $t("Onboarding.vizNow.form.arnLabel") }}</span>

      <div class="inputWithIcon">
        <v-text-field v-model="arnConfig.arnCode" :rules="inputRules" />

        <v-icon v-if="arnInputStatusIcon" :color="arnInputStatusIcon.color">
          {{ arnInputStatusIcon.icon }}
        </v-icon>
      </div>
    </v-label>
  </div>
</template>

<script lang="ts">
import { AdminModule, LicenseModule } from "@/store"
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { extractAccountId } from "../../lib/arn-validation"
import { validateArn } from "../../lib/arn-validation"
import { translate } from "@/plugins/i18n"
import { OrganizationType } from "@/types/organization"
import { ArnConfig } from "../../lib/type"
import { MinLengthTextField } from "@/utils/input-rules"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "AwsCredentials",
  props: {
    value: {
      type: Object as PropType<ArnConfig>,
      required: true
    }
  },
  setup(props, ctx) {
    const EXTERNAL_ID_MIN_LENGTH = 8
    const arnConfig = Utils.vModel(props, ctx.emit)
    const licenseModule = LicenseModule()
    const adminModule = AdminModule()

    const awsAccountId = computed(() => {
      return extractAccountId(arnConfig.value.arnCode)
    })

    const isCustomerHosted = computed(() => {
      return adminModule.organizationDetail?.organizationType === OrganizationType.CustomerHosted
    })

    const arnInputStatusIcon = computed(() => {
      const validArn = arnRegexCheckRule() === true
      const notDuplicates = differentArnCode() === true

      if (validArn && notDuplicates) {
        return {
          color: "success",
          icon: "status_ok_circle"
        }
      } else if (arnConfig.value.arnCode.length > 3) {
        return {
          color: "error",
          icon: "action_remove_circle"
        }
      }

      return false
    })

    const validExternalId = computed(() => {
      return arnConfig.value.externalId?.length >= EXTERNAL_ID_MIN_LENGTH
    })

    const differentArnCode = () => {
      if (arnConfig.value.arnCode !== licenseModule.licenseServer.spaceliftAwsRoleArn) {
        return true
      }

      return translate("Onboarding.ruleFeedback.arnDuplicates")
    }

    const arnRegexCheckRule = () => {
      if (validateArn(arnConfig.value.arnCode)) {
        return true
      }

      return translate("Onboarding.ruleFeedback.invalidArn")
    }

    return {
      validExternalId,
      isCustomerHosted,
      awsAccountId,
      inputRules: [arnRegexCheckRule, differentArnCode],
      uuidRule: MinLengthTextField(EXTERNAL_ID_MIN_LENGTH),
      arnInputStatusIcon,
      arnConfig
    }
  }
})
</script>
