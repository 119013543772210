<template>
  <div id="editableTitle">
    <div class="wrapTitle">
      <div class="editableItem" v-if="!titleEditorOpen">
        <tooltip :disabled="workspace.title.length < 35" :text="workspace.title">
          <h3>{{ workspace.title }}</h3>
        </tooltip>

        <v-btn v-if="canEdit" @click="titleEditorOpen = true" :loading="loading" rbt-target="openTitleEditor" icon>
          <v-icon>action_edit</v-icon>
        </v-btn>
      </div>

      <editor
        v-model="workspace.title"
        @change="value => rippleChange(value, 'title')"
        @cancel="titleEditorOpen = false"
        cypress-anchor="titleInput"
        v-else
      />
    </div>

    <div class="wrapDescription">
      <div class="editableItem" v-if="!descriptionEditorOpen">
        <p>{{ customDescription }}</p>

        <v-btn
          v-if="canEdit"
          @click="descriptionEditorOpen = true"
          :loading="loading"
          rbt-target="openDescriptionEditor"
          icon
        >
          <v-icon>action_edit</v-icon>
        </v-btn>
      </div>

      <editor
        v-model="workspace.description"
        @change="value => rippleChange(value, 'description')"
        @cancel="descriptionEditorOpen = false"
        cypress-anchor="descriptionInput"
        no-rules
        v-else
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, computed } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import { createWorkspaceDescription } from "@/utils/workspace-description"
import { UsersModule } from "@/store"
import Editor from "./Editor.vue"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "EditableTitle",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const titleEditorOpen = ref(false)
    const descriptionEditorOpen = ref(false)
    const usersModule = UsersModule()

    const customDescription = computed(() => {
      return createWorkspaceDescription(props.workspace)
    })

    const canEdit = computed(() => {
      return usersModule.hasEditorPermissions
    })

    const rippleChange = (value: string, input: "title" | "description") => {
      if (input === "title") {
        titleEditorOpen.value = false

        ctx.emit("update", {
          ...props.workspace,
          title: value
        })
      }

      if (input === "description") {
        descriptionEditorOpen.value = false

        ctx.emit("update", {
          ...props.workspace,
          description: value
        })
      }
    }

    return {
      canEdit,
      customDescription,
      rippleChange,
      descriptionEditorOpen,
      titleEditorOpen
    }
  },
  components: {
    Editor,
    Tooltip
  }
})
</script>
