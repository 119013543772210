<template>
  <div id="appLauncherPicker">
    <common-page-loader v-if="loader.on" :text="$t('AppLauncherPicker.loading')" />

    <error-report
      v-else-if="errorDetail.hasError"
      @destroy:space="destroySpace"
      :loading="loader.on"
      :message="errorDetail.msg"
    />

    <div v-else class="occupyFullScreen">
      <div class="contentWrapper">
        <h1>{{ $t("AppLauncherPicker.title", { userName }) }}</h1>

        <app-options v-if="appOptions.length" @click="chooseApp" :apps="appOptions" :loading="loader.on" />

        <h3>{{ $t("AppLauncherPicker.launchTime", { min: deployDuration }) }}</h3>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "@vue/composition-api"
import { UsersModule, WorkspaceModule } from "@/store"
import { AppItem } from "@/types/workspace"
import { RequestErrorResponse } from "@/types/core"
import { DEPLOYING_TIMER } from "@/config/app-timer-speculation"
import Loader from "@/utils/loader"
import AppOptions from "./components/AppOptions.vue"
import Router, { navigate } from "@/router"
import CommonPageLoader from "@/components/commonPageLoader/CommonPageLoader.vue"
import ErrorReport from "../appLauncher/components/errorReport"
import "./scss/_appLauncherPicker.scss"

export default defineComponent({
  name: "AppLauncherPicker",
  setup() {
    const loader = Loader({ throwOnError: true, alertOnError: false })
    const errorDetail = reactive({ hasError: false, msg: "" })
    const workspaceModule = WorkspaceModule()
    const usersModule = UsersModule()

    const _workspaceId = computed(() => {
      return Router.currentRoute.params.workspaceId
    })

    const _currentWorkspace = computed(() => {
      return workspaceModule.watching
    })

    const _appRecentlyAdded = computed(() => {
      const assets = _currentWorkspace.value?.schema?.assets

      if (assets) {
        const lastAddedApp = assets.slice(-1)[0]

        if (lastAddedApp) {
          return lastAddedApp.id
        }
      }

      return null
    })

    const appOptions = computed(() => {
      const availableApps = workspaceModule.appItems.filter(_app => _app.isAvailable)

      if (availableApps && availableApps.length) {
        return availableApps
      }

      return []
    })

    const userName = computed(() => {
      const { firstName, lastName } = usersModule.selfDetail
      return `${firstName} ${lastName}`.trim()
    })

    const deployDuration = computed(() => {
      return Math.floor(DEPLOYING_TIMER / 60 / 1000)
    })

    const _reinforceNoAddedApps = () => {
      const workspaceNotEmpty = _currentWorkspace.value.schema.assets.length > 0

      if (workspaceNotEmpty) {
        _openAppLauncher()
      }
    }

    const _openAppLauncher = () => {
      const appId = _appRecentlyAdded.value as string
      navigate({ name: "AppLauncher", params: { appId }, query: { ...Router.currentRoute.query } })
    }

    const _declareError = (error: unknown) => {
      if (typeof error === "object") {
        const err = error as typeof Object

        if ("details" in err) {
          const requestError = error as RequestErrorResponse

          errorDetail.hasError = true
          errorDetail.msg = requestError?.details || requestError?.message
        }
      }
    }

    const destroySpace = () => {
      loader.run(async () => {
        await workspaceModule.destroy(_workspaceId.value)
        Router.go(0)
      })
    }

    const chooseApp = (appItem: AppItem) => {
      loader.run(async () => {
        await workspaceModule.addApp({ assetModelType: appItem.key })
        await workspaceModule.getWorkspace(_workspaceId.value)

        if (_appRecentlyAdded.value) {
          _openAppLauncher()
        }
      })
    }

    loader.run(async () => {
      workspaceModule.resetCurrentWorkspace()

      try {
        await Promise.all([
          workspaceModule.getWorkspace(_workspaceId.value),
          workspaceModule.getAssetModels(),
          workspaceModule.getAvailableApps(_workspaceId.value)
        ])

        _reinforceNoAddedApps()
      } catch (err) {
        _declareError(err)
      }
    })

    return {
      destroySpace,
      errorDetail,
      userName,
      deployDuration,
      chooseApp,
      appOptions,
      loader
    }
  },
  components: {
    ErrorReport,
    CommonPageLoader,
    AppOptions
  }
})
</script>
