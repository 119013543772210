export const MASK_16 = "16"
export const MASK_22 = "22"

export const AVAILABLE_SPACES = {
  mask16: 64,
  mask22: 16
}

export const CIDR_BLOCK_MASKS = {
  masks: [MASK_16, MASK_22],
  default: MASK_16
}
