<template>
  <div class="deployedInstance">
    <label>
      <span>{{ $t("WorkspaceEdit.appDetails.labels.deployedInstanceType") }}:</span>
    </label>

    <p>{{ name }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "DeployedInstance",
  props: {
    name: {
      type: String,
      required: true
    }
  }
})
</script>
