import { Id, SelectItem, TranslationOptions } from "./core"

enum WizardWidget {
  slider = "slider",
  checkbox = "checkbox",
  radio = "radio",
  comboBox = "comboBox",
  text = "text",
  iterableText = "iterableText"
}

export interface WizardRule {
  regex: string
  feedback: TranslationOptions
}

interface Checkbox {
  selected: boolean
  value: string | number | Id
  label: TranslationOptions
}

interface Radio {
  value: string | number | Id
  label: TranslationOptions
}

interface ComboBox {
  value: string | number | Id
  label: TranslationOptions
}

interface WidgetSlider {
  value: string | number
  unitLabel: string
  max: number | string
  min: number | string
  step: number
}

interface WidgetCheckbox {
  options: Checkbox[]
}

interface WidgetRadio {
  value: string | number | Id
  options: Radio[]
}

interface WidgetComboBox {
  value: string | number | SelectItem<string | number>
  options: ComboBox[]
}

interface WidgetText {
  value: string
  label: TranslationOptions
  rule: WizardRule
}

interface WidgetTextIterable {
  label: {
    value1: TranslationOptions
    value2: TranslationOptions
  }
  rule: null | {
    value1: WizardRule
    value2: WizardRule
  }
  options: {
    value1: string
    value2: string
  }[]
}

export type Widget = WidgetSlider | WidgetCheckbox | WidgetRadio | WidgetText | WidgetComboBox | WidgetTextIterable

export { WidgetCheckbox, WidgetSlider, WidgetRadio, WidgetText, WizardWidget, WidgetComboBox, WidgetTextIterable }

export interface WizardSettings<W> {
  active: boolean
  widget: W | null
  required: boolean
  firstDeployOnly: boolean
  documentation?: TranslationOptions
}

export interface WizardConfigItem<W = Widget> {
  resourceKey: string
  label: TranslationOptions
  type: WizardWidget
  settings: WizardSettings<W>
}
