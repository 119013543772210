<template>
  <div v-if="feedback" class="workspaceAlertsContainer">
    <div class="alertErrorType">
      <generic-alert
        @click="$emit('redeploy')"
        icon="status_warning"
        :action="$t('WorkspaceEdit.alerts.buttons.redeploy')"
        :disabled="disabled"
        warning
      >
        <p>
          {{ feedback }}
        </p>
      </generic-alert>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import GenericAlert from "@/views/workspaceEdit/components/alerts/GenericAlert.vue"

export default defineComponent({
  name: "ErrorAlert",
  props: {
    feedback: {
      type: String,
      default: ""
    }
  },
  components: {
    GenericAlert
  }
})
</script>
