<template>
  <div v-if="isSaas && subscriptionType" class="gridItem divisor">
    <span>{{ t("subscription") }}</span>
    <span>{{ subscriptionType }}</span>

    <span v-if="subscriptionEndDate">
      <span class="label">{{ t("subscriptionEndDate", { date: subscriptionEndDate }) }}</span>
    </span>

    <span v-if="subscriptionExpirationTime <= WARNING_DAYS_BEFORE_EXPIRE">
      <span v-if="!subscriptionIsExpired" class="--color-warning">
        {{ t("timeLeftLabel", { days: subscriptionExpirationTime }, subscriptionExpirationTime) }}
      </span>

      <span v-else class="--color-danger">
        {{ t("expired") }}
      </span>
    </span>

    <span v-else-if="subscriptionIsExpired">
      <span class="--color-danger"></span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { useStore } from "@/composable/useStore"
import { createTranslationModule } from "@/plugins/i18n"
import { OrganizationType } from "@/types/organization"
import { COMFORTABLE_TIME_RANGE } from "@/config/warning-range"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "SubscriptionLabel",
  setup() {
    const { adminModule, organizationModule } = useStore()
    const t = createTranslationModule("TriCaster.boxFooter.moreInfo.")
    const WARNING_DAYS_BEFORE_EXPIRE = COMFORTABLE_TIME_RANGE

    const isSaas = computed(() => {
      return adminModule.organizationDetail.organizationType === OrganizationType.SaaS
    })

    const subscriptionType = computed(() => {
      return organizationModule.walletDetail?.subscriptionType || false
    })

    const subscriptionExpirationTime = computed(() => {
      const subscription = adminModule.organizationDetail?.tokenSubscription

      if (subscription && subscription.expirationDate) {
        const rangeToExpireInDays = Utils.dateRange(Date.now(), subscription.expirationDate)?.days
        const subscriptionExpired = rangeToExpireInDays <= 0

        if (!subscriptionExpired) {
          return rangeToExpireInDays
        }
      }

      return 0
    })

    const subscriptionEndDate = computed(() => {
      const subscription = adminModule.organizationDetail?.tokenSubscription

      if (subscription && subscription.expirationDate) {
        return Utils.dateWithNamedMonth(subscription.expirationDate)
      }

      return null
    })

    const subscriptionIsExpired = computed(() => {
      return subscriptionExpirationTime.value === 0
    })

    return {
      t,
      WARNING_DAYS_BEFORE_EXPIRE,
      subscriptionIsExpired,
      subscriptionType,
      subscriptionEndDate,
      subscriptionExpirationTime,
      isSaas
    }
  }
})
</script>
