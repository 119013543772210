<template>
  <div class="genericAlertContainer">
    <div class="alertBody" :style="borderColor">
      <v-icon v-if="icon" :color="stateColor">
        {{ icon }}
      </v-icon>

      <div class="mainAlertContent">
        <slot />
      </div>

      <v-btn v-if="cancelText" @click="$emit('cancel')" :disabled="disabled || loading" large>
        {{ cancelText }}
      </v-btn>

      <v-btn
        v-if="action"
        @click="handleClick"
        :disabled="disabled || (loading && !buttonPressed)"
        :loading="loading && buttonPressed"
        color="primary"
        large
      >
        {{ action }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent, PropType } from "@vue/composition-api"

const COLORS = {
  error: "#f75b5e",
  warning: "#fcdc5c",
  info: "#4f6be8"
}

const ICON = {
  error: "status_error",
  warning: "status_warning",
  info: "vizartist_show_scene_info_editor"
}

type alertType = "warning" | "error" | "info"

export default defineComponent({
  name: "GenericAlert",
  props: {
    action: {
      type: String,
      required: false
    },
    cancelText: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String as PropType<alertType>,
      default: "error"
    }
  },
  setup(props, ctx) {
    const buttonPressed = ref(false)

    const stateColor = computed(() => {
      switch (props.type) {
        case "error":
          return COLORS.error
        case "warning":
          return COLORS.warning
        case "info":
        default:
          return COLORS.info
      }
    })

    const icon = computed(() => {
      switch (props.type) {
        case "error":
          return ICON.error
        case "warning":
          return ICON.warning
        case "info":
        default:
          return ICON.info
      }
    })

    const borderColor = computed(() => {
      return `borderColor: ${stateColor.value};`
    })

    const handleClick = () => {
      buttonPressed.value = true
      ctx.emit("click")

      setTimeout(() => {
        buttonPressed.value = false
      }, 15000)
    }

    return {
      icon,
      buttonPressed,
      handleClick,
      stateColor,
      borderColor
    }
  }
})
</script>
