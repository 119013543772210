<template>
  <div
    id="presentationBox"
    :online-status="app.onlineStatus"
    :status="workspace.status"
    class="animateFadeUp animationDelay-3"
  >
    <box-header
      @open:auto-shutdown="$emit('open:auto-shutdown')"
      :online-status="app.onlineStatus"
      :status="workspace.status"
      :is-deploying="isDeploying"
      :is-deployed="isDeployed"
      :has-error="hasError"
      :error-message="workspace.lastErrorTitle"
    />

    <box-body
      @cancel:shutdown="$emit('cancel:shutdown')"
      @postpone:shutdown="$emit('postpone:shutdown')"
      :background="backgroundImage"
      :workspace="workspace"
      :loading="loading"
    />

    <box-footer
      @set:status="status => $emit('set:status', status)"
      @open:ip-config="$emit('open:ip-config')"
      @open:dcv="$emit('open:dcv')"
      @archive="$emit('archive')"
      @restore="$emit('restore')"
      @deploy="$emit('deploy')"
      @open:ndi-bridge="$emit('open:ndi-bridge')"
      @open:nice-dcv="$emit('open:nice-dcv')"
      @open:both="$emit('open:both')"
      :online-status="app.onlineStatus"
      :status="workspace.status"
      :is-deploying="isDeploying"
      :is-deployed="isDeployed"
      :has-error="hasError"
      :loading="loading"
      :app="app"
      :workspace="workspace"
      :certificate-info="certificateInfo"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "@vue/composition-api"
import { IWorkspaceAsset, WorkspaceTemplate, WorkspaceOnlineStatusEnum } from "@/types/workspace"
import { WorkspaceStates } from "@/config/workspace-states"
import BoxHeader from "./BoxHeader.vue"
import BoxBody from "./BoxBody.vue"
import BoxFooter from "./boxFooter/BoxFooter.vue"
import backgroundMock from "../__mocks__/tricaster-background.jpg"

export default defineComponent({
  name: "PresentationBox",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    app: {
      type: Object as PropType<IWorkspaceAsset>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const isDeploying = computed(() => {
      return WorkspaceStates.isLoading(props.workspace.status)
    })

    const isDeployed = computed(() => {
      return WorkspaceStates.isDeployed(props.workspace.status)
    })

    const certificateInfo = computed(() => {
      return props.workspace.certificateInfo
    })

    const appChangingOnlineStatus = computed(() => {
      const { Starting, Stopping } = WorkspaceOnlineStatusEnum
      return [Starting, Stopping].includes(props.app.onlineStatus)
    })

    const hasError = computed(() => {
      return WorkspaceStates.failed(props.workspace.status)
    })

    const backgroundImage = computed(() => {
      return backgroundMock
    })

    return {
      certificateInfo,
      appChangingOnlineStatus,
      backgroundImage,
      hasError,
      isDeploying,
      isDeployed
    }
  },
  components: {
    BoxFooter,
    BoxBody,
    BoxHeader
  }
})
</script>
