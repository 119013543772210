import { createPinia, PiniaVuePlugin } from "pinia"
import Env from "@/utils/env"
import Vue from "vue"
import VueCompositionAPI from "@vue/composition-api"
import i18n from "./plugins/i18n"
import vuetify from "./plugins/vuetify"
import VueTippy from "vue-tippy"
import router from "./router"
import App from "./App.vue"

Vue.use(PiniaVuePlugin)
Vue.use(VueTippy, { delay: [750, 500] })
Vue.use(VueCompositionAPI)

Vue.config.devtools = Env.getEnv("VUE_APP_DEVTOOLS")
Vue.config.productionTip = false

export const pinia = createPinia()
export const eventBus = new Vue()

export default new Vue({
  router,
  i18n,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount("#app")
