import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inputLabel"},[_vm._t("label",function(){return [(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e()]}),_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"rules":_vm.rules,"placeholder":_vm.placeholder,"name":_vm.name,"disabled":_vm.isDisabled,"loading":_vm.isLoading,"hide-details":_vm.shouldHideDetails,"readonly":""},on:{"blur":function($event){return _vm.$emit('blur', _vm.value)},"change":function($event){return _vm.$emit('change', _vm.value)}},nativeOn:{"input":function($event){return _vm.$emit('input', _vm.value)}},model:{value:(_vm.activationDate),callback:function ($$v) {_vm.activationDate=$$v},expression:"activationDate"}},'v-text-field',attrs,false),on))]}}])},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }