import { Dictionary } from "@/types/core"
import { AxiosPromise, AxiosResponse, Method } from "axios"
import { IGenericResponse } from "./request"
import Timer from "@/config/throttling-cool-down-time"
import Utils from "./utils"

interface CachedRequest<T = unknown> {
  storedResponse: T
  coolDownDate: number
}

export interface ThrottlingConfig {
  ignoreCache: boolean
}

export default class {
  private static _coolDownTime = Timer.throttlingCoolDownTime
  private static _cachedRequests: Dictionary<CachedRequest> = {}

  public static async preventDuplicated<T>(
    endpoint: string,
    method: Method,
    config: ThrottlingConfig,
    request: () => AxiosPromise<IGenericResponse<T>>
  ): Promise<AxiosResponse<IGenericResponse<T>>> {
    const canRequest = this._canRequestUrl(endpoint)
    const methodNotTrackable = method !== "get"

    if (methodNotTrackable) {
      return await request()
    }

    if (canRequest) {
      const preventionTime = Utils.seconds(20)
      this._updateCoolDownTimer(endpoint, preventionTime, config)

      await this._fetchEndpointThenRegisterResponse(endpoint, request)
      this._updateCoolDownTimer(endpoint, 0, config)
    }

    return this._getEndpointResponse<T>(endpoint)
  }

  private static get _nextCoolDownTime() {
    return Date.now() + this._coolDownTime
  }

  private static _getEndpointResponse = <T>(endpoint: string): Promise<AxiosResponse<IGenericResponse<T>>> => {
    return new Promise<AxiosResponse<IGenericResponse<T>>>(resolve => {
      const checkResponse = () => {
        const storedResponse = this._cachedRequests[endpoint]?.storedResponse as AxiosResponse
        if (storedResponse && storedResponse.status) {
          resolve(storedResponse)
        } else {
          setTimeout(checkResponse, this._coolDownTime)
        }
      }

      checkResponse()
    })
  }

  private static _canRequestUrl(endpoint: string) {
    const cached = this._cachedRequests[endpoint]

    if (cached) {
      return Date.now() >= cached.coolDownDate
    }

    return true
  }

  private static _updateCoolDownTimer = (endpoint: string, nextCoolDown?: number, config = {} as ThrottlingConfig) => {
    let coolDown = nextCoolDown ? this._nextCoolDownTime + nextCoolDown : this._nextCoolDownTime
    const cached = this._cachedRequests[endpoint]
    const noCaching = config?.ignoreCache === true

    if (noCaching) {
      coolDown = 0
    }

    if (cached) {
      cached.coolDownDate = coolDown
    } else {
      this._cachedRequests[endpoint] = {
        storedResponse: {},
        coolDownDate: coolDown
      }
    }
  }

  private static async _fetchEndpointThenRegisterResponse<T>(
    endpoint: string,
    request: () => AxiosPromise<IGenericResponse<T>>
  ) {
    const response = await request()
    this._cachedRequests[endpoint].storedResponse = response
  }
}
