<template>
  <div id="formUploader" :is-dragging-over="isDraggingOver">
    <div class="uploaderIcon">
      <v-icon>action_attach</v-icon>
    </div>

    <input
      @change="onUploadFile"
      @dragover="isDraggingOver = true"
      @dragend="isDraggingOver = false"
      @dragleave="isDraggingOver = false"
      @mouseleave="isDraggingOver = false"
      :key="fileListWithoutDuplicates.length"
      :title="false"
      :multiple="multiple"
      :accept="formats"
      type="file"
      class="uploaderHandler"
      rbt-target="uploader"
    />

    <div class="uploaderContainer">
      <div v-if="fileListWithoutDuplicates.length" class="filesUploaded">
        <div v-for="file in fileListWithoutDuplicates" :key="file.name" class="fileItem">
          <tooltip :text="file.name">
            <span class="fileName">
              {{ fileNameWithoutType(file.name) }}
            </span>
          </tooltip>

          <span class="fileSize"> {{ fileSizeInKb(file.size) }} Kb </span>

          <span class="fileType">
            {{ fileFormat(file.name) }}
          </span>

          <span class="action">
            <v-btn @click="removeFile(file)" icon>
              <v-icon color="error">action_close</v-icon>
            </v-btn>
          </span>
        </div>
      </div>

      <span v-else>{{ $t("FormUploader.clickOrDrop") }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "@vue/composition-api"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"
import "./scss/_formUploader.scss"

export default defineComponent({
  name: "FormUploader",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    formats: {
      type: String,
      default: "*"
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup(_, ctx) {
    const isDraggingOver = ref(false)
    const fileList = ref<File[]>([])

    const fileListWithoutDuplicates = computed(() => {
      return fileList.value.reduce((collection: File[], file) => {
        const hasFile = collection.some(_file => _file.name === file.name)

        if (!hasFile) {
          collection.push(file)
        }

        return collection
      }, [])
    })

    const fileNameWithoutType = (fileName: string) => {
      return fileName.substr(0, fileName.indexOf("."))
    }

    const fileFormat = (fileName: string) => {
      return fileName.substr(fileName.indexOf(".") + 1)
    }

    const removeFile = (file: File) => {
      fileList.value = fileList.value.filter(_file => _file.name !== file.name)
      ctx.emit("upload", fileListWithoutDuplicates.value)
    }

    const fileSizeInKb = (fileSize: number) => {
      return Math.floor(fileSize / 1000)
    }

    const onUploadFile = (event: InputEvent) => {
      const $input = Utils.isType<HTMLInputElement>(event.target, "files")
      isDraggingOver.value = false

      if ($input && $input.files?.length) {
        fileList.value = []

        if ($input && $input.files) {
          fileList.value = [...$input.files]

          ctx.emit("upload", fileListWithoutDuplicates.value)
        }
      }
    }

    return {
      fileSizeInKb,
      fileFormat,
      onUploadFile,
      removeFile,
      fileNameWithoutType,
      isDraggingOver,
      fileListWithoutDuplicates
    }
  },
  components: {
    Tooltip
  }
})
</script>
