<template>
  <div class="onboardingItem stepOne">
    <div class="contentTitle">
      <label>
        <span class="field-label">
          {{ $t("Onboarding.stepOne.alert") }}
        </span>
      </label>

      <v-btn @click="nextStep" :loading="loader.on" color="primary" large>
        {{ $t("Onboarding.buttons.next") }}
      </v-btn>
    </div>

    <div class="wrapHandlers">
      <v-form ref="DomainForm" @submit.prevent="nextStep">
        <domain-name
          v-model="domainNamePicked"
          @validate:dns="validateDomain"
          :static-domain="staticDomain"
          :domain-state="domainStateCheck"
        />
      </v-form>

      <v-form ref="IpForm">
        <ip-range-handler v-model="ipRange" />
      </v-form>
    </div>

    <div class="cloudConfig">
      <v-form ref="Form">
        <cloud-provider v-model="selectedRegions" />
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, computed } from "@vue/composition-api"
import { VForm } from "@/types/core"
import { LicenseModule } from "@/store/license-server"
import { UsersModule } from "@/store/users"
import { DEFAULTS } from "../../lib/cloud-provider-options"
import { translate } from "@/plugins/i18n"
import { raiseError } from "@/utils/event-bus"
import { getEnv } from "@/utils/env"
import Loader from "@/utils/loader"
import Utils from "@/utils/utils"
import CloudProvider, { CloudSettings } from "./CloudProvider.vue"
import DomainName from "./DomainName.vue"
import IpRangeHandler from "./IpRangeHandler.vue"

export default defineComponent({
  name: "RegionProviderRoot",
  setup(_, ctx) {
    const Form = ref<null | VForm>(null)
    const IpForm = ref<null | VForm>(null)
    const DomainForm = ref<null | VForm>(null)
    const licenseModule = LicenseModule()
    const usersModule = UsersModule()
    const domainNamePicked = ref("")
    const domainStateCheck = ref(false)
    const staticDomain = getEnv("VUE_APP_ONBOARDING_DOMAIN")
    const loader = Loader()

    const ipRange = reactive({
      ip: "",
      mask: "16"
    })

    const selectedRegions = reactive<CloudSettings>({
      cloudZones: [],
      cloudProvider: DEFAULTS.cloudProvider
    })

    const registeredDomain = computed(() => {
      return `${domainNamePicked.value}.${staticDomain}`
    })

    const validateDomain = async () => {
      const domainForm = Utils.isType<VForm>(DomainForm.value, "validate")

      if (domainForm && domainForm.validate()) {
        return await loader.run(async () => {
          if (!domainNamePicked.value) {
            return false
          }

          const domain = domainNamePicked.value
          const available = await licenseModule.validateDomainName(domain)

          domainStateCheck.value = available
          return available
        }, translate("Onboarding.stepOne.warning.invalidDomain"))
      }

      domainStateCheck.value = false
      return false
    }

    const _registerDomainName = async () => {
      await loader.run(async () => {
        if (await validateDomain()) {
          const organizationId = usersModule.selectedOrganizationId
          await licenseModule.registerDomainName(domainNamePicked.value, organizationId)
        }
      })
    }

    const validateIp = async () => {
      const ipForm = Utils.isType<VForm>(IpForm.value, "validate")

      if (ipForm && ipForm.validate()) {
        try {
          const ip = ipRange.ip ? `${ipRange.ip}/${ipRange.mask}` : ""
          return await licenseModule.insertCidrBlocks(usersModule.selectedOrganizationId, ip)
        } catch (err) {
          const errMsg = (err ?? translate("Onboarding.stepOne.warning.invalidIP")) as string
          raiseError({ text: errMsg })

          return false
        }
      }

      return false
    }

    const _validate = async () => {
      const hasValidIP = ref(false)
      const hasValidDomain = ref(false)

      await loader.run(async () => {
        hasValidDomain.value = Boolean(await validateDomain())
        hasValidIP.value = await validateIp()
      })

      return {
        hasValidIP: hasValidIP.value,
        hasValidDomain: hasValidDomain.value
      }
    }

    const nextStep = async () => {
      const form = Utils.isType<VForm>(Form.value, "validate")
      const { cloudZones, cloudProvider } = selectedRegions
      const { organizationId } = usersModule.selfDetail
      const { hasValidIP, hasValidDomain } = await _validate()

      if (form && form.validate() && hasValidDomain && hasValidIP) {
        const license = licenseModule.licenseServer

        license.cloudProvider = cloudProvider
        license.selectedRegions = cloudZones
        license.organizationId = organizationId
        license.domainName = registeredDomain.value
        license.ipRange = ipRange.ip ? `${ipRange.ip}/${ipRange.mask}` : ""

        await _registerDomainName()
        ctx.emit("next")
      }
    }

    loader.run(async () => {
      await licenseModule.getDefaultCidrIP()
      ipRange.ip = licenseModule.defaultCidrIP
    })

    return {
      loader,
      Form,
      IpForm,
      selectedRegions,
      nextStep,
      domainNamePicked,
      DomainForm,
      domainStateCheck,
      validateDomain,
      ipRange,
      staticDomain
    }
  },
  components: {
    DomainName,
    IpRangeHandler,
    CloudProvider
  }
})
</script>
