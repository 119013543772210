<template>
  <div id="headerController">
    <div class="leftBox">
      <div class="closeItems">
        <share v-model="workspace" @change="rippleChangeSharedState" :loading="loading" />

        <auto-shutdown-trigger
          @show:postpone="showPostponeAutoShutdownPopup = true"
          @show:shutdown="showAutoShutdown = true"
        />
      </div>

      <vpc-status-label @click="openVpc" />
    </div>

    <div class="rightBox">
      <controllers
        v-model="addAppOpened"
        @save:instructions="value => $emit('save:instructions', { ...workspace, instructions: value })"
        @add:ip="ip => $emit('add:ip', ip)"
        @remove:ip="ip => $emit('remove:ip', ip)"
        @add:user="user => $emit('add:user', user)"
        @remove:user="user => $emit('remove:user', user)"
        @add:apps="apps => $emit('add:apps', apps)"
        @refresh="$emit('refresh')"
        @deploy="$emit('deploy')"
        @archive="$emit('archive')"
        @delete="$emit('delete')"
        @destroy="$emit('destroy')"
        @restore="$emit('restore')"
        @download:dot-now-file="$emit('download:dot-now-file')"
        @open:auto-shutdown="showAutoShutdown = true"
        @open:vpc-peering="openVpc"
        @open:file-transfer="fileTransferOpen = true"
        :workspace="workspace"
        :loading="loading"
      />
    </div>

    <file-transfer
      v-model="fileTransferOpen"
      @set:file-transfer="fileConfig => $emit('set:file-transfer', fileConfig)"
    />

    <multi-vpc v-if="vpcPeeringOpen" v-model="vpcPeeringOpen" :loading="loading" />

    <auto-shutdown v-if="showAutoShutdown" v-model="showAutoShutdown" :locked="lockAutoShutdown" :loading="loading" />

    <postpone-auto-shutdown-popup
      v-if="shouldShowAutoShutdownAlert"
      v-model="showPostponeAutoShutdownPopup"
      @continue:shutdown="cancelAlarm"
      @restart:notifications="alarmIsOn = true"
      :time-remaining="autoShutdownRemainingTime"
      :workspace-id="workspace.id"
      :preventing-notifications="alarmIsOn"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, onBeforeUnmount } from "@vue/composition-api"
import { AutoShutdownStatus, WorkspaceTemplate } from "@/types/workspace"
import { WorkspaceModule } from "@/store/workspace"
import { UsersModule } from "@/store"
import { appLoading, workspaceIsLoading } from "@/config/workspace-states"
import { playSound } from "@/utils/play-sound"
import { createTranslationModule, translate } from "@/plugins/i18n"
import * as Notification from "@/utils/web-notifications"
import Controllers from "./Controllers.vue"
import Share from "./Share.vue"
import Utils from "@/utils/utils"
import FileTransfer from "./fileTransfer/FileTransferRoot.vue"
import MultiVpc from "./multipleVpc"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import VpcStatusLabel from "./_VpcStatusLabel.vue"
import AutoShutdownPopup from "./_AutoShutdownPopup.vue"
import PostponeAutoShutdownPopup from "./_PostponeAutoShutdownPopup.vue"
import Timer from "@/config/app-loop-timers"
import AutoShutdown from "@/components/auto-shutdown"
import AutoShutdownTrigger from "./_AutoShutdownTrigger.vue"

export default defineComponent({
  name: "HeaderRoot",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const t = createTranslationModule("WorkspaceEdit")
    const addAppOpened = Utils.vModel(props, ctx.emit)
    const workspaceModule = WorkspaceModule()
    const usersModule = UsersModule()
    const fileTransferOpen = ref(false)
    const showAutoShutdown = ref(false)
    const vpcPeeringOpen = ref(false)
    const showPostponeAutoShutdownPopup = ref(false)
    const alarmIsOn = ref(true)
    const isMounted = ref(true)

    const rippleChangeSharedState = (shared: boolean) => {
      ctx.emit("change:shared", shared)
    }

    const hasAdminLevelPermissions = computed(() => {
      return usersModule.hasAdminPermissions
    })

    const vpc = computed(() => {
      return workspaceModule.vpc
    })

    const meetsNotificationConditions = computed(() => {
      const shutdownSettings = props.workspace?.autoShutdownDefinition

      if (shutdownSettings && spaceIsOnline.value) {
        const isCloseToShuttingDown = shutdownSettings.status === AutoShutdownStatus.nearAutoShutdown
        const userNotLooking = document.visibilityState === "hidden"

        return isCloseToShuttingDown && userNotLooking && alarmIsOn.value
      }

      return false
    })

    const shouldShowAutoShutdownAlert = computed(() => {
      return showPostponeAutoShutdownPopup.value && autoShutdownRemainingTime.value
    })

    const lockAutoShutdown = computed(() => {
      const isOnline = props.workspace.onlineStatus === "online"
      const loading = props.workspace.schemaDeployed?.assets.some(app => appLoading(app.onlineStatus))
      const spaceLoading = workspaceIsLoading(props.workspace.status)

      return isOnline || loading || spaceLoading
    })

    const autoShutdownRemainingTime = computed(() => {
      if (props.workspace?.autoShutdownDefinition && spaceIsOnline.value) {
        const { scheduledToShutdownAt } = props.workspace.autoShutdownDefinition
        const { hours, min } = Utils.dateRange(Date.now(), scheduledToShutdownAt)

        if (hours > 0) {
          return translate("AutoShutdown.time.hour", { hh: hours, mm: min })
        } else if (min > 0) {
          return translate("AutoShutdown.time.min", { mm: min })
        } else {
          return translate("AutoShutdown.time.lessThanZero")
        }
      }

      return false
    })

    const spaceIsOnline = computed(() => {
      return props.workspace.onlineStatus === "online"
    })

    const openVpc = () => {
      const ownerId = props.workspace.userId
      const myId = usersModule.selfDetail.id
      const isOwner = ownerId === myId

      if (hasAdminLevelPermissions || isOwner) {
        vpcPeeringOpen.value = true
      }
    }

    const cancelAlarm = () => {
      alarmIsOn.value = false
    }

    const _playSoundLoop = (times: number) => {
      const canPlaySound = meetsNotificationConditions.value

      if (times <= 0) {
        return
      }

      if (canPlaySound) {
        playSound.pong()
      }

      setTimeout(() => {
        _playSoundLoop(times - 1)
      }, 200)
    }

    const _alertUserWithSound = () => {
      const shutdownSettings = props.workspace?.autoShutdownDefinition
      const isCloseToShuttingDown = shutdownSettings?.status === AutoShutdownStatus.nearAutoShutdown

      if (meetsNotificationConditions.value) {
        _playSoundLoop(2)
      }

      if (isCloseToShuttingDown && alarmIsOn.value) {
        showPostponeAutoShutdownPopup.value = true
      }

      setTimeout(() => {
        if (isMounted.value) {
          _alertUserWithSound()
        }
      }, Timer.workspaceEditAlarm)
    }

    const _notifyBrowser = async () => {
      const canNotify = meetsNotificationConditions.value

      if (spaceIsOnline.value) {
        await Notification.getNotificationPermission()
      }

      if (canNotify) {
        Notification.notifyUser(
          t("browserNotification.title"),
          t("browserNotification.text", { timeLeft: autoShutdownRemainingTime.value as string })
        )
      }

      setTimeout(() => {
        if (isMounted.value) {
          _notifyBrowser()
        }
      }, Timer.notifyBrowser)
    }

    _alertUserWithSound()
    _notifyBrowser()

    onBeforeUnmount(() => {
      isMounted.value = false
      Notification.destroyEvents()
    })

    return {
      alarmIsOn,
      vpc,
      shouldShowAutoShutdownAlert,
      cancelAlarm,
      autoShutdownRemainingTime,
      lockAutoShutdown,
      showPostponeAutoShutdownPopup,
      openVpc,
      showAutoShutdown,
      vpcPeeringOpen,
      addAppOpened,
      fileTransferOpen,
      hasAdminLevelPermissions,
      rippleChangeSharedState
    }
  },
  components: {
    AutoShutdown,
    VpcStatusLabel,
    PostponeAutoShutdownPopup,
    Controllers,
    Tooltip,
    Share,
    MultiVpc,
    AutoShutdownPopup,
    AutoShutdownTrigger,
    FileTransfer
  }
})
</script>
