import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('popup',{attrs:{"title":_vm.$t('OrganizationUsers.userEditor.title')},on:{"confirm":_vm.save},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{attrs:{"id":"userEditor"}},[_c('div',{staticClass:"summary"},[_c(VLabel,[_c('span',[_vm._v(_vm._s(_vm.$t("OrganizationUsers.userEditor.input.email"))+"*")]),_c(VTextField,{attrs:{"value":_vm.tempUser.email,"readonly":"","hide-details":""}})],1),_c(VSwitch,{attrs:{"label":_vm.$t('OrganizationUsers.userEditor.input.disableUser'),"rbt-target":"activateUserSwitch","hide-details":""},model:{value:(_vm.tempUser.disabled),callback:function ($$v) {_vm.$set(_vm.tempUser, "disabled", $$v)},expression:"tempUser.disabled"}})],1),_c(VForm,{ref:"Form"},[_c(VLabel,[_c('span',[_vm._v(_vm._s(_vm.$t("OrganizationUsers.userEditor.input.firstName"))+"*")]),_c(VTextField,{attrs:{"rules":_vm.rules,"rbt-target":"editFirstNameInput","hide-details":""},model:{value:(_vm.tempUser.firstName),callback:function ($$v) {_vm.$set(_vm.tempUser, "firstName", $$v)},expression:"tempUser.firstName"}})],1),_c(VLabel,[_c('span',[_vm._v(_vm._s(_vm.$t("OrganizationUsers.userEditor.input.lastName")))]),_c(VTextField,{attrs:{"rbt-target":"editLastNameInput","hide-details":""},model:{value:(_vm.tempUser.lastName),callback:function ($$v) {_vm.$set(_vm.tempUser, "lastName", $$v)},expression:"tempUser.lastName"}})],1),_c('div',{staticClass:"inputLabel"},[_c('span',[_vm._v(_vm._s(_vm.$t("OrganizationUsers.userEditor.input.role"))+"*")]),_c('span',{staticClass:"userRole"},[_c(VSelect,{attrs:{"rules":_vm.rules,"items":_vm.userRoles,"rbt-target":"userRole","hide-details":""},model:{value:(_vm.tempUser.role),callback:function ($$v) {_vm.$set(_vm.tempUser, "role", $$v)},expression:"tempUser.role"}}),_c('tooltip',{attrs:{"text":_vm.roleExplanation}},[_c(VIcon,[_vm._v("status_question")])],1)],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }