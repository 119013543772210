import type { BasicDeployPackageData, ServiceVersion } from "@/types/deployPackages"
import type { WorkspaceApplication } from "@/types/asset"
import type { CloudZone } from "@/types/core"
import type { ChangeLogs } from "@/types/messages"
import type { IUser, CreateUserPayload } from "@/types/user"
import type { OrganizationTag } from "@/types/organization"
import type { DeployPackagePayload, IDeployPackage, IDeployPackageVersion } from "@/types/deployPackages"
import type { EditSubscriptionPayload } from "@/types/organization"
import type { FeedbackItem } from "@/types/feedback"
import type { PlatformAsset } from "@/types/asset"
import { defineStore } from "pinia"
import { Admin, Assets, Workspace, Deployment, Feedback, ChangeLog, SystemMessage, Organization } from "@/services"
import { downloadFromBase64 } from "@/utils/download"
import { raiseError, raiseWarning } from "@/utils/event-bus"
import { translate, translatePlural } from "@/plugins/i18n"
import { updateCloudZonePing } from "@/utils/cloud-zones/ping-cloud-zones"
import CloudHandler from "@/utils/cloud-zones"
import Utils from "@/utils/utils"

import {
  OrganizationType,
  type IOrganization,
  type OrganizationItem,
  type SimpleOrganizationItem,
  type CreateOrganizationPayload,
  type AddTokenPayload,
  type RemoveTokenPayload
} from "@/types/organization"

import {
  type WorkspaceTemplate,
  type WorkspaceRawTemplate,
  type LicenseProduct,
  WorkspaceOnlineStatusEnum,
  CertificateVisibleState,
  CertificateStatus
} from "@/types/workspace"

export const AdminModule = defineStore("admin", {
  state: () => ({
    cloudProviders: [] as CloudZone[],
    cloudProviderConfig: {
      cloudZones: [] as CloudZone[],
      supportedRegions: [] as string[]
    },
    bestRegions: [] as CloudZone[],
    cloudZones: new CloudHandler(),
    organizationCollection: [] as OrganizationItem[],
    simpleOrganizationCollection: [] as SimpleOrganizationItem[],
    licenseServer: {} as WorkspaceTemplate,
    organizationLicenses: [] as LicenseProduct[],
    organizationDetail: {} as IOrganization,
    organizationUsers: [] as IUser[],
    ownIp: "",
    deployPackages: [] as IDeployPackage[],
    packageVersions: [] as IDeployPackageVersion[],
    workspaceTemplates: [] as WorkspaceRawTemplate[],
    feedbacks: [] as FeedbackItem[],
    changeLogs: "",
    selectedOrganization: { text: "", value: "" },
    appConnectionDetail: { connectionUrl: "", password: "" },
    platformAssetModel: [] as PlatformAsset[],
    deployPackageServiceVersions: [] as ServiceVersion[]
  }),
  getters: {
    isCustomerHosted(state) {
      return state.organizationDetail?.organizationType === OrganizationType.CustomerHosted
    },
    isSaaS(state) {
      return state.organizationDetail?.organizationType === OrganizationType.SaaS
    }
  },
  actions: {
    alertOrganizationExpireDate() {
      if (this.organizationDetail) {
        const { warning, danger } = CertificateVisibleState
        const state = this.organizationDetail.certificateInfo?.visibleState
        const status = this.organizationDetail.certificateInfo?.status
        const expired = new Date(this.organizationDetail.certificateInfo?.expiresAt as string).getTime() < Date.now()
        const isValidatingSSL = status === CertificateStatus.validating

        if (state && !isValidatingSSL) {
          const expiresAt = this.organizationDetail.certificateInfo?.expiresAt as string
          const { days, hours, min } = Utils.dateRange(Date.now(), expiresAt)
          const translatedDay = translatePlural("CertificateWarning.day", days)

          if (expired) {
            raiseError({ text: translate("CertificateWarning.expired") })
          } else if (state === warning) {
            raiseWarning({
              text: translate("CertificateWarning.willExpire", { expiresAt: `${days} ${translatedDay}` })
            })
          } else if (state === danger) {
            if (days) {
              raiseError({
                text: translate("CertificateWarning.willExpire", { expiresAt: `${days} ${translatedDay}` })
              })
            } else {
              raiseError({
                text: translate("CertificateWarning.willExpire", {
                  expiresAt: hours ? `${hours}h ${min}min` : `${min}min`
                })
              })
            }
          }
        }
      }
    },

    sortZones() {
      this.cloudProviders = this.cloudProviders.sort((a, b) => {
        return (a.ping ?? 0) - (b.ping ?? 0)
      })
    },

    async getPlatformAssets() {
      const req = await Assets.getPlatformAssets()
      this.platformAssetModel = req.data.data

      return this.platformAssetModel
    },

    async getPlatformAssetById(assetId: string) {
      const req = await Assets.getPlatformAssetById(assetId)
      return req.data.data
    },

    async createAsset(assetDetails: WorkspaceApplication) {
      return await Assets.create(assetDetails)
    },

    async editAsset(assetDetails: WorkspaceApplication) {
      await Assets.edit(assetDetails)
    },

    async deleteAsset(assetId: string) {
      await Assets.delete(assetId)
    },

    async getSupportedRegions(cloudZones: CloudZone[]): Promise<void> {
      const req = await Admin.getSupportedRegions()
      const supportedRegions = req.data.data

      this.cloudZones.register(cloudZones)

      this.bestRegions = await this.cloudZones.getZones(supportedRegions)
    },

    async getCloudZones(cloudZones: CloudZone[]) {
      const req = await Admin.getSupportedRegions()
      const supportedRegions = req.data.data

      this.cloudProviderConfig.cloudZones = cloudZones
      this.cloudProviderConfig.supportedRegions = supportedRegions

      this.cloudProviders = await updateCloudZonePing(cloudZones, supportedRegions, this.cloudProviders)
    },

    async refreshRegionPing(ignoreUpdate: boolean) {
      const { cloudZones, supportedRegions } = this.cloudProviderConfig
      const cloudProviders = await updateCloudZonePing(cloudZones, supportedRegions, this.cloudProviders)

      if (!ignoreUpdate) {
        this.cloudProviders = [...cloudProviders]
      }
    },

    async setSystemMessage(message: string) {
      await SystemMessage.setNewMessage(message)
    },

    async setSystemMessageActivity(state: boolean) {
      await SystemMessage.setActive(state)
    },

    async getOrganizationUsers(organizationId: string) {
      const req = await Admin.getOrganizationUsers(organizationId)
      this.organizationUsers = req.data.data
    },

    async getCurrentChangeLogs() {
      const req = await ChangeLog.getCurrentChangeLogs()
      this.changeLogs = req.data.data.changeLog
    },

    async releaseChangeLogs(changeLog: ChangeLogs) {
      await ChangeLog.releaseChangeLogs(changeLog)
      await this.getCurrentChangeLogs()
    },

    async resetViews() {
      await ChangeLog.resetViews()
    },

    async deleteUser(userId: string, organizationId: string) {
      await Admin.deleteUser(userId, organizationId)
    },

    async getFeedbacks() {
      const req = await Feedback.getFeedbacks()
      const feedbacks = req.data.data

      feedbacks.forEach(item => (item.solvedTemp = false))
      this.feedbacks = feedbacks
    },

    async resolveFeedback(feedback: FeedbackItem) {
      await Feedback.resolveFeedback(feedback)
    },

    async editUser(userData: IUser, organizationId: string) {
      const { id, firstName, lastName, role, disabled } = userData

      await Admin.editUser({ id, firstName, lastName, role, disabled }, organizationId)
      await this.getOrganizationUsers(organizationId)
    },

    async createUser(user: CreateUserPayload, organizationId: string) {
      await Admin.createUser(user, organizationId)
    },

    async getOrganizationCollection() {
      const req = await Admin.getOrganizationCollection()
      this.organizationCollection = [...req.data.data]
    },

    async getSimpleOrganizationCollection() {
      const req = await Admin.getSimpleOrganizationCollection()
      this.simpleOrganizationCollection = [...req.data.data]
    },

    async resetTermsAndConditions() {
      await Admin.resetTermsAndConditions()
    },

    async getAllWorkspaceTemplates() {
      const req = await Admin.getAllWorkspaceTemplates()
      this.workspaceTemplates = req.data.data
    },

    async editWorkspaceTemplate(templateId: string, deployPackageId: string) {
      await Admin.editWorkspaceTemplate(templateId, deployPackageId)
    },

    async setWorkspaceTemplateInstruction(templateId: string, instruction: string) {
      await Admin.setWorkspaceTemplateInstruction(templateId, instruction)
    },

    async getOrganizationDetail(organizationId: string) {
      if (organizationId) {
        const req = await Admin.getOrganizationDetail(organizationId)
        this.organizationDetail = req.data.data
      }
    },

    async editOrganizationTag(organizationId: string, tag: OrganizationTag) {
      await Organization.editTag(organizationId, tag)
    },

    async removeOrganizationTag(organizationId: string, tagName: string) {
      await Organization.removeTag(organizationId, tagName)
    },

    async editOrganizationGlobalTag(organizationId: string, tag: OrganizationTag) {
      await Organization.editGlobalTag(organizationId, tag)
    },

    async removeOrganizationGlobalTag(organizationId: string, tagName: string) {
      await Organization.removeGlobalTag(organizationId, tagName)
    },

    async getOrganizationWithExpireError(organizationId: string) {
      await this.getOrganizationDetail(organizationId)
      this.alertOrganizationExpireDate()
    },

    async renewCertificate(organizationId: string) {
      await Organization.renewCertificate(organizationId)
    },

    async addToken(organizationId: string, addTokenPayload: AddTokenPayload) {
      await Organization.addToken(organizationId, addTokenPayload)
    },

    async removeToken(organizationId: string, removeTokenPayload: RemoveTokenPayload) {
      await Organization.removeToken(organizationId, removeTokenPayload)
    },

    async editOrganizationSubscription(organizationId: string, editSubscriptionPayload: EditSubscriptionPayload) {
      await Admin.editSubscription(organizationId, editSubscriptionPayload)
    },

    async updateWorkspaceTemplate(workspaceTemplate: string[], organizationId: string) {
      await Admin.updateWorkspaceTemplate(workspaceTemplate, organizationId)
      await this.getOrganizationDetail(organizationId)

      return this.organizationDetail
    },

    async createOrganization(createOrganizationPayload: CreateOrganizationPayload) {
      const req = await Admin.createOrganization(createOrganizationPayload)
      await this.getOrganizationCollection()

      return req.data.data.id
    },

    async changeOrganizationName(orgName: string, orgId: string) {
      await Admin.changeOrganizationName(orgName, orgId)
    },

    async deleteOrganization(organizationId: string) {
      await Admin.deleteOrganization(organizationId)
      await this.getOrganizationCollection()
    },

    async getLicense(organizationId: string, action: "reset" | "soft" = "reset") {
      if (action === "reset") {
        this.licenseServer = {} as WorkspaceTemplate
      }

      await this.getLicenseOnlineStateChange(organizationId)

      return this.licenseServer
    },

    async getLicenseOnlineStateChange(organizationId: string | undefined) {
      if (organizationId) {
        const req = await Workspace.getLicenseServer(organizationId)
        this.licenseServer = req.data.data
      }
    },

    async downloadDeploySpaceTemplate(deployPackageId: string, versionId: string, fileName: string) {
      const req = await Admin.downloadDeploySpaceTemplate(deployPackageId, versionId)

      if (req.data.data?.zipFileBase64) {
        downloadFromBase64(req.data.data.zipFileBase64, `${fileName}.zip`)
      }
    },

    async getOwnIp(): Promise<string> {
      const req = await Admin.getOwnIp()
      this.ownIp = req.data.data

      return this.ownIp
    },

    async getDeployPackages() {
      const req = await Admin.getDeployPackages()
      this.deployPackages = req.data.data

      return this.deployPackages
    },

    async createDeployPackage(deployData: BasicDeployPackageData) {
      const req = await Admin.createDeployPackage(deployData)
      await this.getDeployPackages()

      return req.data.data
    },

    async releaseDeployPackageVersion(deployData: DeployPackagePayload) {
      await Admin.releaseDeployPackageVersion(deployData.deployPackageId, deployData)
      await this.getDeployPackages()
      await this.getPackageVersions(deployData.deployPackageId)

      return await this.getDeployPackageDetails(deployData.deployPackageId)
    },

    async getPackageVersions(deployPackageId: string) {
      const req = await Admin.getPackageVersions(deployPackageId)
      this.packageVersions = req.data.data
    },

    async getDeployPackageDetails(deployPackageId: string) {
      const req = await Admin.getDeployPackageDetails(deployPackageId)
      return req.data.data
    },

    async deleteDeployPackage(deployPackageId: string) {
      await Admin.deleteDeployPackage(deployPackageId)
      await this.getDeployPackages()
    },

    async pickPackageVersion(deployPackageId: string, versionId: string) {
      await Admin.pickPackageVersion(deployPackageId, versionId)
    },

    async updateDeployPackage(packageData: IDeployPackage) {
      await Admin.updateDeployPackage(packageData)
    },

    async getOrganizationLicenses(organizationId: string) {
      const req = await Admin.getOrganizationLicenses(organizationId)

      this.organizationLicenses = req.data.data
    },

    async downloadWorkspaceShortcut(workspaceId: string, appId: string) {
      await Deployment.downloadWorkspaceShortcut(workspaceId, appId)
    },

    async getDeployPackageServiceVersions() {
      const req = await Admin.getDeployPackageServiceVersions()
      this.deployPackageServiceVersions = req.data.data.options
    },

    async downloadDotNowFile(workspaceId: string, workspaceName: string, appId?: string) {
      await Deployment.downloadDotNowFile(workspaceId, workspaceName, appId)
    },

    async updateOrganization(organization: IOrganization) {
      await Admin.updateOrganizationDetails({
        id: organization.id,
        cloudProviderName: organization.cloudProvider.name,
        cloudZones: organization.cloudZones
      })
    },

    async switchLicenseOnlineStatus(workspaceId: string, onlineStatus: "online" | "offline") {
      const { Starting, Stopping } = WorkspaceOnlineStatusEnum
      const state = onlineStatus === "online" ? Starting : Stopping

      const req = await Deployment.appSetOnlineState(workspaceId, state)
      this.licenseServer.schema.assets = req.data.data.schema.assets
    },

    async getWorkspaceAppPassword(workspaceId: string, appId: string) {
      const req = await Deployment.getAppPassword(workspaceId, appId)
      return req.data.data
    },

    async getWorkspaceAppInstanceLink(workspaceId: string, appId: string) {
      const req = await Deployment.getAppInstanceLink(workspaceId, appId)
      this.appConnectionDetail = req.data.data
      return this.appConnectionDetail
    },

    changeOrganization(organizationId: string) {
      const organization = this.simpleOrganizationCollection.find(org => org.id === organizationId)

      if (organization) {
        this.selectedOrganization = {
          text: organization.name,
          value: organization.id
        }
      }
    }
  }
})
