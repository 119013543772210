<template>
  <div id="identity">
    <organization-details
      @change-name="changeName"
      :loading="loader.on"
      :has-permissions="hasPermissionsToEdit"
      :file-list="organizationFileList"
    />

    <logo-uploader v-if="hasPermissionsToEdit" @update="updatePlatform" :file-list="organizationFileList" />

    <ssl-details />
  </div>
</template>

<script lang="ts">
import { IOrganization } from "@/types/organization"
import { defineComponent, ref, computed, inject } from "@vue/composition-api"
import { AdminModule, UsersModule, UploadModule } from "@/store"
import { IMedia } from "@/types/media"
import { resolveChangeOrganizationId, updatePlatformLogo } from "@/utils/event-bus"
import Loader from "@/utils/loader"
import OrganizationDetails from "./_OrganizationDetails.vue"
import LogoUploader from "./_LogoUploader.vue"
import SslDetails from "./_SslDetails.vue"

export default defineComponent({
  name: "IdentityRoot",
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(_, ctx) {
    const adminModule = AdminModule()
    const usersModule = UsersModule()
    const uploadModule = UploadModule()
    const organizationFileList = ref<IMedia[]>([])
    const organization = inject("organization") as IOrganization
    const loader = Loader()

    const hasPermissionsToEdit = computed(() => {
      return usersModule.hasGodPermissions
    })

    const changeName = (newName: string) => {
      loader.run(async () => {
        await adminModule.changeOrganizationName(newName, organization.id)
        await adminModule.getSimpleOrganizationCollection()
        resolveChangeOrganizationId(organization.id)
        ctx.emit("update:organization")
      })
    }

    const updateFileList = async () => {
      await loader.run(async () => {
        organizationFileList.value = await uploadModule.getContextId({
          contextId: organization.id,
          context: "organization-logo",
          ignoreExhibition: false
        })
      })
    }

    const updatePlatform = async () => {
      await updateFileList()
      updatePlatformLogo()
    }

    updateFileList()

    return {
      organization,
      organizationFileList,
      updateFileList,
      hasPermissionsToEdit,
      changeName,
      loader,
      updatePlatform
    }
  },
  components: {
    SslDetails,
    LogoUploader,
    OrganizationDetails
  }
})
</script>
