import { Dictionary } from "@/types/core"

export const AVERAGE_STATUS_TIME = 1000 * 30

export enum ProgressCacheKey {
  APP_LAUNCHER_CACHE_KEY = "_app-launcher-progress-bar",
  ARCHIVING_WORKSPACE = "_workspace-archiving-app-progress-bar",
  DEPLOYING_CACHE_KEY = "_workspace-deploying-app-progress-bar"
}

interface CachedEstimatedTime {
  appId: string
  endPrediction: number
}

interface EstimateTimeConfig {
  progressKey?: string
  averageTime?: number
  lastModified: number
}

export const ProgressKey: Dictionary<ProgressCacheKey> = {
  APP_LAUNCHER_CACHE_KEY: ProgressCacheKey.APP_LAUNCHER_CACHE_KEY,
  ARCHIVING_WORKSPACE: ProgressCacheKey.ARCHIVING_WORKSPACE,
  DEPLOYING_CACHE_KEY: ProgressCacheKey.DEPLOYING_CACHE_KEY
}

const DEFAULT_CONFIG: EstimateTimeConfig = {
  progressKey: ProgressKey.APP_LAUNCHER_CACHE_KEY,
  averageTime: AVERAGE_STATUS_TIME,
  lastModified: 0
}

export class EstimatedTime {
  private _lastRegisteredCacheKey!: string
  private _registeredAppId!: string
  private _averageTime!: number
  private _lastModified!: number

  private _getCacheTime(): CachedEstimatedTime | null {
    return JSON.parse(localStorage.getItem(this._lastRegisteredCacheKey) as string)
  }

  private _setCache(estimatedTime: CachedEstimatedTime): CachedEstimatedTime {
    localStorage.setItem(this._lastRegisteredCacheKey, JSON.stringify(estimatedTime))
    return estimatedTime
  }

  constructor(appId: string, config: EstimateTimeConfig) {
    this._registeredAppId = appId
    this._lastRegisteredCacheKey = config?.progressKey || (DEFAULT_CONFIG.progressKey as string)
    this._averageTime = config?.averageTime || (DEFAULT_CONFIG.averageTime as number)
    this._lastModified = config.lastModified

    this.estimated()
  }

  public get recoverRegisteredKey() {
    return this._lastRegisteredCacheKey
  }

  public get recoverRegisteredAverageTime() {
    return this._averageTime
  }

  public static get progressKeys() {
    return ProgressKey
  }

  public static get averageDefaultTime() {
    return AVERAGE_STATUS_TIME
  }

  public estimated(): CachedEstimatedTime {
    const cachedTime = this._getCacheTime()

    if (cachedTime) {
      return cachedTime
    } else {
      return this._setCache({
        appId: this._registeredAppId,
        endPrediction: this._lastModified + this.recoverRegisteredAverageTime
      })
    }
  }

  public clear() {
    localStorage.removeItem(this._lastRegisteredCacheKey)
  }
}

export const clearAllTimers = () => {
  for (const key in ProgressKey) {
    localStorage.removeItem(ProgressKey[key])
  }
}
