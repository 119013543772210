import axios, { AxiosPromise, AxiosResponse, Method, AxiosRequestHeaders } from "axios"
import { sessionOn, sessionToken, signOut } from "@/auth"
import { RequestErrorResponse } from "@/types/core"
import { cleanCachedOrganization } from "@/utils/organization-helper"
import { navigate } from "@/router"
import { translate } from "@/plugins/i18n"
import Throttling, { ThrottlingConfig } from "./throttling-control"
import env from "./env"

// eslint-disable-next-line
type RequestBody = any

export type IGenericResponse<T> = { success: boolean; data: T; error: RequestErrorResponse }

const service = axios.create({
  baseURL: env.VUE_APP_BASE_API,
  headers: { "Content-Type": "application/json" }
})

const rejectWithErrorDetail = (error: RequestBody) => {
  const response = error.response as AxiosResponse<IGenericResponse<unknown>>

  if (response?.data?.error) {
    return Promise.reject(response.data.error)
  } else if (error?.response?.statusText) {
    return Promise.reject(error.response.statusText)
  } else {
    return Promise.reject(error)
  }
}

service.interceptors.request.use(async config => {
  if (config) {
    const token = await sessionToken()
    const headers = config.headers ?? ({} as AxiosRequestHeaders)

    if (!token) {
      throw Error(translate("common.invalidToken"))
    }

    headers.Authorization = "Bearer " + token
    config.headers = headers
  }

  return config
})

service.interceptors.response.use(undefined, error => {
  switch (error.response?.status) {
    case 401: {
      cleanCachedOrganization()
      signOut()
      break
    }

    case 403: {
      const isGetMethod = error.response.config.method === "get"

      if (isGetMethod) {
        if (sessionOn()) {
          navigate({ name: "ForbiddenContent" })
        } else {
          navigate({ name: "Login" })
        }
      } else {
        return rejectWithErrorDetail(error)
      }
      break
    }

    default: {
      return rejectWithErrorDetail(error)
    }
  }
})

export class Request {
  baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  apiRequest<T>(
    endpoint: string,
    method: Method = "get",
    data?: RequestBody,
    params?: RequestBody,
    throttlingConfig = {} as ThrottlingConfig,
    progressCallback: (progress: number) => number = p => p
  ): AxiosPromise<IGenericResponse<T>> {
    return Throttling.preventDuplicated(this.baseUrl + endpoint, method, throttlingConfig, () =>
      service({
        url: this.baseUrl + endpoint,
        method,
        params,
        data,
        onUploadProgress: progress => {
          const percentCompleted = Math.round((progress.loaded * 100) / (progress?.total ?? progress.loaded))
          progressCallback.call(null, percentCompleted)
        }
      })
    )
  }

  get<T>(
    endpoint: string,
    params?: RequestBody,
    throttlingConfig?: ThrottlingConfig
  ): AxiosPromise<IGenericResponse<T>> {
    return this.apiRequest<T>(endpoint, "get", null, params, throttlingConfig)
  }

  post<T>(endpoint: string, data?: RequestBody, params?: RequestBody): AxiosPromise<IGenericResponse<T>> {
    return this.apiRequest<T>(endpoint, "post", data, params)
  }

  put<T>(endpoint: string, data?: RequestBody, params?: RequestBody): AxiosPromise<IGenericResponse<T>> {
    return this.apiRequest<T>(endpoint, "put", data, params)
  }

  delete<T>(endpoint: string, data?: RequestBody, params?: RequestBody): AxiosPromise<IGenericResponse<T>> {
    return this.apiRequest<T>(endpoint, "delete", data, params)
  }

  upload<T>(
    endpoint: string,
    data?: RequestBody,
    progressFeedback?: (p: number) => number,
    params?: RequestBody
  ): AxiosPromise<IGenericResponse<T>> {
    return this.apiRequest<T>(endpoint, "post", data, params, undefined, progressFeedback)
  }

  async downloadItem(endpoint: string, fileName: string, contentType?: string): Promise<unknown> {
    const response = await service(this.baseUrl + endpoint, { responseType: "blob" })

    this.triggerDownloadBlob(new Blob([response.data], { type: contentType }), fileName)

    return response.data
  }

  async triggerDownloadBlob(blob: Blob, fileName: string): Promise<void> {
    const link = document.createElement("a")

    link.href = URL.createObjectURL(blob)
    link.download = fileName
    link.click()

    URL.revokeObjectURL(link.href)
  }

  /**
   * Convert Base64 to Blob,
   * see {@link `https://stackoverflow.com/a/16245768/7649295`}
   */
  b64toBlob(b64Data: string, contentType = "", sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) byteNumbers[i] = slice.charCodeAt(i)
      byteArrays.push(new Uint8Array(byteNumbers))
    }

    return new Blob(byteArrays, { type: contentType })
  }
}

export default service
