import { WorkspaceTemplate } from "@/types/workspace"
import { Dictionary } from "@/types/core"
import { updateFavicon } from "./update-favicon"

const spaceMetaData = {
  status: "SpaceTitleTabs.",
  title: "",
  online: ""
}

const STATUS_TABLE: Dictionary<string> = {
  idle: "idle",
  starting: "starting",
  stopping: "stopping",
  online: "online",
  offline: "offline",
  aborting: "aborting",
  archived: "archived",
  archiving: "archiving",
  restoring: "restoring",
  draft: "draft",
  deploying: "deploying",
  deployed: "deployed",
  "deployed-with-error": "deployedWithError",
  "deploy-failed": "deployFailed",
  destroying: "destroying",
  "destroy-failed": "destroyFailed"
}

const _onlineCount = (workspace: WorkspaceTemplate) => {
  const onlineCount = workspace.schemaDeployed?.assets.filter(space => space.onlineStatus === "online").length

  return `${onlineCount}/${workspace.schemaDeployed?.assets.length}`
}

export const createSpaceMetaData = (space: WorkspaceTemplate | WorkspaceTemplate) => {
  const metaData = { ...spaceMetaData }

  metaData.title = space.title
  updateFavicon(space)

  switch (space.status) {
    case "deployed":
      metaData.status += STATUS_TABLE[space.onlineStatus]

      if (space.onlineStatus === "online") {
        metaData.online = `(${_onlineCount(space as WorkspaceTemplate)})`
      }

      break

    default:
      metaData.status += STATUS_TABLE[space.status]
  }

  return metaData
}
