<template>
  <p class="appDescription">
    {{ description }}
    <a :href="manualLink" target="_blank" rel="nofollow">{{ $t("WorkspaceEdit.appCard.label.openManual") }}</a>
  </p>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "AppDescription",
  props: {
    description: {
      type: String,
      required: true
    },
    manualLink: {
      type: String,
      required: true
    }
  }
})
</script>
