<template>
  <div id="workspaceTemplates">
    <div class="titleRow">
      <v-label>
        <span> {{ $t("Admin.organizations.createPopup.template.title") }} </span>
      </v-label>

      <div v-if="hasGodLevelPermissions" class="controller">
        <v-label>
          <span>{{ $t("Admin.organizations.createPopup.template.select") }}</span>
        </v-label>

        <v-btn @click="selectAll" :disabled="loading" rbt-target="selectAllTemplates">
          {{ $t("Admin.organizations.createPopup.template.selectButton.all") }}
        </v-btn>

        <v-btn @click="selectNone" :disabled="loading">
          {{ $t("Admin.organizations.createPopup.template.selectButton.none") }}
        </v-btn>
      </div>
    </div>

    <div class="templateContainer">
      <div
        v-for="template in allTemplates"
        :key="template.id"
        :active="templateIsActive(template.id)"
        class="templateItem"
      >
        <v-checkbox
          @change="toggleActivateWorkspaceTemplate(template.id)"
          :disabled="loading || !hasGodLevelPermissions"
          :input-value="templateIsActive(template.id)"
          class="my-0 py-0"
          hide-details
        />

        <span class="templateName">{{ template.title }}</span>

        <span class="templatePlansAndTags">
          <span class="plan">{{ template.plan }} </span>
          <span class="tags">{{ spreadTags(template.tags) }}</span>
        </span>

        <div class="templateDescription">
          {{ template.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { AdminModule, UsersModule } from "@/store"

export default defineComponent({
  name: "WorkspaceTemplates",
  props: {
    workspaceTemplates: {
      type: Array as PropType<string[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const usersModule = UsersModule()
    const adminModule = AdminModule()

    const allTemplates = computed(() => {
      return adminModule.workspaceTemplates
    })

    const organizationDetail = computed(() => {
      return adminModule.organizationDetail
    })

    const hasGodLevelPermissions = computed(() => {
      return usersModule.hasGodPermissions
    })

    const selectAll = () => {
      const templateIdList = allTemplates.value.map(template => template.id)
      ctx.emit("update:workspace-template", templateIdList)
    }

    const selectNone = () => {
      ctx.emit("update:workspace-template", [])
    }

    const toggleActivateWorkspaceTemplate = (templateId: string) => {
      const isActive = templateIsActive(templateId)
      const newTemplateCollection = [...props.workspaceTemplates]

      if (isActive) {
        newTemplateCollection.splice(newTemplateCollection.indexOf(templateId), 1)
        ctx.emit("update:workspace-template", newTemplateCollection)
      } else {
        ctx.emit("update:workspace-template", [...newTemplateCollection, templateId])
      }
    }

    const templateIsActive = (templateId: string) => {
      return props.workspaceTemplates.includes(templateId)
    }

    const spreadTags = (tags: string[]) => {
      return tags.join(", ")
    }

    return {
      spreadTags,
      selectAll,
      selectNone,
      templateIsActive,
      hasGodLevelPermissions,
      toggleActivateWorkspaceTemplate,
      allTemplates,
      organizationDetail
    }
  }
})
</script>
