<template>
  <div class="statusOptions">
    <label class="checkbox">
      <v-checkbox
        class="py-0 my-0"
        @change="applySelectAll"
        :input-value="allSelected"
        :indeterminate="partialSelected"
        :key="selectedStatus.length"
        hide-details
      />

      <span class="field-label">Select all</span>
    </label>

    <ul class="statusOptionWrap">
      <li v-for="status in statusOptions" :key="status" :class="status">
        <label class="checkbox">
          <v-checkbox
            @change="updateStatusSelection(status)"
            :input-value="statusIsIncluded(status)"
            class="py-0 my-0"
            hide-details
          />

          <span class="field-label">{{ $t(`MyWorkspaces.statusOptions.${status}`) }}</span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/composition-api"
import { FilterStatusOption } from "@/utils/advancedFilter/type"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "PickStatus",
  props: {
    value: {
      type: Array as PropType<FilterStatusOption[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const selectedStatus = Utils.vModel(props, ctx.emit)

    const statusOptions = computed(() => {
      return Object.values(FilterStatusOption)
    })

    const partialSelected = computed(() => {
      if (selectedStatus.value.length > 0) {
        return selectedStatus.value.length < statusOptions.value.length
      }

      return false
    })

    const allSelected = computed(() => {
      return selectedStatus.value.length === statusOptions.value.length
    })

    const applySelectAll = () => {
      if (partialSelected.value || allSelected.value) {
        selectedStatus.value = []
      } else {
        selectedStatus.value = [...statusOptions.value]
      }
    }

    const updateStatusSelection = (status: FilterStatusOption) => {
      const statusIndex = selectedStatus.value.findIndex(_status => _status === status)

      if (statusIndex === -1) {
        selectedStatus.value.push(status)
      } else {
        selectedStatus.value.splice(statusIndex, 1)
      }
    }

    const statusIsIncluded = (status: FilterStatusOption) => {
      return selectedStatus.value.includes(status)
    }

    return {
      applySelectAll,
      allSelected,
      partialSelected,
      statusOptions,
      statusIsIncluded,
      selectedStatus,
      updateStatusSelection,
      FilterStatusOption
    }
  }
})
</script>
