import { Dictionary } from "@/types/core"

const isTestingEnv = process.env.NODE_ENV === "test"
const cachedCycleCollection: Dictionary<number> = {}
/* istanbul ignore next */
const MAX_CYCLES = isTestingEnv ? 20 : 500
/* istanbul ignore next */
const DEFAULT_INTERVAL = isTestingEnv ? 1 : 500

interface Config {
  maxCycles?: number
  watchCycles?: boolean
  interval?: number
  isAlive?: () => boolean
}

const DEFAULT_CONFIG = {
  maxCycles: MAX_CYCLES,
  watchCycles: true,
  interval: DEFAULT_INTERVAL,
  isAlive: () => true
} as Config

const _registerCycle = () => {
  const cycleStampKey = Date.now()
  cachedCycleCollection[cycleStampKey] = 0
  return cycleStampKey
}

const _iterateCycleCount = (cycleStampKey: number) => {
  cachedCycleCollection[cycleStampKey]++
}

const _getCycleCount = (cycleStampKey: number) => {
  return cachedCycleCollection[cycleStampKey]
}

const _removeCycle = (cycleStampKey: number) => {
  delete cachedCycleCollection[cycleStampKey]
}

/**
 * This function is ideal to handle loop scenarios, where
 * you need to complete a certain task before releasing the loop.
 * Best used to wait for workspaces to change status, like:
 * Deploying to Deployed, Archiving to Archived, Restoring to Deployed.
 *
 * @returns Promise<void>
 *
 * @example
 * const appLoader = Loader()
 * const workspaceModule = WorkspaceModule()
 * const isAlive = ref(true)
 *
 * const isAliveCheck = () => {
 *  return isAlive.value
 * }
 *
 * const defaultConfiguration = {
 *  // Loop will break if reaches this amount of loop cycles, this acts as a garbage collector
 *  maxCycles: 500,
 *  // When false will stop watching cycle count, might be useful for very long process
 *  watchCycles: true,
 *  // The interval per loop
 *  interval: 500,
 *  // Condition to avoid memory leak when navigating between pages
 *  isAlive: () => true
 * }
 *
 * appLoader.run(async () => {
 *   await waitComplete(async () => {
 *      const workspaceId = "123"
 *      const canReleaseLoop = () => workspaceModule.watching.status === "deployed"
 *      await workspaceModule.getWorkspace(workspaceId)
 *
 *      return canReleaseLoop()
 *   }, { isAlive: isAliveCheck })
 * })
 *
 * onBeforeUnmount(() => {
 *  isAlive.value = false
 * })
 */
export const waitComplete = async (callback: () => Promise<boolean>, config: Config = {}) => {
  const cycleStampKey = _registerCycle()
  const _config = { ...DEFAULT_CONFIG, ...config }

  return new Promise<void>((resolve, reject) => {
    const _recursiveFn = async () => {
      _iterateCycleCount(cycleStampKey)

      if (_getCycleCount(cycleStampKey) >= (_config.maxCycles || MAX_CYCLES) && _config.watchCycles) {
        reject("wait-complete: Max cycles reached")
      }

      if (_config.isAlive && !_config.isAlive()) {
        resolve()
      }

      try {
        if (await callback()) {
          _removeCycle(cycleStampKey)
          resolve()
        } else {
          setTimeout(async () => await _recursiveFn(), _config.interval)
        }
      } catch (err) {
        reject(err)
      }
    }

    _recursiveFn()
  })
}
