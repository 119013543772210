import { defineStore } from "pinia"
import { Upload } from "@/services"
import { UploadReqContextId, FileUploadPayload } from "@/types/core"
import { IMedia } from "@/types/media"

export const UploadModule = defineStore("upload", {
  state: () => ({
    contextId: ""
  }),
  actions: {
    async getContextId(context: UploadReqContextId): Promise<IMedia[]> {
      const req = await Upload.getContextId(context)
      return req.data.data
    },

    async sendFile(
      payload: FileUploadPayload,
      progressFeedback: (progress: number) => number
    ): Promise<FileUploadPayload> {
      const req = await Upload.sendFile(payload, progressFeedback)
      return req.data.data as FileUploadPayload
    },

    async removeFile(payload: FileUploadPayload): Promise<void> {
      if (payload.id) {
        await Upload.removeFile(payload.id)
      } else {
        throw Error("Pinia Upload Module, invalid file id, could not remove")
      }
    }
  }
})
