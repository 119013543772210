<template>
  <div class="wrapCodeViewer">
    <div @click="$emit('copy')" class="overlay"></div>

    <v-btn icon @click="$emit('copy')" class="controllerCopy">
      <v-icon>action_copy</v-icon>
    </v-btn>

    <pre class="json">
      <code class="json">
          <span class="symbol s-1">{</span>
          <span class="key s-2">"Version"</span>: <span class="string">"2012-10-17"</span>,
          <span class="key s-2">"Statment"</span>: <span class="symbol">[</span>
              <span class="symbol s-3">{</span>
              <span class="key s-4">"Effect"</span>: <span class="string">"Allow"</span>,
              <span class="key s-4">"Principal"</span>: <span class="symbol">{</span>
                  <span class="key s-5">"AWS"</span>: <span class="string">"arn:aws:iam::324880187172:root"</span>
              <span class="symbol s-4">}</span>,
              <span class="key s-4">"Action"</span>: <span class="string">"sts:AssumeRole"</span>,
              <span class="key s-4">"Condition"</span>: <span class="symbol">{</span>
                  <span class="key s-5">"StringLike"</span>: <span class="symbol">{</span>
                  <span class="key s-6">"sts:ExternalId"</span>: <span class="string">"SpaceliftIntegration@*"</span>
                  <span class="symbol s-5">}</span>
              <span class="symbol s-4">}</span> 
              <span class="symbol s-3">}</span>
          <span class="symbol s-2">]</span>
          <span class="symbol s-1">}</span>
      </code>
    </pre>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "CodeSnippet"
})
</script>
