<template>
  <div class="inputLabel">
    <slot name="label">
      <label v-if="label">{{ label }}</label>
    </slot>

    <v-combobox
      v-model="modelValue"
      @input.native="$emit('input', value)"
      @blur="$emit('blur', value)"
      @change="$emit('change', value)"
      :rules="rules"
      :placeholder="placeholder"
      :name="name"
      :disabled="isDisabled"
      :loading="isLoading"
      :readonly="loading || disabled"
      :hide-details="shouldHideDetails"
      :items="director.items"
      :return-object="false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { ComboboxInput } from "./lib/type"
import commonInputConfig from "./composable/common-props"

export default defineComponent({
  name: "ComboboxInput",
  props: {
    value: {
      type: String,
      required: true
    },
    director: {
      type: Object as PropType<ComboboxInput>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    return {
      ...commonInputConfig(props, ctx.emit)
    }
  }
})
</script>
