import { createService } from "../api/api-factory"
import { Request } from "@/utils/request"
import { WorkspaceRawTemplate } from "@/types/workspace"
import { AppReadyState } from "@/types/core"
import { AddTokenPayload, RemoveTokenPayload, Wallet } from "@/types/organization"
import { OrganizationTag } from "@/types/organization"

const api = new Request("/v1/Organization/")
const organizationsApi = new Request("/v1/Organizations/")

export default createService("organization", {
  async getLicenseServerState() {
    return await api.get<AppReadyState>("IsReady/")
  },

  async editTag(organizationId: string, tag: OrganizationTag) {
    return await organizationsApi.put(`${organizationId}/Tags/Space/`, tag)
  },

  async removeTag(organizationId: string, tagName: string) {
    return await organizationsApi.delete(`${organizationId}/Tags/Space/${tagName}`)
  },

  async editGlobalTag(organizationId: string, tag: OrganizationTag) {
    return await organizationsApi.put(`${organizationId}/Tags/`, tag)
  },

  async removeGlobalTag(organizationId: string, tagName: string) {
    return await organizationsApi.delete(`${organizationId}/Tags/${tagName}`)
  },

  async insertCidrBlocks(organizationId: string, ip: string) {
    const cidrBlocks = ip ? [ip] : []
    return await api.post<boolean>(`${organizationId}/CidrBlocks`, { cidrBlocks, organizationId })
  },

  async getDefaultCidrIP() {
    return await api.get<{ defaultCidr: string }>("DefaultCidr")
  },

  async getWallet(organizationId: string) {
    return await api.get<Wallet>(`${organizationId}/Wallet`)
  },

  async addToken(organizationId: string, payload: AddTokenPayload) {
    return await api.post<void>(`${organizationId}/Wallet/Token`, payload)
  },

  async removeToken(organizationId: string, payload: RemoveTokenPayload) {
    return await api.put<void>(`${organizationId}/Wallet/Token/Transaction`, payload)
  },

  async pickDomainName(dnsName: string, organizationId: string) {
    return await api.post(`${organizationId}/DnsName`, { organizationId, dnsName })
  },

  async getTemplates(organizationId: string) {
    return await api.get<WorkspaceRawTemplate[]>(`${organizationId}/WorkspaceTemplates`)
  },

  async validateDomainName(domainName: string) {
    return await api.get<{ available: boolean }>(`DnsNames/Availability?dnsName=${domainName}`)
  },

  async renewCertificate(organizationId: string) {
    return await api.post(`${organizationId}/RenewCertificate`)
  }
})
