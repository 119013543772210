import { WorkspaceApplication, ApplicationLink, Code } from "@/types/asset"
import { InstancePlatform } from "@/types/workspace"

export const newFolderItem = (folderType: "upload" | "download") => ({
  mountDrive: "",
  name: "",
  type: folderType
})

export const spaceAsset = () => {
  return {
    id: "",
    key: "",
    text: "",
    costCode: "",
    icon: "",
    category: "",
    amiName: "",
    requireLicenseServer: false,
    installCrowdStrike: false,
    extraInformation: { estimatedCost: "", inputs: "", outputs: "" },
    defaultInstanceTypes: [{ name: "AWS", instanceTypes: [] as Code[] }],
    availableInstanceTypes: [{ name: "AWS", instanceTypes: [{ code: "" }] }],
    description: "",
    manualUrl: "",
    userConfigurations: [],
    instancePlatform: InstancePlatform.windows,
    fileTransferElegible: true,
    fileTransferConfig: {
      folders: [newFolderItem("upload"), newFolderItem("download")]
    },
    defaultApplicationLinks: [] as ApplicationLink[]
  } as WorkspaceApplication
}
