import { translate } from "@/plugins/i18n"
import Vue, { VueConstructor } from "vue"
import RegionProvider from "../components/regionProvider/Root.vue"
import DeployAgent from "../components/deployAgent/Root.vue"
import VizNow from "../components/vizNow/Root.vue"
import LicenseServer from "../components/licenseServer/LicenseRoot.vue"
import Icons from "./step-icons"
import Utils from "@/utils/utils"

export interface TabItem {
  label: string
  value: number
  icon: string
  component: VueConstructor<Vue>
}

const tabOptions: (isCustomerHosted: boolean) => TabItem[] = (isCustomerHosted: boolean) => [
  {
    label: translate("Onboarding.steps.stepOne"),
    value: 1,
    icon: Icons.location,
    component: RegionProvider
  },
  {
    label: translate("Onboarding.steps.stepTwo"),
    value: 2,
    icon: Icons.terraform,
    component: DeployAgent
  },
  {
    label: translate("Onboarding.steps.stepThree"),
    value: 3,
    icon: Icons.vizNow,
    component: VizNow
  },
  ...Utils.insertIf(isCustomerHosted, {
    label: translate("Onboarding.steps.stepFour"),
    value: 4,
    icon: Icons.deploy,
    component: LicenseServer
  })
]

export default tabOptions
