<template>
  <div v-if="hasVpc" class="vpcStatusLabel">
    <span class="peeringTitle">{{ $t("WorkspaceEdit.vpcPeering.label") }}</span>

    <div class="vpcWrapQuickStatus" @click="$emit('click')" :class="hasPermission ? 'clickable' : ''">
      <div v-for="status in ['active', 'loading', 'failed']" :key="status">
        <div v-if="vpcStatusCount(status)" class="wrapVpcCounter">
          <span class="statusCircle" :status="status">
            <v-icon v-if="status === 'loading'" class="spinning">status_spinner_indeterminate</v-icon>
          </span>

          <span class="statusCount">{{ vpcStatusCount(status) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent, onBeforeUnmount } from "@vue/composition-api"
import { UsersModule, WorkspaceModule } from "@/store"
import { isVpcStatus, VpcStatusName } from "./multipleVpc/lib/vpc-states"
import Timer from "@/config/app-loop-timers"

export default defineComponent({
  name: "VpcStatusLabel",
  setup() {
    const workspaceModule = WorkspaceModule()
    const usersModule = UsersModule()
    const _mountRef = ref(true)

    const vpcCollection = computed(() => {
      return workspaceModule.vpc
    })

    const hasVpc = computed(() => {
      return vpcCollection.value.length > 0
    })

    const hasPermission = computed(() => {
      const ownerId = workspaceModule.watching.userId
      const myId = usersModule.selfDetail.id
      const isAdmin = usersModule.hasAdminPermissions

      return ownerId === myId || isAdmin
    })

    const vpcStatusCount = (status: string) => {
      const vpcName = status as VpcStatusName

      return vpcCollection.value.reduce((count: number, vpc) => {
        if (isVpcStatus(vpc.peeringStatus, vpcName)) {
          count += 1
        }

        return count
      }, 0)
    }

    const _vpcLoop = async () => {
      if (_mountRef.value && hasPermission.value) {
        const spaceId = workspaceModule.watching.id
        await workspaceModule.getVpc(spaceId)

        setTimeout(() => {
          _vpcLoop()
        }, Timer.workspaceEditPeering)
      }
    }

    _vpcLoop()

    onBeforeUnmount(() => {
      _mountRef.value = false
    })

    return {
      hasPermission,
      hasVpc,
      isVpcStatus,
      vpcStatusCount
    }
  }
})
</script>
