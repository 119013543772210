<template>
  <nested-item
    :app="text"
    :service="service"
    :has-changes="hasChanges"
    :readonly="service.firstDeployOnly && wasDeployed"
  >
    <v-checkbox
      v-model="service.active"
      @click="validate"
      :disabled="isMandatory || (service.firstDeployOnly && wasDeployed)"
      rbt-target="wizardSectionContainer"
      class="my-0"
      hide-details
    >
      <template v-slot:label>
        <span>
          <span>{{ label[$i18n.locale] }}</span>
          <span v-if="service.active && inputRules.length" class="mandatoryLabel">*</span>
        </span>
      </template>
    </v-checkbox>

    <template v-slot:child>
      <v-label v-if="service.widget">
        <span v-if="service.widget.label[$i18n.locale]">{{ service.widget.label[$i18n.locale] }}</span>
        <div v-else class="textInput" />

        <v-text-field
          v-model="service.widget.value"
          :rules="inputRules"
          :hide-details="shouldHideDetails"
          rbt-target="wizardText"
          class="my-0 py-0"
        />
      </v-label>
    </template>
  </nested-item>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api"
import { useValidate } from "../composable/useValidate"
import WizardRuleMixin from "../mixin/WizardRuleMixin"
import WizardWidgetMixin from "../mixin/WizardWidgetMixin"

export default defineComponent({
  name: "WizardText",
  mixins: [WizardRuleMixin, WizardWidgetMixin],
  setup(_, ctx) {
    const { validate } = useValidate(ctx.emit)

    return {
      validate
    }
  }
})
</script>
