<template>
  <div class="adminMenuLinks">
    <div class="wrapMenu" v-if="showMenu" :key="currentRouteRelative">
      <router-link
        v-for="link in links"
        :to="link.route"
        :key="routeName(link.route)"
        :link-active="menuIsActive(routeName(link.route))"
        :rbt-target="link['rbt-target']"
        :alarm="hasNotificationBadge(routeName(link.route))"
        class="linkMenu"
      >
        <span>
          <v-icon v-if="link.icon" :size="18">{{ link.icon }}</v-icon>
          {{ link.label }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api"
import { AdminModule, UsersModule, LicenseModule } from "@/store"
import { OrganizationType } from "@/types/organization"
import { Location, RawLocation } from "vue-router"
import menuLinks from "../lib/menu-links"
import Router from "@/router"

export default defineComponent({
  name: "AdminMenuLinks",
  setup() {
    const usersModule = UsersModule()
    const adminModule = AdminModule()
    const licenseModule = LicenseModule()

    const isCustomerHosted = computed(() => {
      return adminModule.organizationDetail?.organizationType === OrganizationType.CustomerHosted
    })

    const links = computed(() => {
      return {
        ...menuLinks({
          selectedOrganizationId: usersModule.selectedOrganizationId,
          isCustomerHosted: isCustomerHosted.value
        })
      }
    })

    const showMenu = computed(() => {
      return usersModule.hasAdminPermissions
    })

    const currentRouteRelative = computed(() => {
      return Router.currentRoute.meta?.pageRelative
    })

    const routeName = (rawLocation: RawLocation) => {
      return (rawLocation as Location)?.name ?? ""
    }

    const hasNotificationBadge = (routeName: string) => {
      const _hasWarning = licenseModule.appReadyState.warning
      return routeName === "CoreLicenses" && _hasWarning
    }

    const menuIsActive = (routeName: string) => {
      const routeData = Router?.options?.routes?.find(item => item.name === routeName)

      if (routeData) {
        return currentRouteRelative.value === routeData.meta?.pageRelative
      }

      return false
    }

    return {
      routeName,
      currentRouteRelative,
      hasNotificationBadge,
      links,
      menuIsActive,
      showMenu
    }
  }
})
</script>
