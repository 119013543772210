import { defineComponent, computed, inject, PropType } from "@vue/composition-api"
import { WizardSettings, Widget, WizardWidget } from "@/types/wizard"
import { IAssetModel, WorkspaceTemplate } from "@/types/workspace"
import { TranslationOptions } from "@/types/core"
import { areEqualStructures } from "@/utils/workspaceDiff"
import NestedItem from "../components/NestedItem.vue"
import Utils from "@/utils/utils"

export default defineComponent({
  name: "WizardMixin",
  props: {
    value: {
      type: Object as PropType<WizardSettings<Widget>>,
      required: true
    },
    label: {
      type: Object as PropType<TranslationOptions>,
      required: true
    },
    wasDeployed: {
      type: Boolean,
      default: false
    },
    resourceKey: {
      type: String,
      required: false
    }
  },
  setup(props, ctx) {
    const service = Utils.vModel<WizardSettings<Widget>>(props, ctx.emit)
    const workspace = inject<WorkspaceTemplate | null>("workspace", null)
    const app = inject<IAssetModel | null>("app", null)

    const _widgetBasedResourceKey = computed(() => {
      if (workspace && app) {
        const { schema, schemaDeployed } = workspace

        const appNew = schema.assets.find(_a => _a.id === app.id)
        const appOld = schemaDeployed?.assets.find(_a => _a.id === app.id)

        const widgetNew = appNew?.userConfigurations?.find(widget => widget.resourceKey === props.resourceKey)
        const widgetOld = appOld?.userConfigurations?.find(widget => widget.resourceKey === props.resourceKey)

        return {
          widgetNew,
          widgetOld
        }
      }

      return null
    })

    const isMandatory = computed(() => {
      return service.value?.required ?? false
    })

    const hasChanges = computed(() => {
      if (workspace && app) {
        if (_widgetBasedResourceKey.value) {
          const { widgetNew, widgetOld } = _widgetBasedResourceKey.value

          if (widgetNew && widgetOld) {
            return !areEqualStructures(widgetNew, widgetOld)
          }
        }
      }

      return false
    })

    return {
      hasChanges,
      service,
      isMandatory,
      ...WizardWidget
    }
  },
  components: {
    NestedItem
  }
})
