<template>
  <div class="searchContainer">
    <v-text-field
      v-model="snippet"
      @input.native="$emit('change')"
      :label="$t('WorkspaceEdit.addApps.search')"
      prepend-inner-icon="action_search"
      hide-details
    />
  </div>
</template>

<script lang="ts">
import Utils from "@/utils/utils"
import { defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "SearchApp",
  props: {
    value: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const snippet = Utils.vModel(props, ctx.emit)

    return {
      snippet
    }
  }
})
</script>
