interface Config {
  itemsPerPage: number
}

const defaultConfig: Config = {
  itemsPerPage: 5
}

class Pagination<T = unknown[]> {
  private allItems!: T[]
  private config!: Config
  private currentPage = 0
  private filter = (items: T[]) => items

  public get maxPageCount() {
    return Math.floor(this.filter(this.allItems).length / this.config.itemsPerPage)
  }

  constructor(items: T[], config = defaultConfig) {
    this.set(items, config)
  }

  public set(items: T[], config = defaultConfig) {
    this.allItems = [...items]
    this.config = config
  }

  public get items() {
    const offset = this.currentPage * this.config.itemsPerPage
    return this.filter(this.allItems).slice(offset, offset + this.config.itemsPerPage)
  }

  public get hasNextPage() {
    return this.currentPage < this.maxPageCount
  }

  public get hasPreviousPage() {
    return this.currentPage >= 1
  }

  public get page() {
    return this.currentPage + 1
  }

  public get lastPage() {
    return this.maxPageCount + 1
  }

  public jumpToPage(pageNumber: number) {
    const page = pageNumber - 1

    if (page >= 0 && page <= this.maxPageCount) {
      this.currentPage = pageNumber - 1
    }
  }

  public next() {
    if (this.currentPage < this.maxPageCount) {
      this.currentPage++
    }
  }

  public previous() {
    if (this.currentPage >= 1) {
      this.currentPage--
    }
  }

  public setFilter(filter: (items: T[]) => typeof items) {
    this.filter = filter
  }
}

export { Pagination }
