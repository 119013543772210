import { WorkspaceOnlineStatusEnum, WorkspaceStatusEnum } from "@/types/workspace"
import * as Timer from "@/config/app-timer-speculation"
import { translate } from "@/plugins/i18n"

const _toMin = (ms: number) => `${Math.max(ms / 60 / 1000)}`

const inMinutes = {
  deploying: _toMin(Timer.DEPLOYING_TIMER),
  restoring: _toMin(Timer.RESTORING_TIMER)
}

export const feedbacksBasedOnWorkspaceStatus = (
  workspaceStatus: WorkspaceStatusEnum,
  appStatus: WorkspaceOnlineStatusEnum
) => {
  const _s = WorkspaceStatusEnum

  switch (workspaceStatus) {
    case _s.Deployed:
      if (appStatus === WorkspaceOnlineStatusEnum.Offline) {
        return translate("TriCaster.bottomFeedback.offline")
      }
      return ""

    case _s.Archiving:
      return translate("TriCaster.bottomFeedback.archiving", { min: inMinutes.restoring })

    case _s.Archived:
      return translate("TriCaster.bottomFeedback.archived", { min: inMinutes.restoring })

    case _s.Restoring:
      return translate("TriCaster.bottomFeedback.restoring", { min: inMinutes.restoring })

    case _s.Deploying:
      return translate("TriCaster.bottomFeedback.deploying", { min: inMinutes.deploying })

    case _s.Draft:
      return translate("TriCaster.bottomFeedback.draft")

    default:
      return ""
  }
}
