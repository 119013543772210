<template>
  <div class="inputLabel">
    <v-checkbox
      v-model="modelValue"
      @input.native="$emit('input', value)"
      @blur="$emit('blur', value)"
      @change="$emit('change', value)"
      :placeholder="placeholder"
      :name="name"
      :disabled="isDisabled"
      :loading="isLoading"
      :readonly="loading || disabled"
      :hide-details="shouldHideDetails"
      :label="label"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { InputItem } from "./lib/type"
import commonInputConfig from "./composable/common-props"

export default defineComponent({
  name: "Checkbox",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    director: {
      type: Object as PropType<InputItem>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    return {
      ...commonInputConfig(props, ctx.emit)
    }
  }
})
</script>
