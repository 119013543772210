import { translate } from "@/plugins/i18n"
import { HeaderItem } from "@/types/core"

const headers: () => HeaderItem[] = () => {
  return [
    {
      text: translate("LicensePage.table.headers.software"),
      value: "product",
      sortable: true
    },
    {
      text: translate("LicensePage.table.headers.available"),
      value: "freeLicenses",
      sortable: true
    },
    {
      text: translate("LicensePage.table.headers.containerId"),
      value: "containerId",
      sortable: false
    }
  ]
}

export default headers
