<template>
  <div class="warnChanges">
    <div class="wrapError">
      <span class="leftBox">
        <v-icon color="error" left>status_error</v-icon>

        <span>
          {{ errorMessage }}
        </span>
      </span>

      <div class="rightBox">
        <v-btn @click="$emit('app:retry')" :disabled="loading" text>
          <v-icon left>action_reset</v-icon>
          <span>{{ $t("WorkspaceEdit.appDetails.buttons.retry") }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { translate } from "@/plugins/i18n"
import { defineComponent, computed } from "@vue/composition-api"

export default defineComponent({
  name: "WarnAppError",
  props: {
    appErrorMessage: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const errorMessage = computed(() => {
      if (props.appErrorMessage) {
        return props.appErrorMessage
      }
      return translate("WorkspaceEdit.appDetails.alert.defaultError")
    })

    return {
      errorMessage
    }
  }
})
</script>
