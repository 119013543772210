<template>
  <div class="viewTypeHandlerWrapper">
    <tooltip :text="$t('MyWorkspaces.tooltip.changeViewType')">
      <v-btn-toggle v-model="viewMode" @change="cacheViewModePreference" mandatory>
        <v-btn value="table" rbt-target="listView" large icon>
          <v-icon>navigate_view_list</v-icon>
        </v-btn>

        <v-btn value="grid" rbt-target="gridView" large icon>
          <v-icon>navigate_view_grid_thumbnails_medium</v-icon>
        </v-btn>
      </v-btn-toggle>
    </tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"
import Utils from "@/utils/utils"

enum ViewMode {
  table = "table",
  grid = "grid"
}

export default defineComponent({
  name: "ViewTypeHandler",
  props: {
    value: {
      type: String as PropType<ViewMode | string>,
      required: true
    }
  },
  setup(props, ctx) {
    const viewMode = Utils.vModel(props, ctx.emit)
    const preDefinedViewType = localStorage.getItem("workspaceViewTypePreference") as ViewMode

    viewMode.value = preDefinedViewType ?? ViewMode.grid

    const cacheViewModePreference = (newValue: string) => {
      localStorage.setItem("workspaceViewTypePreference", newValue)
    }

    return {
      cacheViewModePreference,
      viewMode
    }
  },
  components: {
    Tooltip
  }
})
</script>
