<template>
  <span v-if="showIcon" class="workspaceIcon">
    <tooltip :text="feedbackMessage">
      <v-icon :color="iconColor" right>{{ icon }}</v-icon>
    </tooltip>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/composition-api"
import { WorkspaceTemplate } from "@/types/workspace"
import gridCardSetup from "../../lib/grid-card-icon-setup"
import Tooltip from "@/components/tooltip/TooltipRoot.vue"

export default defineComponent({
  name: "GridCardStatusIcon",
  props: {
    workspace: {
      type: Object as PropType<WorkspaceTemplate>,
      required: true
    },
    error: {
      type: Boolean,
      default: true
    },
    shared: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    return gridCardSetup(props)
  },
  components: {
    Tooltip
  }
})
</script>
