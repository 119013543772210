import type { ScheduledOnOff } from "@/views/appLauncher/lib/types"
import { useStore } from "@/composable/useStore"
import { computed } from "@vue/composition-api"
import Utils from "@/utils/utils"

export const useSchedule = () => {
  const { spaceModule } = useStore()

  const schedule = computed<ScheduledOnOff>({
    get() {
      return spaceModule.scheduledOnOff
    },
    set(schedule: ScheduledOnOff) {
      spaceModule.scheduledOnOff = schedule
    }
  })

  const startTime = computed<number | null>({
    get() {
      const time = schedule.value.autoStartupAt

      if (time) {
        return new Date(time as string).getTime()
      } else {
        return null
      }
    },
    set(startTime: number | null) {
      if (startTime) {
        schedule.value.autoStartupAt = new Date(startTime).toISOString()
      } else {
        schedule.value.autoStartupAt = null
      }
    }
  })

  const startTimeDate = computed(() => {
    if (startTime.value) {
      return Utils.dateWithNamedMonth(new Date(startTime.value).getTime())
    }
    return null
  })

  const startTimeUTC = computed(() => {
    if (startTime.value) {
      const date = new Date(startTime.value)
      const timezone = Utils.minutes(date.getTimezoneOffset())
      const formattedDate = Utils.dateFormatWithTime(date.getTime() + timezone)

      return formattedDate
    }
    return null
  })

  const endTime = computed({
    get() {
      const time = schedule.value.autoShutdownAt

      if (time) {
        return new Date(time).getTime()
      } else {
        return null
      }
    },
    set(endTime: number | null) {
      if (endTime) {
        schedule.value.autoShutdownAt = new Date(endTime).toISOString()
      } else {
        schedule.value.autoShutdownAt = null
      }
    }
  })

  const endTimeDate = computed(() => {
    if (endTime.value) {
      return Utils.dateWithNamedMonth(new Date(endTime.value).getTime())
    }
    return null
  })

  const endTimeUTC = computed(() => {
    if (endTime.value) {
      const date = new Date(endTime.value)
      const timezone = Utils.minutes(date.getTimezoneOffset())
      const formattedDate = Utils.dateFormatWithTime(date.getTime() + timezone)

      return formattedDate
    }
    return null
  })

  return {
    start: {
      startTime,
      startTimeDate,
      startTimeUTC
    },
    end: {
      endTime,
      endTimeDate,
      endTimeUTC
    }
  }
}
