import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticClass:"userTable",attrs:{"headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"text":_vm.disabledIcon(item).text}},[_c(VIcon,{attrs:{"color":_vm.disabledIcon(item).color,"rbt-target":"userActiveState","left":""}},[_vm._v(" "+_vm._s(_vm.disabledIcon(item).icon)+" ")])],1),_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]),(item.id === _vm.myId)?_c('small',{staticClass:"highlighter"},[_vm._v(" (This is you)")]):_vm._e()]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("OrganizationUsers.roles." + (item.role))))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":_vm.userHasMorePermissionThenI(item.role),"rbt-target":"moreOptionsBtn","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("navigate_more_options_vertical")])],1)]}}],null,true)},[_c('div',{staticClass:"listMenu"},[_c('div',{staticClass:"listRow",attrs:{"rbt-target":"editUserBtn"},on:{"click":function($event){return _vm.$emit('edit:user', item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_edit")]),_c('span',[_vm._v(_vm._s(_vm.$t("OrganizationUsers.table.menu.edit")))])],1),(item.disabled)?_c('div',{staticClass:"listRow",attrs:{"rbt-target":"activateUser"},on:{"click":function($event){return _vm.changeUserActiveState(item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("status_ok_circle")]),_c('span',[_vm._v(_vm._s(_vm.$t("OrganizationUsers.table.menu.activateUser")))])],1):_c('div',{staticClass:"listRow",attrs:{"rbt-target":"disableUser"},on:{"click":function($event){return _vm.changeUserActiveState(item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("status_no")]),_c('span',[_vm._v(_vm._s(_vm.$t("OrganizationUsers.table.menu.disableUser")))])],1),_c('div',{staticClass:"listRow",attrs:{"rbt-target":"removeUser"},on:{"click":function($event){return _vm.confirmRemoveUser(item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("action_trash")]),_c('span',[_vm._v(_vm._s(_vm.$t("OrganizationUsers.table.menu.remove")))])],1)])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }