export enum AppMode {
  open = "open",
  closed = "closed"
}

export interface ShutdownInformation {
  hoursBeforeShutdown: number
  inactivityTime: number
  autoShutdown: true
  watchForInactivity: true
  shutdownAt: number
  inactiveShutdownAt: number
  isInactive: false
}

export interface CommunicationStructure<T, Data> {
  variant: string
  message: T | WebMessageErrorReport
  data?: Data
}

export interface WebMessageErrorReport {
  error: string
  text: string
}

export interface ShutdownSettings {
  autoShutdownEnabled: boolean
  shutdownTimerInHours: number
  inactivityTrackerEnabled: boolean
  inactivityPeriodInHours: number
}

export interface ScheduledOnOff extends SpaceSchedule {
  scheduledOnOffEnabled: boolean
}

export interface SpaceSchedule {
  autoStartupAt: string | null
  autoShutdownAt: string | null
}
